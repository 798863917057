import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SettingsPage from '../../../components/Settings/SettingsPage';
import { http } from '../../../utils/Utils';
import { CLIENT_ROLE_ADMIN } from '../../../utils/Utils';
import CardsHeader from '../../../components/UI/CardsHeader';
import ClientUsage from '../../../components/Subscriptions/ClientUsage';
import SubscriptionInfo from '../../../components/Subscriptions/SubscriptionInfo';
import TableHeaderTab from '../../../components/UI/TableHeaderTab';
import SepaComponent from '../../../components/Subscriptions/SepaComponent';
import ClientPayments from '../../../components/ClientPayments/ClientPayments';

const SubscriptionDetails = () => {
  const [planMonths, setPlanMonths] = useState([]);
  const [fieldToUpdate, setFieldToUpdate] = useState('subscription');
  const [client, setClient] = useState(null);

  useEffect(() => {
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClient = async () => {
    try {
      const response = await http({
        method: 'GET',
        url: `/clients/client`,
      });

      setClient(response);

      const createdAt = new Date(response?.createdAt);
      createdAt.setDate(1); // set the day to the first day of the month
      const now = new Date();
      now.setDate(1); // set the day to the first day of the month
      const months = [];

      while (createdAt <= now) {
        months.push(new Date(createdAt.getFullYear(), createdAt.getMonth(), 1));
        createdAt.setMonth(createdAt.getMonth() + 1);
      }

      months.reverse();
      setPlanMonths(months);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <SettingsPage canAccess={[CLIENT_ROLE_ADMIN]} hasBox={false}>
      <TableHeaderTab
        buttons={[
          {
            label: 'Indietro',
            function: () => {
              window.history.back();
            },
          },
          {
            label: 'Abbonamento',
            function: () => {
              setFieldToUpdate('subscription');
            },
            selected: fieldToUpdate === 'subscription',
          },
          {
            label: 'Pagamenti',
            function: () => {
              setFieldToUpdate('payments');
            },
            selected: fieldToUpdate === 'payments',
          },
          {
            label: 'Sepa',
            function: () => {
              setFieldToUpdate('sepa');
            },
            selected: fieldToUpdate === 'sepa',
          },
        ]}
      />
      {fieldToUpdate === 'subscription' && (
        <>
          <CardsHeader title={'Abbonamento'} />

          <div className="flex flex-col-reverse md:flex-row space-x-0 md:space-x-4 px-6">
            <div className="w-full md:w-3/4 order-last md:order-first">
              {planMonths.map((month, index) => {
                return <ClientUsage key={index} month={month} />;
              })}
            </div>
            <div className="w-full md:w-1/4 order-first md:order-last">
              <SubscriptionInfo />
            </div>
          </div>
        </>
      )}
      {fieldToUpdate === 'payments' && (
        <>
          <CardsHeader title={'Pagamenti'} />

          <div className="flex flex-col-reverse md:flex-row space-x-0 md:space-x-4 px-6">
            <div className="w-full md:w-full order-last md:order-first">
              <ClientPayments client={client} />
            </div>
          </div>
        </>
      )}
      {fieldToUpdate === 'sepa' && (
        <>
          <CardsHeader title={'Sepa'} />

          <div className="flex flex-col-reverse md:flex-row space-x-0 md:space-x-4 px-6">
            <div className="w-full md:w-full order-last md:order-first">
              <SepaComponent />
            </div>
          </div>
        </>
      )}
    </SettingsPage>
  );
};

export default SubscriptionDetails;

import React, { useEffect, useState } from 'react';
import AdminPage from '../../../components/Admin/AdminPage';
import ClientsTable from '../../../components/Clients/Clients/ClientsTable';
import TableHeader from '../../../components/UI/TableHeader';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../utils/Utils';
import ClientsModal from '../../../components/Clients/Clients/ClientsModal';
import FilterContainer from '../../../components/UI/FilterContainer';

const Clients = () => {
  const [clients, setClients] = useState([]); // eslint-disable-line
  const [showAddClient, setShowAddClient] = useState(false);

  const [clientsCount, setClientsCount] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    fetchClientsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientsCount = async () => {
    try {
      const response = await http({ url: '/clients/client/all' });
      setClientsCount(response.count);
    } catch (err) {
      console.error(err);
    }
  };

  const searchSubmit = async (values) => {
    try {
      setQuery(values.query);

      const response = await http({
        url: `/clients/client/all?search=${values.query}`,
      });
      setClientsCount(response.count);
      setClients(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <div className="flex justify-between items-center gap-2 mr-6">
        <TableHeader
          tableName={'Clienti'}
          buttons={[
            {
              function: () => {
                setShowAddClient(true);
              },
              label: '+',
            },
          ]}
          length={clientsCount}
        />

        <FilterContainer onSubmit={searchSubmit} />
      </div>

      <ClientsTable filterQuery={query} />

      {showAddClient && <ClientsModal mode={'add'} closeModal={() => setShowAddClient(false)} />}
    </AdminPage>
  );
};

export default Clients;

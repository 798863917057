const professionsJson = [
  { value: 'addetto_al_controllo_di_qualità', label: 'Addetto al controllo di qualità' },
  { value: 'addetto_al_customer_service', label: 'Addetto al customer service' },
  { value: 'addetto_alle_risorse_umane', label: 'Addetto alle risorse umane' },
  { value: 'addetto_al_servizio_clienti', label: 'Addetto al servizio clienti' },
  { value: 'addetto_alle_vendite', label: 'Addetto alle vendite' },
  { value: 'agricoltore', label: 'Agricoltore' },
  { value: 'analista_dati', label: 'Analista dati' },
  { value: 'analista_del_credito', label: 'Analista del credito' },
  { value: 'analista_finanziario', label: 'Analista finanziario' },
  { value: 'analista_di_marketing', label: 'Analista di marketing' },
  { value: 'architetto', label: 'Architetto' },
  { value: 'archivista', label: 'Archivista' },
  { value: 'astronomo', label: 'Astronomo' },
  { value: 'avvocato', label: 'Avvocato' },
  { value: 'avvocato_penalista', label: 'Avvocato penalista' },
  { value: 'barista', label: 'Barista' },
  { value: 'biologo', label: 'Biologo' },
  { value: 'cameraman', label: 'Cameraman' },
  { value: 'cameriere', label: 'Cameriere' },
  { value: 'cartografo', label: 'Cartografo' },
  { value: 'cassiere', label: 'Cassiere' },
  { value: 'chimico', label: 'Chimico' },
  { value: 'commerciante', label: 'Commerciante' },
  { value: 'consulente_assicurativo', label: 'Consulente assicurativo' },
  { value: 'consulente_aziendale', label: 'Consulente aziendale' },
  { value: 'consulente_di_viaggio', label: 'Consulente di viaggio' },
  { value: 'consulente_finanziario', label: 'Consulente finanziario' },
  { value: 'cuoco', label: 'Cuoco' },
  { value: 'decoratore_dinterni', label: "Decoratore d'interni" },
  { value: 'dietologo', label: 'Dietologo' },
  { value: 'direttore_creativo', label: 'Direttore creativo' },
  { value: 'direttore_delle_vendite', label: 'Direttore delle vendite' },
  { value: 'direttore_di_produzione', label: 'Direttore di produzione' },
  { value: 'economista', label: 'Economista' },
  { value: 'elettricista', label: 'Elettricista' },
  { value: 'elettricista_industriale', label: 'Elettricista industriale' },
  { value: 'educatore_per_linfanzia', label: "Educatore per l'infanzia" },
  { value: 'farmacista', label: 'Farmacista' },
  { value: 'farmacologo', label: 'Farmacologo' },
  { value: 'farmacologo_clinico', label: 'Farmacologo clinico' },
  { value: 'fisioterapista', label: 'Fisioterapista' },
  { value: 'fotografo', label: 'Fotografo' },
  { value: 'gestionale_delle_risorse_umane', label: 'Gestionale delle risorse umane' },
  { value: 'geologo', label: 'Geologo' },
  { value: 'geometra', label: 'Geometra' },
  { value: 'grafico', label: 'Grafico' },
  { value: 'ingegnere', label: 'Ingegnere' },
  { value: 'ingegnere_del_software', label: 'Ingegnere del software' },
  { value: 'logopedista', label: 'Logopedista' },
  { value: 'manager', label: 'Manager' },
  { value: 'massaggiatore', label: 'Massaggiatore' },
  { value: 'medico', label: 'Medico' },
  { value: 'meccanico', label: 'Meccanico' },
  { value: 'musicista', label: 'Musicista' },
  { value: 'nutrizionista', label: 'Nutrizionista' },
  { value: 'ottico', label: 'Ottico' },
  { value: 'parrucchiere', label: 'Parrucchiere' },
  { value: 'personal_shopper', label: 'Personal shopper' },
  { value: 'personal_trainer', label: 'Personal trainer' },
  { value: 'pilota', label: 'Pilota' },
  { value: 'pittore', label: 'Pittore' },
  { value: 'pompiere', label: 'Pompiere' },
  { value: 'poliziotto', label: 'Poliziotto' },
  { value: 'programmatore_informatico', label: 'Programmatore informatico' },
  { value: 'psicologo', label: 'Psicologo' },
  { value: 'psichiatra', label: 'Psichiatra' },
  { value: 'rappresentante_di_vendita', label: 'Rappresentante di vendita' },
  { value: 'realizzatore_di_software', label: 'Realizzatore di software' },
  { value: 'responsabile_delle_operazioni', label: 'Responsabile delle operazioni' },
  { value: 'responsabile_delle_risorse_umane', label: 'Responsabile delle risorse umane' },
  { value: 'responsabile_del_servizio_clienti', label: 'Responsabile del servizio clienti' },
  { value: 'scienziato_dei_dati', label: 'Scienziato dei dati' },
  { value: 'sarto', label: 'Sarto' },
  { value: 'seo_specialist', label: 'SEO Specialist' },
  { value: 'specialista_in_pubbliche_relazioni', label: 'Specialista in pubbliche relazioni' },
  { value: 'steward_hostess', label: 'Steward/Hostess' },
  { value: 'stilista', label: 'Stilista' },
  { value: 'tecnico_del_software', label: 'Tecnico del software' },
  { value: 'tecnico_del_suono', label: 'Tecnico del suono' },
  { value: 'tecnico_di_laboratorio', label: 'Tecnico di laboratorio' },
  { value: 'tecnico_di_radiologia', label: 'Tecnico di radiologia' },
  { value: 'tecnico_elettronico', label: 'Tecnico elettronico' },
  { value: 'tecnico_informatico', label: 'Tecnico informatico' },
  { value: 'tecnico_delle_energie_rinnovabili', label: 'Tecnico delle energie rinnovabili' },
  { value: 'traduttore_interprete', label: 'Traduttore/Interprete' },
  { value: 'veterinario', label: 'Veterinario' },
  { value: 'web_developer', label: 'Web Developer' },
  { value: 'web_designer', label: 'Web Designer' },
  { value: 'web_marketing', label: 'Web Marketing' },
  { value: 'other', label: 'Altro' },
];

export default professionsJson;

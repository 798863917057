import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import WhiteBox from '../UI/WhiteBox';
import SepaElement from './SepaElement';

const SepaComponent = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  return (
    <WhiteBox className="mx-0 my-0" innerClassName="px-6 py-5">
      <Elements stripe={stripePromise}>
        <SepaElement />
      </Elements>
    </WhiteBox>
  );
};

export default SepaComponent;

export const getVehicleGroup = (vehicle) => {
  if (vehicle === undefined) return;
  if (vehicle && vehicle.group) {
    return { group: vehicle.group, custom: true };
  } else {
    return { group: vehicle?.version?.group, custom: false };
  }
};

export const getVehicleImageUrl = (vehicle) => {
  if (vehicle === undefined) return;
  if (vehicle && vehicle.imageUrl) {
    return { imageUrl: vehicle.imageUrl, custom: true };
  } else {
    return { imageUrl: vehicle?.version?.imageUrl, custom: false };
  }
};

import React from 'react';
import LightGrayLink from '../../UI/LightGrayLink';

const BrandsTableItem = (item) => {
  const brand = item.brand;
  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{brand.brandName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {brand.brandImage ? <img src={brand.brandImage} width={40} alt="brand-logo" /> : <></>}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {brand.createdAt !== undefined && new Date(brand.createdAt).toLocaleDateString()}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600 w-10">
          {brand.updatedAt !== undefined && new Date(brand.updatedAt).toLocaleDateString()}
        </p>
      </td>
      <td>
        <LightGrayLink to={`/admin/veicoli/marche/${brand._id}`}>Aggiorna</LightGrayLink>
      </td>
    </tr>
  );
};

export default BrandsTableItem;

// import React, { useContext } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

// import { UserContext } from '../../store/UserContext';
// import { MOVOLAB_ROLE_ADMIN } from '../../utils/Utils';
import DisplayDateTime from '../UI/dates/DisplayDateTime';
import Button from '../UI/buttons/Button';
import { convertPrice } from '../../utils/Prices';

const RevenueSharesTableItem = (item) => {
  const revenueShare = item.revenueShare;
  // const { data: currentClient } = useContext(UserContext);
  // const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN;

  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          <Link
            to={`/admin/clienti/anagrafica/${revenueShare?.client?._id}`}
            className="text-blue-500 hover:underline"
          >
            {revenueShare?.client?.ragioneSociale}
          </Link>{' '}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          <Link
            to={`/admin/clienti/movimenti/${revenueShare?.rent?._id}`}
            className="text-blue-500 hover:underline"
          >
            {revenueShare.rent?.code}
          </Link>{' '}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          <Link
            to={`/admin/packs/${revenueShare?.pack?._id}`}
            className="text-blue-500 hover:underline"
          >
            {revenueShare.pack?.name}
          </Link>
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          {convertPrice(revenueShare.totalAmount)}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          {convertPrice(revenueShare.amountMovolab)} ({revenueShare.percentageMovolab}%)
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <DisplayDateTime date={revenueShare.createdAt} displayType={'flat'} />
      </td>
      <td>
        <Button to={`/admin/ripartizione-incassi/${revenueShare?._id}`} btnStyle="tableItemAction">
          Dettagli &raquo;
        </Button>
      </td>
    </tr>
  );
};

export default RevenueSharesTableItem;

import React, { useContext } from 'react';
import { UserContext } from '../../store/UserContext';
import { MOVOLAB_ROLE_ADMIN } from '../../utils/Utils';
import DisplayDateTime from '../UI/dates/DisplayDateTime';

const AccessCodesTableItem = (item) => {
  const accessCode = item.accessCode;

  const { data: currentClient } = useContext(UserContext);
  const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN; // eslint-disable-line no-unused-vars

  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600 w-20">{accessCode?.code}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{accessCode?.agent}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{accessCode?.maxUses}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{accessCode?.uses}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-semibold text-gray-600">
          <DisplayDateTime date={accessCode.createdAt} />
        </div>
      </td>
    </tr>
  );
};

export default AccessCodesTableItem;

import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

const SearchAddress = ({ returnAddress, onChange, placeholder = 'Ricerca per indirizzo...' }) => {
  const [address, setAddress] = useState('');
  const [value, setValue] = useState(''); // eslint-disable-line

  const getComponent = (address_components, type) => {
    return address_components?.find((component) => component.types.includes(type));
  };

  const handleChange = (address) => {
    setAddress(address);
    onChange(address);
  };
  const handleSelect = (address) => {
    let street = address.split(', ')[0];
    setAddress(address);
    setValue('name', street);
    geocodeByAddress(address)
      .then((results) => {
        const address_components = {
          street: getComponent(results[0].address_components, 'route')?.long_name,
          houseNumber: getComponent(results[0].address_components, 'street_number')?.long_name,
          city: getComponent(results[0].address_components, 'locality')?.long_name,
          province: getComponent(results[0].address_components, 'administrative_area_level_2')
            ?.short_name,
          nation: getComponent(results[0].address_components, 'country')?.long_name,
          zipCode: getComponent(results[0].address_components, 'postal_code')?.long_name,
        };

        returnAddress(results[0].formatted_address, address_components);
      })
      .catch((error) => {
        console.error('ErrorXXX', error);
        setValue('city', '');
        setValue('state', '');
        setValue('zipcode', '');
      });
  };

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder,
              className: 'location-search-input w-full md:w-96 text-gray-800 rounded px-3 py-1 bg-white' + (suggestions.length > 0 && ' rounded-b-none'),
            })}
          />
          { suggestions.length > 0 && (
          <div className="autocomplete-dropdown-container border border-black rounded-b overflow-hidden">
            {loading && <div>Caricamento...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#D1D5DB',
                    cursor: 'pointer',
                    // border: '2px solid #000000',
                    // borderRadius: '4px',
                    padding: '0.25rem 0.75rem',
                    width: '24rem',
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    // border: '1px solid #000000',
                    // borderRadius: '4px',
                    padding: '0.25rem 0.75rem',
                    width: '24rem',
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default SearchAddress;

import React from 'react';
import moment from 'moment';

const CalendarCell = ({ date, dayAvailability, isClosingDay }) => {
  const cellContent = [];
  const daySegmentLen = 100 / 24;

  if (isClosingDay) {
    cellContent.push(
      <div
        className="absolute h-[65px] bg-opacity-40 bg-gray-100 z-[1] cursor-not-allowed"
        style={{ left: '0%', width: '100%' }}
        title="Giorno di chiusura"
      ></div>,
    );
  } else {
    // Questo obrobrio crea i segmentini degli orari di apertura durante la giornata
    var curSegmentLen = 0;
    var startAt = 0;
    dayAvailability.forEach((available, hour) => {
      if (!available) {
        if (curSegmentLen === 0) {
          startAt = hour * daySegmentLen;
        }
        curSegmentLen += daySegmentLen;
      }

      if (curSegmentLen !== 0 && (hour >= 23 || (hour < 24 && dayAvailability[hour + 1]))) {
        cellContent.push(
          <div
            className="absolute h-[65px] bg-opacity-40 bg-gray-100 z-[1] cursor-not-allowed"
            style={{ left: startAt + '%', width: curSegmentLen + '%' }}
            key={hour}
            title="Chiuso"
          ></div>,
        );
        curSegmentLen = 0;
      }
    });
  }

  const hourNow = date.isSame(moment(), 'date') ? moment().get('hour') : 24;

  // Riga con l'ora corrente
  if (hourNow !== 24) {
    cellContent.push(
      <div
        className="absolute h-[65px] bg-green-300 z-[1]"
        style={{ left: hourNow * daySegmentLen + '%', width: '1px' }}
        key="curhour"
        title="Adesso"
      ></div>,
    );
  }

  return (
    <>
      <div className="relative">{cellContent.map((cell) => cell)}</div>
      {!isClosingDay ? (
        <div className="flex py-1 text-center text-slate-300 h-full items-center opacity-0 hover:opacity-100">
          <div className="flex-1 py-2 text-sm select-none border-r border-slate-300">
            {hourNow < 6 ? <div className="rotate-[270deg] text-slate-400">Movo</div> : '6'}
          </div>
          <div className="flex-1 py-2 text-sm select-none border-r border-slate-300">
            {hourNow >= 6 && hourNow < 12 ? (
              <div className="rotate-[270deg] text-slate-400">Movo</div>
            ) : (
              '12'
            )}
          </div>
          <div className="flex-1 py-2 text-sm select-none border-r border-slate-300">
            {hourNow >= 12 && hourNow < 18 ? (
              <div className="rotate-[270deg] text-slate-400">Movo</div>
            ) : (
              '18'
            )}
          </div>
          <div className="flex-1 py-2 text-sm select-none">
            {hourNow >= 18 && hourNow < 24 ? (
              <div className="rotate-[270deg] text-slate-400">Movo</div>
            ) : (
              '24'
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CalendarCell;

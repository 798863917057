import React, { useEffect, useState } from 'react';
import DisplayDateTime from '../UI/dates/DisplayDateTime';
import { http } from '../../utils/Utils';
import toast from 'react-hot-toast';
import WhiteBox from '../UI/WhiteBox';
import ElementLabel from '../UI/ElementLabel';

const SubscriptionInfo = ({ ...props }) => {
  const [client, setClient] = useState(null);

  useEffect(
    () => {
      fetchClient();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fetchClient = async () => {
    try {
      const response = await http({
        method: 'GET',
        url: `/clients/client`,
      });

      setClient(response);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <WhiteBox
      className="mx-0"
      innerClassName="px-6 py-5
"
      headerChildren={(expanded) => (
        <div className="flex gap-x-4 items-center flex-wrap font-bold text-lg min-h-[32px]">
          {'Informazioni Abbonamento'}
        </div>
      )}
      {...props}
    >
      <div className="flex flex-wrap md:flex-nowrap mt-4 gap-4">
        <div className="flex-initial w-full md:w-full p-4 px-5 text-sm rounded-lg">
          <h4 className="text-xl font-bold mb-2">Informazioni</h4>
          <p>
            <strong className="my-1 inline-block"></strong>{' '}
          </p>
          <div className="flex space-x-2">
            <strong className="inline-block">Registrazione</strong>
            <DisplayDateTime date={client?.createdAt} displayType={'flat'} />
          </div>
          <div className="flex space-x-2">
            <strong className="inline-block">Tipo Licenza</strong>
            {client?.license?.licenseOwner === 'movolab' ? (
              <ElementLabel bgColor="bg-blue-500">Movolab</ElementLabel>
            ) : (
              <ElementLabel bgColor="bg-gray-500">Cliente</ElementLabel>
            )}
          </div>
          {client?.currentPack && (
            <div className="flex space-x-2">
              <strong className="inline-block">Pack</strong>
              <div>{client?.currentPack?.name}</div>
            </div>
          )}
          {client?.currentPack && (
            <div className="flex space-x-2">
              <strong className="inline-block">Attivazione Pack</strong>
              <DisplayDateTime
                date={client?.packHistory[client.packHistory.length - 1].startDate}
                displayType={'flat'}
              />
            </div>
          )}
        </div>
      </div>
    </WhiteBox>
  );
};

export default SubscriptionInfo;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AdminPage from '../../../components/Admin/AdminPage';
import { TextField } from '../../../components/Form/TextField';
import { SelectField } from '../../../components/Form/SelectField';
import FormLabel from '../../../components/UI/FormLabel';
import { Link } from 'react-router-dom';
import { FaLink } from 'react-icons/fa';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../utils/Utils';
import Button from '../../../components/UI/buttons/Button';
import UpdateWorkflowsAdministration from '../../../components/Workflows/UpdateWorkflowsAdministration';
import UpdateWorkflowsConfigurations from '../../../components/Workflows/UpdateWorkflowsConfigurations';
import UpdateWorkflowsExtraFields from '../../../components/Workflows/UpdateWorkflowsExtraFields';
import UpdateWorkflowsPriceLists from '../../../components/Workflows/UpdateWorkflowsPriceLists';
import UpdateWorkflowsRentalLocations from '../../../components/Workflows/UpdateWorkflowsRentalLocations';
import UpdateWorkflowsUserCompanies from '../../../components/Workflows/UpdateWorkflowsUserCompanies';
import UpdateWorkflowsAgreements from '../../../components/Workflows/UpdateWorkflowsAgreements';

const AdminWorkflow = () => {
  const form = useForm();
  const history = useHistory();
  const params = useParams();
  const mode = params.id ? 'edit' : 'create';
  const [workflow, setWorkflow] = useState({ extraFields: [] });
  const [userCompanies, setUserCompanies] = useState([]);
  const search = useLocation().search;

  const [rentalLocations, setRentalLocations] = useState([]);
  const [fieldToUpdate, setFieldToUpdate] = useState(
    new URLSearchParams(search).get('tab') || 'generale',
  );

  const fetchWorkflow = async () => {
    try {
      if (mode === 'edit') {
        const response = await http({ url: `/workflow/${params.id}` });
        setRentalLocations(response.rentalLocations);

        form.setValue('name', response.name);
        form.setValue('description', response.description);
        form.setValue('status', response.status);
        form.setValue('extraFields', response.extraFields);
        form.setValue('initiator', response.initiator);
        form.setValue('workflowType', response.workflowType);
        form.setValue('workflowCorporateClient', response.workflowCorporateClient?._id);
        setWorkflow(response);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const fetchUserCompanies = async () => {
    try {
      const response = await http({ url: `/userCompanies` });

      setUserCompanies(
        response.userCompanies.map((userCompany) => {
          return {
            value: userCompany._id,
            label: `${userCompany.ragioneSociale}`,
          };
        }),
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  useEffect(() => {
    fetchWorkflow();
    fetchUserCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToTab = (tab) => {
    setFieldToUpdate(tab);
    history.push(`/admin/workflows/${params.id}?tab=${tab}`);
  };

  const onSubmit = async (data) => {
    try {
      if (data.extraFields && data.extraFields.length > 0) {
        data.extraFields.map((extraField) => {
          if (extraField.field === '') {
            toast.error('I campi extra non possono essere vuoti');
          }
          if (extraField.dataType === undefined) {
            toast.error('I campi devono avere un tipo');
          }
          if (extraField.requiredField === undefined) {
            extraField.requiredField = false;
          }

          return extraField;
        });
      }
      data.workflowType = data.workflowType || 'movolab';

      if (mode === 'create') {
        const response = await http({
          method: 'POST',
          url: '/workflow',
          form: data,
        });

        history.push(`/admin/workflows/${response._id}?tab=listini`);
        toast.success('Flusso creato con successo');
        await fetchWorkflow();
      } else if (mode === 'edit') {
        await http({
          method: 'PUT',
          url: `/workflow/${params.id}`,
          form: data,
        });
        toast.success('Flusso aggiornato');
        await fetchWorkflow();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <div className="p-4">
        <div className="flex flex-wrap">
          <div className="pb-2 pr-2">
            <Button
              btnStyle={'whiteSkyLightButton'}
              onClick={() => history.push('/admin/workflows')}
            >
              Indietro
            </Button>
          </div>

          {mode === 'edit' && (
            <>
              <div className="pb-2 pr-2">
                <Button
                  selected={fieldToUpdate === 'generale'}
                  btnStyle={'lightGray'}
                  onClick={() => {
                    goToTab('generale');
                  }}
                >
                  Generale
                </Button>
              </div>
              <div className="pb-2 pr-2">
                <Button
                  selected={fieldToUpdate === 'listini'}
                  btnStyle={'lightGray'}
                  onClick={() => {
                    goToTab('listini');
                  }}
                >
                  Listini
                </Button>
              </div>
              <div className="pb-2 pr-2">
                <Button
                  selected={fieldToUpdate === 'puntiNolo'}
                  btnStyle={'lightGray'}
                  onClick={() => {
                    goToTab('puntiNolo');
                  }}
                >
                  Punti Nolo
                </Button>
              </div>
              <div className="pb-2 pr-2">
                <Button
                  selected={fieldToUpdate === 'campiExtra'}
                  btnStyle={'lightGray'}
                  onClick={() => {
                    goToTab('campiExtra');
                  }}
                >
                  Campi Extra
                </Button>
              </div>
              <div className="pb-2 pr-2">
                <Button
                  selected={fieldToUpdate === 'configurazione'}
                  btnStyle={'lightGray'}
                  onClick={() => {
                    goToTab('configurazione');
                  }}
                >
                  Configurazione
                </Button>
              </div>
              <div className="pb-2 pr-2">
                <Button
                  selected={fieldToUpdate === 'amministrazione'}
                  btnStyle={'lightGray'}
                  onClick={() => {
                    goToTab('amministrazione');
                  }}
                >
                  Amministrazione
                </Button>
              </div>
              {workflow.initiator === 'corporate' ? (
                <div className="pb-2 pr-2">
                  <Button
                    selected={fieldToUpdate === 'convenzioni'}
                    btnStyle={'lightGray'}
                    onClick={() => {
                      goToTab('convenzioni');
                    }}
                  >
                    Convenzioni
                  </Button>
                </div>
              ) : (
                <div className="pb-2 pr-2">
                  <Button
                    selected={fieldToUpdate === 'aziendeClienti'}
                    btnStyle={'lightGray'}
                    onClick={() => {
                      goToTab('aziendeClienti');
                    }}
                  >
                    Aziende Clienti
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        <div className="mb-4">
          {!params.id || params.id === 'crea' ? (
            <span className="font-bold">Creazione Flusso</span>
          ) : (
            <>
              <span className="font-semibold">Flusso {workflow.name}:</span>{' '}
              <span>Aggiornamento</span>
            </>
          )}
          {fieldToUpdate === 'generale' && (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <fieldset disabled={form.formState.isSubmitting}>
                <div className="max-w-sm">
                  <FormLabel>Nome</FormLabel>
                  <TextField form={form} name="name" type="text" placeholder="Nome" />
                </div>
                <div className="max-w-sm">
                  <FormLabel>Descrizione</FormLabel>
                  <TextField form={form} name="description" type="text" placeholder="Nome" />
                </div>
                <div className="max-w-sm">
                  <FormLabel>Stato</FormLabel>
                  <SelectField
                    form={form}
                    name="status"
                    placeholder="Stato"
                    options={[
                      { value: 'active', label: 'Attivo' },
                      { value: 'inactive', label: 'Disattivato' },
                    ]}
                  />
                </div>
                <div className="max-w-sm">
                  <FormLabel>Tipo</FormLabel>
                  <SelectField
                    form={form}
                    name="workflowType"
                    placeholder="Tipo"
                    options={[
                      { value: 'movolab', label: 'Movolab' },
                      { value: 'client', label: 'Cliente' },
                    ]}
                  />
                </div>
                {workflow.workflowType === 'client' && (
                  <div className="max-w-sm">
                    <FormLabel>Cliente Associato</FormLabel>
                    <div className="text-md py-2 text-gray-700">
                      <Link to={`/admin/utenti/clientProfile`}>
                        <span className="font-semibold">
                          {' '}
                          {workflow.client?.ragioneSociale || 'Nessun cliente associato'}
                        </span>
                        <FaLink className="inline ml-1 mb-1 text-sm text-blue-600" />
                      </Link>
                    </div>
                  </div>
                )}
                <div className="max-w-sm">
                  <FormLabel>Iniziatore</FormLabel>
                  <p className="text-xs text-gray-700">
                    L'iniziatore è chi inizia il processo di prenotazione o di richiesta di
                    noleggio.
                  </p>
                  <SelectField
                    form={form}
                    name="initiator"
                    placeholder="Iniziatore"
                    options={[
                      { value: 'dashboard', label: 'Azienda Nolo' },
                      { value: 'corporate', label: 'Cliente Corporate' },
                      { value: 'mobileapp', label: 'Utente App' },
                    ]}
                  />
                </div>
                {form.watch('initiator') === 'corporate' && (
                  <div className="max-w-sm bg-gray-100 p-2 mt-3">
                    <FormLabel>Azienda Cliente</FormLabel>
                    <SelectField
                      form={form}
                      name="workflowCorporateClient"
                      placeholder="Cliente Corporate"
                      options={[{ value: '', label: '-- Seleziona --' }, ...userCompanies]}
                    />
                  </div>
                )}

                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-wrap -mx-3 mt-6 ">
                    <div className="w-full px-3">
                      {mode === 'edit' ? (
                        <div className="flex space-x-2">
                          <Button>Aggiorna Flusso</Button>
                        </div>
                      ) : (
                        <Button>Crea Flusso</Button>
                      )}
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          )}
          {fieldToUpdate === 'listini' && <UpdateWorkflowsPriceLists></UpdateWorkflowsPriceLists>}
          {fieldToUpdate === 'puntiNolo' && (
            <UpdateWorkflowsRentalLocations
              rentalLocations={rentalLocations}
            ></UpdateWorkflowsRentalLocations>
          )}
          {fieldToUpdate === 'campiExtra' && (
            <UpdateWorkflowsExtraFields></UpdateWorkflowsExtraFields>
          )}
          {fieldToUpdate === 'configurazione' && (
            <UpdateWorkflowsConfigurations></UpdateWorkflowsConfigurations>
          )}
          {fieldToUpdate === 'amministrazione' && (
            <UpdateWorkflowsAdministration></UpdateWorkflowsAdministration>
          )}
          {fieldToUpdate === 'aziendeClienti' && (
            <UpdateWorkflowsUserCompanies></UpdateWorkflowsUserCompanies>
          )}
          {fieldToUpdate === 'convenzioni' && (
            <UpdateWorkflowsAgreements></UpdateWorkflowsAgreements>
          )}
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminWorkflow;

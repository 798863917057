import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { http } from '../../../utils/Utils';

import { SelectField } from '../../Form/SelectField';
import FormLabel from '../../UI/FormLabel';
import Button from '../../UI/buttons/Button';

const UpdatePackRevenueShare = (props) => {
  const form = useForm();
  const params = useParams();
  const history = useHistory();
  const [pack, setPack] = useState({});

  useEffect(() => {
    fetchPack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchPack = async () => {
    try {
      if (props.mode === 'edit') {
        const response = await http({ url: `/clientPayments/packs/${params.id}` });

        setPack(response);
        form.setValue('movolabRevenueShare', response.movolabRevenueShare);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const onSubmit = async (data) => {
    try {
      await http({
        method: 'PUT',
        url: `/clientPayments/packs/${params.id}`,
        form: data,
      });
      toast.success('Pack aggiornato');
      history.push(`/admin/packs/${params.id}`);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)} className="p-5">
        {!params.id || params.id === 'crea' ? (
          <span className="font-bold">Creazione Pack</span>
        ) : (
          <>
            <span>{pack?.name}:</span> <span className="font-bold">Configurazione Convenzione</span>
          </>
        )}
        <fieldset disabled={form.formState.isSubmitting} className="space-y-0">
          <div className="flex flex-wrap">
            <div className="w-64 mr-2">
              <FormLabel className="block text-gray-700 text-sm font-bold mb-2">
                Percentuale Movolab
              </FormLabel>
              <SelectField
                form={form}
                name="movolabRevenueShare"
                placeholder="Percentuale Movolab"
                validation={{
                  required: {
                    value: true,
                    message: 'Inserisci Percentuale MovoLab',
                  },
                }}
                options={[
                  { label: '0%', value: 0 },
                  { label: '5%', value: 5 },
                  { label: '10%', value: 10 },
                  { label: '15%', value: 15 },
                  { label: '20%', value: 20 },
                  { label: '25%', value: 25 },
                  { label: '30%', value: 30 },
                ]}
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-2">
            <div className="flex flex-wrap -mx-3 mt-6 ">
              <div className="w-full px-3">
                <div className="flex space-x-2">
                  <Button btnStyle="">Aggiorna Pack</Button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default UpdatePackRevenueShare;

import React, { useContext, useEffect, useState } from 'react';
import ProgressBar from '../UI/ProgressBar';
import { http } from '../../utils/Utils';
import toast from 'react-hot-toast';
import WhiteBox from '../UI/WhiteBox';
import Button from '../UI/buttons/Button';
import { UserContext } from '../../store/UserContext';
import { MOVOLAB_ROLE_ADMIN } from '../../utils/Utils';

const ClientUsage = ({ ...props }) => {
  const [monthlyStats, setMonthlyStats] = useState(null);

  const { data: currentClient } = useContext(UserContext);
  const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN;

  const monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];

  const date = new Date(props.month);
  const formattedMonth = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`; // getMonth() is zero-based
  const currentMonthName = monthNames[date.getMonth()]; // getMonth() is zero-based
  const currentYear = date.getFullYear();

  const fetchMonthlyStats = async (month) => {
    try {
      if (isAdmin) {
        const response = await http({
          url: `/clients/stats/monthly?month=${month}&clientId=${props.clientId}`,
        });
        setMonthlyStats(response);
      } else {
        const response = await http({ url: `/clients/stats/monthly?month=${month}` });
        setMonthlyStats(response);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  useEffect(
    () => {
      fetchMonthlyStats(formattedMonth);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!monthlyStats) {
    return null;
  }

  return (
    <WhiteBox
      className="mx-0"
      innerClassName="px-6 py-5"
      isCollapsible="true"
      isExpanded={props.isExpanded}
      headerChildren={(expanded) => (
        <div className="flex gap-x-4 items-center flex-wrap font-bold text-lg min-h-[32px]">
          {'Utilizzo'}
          {!expanded && (
            <div className="px-2">
              {currentMonthName} {currentYear}
            </div>
          )}
        </div>
      )}
      {...props}
    >
      <div className="flex flex-wrap md:flex-nowrap mt-4 gap-4">
        <div className="flex-initial w-full md:w-full p-4 px-5 text-sm bg-slate-200 rounded-lg">
          <div className="flex justify-between items-center">
            <h4 className="text-xl font-bold mb-2">
              Utilizzo per {currentMonthName} {currentYear}
            </h4>
            <Button
              to={`/settings/abbonamento/${formattedMonth}`}
              className="text-white font-bold py-2 px-4 rounded"
            >
              {' '}
              Dettagli
            </Button>
          </div>

          <p>
            <strong className="my-1 inline-block">Punti Nolo</strong>{' '}
            <ProgressBar usage={monthlyStats?.rentalLocationCount} total={6} />
          </p>
          <p>
            <strong className="my-1 inline-block">Veicoli</strong>{' '}
            <ProgressBar usage={monthlyStats?.vehicleCount} total={6} />
          </p>
          <p>
            <strong className="my-1 inline-block">Noleggi Mensili</strong>{' '}
            <ProgressBar usage={monthlyStats?.monthlyRents} total={100} />
          </p>
          <p>
            <strong className="my-1 inline-block">Comodati Mensili</strong>{' '}
            <ProgressBar usage={monthlyStats?.monthlyComodati} total={100} />
          </p>
          <p>
            <strong className="my-1 inline-block">MNP mensili</strong>{' '}
            <ProgressBar usage={monthlyStats?.monthlyMNP} total={100} />
          </p>
        </div>
      </div>
    </WhiteBox>
  );
};

export default ClientUsage;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../../utils/Utils';
import AdminPage from '../../../../components/Admin/AdminPage';
import RangesTable from '../../../../components/Clients/Prices/RangesTable';
import TableHeader from '../../../../components/UI/TableHeader';

const AdminRanges = () => {
  const history = useHistory();
  const [rangesCount, setRangesCount] = useState(0);

  useEffect(() => {
    fetchRanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRanges = async () => {
    try {
      const response = await http({ url: '/pricing/range' });
      setRangesCount(response.count);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <TableHeader
        tableName={'Fasce'}
        buttons={[
          {
            function: () => {
              history.push(`/admin/movolab/fasce/crea`);
            },
            label: '+',
          },
        ]}
        length={rangesCount}
      />
      
      <RangesTable />
    </AdminPage>
  );
};

export default AdminRanges;

import React from 'react';

const Stepper = ({ className = '', stepsClassName = '', steps = [] }) => {    
  return (
    <ol className={`flex items-center text-white text-xs font-semibold ${className}`}>
      {steps.map((props, index) => ( 
        <li className={`flex items-center ${index < steps.length-1 && `after:content-[''] after:w-8 after:border-sky-600 after:border-b after:inline-block`}`} key={index}>
          <span className={`block text-center w-6 h-6 border rounded-full shrink-0 pt-[2px] pr-[1px] ${props.isCurrent ? 'bg-sky-600 border-sky-600' : 'bg-white border-sky-600 text-sky-600'} ${stepsClassName}`}>
            { props.content }
          </span>
        </li>
      ))}
    </ol>
  )
}

export default Stepper;
import React from 'react';
import { Link } from 'react-router-dom';

// import { UserContext } from '../../store/UserContext';
// import { MOVOLAB_ROLE_ADMIN } from '../../utils/Utils';
import DisplayDateTime from '../UI/dates/DisplayDateTime';
import Button from '../UI/buttons/Button';
import ElementLabel from '../UI/ElementLabel';
import { convertPrice } from '../../utils/Prices';
import { getMonthName } from '../../utils/Dates';

const RevenueSharesMonthlyRecapTableItem = (item) => {
  const monthlyRecap = item.monthlyRecap;

  // const { data: currentClient } = useContext(UserContext);
  // const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN;

  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          <Link
            to={`/admin/clienti/anagrafica/${monthlyRecap?.client?._id}`}
            className="text-blue-500 hover:underline"
          >
            {monthlyRecap?.client?.ragioneSociale}
          </Link>{' '}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          {getMonthName(monthlyRecap.month)}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          {monthlyRecap.year}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p>
          {monthlyRecap?.paid ? (
            <ElementLabel bgColor="bg-green-500">Pagato</ElementLabel>
          ) : (
            <ElementLabel bgColor="bg-red-500">Non Pagato</ElementLabel>
          )}{' '}
        </p>
      </td>
      <td className="first:pl-5 w-60 last:pr-5 py-3 whitespace-normal">
        <div className="pr-5">
          {monthlyRecap?.status === 'open' ? (
            <ElementLabel bgColor="bg-yellow-600">In Elaborazione...</ElementLabel>
          ) : (
            <ElementLabel bgColor="bg-green-500">Fatturato</ElementLabel>
          )}
        </div>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-center font-semibold text-gray-600 overflow-hidden">
          {monthlyRecap.revenueShares?.length}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          {monthlyRecap.totalMovolab ? convertPrice(monthlyRecap.totalAmount) : 'N/D'}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          {monthlyRecap.totalMovolab
            ? convertPrice(monthlyRecap.totalAmount - monthlyRecap.totalMovolab)
            : 'N/D'}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-normal">
        <p className="text-left font-semibold text-gray-600 overflow-hidden text-ellipsis">
          <DisplayDateTime date={monthlyRecap.updatedAt} displayType={'flex'} />
        </p>
      </td>
      <td>
        <Button
          to={`/admin/ripartizione-incassi/mensile/${monthlyRecap?._id}`}
          btnStyle="tableItemAction"
        >
          Dettagli &raquo;
        </Button>
      </td>
    </tr>
  );
};

export default RevenueSharesMonthlyRecapTableItem;

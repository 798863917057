import React, { useContext, useEffect, useState } from 'react';
import { http, MOVOLAB_ROLE_ADMIN, CLIENT_ROLE_ADMIN } from '../../utils/Utils';
import { UserContext } from '../../store/UserContext';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SelectField } from '../Form/SelectField';
import { TextField } from '../Form/TextField';
import FormLabel from '../UI/FormLabel';
import Button from '../UI/buttons/Button';

const UpdateWorkflowsAdministration = () => {
  const form = useForm();
  const params = useParams();
  const [workflow] = useState({});

  const { data: currentClient } = useContext(UserContext);
  const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN;
  const isClientAdmin = currentClient?.role === CLIENT_ROLE_ADMIN;
  const allowAdmin =
    isAdmin || (isClientAdmin && workflow?.client?._id === currentClient?.client?._id);

  const maxDays = 60;

  useEffect(() => {
    fetchWorkflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWorkflow = async () => {
    try {
      const response = await http({ url: `/workflow/${params.id}?mode=flat` });
      const data = response.administration;

      form.setValue('faresDiscountMaxPercentage', data.faresDiscountMaxPercentage);
      form.setValue('faresDiscountMaxEuro', data.faresDiscountMaxEuro);
      form.setValue('reservationPrepaidPercentage', data.paymentAdvance);
      form.setValue('prepaidReservation', data.prepaidReservation);
      form.setValue('prepaidRent', data.prepaidRent);
      form.setValue('deposit', data.deposit ? 'true' : 'false');
      form.setValue('depositInvoice', data.depositInvoice ? 'true' : 'false');
      form.setValue('maxDays', data.maxDays);
      form.setValue('prepaidExtra', data.prepaidExtra ? 'true' : 'false');
      form.setValue('faresDiscount', data.faresDiscount ? 'true' : 'false');
      form.setValue('paymentAdvance', data.paymentAdvance);
      form.setValue('noShowFee', data.noShowFee);
      form.setValue('prepaid', data.prepaid ? 'true' : 'false');
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const handlePrepaidChange = (value) => {
    form.setValue('prepaidRent', 0);
    form.setValue('prepaidReservation', 0);
  };

  const handleDepositChange = (value) => {
    form.setValue('depositInvoice', false);
  };

  const handlePrepaidRentChange = (e) => {
    e.preventDefault();
    const prepaid = form.watch('prepaid');
    const prepaidRent = form.watch('prepaidRent');
    const prepaidReservationValue = 100 - prepaidRent;
    form.setValue('prepaidReservation', prepaid ? prepaidReservationValue : 0);
  };

  const handlePrepaidReservationChange = (e) => {
    e.preventDefault();
    const prepaid = form.watch('prepaid');
    const prepaidReservation = form.watch('prepaidReservation');
    const prepaidRentValue = 100 - prepaidReservation;
    form.setValue('prepaidRent', prepaid ? prepaidRentValue : 0);
  };

  const onSubmit = async (data) => {
    try {
      if (!allowAdmin) {
        toast.error('Non hai i permessi per modificare la sezione amministrazione');
        return;
      }

      const update = {
        administration: {
          prepaid: data.prepaid,
          prepaidReservation: data.prepaidReservation,
          prepaidRent: data.prepaidRent,
          deposit: data.deposit,
          depositInvoice: data.depositInvoice,
          maxDays: data.maxDays,
          prepaidExtra: data.prepaidExtra,
          faresDiscount: data.faresDiscount,
          faresDiscountMaxPercentage:
            data.faresDiscount === 'true' ? data.faresDiscountMaxPercentage : 0,
          faresDiscountMaxEuro: data.faresDiscount === 'true' ? data.faresDiscountMaxEuro : 0,
          paymentAdvance: data.paymentAdvance,
          noShowFee: data.noShowFee,
        },
      };

      await http({
        method: 'PUT',
        url: `/workflow/${params.id}`,
        form: update,
      });
      toast.success('Sezione Amministrazione aggiornata con successo');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mt-4">
      <div className="text-lg font-semibold">Amministrazione</div>

      <div className="mt-2">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <fieldset disabled={form.formState.isSubmitting}>
            <div className="flex flex-wrap -mx-3 mt-2 ">
              <div className="w-60 px-3">
                <FormLabel>Prepagato</FormLabel>
                <SelectField
                  name="prepaid"
                  form={form}
                  options={[
                    { value: true, label: 'SI' },
                    { value: false, label: 'NO' },
                  ]}
                  placeholder="Prepagato"
                  onChangeFunction={(value) => handlePrepaidChange(value)}
                  disabled={!allowAdmin}
                />
              </div>
              <>
                <div className="w-60 px-3">
                  <FormLabel>Pagamento alla Prenotazione</FormLabel>
                  <SelectField
                    name="prepaidReservation"
                    form={form}
                    options={[
                      { value: 0, label: '0%' },
                      { value: 100, label: '100%' },
                    ]}
                    onChangeFunction={(e) => handlePrepaidReservationChange(e)}
                    disabled={!allowAdmin || form.watch('prepaid') !== 'true'}
                  />
                </div>
                <div className="w-60 px-3">
                  <FormLabel>Pagamento in Apertura Movo</FormLabel>
                  <SelectField
                    name="prepaidRent"
                    form={form}
                    options={[
                      { value: 0, label: '0%' },
                      { value: 100, label: '100%' },
                    ]}
                    onChangeFunction={(e) => handlePrepaidRentChange(e)}
                    disabled={!allowAdmin || form.watch('prepaid') !== 'true'}
                  />
                </div>
              </>
            </div>
            <div className="flex flex-wrap -mx-3 mt-2 ">
              <div className="w-60 px-3">
                <FormLabel>Deposito</FormLabel>
                <SelectField
                  name="deposit"
                  form={form}
                  options={[
                    { value: true, label: 'SI' },
                    { value: false, label: 'NO' },
                  ]}
                  placeholder="Deposito"
                  onChangeFunction={(value) => handleDepositChange(value)}
                  disabled={!allowAdmin}
                />
              </div>
              <div className="w-60 px-3">
                <FormLabel>Fatturazione Deposito</FormLabel>
                <SelectField
                  name="depositInvoice"
                  form={form}
                  options={[
                    { value: true, label: 'SI' },
                    { value: false, label: 'NO' },
                  ]}
                  placeholder="Fatturazione Deposito"
                  disabled={!allowAdmin || form.watch('deposit') !== 'true'}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-2 ">
              <div className="w-60 px-3">
                <FormLabel>Giorni Max</FormLabel>
                <SelectField
                  name="maxDays"
                  form={form}
                  options={
                    maxDays &&
                    [...Array(maxDays).keys()].map((i) => ({
                      value: i + 1,
                      label: i + 1,
                    }))
                  }
                  placeholder="Giorni Max"
                  disabled={!allowAdmin}
                />
              </div>
              <div className="w-60 px-3">
                <FormLabel>Prepaga Extra</FormLabel>

                <SelectField
                  name="administration.prepaidExtra"
                  form={form}
                  options={[
                    { value: true, label: 'SI' },
                    { value: false, label: 'NO' },
                  ]}
                  placeholder="Prepaga Extra"
                  disabled={!allowAdmin}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-2 ">
              <div className="w-60 px-3">
                <FormLabel>Sconto Tariffe</FormLabel>
                <SelectField
                  name="faresDiscount"
                  form={form}
                  options={[
                    { value: 'true', label: 'SI' },
                    { value: 'false', label: 'NO' },
                  ]}
                  placeholder="Sconto Tariffe"
                  disabled={!allowAdmin}
                />
              </div>
              {form.watch('faresDiscount') && form.watch('faresDiscount') === 'true' && (
                <div className="w-60 px-3">
                  <FormLabel>Sconto Max %</FormLabel>
                  <TextField
                    form={form}
                    name="faresDiscountMaxPercentage"
                    type="number"
                    min={0}
                    max={100}
                    placeholder="Sconto Max %"
                    disabled={!allowAdmin}
                  />
                </div>
              )}
              {form.watch('faresDiscount') && form.watch('faresDiscount') === 'true' && (
                <div className="w-60 px-3">
                  <FormLabel>Sconto Max Euro</FormLabel>
                  <TextField
                    form={form}
                    name="faresDiscountMaxEuro"
                    type="number"
                    min={0}
                    placeholder="Sconto Max Euro"
                    disabled={!allowAdmin}
                  />
                </div>
              )}
            </div>
            {/* <div className="flex flex-wrap -mx-3 mt-2 ">
              <div className="w-60 px-3">
                <FormLabel>Percentuale Anticipo Minima</FormLabel>
                <SelectField
                  name="paymentAdvance"
                  form={form}
                  options={[
                    { value: 0, label: '0%' },
                    { value: 10, label: '10%' },
                    { value: 20, label: '20%' },
                    { value: 30, label: '30%' },
                    { value: 40, label: '40%' },
                    { value: 50, label: '50%' },
                    { value: 60, label: '60%' },
                    { value: 70, label: '70%' },
                    { value: 80, label: '80%' },
                    { value: 90, label: '90%' },
                    { value: 100, label: '100%' },
                  ]}
                  placeholder="Percentuale Anticipo Minima"
                  disabled={!allowAdmin}
                />
              </div>
              <div className="w-60 px-3">
                <FormLabel>Penale No Show</FormLabel>
                <SelectField
                  name="noShowFee"
                  form={form}
                  options={[
                    { value: 0, label: '0%' },
                    { value: 10, label: '10%' },
                    { value: 20, label: '20%' },
                    { value: 30, label: '30%' },
                    { value: 40, label: '40%' },
                    { value: 50, label: '50%' },
                    { value: 60, label: '60%' },
                    { value: 70, label: '70%' },
                    { value: 80, label: '80%' },
                    { value: 90, label: '90%' },
                    { value: 100, label: '100%' },
                  ]}
                  placeholder="% Penale su Importo Movo in Apertura"
                  disabled={!allowAdmin}
                />
              </div>
            </div> */}

            {allowAdmin && (
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-wrap -mx-3 mt-5">
                  <div className="w-full px-3">
                    <Button>Aggiorna</Button>
                  </div>
                </div>
              </div>
            )}
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default UpdateWorkflowsAdministration;

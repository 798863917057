const comuniJson = [
  {
    nome: 'Abano Terme',
    codice: '028001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A001',
    cap: ['35031'],
    popolazione: 19349,
  },
  {
    nome: 'Abbadia Cerreto',
    codice: '098001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'A004',
    cap: ['26834'],
    popolazione: 297,
  },
  {
    nome: 'Abbadia Lariana',
    codice: '097001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A005',
    cap: ['23821'],
    popolazione: 3249,
  },
  {
    nome: 'Abbadia San Salvatore',
    codice: '052001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'A006',
    cap: ['53021'],
    popolazione: 6557,
  },
  {
    nome: 'Abbasanta',
    codice: '095001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A007',
    cap: ['09071'],
    popolazione: 2828,
  },
  {
    nome: 'Abbateggio',
    codice: '068001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'A008',
    cap: ['65020'],
    popolazione: 441,
  },
  {
    nome: 'Abbiategrasso',
    codice: '015002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A010',
    cap: ['20081'],
    popolazione: 30994,
  },
  {
    nome: 'Abetone Cutigliano',
    codice: '047023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'M376',
    cap: ['51024'],
    popolazione: 2248,
  },
  {
    nome: 'Abriola',
    codice: '076001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A013',
    cap: ['85010'],
    popolazione: 1571,
  },
  {
    nome: 'Acate',
    codice: '088001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'A014',
    cap: ['97011'],
    popolazione: 9574,
  },
  {
    nome: 'Accadia',
    codice: '071001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'A015',
    cap: ['71021'],
    popolazione: 2418,
  },
  {
    nome: 'Acceglio',
    codice: '004001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A016',
    cap: ['12021'],
    popolazione: 174,
  },
  {
    nome: 'Accettura',
    codice: '077001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'A017',
    cap: ['75011'],
    popolazione: 1980,
  },
  {
    nome: 'Acciano',
    codice: '066001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A018',
    cap: ['67020'],
    popolazione: 351,
  },
  {
    nome: 'Accumoli',
    codice: '057001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A019',
    cap: ['02011'],
    popolazione: 653,
  },
  {
    nome: 'Acerenza',
    codice: '076002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A020',
    cap: ['85011'],
    popolazione: 2553,
  },
  {
    nome: 'Acerno',
    codice: '065001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A023',
    cap: ['84042'],
    popolazione: 2872,
  },
  {
    nome: 'Acerra',
    codice: '063001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A024',
    cap: ['80011'],
    popolazione: 56465,
  },
  {
    nome: 'Aci Bonaccorsi',
    codice: '087001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A025',
    cap: ['95020'],
    popolazione: 3200,
  },
  {
    nome: 'Aci Castello',
    codice: '087002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A026',
    cap: ['95021'],
    popolazione: 18122,
  },
  {
    nome: 'Aci Catena',
    codice: '087003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A027',
    cap: ['95022'],
    popolazione: 28749,
  },
  {
    nome: "Aci Sant'Antonio",
    codice: '087005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A029',
    cap: ['95025'],
    popolazione: 17270,
  },
  {
    nome: 'Acireale',
    codice: '087004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A028',
    cap: ['95024'],
    popolazione: 51456,
  },
  {
    nome: 'Acquafondata',
    codice: '060001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A032',
    cap: ['03040'],
    popolazione: 282,
  },
  {
    nome: 'Acquaformosa',
    codice: '078001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A033',
    cap: ['87010'],
    popolazione: 1161,
  },
  {
    nome: 'Acquafredda',
    codice: '017001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A034',
    cap: ['25010'],
    popolazione: 1579,
  },
  {
    nome: 'Acqualagna',
    codice: '041001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'A035',
    cap: ['61041'],
    popolazione: 4496,
  },
  {
    nome: 'Acquanegra Cremonese',
    codice: '019001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A039',
    cap: ['26020'],
    popolazione: 1302,
  },
  {
    nome: 'Acquanegra sul Chiese',
    codice: '020001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'A038',
    cap: ['46011'],
    popolazione: 2996,
  },
  {
    nome: 'Acquapendente',
    codice: '056001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A040',
    cap: ['01021'],
    popolazione: 5655,
  },
  {
    nome: 'Acquappesa',
    codice: '078002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A041',
    cap: ['87020'],
    popolazione: 1910,
  },
  {
    nome: 'Acquaro',
    codice: '102001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'A043',
    cap: ['89832'],
    popolazione: 2448,
  },
  {
    nome: 'Acquasanta Terme',
    codice: '044001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'A044',
    cap: ['63095'],
    popolazione: 3050,
  },
  {
    nome: 'Acquasparta',
    codice: '055001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A045',
    cap: ['05021'],
    popolazione: 4929,
  },
  {
    nome: 'Acquaviva Collecroce',
    codice: '070001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'A050',
    cap: ['86030'],
    popolazione: 674,
  },
  {
    nome: "Acquaviva d'Isernia",
    codice: '094001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'A051',
    cap: ['86080'],
    popolazione: 455,
  },
  {
    nome: 'Acquaviva delle Fonti',
    codice: '072001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A048',
    cap: ['70021'],
    popolazione: 21038,
  },
  {
    nome: 'Acquaviva Picena',
    codice: '044002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'A047',
    cap: ['63075'],
    popolazione: 3848,
  },
  {
    nome: 'Acquaviva Platani',
    codice: '085001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'A049',
    cap: ['93010'],
    popolazione: 1041,
  },
  {
    nome: 'Acquedolci',
    codice: '083107',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'M211',
    cap: ['98070'],
    popolazione: 5744,
  },
  {
    nome: 'Acqui Terme',
    codice: '006001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A052',
    cap: ['15011'],
    popolazione: 20054,
  },
  {
    nome: 'Acri',
    codice: '078003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A053',
    cap: ['87041'],
    popolazione: 21458,
  },
  {
    nome: 'Acuto',
    codice: '060002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A054',
    cap: ['03010'],
    popolazione: 1910,
  },
  {
    nome: 'Adelfia',
    codice: '072002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A055',
    cap: ['70010'],
    popolazione: 17101,
  },
  {
    nome: 'Adrano',
    codice: '087006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A056',
    cap: ['95031'],
    popolazione: 35549,
  },
  {
    nome: 'Adrara San Martino',
    codice: '016001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A057',
    cap: ['24060'],
    popolazione: 2161,
  },
  {
    nome: 'Adrara San Rocco',
    codice: '016002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A058',
    cap: ['24060'],
    popolazione: 836,
  },
  {
    nome: 'Adria',
    codice: '029001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'A059',
    cap: ['45011'],
    popolazione: 20233,
  },
  {
    nome: 'Adro',
    codice: '017002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A060',
    cap: ['25030'],
    popolazione: 7114,
  },
  {
    nome: 'Affi',
    codice: '023001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A061',
    cap: ['37010'],
    popolazione: 2297,
  },
  {
    nome: 'Affile',
    codice: '058001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A062',
    cap: ['00021'],
    popolazione: 1552,
  },
  {
    nome: 'Afragola',
    codice: '063002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A064',
    cap: ['80021'],
    popolazione: 63820,
  },
  {
    nome: 'Africo',
    codice: '080001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A065',
    cap: ['89030'],
    popolazione: 3210,
  },
  {
    nome: 'Agazzano',
    codice: '033001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'A067',
    cap: ['29010'],
    popolazione: 2070,
  },
  {
    nome: 'Agerola',
    codice: '063003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A068',
    cap: ['80051'],
    popolazione: 7373,
  },
  {
    nome: 'Aggius',
    codice: '090001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A069',
    cap: ['07020'],
    popolazione: 1602,
  },
  {
    nome: 'Agira',
    codice: '086001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'A070',
    cap: ['94011'],
    popolazione: 8484,
  },
  {
    nome: 'Agliana',
    codice: '047002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'A071',
    cap: ['51031'],
    popolazione: 16792,
  },
  {
    nome: 'Agliano Terme',
    codice: '005001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A072',
    cap: ['14041'],
    popolazione: 1673,
  },
  {
    nome: 'Agliè',
    codice: '001001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A074',
    cap: ['10011'],
    popolazione: 2644,
  },
  {
    nome: 'Aglientu',
    codice: '090062',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'H848',
    cap: ['07020'],
    popolazione: 1171,
  },
  {
    nome: 'Agna',
    codice: '028002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A075',
    cap: ['35021'],
    popolazione: 3400,
  },
  {
    nome: 'Agnadello',
    codice: '019002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A076',
    cap: ['26020'],
    popolazione: 3757,
  },
  {
    nome: 'Agnana Calabra',
    codice: '080002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A077',
    cap: ['89040'],
    popolazione: 569,
  },
  {
    nome: 'Agnone',
    codice: '094002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'A080',
    cap: ['86081'],
    popolazione: 5240,
  },
  {
    nome: 'Agnosine',
    codice: '017003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A082',
    cap: ['25071'],
    popolazione: 1833,
  },
  {
    nome: 'Agordo',
    codice: '025001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A083',
    cap: ['32021'],
    popolazione: 4249,
  },
  {
    nome: 'Agosta',
    codice: '058002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A084',
    cap: ['00020'],
    popolazione: 1760,
  },
  {
    nome: 'Agra',
    codice: '012001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A085',
    cap: ['21010'],
    popolazione: 379,
  },
  {
    nome: 'Agrate Brianza',
    codice: '108001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A087',
    cap: ['20864'],
    popolazione: 14770,
  },
  {
    nome: 'Agrate Conturbia',
    codice: '003001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A088',
    cap: ['28010'],
    popolazione: 1554,
  },
  {
    nome: 'Agrigento',
    codice: '084001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'A089',
    cap: ['92100'],
    popolazione: 58323,
  },
  {
    nome: 'Agropoli',
    codice: '065002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A091',
    cap: ['84043'],
    popolazione: 20610,
  },
  {
    nome: 'Agugliano',
    codice: '042001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'A092',
    cap: ['60020'],
    popolazione: 4870,
  },
  {
    nome: 'Agugliaro',
    codice: '024001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A093',
    cap: ['36020'],
    popolazione: 1422,
  },
  {
    nome: 'Aicurzio',
    codice: '108002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A096',
    cap: ['20886'],
    popolazione: 2067,
  },
  {
    nome: 'Aidomaggiore',
    codice: '095002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A097',
    cap: ['09070'],
    popolazione: 472,
  },
  {
    nome: 'Aidone',
    codice: '086002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'A098',
    cap: ['94010'],
    popolazione: 4929,
  },
  {
    nome: 'Aielli',
    codice: '066002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A100',
    cap: ['67041'],
    popolazione: 1458,
  },
  {
    nome: 'Aiello Calabro',
    codice: '078004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A102',
    cap: ['87031'],
    popolazione: 1907,
  },
  {
    nome: 'Aiello del Friuli',
    codice: '030001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A103',
    cap: ['33041'],
    popolazione: 2272,
  },
  {
    nome: 'Aiello del Sabato',
    codice: '064001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A101',
    cap: ['83020'],
    popolazione: 3971,
  },
  {
    nome: 'Aieta',
    codice: '078005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A105',
    cap: ['87020'],
    popolazione: 839,
  },
  {
    nome: 'Ailano',
    codice: '061001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A106',
    cap: ['81010'],
    popolazione: 1380,
  },
  {
    nome: 'Ailoche',
    codice: '096001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'A107',
    cap: ['13861'],
    popolazione: 330,
  },
  {
    nome: 'Airasca',
    codice: '001002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A109',
    cap: ['10060'],
    popolazione: 3819,
  },
  {
    nome: 'Airola',
    codice: '062001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A110',
    cap: ['82011'],
    popolazione: 8062,
  },
  {
    nome: 'Airole',
    codice: '008001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A111',
    cap: ['18030'],
    popolazione: 461,
  },
  {
    nome: 'Airuno',
    codice: '097002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A112',
    cap: ['23881'],
    popolazione: 2979,
  },
  {
    nome: 'Aisone',
    codice: '004002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A113',
    cap: ['12010'],
    popolazione: 254,
  },
  {
    nome: 'Ala',
    codice: '022001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A116',
    cap: ['38061'],
    popolazione: 8887,
  },
  {
    nome: 'Alà dei Sardi',
    codice: '090002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A115',
    cap: ['07020'],
    popolazione: 1947,
  },
  {
    nome: 'Ala di Stura',
    codice: '001003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A117',
    cap: ['10070'],
    popolazione: 462,
  },
  {
    nome: 'Alagna',
    codice: '018001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A118',
    cap: ['27020'],
    popolazione: 882,
  },
  {
    nome: 'Alagna Valsesia',
    codice: '002002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A119',
    cap: ['13021'],
    popolazione: 671,
  },
  {
    nome: 'Alanno',
    codice: '068002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'A120',
    cap: ['65020'],
    popolazione: 3608,
  },
  {
    nome: 'Alano di Piave',
    codice: '025002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A121',
    cap: ['32031'],
    popolazione: 2926,
  },
  {
    nome: 'Alassio',
    codice: '009001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A122',
    cap: ['17021'],
    popolazione: 11026,
  },
  {
    nome: 'Alatri',
    codice: '060003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A123',
    cap: ['03011'],
    popolazione: 28609,
  },
  {
    nome: 'Alba',
    codice: '004003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A124',
    cap: ['12051'],
    popolazione: 30804,
  },
  {
    nome: 'Alba Adriatica',
    codice: '067001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A125',
    cap: ['64011'],
    popolazione: 11565,
  },
  {
    nome: 'Albagiara',
    codice: '095003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A126',
    cap: ['09090'],
    popolazione: 277,
  },
  {
    nome: 'Albairate',
    codice: '015005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A127',
    cap: ['20080'],
    popolazione: 4621,
  },
  {
    nome: 'Albanella',
    codice: '065003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A128',
    cap: ['84044'],
    popolazione: 6503,
  },
  {
    nome: 'Albano di Lucania',
    codice: '076003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A131',
    cap: ['85010'],
    popolazione: 1474,
  },
  {
    nome: 'Albano Laziale',
    codice: '058003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A132',
    cap: ['00041'],
    popolazione: 38433,
  },
  {
    nome: "Albano Sant'Alessandro",
    codice: '016003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A129',
    cap: ['24061'],
    popolazione: 8029,
  },
  {
    nome: 'Albano Vercellese',
    codice: '002003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A130',
    cap: ['13030'],
    popolazione: 334,
  },
  {
    nome: 'Albaredo Arnaboldi',
    codice: '018002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A134',
    cap: ['27040'],
    popolazione: 225,
  },
  {
    nome: "Albaredo d'Adige",
    codice: '023002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A137',
    cap: ['37041'],
    popolazione: 5232,
  },
  {
    nome: 'Albaredo per San Marco',
    codice: '014001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A135',
    cap: ['23010'],
    popolazione: 349,
  },
  {
    nome: 'Albareto',
    codice: '034001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'A138',
    cap: ['43051'],
    popolazione: 2165,
  },
  {
    nome: 'Albaretto della Torre',
    codice: '004004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A139',
    cap: ['12050'],
    popolazione: 259,
  },
  {
    nome: 'Albavilla',
    codice: '013003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A143',
    cap: ['22031'],
    popolazione: 6255,
  },
  {
    nome: 'Albenga',
    codice: '009002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A145',
    cap: ['17031'],
    popolazione: 23576,
  },
  {
    nome: 'Albera Ligure',
    codice: '006002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A146',
    cap: ['15060'],
    popolazione: 329,
  },
  {
    nome: 'Alberobello',
    codice: '072003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A149',
    cap: ['70011'],
    popolazione: 10924,
  },
  {
    nome: 'Alberona',
    codice: '071002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'A150',
    cap: ['71031'],
    popolazione: 1002,
  },
  {
    nome: 'Albese con Cassano',
    codice: '013004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A153',
    cap: ['22032'],
    popolazione: 4164,
  },
  {
    nome: 'Albettone',
    codice: '024002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A154',
    cap: ['36020'],
    popolazione: 2066,
  },
  {
    nome: 'Albi',
    codice: '079002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A155',
    cap: ['88055'],
    popolazione: 1010,
  },
  {
    nome: 'Albiano',
    codice: '022002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A158',
    cap: ['38041'],
    popolazione: 1508,
  },
  {
    nome: "Albiano d'Ivrea",
    codice: '001004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A157',
    cap: ['10010'],
    popolazione: 1791,
  },
  {
    nome: 'Albiate',
    codice: '108003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A159',
    cap: ['20847'],
    popolazione: 6190,
  },
  {
    nome: 'Albidona',
    codice: '078006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A160',
    cap: ['87070'],
    popolazione: 1463,
  },
  {
    nome: 'Albignasego',
    codice: '028003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A161',
    cap: ['35020'],
    popolazione: 23464,
  },
  {
    nome: 'Albinea',
    codice: '035001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'A162',
    cap: ['42020'],
    popolazione: 8755,
  },
  {
    nome: 'Albino',
    codice: '016004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A163',
    cap: ['24021'],
    popolazione: 18087,
  },
  {
    nome: 'Albiolo',
    codice: '013005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A164',
    cap: ['22070'],
    popolazione: 2679,
  },
  {
    nome: 'Albisola Superiore',
    codice: '009004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A166',
    cap: ['17011'],
    popolazione: 10407,
  },
  {
    nome: 'Albissola Marina',
    codice: '009003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A165',
    cap: ['17012'],
    popolazione: 5564,
  },
  {
    nome: 'Albizzate',
    codice: '012002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A167',
    cap: ['21041'],
    popolazione: 5292,
  },
  {
    nome: 'Albonese',
    codice: '018003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A171',
    cap: ['27020'],
    popolazione: 565,
  },
  {
    nome: 'Albosaggia',
    codice: '014002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A172',
    cap: ['23010'],
    popolazione: 3146,
  },
  {
    nome: 'Albugnano',
    codice: '005002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A173',
    cap: ['14022'],
    popolazione: 541,
  },
  {
    nome: 'Albuzzano',
    codice: '018004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A175',
    cap: ['27010'],
    popolazione: 3355,
  },
  {
    nome: 'Alcamo',
    codice: '081001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'A176',
    cap: ['91011'],
    popolazione: 45314,
  },
  {
    nome: 'Alcara li Fusi',
    codice: '083001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'A177',
    cap: ['98070'],
    popolazione: 2072,
  },
  {
    nome: 'Aldeno',
    codice: '022003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A178',
    cap: ['38060'],
    popolazione: 3036,
  },
  {
    nome: 'Aldino',
    codice: '021001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A179',
    cap: ['39040'],
    popolazione: 1651,
  },
  {
    nome: 'Ales',
    codice: '095004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A180',
    cap: ['09091'],
    popolazione: 1515,
  },
  {
    nome: 'Alessandria',
    codice: '006003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A182',
    cap: ['15121', '15122'],
    popolazione: 89411,
  },
  {
    nome: 'Alessandria del Carretto',
    codice: '078007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A183',
    cap: ['87070'],
    popolazione: 530,
  },
  {
    nome: 'Alessandria della Rocca',
    codice: '084002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'A181',
    cap: ['92010'],
    popolazione: 3118,
  },
  {
    nome: 'Alessano',
    codice: '075002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A184',
    cap: ['73031'],
    popolazione: 6480,
  },
  {
    nome: 'Alezio',
    codice: '075003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A185',
    cap: ['73011'],
    popolazione: 5611,
  },
  {
    nome: 'Alfano',
    codice: '065004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A186',
    cap: ['84040'],
    popolazione: 1097,
  },
  {
    nome: 'Alfedena',
    codice: '066003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A187',
    cap: ['67030'],
    popolazione: 785,
  },
  {
    nome: 'Alfianello',
    codice: '017004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A188',
    cap: ['25020'],
    popolazione: 2451,
  },
  {
    nome: 'Alfiano Natta',
    codice: '006004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A189',
    cap: ['15021'],
    popolazione: 754,
  },
  {
    nome: 'Alfonsine',
    codice: '039001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'A191',
    cap: ['48011'],
    popolazione: 12245,
  },
  {
    nome: 'Alghero',
    codice: '090003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A192',
    cap: ['07041'],
    popolazione: 40641,
  },
  {
    nome: 'Algua',
    codice: '016248',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A193',
    cap: ['24010'],
    popolazione: 721,
  },
  {
    nome: 'Alì',
    codice: '083002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'A194',
    cap: ['98020'],
    popolazione: 823,
  },
  {
    nome: 'Alì Terme',
    codice: '083003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'A201',
    cap: ['98021'],
    popolazione: 2567,
  },
  {
    nome: 'Alia',
    codice: '082001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A195',
    cap: ['90021'],
    popolazione: 3806,
  },
  {
    nome: 'Aliano',
    codice: '077002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'A196',
    cap: ['75010'],
    popolazione: 1082,
  },
  {
    nome: 'Alice Bel Colle',
    codice: '006005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A197',
    cap: ['15010'],
    popolazione: 774,
  },
  {
    nome: 'Alice Castello',
    codice: '002004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A198',
    cap: ['13040'],
    popolazione: 2721,
  },
  {
    nome: 'Alife',
    codice: '061002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A200',
    cap: ['81011'],
    popolazione: 7660,
  },
  {
    nome: 'Alimena',
    codice: '082002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A202',
    cap: ['90020'],
    popolazione: 2152,
  },
  {
    nome: 'Aliminusa',
    codice: '082003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A203',
    cap: ['90020'],
    popolazione: 1291,
  },
  {
    nome: 'Allai',
    codice: '095005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A204',
    cap: ['09080'],
    popolazione: 370,
  },
  {
    nome: 'Alleghe',
    codice: '025003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A206',
    cap: ['32022'],
    popolazione: 1331,
  },
  {
    nome: 'Allein',
    codice: '007001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A205',
    cap: ['11010'],
    popolazione: 248,
  },
  {
    nome: 'Allerona',
    codice: '055002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A207',
    cap: ['05011'],
    popolazione: 1859,
  },
  {
    nome: 'Alliste',
    codice: '075004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A208',
    cap: ['73040'],
    popolazione: 6657,
  },
  {
    nome: 'Allumiere',
    codice: '058004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A210',
    cap: ['00051'],
    popolazione: 4133,
  },
  {
    nome: 'Alluvioni Piovera',
    codice: '006192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M397',
    cap: ['15047'],
    popolazione: 1791,
  },
  {
    nome: 'Almè',
    codice: '016005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A214',
    cap: ['24011'],
    popolazione: 5665,
  },
  {
    nome: 'Almenno San Bartolomeo',
    codice: '016006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A216',
    cap: ['24030'],
    popolazione: 6030,
  },
  {
    nome: 'Almenno San Salvatore',
    codice: '016007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A217',
    cap: ['24031'],
    popolazione: 5831,
  },
  {
    nome: 'Almese',
    codice: '001006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A218',
    cap: ['10040'],
    popolazione: 6303,
  },
  {
    nome: 'Alonte',
    codice: '024003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A220',
    cap: ['36045'],
    popolazione: 1647,
  },
  {
    nome: 'Alpago',
    codice: '025072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M375',
    cap: ['32016'],
    popolazione: 7175,
  },
  {
    nome: 'Alpette',
    codice: '001007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A221',
    cap: ['10080'],
    popolazione: 277,
  },
  {
    nome: 'Alpignano',
    codice: '001008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A222',
    cap: ['10091'],
    popolazione: 16893,
  },
  {
    nome: 'Alseno',
    codice: '033002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'A223',
    cap: ['29010'],
    popolazione: 4823,
  },
  {
    nome: 'Alserio',
    codice: '013006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A224',
    cap: ['22040'],
    popolazione: 1176,
  },
  {
    nome: 'Alta Val Tidone',
    codice: '033049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'M386',
    cap: ['29031'],
    popolazione: 3349,
  },
  {
    nome: 'Alta Valle Intelvi',
    codice: '013253',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M383',
    cap: ['22024'],
    popolazione: 2835,
  },
  {
    nome: 'Altamura',
    codice: '072004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A225',
    cap: ['70022'],
    popolazione: 69529,
  },
  {
    nome: 'Altare',
    codice: '009005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A226',
    cap: ['17041'],
    popolazione: 2127,
  },
  {
    nome: 'Altavalle',
    codice: '022235',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M349',
    cap: ['38092'],
    popolazione: 1640,
  },
  {
    nome: 'Altavilla Irpina',
    codice: '064002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A228',
    cap: ['83011'],
    popolazione: 4280,
  },
  {
    nome: 'Altavilla Milicia',
    codice: '082004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A229',
    cap: ['90010'],
    popolazione: 7429,
  },
  {
    nome: 'Altavilla Monferrato',
    codice: '006007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A227',
    cap: ['15041'],
    popolazione: 497,
  },
  {
    nome: 'Altavilla Silentina',
    codice: '065005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A230',
    cap: ['84045'],
    popolazione: 6997,
  },
  {
    nome: 'Altavilla Vicentina',
    codice: '024004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A231',
    cap: ['36077'],
    popolazione: 11760,
  },
  {
    nome: 'Altidona',
    codice: '109001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'A233',
    cap: ['63824'],
    popolazione: 3234,
  },
  {
    nome: 'Altilia',
    codice: '078008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A234',
    cap: ['87040'],
    popolazione: 737,
  },
  {
    nome: 'Altino',
    codice: '069001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'A235',
    cap: ['66040'],
    popolazione: 2833,
  },
  {
    nome: 'Altissimo',
    codice: '024005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A236',
    cap: ['36070'],
    popolazione: 2266,
  },
  {
    nome: 'Altivole',
    codice: '026001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'A237',
    cap: ['31030'],
    popolazione: 6720,
  },
  {
    nome: 'Alto',
    codice: '004005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A238',
    cap: ['12070'],
    popolazione: 121,
  },
  {
    nome: 'Alto Reno Terme',
    codice: '037062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'M369',
    cap: ['40046'],
    popolazione: 6967,
  },
  {
    nome: 'Alto Sermenza',
    codice: '002170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'M389',
    cap: ['13029'],
    popolazione: 190,
  },
  {
    nome: 'Altofonte',
    codice: '082005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A239',
    cap: ['90030'],
    popolazione: 10266,
  },
  {
    nome: 'Altomonte',
    codice: '078009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A240',
    cap: ['87042'],
    popolazione: 4341,
  },
  {
    nome: 'Altopascio',
    codice: '046001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'A241',
    cap: ['55011'],
    popolazione: 15072,
  },
  {
    nome: 'Altopiano della Vigolana',
    codice: '022236',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M350',
    cap: ['38049'],
    popolazione: 4802,
  },
  {
    nome: 'Alviano',
    codice: '055003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A242',
    cap: ['05020'],
    popolazione: 1514,
  },
  {
    nome: 'Alvignano',
    codice: '061003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A243',
    cap: ['81012'],
    popolazione: 4914,
  },
  {
    nome: 'Alvito',
    codice: '060004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A244',
    cap: ['03041'],
    popolazione: 2852,
  },
  {
    nome: 'Alzano Lombardo',
    codice: '016008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A246',
    cap: ['24022'],
    popolazione: 13591,
  },
  {
    nome: 'Alzano Scrivia',
    codice: '006008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A245',
    cap: ['15050'],
    popolazione: 380,
  },
  {
    nome: 'Alzate Brianza',
    codice: '013007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A249',
    cap: ['22040'],
    popolazione: 5019,
  },
  {
    nome: 'Amalfi',
    codice: '065006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A251',
    cap: ['84011'],
    popolazione: 5163,
  },
  {
    nome: 'Amandola',
    codice: '109002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'A252',
    cap: ['63857'],
    popolazione: 3709,
  },
  {
    nome: 'Amantea',
    codice: '078010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A253',
    cap: ['87032'],
    popolazione: 13754,
  },
  {
    nome: 'Amaro',
    codice: '030002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A254',
    cap: ['33020'],
    popolazione: 841,
  },
  {
    nome: 'Amaroni',
    codice: '079003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A255',
    cap: ['88050'],
    popolazione: 1885,
  },
  {
    nome: 'Amaseno',
    codice: '060005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A256',
    cap: ['03021'],
    popolazione: 4314,
  },
  {
    nome: 'Amato',
    codice: '079004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A257',
    cap: ['88040'],
    popolazione: 837,
  },
  {
    nome: 'Amatrice',
    codice: '057002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A258',
    cap: ['02012'],
    popolazione: 2646,
  },
  {
    nome: 'Ambivere',
    codice: '016009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A259',
    cap: ['24030'],
    popolazione: 2354,
  },
  {
    nome: 'Amblar-Don',
    codice: '022237',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M351',
    cap: ['38011'],
    popolazione: 478,
  },
  {
    nome: 'Ameglia',
    codice: '011001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'A261',
    cap: ['19031'],
    popolazione: 4484,
  },
  {
    nome: 'Amelia',
    codice: '055004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A262',
    cap: ['05022'],
    popolazione: 11781,
  },
  {
    nome: 'Amendolara',
    codice: '078011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A263',
    cap: ['87071'],
    popolazione: 3001,
  },
  {
    nome: 'Ameno',
    codice: '003002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A264',
    cap: ['28010'],
    popolazione: 874,
  },
  {
    nome: 'Amorosi',
    codice: '062002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A265',
    cap: ['82031'],
    popolazione: 2836,
  },
  {
    nome: 'Ampezzo',
    codice: '030003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A267',
    cap: ['33021'],
    popolazione: 1030,
  },
  {
    nome: 'Anacapri',
    codice: '063004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A268',
    cap: ['80071'],
    popolazione: 6546,
  },
  {
    nome: 'Anagni',
    codice: '060006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A269',
    cap: ['03012'],
    popolazione: 21441,
  },
  {
    nome: 'Ancarano',
    codice: '067002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A270',
    cap: ['64010'],
    popolazione: 1877,
  },
  {
    nome: 'Ancona',
    codice: '042002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'A271',
    cap: [
      '60121',
      '60122',
      '60123',
      '60124',
      '60125',
      '60126',
      '60127',
      '60128',
      '60129',
      '60130',
      '60131',
    ],
    popolazione: 100497,
  },
  {
    nome: 'Andali',
    codice: '079005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A272',
    cap: ['88050'],
    popolazione: 795,
  },
  {
    nome: 'Andalo',
    codice: '022005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A274',
    cap: ['38010'],
    popolazione: 1026,
  },
  {
    nome: 'Andalo Valtellino',
    codice: '014003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A273',
    cap: ['23014'],
    popolazione: 552,
  },
  {
    nome: 'Andezeno',
    codice: '001009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A275',
    cap: ['10020'],
    popolazione: 1966,
  },
  {
    nome: 'Andora',
    codice: '009006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A278',
    cap: ['17051'],
    popolazione: 7470,
  },
  {
    nome: 'Andorno Micca',
    codice: '096002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'A280',
    cap: ['13811'],
    popolazione: 3407,
  },
  {
    nome: 'Andrano',
    codice: '075005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A281',
    cap: ['73032'],
    popolazione: 4962,
  },
  {
    nome: 'Andrate',
    codice: '001010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A282',
    cap: ['10010'],
    popolazione: 512,
  },
  {
    nome: 'Andreis',
    codice: '093001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'A283',
    cap: ['33080'],
    popolazione: 282,
  },
  {
    nome: 'Andretta',
    codice: '064003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A284',
    cap: ['83040'],
    popolazione: 2056,
  },
  {
    nome: 'Andria',
    codice: '110001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'A285',
    cap: ['76123'],
    popolazione: 100052,
  },
  {
    nome: 'Andriano',
    codice: '021002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A286',
    cap: ['39010'],
    popolazione: 1035,
  },
  {
    nome: 'Anela',
    codice: '090004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A287',
    cap: ['07010'],
    popolazione: 673,
  },
  {
    nome: 'Anfo',
    codice: '017005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A288',
    cap: ['25070'],
    popolazione: 472,
  },
  {
    nome: 'Angera',
    codice: '012003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A290',
    cap: ['21021'],
    popolazione: 5622,
  },
  {
    nome: 'Anghiari',
    codice: '051001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'A291',
    cap: ['52031'],
    popolazione: 5672,
  },
  {
    nome: 'Angiari',
    codice: '023003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A292',
    cap: ['37050'],
    popolazione: 2164,
  },
  {
    nome: 'Angolo Terme',
    codice: '017006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A293',
    cap: ['25040'],
    popolazione: 2503,
  },
  {
    nome: 'Angri',
    codice: '065007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A294',
    cap: ['84012'],
    popolazione: 32576,
  },
  {
    nome: 'Angrogna',
    codice: '001011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A295',
    cap: ['10060'],
    popolazione: 870,
  },
  {
    nome: 'Anguillara Sabazia',
    codice: '058005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A297',
    cap: ['00061'],
    popolazione: 18575,
  },
  {
    nome: 'Anguillara Veneta',
    codice: '028004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A296',
    cap: ['35022'],
    popolazione: 4594,
  },
  {
    nome: 'Annicco',
    codice: '019003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A299',
    cap: ['26021'],
    popolazione: 2075,
  },
  {
    nome: 'Annone di Brianza',
    codice: '097003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A301',
    cap: ['23841'],
    popolazione: 2292,
  },
  {
    nome: 'Annone Veneto',
    codice: '027001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'A302',
    cap: ['30020'],
    popolazione: 3976,
  },
  {
    nome: 'Anoia',
    codice: '080003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A303',
    cap: ['89020'],
    popolazione: 2246,
  },
  {
    nome: 'Antegnate',
    codice: '016010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A304',
    cap: ['24051'],
    popolazione: 3107,
  },
  {
    nome: 'Anterivo',
    codice: '021003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A306',
    cap: ['39040'],
    popolazione: 383,
  },
  {
    nome: 'Antey-Saint-André',
    codice: '007002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A305',
    cap: ['11020'],
    popolazione: 626,
  },
  {
    nome: 'Anticoli Corrado',
    codice: '058006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A309',
    cap: ['00022'],
    popolazione: 942,
  },
  {
    nome: 'Antignano',
    codice: '005003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A312',
    cap: ['14010'],
    popolazione: 1025,
  },
  {
    nome: 'Antillo',
    codice: '083004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'A313',
    cap: ['98030'],
    popolazione: 992,
  },
  {
    nome: 'Antonimina',
    codice: '080004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A314',
    cap: ['89040'],
    popolazione: 1361,
  },
  {
    nome: 'Antrodoco',
    codice: '057003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A315',
    cap: ['02013'],
    popolazione: 2704,
  },
  {
    nome: 'Antrona Schieranco',
    codice: '103001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A317',
    cap: ['28841'],
    popolazione: 467,
  },
  {
    nome: 'Anversa degli Abruzzi',
    codice: '066004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A318',
    cap: ['67030'],
    popolazione: 368,
  },
  {
    nome: 'Anzano del Parco',
    codice: '013009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A319',
    cap: ['22040'],
    popolazione: 1757,
  },
  {
    nome: 'Anzano di Puglia',
    codice: '071003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'A320',
    cap: ['71020'],
    popolazione: 1617,
  },
  {
    nome: 'Anzi',
    codice: '076004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A321',
    cap: ['85010'],
    popolazione: 1765,
  },
  {
    nome: 'Anzio',
    codice: '058007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A323',
    cap: ['00042'],
    popolazione: 49731,
  },
  {
    nome: "Anzola d'Ossola",
    codice: '103002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A325',
    cap: ['28877'],
    popolazione: 448,
  },
  {
    nome: "Anzola dell'Emilia",
    codice: '037001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'A324',
    cap: ['40011'],
    popolazione: 11851,
  },
  {
    nome: 'Aosta',
    codice: '007003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A326',
    cap: ['11100'],
    popolazione: 34102,
  },
  {
    nome: 'Apecchio',
    codice: '041002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'A327',
    cap: ['61042'],
    popolazione: 2013,
  },
  {
    nome: 'Apice',
    codice: '062003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A328',
    cap: ['82021'],
    popolazione: 5802,
  },
  {
    nome: 'Apiro',
    codice: '043002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'A329',
    cap: ['62021'],
    popolazione: 2421,
  },
  {
    nome: 'Apollosa',
    codice: '062004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A330',
    cap: ['82030'],
    popolazione: 2697,
  },
  {
    nome: 'Appiano Gentile',
    codice: '013010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A333',
    cap: ['22070'],
    popolazione: 7718,
  },
  {
    nome: 'Appiano sulla strada del vino',
    codice: '021004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A332',
    cap: ['39057'],
    popolazione: 13988,
  },
  {
    nome: 'Appignano',
    codice: '043003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'A334',
    cap: ['62010'],
    popolazione: 4212,
  },
  {
    nome: 'Appignano del Tronto',
    codice: '044005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'A335',
    cap: ['63083'],
    popolazione: 1852,
  },
  {
    nome: 'Aprica',
    codice: '014004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A337',
    cap: ['23031'],
    popolazione: 1588,
  },
  {
    nome: 'Apricale',
    codice: '008002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A338',
    cap: ['18035'],
    popolazione: 625,
  },
  {
    nome: 'Apricena',
    codice: '071004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'A339',
    cap: ['71011'],
    popolazione: 13435,
  },
  {
    nome: 'Aprigliano',
    codice: '078012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A340',
    cap: ['87051'],
    popolazione: 2968,
  },
  {
    nome: 'Aprilia',
    codice: '059001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'A341',
    cap: ['04011'],
    popolazione: 66979,
  },
  {
    nome: 'Aquara',
    codice: '065008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A343',
    cap: ['84020'],
    popolazione: 1550,
  },
  {
    nome: "Aquila d'Arroscia",
    codice: '008003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A344',
    cap: ['18020'],
    popolazione: 176,
  },
  {
    nome: 'Aquileia',
    codice: '030004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A346',
    cap: ['33051'],
    popolazione: 3441,
  },
  {
    nome: 'Aquilonia',
    codice: '064004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A347',
    cap: ['83041'],
    popolazione: 1815,
  },
  {
    nome: 'Aquino',
    codice: '060007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A348',
    cap: ['03031'],
    popolazione: 5309,
  },
  {
    nome: 'Aradeo',
    codice: '075006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A350',
    cap: ['73040'],
    popolazione: 9755,
  },
  {
    nome: 'Aragona',
    codice: '084003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'A351',
    cap: ['92021'],
    popolazione: 9493,
  },
  {
    nome: 'Aramengo',
    codice: '005004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A352',
    cap: ['14020'],
    popolazione: 632,
  },
  {
    nome: 'Arba',
    codice: '093002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'A354',
    cap: ['33090'],
    popolazione: 1309,
  },
  {
    nome: 'Arborea',
    codice: '095006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A357',
    cap: ['09092'],
    popolazione: 4048,
  },
  {
    nome: 'Arborio',
    codice: '002006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A358',
    cap: ['13031'],
    popolazione: 909,
  },
  {
    nome: 'Arbus',
    codice: '111001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'A359',
    cap: ['09031'],
    popolazione: 6575,
  },
  {
    nome: 'Arcade',
    codice: '026002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'A360',
    cap: ['31030'],
    popolazione: 4366,
  },
  {
    nome: 'Arce',
    codice: '060008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A363',
    cap: ['03032'],
    popolazione: 5783,
  },
  {
    nome: 'Arcene',
    codice: '016011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A365',
    cap: ['24040'],
    popolazione: 4731,
  },
  {
    nome: 'Arcevia',
    codice: '042003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'A366',
    cap: ['60011'],
    popolazione: 4914,
  },
  {
    nome: 'Archi',
    codice: '069002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'A367',
    cap: ['66044'],
    popolazione: 2282,
  },
  {
    nome: 'Arcidosso',
    codice: '053001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'A369',
    cap: ['58031'],
    popolazione: 4313,
  },
  {
    nome: 'Arcinazzo Romano',
    codice: '058008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A370',
    cap: ['00020'],
    popolazione: 1394,
  },
  {
    nome: 'Arcisate',
    codice: '012004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A371',
    cap: ['21051'],
    popolazione: 9850,
  },
  {
    nome: 'Arco',
    codice: '022006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A372',
    cap: ['38062'],
    popolazione: 16871,
  },
  {
    nome: 'Arcola',
    codice: '011002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'A373',
    cap: ['19021'],
    popolazione: 10316,
  },
  {
    nome: 'Arcole',
    codice: '023004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A374',
    cap: ['37040'],
    popolazione: 6144,
  },
  {
    nome: 'Arconate',
    codice: '015007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A375',
    cap: ['20020'],
    popolazione: 6524,
  },
  {
    nome: 'Arcore',
    codice: '108004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A376',
    cap: ['20862'],
    popolazione: 17285,
  },
  {
    nome: 'Arcugnano',
    codice: '024006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A377',
    cap: ['36057'],
    popolazione: 7899,
  },
  {
    nome: 'Ardara',
    codice: '090005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A379',
    cap: ['07010'],
    popolazione: 800,
  },
  {
    nome: 'Ardauli',
    codice: '095007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A380',
    cap: ['09081'],
    popolazione: 946,
  },
  {
    nome: 'Ardea',
    codice: '058117',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M213',
    cap: ['00040'],
    popolazione: 44202,
  },
  {
    nome: 'Ardenno',
    codice: '014005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A382',
    cap: ['23011'],
    popolazione: 3270,
  },
  {
    nome: 'Ardesio',
    codice: '016012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A383',
    cap: ['24020'],
    popolazione: 3632,
  },
  {
    nome: 'Ardore',
    codice: '080005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A385',
    cap: ['89031'],
    popolazione: 4760,
  },
  {
    nome: 'Arena',
    codice: '102002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'A386',
    cap: ['89832'],
    popolazione: 1532,
  },
  {
    nome: 'Arena Po',
    codice: '018005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A387',
    cap: ['27040'],
    popolazione: 1625,
  },
  {
    nome: 'Arenzano',
    codice: '010001',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'A388',
    cap: ['16011'],
    popolazione: 11584,
  },
  {
    nome: 'Arese',
    codice: '015009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A389',
    cap: ['20020'],
    popolazione: 19138,
  },
  {
    nome: 'Arezzo',
    codice: '051002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'A390',
    cap: ['52100'],
    popolazione: 98144,
  },
  {
    nome: 'Argegno',
    codice: '013011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A391',
    cap: ['22010'],
    popolazione: 667,
  },
  {
    nome: 'Argelato',
    codice: '037002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'A392',
    cap: ['40050'],
    popolazione: 9656,
  },
  {
    nome: 'Argenta',
    codice: '038001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'A393',
    cap: ['44011'],
    popolazione: 22133,
  },
  {
    nome: 'Argentera',
    codice: '004006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A394',
    cap: ['12010'],
    popolazione: 79,
  },
  {
    nome: 'Arguello',
    codice: '004007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A396',
    cap: ['12050'],
    popolazione: 201,
  },
  {
    nome: 'Argusto',
    codice: '079007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A397',
    cap: ['88060'],
    popolazione: 529,
  },
  {
    nome: 'Ari',
    codice: '069003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'A398',
    cap: ['66010'],
    popolazione: 1165,
  },
  {
    nome: 'Ariano Irpino',
    codice: '064005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A399',
    cap: ['83031'],
    popolazione: 22517,
  },
  {
    nome: 'Ariano nel Polesine',
    codice: '029002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'A400',
    cap: ['45012'],
    popolazione: 4554,
  },
  {
    nome: 'Ariccia',
    codice: '058009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A401',
    cap: ['00072'],
    popolazione: 18311,
  },
  {
    nome: 'Arielli',
    codice: '069004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'A402',
    cap: ['66030'],
    popolazione: 1144,
  },
  {
    nome: 'Arienzo',
    codice: '061004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A403',
    cap: ['81021'],
    popolazione: 5333,
  },
  {
    nome: 'Arignano',
    codice: '001012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A405',
    cap: ['10020'],
    popolazione: 1039,
  },
  {
    nome: 'Aritzo',
    codice: '091001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A407',
    cap: ['08031'],
    popolazione: 1328,
  },
  {
    nome: 'Arizzano',
    codice: '103003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A409',
    cap: ['28811'],
    popolazione: 2040,
  },
  {
    nome: 'Arlena di Castro',
    codice: '056002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A412',
    cap: ['01010'],
    popolazione: 886,
  },
  {
    nome: 'Arluno',
    codice: '015010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A413',
    cap: ['20010'],
    popolazione: 11495,
  },
  {
    nome: 'Armeno',
    codice: '003006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A414',
    cap: ['28011'],
    popolazione: 2201,
  },
  {
    nome: 'Armento',
    codice: '076005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A415',
    cap: ['85010'],
    popolazione: 679,
  },
  {
    nome: 'Armo',
    codice: '008004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A418',
    cap: ['18026'],
    popolazione: 124,
  },
  {
    nome: 'Armungia',
    codice: '111002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'A419',
    cap: ['09040'],
    popolazione: 489,
  },
  {
    nome: 'Arnad',
    codice: '007004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A424',
    cap: ['11020'],
    popolazione: 1294,
  },
  {
    nome: 'Arnara',
    codice: '060009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A421',
    cap: ['03020'],
    popolazione: 2379,
  },
  {
    nome: 'Arnasco',
    codice: '009007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A422',
    cap: ['17032'],
    popolazione: 630,
  },
  {
    nome: 'Arnesano',
    codice: '075007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A425',
    cap: ['73010'],
    popolazione: 3953,
  },
  {
    nome: 'Arola',
    codice: '103004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A427',
    cap: ['28899'],
    popolazione: 250,
  },
  {
    nome: 'Arona',
    codice: '003008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A429',
    cap: ['28041'],
    popolazione: 14195,
  },
  {
    nome: 'Arosio',
    codice: '013012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A430',
    cap: ['22060'],
    popolazione: 4987,
  },
  {
    nome: 'Arpaia',
    codice: '062005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A431',
    cap: ['82011'],
    popolazione: 2016,
  },
  {
    nome: 'Arpaise',
    codice: '062006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A432',
    cap: ['82010'],
    popolazione: 830,
  },
  {
    nome: 'Arpino',
    codice: '060010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A433',
    cap: ['03033'],
    popolazione: 7386,
  },
  {
    nome: 'Arquà Petrarca',
    codice: '028005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A434',
    cap: ['35032'],
    popolazione: 1847,
  },
  {
    nome: 'Arquà Polesine',
    codice: '029003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'A435',
    cap: ['45031'],
    popolazione: 2811,
  },
  {
    nome: 'Arquata del Tronto',
    codice: '044006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'A437',
    cap: ['63096'],
    popolazione: 1287,
  },
  {
    nome: 'Arquata Scrivia',
    codice: '006009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A436',
    cap: ['15061'],
    popolazione: 6068,
  },
  {
    nome: 'Arre',
    codice: '028006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A438',
    cap: ['35020'],
    popolazione: 2191,
  },
  {
    nome: 'Arrone',
    codice: '055005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A439',
    cap: ['05031'],
    popolazione: 2839,
  },
  {
    nome: 'Arsago Seprio',
    codice: '012005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A441',
    cap: ['21010'],
    popolazione: 4845,
  },
  {
    nome: 'Arsiè',
    codice: '025004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A443',
    cap: ['32030'],
    popolazione: 2465,
  },
  {
    nome: 'Arsiero',
    codice: '024007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A444',
    cap: ['36011'],
    popolazione: 3303,
  },
  {
    nome: 'Arsita',
    codice: '067003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A445',
    cap: ['64031'],
    popolazione: 871,
  },
  {
    nome: 'Arsoli',
    codice: '058010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A446',
    cap: ['00023'],
    popolazione: 1647,
  },
  {
    nome: 'Arta Terme',
    codice: '030005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A447',
    cap: ['33022'],
    popolazione: 2243,
  },
  {
    nome: 'Artegna',
    codice: '030006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A448',
    cap: ['33011'],
    popolazione: 2877,
  },
  {
    nome: 'Artena',
    codice: '058011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A449',
    cap: ['00031'],
    popolazione: 13665,
  },
  {
    nome: 'Artogne',
    codice: '017007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A451',
    cap: ['25040'],
    popolazione: 3571,
  },
  {
    nome: 'Arvier',
    codice: '007005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A452',
    cap: ['11011'],
    popolazione: 892,
  },
  {
    nome: 'Arzachena',
    codice: '090006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A453',
    cap: ['07021'],
    popolazione: 11447,
  },
  {
    nome: "Arzago d'Adda",
    codice: '016013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A440',
    cap: ['24040'],
    popolazione: 2760,
  },
  {
    nome: 'Arzana',
    codice: '091002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A454',
    cap: ['08040'],
    popolazione: 2501,
  },
  {
    nome: 'Arzano',
    codice: '063005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A455',
    cap: ['80022'],
    popolazione: 34933,
  },
  {
    nome: 'Arzergrande',
    codice: '028007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A458',
    cap: ['35020'],
    popolazione: 4682,
  },
  {
    nome: 'Arzignano',
    codice: '024008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A459',
    cap: ['36071'],
    popolazione: 25589,
  },
  {
    nome: 'Ascea',
    codice: '065009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A460',
    cap: ['84046'],
    popolazione: 5580,
  },
  {
    nome: 'Asciano',
    codice: '052002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'A461',
    cap: ['53041'],
    popolazione: 7228,
  },
  {
    nome: 'Ascoli Piceno',
    codice: '044007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'A462',
    cap: ['63100'],
    popolazione: 49958,
  },
  {
    nome: 'Ascoli Satriano',
    codice: '071005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'A463',
    cap: ['71022'],
    popolazione: 6194,
  },
  {
    nome: 'Ascrea',
    codice: '057004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A464',
    cap: ['02020'],
    popolazione: 266,
  },
  {
    nome: 'Asiago',
    codice: '024009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A465',
    cap: ['36012'],
    popolazione: 6391,
  },
  {
    nome: 'Asigliano Veneto',
    codice: '024010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A467',
    cap: ['36020'],
    popolazione: 877,
  },
  {
    nome: 'Asigliano Vercellese',
    codice: '002007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A466',
    cap: ['13032'],
    popolazione: 1401,
  },
  {
    nome: 'Asola',
    codice: '020002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'A470',
    cap: ['46041'],
    popolazione: 10077,
  },
  {
    nome: 'Asolo',
    codice: '026003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'A471',
    cap: ['31011'],
    popolazione: 8952,
  },
  {
    nome: 'Assago',
    codice: '015011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A473',
    cap: ['20090'],
    popolazione: 8124,
  },
  {
    nome: 'Assemini',
    codice: '092003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'A474',
    cap: ['09032'],
    popolazione: 26620,
  },
  {
    nome: 'Assisi',
    codice: '054001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'A475',
    cap: ['06081'],
    popolazione: 27377,
  },
  {
    nome: 'Asso',
    codice: '013013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A476',
    cap: ['22033'],
    popolazione: 3625,
  },
  {
    nome: 'Assolo',
    codice: '095008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A477',
    cap: ['09080'],
    popolazione: 434,
  },
  {
    nome: 'Assoro',
    codice: '086003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'A478',
    cap: ['94010'],
    popolazione: 5366,
  },
  {
    nome: 'Asti',
    codice: '005005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A479',
    cap: ['14100'],
    popolazione: 73899,
  },
  {
    nome: 'Asuni',
    codice: '095009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A480',
    cap: ['09080'],
    popolazione: 357,
  },
  {
    nome: 'Ateleta',
    codice: '066005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A481',
    cap: ['67030'],
    popolazione: 1153,
  },
  {
    nome: 'Atella',
    codice: '076006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A482',
    cap: ['85020'],
    popolazione: 3863,
  },
  {
    nome: 'Atena Lucana',
    codice: '065010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A484',
    cap: ['84030'],
    popolazione: 2288,
  },
  {
    nome: 'Atessa',
    codice: '069005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'A485',
    cap: ['66041'],
    popolazione: 10761,
  },
  {
    nome: 'Atina',
    codice: '060011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A486',
    cap: ['03042'],
    popolazione: 4461,
  },
  {
    nome: 'Atrani',
    codice: '065011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A487',
    cap: ['84010'],
    popolazione: 887,
  },
  {
    nome: 'Atri',
    codice: '067004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A488',
    cap: ['64032'],
    popolazione: 11112,
  },
  {
    nome: 'Atripalda',
    codice: '064006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A489',
    cap: ['83042'],
    popolazione: 10926,
  },
  {
    nome: 'Attigliano',
    codice: '055006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A490',
    cap: ['05012'],
    popolazione: 1917,
  },
  {
    nome: 'Attimis',
    codice: '030007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A491',
    cap: ['33040'],
    popolazione: 1861,
  },
  {
    nome: 'Atzara',
    codice: '091003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A492',
    cap: ['08030'],
    popolazione: 1210,
  },
  {
    nome: 'Augusta',
    codice: '089001',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'A494',
    cap: ['96011'],
    popolazione: 36169,
  },
  {
    nome: 'Auletta',
    codice: '065012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A495',
    cap: ['84031'],
    popolazione: 2406,
  },
  {
    nome: 'Aulla',
    codice: '045001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'A496',
    cap: ['54011'],
    popolazione: 11284,
  },
  {
    nome: 'Aurano',
    codice: '103005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A497',
    cap: ['28812'],
    popolazione: 103,
  },
  {
    nome: 'Aurigo',
    codice: '008005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A499',
    cap: ['18020'],
    popolazione: 346,
  },
  {
    nome: 'Auronzo di Cadore',
    codice: '025005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A501',
    cap: ['32041'],
    popolazione: 3453,
  },
  {
    nome: 'Ausonia',
    codice: '060012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A502',
    cap: ['03040'],
    popolazione: 2650,
  },
  {
    nome: 'Austis',
    codice: '091004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A503',
    cap: ['08030'],
    popolazione: 876,
  },
  {
    nome: 'Avegno',
    codice: '010002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'A506',
    cap: ['16036'],
    popolazione: 2539,
  },
  {
    nome: 'Avelengo',
    codice: '021005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A507',
    cap: ['39010'],
    popolazione: 739,
  },
  {
    nome: 'Avella',
    codice: '064007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A508',
    cap: ['83021'],
    popolazione: 7788,
  },
  {
    nome: 'Avellino',
    codice: '064008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A509',
    cap: ['83100'],
    popolazione: 54222,
  },
  {
    nome: 'Averara',
    codice: '016014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A511',
    cap: ['24010'],
    popolazione: 182,
  },
  {
    nome: 'Aversa',
    codice: '061005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A512',
    cap: ['81031'],
    popolazione: 52830,
  },
  {
    nome: 'Avetrana',
    codice: '073001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'A514',
    cap: ['74020'],
    popolazione: 7024,
  },
  {
    nome: 'Avezzano',
    codice: '066006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A515',
    cap: ['67051'],
    popolazione: 40744,
  },
  {
    nome: 'Aviano',
    codice: '093004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'A516',
    cap: ['33081'],
    popolazione: 9025,
  },
  {
    nome: 'Aviatico',
    codice: '016015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A517',
    cap: ['24020'],
    popolazione: 517,
  },
  {
    nome: 'Avigliana',
    codice: '001013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A518',
    cap: ['10051'],
    popolazione: 12129,
  },
  {
    nome: 'Avigliano',
    codice: '076007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A519',
    cap: ['85021'],
    popolazione: 11796,
  },
  {
    nome: 'Avigliano Umbro',
    codice: '055033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'M258',
    cap: ['05020'],
    popolazione: 2568,
  },
  {
    nome: 'Avio',
    codice: '022007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A520',
    cap: ['38063'],
    popolazione: 4115,
  },
  {
    nome: 'Avise',
    codice: '007006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A521',
    cap: ['11010'],
    popolazione: 348,
  },
  {
    nome: 'Avola',
    codice: '089002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'A522',
    cap: ['96012'],
    popolazione: 31328,
  },
  {
    nome: 'Avolasca',
    codice: '006010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A523',
    cap: ['15050'],
    popolazione: 306,
  },
  {
    nome: 'Ayas',
    codice: '007007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A094',
    cap: ['11020'],
    popolazione: 1359,
  },
  {
    nome: 'Aymavilles',
    codice: '007008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A108',
    cap: ['11010'],
    popolazione: 2072,
  },
  {
    nome: 'Azeglio',
    codice: '001014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A525',
    cap: ['10010'],
    popolazione: 1347,
  },
  {
    nome: 'Azzanello',
    codice: '019004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A526',
    cap: ['26010'],
    popolazione: 717,
  },
  {
    nome: "Azzano d'Asti",
    codice: '005006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A527',
    cap: ['14030'],
    popolazione: 419,
  },
  {
    nome: 'Azzano Decimo',
    codice: '093005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'A530',
    cap: ['33082'],
    popolazione: 15554,
  },
  {
    nome: 'Azzano Mella',
    codice: '017008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A529',
    cap: ['25020'],
    popolazione: 2969,
  },
  {
    nome: 'Azzano San Paolo',
    codice: '016016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A528',
    cap: ['24052'],
    popolazione: 7597,
  },
  {
    nome: 'Azzate',
    codice: '012006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A531',
    cap: ['21022'],
    popolazione: 4554,
  },
  {
    nome: 'Azzio',
    codice: '012007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A532',
    cap: ['21030'],
    popolazione: 802,
  },
  {
    nome: 'Azzone',
    codice: '016017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A533',
    cap: ['24020'],
    popolazione: 433,
  },
  {
    nome: 'Baceno',
    codice: '103006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A534',
    cap: ['28861'],
    popolazione: 922,
  },
  {
    nome: 'Bacoli',
    codice: '063006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A535',
    cap: ['80070'],
    popolazione: 26648,
  },
  {
    nome: 'Badalucco',
    codice: '008006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A536',
    cap: ['18010'],
    popolazione: 1190,
  },
  {
    nome: 'Badesi',
    codice: '090081',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M214',
    cap: ['07030'],
    popolazione: 1898,
  },
  {
    nome: 'Badia',
    codice: '021006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A537',
    cap: ['39036'],
    popolazione: 3360,
  },
  {
    nome: 'Badia Calavena',
    codice: '023005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A540',
    cap: ['37030'],
    popolazione: 2661,
  },
  {
    nome: 'Badia Pavese',
    codice: '018006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A538',
    cap: ['27010'],
    popolazione: 402,
  },
  {
    nome: 'Badia Polesine',
    codice: '029004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'A539',
    cap: ['45021'],
    popolazione: 10536,
  },
  {
    nome: 'Badia Tedalda',
    codice: '051003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'A541',
    cap: ['52032'],
    popolazione: 1091,
  },
  {
    nome: 'Badolato',
    codice: '079008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A542',
    cap: ['88060'],
    popolazione: 3183,
  },
  {
    nome: 'Bagaladi',
    codice: '080006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A544',
    cap: ['89060'],
    popolazione: 1082,
  },
  {
    nome: 'Bagheria',
    codice: '082006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A546',
    cap: ['90011'],
    popolazione: 54257,
  },
  {
    nome: 'Bagnacavallo',
    codice: '039002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'A547',
    cap: ['48012'],
    popolazione: 16715,
  },
  {
    nome: 'Bagnara Calabra',
    codice: '080007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A552',
    cap: ['89011'],
    popolazione: 10622,
  },
  {
    nome: 'Bagnara di Romagna',
    codice: '039003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'A551',
    cap: ['48031'],
    popolazione: 2348,
  },
  {
    nome: 'Bagnaria',
    codice: '018007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A550',
    cap: ['27050'],
    popolazione: 675,
  },
  {
    nome: 'Bagnaria Arsa',
    codice: '030008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A553',
    cap: ['33050'],
    popolazione: 3577,
  },
  {
    nome: 'Bagnasco',
    codice: '004008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A555',
    cap: ['12071'],
    popolazione: 1038,
  },
  {
    nome: 'Bagnatica',
    codice: '016018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A557',
    cap: ['24060'],
    popolazione: 4154,
  },
  {
    nome: 'Bagni di Lucca',
    codice: '046002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'A560',
    cap: ['55022'],
    popolazione: 6207,
  },
  {
    nome: 'Bagno a Ripoli',
    codice: '048001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'A564',
    cap: ['50012'],
    popolazione: 25403,
  },
  {
    nome: 'Bagno di Romagna',
    codice: '040001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'A565',
    cap: ['47021'],
    popolazione: 6138,
  },
  {
    nome: 'Bagnoli del Trigno',
    codice: '094003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'A567',
    cap: ['86091'],
    popolazione: 772,
  },
  {
    nome: 'Bagnoli di Sopra',
    codice: '028008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A568',
    cap: ['35023'],
    popolazione: 3626,
  },
  {
    nome: 'Bagnoli Irpino',
    codice: '064009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A566',
    cap: ['83043'],
    popolazione: 3274,
  },
  {
    nome: 'Bagnolo Cremasco',
    codice: '019005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A570',
    cap: ['26010'],
    popolazione: 4788,
  },
  {
    nome: 'Bagnolo del Salento',
    codice: '075008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'A572',
    cap: ['73020'],
    popolazione: 1879,
  },
  {
    nome: 'Bagnolo di Po',
    codice: '029005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'A574',
    cap: ['45022'],
    popolazione: 1374,
  },
  {
    nome: 'Bagnolo in Piano',
    codice: '035002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'A573',
    cap: ['42011'],
    popolazione: 9386,
  },
  {
    nome: 'Bagnolo Mella',
    codice: '017009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A569',
    cap: ['25021'],
    popolazione: 12692,
  },
  {
    nome: 'Bagnolo Piemonte',
    codice: '004009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A571',
    cap: ['12031'],
    popolazione: 6040,
  },
  {
    nome: 'Bagnolo San Vito',
    codice: '020003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'A575',
    cap: ['46031'],
    popolazione: 5874,
  },
  {
    nome: 'Bagnone',
    codice: '045002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'A576',
    cap: ['54021'],
    popolazione: 1926,
  },
  {
    nome: 'Bagnoregio',
    codice: '056003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A577',
    cap: ['01022'],
    popolazione: 3674,
  },
  {
    nome: 'Bagolino',
    codice: '017010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A578',
    cap: ['25072'],
    popolazione: 3940,
  },
  {
    nome: 'Baia e Latina',
    codice: '061006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A579',
    cap: ['81010'],
    popolazione: 2251,
  },
  {
    nome: 'Baiano',
    codice: '064010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A580',
    cap: ['83022'],
    popolazione: 4730,
  },
  {
    nome: 'Bairo',
    codice: '001015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A584',
    cap: ['10010'],
    popolazione: 816,
  },
  {
    nome: 'Baiso',
    codice: '035003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'A586',
    cap: ['42031'],
    popolazione: 3403,
  },
  {
    nome: 'Bajardo',
    codice: '008007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A581',
    cap: ['18031'],
    popolazione: 312,
  },
  {
    nome: 'Balangero',
    codice: '001016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A587',
    cap: ['10070'],
    popolazione: 3161,
  },
  {
    nome: "Baldichieri d'Asti",
    codice: '005007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A588',
    cap: ['14011'],
    popolazione: 1114,
  },
  {
    nome: 'Baldissero Canavese',
    codice: '001017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A590',
    cap: ['10080'],
    popolazione: 534,
  },
  {
    nome: "Baldissero d'Alba",
    codice: '004010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A589',
    cap: ['12040'],
    popolazione: 1086,
  },
  {
    nome: 'Baldissero Torinese',
    codice: '001018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A591',
    cap: ['10020'],
    popolazione: 3783,
  },
  {
    nome: 'Balestrate',
    codice: '082007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A592',
    cap: ['90041'],
    popolazione: 6413,
  },
  {
    nome: 'Balestrino',
    codice: '009008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A593',
    cap: ['17020'],
    popolazione: 597,
  },
  {
    nome: 'Ballabio',
    codice: '097004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A594',
    cap: ['23811'],
    popolazione: 4018,
  },
  {
    nome: 'Ballao',
    codice: '111003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'A597',
    cap: ['09040'],
    popolazione: 877,
  },
  {
    nome: 'Balme',
    codice: '001019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A599',
    cap: ['10070'],
    popolazione: 95,
  },
  {
    nome: 'Balmuccia',
    codice: '002008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A600',
    cap: ['13020'],
    popolazione: 94,
  },
  {
    nome: 'Balocco',
    codice: '002009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A601',
    cap: ['13040'],
    popolazione: 239,
  },
  {
    nome: 'Balsorano',
    codice: '066007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A603',
    cap: ['67052'],
    popolazione: 3655,
  },
  {
    nome: 'Balvano',
    codice: '076008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A604',
    cap: ['85050'],
    popolazione: 1861,
  },
  {
    nome: 'Balzola',
    codice: '006011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A605',
    cap: ['15031'],
    popolazione: 1420,
  },
  {
    nome: 'Banari',
    codice: '090007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A606',
    cap: ['07040'],
    popolazione: 610,
  },
  {
    nome: 'Banchette',
    codice: '001020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A607',
    cap: ['10010'],
    popolazione: 3280,
  },
  {
    nome: 'Bannio Anzino',
    codice: '103007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A610',
    cap: ['28871'],
    popolazione: 518,
  },
  {
    nome: 'Banzi',
    codice: '076009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A612',
    cap: ['85010'],
    popolazione: 1406,
  },
  {
    nome: 'Baone',
    codice: '028009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A613',
    cap: ['35030'],
    popolazione: 3108,
  },
  {
    nome: 'Baradili',
    codice: '095010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A614',
    cap: ['09090'],
    popolazione: 90,
  },
  {
    nome: 'Baragiano',
    codice: '076010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A615',
    cap: ['85050'],
    popolazione: 2675,
  },
  {
    nome: 'Baranello',
    codice: '070002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'A616',
    cap: ['86011'],
    popolazione: 2732,
  },
  {
    nome: "Barano d'Ischia",
    codice: '063007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'A617',
    cap: ['80072'],
    popolazione: 9882,
  },
  {
    nome: 'Baranzate',
    codice: '015250',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A618',
    cap: ['20021'],
    popolazione: 10779,
  },
  {
    nome: 'Barasso',
    codice: '012008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A619',
    cap: ['21020'],
    popolazione: 1710,
  },
  {
    nome: 'Baratili San Pietro',
    codice: '095011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A621',
    cap: ['09070'],
    popolazione: 1329,
  },
  {
    nome: 'Barbania',
    codice: '001021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A625',
    cap: ['10070'],
    popolazione: 1623,
  },
  {
    nome: 'Barbara',
    codice: '042004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'A626',
    cap: ['60010'],
    popolazione: 1408,
  },
  {
    nome: 'Barbarano Mossano',
    codice: '024124',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M401',
    cap: ['36048'],
    popolazione: 6352,
  },
  {
    nome: 'Barbarano Romano',
    codice: '056004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A628',
    cap: ['01010'],
    popolazione: 1085,
  },
  {
    nome: 'Barbaresco',
    codice: '004011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A629',
    cap: ['12050'],
    popolazione: 677,
  },
  {
    nome: 'Barbariga',
    codice: '017011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A630',
    cap: ['25030'],
    popolazione: 2388,
  },
  {
    nome: 'Barbata',
    codice: '016019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A631',
    cap: ['24040'],
    popolazione: 698,
  },
  {
    nome: 'Barberino di Mugello',
    codice: '048002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'A632',
    cap: ['50031'],
    popolazione: 10461,
  },
  {
    nome: 'Barberino Tavarnelle',
    codice: '048054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'M408',
    cap: ['50028'],
    popolazione: 12026,
  },
  {
    nome: 'Barbianello',
    codice: '018008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A634',
    cap: ['27041'],
    popolazione: 890,
  },
  {
    nome: 'Barbiano',
    codice: '021007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A635',
    cap: ['39040'],
    popolazione: 1596,
  },
  {
    nome: 'Barbona',
    codice: '028010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A637',
    cap: ['35040'],
    popolazione: 733,
  },
  {
    nome: 'Barcellona Pozzo di Gotto',
    codice: '083005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'A638',
    cap: ['98051'],
    popolazione: 41632,
  },
  {
    nome: 'Barcis',
    codice: '093006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'A640',
    cap: ['33080'],
    popolazione: 261,
  },
  {
    nome: 'Bard',
    codice: '007009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A643',
    cap: ['11020'],
    popolazione: 125,
  },
  {
    nome: 'Bardello',
    codice: '012009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A645',
    cap: ['21020'],
    popolazione: 1550,
  },
  {
    nome: 'Bardi',
    codice: '034002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'A646',
    cap: ['43032'],
    popolazione: 2337,
  },
  {
    nome: 'Bardineto',
    codice: '009009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A647',
    cap: ['17057'],
    popolazione: 697,
  },
  {
    nome: 'Bardolino',
    codice: '023006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A650',
    cap: ['37011'],
    popolazione: 6714,
  },
  {
    nome: 'Bardonecchia',
    codice: '001022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A651',
    cap: ['10052'],
    popolazione: 3212,
  },
  {
    nome: 'Bareggio',
    codice: '015012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A652',
    cap: ['20010'],
    popolazione: 17035,
  },
  {
    nome: 'Barengo',
    codice: '003012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A653',
    cap: ['28010'],
    popolazione: 852,
  },
  {
    nome: 'Baressa',
    codice: '095012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A655',
    cap: ['09090'],
    popolazione: 723,
  },
  {
    nome: 'Barete',
    codice: '066008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A656',
    cap: ['67010'],
    popolazione: 679,
  },
  {
    nome: 'Barga',
    codice: '046003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'A657',
    cap: ['55051'],
    popolazione: 10125,
  },
  {
    nome: 'Bargagli',
    codice: '010003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'A658',
    cap: ['16021'],
    popolazione: 2810,
  },
  {
    nome: 'Barge',
    codice: '004012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A660',
    cap: ['12032'],
    popolazione: 7861,
  },
  {
    nome: 'Barghe',
    codice: '017012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A661',
    cap: ['25070'],
    popolazione: 1188,
  },
  {
    nome: 'Bari',
    codice: '072006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A662',
    cap: [
      '70121',
      '70122',
      '70123',
      '70124',
      '70125',
      '70126',
      '70127',
      '70128',
      '70129',
      '70130',
      '70131',
      '70132',
    ],
    popolazione: 315933,
  },
  {
    nome: 'Bari Sardo',
    codice: '091005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A663',
    cap: ['08042'],
    popolazione: 3938,
  },
  {
    nome: 'Bariano',
    codice: '016020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A664',
    cap: ['24050'],
    popolazione: 4350,
  },
  {
    nome: 'Baricella',
    codice: '037003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'A665',
    cap: ['40052'],
    popolazione: 6763,
  },
  {
    nome: 'Barile',
    codice: '076011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A666',
    cap: ['85022'],
    popolazione: 2905,
  },
  {
    nome: 'Barisciano',
    codice: '066009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A667',
    cap: ['67021'],
    popolazione: 1853,
  },
  {
    nome: 'Barlassina',
    codice: '108005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A668',
    cap: ['20825'],
    popolazione: 6826,
  },
  {
    nome: 'Barletta',
    codice: '110002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'A669',
    cap: ['76121'],
    popolazione: 94239,
  },
  {
    nome: 'Barni',
    codice: '013015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A670',
    cap: ['22030'],
    popolazione: 597,
  },
  {
    nome: 'Barolo',
    codice: '004013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A671',
    cap: ['12060'],
    popolazione: 705,
  },
  {
    nome: 'Barone Canavese',
    codice: '001023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A673',
    cap: ['10010'],
    popolazione: 599,
  },
  {
    nome: 'Baronissi',
    codice: '065013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A674',
    cap: ['84081'],
    popolazione: 16790,
  },
  {
    nome: 'Barrafranca',
    codice: '086004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'A676',
    cap: ['94012'],
    popolazione: 13977,
  },
  {
    nome: 'Barrali',
    codice: '111004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'A677',
    cap: ['09040'],
    popolazione: 1139,
  },
  {
    nome: 'Barrea',
    codice: '066010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A678',
    cap: ['67030'],
    popolazione: 726,
  },
  {
    nome: 'Barumini',
    codice: '111005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'A681',
    cap: ['09021'],
    popolazione: 1310,
  },
  {
    nome: 'Barzago',
    codice: '097005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A683',
    cap: ['23890'],
    popolazione: 2577,
  },
  {
    nome: 'Barzana',
    codice: '016021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A684',
    cap: ['24030'],
    popolazione: 1790,
  },
  {
    nome: 'Barzanò',
    codice: '097006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A686',
    cap: ['23891'],
    popolazione: 5171,
  },
  {
    nome: 'Barzio',
    codice: '097007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A687',
    cap: ['23816'],
    popolazione: 1297,
  },
  {
    nome: 'Basaluzzo',
    codice: '006012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A689',
    cap: ['15060'],
    popolazione: 2071,
  },
  {
    nome: 'Bascapè',
    codice: '018009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A690',
    cap: ['27010'],
    popolazione: 1715,
  },
  {
    nome: 'Baschi',
    codice: '055007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'A691',
    cap: ['05023'],
    popolazione: 2803,
  },
  {
    nome: 'Basciano',
    codice: '067005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A692',
    cap: ['64030'],
    popolazione: 2438,
  },
  {
    nome: 'Baselga di Pinè',
    codice: '022009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A694',
    cap: ['38042'],
    popolazione: 4928,
  },
  {
    nome: 'Baselice',
    codice: '062007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A696',
    cap: ['82020'],
    popolazione: 2555,
  },
  {
    nome: 'Basiano',
    codice: '015014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A697',
    cap: ['20060'],
    popolazione: 3639,
  },
  {
    nome: 'Basicò',
    codice: '083006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'A698',
    cap: ['98060'],
    popolazione: 679,
  },
  {
    nome: 'Basiglio',
    codice: '015015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A699',
    cap: ['20080'],
    popolazione: 7567,
  },
  {
    nome: 'Basiliano',
    codice: '030009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A700',
    cap: ['33031'],
    popolazione: 5353,
  },
  {
    nome: 'Bassano Bresciano',
    codice: '017013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A702',
    cap: ['25020'],
    popolazione: 2224,
  },
  {
    nome: 'Bassano del Grappa',
    codice: '024012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A703',
    cap: ['36061'],
    popolazione: 42984,
  },
  {
    nome: 'Bassano in Teverina',
    codice: '056006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A706',
    cap: ['01030'],
    popolazione: 1277,
  },
  {
    nome: 'Bassano Romano',
    codice: '056005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A704',
    cap: ['01030'],
    popolazione: 4834,
  },
  {
    nome: 'Bassiano',
    codice: '059002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'A707',
    cap: ['04010'],
    popolazione: 1580,
  },
  {
    nome: 'Bassignana',
    codice: '006013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A708',
    cap: ['15042'],
    popolazione: 1742,
  },
  {
    nome: 'Bastia Mondovì',
    codice: '004014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A709',
    cap: ['12060'],
    popolazione: 646,
  },
  {
    nome: 'Bastia Umbra',
    codice: '054002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'A710',
    cap: ['06083'],
    popolazione: 21653,
  },
  {
    nome: 'Bastida Pancarana',
    codice: '018011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A712',
    cap: ['27050'],
    popolazione: 1032,
  },
  {
    nome: 'Bastiglia',
    codice: '036001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'A713',
    cap: ['41030'],
    popolazione: 3985,
  },
  {
    nome: 'Battaglia Terme',
    codice: '028011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A714',
    cap: ['35041'],
    popolazione: 3933,
  },
  {
    nome: 'Battifollo',
    codice: '004015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A716',
    cap: ['12070'],
    popolazione: 234,
  },
  {
    nome: 'Battipaglia',
    codice: '065014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A717',
    cap: ['84091'],
    popolazione: 50464,
  },
  {
    nome: 'Battuda',
    codice: '018012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A718',
    cap: ['27020'],
    popolazione: 582,
  },
  {
    nome: 'Baucina',
    codice: '082008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A719',
    cap: ['90020'],
    popolazione: 2014,
  },
  {
    nome: 'Bauladu',
    codice: '095013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A721',
    cap: ['09070'],
    popolazione: 703,
  },
  {
    nome: 'Baunei',
    codice: '091006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A722',
    cap: ['08040'],
    popolazione: 3716,
  },
  {
    nome: 'Baveno',
    codice: '103008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A725',
    cap: ['28831'],
    popolazione: 4917,
  },
  {
    nome: 'Bedero Valcuvia',
    codice: '012010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A728',
    cap: ['21039'],
    popolazione: 667,
  },
  {
    nome: 'Bedizzole',
    codice: '017014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A729',
    cap: ['25081'],
    popolazione: 11816,
  },
  {
    nome: 'Bedollo',
    codice: '022011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A730',
    cap: ['38043'],
    popolazione: 1479,
  },
  {
    nome: 'Bedonia',
    codice: '034003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'A731',
    cap: ['43041'],
    popolazione: 3617,
  },
  {
    nome: 'Bedulita',
    codice: '016022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A732',
    cap: ['24030'],
    popolazione: 723,
  },
  {
    nome: 'Bee',
    codice: '103009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A733',
    cap: ['28813'],
    popolazione: 729,
  },
  {
    nome: 'Beinasco',
    codice: '001024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A734',
    cap: ['10092'],
    popolazione: 18104,
  },
  {
    nome: 'Beinette',
    codice: '004016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A735',
    cap: ['12081'],
    popolazione: 3200,
  },
  {
    nome: 'Belcastro',
    codice: '079009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'A736',
    cap: ['88050'],
    popolazione: 1400,
  },
  {
    nome: 'Belfiore',
    codice: '023007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A737',
    cap: ['37050'],
    popolazione: 3008,
  },
  {
    nome: "Belforte all'Isauro",
    codice: '041005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'A740',
    cap: ['61026'],
    popolazione: 788,
  },
  {
    nome: 'Belforte del Chienti',
    codice: '043004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'A739',
    cap: ['62020'],
    popolazione: 1860,
  },
  {
    nome: 'Belforte Monferrato',
    codice: '006014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A738',
    cap: ['15070'],
    popolazione: 505,
  },
  {
    nome: 'Belgioioso',
    codice: '018013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A741',
    cap: ['27011'],
    popolazione: 6287,
  },
  {
    nome: 'Belgirate',
    codice: '103010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A742',
    cap: ['28832'],
    popolazione: 546,
  },
  {
    nome: 'Bella',
    codice: '076012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'A743',
    cap: ['85051'],
    popolazione: 5240,
  },
  {
    nome: 'Bellagio',
    codice: '013250',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M335',
    cap: ['22021'],
    popolazione: 3796,
  },
  {
    nome: 'Bellano',
    codice: '097008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'A745',
    cap: ['23822'],
    popolazione: 3583,
  },
  {
    nome: 'Bellante',
    codice: '067006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A746',
    cap: ['64020'],
    popolazione: 7160,
  },
  {
    nome: 'Bellaria-Igea Marina',
    codice: '099001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'A747',
    cap: ['47814'],
    popolazione: 18591,
  },
  {
    nome: 'Bellegra',
    codice: '058012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'A749',
    cap: ['00030'],
    popolazione: 2948,
  },
  {
    nome: 'Bellino',
    codice: '004017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A750',
    cap: ['12020'],
    popolazione: 135,
  },
  {
    nome: 'Bellinzago Lombardo',
    codice: '015016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A751',
    cap: ['20060'],
    popolazione: 3810,
  },
  {
    nome: 'Bellinzago Novarese',
    codice: '003016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A752',
    cap: ['28043'],
    popolazione: 9375,
  },
  {
    nome: 'Bellizzi',
    codice: '065158',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'M294',
    cap: ['84092'],
    popolazione: 12971,
  },
  {
    nome: 'Bellona',
    codice: '061007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'A755',
    cap: ['81041'],
    popolazione: 5705,
  },
  {
    nome: 'Bellosguardo',
    codice: '065015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'A756',
    cap: ['84020'],
    popolazione: 853,
  },
  {
    nome: 'Belluno',
    codice: '025006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A757',
    cap: ['32100'],
    popolazione: 35591,
  },
  {
    nome: 'Bellusco',
    codice: '108006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A759',
    cap: ['20882'],
    popolazione: 7212,
  },
  {
    nome: 'Belmonte Calabro',
    codice: '078013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A762',
    cap: ['87033'],
    popolazione: 2007,
  },
  {
    nome: 'Belmonte Castello',
    codice: '060013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A763',
    cap: ['03040'],
    popolazione: 778,
  },
  {
    nome: 'Belmonte del Sannio',
    codice: '094004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'A761',
    cap: ['86080'],
    popolazione: 810,
  },
  {
    nome: 'Belmonte in Sabina',
    codice: '057005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A765',
    cap: ['02020'],
    popolazione: 649,
  },
  {
    nome: 'Belmonte Mezzagno',
    codice: '082009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A764',
    cap: ['90031'],
    popolazione: 11149,
  },
  {
    nome: 'Belmonte Piceno',
    codice: '109003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'A760',
    cap: ['63838'],
    popolazione: 664,
  },
  {
    nome: 'Belpasso',
    codice: '087007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A766',
    cap: ['95032'],
    popolazione: 26378,
  },
  {
    nome: 'Belsito',
    codice: '078014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A768',
    cap: ['87030'],
    popolazione: 958,
  },
  {
    nome: 'Belvedere di Spinello',
    codice: '101001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'A772',
    cap: ['88824'],
    popolazione: 2327,
  },
  {
    nome: 'Belvedere Langhe',
    codice: '004018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A774',
    cap: ['12060'],
    popolazione: 370,
  },
  {
    nome: 'Belvedere Marittimo',
    codice: '078015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A773',
    cap: ['87021'],
    popolazione: 9120,
  },
  {
    nome: 'Belvedere Ostrense',
    codice: '042005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'A769',
    cap: ['60030'],
    popolazione: 2288,
  },
  {
    nome: 'Belveglio',
    codice: '005008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A770',
    cap: ['14040'],
    popolazione: 326,
  },
  {
    nome: 'Belvì',
    codice: '091007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A776',
    cap: ['08030'],
    popolazione: 665,
  },
  {
    nome: 'Bema',
    codice: '014006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A777',
    cap: ['23010'],
    popolazione: 130,
  },
  {
    nome: 'Bene Lario',
    codice: '013021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A778',
    cap: ['22010'],
    popolazione: 342,
  },
  {
    nome: 'Bene Vagienna',
    codice: '004019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A779',
    cap: ['12041'],
    popolazione: 3671,
  },
  {
    nome: 'Benestare',
    codice: '080008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A780',
    cap: ['89030'],
    popolazione: 2442,
  },
  {
    nome: 'Benetutti',
    codice: '090008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A781',
    cap: ['07010'],
    popolazione: 1971,
  },
  {
    nome: 'Benevello',
    codice: '004020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A782',
    cap: ['12050'],
    popolazione: 457,
  },
  {
    nome: 'Benevento',
    codice: '062008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A783',
    cap: ['82100'],
    popolazione: 61489,
  },
  {
    nome: 'Benna',
    codice: '096003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'A784',
    cap: ['13871'],
    popolazione: 1190,
  },
  {
    nome: 'Bentivoglio',
    codice: '037005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'A785',
    cap: ['40010'],
    popolazione: 5358,
  },
  {
    nome: 'Berbenno',
    codice: '016023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A786',
    cap: ['24030'],
    popolazione: 2442,
  },
  {
    nome: 'Berbenno di Valtellina',
    codice: '014007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A787',
    cap: ['23010'],
    popolazione: 4308,
  },
  {
    nome: 'Berceto',
    codice: '034004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'A788',
    cap: ['43042'],
    popolazione: 2144,
  },
  {
    nome: 'Berchidda',
    codice: '090009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A789',
    cap: ['07022'],
    popolazione: 2897,
  },
  {
    nome: 'Beregazzo con Figliaro',
    codice: '013022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A791',
    cap: ['22070'],
    popolazione: 2577,
  },
  {
    nome: 'Bereguardo',
    codice: '018014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A792',
    cap: ['27021'],
    popolazione: 2775,
  },
  {
    nome: 'Bergamasco',
    codice: '006015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A793',
    cap: ['15022'],
    popolazione: 765,
  },
  {
    nome: 'Bergamo',
    codice: '016024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A794',
    cap: ['24121', '24122', '24123', '24124', '24125', '24126', '24127', '24128', '24129'],
    popolazione: 115349,
  },
  {
    nome: 'Bergantino',
    codice: '029006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'A795',
    cap: ['45032'],
    popolazione: 2626,
  },
  {
    nome: 'Bergeggi',
    codice: '009010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A796',
    cap: ['17028'],
    popolazione: 1126,
  },
  {
    nome: 'Bergolo',
    codice: '004021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A798',
    cap: ['12074'],
    popolazione: 67,
  },
  {
    nome: 'Berlingo',
    codice: '017015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A799',
    cap: ['25030'],
    popolazione: 2582,
  },
  {
    nome: 'Bernalda',
    codice: '077003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'A801',
    cap: ['75012'],
    popolazione: 12264,
  },
  {
    nome: 'Bernareggio',
    codice: '108007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A802',
    cap: ['20881'],
    popolazione: 10555,
  },
  {
    nome: 'Bernate Ticino',
    codice: '015019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A804',
    cap: ['20010'],
    popolazione: 3071,
  },
  {
    nome: 'Bernezzo',
    codice: '004022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A805',
    cap: ['12010'],
    popolazione: 3785,
  },
  {
    nome: 'Bertinoro',
    codice: '040003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'A809',
    cap: ['47032'],
    popolazione: 10798,
  },
  {
    nome: 'Bertiolo',
    codice: '030010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A810',
    cap: ['33032'],
    popolazione: 2577,
  },
  {
    nome: 'Bertonico',
    codice: '098002',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'A811',
    cap: ['26821'],
    popolazione: 1168,
  },
  {
    nome: 'Berzano di San Pietro',
    codice: '005009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A812',
    cap: ['14020'],
    popolazione: 431,
  },
  {
    nome: 'Berzano di Tortona',
    codice: '006016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A813',
    cap: ['15050'],
    popolazione: 171,
  },
  {
    nome: 'Berzo Demo',
    codice: '017016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A816',
    cap: ['25040'],
    popolazione: 1720,
  },
  {
    nome: 'Berzo Inferiore',
    codice: '017017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A817',
    cap: ['25040'],
    popolazione: 2456,
  },
  {
    nome: 'Berzo San Fermo',
    codice: '016025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A815',
    cap: ['24060'],
    popolazione: 1298,
  },
  {
    nome: 'Besana in Brianza',
    codice: '108008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A818',
    cap: ['20842'],
    popolazione: 15510,
  },
  {
    nome: 'Besano',
    codice: '012011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A819',
    cap: ['21050'],
    popolazione: 2603,
  },
  {
    nome: 'Besate',
    codice: '015022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A820',
    cap: ['20080'],
    popolazione: 2022,
  },
  {
    nome: 'Besenello',
    codice: '022013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A821',
    cap: ['38060'],
    popolazione: 2511,
  },
  {
    nome: 'Besenzone',
    codice: '033003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'A823',
    cap: ['29010'],
    popolazione: 976,
  },
  {
    nome: 'Besnate',
    codice: '012012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A825',
    cap: ['21010'],
    popolazione: 5464,
  },
  {
    nome: 'Besozzo',
    codice: '012013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A826',
    cap: ['21023'],
    popolazione: 8994,
  },
  {
    nome: 'Bessude',
    codice: '090010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A827',
    cap: ['07040'],
    popolazione: 428,
  },
  {
    nome: 'Bettola',
    codice: '033004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'A831',
    cap: ['29021'],
    popolazione: 2999,
  },
  {
    nome: 'Bettona',
    codice: '054003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'A832',
    cap: ['06084'],
    popolazione: 4302,
  },
  {
    nome: 'Beura-Cardezza',
    codice: '103011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A834',
    cap: ['28851'],
    popolazione: 1437,
  },
  {
    nome: 'Bevagna',
    codice: '054004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'A835',
    cap: ['06031'],
    popolazione: 5074,
  },
  {
    nome: 'Beverino',
    codice: '011003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'A836',
    cap: ['19020'],
    popolazione: 2403,
  },
  {
    nome: 'Bevilacqua',
    codice: '023008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A837',
    cap: ['37040'],
    popolazione: 1787,
  },
  {
    nome: 'Biancavilla',
    codice: '087008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'A841',
    cap: ['95033'],
    popolazione: 23703,
  },
  {
    nome: 'Bianchi',
    codice: '078016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A842',
    cap: ['87050'],
    popolazione: 1367,
  },
  {
    nome: 'Bianco',
    codice: '080009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A843',
    cap: ['89032'],
    popolazione: 4125,
  },
  {
    nome: 'Biandrate',
    codice: '003018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A844',
    cap: ['28061'],
    popolazione: 1200,
  },
  {
    nome: 'Biandronno',
    codice: '012014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A845',
    cap: ['21024'],
    popolazione: 3285,
  },
  {
    nome: 'Bianzano',
    codice: '016026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A846',
    cap: ['24060'],
    popolazione: 605,
  },
  {
    nome: 'Bianzè',
    codice: '002011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A847',
    cap: ['13041'],
    popolazione: 2028,
  },
  {
    nome: 'Bianzone',
    codice: '014008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'A848',
    cap: ['23030'],
    popolazione: 1273,
  },
  {
    nome: 'Biassono',
    codice: '108009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'A849',
    cap: ['20853'],
    popolazione: 11697,
  },
  {
    nome: 'Bibbiano',
    codice: '035004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'A850',
    cap: ['42021'],
    popolazione: 9965,
  },
  {
    nome: 'Bibbiena',
    codice: '051004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'A851',
    cap: ['52011'],
    popolazione: 12284,
  },
  {
    nome: 'Bibbona',
    codice: '049001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'A852',
    cap: ['57020'],
    popolazione: 3209,
  },
  {
    nome: 'Bibiana',
    codice: '001025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A853',
    cap: ['10060'],
    popolazione: 3376,
  },
  {
    nome: 'Biccari',
    codice: '071006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'A854',
    cap: ['71032'],
    popolazione: 2872,
  },
  {
    nome: 'Bicinicco',
    codice: '030011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A855',
    cap: ['33050'],
    popolazione: 1922,
  },
  {
    nome: 'Bidonì',
    codice: '095014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A856',
    cap: ['09080'],
    popolazione: 147,
  },
  {
    nome: 'Biella',
    codice: '096004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'A859',
    cap: ['13900'],
    popolazione: 43818,
  },
  {
    nome: 'Bienno',
    codice: '017018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A861',
    cap: ['25040'],
    popolazione: 3958,
  },
  {
    nome: 'Bieno',
    codice: '022015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A863',
    cap: ['38050'],
    popolazione: 430,
  },
  {
    nome: 'Bientina',
    codice: '050001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'A864',
    cap: ['56031'],
    popolazione: 7766,
  },
  {
    nome: 'Binago',
    codice: '013023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A870',
    cap: ['22070'],
    popolazione: 4776,
  },
  {
    nome: 'Binasco',
    codice: '015024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A872',
    cap: ['20082'],
    popolazione: 7158,
  },
  {
    nome: 'Binetto',
    codice: '072008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A874',
    cap: ['70020'],
    popolazione: 2162,
  },
  {
    nome: 'Bioglio',
    codice: '096005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'A876',
    cap: ['13841'],
    popolazione: 986,
  },
  {
    nome: 'Bionaz',
    codice: '007010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A877',
    cap: ['11010'],
    popolazione: 234,
  },
  {
    nome: 'Bione',
    codice: '017019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'A878',
    cap: ['25070'],
    popolazione: 1471,
  },
  {
    nome: 'Birori',
    codice: '091008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A880',
    cap: ['08010'],
    popolazione: 561,
  },
  {
    nome: 'Bisaccia',
    codice: '064011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A881',
    cap: ['83044'],
    popolazione: 3919,
  },
  {
    nome: 'Bisacquino',
    codice: '082010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A882',
    cap: ['90032'],
    popolazione: 4829,
  },
  {
    nome: 'Bisceglie',
    codice: '110003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'A883',
    cap: ['76011'],
    popolazione: 54678,
  },
  {
    nome: 'Bisegna',
    codice: '066011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A884',
    cap: ['67050'],
    popolazione: 261,
  },
  {
    nome: 'Bisenti',
    codice: '067007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'A885',
    cap: ['64033'],
    popolazione: 2069,
  },
  {
    nome: 'Bisignano',
    codice: '078017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A887',
    cap: ['87043'],
    popolazione: 10335,
  },
  {
    nome: 'Bistagno',
    codice: '006017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A889',
    cap: ['15012'],
    popolazione: 1930,
  },
  {
    nome: 'Bisuschio',
    codice: '012015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A891',
    cap: ['21050'],
    popolazione: 4267,
  },
  {
    nome: 'Bitetto',
    codice: '072010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A892',
    cap: ['70020'],
    popolazione: 11799,
  },
  {
    nome: 'Bitonto',
    codice: '072011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A893',
    cap: ['70032'],
    popolazione: 56258,
  },
  {
    nome: 'Bitritto',
    codice: '072012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'A894',
    cap: ['70020'],
    popolazione: 10878,
  },
  {
    nome: 'Bitti',
    codice: '091009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A895',
    cap: ['08021'],
    popolazione: 3019,
  },
  {
    nome: 'Bivona',
    codice: '084004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'A896',
    cap: ['92010'],
    popolazione: 3882,
  },
  {
    nome: 'Bivongi',
    codice: '080010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'A897',
    cap: ['89040'],
    popolazione: 1398,
  },
  {
    nome: 'Bizzarone',
    codice: '013024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A898',
    cap: ['22020'],
    popolazione: 1517,
  },
  {
    nome: 'Bleggio Superiore',
    codice: '022017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A902',
    cap: ['38071'],
    popolazione: 1600,
  },
  {
    nome: 'Blello',
    codice: '016027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A903',
    cap: ['24010'],
    popolazione: 76,
  },
  {
    nome: 'Blera',
    codice: '056007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A857',
    cap: ['01010'],
    popolazione: 3356,
  },
  {
    nome: 'Blessagno',
    codice: '013025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A904',
    cap: ['22028'],
    popolazione: 284,
  },
  {
    nome: 'Blevio',
    codice: '013026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'A905',
    cap: ['22020'],
    popolazione: 1185,
  },
  {
    nome: 'Blufi',
    codice: '082082',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'M268',
    cap: ['90020'],
    popolazione: 1083,
  },
  {
    nome: 'Boara Pisani',
    codice: '028012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'A906',
    cap: ['35040'],
    popolazione: 2611,
  },
  {
    nome: 'Bobbio',
    codice: '033005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'A909',
    cap: ['29022'],
    popolazione: 3711,
  },
  {
    nome: 'Bobbio Pellice',
    codice: '001026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A910',
    cap: ['10060'],
    popolazione: 566,
  },
  {
    nome: 'Boca',
    codice: '003019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A911',
    cap: ['28010'],
    popolazione: 1227,
  },
  {
    nome: 'Bocchigliero',
    codice: '078018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A912',
    cap: ['87060'],
    popolazione: 1479,
  },
  {
    nome: 'Boccioleto',
    codice: '002014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'A914',
    cap: ['13022'],
    popolazione: 219,
  },
  {
    nome: 'Bocenago',
    codice: '022018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A916',
    cap: ['38080'],
    popolazione: 407,
  },
  {
    nome: 'Bodio Lomnago',
    codice: '012016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A918',
    cap: ['21020'],
    popolazione: 2119,
  },
  {
    nome: "Boffalora d'Adda",
    codice: '098003',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'A919',
    cap: ['26811'],
    popolazione: 1705,
  },
  {
    nome: 'Boffalora sopra Ticino',
    codice: '015026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A920',
    cap: ['20010'],
    popolazione: 4195,
  },
  {
    nome: 'Bogliasco',
    codice: '010004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'A922',
    cap: ['16031'],
    popolazione: 4486,
  },
  {
    nome: 'Bognanco',
    codice: '103012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'A925',
    cap: ['28842'],
    popolazione: 230,
  },
  {
    nome: 'Bogogno',
    codice: '003021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A929',
    cap: ['28010'],
    popolazione: 1325,
  },
  {
    nome: 'Boissano',
    codice: '009011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A931',
    cap: ['17054'],
    popolazione: 2437,
  },
  {
    nome: 'Bojano',
    codice: '070003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'A930',
    cap: ['86021'],
    popolazione: 7946,
  },
  {
    nome: 'Bolano',
    codice: '011004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'A932',
    cap: ['19020'],
    popolazione: 7759,
  },
  {
    nome: 'Bolgare',
    codice: '016028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A937',
    cap: ['24060'],
    popolazione: 5760,
  },
  {
    nome: 'Bollate',
    codice: '015027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'A940',
    cap: ['20021'],
    popolazione: 35557,
  },
  {
    nome: 'Bollengo',
    codice: '001027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A941',
    cap: ['10012'],
    popolazione: 2112,
  },
  {
    nome: 'Bologna',
    codice: '037006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'A944',
    cap: [
      '40121',
      '40122',
      '40123',
      '40124',
      '40125',
      '40126',
      '40127',
      '40128',
      '40129',
      '40130',
      '40131',
      '40132',
      '40133',
      '40134',
      '40135',
      '40136',
      '40137',
      '40138',
      '40139',
      '40140',
      '40141',
    ],
    popolazione: 371337,
  },
  {
    nome: 'Bolognano',
    codice: '068003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'A945',
    cap: ['65020'],
    popolazione: 1157,
  },
  {
    nome: 'Bolognetta',
    codice: '082011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A946',
    cap: ['90030'],
    popolazione: 3932,
  },
  {
    nome: 'Bolognola',
    codice: '043005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'A947',
    cap: ['62035'],
    popolazione: 161,
  },
  {
    nome: 'Bolotana',
    codice: '091010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A948',
    cap: ['08011'],
    popolazione: 2846,
  },
  {
    nome: 'Bolsena',
    codice: '056008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A949',
    cap: ['01023'],
    popolazione: 4137,
  },
  {
    nome: 'Boltiere',
    codice: '016029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A950',
    cap: ['24040'],
    popolazione: 5814,
  },
  {
    nome: 'Bolzano',
    codice: '021008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A952',
    cap: ['39100'],
    popolazione: 102575,
  },
  {
    nome: 'Bolzano Novarese',
    codice: '003022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'A953',
    cap: ['28010'],
    popolazione: 1176,
  },
  {
    nome: 'Bolzano Vicentino',
    codice: '024013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'A954',
    cap: ['36050'],
    popolazione: 6509,
  },
  {
    nome: 'Bomarzo',
    codice: '056009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A955',
    cap: ['01020'],
    popolazione: 1814,
  },
  {
    nome: 'Bomba',
    codice: '069006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'A956',
    cap: ['66042'],
    popolazione: 885,
  },
  {
    nome: 'Bompensiere',
    codice: '085002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'A957',
    cap: ['93010'],
    popolazione: 610,
  },
  {
    nome: 'Bompietro',
    codice: '082012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A958',
    cap: ['90020'],
    popolazione: 1474,
  },
  {
    nome: 'Bomporto',
    codice: '036002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'A959',
    cap: ['41030'],
    popolazione: 9761,
  },
  {
    nome: 'Bonarcado',
    codice: '095015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A960',
    cap: ['09070'],
    popolazione: 1627,
  },
  {
    nome: 'Bonassola',
    codice: '011005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'A961',
    cap: ['19011'],
    popolazione: 995,
  },
  {
    nome: 'Bonate Sopra',
    codice: '016030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A963',
    cap: ['24040'],
    popolazione: 8920,
  },
  {
    nome: 'Bonate Sotto',
    codice: '016031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A962',
    cap: ['24040'],
    popolazione: 6455,
  },
  {
    nome: 'Bonavigo',
    codice: '023009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'A964',
    cap: ['37040'],
    popolazione: 2024,
  },
  {
    nome: 'Bondeno',
    codice: '038003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'A965',
    cap: ['44012'],
    popolazione: 15116,
  },
  {
    nome: 'Bondone',
    codice: '022021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'A968',
    cap: ['38080'],
    popolazione: 668,
  },
  {
    nome: 'Bonea',
    codice: '062009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'A970',
    cap: ['82013'],
    popolazione: 1483,
  },
  {
    nome: 'Bonefro',
    codice: '070004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'A971',
    cap: ['86041'],
    popolazione: 1528,
  },
  {
    nome: 'Bonemerse',
    codice: '019006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A972',
    cap: ['26040'],
    popolazione: 1485,
  },
  {
    nome: 'Bonifati',
    codice: '078019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'A973',
    cap: ['87020'],
    popolazione: 2912,
  },
  {
    nome: 'Bonito',
    codice: '064012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'A975',
    cap: ['83032'],
    popolazione: 2526,
  },
  {
    nome: 'Bonnanaro',
    codice: '090011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A976',
    cap: ['07043'],
    popolazione: 1021,
  },
  {
    nome: 'Bono',
    codice: '090012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A977',
    cap: ['07011'],
    popolazione: 3636,
  },
  {
    nome: 'Bonorva',
    codice: '090013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'A978',
    cap: ['07012'],
    popolazione: 3669,
  },
  {
    nome: 'Bonvicino',
    codice: '004023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'A979',
    cap: ['12060'],
    popolazione: 107,
  },
  {
    nome: 'Borbona',
    codice: '057006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A981',
    cap: ['02010'],
    popolazione: 650,
  },
  {
    nome: 'Borca di Cadore',
    codice: '025007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A982',
    cap: ['32040'],
    popolazione: 818,
  },
  {
    nome: 'Bordano',
    codice: '030012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'A983',
    cap: ['33010'],
    popolazione: 789,
  },
  {
    nome: 'Bordighera',
    codice: '008008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A984',
    cap: ['18012'],
    popolazione: 10416,
  },
  {
    nome: 'Bordolano',
    codice: '019007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'A986',
    cap: ['26020'],
    popolazione: 621,
  },
  {
    nome: 'Bore',
    codice: '034005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'A987',
    cap: ['43030'],
    popolazione: 799,
  },
  {
    nome: 'Boretto',
    codice: '035005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'A988',
    cap: ['42022'],
    popolazione: 5263,
  },
  {
    nome: 'Borgarello',
    codice: '018015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'A989',
    cap: ['27010'],
    popolazione: 2659,
  },
  {
    nome: 'Borgaro Torinese',
    codice: '001028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'A990',
    cap: ['10071'],
    popolazione: 11723,
  },
  {
    nome: 'Borgetto',
    codice: '082013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'A991',
    cap: ['90042'],
    popolazione: 7021,
  },
  {
    nome: "Borghetto d'Arroscia",
    codice: '008009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'A993',
    cap: ['18020'],
    popolazione: 463,
  },
  {
    nome: 'Borghetto di Borbera',
    codice: '006018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'A998',
    cap: ['15060'],
    popolazione: 1991,
  },
  {
    nome: 'Borghetto di Vara',
    codice: '011006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'A992',
    cap: ['19020'],
    popolazione: 1008,
  },
  {
    nome: 'Borghetto Lodigiano',
    codice: '098004',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'A995',
    cap: ['26812'],
    popolazione: 4379,
  },
  {
    nome: 'Borghetto Santo Spirito',
    codice: '009012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'A999',
    cap: ['17052'],
    popolazione: 5154,
  },
  {
    nome: 'Borghi',
    codice: '040004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'B001',
    cap: ['47030'],
    popolazione: 2718,
  },
  {
    nome: 'Borgia',
    codice: '079011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'B002',
    cap: ['88021'],
    popolazione: 7418,
  },
  {
    nome: 'Borgiallo',
    codice: '001029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B003',
    cap: ['10080'],
    popolazione: 550,
  },
  {
    nome: 'Borgio Verezzi',
    codice: '009013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B005',
    cap: ['17022'],
    popolazione: 2327,
  },
  {
    nome: 'Borgo a Mozzano',
    codice: '046004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'B007',
    cap: ['55023'],
    popolazione: 7227,
  },
  {
    nome: 'Borgo Chiese',
    codice: '022238',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M352',
    cap: ['38083'],
    popolazione: 2083,
  },
  {
    nome: "Borgo d'Ale",
    codice: '002015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B009',
    cap: ['13040'],
    popolazione: 2588,
  },
  {
    nome: "Borgo d'Anaunia",
    codice: '022252',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M429',
    cap: ['38013', '38020'],
    popolazione: 2512,
  },
  {
    nome: 'Borgo di Terzo',
    codice: '016032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B010',
    cap: ['24060'],
    popolazione: 1090,
  },
  {
    nome: 'Borgo Lares',
    codice: '022239',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M353',
    cap: ['38079'],
    popolazione: 707,
  },
  {
    nome: 'Borgo Mantovano',
    codice: '020072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'M396',
    cap: ['46036'],
    popolazione: 5619,
  },
  {
    nome: 'Borgo Pace',
    codice: '041006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'B026',
    cap: ['61040'],
    popolazione: 643,
  },
  {
    nome: 'Borgo Priolo',
    codice: '018016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B028',
    cap: ['27040'],
    popolazione: 1382,
  },
  {
    nome: 'Borgo San Dalmazzo',
    codice: '004025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B033',
    cap: ['12011'],
    popolazione: 12372,
  },
  {
    nome: 'Borgo San Giacomo',
    codice: '017020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B035',
    cap: ['25022'],
    popolazione: 5496,
  },
  {
    nome: 'Borgo San Giovanni',
    codice: '098005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B017',
    cap: ['26851'],
    popolazione: 2213,
  },
  {
    nome: 'Borgo San Lorenzo',
    codice: '048004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'B036',
    cap: ['50032'],
    popolazione: 17854,
  },
  {
    nome: 'Borgo San Martino',
    codice: '006020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B037',
    cap: ['15032'],
    popolazione: 1470,
  },
  {
    nome: 'Borgo San Siro',
    codice: '018018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B038',
    cap: ['27020'],
    popolazione: 1035,
  },
  {
    nome: 'Borgo Ticino',
    codice: '003025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B043',
    cap: ['28040'],
    popolazione: 4929,
  },
  {
    nome: 'Borgo Tossignano',
    codice: '037007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B044',
    cap: ['40021'],
    popolazione: 3302,
  },
  {
    nome: 'Borgo Val di Taro',
    codice: '034006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'B042',
    cap: ['43043'],
    popolazione: 7275,
  },
  {
    nome: 'Borgo Valbelluna',
    codice: '025074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M421',
    cap: ['32026'],
    popolazione: 13993,
  },
  {
    nome: 'Borgo Valsugana',
    codice: '022022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B006',
    cap: ['38051'],
    popolazione: 6826,
  },
  {
    nome: 'Borgo Velino',
    codice: '057008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A996',
    cap: ['02010'],
    popolazione: 990,
  },
  {
    nome: 'Borgo Veneto',
    codice: '028107',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'M402',
    cap: ['35046'],
    popolazione: 6981,
  },
  {
    nome: 'Borgo Vercelli',
    codice: '002017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B046',
    cap: ['13012'],
    popolazione: 2295,
  },
  {
    nome: 'Borgo Virgilio',
    codice: '020071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'M340',
    cap: ['46034'],
    popolazione: 14400,
  },
  {
    nome: 'Borgocarbonara',
    codice: '020073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'M406',
    cap: ['46021'],
    popolazione: 2123,
  },
  {
    nome: "Borgofranco d'Ivrea",
    codice: '001030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B015',
    cap: ['10013'],
    popolazione: 3643,
  },
  {
    nome: 'Borgolavezzaro',
    codice: '003023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B016',
    cap: ['28071'],
    popolazione: 2083,
  },
  {
    nome: 'Borgomale',
    codice: '004024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B018',
    cap: ['12050'],
    popolazione: 389,
  },
  {
    nome: 'Borgomanero',
    codice: '003024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B019',
    cap: ['28021'],
    popolazione: 21166,
  },
  {
    nome: 'Borgomaro',
    codice: '008010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'B020',
    cap: ['18021'],
    popolazione: 873,
  },
  {
    nome: 'Borgomasino',
    codice: '001031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B021',
    cap: ['10031'],
    popolazione: 835,
  },
  {
    nome: 'Borgomezzavalle',
    codice: '103078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'M370',
    cap: ['28846'],
    popolazione: 335,
  },
  {
    nome: 'Borgone Susa',
    codice: '001032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B024',
    cap: ['10050'],
    popolazione: 2320,
  },
  {
    nome: 'Borgonovo Val Tidone',
    codice: '033006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'B025',
    cap: ['29011'],
    popolazione: 7631,
  },
  {
    nome: 'Borgoratto Alessandrino',
    codice: '006019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B029',
    cap: ['15013'],
    popolazione: 617,
  },
  {
    nome: 'Borgoratto Mormorolo',
    codice: '018017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B030',
    cap: ['27040'],
    popolazione: 423,
  },
  {
    nome: 'Borgoricco',
    codice: '028013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B031',
    cap: ['35010'],
    popolazione: 8478,
  },
  {
    nome: 'Borgorose',
    codice: '057007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'B008',
    cap: ['02021'],
    popolazione: 4615,
  },
  {
    nome: 'Borgosatollo',
    codice: '017021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B040',
    cap: ['25010'],
    popolazione: 9094,
  },
  {
    nome: 'Borgosesia',
    codice: '002016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B041',
    cap: ['13011'],
    popolazione: 13031,
  },
  {
    nome: 'Bormida',
    codice: '009014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B048',
    cap: ['17045'],
    popolazione: 401,
  },
  {
    nome: 'Bormio',
    codice: '014009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'B049',
    cap: ['23032'],
    popolazione: 4036,
  },
  {
    nome: 'Bornasco',
    codice: '018019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B051',
    cap: ['27010'],
    popolazione: 2589,
  },
  {
    nome: 'Borno',
    codice: '017022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B054',
    cap: ['25042'],
    popolazione: 2630,
  },
  {
    nome: 'Boroneddu',
    codice: '095016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'B055',
    cap: ['09080'],
    popolazione: 165,
  },
  {
    nome: 'Borore',
    codice: '091011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'B056',
    cap: ['08016'],
    popolazione: 2190,
  },
  {
    nome: 'Borrello',
    codice: '069007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B057',
    cap: ['66040'],
    popolazione: 368,
  },
  {
    nome: 'Borriana',
    codice: '096006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B058',
    cap: ['13872'],
    popolazione: 880,
  },
  {
    nome: 'Borso del Grappa',
    codice: '026004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B061',
    cap: ['31030'],
    popolazione: 5913,
  },
  {
    nome: 'Bortigali',
    codice: '091012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'B062',
    cap: ['08012'],
    popolazione: 1417,
  },
  {
    nome: 'Bortigiadas',
    codice: '090014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B063',
    cap: ['07030'],
    popolazione: 800,
  },
  {
    nome: 'Borutta',
    codice: '090015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B064',
    cap: ['07040'],
    popolazione: 285,
  },
  {
    nome: 'Borzonasca',
    codice: '010005',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B067',
    cap: ['16041'],
    popolazione: 2124,
  },
  {
    nome: 'Bosa',
    codice: '095079',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'B068',
    cap: ['09089'],
    popolazione: 8026,
  },
  {
    nome: 'Bosaro',
    codice: '029007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'B069',
    cap: ['45033'],
    popolazione: 1508,
  },
  {
    nome: "Boschi Sant'Anna",
    codice: '023010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B070',
    cap: ['37040'],
    popolazione: 1454,
  },
  {
    nome: 'Bosco Chiesanuova',
    codice: '023011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B073',
    cap: ['37021'],
    popolazione: 3546,
  },
  {
    nome: 'Bosco Marengo',
    codice: '006021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B071',
    cap: ['15062'],
    popolazione: 2531,
  },
  {
    nome: 'Bosconero',
    codice: '001033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B075',
    cap: ['10080'],
    popolazione: 3056,
  },
  {
    nome: 'Boscoreale',
    codice: '063008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B076',
    cap: ['80041'],
    popolazione: 27457,
  },
  {
    nome: 'Boscotrecase',
    codice: '063009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B077',
    cap: ['80042'],
    popolazione: 10416,
  },
  {
    nome: 'Bosia',
    codice: '004026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B079',
    cap: ['12050'],
    popolazione: 181,
  },
  {
    nome: 'Bosio',
    codice: '006022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B080',
    cap: ['15060'],
    popolazione: 1240,
  },
  {
    nome: 'Bosisio Parini',
    codice: '097009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B081',
    cap: ['23842'],
    popolazione: 3504,
  },
  {
    nome: 'Bosnasco',
    codice: '018020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B082',
    cap: ['27040'],
    popolazione: 633,
  },
  {
    nome: 'Bossico',
    codice: '016033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B083',
    cap: ['24060'],
    popolazione: 969,
  },
  {
    nome: 'Bossolasco',
    codice: '004027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B084',
    cap: ['12060'],
    popolazione: 676,
  },
  {
    nome: 'Botricello',
    codice: '079012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'B085',
    cap: ['88070'],
    popolazione: 4906,
  },
  {
    nome: 'Botrugno',
    codice: '075009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B086',
    cap: ['73020'],
    popolazione: 2851,
  },
  {
    nome: 'Bottanuco',
    codice: '016034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B088',
    cap: ['24040'],
    popolazione: 5176,
  },
  {
    nome: 'Botticino',
    codice: '017023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B091',
    cap: ['25082'],
    popolazione: 10788,
  },
  {
    nome: 'Bottidda',
    codice: '090016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B094',
    cap: ['07010'],
    popolazione: 736,
  },
  {
    nome: 'Bova',
    codice: '080011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B097',
    cap: ['89033'],
    popolazione: 461,
  },
  {
    nome: 'Bova Marina',
    codice: '080013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B099',
    cap: ['89035'],
    popolazione: 4142,
  },
  {
    nome: 'Bovalino',
    codice: '080012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B098',
    cap: ['89034'],
    popolazione: 8814,
  },
  {
    nome: 'Bovegno',
    codice: '017024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B100',
    cap: ['25061'],
    popolazione: 2269,
  },
  {
    nome: 'Boves',
    codice: '004028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B101',
    cap: ['12012'],
    popolazione: 9725,
  },
  {
    nome: 'Bovezzo',
    codice: '017025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B102',
    cap: ['25073'],
    popolazione: 7483,
  },
  {
    nome: 'Boville Ernica',
    codice: '060014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A720',
    cap: ['03022'],
    popolazione: 8811,
  },
  {
    nome: 'Bovino',
    codice: '071007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B104',
    cap: ['71023'],
    popolazione: 3562,
  },
  {
    nome: 'Bovisio-Masciago',
    codice: '108010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B105',
    cap: ['20813'],
    popolazione: 16596,
  },
  {
    nome: 'Bovolenta',
    codice: '028014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B106',
    cap: ['35024'],
    popolazione: 3349,
  },
  {
    nome: 'Bovolone',
    codice: '023012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B107',
    cap: ['37051'],
    popolazione: 15846,
  },
  {
    nome: 'Bozzole',
    codice: '006023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B109',
    cap: ['15040'],
    popolazione: 331,
  },
  {
    nome: 'Bozzolo',
    codice: '020007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'B110',
    cap: ['46012'],
    popolazione: 4181,
  },
  {
    nome: 'Bra',
    codice: '004029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B111',
    cap: ['12042'],
    popolazione: 28935,
  },
  {
    nome: 'Bracca',
    codice: '016035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B112',
    cap: ['24010'],
    popolazione: 749,
  },
  {
    nome: 'Bracciano',
    codice: '058013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B114',
    cap: ['00062'],
    popolazione: 18549,
  },
  {
    nome: 'Bracigliano',
    codice: '065016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B115',
    cap: ['84082'],
    popolazione: 5439,
  },
  {
    nome: 'Braies',
    codice: '021009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B116',
    cap: ['39030'],
    popolazione: 672,
  },
  {
    nome: 'Brallo di Pregola',
    codice: '018021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B117',
    cap: ['27050'],
    popolazione: 689,
  },
  {
    nome: 'Brancaleone',
    codice: '080014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B118',
    cap: ['89036'],
    popolazione: 3624,
  },
  {
    nome: 'Brandico',
    codice: '017026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B120',
    cap: ['25030'],
    popolazione: 1611,
  },
  {
    nome: 'Brandizzo',
    codice: '001034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B121',
    cap: ['10032'],
    popolazione: 8402,
  },
  {
    nome: 'Branzi',
    codice: '016036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B123',
    cap: ['24010'],
    popolazione: 732,
  },
  {
    nome: 'Braone',
    codice: '017027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B124',
    cap: ['25040'],
    popolazione: 657,
  },
  {
    nome: 'Brebbia',
    codice: '012017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B126',
    cap: ['21020'],
    popolazione: 3362,
  },
  {
    nome: 'Breda di Piave',
    codice: '026005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B128',
    cap: ['31030'],
    popolazione: 7750,
  },
  {
    nome: 'Bregano',
    codice: '012018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B131',
    cap: ['21020'],
    popolazione: 839,
  },
  {
    nome: 'Breganze',
    codice: '024014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B132',
    cap: ['36042'],
    popolazione: 8693,
  },
  {
    nome: 'Bregnano',
    codice: '013028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B134',
    cap: ['22070'],
    popolazione: 6229,
  },
  {
    nome: 'Brembate',
    codice: '016037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B137',
    cap: ['24041'],
    popolazione: 8293,
  },
  {
    nome: 'Brembate di Sopra',
    codice: '016038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B138',
    cap: ['24030'],
    popolazione: 7771,
  },
  {
    nome: 'Brembio',
    codice: '098006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B141',
    cap: ['26822'],
    popolazione: 2647,
  },
  {
    nome: 'Breme',
    codice: '018022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B142',
    cap: ['27020'],
    popolazione: 853,
  },
  {
    nome: 'Brendola',
    codice: '024015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B143',
    cap: ['36040'],
    popolazione: 6722,
  },
  {
    nome: 'Brenna',
    codice: '013029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B144',
    cap: ['22040'],
    popolazione: 2011,
  },
  {
    nome: 'Brennero',
    codice: '021010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B145',
    cap: ['39041'],
    popolazione: 2087,
  },
  {
    nome: 'Breno',
    codice: '017028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B149',
    cap: ['25043'],
    popolazione: 4920,
  },
  {
    nome: 'Brenta',
    codice: '012019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B150',
    cap: ['21030'],
    popolazione: 1798,
  },
  {
    nome: 'Brentino Belluno',
    codice: '023013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B152',
    cap: ['37020'],
    popolazione: 1406,
  },
  {
    nome: 'Brentonico',
    codice: '022025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B153',
    cap: ['38060'],
    popolazione: 3882,
  },
  {
    nome: 'Brenzone sul Garda',
    codice: '023014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B154',
    cap: ['37010'],
    popolazione: 2496,
  },
  {
    nome: 'Brescello',
    codice: '035006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B156',
    cap: ['42041'],
    popolazione: 5546,
  },
  {
    nome: 'Brescia',
    codice: '017029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B157',
    cap: [
      '25121',
      '25122',
      '25123',
      '25124',
      '25125',
      '25126',
      '25127',
      '25128',
      '25129',
      '25130',
      '25131',
      '25132',
      '25133',
      '25134',
      '25135',
      '25136',
    ],
    popolazione: 189902,
  },
  {
    nome: 'Bresimo',
    codice: '022026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B158',
    cap: ['38020'],
    popolazione: 254,
  },
  {
    nome: 'Bressana Bottarone',
    codice: '018023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B159',
    cap: ['27042'],
    popolazione: 3535,
  },
  {
    nome: 'Bressanone',
    codice: '021011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B160',
    cap: ['39042'],
    popolazione: 20677,
  },
  {
    nome: 'Bressanvido',
    codice: '024016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B161',
    cap: ['36050'],
    popolazione: 3108,
  },
  {
    nome: 'Bresso',
    codice: '015032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B162',
    cap: ['20091'],
    popolazione: 25712,
  },
  {
    nome: 'Brezzo di Bedero',
    codice: '012020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B166',
    cap: ['21010'],
    popolazione: 1185,
  },
  {
    nome: 'Briaglia',
    codice: '004030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B167',
    cap: ['12080'],
    popolazione: 324,
  },
  {
    nome: 'Briatico',
    codice: '102003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'B169',
    cap: ['89817'],
    popolazione: 3983,
  },
  {
    nome: 'Bricherasio',
    codice: '001035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B171',
    cap: ['10060'],
    popolazione: 4517,
  },
  {
    nome: 'Brienno',
    codice: '013030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B172',
    cap: ['22010'],
    popolazione: 402,
  },
  {
    nome: 'Brienza',
    codice: '076013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B173',
    cap: ['85050'],
    popolazione: 4082,
  },
  {
    nome: 'Briga Alta',
    codice: '004031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B175',
    cap: ['18025'],
    popolazione: 48,
  },
  {
    nome: 'Briga Novarese',
    codice: '003026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B176',
    cap: ['28010'],
    popolazione: 3050,
  },
  {
    nome: "Brignano Gera d'Adda",
    codice: '016040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B178',
    cap: ['24053'],
    popolazione: 5937,
  },
  {
    nome: 'Brignano-Frascata',
    codice: '006024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B179',
    cap: ['15050'],
    popolazione: 451,
  },
  {
    nome: 'Brindisi',
    codice: '074001',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'B180',
    cap: ['72100'],
    popolazione: 88812,
  },
  {
    nome: 'Brindisi Montagna',
    codice: '076014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B181',
    cap: ['85010'],
    popolazione: 925,
  },
  {
    nome: 'Brinzio',
    codice: '012021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B182',
    cap: ['21030'],
    popolazione: 873,
  },
  {
    nome: 'Briona',
    codice: '003027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B183',
    cap: ['28072'],
    popolazione: 1234,
  },
  {
    nome: 'Brione',
    codice: '017030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B184',
    cap: ['25060'],
    popolazione: 685,
  },
  {
    nome: 'Briosco',
    codice: '108011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B187',
    cap: ['20836'],
    popolazione: 5966,
  },
  {
    nome: 'Brisighella',
    codice: '039004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'B188',
    cap: ['48013'],
    popolazione: 7664,
  },
  {
    nome: 'Brissago-Valtravaglia',
    codice: '012022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B191',
    cap: ['21030'],
    popolazione: 1250,
  },
  {
    nome: 'Brissogne',
    codice: '007011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'B192',
    cap: ['11020'],
    popolazione: 983,
  },
  {
    nome: 'Brittoli',
    codice: '068004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'B193',
    cap: ['65010'],
    popolazione: 335,
  },
  {
    nome: 'Brivio',
    codice: '097010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B194',
    cap: ['23883'],
    popolazione: 4686,
  },
  {
    nome: 'Broccostella',
    codice: '060015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'B195',
    cap: ['03030'],
    popolazione: 2807,
  },
  {
    nome: 'Brogliano',
    codice: '024017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B196',
    cap: ['36070'],
    popolazione: 3900,
  },
  {
    nome: 'Brognaturo',
    codice: '102004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'B197',
    cap: ['89822'],
    popolazione: 670,
  },
  {
    nome: 'Brolo',
    codice: '083007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'B198',
    cap: ['98061'],
    popolazione: 5826,
  },
  {
    nome: 'Brondello',
    codice: '004032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B200',
    cap: ['12030'],
    popolazione: 287,
  },
  {
    nome: 'Broni',
    codice: '018024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B201',
    cap: ['27043'],
    popolazione: 9073,
  },
  {
    nome: 'Bronte',
    codice: '087009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'B202',
    cap: ['95034'],
    popolazione: 19234,
  },
  {
    nome: 'Bronzolo',
    codice: '021012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B203',
    cap: ['39051'],
    popolazione: 2628,
  },
  {
    nome: 'Brossasco',
    codice: '004033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B204',
    cap: ['12020'],
    popolazione: 1109,
  },
  {
    nome: 'Brosso',
    codice: '001036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B205',
    cap: ['10080'],
    popolazione: 460,
  },
  {
    nome: 'Brovello-Carpugnino',
    codice: '103013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B207',
    cap: ['28833'],
    popolazione: 721,
  },
  {
    nome: 'Brozolo',
    codice: '001037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B209',
    cap: ['10020'],
    popolazione: 471,
  },
  {
    nome: 'Brugherio',
    codice: '108012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B212',
    cap: ['20861'],
    popolazione: 33170,
  },
  {
    nome: 'Brugine',
    codice: '028015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B213',
    cap: ['35020'],
    popolazione: 6812,
  },
  {
    nome: 'Brugnato',
    codice: '011007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'B214',
    cap: ['19020'],
    popolazione: 1266,
  },
  {
    nome: 'Brugnera',
    codice: '093007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'B215',
    cap: ['33070'],
    popolazione: 9254,
  },
  {
    nome: 'Bruino',
    codice: '001038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B216',
    cap: ['10090'],
    popolazione: 8479,
  },
  {
    nome: 'Brumano',
    codice: '016041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B217',
    cap: ['24037'],
    popolazione: 95,
  },
  {
    nome: 'Brunate',
    codice: '013032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B218',
    cap: ['22034'],
    popolazione: 1766,
  },
  {
    nome: 'Brunello',
    codice: '012023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B219',
    cap: ['21020'],
    popolazione: 1030,
  },
  {
    nome: 'Brunico',
    codice: '021013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B220',
    cap: ['39031'],
    popolazione: 15397,
  },
  {
    nome: 'Bruno',
    codice: '005010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B221',
    cap: ['14046'],
    popolazione: 351,
  },
  {
    nome: 'Brusaporto',
    codice: '016042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B223',
    cap: ['24060'],
    popolazione: 5393,
  },
  {
    nome: 'Brusasco',
    codice: '001039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B225',
    cap: ['10020'],
    popolazione: 1726,
  },
  {
    nome: 'Brusciano',
    codice: '063010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B227',
    cap: ['80031'],
    popolazione: 16010,
  },
  {
    nome: 'Brusimpiano',
    codice: '012024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B228',
    cap: ['21050'],
    popolazione: 1208,
  },
  {
    nome: 'Brusnengo',
    codice: '096007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B229',
    cap: ['13862'],
    popolazione: 2168,
  },
  {
    nome: 'Brusson',
    codice: '007012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'B230',
    cap: ['11022'],
    popolazione: 852,
  },
  {
    nome: 'Bruzolo',
    codice: '001040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B232',
    cap: ['10050'],
    popolazione: 1545,
  },
  {
    nome: 'Bruzzano Zeffirio',
    codice: '080015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B234',
    cap: ['89030'],
    popolazione: 1211,
  },
  {
    nome: 'Bubbiano',
    codice: '015035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B235',
    cap: ['20080'],
    popolazione: 2215,
  },
  {
    nome: 'Bubbio',
    codice: '005011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B236',
    cap: ['14051'],
    popolazione: 912,
  },
  {
    nome: 'Buccheri',
    codice: '089003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'B237',
    cap: ['96010'],
    popolazione: 2133,
  },
  {
    nome: 'Bucchianico',
    codice: '069008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B238',
    cap: ['66011'],
    popolazione: 5221,
  },
  {
    nome: 'Bucciano',
    codice: '062010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'B239',
    cap: ['82010'],
    popolazione: 2077,
  },
  {
    nome: 'Buccinasco',
    codice: '015036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B240',
    cap: ['20090'],
    popolazione: 26503,
  },
  {
    nome: 'Buccino',
    codice: '065017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B242',
    cap: ['84021'],
    popolazione: 5248,
  },
  {
    nome: 'Bucine',
    codice: '051005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'B243',
    cap: ['52021'],
    popolazione: 10033,
  },
  {
    nome: 'Buddusò',
    codice: '090017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B246',
    cap: ['07020'],
    popolazione: 3979,
  },
  {
    nome: 'Budoia',
    codice: '093008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'B247',
    cap: ['33070'],
    popolazione: 2552,
  },
  {
    nome: 'Budoni',
    codice: '090091',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B248',
    cap: ['07051'],
    popolazione: 4846,
  },
  {
    nome: 'Budrio',
    codice: '037008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B249',
    cap: ['40054'],
    popolazione: 18008,
  },
  {
    nome: 'Buggerru',
    codice: '111006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'B250',
    cap: ['09010'],
    popolazione: 1108,
  },
  {
    nome: 'Buggiano',
    codice: '047003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'B251',
    cap: ['51011'],
    popolazione: 8735,
  },
  {
    nome: 'Buglio in Monte',
    codice: '014010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'B255',
    cap: ['23010'],
    popolazione: 2091,
  },
  {
    nome: 'Bugnara',
    codice: '066012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B256',
    cap: ['67030'],
    popolazione: 1106,
  },
  {
    nome: 'Buguggiate',
    codice: '012025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B258',
    cap: ['21020'],
    popolazione: 3115,
  },
  {
    nome: 'Buja',
    codice: '030013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'B259',
    cap: ['33030'],
    popolazione: 6627,
  },
  {
    nome: 'Bulciago',
    codice: '097011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B261',
    cap: ['23892'],
    popolazione: 2948,
  },
  {
    nome: 'Bulgarograsso',
    codice: '013034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B262',
    cap: ['22070'],
    popolazione: 3883,
  },
  {
    nome: 'Bultei',
    codice: '090018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B264',
    cap: ['07010'],
    popolazione: 1046,
  },
  {
    nome: 'Bulzi',
    codice: '090019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B265',
    cap: ['07030'],
    popolazione: 552,
  },
  {
    nome: 'Buonabitacolo',
    codice: '065018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B266',
    cap: ['84032'],
    popolazione: 2571,
  },
  {
    nome: 'Buonalbergo',
    codice: '062011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'B267',
    cap: ['82020'],
    popolazione: 1824,
  },
  {
    nome: 'Buonconvento',
    codice: '052003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'B269',
    cap: ['53022'],
    popolazione: 3182,
  },
  {
    nome: 'Buonvicino',
    codice: '078020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B270',
    cap: ['87020'],
    popolazione: 2354,
  },
  {
    nome: 'Burago di Molgora',
    codice: '108013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B272',
    cap: ['20875'],
    popolazione: 4240,
  },
  {
    nome: 'Burcei',
    codice: '111007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'B274',
    cap: ['09040'],
    popolazione: 2896,
  },
  {
    nome: 'Burgio',
    codice: '084005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B275',
    cap: ['92010'],
    popolazione: 2780,
  },
  {
    nome: 'Burgos',
    codice: '090020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B276',
    cap: ['07010'],
    popolazione: 944,
  },
  {
    nome: 'Buriasco',
    codice: '001041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B278',
    cap: ['10060'],
    popolazione: 1405,
  },
  {
    nome: 'Burolo',
    codice: '001042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B279',
    cap: ['10010'],
    popolazione: 1228,
  },
  {
    nome: 'Buronzo',
    codice: '002021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B280',
    cap: ['13040'],
    popolazione: 916,
  },
  {
    nome: 'Busachi',
    codice: '095017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'B281',
    cap: ['09082'],
    popolazione: 1379,
  },
  {
    nome: 'Busalla',
    codice: '010006',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B282',
    cap: ['16012'],
    popolazione: 5741,
  },
  {
    nome: 'Busano',
    codice: '001043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B284',
    cap: ['10080'],
    popolazione: 1588,
  },
  {
    nome: 'Busca',
    codice: '004034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B285',
    cap: ['12022'],
    popolazione: 10113,
  },
  {
    nome: 'Buscate',
    codice: '015038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B286',
    cap: ['20010'],
    popolazione: 4751,
  },
  {
    nome: 'Buscemi',
    codice: '089004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'B287',
    cap: ['96010'],
    popolazione: 1128,
  },
  {
    nome: 'Buseto Palizzolo',
    codice: '081002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'B288',
    cap: ['91012'],
    popolazione: 3031,
  },
  {
    nome: 'Busnago',
    codice: '108051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B289',
    cap: ['20874'],
    popolazione: 6413,
  },
  {
    nome: 'Bussero',
    codice: '015040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B292',
    cap: ['20060'],
    popolazione: 8532,
  },
  {
    nome: 'Busseto',
    codice: '034007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'B293',
    cap: ['43011'],
    popolazione: 7043,
  },
  {
    nome: 'Bussi sul Tirino',
    codice: '068005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'B294',
    cap: ['65022'],
    popolazione: 2636,
  },
  {
    nome: 'Busso',
    codice: '070005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B295',
    cap: ['86010'],
    popolazione: 1367,
  },
  {
    nome: 'Bussolengo',
    codice: '023015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B296',
    cap: ['37012'],
    popolazione: 19483,
  },
  {
    nome: 'Bussoleno',
    codice: '001044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B297',
    cap: ['10053'],
    popolazione: 6363,
  },
  {
    nome: 'Busto Arsizio',
    codice: '012026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B300',
    cap: ['21052'],
    popolazione: 79692,
  },
  {
    nome: 'Busto Garolfo',
    codice: '015041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B301',
    cap: ['20020'],
    popolazione: 13479,
  },
  {
    nome: 'Butera',
    codice: '085003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'B302',
    cap: ['93011'],
    popolazione: 4937,
  },
  {
    nome: 'Buti',
    codice: '050002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'B303',
    cap: ['56032'],
    popolazione: 5773,
  },
  {
    nome: 'Buttapietra',
    codice: '023016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B304',
    cap: ['37060'],
    popolazione: 6867,
  },
  {
    nome: 'Buttigliera Alta',
    codice: '001045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B305',
    cap: ['10090'],
    popolazione: 6386,
  },
  {
    nome: "Buttigliera d'Asti",
    codice: '005012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B306',
    cap: ['14021'],
    popolazione: 2552,
  },
  {
    nome: 'Buttrio',
    codice: '030014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'B309',
    cap: ['33042'],
    popolazione: 4039,
  },
  {
    nome: 'Cabella Ligure',
    codice: '006025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B311',
    cap: ['15060'],
    popolazione: 554,
  },
  {
    nome: 'Cabiate',
    codice: '013035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B313',
    cap: ['22060'],
    popolazione: 7412,
  },
  {
    nome: 'Cabras',
    codice: '095018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'B314',
    cap: ['09072'],
    popolazione: 9032,
  },
  {
    nome: 'Caccamo',
    codice: '082014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B315',
    cap: ['90012'],
    popolazione: 8295,
  },
  {
    nome: 'Caccuri',
    codice: '101002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'B319',
    cap: ['88833'],
    popolazione: 1695,
  },
  {
    nome: 'Cadegliano-Viconago',
    codice: '012027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B326',
    cap: ['21031'],
    popolazione: 1867,
  },
  {
    nome: 'Cadelbosco di Sopra',
    codice: '035008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B328',
    cap: ['42023'],
    popolazione: 10409,
  },
  {
    nome: 'Cadeo',
    codice: '033007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'B332',
    cap: ['29010'],
    popolazione: 6052,
  },
  {
    nome: 'Caderzone Terme',
    codice: '022029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B335',
    cap: ['38080'],
    popolazione: 669,
  },
  {
    nome: 'Cadoneghe',
    codice: '028016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B345',
    cap: ['35010'],
    popolazione: 15964,
  },
  {
    nome: 'Cadorago',
    codice: '013036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B346',
    cap: ['22071'],
    popolazione: 7631,
  },
  {
    nome: 'Cadrezzate con Osmate',
    codice: '012143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'M425',
    cap: ['21062'],
    popolazione: 2590,
  },
  {
    nome: 'Caerano di San Marco',
    codice: '026006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B349',
    cap: ['31031'],
    popolazione: 7941,
  },
  {
    nome: 'Cafasse',
    codice: '001046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B350',
    cap: ['10070'],
    popolazione: 3511,
  },
  {
    nome: 'Caggiano',
    codice: '065019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B351',
    cap: ['84030'],
    popolazione: 2803,
  },
  {
    nome: 'Cagli',
    codice: '041007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'B352',
    cap: ['61043'],
    popolazione: 9013,
  },
  {
    nome: 'Cagliari',
    codice: '092009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'B354',
    cap: [
      '09121',
      '09122',
      '09123',
      '09124',
      '09125',
      '09126',
      '09127',
      '09128',
      '09129',
      '09130',
      '09131',
      '09132',
      '09133',
      '09134',
    ],
    popolazione: 149883,
  },
  {
    nome: 'Caglio',
    codice: '013037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B355',
    cap: ['22030'],
    popolazione: 430,
  },
  {
    nome: 'Cagnano Amiterno',
    codice: '066013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B358',
    cap: ['67012'],
    popolazione: 1383,
  },
  {
    nome: 'Cagnano Varano',
    codice: '071008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B357',
    cap: ['71010'],
    popolazione: 7451,
  },
  {
    nome: 'Caianello',
    codice: '061008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B361',
    cap: ['81059'],
    popolazione: 1782,
  },
  {
    nome: 'Caiazzo',
    codice: '061009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B362',
    cap: ['81013'],
    popolazione: 5657,
  },
  {
    nome: 'Caines',
    codice: '021014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B364',
    cap: ['39010'],
    popolazione: 413,
  },
  {
    nome: 'Caino',
    codice: '017031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B365',
    cap: ['25070'],
    popolazione: 2079,
  },
  {
    nome: 'Caiolo',
    codice: '014011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'B366',
    cap: ['23010'],
    popolazione: 1035,
  },
  {
    nome: 'Cairano',
    codice: '064013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B367',
    cap: ['83040'],
    popolazione: 348,
  },
  {
    nome: 'Cairate',
    codice: '012029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B368',
    cap: ['21050'],
    popolazione: 7830,
  },
  {
    nome: 'Cairo Montenotte',
    codice: '009015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B369',
    cap: ['17014'],
    popolazione: 13237,
  },
  {
    nome: 'Caivano',
    codice: '063011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B371',
    cap: ['80023'],
    popolazione: 37654,
  },
  {
    nome: 'Calabritto',
    codice: '064014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B374',
    cap: ['83040'],
    popolazione: 2500,
  },
  {
    nome: 'Calalzo di Cadore',
    codice: '025008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'B375',
    cap: ['32042'],
    popolazione: 2173,
  },
  {
    nome: 'Calamandrana',
    codice: '005013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B376',
    cap: ['14042'],
    popolazione: 1784,
  },
  {
    nome: 'Calamonaci',
    codice: '084006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B377',
    cap: ['92010'],
    popolazione: 1375,
  },
  {
    nome: 'Calangianus',
    codice: '090021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B378',
    cap: ['07023'],
    popolazione: 4267,
  },
  {
    nome: 'Calanna',
    codice: '080016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B379',
    cap: ['89050'],
    popolazione: 979,
  },
  {
    nome: 'Calasca-Castiglione',
    codice: '103014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B380',
    cap: ['28873'],
    popolazione: 681,
  },
  {
    nome: 'Calascibetta',
    codice: '086005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'B381',
    cap: ['94010'],
    popolazione: 4628,
  },
  {
    nome: 'Calascio',
    codice: '066014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B382',
    cap: ['67020'],
    popolazione: 137,
  },
  {
    nome: 'Calasetta',
    codice: '111008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'B383',
    cap: ['09011'],
    popolazione: 2822,
  },
  {
    nome: 'Calatabiano',
    codice: '087010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'B384',
    cap: ['95011'],
    popolazione: 5383,
  },
  {
    nome: 'Calatafimi-Segesta',
    codice: '081003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'B385',
    cap: ['91013'],
    popolazione: 6938,
  },
  {
    nome: 'Calcata',
    codice: '056010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B388',
    cap: ['01030'],
    popolazione: 905,
  },
  {
    nome: 'Calceranica al Lago',
    codice: '022032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B389',
    cap: ['38050'],
    popolazione: 1305,
  },
  {
    nome: 'Calci',
    codice: '050003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'B390',
    cap: ['56011'],
    popolazione: 6409,
  },
  {
    nome: 'Calciano',
    codice: '077004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'B391',
    cap: ['75010'],
    popolazione: 796,
  },
  {
    nome: 'Calcinaia',
    codice: '050004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'B392',
    cap: ['56012'],
    popolazione: 11684,
  },
  {
    nome: 'Calcinate',
    codice: '016043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B393',
    cap: ['24050'],
    popolazione: 5782,
  },
  {
    nome: 'Calcinato',
    codice: '017032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B394',
    cap: ['25011'],
    popolazione: 12599,
  },
  {
    nome: 'Calcio',
    codice: '016044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B395',
    cap: ['24054'],
    popolazione: 5336,
  },
  {
    nome: 'Calco',
    codice: '097012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B396',
    cap: ['23885'],
    popolazione: 5113,
  },
  {
    nome: 'Caldaro sulla strada del vino',
    codice: '021015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B397',
    cap: ['39052'],
    popolazione: 7660,
  },
  {
    nome: 'Caldarola',
    codice: '043006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'B398',
    cap: ['62020'],
    popolazione: 1839,
  },
  {
    nome: 'Calderara di Reno',
    codice: '037009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B399',
    cap: ['40012'],
    popolazione: 13148,
  },
  {
    nome: 'Caldes',
    codice: '022033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B400',
    cap: ['38022'],
    popolazione: 1090,
  },
  {
    nome: 'Caldiero',
    codice: '023017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B402',
    cap: ['37042'],
    popolazione: 7374,
  },
  {
    nome: 'Caldogno',
    codice: '024018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B403',
    cap: ['36030'],
    popolazione: 11221,
  },
  {
    nome: 'Caldonazzo',
    codice: '022034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B404',
    cap: ['38052'],
    popolazione: 3340,
  },
  {
    nome: 'Calendasco',
    codice: '033008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'B405',
    cap: ['29010'],
    popolazione: 2448,
  },
  {
    nome: 'Calenzano',
    codice: '048005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'B406',
    cap: ['50041'],
    popolazione: 16637,
  },
  {
    nome: 'Calestano',
    codice: '034008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'B408',
    cap: ['43030'],
    popolazione: 2033,
  },
  {
    nome: 'Calice al Cornoviglio',
    codice: '011008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'B410',
    cap: ['19020'],
    popolazione: 1146,
  },
  {
    nome: 'Calice Ligure',
    codice: '009016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B409',
    cap: ['17020'],
    popolazione: 1683,
  },
  {
    nome: 'Calimera',
    codice: '075010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B413',
    cap: ['73021'],
    popolazione: 7264,
  },
  {
    nome: 'Calitri',
    codice: '064015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B415',
    cap: ['83045'],
    popolazione: 4921,
  },
  {
    nome: 'Calizzano',
    codice: '009017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B416',
    cap: ['17057'],
    popolazione: 1550,
  },
  {
    nome: 'Callabiana',
    codice: '096008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B417',
    cap: ['13821'],
    popolazione: 149,
  },
  {
    nome: 'Calliano',
    codice: '005014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B418',
    cap: ['14031'],
    popolazione: 1392,
  },
  {
    nome: 'Calliano',
    codice: '022035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B419',
    cap: ['38060'],
    popolazione: 1611,
  },
  {
    nome: 'Calolziocorte',
    codice: '097013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B423',
    cap: ['23801'],
    popolazione: 14009,
  },
  {
    nome: 'Calopezzati',
    codice: '078021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B424',
    cap: ['87060'],
    popolazione: 1293,
  },
  {
    nome: 'Calosso',
    codice: '005015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B425',
    cap: ['14052'],
    popolazione: 1331,
  },
  {
    nome: 'Caloveto',
    codice: '078022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B426',
    cap: ['87060'],
    popolazione: 1283,
  },
  {
    nome: 'Caltabellotta',
    codice: '084007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B427',
    cap: ['92010'],
    popolazione: 3907,
  },
  {
    nome: 'Caltagirone',
    codice: '087011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'B428',
    cap: ['95041'],
    popolazione: 38123,
  },
  {
    nome: 'Caltanissetta',
    codice: '085004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'B429',
    cap: ['93100'],
    popolazione: 61711,
  },
  {
    nome: 'Caltavuturo',
    codice: '082015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B430',
    cap: ['90022'],
    popolazione: 4171,
  },
  {
    nome: 'Caltignaga',
    codice: '003030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B431',
    cap: ['28010'],
    popolazione: 2585,
  },
  {
    nome: 'Calto',
    codice: '029008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'B432',
    cap: ['45030'],
    popolazione: 819,
  },
  {
    nome: 'Caltrano',
    codice: '024019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B433',
    cap: ['36030'],
    popolazione: 2607,
  },
  {
    nome: "Calusco d'Adda",
    codice: '016046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B434',
    cap: ['24033'],
    popolazione: 8233,
  },
  {
    nome: 'Caluso',
    codice: '001047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B435',
    cap: ['10014'],
    popolazione: 7483,
  },
  {
    nome: 'Calvagese della Riviera',
    codice: '017033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B436',
    cap: ['25080'],
    popolazione: 3461,
  },
  {
    nome: 'Calvanico',
    codice: '065020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B437',
    cap: ['84080'],
    popolazione: 1570,
  },
  {
    nome: 'Calvatone',
    codice: '019009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B439',
    cap: ['26030'],
    popolazione: 1260,
  },
  {
    nome: 'Calvello',
    codice: '076015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B440',
    cap: ['85010'],
    popolazione: 1953,
  },
  {
    nome: 'Calvene',
    codice: '024020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B441',
    cap: ['36030'],
    popolazione: 1323,
  },
  {
    nome: 'Calvenzano',
    codice: '016047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B442',
    cap: ['24040'],
    popolazione: 4061,
  },
  {
    nome: 'Calvera',
    codice: '076016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B443',
    cap: ['85030'],
    popolazione: 430,
  },
  {
    nome: 'Calvi',
    codice: '062012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'B444',
    cap: ['82018'],
    popolazione: 2616,
  },
  {
    nome: "Calvi dell'Umbria",
    codice: '055008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'B446',
    cap: ['05032'],
    popolazione: 1883,
  },
  {
    nome: 'Calvi Risorta',
    codice: '061010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B445',
    cap: ['81042'],
    popolazione: 5785,
  },
  {
    nome: 'Calvignano',
    codice: '018025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B447',
    cap: ['27040'],
    popolazione: 128,
  },
  {
    nome: 'Calvignasco',
    codice: '015042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B448',
    cap: ['20080'],
    popolazione: 1182,
  },
  {
    nome: 'Calvisano',
    codice: '017034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B450',
    cap: ['25012'],
    popolazione: 8537,
  },
  {
    nome: 'Calvizzano',
    codice: '063012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B452',
    cap: ['80012'],
    popolazione: 12537,
  },
  {
    nome: 'Camagna Monferrato',
    codice: '006026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B453',
    cap: ['15030'],
    popolazione: 510,
  },
  {
    nome: 'Camaiore',
    codice: '046005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'B455',
    cap: ['55041'],
    popolazione: 32083,
  },
  {
    nome: 'Camandona',
    codice: '096009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B457',
    cap: ['13821'],
    popolazione: 359,
  },
  {
    nome: 'Camastra',
    codice: '084008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B460',
    cap: ['92020'],
    popolazione: 2163,
  },
  {
    nome: 'Cambiago',
    codice: '015044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B461',
    cap: ['20040'],
    popolazione: 6508,
  },
  {
    nome: 'Cambiano',
    codice: '001048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B462',
    cap: ['10020'],
    popolazione: 6215,
  },
  {
    nome: 'Cambiasca',
    codice: '103015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B463',
    cap: ['28814'],
    popolazione: 1646,
  },
  {
    nome: 'Camburzano',
    codice: '096010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B465',
    cap: ['13891'],
    popolazione: 1227,
  },
  {
    nome: 'Camerana',
    codice: '004035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B467',
    cap: ['12072'],
    popolazione: 655,
  },
  {
    nome: 'Camerano',
    codice: '042006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'B468',
    cap: ['60021'],
    popolazione: 7213,
  },
  {
    nome: 'Camerano Casasco',
    codice: '005016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B469',
    cap: ['14020'],
    popolazione: 480,
  },
  {
    nome: 'Camerata Cornello',
    codice: '016048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B471',
    cap: ['24010'],
    popolazione: 627,
  },
  {
    nome: 'Camerata Nuova',
    codice: '058014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B472',
    cap: ['00020'],
    popolazione: 460,
  },
  {
    nome: 'Camerata Picena',
    codice: '042007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'B470',
    cap: ['60020'],
    popolazione: 2419,
  },
  {
    nome: 'Cameri',
    codice: '003032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B473',
    cap: ['28062'],
    popolazione: 10770,
  },
  {
    nome: 'Camerino',
    codice: '043007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'B474',
    cap: ['62032'],
    popolazione: 6902,
  },
  {
    nome: 'Camerota',
    codice: '065021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B476',
    cap: ['84059'],
    popolazione: 6751,
  },
  {
    nome: 'Camigliano',
    codice: '061011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B477',
    cap: ['81050'],
    popolazione: 1902,
  },
  {
    nome: 'Camini',
    codice: '080017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B481',
    cap: ['89040'],
    popolazione: 715,
  },
  {
    nome: 'Camino',
    codice: '006027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B482',
    cap: ['15020'],
    popolazione: 802,
  },
  {
    nome: 'Camino al Tagliamento',
    codice: '030015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'B483',
    cap: ['33030'],
    popolazione: 1660,
  },
  {
    nome: 'Camisano',
    codice: '019010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B484',
    cap: ['26010'],
    popolazione: 1303,
  },
  {
    nome: 'Camisano Vicentino',
    codice: '024021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B485',
    cap: ['36043'],
    popolazione: 10624,
  },
  {
    nome: 'Cammarata',
    codice: '084009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B486',
    cap: ['92022'],
    popolazione: 6275,
  },
  {
    nome: 'Camogli',
    codice: '010007',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B490',
    cap: ['16032'],
    popolazione: 5481,
  },
  {
    nome: 'Campagna',
    codice: '065022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B492',
    cap: ['84022'],
    popolazione: 15953,
  },
  {
    nome: 'Campagna Lupia',
    codice: '027002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'B493',
    cap: ['30010'],
    popolazione: 6936,
  },
  {
    nome: 'Campagnano di Roma',
    codice: '058015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B496',
    cap: ['00063'],
    popolazione: 11107,
  },
  {
    nome: 'Campagnatico',
    codice: '053002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'B497',
    cap: ['58042'],
    popolazione: 2498,
  },
  {
    nome: 'Campagnola Cremasca',
    codice: '019011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B498',
    cap: ['26010'],
    popolazione: 683,
  },
  {
    nome: 'Campagnola Emilia',
    codice: '035009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B499',
    cap: ['42012'],
    popolazione: 5493,
  },
  {
    nome: 'Campana',
    codice: '078023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B500',
    cap: ['87061'],
    popolazione: 1962,
  },
  {
    nome: 'Camparada',
    codice: '108014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B501',
    cap: ['20857'],
    popolazione: 2074,
  },
  {
    nome: 'Campegine',
    codice: '035010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B502',
    cap: ['42040'],
    popolazione: 5114,
  },
  {
    nome: 'Campello sul Clitunno',
    codice: '054005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'B504',
    cap: ['06042'],
    popolazione: 2500,
  },
  {
    nome: 'Campertogno',
    codice: '002025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B505',
    cap: ['13023'],
    popolazione: 243,
  },
  {
    nome: 'Campi Bisenzio',
    codice: '048006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'B507',
    cap: ['50013'],
    popolazione: 42929,
  },
  {
    nome: 'Campi Salentina',
    codice: '075011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B506',
    cap: ['73012'],
    popolazione: 10760,
  },
  {
    nome: 'Campiglia Cervo',
    codice: '096086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M373',
    cap: ['13812'],
    popolazione: 528,
  },
  {
    nome: 'Campiglia dei Berici',
    codice: '024022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B511',
    cap: ['36020'],
    popolazione: 1791,
  },
  {
    nome: 'Campiglia Marittima',
    codice: '049002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'B509',
    cap: ['57021'],
    popolazione: 13220,
  },
  {
    nome: 'Campiglione Fenile',
    codice: '001049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B512',
    cap: ['10060'],
    popolazione: 1382,
  },
  {
    nome: "Campione d'Italia",
    codice: '013040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B513',
    cap: ['22060'],
    popolazione: 2158,
  },
  {
    nome: 'Campitello di Fassa',
    codice: '022036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B514',
    cap: ['38031'],
    popolazione: 737,
  },
  {
    nome: 'Campli',
    codice: '067008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'B515',
    cap: ['64012'],
    popolazione: 7276,
  },
  {
    nome: 'Campo Calabro',
    codice: '080018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B516',
    cap: ['89052'],
    popolazione: 4410,
  },
  {
    nome: 'Campo di Giove',
    codice: '066015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B526',
    cap: ['67030'],
    popolazione: 847,
  },
  {
    nome: 'Campo di Trens',
    codice: '021016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B529',
    cap: ['39040'],
    popolazione: 2671,
  },
  {
    nome: 'Campo Ligure',
    codice: '010008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B538',
    cap: ['16013'],
    popolazione: 3045,
  },
  {
    nome: "Campo nell'Elba",
    codice: '049003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'B553',
    cap: ['57034'],
    popolazione: 4553,
  },
  {
    nome: 'Campo San Martino',
    codice: '028020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B564',
    cap: ['35010'],
    popolazione: 5724,
  },
  {
    nome: 'Campo Tures',
    codice: '021017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B570',
    cap: ['39032'],
    popolazione: 5272,
  },
  {
    nome: 'Campobasso',
    codice: '070006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B519',
    cap: ['86100'],
    popolazione: 48747,
  },
  {
    nome: 'Campobello di Licata',
    codice: '084010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B520',
    cap: ['92023'],
    popolazione: 10438,
  },
  {
    nome: 'Campobello di Mazara',
    codice: '081004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'B521',
    cap: ['91021'],
    popolazione: 11580,
  },
  {
    nome: 'Campochiaro',
    codice: '070007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B522',
    cap: ['86020'],
    popolazione: 637,
  },
  {
    nome: 'Campodarsego',
    codice: '028017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B524',
    cap: ['35011'],
    popolazione: 14169,
  },
  {
    nome: 'Campodenno',
    codice: '022037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B525',
    cap: ['38010'],
    popolazione: 1498,
  },
  {
    nome: 'Campodimele',
    codice: '059003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'B527',
    cap: ['04020'],
    popolazione: 638,
  },
  {
    nome: 'Campodipietra',
    codice: '070008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B528',
    cap: ['86010'],
    popolazione: 2567,
  },
  {
    nome: 'Campodolcino',
    codice: '014012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'B530',
    cap: ['23021'],
    popolazione: 1037,
  },
  {
    nome: 'Campodoro',
    codice: '028018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B531',
    cap: ['35010'],
    popolazione: 2725,
  },
  {
    nome: 'Campofelice di Fitalia',
    codice: '082016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B533',
    cap: ['90030'],
    popolazione: 548,
  },
  {
    nome: 'Campofelice di Roccella',
    codice: '082017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B532',
    cap: ['90010'],
    popolazione: 6918,
  },
  {
    nome: 'Campofilone',
    codice: '109004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'B534',
    cap: ['63828'],
    popolazione: 1951,
  },
  {
    nome: 'Campofiorito',
    codice: '082018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B535',
    cap: ['90030'],
    popolazione: 1332,
  },
  {
    nome: 'Campoformido',
    codice: '030016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'B536',
    cap: ['33030'],
    popolazione: 7679,
  },
  {
    nome: 'Campofranco',
    codice: '085005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'B537',
    cap: ['93010'],
    popolazione: 3218,
  },
  {
    nome: 'Campogalliano',
    codice: '036003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'B539',
    cap: ['41011'],
    popolazione: 8514,
  },
  {
    nome: 'Campolattaro',
    codice: '062013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'B541',
    cap: ['82020'],
    popolazione: 1084,
  },
  {
    nome: 'Campoli Appennino',
    codice: '060016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'B543',
    cap: ['03030'],
    popolazione: 1749,
  },
  {
    nome: 'Campoli del Monte Taburno',
    codice: '062014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'B542',
    cap: ['82030'],
    popolazione: 1546,
  },
  {
    nome: 'Campolieto',
    codice: '070009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B544',
    cap: ['86040'],
    popolazione: 938,
  },
  {
    nome: 'Campolongo Maggiore',
    codice: '027003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'B546',
    cap: ['30010'],
    popolazione: 10342,
  },
  {
    nome: 'Campolongo Tapogliano',
    codice: '030138',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'M311',
    cap: ['33040'],
    popolazione: 1210,
  },
  {
    nome: 'Campomaggiore',
    codice: '076017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B549',
    cap: ['85010'],
    popolazione: 851,
  },
  {
    nome: 'Campomarino',
    codice: '070010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B550',
    cap: ['86042'],
    popolazione: 7068,
  },
  {
    nome: 'Campomorone',
    codice: '010009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B551',
    cap: ['16014'],
    popolazione: 7306,
  },
  {
    nome: 'Camponogara',
    codice: '027004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'B554',
    cap: ['30010'],
    popolazione: 12920,
  },
  {
    nome: 'Campora',
    codice: '065023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B555',
    cap: ['84040'],
    popolazione: 461,
  },
  {
    nome: 'Camporeale',
    codice: '082019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B556',
    cap: ['90043'],
    popolazione: 3448,
  },
  {
    nome: 'Camporgiano',
    codice: '046006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'B557',
    cap: ['55031'],
    popolazione: 2285,
  },
  {
    nome: 'Camporosso',
    codice: '008011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'B559',
    cap: ['18033'],
    popolazione: 5419,
  },
  {
    nome: 'Camporotondo di Fiastrone',
    codice: '043008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'B562',
    cap: ['62020'],
    popolazione: 589,
  },
  {
    nome: 'Camporotondo Etneo',
    codice: '087012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'B561',
    cap: ['95040'],
    popolazione: 4476,
  },
  {
    nome: 'Camposampiero',
    codice: '028019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B563',
    cap: ['35012'],
    popolazione: 12019,
  },
  {
    nome: 'Camposano',
    codice: '063013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B565',
    cap: ['80030'],
    popolazione: 5365,
  },
  {
    nome: 'Camposanto',
    codice: '036004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'B566',
    cap: ['41031'],
    popolazione: 3171,
  },
  {
    nome: 'Campospinoso',
    codice: '018026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B567',
    cap: ['27040'],
    popolazione: 990,
  },
  {
    nome: 'Campotosto',
    codice: '066016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B569',
    cap: ['67013'],
    popolazione: 586,
  },
  {
    nome: 'Camugnano',
    codice: '037010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B572',
    cap: ['40032'],
    popolazione: 2000,
  },
  {
    nome: 'Canal San Bovo',
    codice: '022038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B577',
    cap: ['38050'],
    popolazione: 1592,
  },
  {
    nome: 'Canale',
    codice: '004037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B573',
    cap: ['12043'],
    popolazione: 5636,
  },
  {
    nome: "Canale d'Agordo",
    codice: '025023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'B574',
    cap: ['32020'],
    popolazione: 1172,
  },
  {
    nome: 'Canale Monterano',
    codice: '058016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B576',
    cap: ['00060'],
    popolazione: 4071,
  },
  {
    nome: 'Canaro',
    codice: '029009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'B578',
    cap: ['45034'],
    popolazione: 2853,
  },
  {
    nome: 'Canazei',
    codice: '022039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B579',
    cap: ['38032'],
    popolazione: 1907,
  },
  {
    nome: 'Cancellara',
    codice: '076018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B580',
    cap: ['85010'],
    popolazione: 1396,
  },
  {
    nome: 'Cancello ed Arnone',
    codice: '061012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B581',
    cap: ['81030'],
    popolazione: 5428,
  },
  {
    nome: 'Canda',
    codice: '029010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'B582',
    cap: ['45020'],
    popolazione: 1007,
  },
  {
    nome: 'Candela',
    codice: '071009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B584',
    cap: ['71024'],
    popolazione: 2693,
  },
  {
    nome: 'Candelo',
    codice: '096012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B586',
    cap: ['13878'],
    popolazione: 7952,
  },
  {
    nome: 'Candia Canavese',
    codice: '001050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B588',
    cap: ['10010'],
    popolazione: 1286,
  },
  {
    nome: 'Candia Lomellina',
    codice: '018027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B587',
    cap: ['27031'],
    popolazione: 1636,
  },
  {
    nome: 'Candiana',
    codice: '028021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B589',
    cap: ['35020'],
    popolazione: 2468,
  },
  {
    nome: 'Candida',
    codice: '064016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B590',
    cap: ['83040'],
    popolazione: 1152,
  },
  {
    nome: 'Candidoni',
    codice: '080019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B591',
    cap: ['89020'],
    popolazione: 389,
  },
  {
    nome: 'Candiolo',
    codice: '001051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B592',
    cap: ['10060'],
    popolazione: 5566,
  },
  {
    nome: 'Canegrate',
    codice: '015046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B593',
    cap: ['20010'],
    popolazione: 12438,
  },
  {
    nome: 'Canelli',
    codice: '005017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B594',
    cap: ['14053'],
    popolazione: 10569,
  },
  {
    nome: 'Canepina',
    codice: '056011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B597',
    cap: ['01030'],
    popolazione: 3149,
  },
  {
    nome: 'Caneva',
    codice: '093009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'B598',
    cap: ['33070'],
    popolazione: 6504,
  },
  {
    nome: 'Canicattì',
    codice: '084011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'B602',
    cap: ['92024'],
    popolazione: 34863,
  },
  {
    nome: 'Canicattini Bagni',
    codice: '089005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'B603',
    cap: ['96010'],
    popolazione: 7186,
  },
  {
    nome: 'Canino',
    codice: '056012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B604',
    cap: ['01011'],
    popolazione: 5270,
  },
  {
    nome: 'Canischio',
    codice: '001052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B605',
    cap: ['10080'],
    popolazione: 294,
  },
  {
    nome: 'Canistro',
    codice: '066017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B606',
    cap: ['67050'],
    popolazione: 1023,
  },
  {
    nome: 'Canna',
    codice: '078024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B607',
    cap: ['87070'],
    popolazione: 785,
  },
  {
    nome: 'Cannalonga',
    codice: '065024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B608',
    cap: ['84040'],
    popolazione: 1081,
  },
  {
    nome: 'Cannara',
    codice: '054006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'B609',
    cap: ['06033'],
    popolazione: 4308,
  },
  {
    nome: 'Cannero Riviera',
    codice: '103016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B610',
    cap: ['28821'],
    popolazione: 973,
  },
  {
    nome: 'Canneto Pavese',
    codice: '018029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B613',
    cap: ['27044'],
    popolazione: 1423,
  },
  {
    nome: "Canneto sull'Oglio",
    codice: '020008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'B612',
    cap: ['46013'],
    popolazione: 4511,
  },
  {
    nome: 'Cannobio',
    codice: '103017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B615',
    cap: ['28822'],
    popolazione: 4992,
  },
  {
    nome: 'Cannole',
    codice: '075012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B616',
    cap: ['73020'],
    popolazione: 1754,
  },
  {
    nome: 'Canolo',
    codice: '080020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B617',
    cap: ['89040'],
    popolazione: 801,
  },
  {
    nome: "Canonica d'Adda",
    codice: '016049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B618',
    cap: ['24040'],
    popolazione: 4207,
  },
  {
    nome: 'Canosa di Puglia',
    codice: '110004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'B619',
    cap: ['76012'],
    popolazione: 30422,
  },
  {
    nome: 'Canosa Sannita',
    codice: '069010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B620',
    cap: ['66010'],
    popolazione: 1441,
  },
  {
    nome: 'Canosio',
    codice: '004038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B621',
    cap: ['12020'],
    popolazione: 82,
  },
  {
    nome: 'Canossa',
    codice: '035018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'C669',
    cap: ['42026'],
    popolazione: 3785,
  },
  {
    nome: 'Cansano',
    codice: '066018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B624',
    cap: ['67030'],
    popolazione: 282,
  },
  {
    nome: 'Cantagallo',
    codice: '100001',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'B626',
    cap: ['59025'],
    popolazione: 3102,
  },
  {
    nome: 'Cantalice',
    codice: '057009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'B627',
    cap: ['02014'],
    popolazione: 2726,
  },
  {
    nome: 'Cantalupa',
    codice: '001053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B628',
    cap: ['10060'],
    popolazione: 2527,
  },
  {
    nome: 'Cantalupo in Sabina',
    codice: '057010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'B631',
    cap: ['02040'],
    popolazione: 1736,
  },
  {
    nome: 'Cantalupo Ligure',
    codice: '006028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B629',
    cap: ['15060'],
    popolazione: 549,
  },
  {
    nome: 'Cantalupo nel Sannio',
    codice: '094005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'B630',
    cap: ['86092'],
    popolazione: 729,
  },
  {
    nome: 'Cantarana',
    codice: '005018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B633',
    cap: ['14010'],
    popolazione: 1023,
  },
  {
    nome: 'Cantello',
    codice: '012030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B634',
    cap: ['21050'],
    popolazione: 4569,
  },
  {
    nome: 'Canterano',
    codice: '058017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B635',
    cap: ['00020'],
    popolazione: 359,
  },
  {
    nome: 'Cantiano',
    codice: '041008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'B636',
    cap: ['61044'],
    popolazione: 2356,
  },
  {
    nome: 'Cantoira',
    codice: '001054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B637',
    cap: ['10070'],
    popolazione: 554,
  },
  {
    nome: 'Cantù',
    codice: '013041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B639',
    cap: ['22063'],
    popolazione: 38717,
  },
  {
    nome: 'Canzano',
    codice: '067009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'B640',
    cap: ['64020'],
    popolazione: 1955,
  },
  {
    nome: 'Canzo',
    codice: '013042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B641',
    cap: ['22035'],
    popolazione: 5109,
  },
  {
    nome: 'Caorle',
    codice: '027005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'B642',
    cap: ['30021'],
    popolazione: 11793,
  },
  {
    nome: 'Caorso',
    codice: '033010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'B643',
    cap: ['29012'],
    popolazione: 4830,
  },
  {
    nome: 'Capaccio Paestum',
    codice: '065025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B644',
    cap: ['84047'],
    popolazione: 22016,
  },
  {
    nome: 'Capaci',
    codice: '082020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B645',
    cap: ['90040'],
    popolazione: 11030,
  },
  {
    nome: 'Capalbio',
    codice: '053003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'B646',
    cap: ['58011'],
    popolazione: 4066,
  },
  {
    nome: 'Capannoli',
    codice: '050005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'B647',
    cap: ['56033'],
    popolazione: 6213,
  },
  {
    nome: 'Capannori',
    codice: '046007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'B648',
    cap: ['55012'],
    popolazione: 44898,
  },
  {
    nome: 'Capena',
    codice: '058018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B649',
    cap: ['00060'],
    popolazione: 9488,
  },
  {
    nome: 'Capergnanica',
    codice: '019012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B650',
    cap: ['26010'],
    popolazione: 2095,
  },
  {
    nome: 'Capestrano',
    codice: '066019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B651',
    cap: ['67022'],
    popolazione: 895,
  },
  {
    nome: 'Capiago Intimiano',
    codice: '013043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B653',
    cap: ['22070'],
    popolazione: 5525,
  },
  {
    nome: 'Capistrano',
    codice: '102005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'B655',
    cap: ['89818'],
    popolazione: 1097,
  },
  {
    nome: 'Capistrello',
    codice: '066020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B656',
    cap: ['67053'],
    popolazione: 5341,
  },
  {
    nome: 'Capitignano',
    codice: '066021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B658',
    cap: ['67014'],
    popolazione: 680,
  },
  {
    nome: 'Capizzi',
    codice: '083008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'B660',
    cap: ['98031'],
    popolazione: 3347,
  },
  {
    nome: 'Capizzone',
    codice: '016050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B661',
    cap: ['24030'],
    popolazione: 1301,
  },
  {
    nome: "Capo d'Orlando",
    codice: '083009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'B666',
    cap: ['98071'],
    popolazione: 13260,
  },
  {
    nome: 'Capo di Ponte',
    codice: '017035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B664',
    cap: ['25044'],
    popolazione: 2509,
  },
  {
    nome: 'Capodimonte',
    codice: '056013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B663',
    cap: ['01010'],
    popolazione: 1741,
  },
  {
    nome: 'Capodrise',
    codice: '061013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B667',
    cap: ['81020'],
    popolazione: 9773,
  },
  {
    nome: 'Capoliveri',
    codice: '049004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'B669',
    cap: ['57031'],
    popolazione: 3763,
  },
  {
    nome: 'Capolona',
    codice: '051006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'B670',
    cap: ['52010'],
    popolazione: 5428,
  },
  {
    nome: 'Caponago',
    codice: '108052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B671',
    cap: ['20867'],
    popolazione: 5226,
  },
  {
    nome: 'Caporciano',
    codice: '066022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B672',
    cap: ['67020'],
    popolazione: 235,
  },
  {
    nome: 'Caposele',
    codice: '064017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B674',
    cap: ['83040'],
    popolazione: 3537,
  },
  {
    nome: 'Capoterra',
    codice: '092011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'B675',
    cap: ['09012'],
    popolazione: 23255,
  },
  {
    nome: 'Capovalle',
    codice: '017036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B676',
    cap: ['25070'],
    popolazione: 388,
  },
  {
    nome: 'Cappadocia',
    codice: '066023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B677',
    cap: ['67060'],
    popolazione: 551,
  },
  {
    nome: 'Cappella Cantone',
    codice: '019013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B679',
    cap: ['26020'],
    popolazione: 577,
  },
  {
    nome: "Cappella de' Picenardi",
    codice: '019014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B680',
    cap: ['26030'],
    popolazione: 439,
  },
  {
    nome: 'Cappella Maggiore',
    codice: '026007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B678',
    cap: ['31012'],
    popolazione: 4677,
  },
  {
    nome: 'Cappelle sul Tavo',
    codice: '068006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'B681',
    cap: ['65010'],
    popolazione: 3959,
  },
  {
    nome: 'Capracotta',
    codice: '094006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'B682',
    cap: ['86082'],
    popolazione: 950,
  },
  {
    nome: 'Capraia e Limite',
    codice: '048008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'B684',
    cap: ['50050'],
    popolazione: 7298,
  },
  {
    nome: 'Capraia Isola',
    codice: '049005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'B685',
    cap: ['57032'],
    popolazione: 394,
  },
  {
    nome: 'Capralba',
    codice: '019015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B686',
    cap: ['26010'],
    popolazione: 2452,
  },
  {
    nome: 'Capranica',
    codice: '056014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B688',
    cap: ['01012'],
    popolazione: 6644,
  },
  {
    nome: 'Capranica Prenestina',
    codice: '058019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B687',
    cap: ['00030'],
    popolazione: 330,
  },
  {
    nome: 'Caprarica di Lecce',
    codice: '075013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B690',
    cap: ['73010'],
    popolazione: 2582,
  },
  {
    nome: 'Caprarola',
    codice: '056015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B691',
    cap: ['01032'],
    popolazione: 5345,
  },
  {
    nome: 'Caprauna',
    codice: '004039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B692',
    cap: ['12070'],
    popolazione: 120,
  },
  {
    nome: 'Caprese Michelangelo',
    codice: '051007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'B693',
    cap: ['52033'],
    popolazione: 1516,
  },
  {
    nome: 'Caprezzo',
    codice: '103018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B694',
    cap: ['28815'],
    popolazione: 168,
  },
  {
    nome: 'Capri',
    codice: '063014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B696',
    cap: ['80073'],
    popolazione: 6831,
  },
  {
    nome: 'Capri Leone',
    codice: '083010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'B695',
    cap: ['98070'],
    popolazione: 4516,
  },
  {
    nome: 'Capriana',
    codice: '022040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B697',
    cap: ['38030'],
    popolazione: 605,
  },
  {
    nome: 'Capriano del Colle',
    codice: '017037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B698',
    cap: ['25020'],
    popolazione: 4553,
  },
  {
    nome: "Capriata d'Orba",
    codice: '006029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B701',
    cap: ['15060'],
    popolazione: 1926,
  },
  {
    nome: 'Capriate San Gervasio',
    codice: '016051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B703',
    cap: ['24042'],
    popolazione: 7777,
  },
  {
    nome: 'Capriati a Volturno',
    codice: '061014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B704',
    cap: ['81014'],
    popolazione: 1594,
  },
  {
    nome: 'Caprie',
    codice: '001055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B705',
    cap: ['10040'],
    popolazione: 2116,
  },
  {
    nome: 'Capriglia Irpina',
    codice: '064018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B706',
    cap: ['83010'],
    popolazione: 2417,
  },
  {
    nome: 'Capriglio',
    codice: '005019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B707',
    cap: ['14014'],
    popolazione: 300,
  },
  {
    nome: 'Caprile',
    codice: '096013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B708',
    cap: ['13864'],
    popolazione: 206,
  },
  {
    nome: 'Caprino Bergamasco',
    codice: '016052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B710',
    cap: ['24030'],
    popolazione: 3127,
  },
  {
    nome: 'Caprino Veronese',
    codice: '023018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B709',
    cap: ['37013'],
    popolazione: 8065,
  },
  {
    nome: 'Capriolo',
    codice: '017038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B711',
    cap: ['25031'],
    popolazione: 9115,
  },
  {
    nome: 'Capriva del Friuli',
    codice: '031001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'B712',
    cap: ['34070'],
    popolazione: 1731,
  },
  {
    nome: 'Capua',
    codice: '061015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B715',
    cap: ['81043'],
    popolazione: 19036,
  },
  {
    nome: 'Capurso',
    codice: '072014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'B716',
    cap: ['70010'],
    popolazione: 15396,
  },
  {
    nome: 'Caraffa del Bianco',
    codice: '080021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B718',
    cap: ['89030'],
    popolazione: 532,
  },
  {
    nome: 'Caraffa di Catanzaro',
    codice: '079017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'B717',
    cap: ['88050'],
    popolazione: 1960,
  },
  {
    nome: 'Caraglio',
    codice: '004040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B719',
    cap: ['12023'],
    popolazione: 6755,
  },
  {
    nome: 'Caramagna Piemonte',
    codice: '004041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B720',
    cap: ['12030'],
    popolazione: 3032,
  },
  {
    nome: 'Caramanico Terme',
    codice: '068007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'B722',
    cap: ['65023'],
    popolazione: 2008,
  },
  {
    nome: 'Carapelle',
    codice: '071010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B724',
    cap: ['71041'],
    popolazione: 6524,
  },
  {
    nome: 'Carapelle Calvisio',
    codice: '066024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B725',
    cap: ['67020'],
    popolazione: 85,
  },
  {
    nome: 'Carasco',
    codice: '010010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B726',
    cap: ['16042'],
    popolazione: 3649,
  },
  {
    nome: 'Carassai',
    codice: '044010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'B727',
    cap: ['63063'],
    popolazione: 1116,
  },
  {
    nome: 'Carate Brianza',
    codice: '108015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B729',
    cap: ['20841'],
    popolazione: 17684,
  },
  {
    nome: 'Carate Urio',
    codice: '013044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B730',
    cap: ['22010'],
    popolazione: 1216,
  },
  {
    nome: 'Caravaggio',
    codice: '016053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B731',
    cap: ['24043'],
    popolazione: 15882,
  },
  {
    nome: 'Caravate',
    codice: '012031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B732',
    cap: ['21032'],
    popolazione: 2612,
  },
  {
    nome: 'Caravino',
    codice: '001056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B733',
    cap: ['10010'],
    popolazione: 995,
  },
  {
    nome: 'Caravonica',
    codice: '008012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'B734',
    cap: ['18020'],
    popolazione: 303,
  },
  {
    nome: 'Carbognano',
    codice: '056016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'B735',
    cap: ['01030'],
    popolazione: 2042,
  },
  {
    nome: 'Carbonara al Ticino',
    codice: '018030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B741',
    cap: ['27020'],
    popolazione: 1516,
  },
  {
    nome: 'Carbonara di Nola',
    codice: '063015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B740',
    cap: ['80030'],
    popolazione: 2303,
  },
  {
    nome: 'Carbonara Scrivia',
    codice: '006030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B736',
    cap: ['15050'],
    popolazione: 1055,
  },
  {
    nome: 'Carbonate',
    codice: '013045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B742',
    cap: ['22070'],
    popolazione: 2905,
  },
  {
    nome: 'Carbone',
    codice: '076019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B743',
    cap: ['85030'],
    popolazione: 705,
  },
  {
    nome: 'Carbonera',
    codice: '026008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B744',
    cap: ['31030'],
    popolazione: 11135,
  },
  {
    nome: 'Carbonia',
    codice: '111009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'B745',
    cap: ['09013'],
    popolazione: 28882,
  },
  {
    nome: 'Carcare',
    codice: '009018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B748',
    cap: ['17043'],
    popolazione: 5605,
  },
  {
    nome: 'Carceri',
    codice: '028022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B749',
    cap: ['35040'],
    popolazione: 1600,
  },
  {
    nome: 'Carcoforo',
    codice: '002029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B752',
    cap: ['13026'],
    popolazione: 75,
  },
  {
    nome: 'Cardano al Campo',
    codice: '012032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B754',
    cap: ['21010'],
    popolazione: 14136,
  },
  {
    nome: 'Cardè',
    codice: '004042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B755',
    cap: ['12030'],
    popolazione: 1134,
  },
  {
    nome: 'Cardedu',
    codice: '091103',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'M285',
    cap: ['08040'],
    popolazione: 1809,
  },
  {
    nome: 'Cardeto',
    codice: '080022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B756',
    cap: ['89060'],
    popolazione: 1822,
  },
  {
    nome: 'Cardinale',
    codice: '079018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'B758',
    cap: ['88062'],
    popolazione: 2334,
  },
  {
    nome: 'Cardito',
    codice: '063016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B759',
    cap: ['80024'],
    popolazione: 22322,
  },
  {
    nome: 'Careggine',
    codice: '046008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'B760',
    cap: ['55030'],
    popolazione: 584,
  },
  {
    nome: 'Carema',
    codice: '001057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B762',
    cap: ['10010'],
    popolazione: 772,
  },
  {
    nome: 'Carenno',
    codice: '097014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B763',
    cap: ['23802'],
    popolazione: 1496,
  },
  {
    nome: 'Carentino',
    codice: '006031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B765',
    cap: ['15026'],
    popolazione: 325,
  },
  {
    nome: 'Careri',
    codice: '080023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B766',
    cap: ['89030'],
    popolazione: 2410,
  },
  {
    nome: 'Caresana',
    codice: '002030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B767',
    cap: ['13010'],
    popolazione: 1028,
  },
  {
    nome: 'Caresanablot',
    codice: '002031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B768',
    cap: ['13030'],
    popolazione: 1137,
  },
  {
    nome: 'Carezzano',
    codice: '006032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B769',
    cap: ['15051'],
    popolazione: 444,
  },
  {
    nome: 'Carfizzi',
    codice: '101003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'B771',
    cap: ['88817'],
    popolazione: 745,
  },
  {
    nome: 'Cargeghe',
    codice: '090022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'B772',
    cap: ['07030'],
    popolazione: 644,
  },
  {
    nome: 'Cariati',
    codice: '078025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B774',
    cap: ['87062'],
    popolazione: 8644,
  },
  {
    nome: 'Carife',
    codice: '064019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B776',
    cap: ['83040'],
    popolazione: 1498,
  },
  {
    nome: 'Carignano',
    codice: '001058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B777',
    cap: ['10041'],
    popolazione: 9156,
  },
  {
    nome: 'Carimate',
    codice: '013046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B778',
    cap: ['22060'],
    popolazione: 4327,
  },
  {
    nome: 'Carinaro',
    codice: '061016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B779',
    cap: ['81032'],
    popolazione: 6886,
  },
  {
    nome: 'Carini',
    codice: '082021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'B780',
    cap: ['90044'],
    popolazione: 35681,
  },
  {
    nome: 'Carinola',
    codice: '061017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B781',
    cap: ['81030'],
    popolazione: 8056,
  },
  {
    nome: 'Carisio',
    codice: '002032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B782',
    cap: ['13040'],
    popolazione: 864,
  },
  {
    nome: 'Carisolo',
    codice: '022042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B783',
    cap: ['38080'],
    popolazione: 984,
  },
  {
    nome: 'Carlantino',
    codice: '071011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B784',
    cap: ['71030'],
    popolazione: 1040,
  },
  {
    nome: 'Carlazzo',
    codice: '013047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B785',
    cap: ['22010'],
    popolazione: 2972,
  },
  {
    nome: 'Carlentini',
    codice: '089006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'B787',
    cap: ['96013'],
    popolazione: 17958,
  },
  {
    nome: 'Carlino',
    codice: '030018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'B788',
    cap: ['33050'],
    popolazione: 2790,
  },
  {
    nome: 'Carloforte',
    codice: '111010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'B789',
    cap: ['09014'],
    popolazione: 6301,
  },
  {
    nome: 'Carlopoli',
    codice: '079020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'B790',
    cap: ['88040'],
    popolazione: 1622,
  },
  {
    nome: 'Carmagnola',
    codice: '001059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B791',
    cap: ['10022'],
    popolazione: 28563,
  },
  {
    nome: 'Carmiano',
    codice: '075014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B792',
    cap: ['73041'],
    popolazione: 12096,
  },
  {
    nome: 'Carmignano',
    codice: '100002',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'B794',
    cap: ['59015'],
    popolazione: 13991,
  },
  {
    nome: 'Carmignano di Brenta',
    codice: '028023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B795',
    cap: ['35010'],
    popolazione: 7540,
  },
  {
    nome: 'Carnago',
    codice: '012033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B796',
    cap: ['21040'],
    popolazione: 6502,
  },
  {
    nome: 'Carnate',
    codice: '108016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'B798',
    cap: ['20866'],
    popolazione: 7177,
  },
  {
    nome: 'Carobbio degli Angeli',
    codice: '016055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B801',
    cap: ['24060'],
    popolazione: 4549,
  },
  {
    nome: 'Carolei',
    codice: '078026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B802',
    cap: ['87030'],
    popolazione: 3462,
  },
  {
    nome: 'Carona',
    codice: '016056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B803',
    cap: ['24010'],
    popolazione: 359,
  },
  {
    nome: 'Caronia',
    codice: '083011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'B804',
    cap: ['98072'],
    popolazione: 3463,
  },
  {
    nome: 'Caronno Pertusella',
    codice: '012034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B805',
    cap: ['21042'],
    popolazione: 16397,
  },
  {
    nome: 'Caronno Varesino',
    codice: '012035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B807',
    cap: ['21040'],
    popolazione: 4940,
  },
  {
    nome: 'Carosino',
    codice: '073002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'B808',
    cap: ['74021'],
    popolazione: 6832,
  },
  {
    nome: 'Carovigno',
    codice: '074002',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'B809',
    cap: ['72012'],
    popolazione: 15896,
  },
  {
    nome: 'Carovilli',
    codice: '094007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'B810',
    cap: ['86083'],
    popolazione: 1428,
  },
  {
    nome: 'Carpaneto Piacentino',
    codice: '033011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'B812',
    cap: ['29013'],
    popolazione: 7537,
  },
  {
    nome: 'Carpanzano',
    codice: '078027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B813',
    cap: ['87050'],
    popolazione: 300,
  },
  {
    nome: 'Carpegna',
    codice: '041009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'B816',
    cap: ['61021'],
    popolazione: 1670,
  },
  {
    nome: 'Carpenedolo',
    codice: '017039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'B817',
    cap: ['25013'],
    popolazione: 12649,
  },
  {
    nome: 'Carpeneto',
    codice: '006033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B818',
    cap: ['15071'],
    popolazione: 991,
  },
  {
    nome: 'Carpi',
    codice: '036005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'B819',
    cap: ['41012'],
    popolazione: 67268,
  },
  {
    nome: 'Carpiano',
    codice: '015050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B820',
    cap: ['20080'],
    popolazione: 3976,
  },
  {
    nome: 'Carpignano Salentino',
    codice: '075015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B822',
    cap: ['73020'],
    popolazione: 3685,
  },
  {
    nome: 'Carpignano Sesia',
    codice: '003036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B823',
    cap: ['28064'],
    popolazione: 2578,
  },
  {
    nome: 'Carpineti',
    codice: '035011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B825',
    cap: ['42033'],
    popolazione: 4178,
  },
  {
    nome: 'Carpineto della Nora',
    codice: '068008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'B827',
    cap: ['65010'],
    popolazione: 680,
  },
  {
    nome: 'Carpineto Romano',
    codice: '058020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B828',
    cap: ['00032'],
    popolazione: 4649,
  },
  {
    nome: 'Carpineto Sinello',
    codice: '069011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B826',
    cap: ['66030'],
    popolazione: 666,
  },
  {
    nome: 'Carpino',
    codice: '071012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B829',
    cap: ['71010'],
    popolazione: 4305,
  },
  {
    nome: 'Carpinone',
    codice: '094008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'B830',
    cap: ['86093'],
    popolazione: 1226,
  },
  {
    nome: 'Carrara',
    codice: '045003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'B832',
    cap: ['54033'],
    popolazione: 64689,
  },
  {
    nome: 'Carrè',
    codice: '024024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B835',
    cap: ['36010'],
    popolazione: 3647,
  },
  {
    nome: 'Carrega Ligure',
    codice: '006034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B836',
    cap: ['15060'],
    popolazione: 83,
  },
  {
    nome: 'Carro',
    codice: '011009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'B838',
    cap: ['19012'],
    popolazione: 580,
  },
  {
    nome: 'Carrodano',
    codice: '011010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'B839',
    cap: ['19020'],
    popolazione: 521,
  },
  {
    nome: 'Carrosio',
    codice: '006035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B840',
    cap: ['15060'],
    popolazione: 481,
  },
  {
    nome: 'Carrù',
    codice: '004043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B841',
    cap: ['12061'],
    popolazione: 4428,
  },
  {
    nome: 'Carsoli',
    codice: '066025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'B842',
    cap: ['67061'],
    popolazione: 5419,
  },
  {
    nome: 'Cartigliano',
    codice: '024025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'B844',
    cap: ['36050'],
    popolazione: 3802,
  },
  {
    nome: 'Cartignano',
    codice: '004044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B845',
    cap: ['12020'],
    popolazione: 178,
  },
  {
    nome: 'Cartoceto',
    codice: '041010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'B846',
    cap: ['61030'],
    popolazione: 7850,
  },
  {
    nome: 'Cartosio',
    codice: '006036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B847',
    cap: ['15015'],
    popolazione: 811,
  },
  {
    nome: 'Cartura',
    codice: '028026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B848',
    cap: ['35025'],
    popolazione: 4623,
  },
  {
    nome: 'Carugate',
    codice: '015051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B850',
    cap: ['20061'],
    popolazione: 14175,
  },
  {
    nome: 'Carugo',
    codice: '013048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B851',
    cap: ['22060'],
    popolazione: 6243,
  },
  {
    nome: 'Carunchio',
    codice: '069012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B853',
    cap: ['66050'],
    popolazione: 639,
  },
  {
    nome: 'Carvico',
    codice: '016057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B854',
    cap: ['24030'],
    popolazione: 4666,
  },
  {
    nome: 'Carzano',
    codice: '022043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'B856',
    cap: ['38050'],
    popolazione: 505,
  },
  {
    nome: 'Casabona',
    codice: '101004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'B857',
    cap: ['88822'],
    popolazione: 2856,
  },
  {
    nome: 'Casacalenda',
    codice: '070011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B858',
    cap: ['86043'],
    popolazione: 2207,
  },
  {
    nome: 'Casacanditella',
    codice: '069013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B859',
    cap: ['66010'],
    popolazione: 1340,
  },
  {
    nome: 'Casagiove',
    codice: '061018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B860',
    cap: ['81022'],
    popolazione: 13705,
  },
  {
    nome: 'Casal Cermelli',
    codice: '006037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B870',
    cap: ['15072'],
    popolazione: 1235,
  },
  {
    nome: 'Casal di Principe',
    codice: '061019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B872',
    cap: ['81033'],
    popolazione: 20828,
  },
  {
    nome: 'Casal Velino',
    codice: '065028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B895',
    cap: ['84040'],
    popolazione: 4938,
  },
  {
    nome: 'Casalanguida',
    codice: '069014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B861',
    cap: ['66031'],
    popolazione: 1006,
  },
  {
    nome: 'Casalattico',
    codice: '060017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'B862',
    cap: ['03030'],
    popolazione: 641,
  },
  {
    nome: 'Casalbeltrame',
    codice: '003037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B864',
    cap: ['28060'],
    popolazione: 1076,
  },
  {
    nome: 'Casalbordino',
    codice: '069015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B865',
    cap: ['66021'],
    popolazione: 6303,
  },
  {
    nome: 'Casalbore',
    codice: '064020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B866',
    cap: ['83034'],
    popolazione: 1922,
  },
  {
    nome: 'Casalborgone',
    codice: '001060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B867',
    cap: ['10020'],
    popolazione: 1820,
  },
  {
    nome: 'Casalbuono',
    codice: '065026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B868',
    cap: ['84030'],
    popolazione: 1211,
  },
  {
    nome: 'Casalbuttano ed Uniti',
    codice: '019016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B869',
    cap: ['26011'],
    popolazione: 4103,
  },
  {
    nome: 'Casalciprano',
    codice: '070012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'B871',
    cap: ['86010'],
    popolazione: 571,
  },
  {
    nome: 'Casalduni',
    codice: '062015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'B873',
    cap: ['82027'],
    popolazione: 1474,
  },
  {
    nome: 'Casale Corte Cerro',
    codice: '103019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'B876',
    cap: ['28881'],
    popolazione: 3476,
  },
  {
    nome: 'Casale Cremasco-Vidolasco',
    codice: '019017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B881',
    cap: ['26010'],
    popolazione: 1826,
  },
  {
    nome: 'Casale di Scodosia',
    codice: '028027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B877',
    cap: ['35040'],
    popolazione: 4866,
  },
  {
    nome: 'Casale Litta',
    codice: '012036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B875',
    cap: ['21020'],
    popolazione: 2652,
  },
  {
    nome: 'Casale Marittimo',
    codice: '050006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'B878',
    cap: ['56040'],
    popolazione: 1084,
  },
  {
    nome: 'Casale Monferrato',
    codice: '006039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B885',
    cap: ['15033'],
    popolazione: 34812,
  },
  {
    nome: 'Casale sul Sile',
    codice: '026009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B879',
    cap: ['31032'],
    popolazione: 12722,
  },
  {
    nome: 'Casalecchio di Reno',
    codice: '037011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B880',
    cap: ['40033'],
    popolazione: 35173,
  },
  {
    nome: 'Casaleggio Boiro',
    codice: '006038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B882',
    cap: ['15070'],
    popolazione: 401,
  },
  {
    nome: 'Casaleggio Novara',
    codice: '003039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B883',
    cap: ['28060'],
    popolazione: 930,
  },
  {
    nome: 'Casaleone',
    codice: '023019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'B886',
    cap: ['37052'],
    popolazione: 5939,
  },
  {
    nome: 'Casaletto Ceredano',
    codice: '019018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B889',
    cap: ['26010'],
    popolazione: 1180,
  },
  {
    nome: 'Casaletto di Sopra',
    codice: '019019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B890',
    cap: ['26014'],
    popolazione: 548,
  },
  {
    nome: 'Casaletto Lodigiano',
    codice: '098008',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B887',
    cap: ['26852'],
    popolazione: 2779,
  },
  {
    nome: 'Casaletto Spartano',
    codice: '065027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B888',
    cap: ['84030'],
    popolazione: 1463,
  },
  {
    nome: 'Casaletto Vaprio',
    codice: '019020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B891',
    cap: ['26010'],
    popolazione: 1761,
  },
  {
    nome: 'Casalfiumanese',
    codice: '037012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B892',
    cap: ['40020'],
    popolazione: 3461,
  },
  {
    nome: 'Casalgrande',
    codice: '035012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B893',
    cap: ['42013'],
    popolazione: 18635,
  },
  {
    nome: 'Casalgrasso',
    codice: '004045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'B894',
    cap: ['12030'],
    popolazione: 1448,
  },
  {
    nome: 'Casali del Manco',
    codice: '078156',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'M385',
    cap: ['87059'],
    popolazione: 10381,
  },
  {
    nome: 'Casalincontrada',
    codice: '069016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B896',
    cap: ['66012'],
    popolazione: 3153,
  },
  {
    nome: 'Casalino',
    codice: '003040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B897',
    cap: ['28060'],
    popolazione: 1555,
  },
  {
    nome: 'Casalmaggiore',
    codice: '019021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B898',
    cap: ['26041'],
    popolazione: 15111,
  },
  {
    nome: 'Casalmaiocco',
    codice: '098009',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B899',
    cap: ['26831'],
    popolazione: 3069,
  },
  {
    nome: 'Casalmorano',
    codice: '019022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B900',
    cap: ['26020'],
    popolazione: 1680,
  },
  {
    nome: 'Casalmoro',
    codice: '020010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'B901',
    cap: ['46040'],
    popolazione: 2204,
  },
  {
    nome: 'Casalnoceto',
    codice: '006040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B902',
    cap: ['15052'],
    popolazione: 1015,
  },
  {
    nome: 'Casalnuovo di Napoli',
    codice: '063017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B905',
    cap: ['80013'],
    popolazione: 48621,
  },
  {
    nome: 'Casalnuovo Monterotaro',
    codice: '071013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B904',
    cap: ['71033'],
    popolazione: 1663,
  },
  {
    nome: 'Casaloldo',
    codice: '020011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'B907',
    cap: ['46040'],
    popolazione: 2621,
  },
  {
    nome: 'Casalpusterlengo',
    codice: '098010',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B910',
    cap: ['26841'],
    popolazione: 14852,
  },
  {
    nome: 'Casalromano',
    codice: '020012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'B911',
    cap: ['46040'],
    popolazione: 1551,
  },
  {
    nome: 'Casalserugo',
    codice: '028028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'B912',
    cap: ['35020'],
    popolazione: 5452,
  },
  {
    nome: 'Casaluce',
    codice: '061020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B916',
    cap: ['81030'],
    popolazione: 10001,
  },
  {
    nome: 'Casalvecchio di Puglia',
    codice: '071014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'B917',
    cap: ['71030'],
    popolazione: 1939,
  },
  {
    nome: 'Casalvecchio Siculo',
    codice: '083012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'B918',
    cap: ['98032'],
    popolazione: 907,
  },
  {
    nome: 'Casalvieri',
    codice: '060018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'B919',
    cap: ['03034'],
    popolazione: 2867,
  },
  {
    nome: 'Casalvolone',
    codice: '003041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'B920',
    cap: ['28060'],
    popolazione: 867,
  },
  {
    nome: 'Casalzuigno',
    codice: '012037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B921',
    cap: ['21030'],
    popolazione: 1304,
  },
  {
    nome: 'Casamarciano',
    codice: '063018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B922',
    cap: ['80032'],
    popolazione: 3272,
  },
  {
    nome: 'Casamassima',
    codice: '072015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'B923',
    cap: ['70010'],
    popolazione: 19246,
  },
  {
    nome: 'Casamicciola Terme',
    codice: '063019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B924',
    cap: ['80074'],
    popolazione: 8080,
  },
  {
    nome: 'Casandrino',
    codice: '063020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B925',
    cap: ['80025'],
    popolazione: 13295,
  },
  {
    nome: 'Casanova Elvo',
    codice: '002033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B928',
    cap: ['13030'],
    popolazione: 265,
  },
  {
    nome: 'Casanova Lerrone',
    codice: '009019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'B927',
    cap: ['17033'],
    popolazione: 744,
  },
  {
    nome: 'Casanova Lonati',
    codice: '018031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B929',
    cap: ['27041'],
    popolazione: 483,
  },
  {
    nome: 'Casape',
    codice: '058021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B932',
    cap: ['00010'],
    popolazione: 737,
  },
  {
    nome: 'Casapesenna',
    codice: '061103',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'M260',
    cap: ['81030'],
    popolazione: 6651,
  },
  {
    nome: 'Casapinta',
    codice: '096014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'B933',
    cap: ['13866'],
    popolazione: 454,
  },
  {
    nome: 'Casaprota',
    codice: '057011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'B934',
    cap: ['02030'],
    popolazione: 723,
  },
  {
    nome: 'Casapulla',
    codice: '061021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B935',
    cap: ['81020'],
    popolazione: 8180,
  },
  {
    nome: 'Casarano',
    codice: '075016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'B936',
    cap: ['73042'],
    popolazione: 20489,
  },
  {
    nome: 'Casargo',
    codice: '097015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B937',
    cap: ['23831'],
    popolazione: 849,
  },
  {
    nome: 'Casarile',
    codice: '015055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B938',
    cap: ['20080'],
    popolazione: 3867,
  },
  {
    nome: 'Casarsa della Delizia',
    codice: '093010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'B940',
    cap: ['33072'],
    popolazione: 8440,
  },
  {
    nome: 'Casarza Ligure',
    codice: '010011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B939',
    cap: ['16030'],
    popolazione: 6708,
  },
  {
    nome: 'Casasco',
    codice: '006041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'B941',
    cap: ['15050'],
    popolazione: 124,
  },
  {
    nome: 'Casatenovo',
    codice: '097016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B943',
    cap: ['23880'],
    popolazione: 12664,
  },
  {
    nome: 'Casatisma',
    codice: '018032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B945',
    cap: ['27040'],
    popolazione: 895,
  },
  {
    nome: 'Casavatore',
    codice: '063021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B946',
    cap: ['80020'],
    popolazione: 18663,
  },
  {
    nome: 'Casazza',
    codice: '016058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B947',
    cap: ['24060'],
    popolazione: 4021,
  },
  {
    nome: 'Cascia',
    codice: '054007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'B948',
    cap: ['06043'],
    popolazione: 3248,
  },
  {
    nome: 'Casciago',
    codice: '012038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B949',
    cap: ['21020'],
    popolazione: 3865,
  },
  {
    nome: 'Casciana Terme Lari',
    codice: '050040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'M327',
    cap: ['56035'],
    popolazione: 12366,
  },
  {
    nome: 'Cascina',
    codice: '050008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'B950',
    cap: ['56021'],
    popolazione: 43833,
  },
  {
    nome: "Cascinette d'Ivrea",
    codice: '001061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B953',
    cap: ['10010'],
    popolazione: 1491,
  },
  {
    nome: 'Casei Gerola',
    codice: '018033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B954',
    cap: ['27050'],
    popolazione: 2500,
  },
  {
    nome: 'Caselette',
    codice: '001062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B955',
    cap: ['10040'],
    popolazione: 2931,
  },
  {
    nome: 'Casella',
    codice: '010012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'B956',
    cap: ['16015'],
    popolazione: 3232,
  },
  {
    nome: 'Caselle in Pittari',
    codice: '065029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'B959',
    cap: ['84030'],
    popolazione: 1972,
  },
  {
    nome: 'Caselle Landi',
    codice: '098011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B961',
    cap: ['26842'],
    popolazione: 1652,
  },
  {
    nome: 'Caselle Lurani',
    codice: '098012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'B958',
    cap: ['26853'],
    popolazione: 3100,
  },
  {
    nome: 'Caselle Torinese',
    codice: '001063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B960',
    cap: ['10072'],
    popolazione: 13389,
  },
  {
    nome: 'Caserta',
    codice: '061022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B963',
    cap: ['81100'],
    popolazione: 75640,
  },
  {
    nome: 'Casier',
    codice: '026010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'B965',
    cap: ['31030'],
    popolazione: 11018,
  },
  {
    nome: 'Casignana',
    codice: '080024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'B966',
    cap: ['89030'],
    popolazione: 773,
  },
  {
    nome: 'Casina',
    codice: '035013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'B967',
    cap: ['42034'],
    popolazione: 4534,
  },
  {
    nome: "Casirate d'Adda",
    codice: '016059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B971',
    cap: ['24040'],
    popolazione: 3894,
  },
  {
    nome: "Caslino d'Erba",
    codice: '013052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B974',
    cap: ['22030'],
    popolazione: 1692,
  },
  {
    nome: 'Casnate con Bernate',
    codice: '013053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'B977',
    cap: ['22070'],
    popolazione: 4915,
  },
  {
    nome: 'Casnigo',
    codice: '016060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B978',
    cap: ['24020'],
    popolazione: 3334,
  },
  {
    nome: 'Casola di Napoli',
    codice: '063022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B980',
    cap: ['80050'],
    popolazione: 3852,
  },
  {
    nome: 'Casola in Lunigiana',
    codice: '045004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'B979',
    cap: ['54014'],
    popolazione: 1003,
  },
  {
    nome: 'Casola Valsenio',
    codice: '039005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'B982',
    cap: ['48032'],
    popolazione: 2724,
  },
  {
    nome: "Casole d'Elsa",
    codice: '052004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'B984',
    cap: ['53031'],
    popolazione: 3886,
  },
  {
    nome: 'Casoli',
    codice: '069017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B985',
    cap: ['66043'],
    popolazione: 5847,
  },
  {
    nome: 'Casorate Primo',
    codice: '018034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B988',
    cap: ['27022'],
    popolazione: 8480,
  },
  {
    nome: 'Casorate Sempione',
    codice: '012039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B987',
    cap: ['21011'],
    popolazione: 5726,
  },
  {
    nome: 'Casorezzo',
    codice: '015058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'B989',
    cap: ['20010'],
    popolazione: 5355,
  },
  {
    nome: 'Casoria',
    codice: '063023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'B990',
    cap: ['80026'],
    popolazione: 78647,
  },
  {
    nome: 'Casorzo',
    codice: '005020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'B991',
    cap: ['14032'],
    popolazione: 657,
  },
  {
    nome: 'Casperia',
    codice: '057012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'A472',
    cap: ['02041'],
    popolazione: 1231,
  },
  {
    nome: 'Caspoggio',
    codice: '014013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'B993',
    cap: ['23020'],
    popolazione: 1500,
  },
  {
    nome: 'Cassacco',
    codice: '030019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'B994',
    cap: ['33010'],
    popolazione: 2911,
  },
  {
    nome: 'Cassago Brianza',
    codice: '097017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'B996',
    cap: ['23893'],
    popolazione: 4406,
  },
  {
    nome: "Cassano all'Ionio",
    codice: '078029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C002',
    cap: ['87011'],
    popolazione: 17281,
  },
  {
    nome: "Cassano d'Adda",
    codice: '015059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C003',
    cap: ['20062'],
    popolazione: 18552,
  },
  {
    nome: 'Cassano delle Murge',
    codice: '072016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'B998',
    cap: ['70020'],
    popolazione: 14270,
  },
  {
    nome: 'Cassano Irpino',
    codice: '064021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'B997',
    cap: ['83040'],
    popolazione: 967,
  },
  {
    nome: 'Cassano Magnago',
    codice: '012040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C004',
    cap: ['21012'],
    popolazione: 21386,
  },
  {
    nome: 'Cassano Spinola',
    codice: '006191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M388',
    cap: ['15063'],
    popolazione: 1965,
  },
  {
    nome: 'Cassano Valcuvia',
    codice: '012041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B999',
    cap: ['21030'],
    popolazione: 665,
  },
  {
    nome: 'Cassaro',
    codice: '089007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'C006',
    cap: ['96010'],
    popolazione: 813,
  },
  {
    nome: 'Cassiglio',
    codice: '016061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C007',
    cap: ['24010'],
    popolazione: 122,
  },
  {
    nome: "Cassina de' Pecchi",
    codice: '015060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C014',
    cap: ['20060'],
    popolazione: 13206,
  },
  {
    nome: 'Cassina Rizzardi',
    codice: '013055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C020',
    cap: ['22070'],
    popolazione: 3175,
  },
  {
    nome: 'Cassina Valsassina',
    codice: '097018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C024',
    cap: ['23817'],
    popolazione: 470,
  },
  {
    nome: 'Cassinasco',
    codice: '005021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C022',
    cap: ['14050'],
    popolazione: 590,
  },
  {
    nome: 'Cassine',
    codice: '006043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C027',
    cap: ['15016'],
    popolazione: 3048,
  },
  {
    nome: 'Cassinelle',
    codice: '006044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C030',
    cap: ['15070'],
    popolazione: 937,
  },
  {
    nome: 'Cassinetta di Lugagnano',
    codice: '015061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C033',
    cap: ['20081'],
    popolazione: 1884,
  },
  {
    nome: 'Cassino',
    codice: '060019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C034',
    cap: ['03043'],
    popolazione: 33658,
  },
  {
    nome: 'Cassola',
    codice: '024026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'C037',
    cap: ['36022'],
    popolazione: 14128,
  },
  {
    nome: 'Cassolnovo',
    codice: '018035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C038',
    cap: ['27023'],
    popolazione: 6940,
  },
  {
    nome: 'Castagnaro',
    codice: '023020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C041',
    cap: ['37043'],
    popolazione: 3930,
  },
  {
    nome: 'Castagneto Carducci',
    codice: '049006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'C044',
    cap: ['57022'],
    popolazione: 8462,
  },
  {
    nome: 'Castagneto Po',
    codice: '001064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C045',
    cap: ['10090'],
    popolazione: 1791,
  },
  {
    nome: 'Castagnito',
    codice: '004046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C046',
    cap: ['12050'],
    popolazione: 2113,
  },
  {
    nome: 'Castagnole delle Lanze',
    codice: '005022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C049',
    cap: ['14054'],
    popolazione: 3784,
  },
  {
    nome: 'Castagnole Monferrato',
    codice: '005023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C047',
    cap: ['14030'],
    popolazione: 1271,
  },
  {
    nome: 'Castagnole Piemonte',
    codice: '001065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C048',
    cap: ['10060'],
    popolazione: 2193,
  },
  {
    nome: 'Castana',
    codice: '018036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C050',
    cap: ['27040'],
    popolazione: 744,
  },
  {
    nome: 'Castano Primo',
    codice: '015062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C052',
    cap: ['20022'],
    popolazione: 10990,
  },
  {
    nome: 'Casteggio',
    codice: '018037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C053',
    cap: ['27045'],
    popolazione: 6836,
  },
  {
    nome: 'Castegnato',
    codice: '017040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C055',
    cap: ['25045'],
    popolazione: 8031,
  },
  {
    nome: 'Castegnero',
    codice: '024027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'C056',
    cap: ['36020'],
    popolazione: 2857,
  },
  {
    nome: 'Castel Baronia',
    codice: '064022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C058',
    cap: ['83040'],
    popolazione: 1150,
  },
  {
    nome: 'Castel Boglione',
    codice: '005024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C064',
    cap: ['14040'],
    popolazione: 614,
  },
  {
    nome: 'Castel Bolognese',
    codice: '039006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'C065',
    cap: ['48014'],
    popolazione: 9519,
  },
  {
    nome: 'Castel Campagnano',
    codice: '061023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'B494',
    cap: ['81010'],
    popolazione: 1608,
  },
  {
    nome: 'Castel Castagna',
    codice: '067010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C040',
    cap: ['64030'],
    popolazione: 491,
  },
  {
    nome: 'Castel Condino',
    codice: '022045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C183',
    cap: ['38082'],
    popolazione: 238,
  },
  {
    nome: "Castel d'Aiano",
    codice: '037013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C075',
    cap: ['40034'],
    popolazione: 1951,
  },
  {
    nome: "Castel d'Ario",
    codice: '020014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C076',
    cap: ['46033'],
    popolazione: 4787,
  },
  {
    nome: "Castel d'Azzano",
    codice: '023021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C078',
    cap: ['37060'],
    popolazione: 11739,
  },
  {
    nome: 'Castel del Giudice',
    codice: '094009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C082',
    cap: ['86080'],
    popolazione: 355,
  },
  {
    nome: 'Castel del Monte',
    codice: '066026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C083',
    cap: ['67023'],
    popolazione: 447,
  },
  {
    nome: 'Castel del Piano',
    codice: '053004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'C085',
    cap: ['58033'],
    popolazione: 4671,
  },
  {
    nome: 'Castel del Rio',
    codice: '037014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C086',
    cap: ['40022'],
    popolazione: 1230,
  },
  {
    nome: 'Castel di Casio',
    codice: '037015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B969',
    cap: ['40030'],
    popolazione: 3479,
  },
  {
    nome: 'Castel di Ieri',
    codice: '066027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C090',
    cap: ['67020'],
    popolazione: 329,
  },
  {
    nome: 'Castel di Iudica',
    codice: '087013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'C091',
    cap: ['95040'],
    popolazione: 4748,
  },
  {
    nome: 'Castel di Lama',
    codice: '044011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'C093',
    cap: ['63082'],
    popolazione: 8470,
  },
  {
    nome: 'Castel di Lucio',
    codice: '083013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'C094',
    cap: ['98070'],
    popolazione: 1366,
  },
  {
    nome: 'Castel di Sangro',
    codice: '066028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C096',
    cap: ['67031'],
    popolazione: 5985,
  },
  {
    nome: 'Castel di Sasso',
    codice: '061024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C097',
    cap: ['81040'],
    popolazione: 1193,
  },
  {
    nome: 'Castel di Tora',
    codice: '057013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C098',
    cap: ['02020'],
    popolazione: 299,
  },
  {
    nome: 'Castel Focognano',
    codice: '051008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C102',
    cap: ['52016'],
    popolazione: 3239,
  },
  {
    nome: 'Castel Frentano',
    codice: '069018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C114',
    cap: ['66032'],
    popolazione: 4311,
  },
  {
    nome: 'Castel Gabbiano',
    codice: '019024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C115',
    cap: ['26010'],
    popolazione: 455,
  },
  {
    nome: 'Castel Gandolfo',
    codice: '058022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C116',
    cap: ['00073'],
    popolazione: 8782,
  },
  {
    nome: 'Castel Giorgio',
    codice: '055009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'C117',
    cap: ['05013'],
    popolazione: 2178,
  },
  {
    nome: 'Castel Goffredo',
    codice: '020015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C118',
    cap: ['46042'],
    popolazione: 12065,
  },
  {
    nome: 'Castel Guelfo di Bologna',
    codice: '037016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C121',
    cap: ['40023'],
    popolazione: 4277,
  },
  {
    nome: 'Castel Ivano',
    codice: '022240',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M354',
    cap: ['38059'],
    popolazione: 3381,
  },
  {
    nome: 'Castel Madama',
    codice: '058023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C203',
    cap: ['00024'],
    popolazione: 7328,
  },
  {
    nome: 'Castel Maggiore',
    codice: '037019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C204',
    cap: ['40013'],
    popolazione: 17507,
  },
  {
    nome: 'Castel Mella',
    codice: '017042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C208',
    cap: ['25030'],
    popolazione: 10840,
  },
  {
    nome: 'Castel Morrone',
    codice: '061026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C211',
    cap: ['81020'],
    popolazione: 3934,
  },
  {
    nome: 'Castel Ritaldi',
    codice: '054008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C252',
    cap: ['06044'],
    popolazione: 3319,
  },
  {
    nome: 'Castel Rocchero',
    codice: '005032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C253',
    cap: ['14044'],
    popolazione: 396,
  },
  {
    nome: 'Castel Rozzone',
    codice: '016063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C255',
    cap: ['24040'],
    popolazione: 2896,
  },
  {
    nome: 'Castel San Giorgio',
    codice: '065034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C259',
    cap: ['84083'],
    popolazione: 13411,
  },
  {
    nome: 'Castel San Giovanni',
    codice: '033013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'C261',
    cap: ['29015'],
    popolazione: 13629,
  },
  {
    nome: 'Castel San Lorenzo',
    codice: '065035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C262',
    cap: ['84049'],
    popolazione: 2632,
  },
  {
    nome: 'Castel San Niccolò',
    codice: '051010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C263',
    cap: ['52018'],
    popolazione: 2739,
  },
  {
    nome: 'Castel San Pietro Romano',
    codice: '058025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C266',
    cap: ['00030'],
    popolazione: 855,
  },
  {
    nome: 'Castel San Pietro Terme',
    codice: '037020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C265',
    cap: ['40024'],
    popolazione: 20468,
  },
  {
    nome: 'Castel San Vincenzo',
    codice: '094012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C270',
    cap: ['86071'],
    popolazione: 545,
  },
  {
    nome: "Castel Sant'Angelo",
    codice: '057015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C268',
    cap: ['02010'],
    popolazione: 1289,
  },
  {
    nome: "Castel Sant'Elia",
    codice: '056017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C269',
    cap: ['01030'],
    popolazione: 2558,
  },
  {
    nome: 'Castel Viscardo',
    codice: '055010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'C289',
    cap: ['05014'],
    popolazione: 3028,
  },
  {
    nome: 'Castel Vittorio',
    codice: '008015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C110',
    cap: ['18030'],
    popolazione: 329,
  },
  {
    nome: 'Castel Volturno',
    codice: '061027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C291',
    cap: ['81030'],
    popolazione: 22882,
  },
  {
    nome: 'Castelbaldo',
    codice: '028029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'C057',
    cap: ['35040'],
    popolazione: 1625,
  },
  {
    nome: 'Castelbelforte',
    codice: '020013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C059',
    cap: ['46032'],
    popolazione: 3069,
  },
  {
    nome: 'Castelbellino',
    codice: '042008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'C060',
    cap: ['60030'],
    popolazione: 4763,
  },
  {
    nome: 'Castelbello-Ciardes',
    codice: '021018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'C062',
    cap: ['39020'],
    popolazione: 2378,
  },
  {
    nome: 'Castelbianco',
    codice: '009020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'C063',
    cap: ['17030'],
    popolazione: 321,
  },
  {
    nome: 'Castelbottaccio',
    codice: '070013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C066',
    cap: ['86030'],
    popolazione: 349,
  },
  {
    nome: 'Castelbuono',
    codice: '082022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C067',
    cap: ['90013'],
    popolazione: 9161,
  },
  {
    nome: 'Castelcivita',
    codice: '065030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C069',
    cap: ['84020'],
    popolazione: 1834,
  },
  {
    nome: 'Castelcovati',
    codice: '017041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C072',
    cap: ['25030'],
    popolazione: 6593,
  },
  {
    nome: 'Castelcucco',
    codice: '026011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C073',
    cap: ['31030'],
    popolazione: 2189,
  },
  {
    nome: 'Casteldaccia',
    codice: '082023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C074',
    cap: ['90014'],
    popolazione: 10884,
  },
  {
    nome: 'Casteldelci',
    codice: '099021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'C080',
    cap: ['47861'],
    popolazione: 445,
  },
  {
    nome: 'Casteldelfino',
    codice: '004047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C081',
    cap: ['12020'],
    popolazione: 179,
  },
  {
    nome: 'Casteldidone',
    codice: '019023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C089',
    cap: ['26030'],
    popolazione: 576,
  },
  {
    nome: 'Castelfidardo',
    codice: '042010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'C100',
    cap: ['60022'],
    popolazione: 18645,
  },
  {
    nome: 'Castelfiorentino',
    codice: '048010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'C101',
    cap: ['50051'],
    popolazione: 17489,
  },
  {
    nome: 'Castelforte',
    codice: '059004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'C104',
    cap: ['04021'],
    popolazione: 4401,
  },
  {
    nome: 'Castelfranci',
    codice: '064023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C105',
    cap: ['83040'],
    popolazione: 2104,
  },
  {
    nome: 'Castelfranco di Sotto',
    codice: '050009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'C113',
    cap: ['56022'],
    popolazione: 12904,
  },
  {
    nome: 'Castelfranco Emilia',
    codice: '036006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'C107',
    cap: ['41013'],
    popolazione: 31656,
  },
  {
    nome: 'Castelfranco in Miscano',
    codice: '062016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C106',
    cap: ['82022'],
    popolazione: 935,
  },
  {
    nome: 'Castelfranco Piandiscò',
    codice: '051040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'M322',
    cap: ['52026'],
    popolazione: 9518,
  },
  {
    nome: 'Castelfranco Veneto',
    codice: '026012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C111',
    cap: ['31033'],
    popolazione: 32894,
  },
  {
    nome: 'Castelgerundo',
    codice: '098062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'M393',
    cap: ['26844'],
    popolazione: 1520,
  },
  {
    nome: 'Castelgomberto',
    codice: '024028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'C119',
    cap: ['36070'],
    popolazione: 6098,
  },
  {
    nome: 'Castelgrande',
    codice: '076021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C120',
    cap: ['85050'],
    popolazione: 1018,
  },
  {
    nome: 'Castelguglielmo',
    codice: '029011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'C122',
    cap: ['45020'],
    popolazione: 1646,
  },
  {
    nome: 'Castelguidone',
    codice: '069019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C123',
    cap: ['66040'],
    popolazione: 416,
  },
  {
    nome: "Castell'Alfero",
    codice: '005025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C127',
    cap: ['14033'],
    popolazione: 2750,
  },
  {
    nome: "Castell'Arquato",
    codice: '033012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'C145',
    cap: ['29014'],
    popolazione: 4712,
  },
  {
    nome: "Castell'Azzara",
    codice: '053005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'C147',
    cap: ['58034'],
    popolazione: 1601,
  },
  {
    nome: "Castell'Umberto",
    codice: '083014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'C051',
    cap: ['98070'],
    popolazione: 3295,
  },
  {
    nome: 'Castellabate',
    codice: '065031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C125',
    cap: ['84048'],
    popolazione: 8209,
  },
  {
    nome: 'Castellafiume',
    codice: '066029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C126',
    cap: ['67050'],
    popolazione: 1099,
  },
  {
    nome: 'Castellalto',
    codice: '067011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C128',
    cap: ['64020'],
    popolazione: 7359,
  },
  {
    nome: 'Castellammare del Golfo',
    codice: '081005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'C130',
    cap: ['91014'],
    popolazione: 14603,
  },
  {
    nome: 'Castellammare di Stabia',
    codice: '063024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'C129',
    cap: ['80053'],
    popolazione: 65944,
  },
  {
    nome: 'Castellamonte',
    codice: '001066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C133',
    cap: ['10081'],
    popolazione: 9917,
  },
  {
    nome: 'Castellana Grotte',
    codice: '072017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'C134',
    cap: ['70013'],
    popolazione: 19340,
  },
  {
    nome: 'Castellana Sicula',
    codice: '082024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C135',
    cap: ['90020'],
    popolazione: 3549,
  },
  {
    nome: 'Castellaneta',
    codice: '073003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'C136',
    cap: ['74011'],
    popolazione: 17125,
  },
  {
    nome: 'Castellania Coppi',
    codice: '006045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C137',
    cap: ['15051'],
    popolazione: 91,
  },
  {
    nome: 'Castellanza',
    codice: '012042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C139',
    cap: ['21053'],
    popolazione: 14244,
  },
  {
    nome: 'Castellar Guidobono',
    codice: '006046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C142',
    cap: ['15050'],
    popolazione: 427,
  },
  {
    nome: 'Castellarano',
    codice: '035014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'C141',
    cap: ['42014'],
    popolazione: 14838,
  },
  {
    nome: 'Castellaro',
    codice: '008014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C143',
    cap: ['18011'],
    popolazione: 1233,
  },
  {
    nome: 'Castellazzo Bormida',
    codice: '006047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C148',
    cap: ['15073'],
    popolazione: 4566,
  },
  {
    nome: 'Castellazzo Novarese',
    codice: '003042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C149',
    cap: ['28060'],
    popolazione: 323,
  },
  {
    nome: 'Castelleone',
    codice: '019025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C153',
    cap: ['26012'],
    popolazione: 9506,
  },
  {
    nome: 'Castelleone di Suasa',
    codice: '042011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'C152',
    cap: ['60010'],
    popolazione: 1702,
  },
  {
    nome: 'Castellero',
    codice: '005026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C154',
    cap: ['14013'],
    popolazione: 302,
  },
  {
    nome: 'Castelletto Cervo',
    codice: '096015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'C155',
    cap: ['13851'],
    popolazione: 880,
  },
  {
    nome: "Castelletto d'Erro",
    codice: '006048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C156',
    cap: ['15010'],
    popolazione: 150,
  },
  {
    nome: "Castelletto d'Orba",
    codice: '006049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C158',
    cap: ['15060'],
    popolazione: 2096,
  },
  {
    nome: 'Castelletto di Branduzzo',
    codice: '018038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C157',
    cap: ['27040'],
    popolazione: 1037,
  },
  {
    nome: 'Castelletto Merli',
    codice: '006050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C160',
    cap: ['15020'],
    popolazione: 484,
  },
  {
    nome: 'Castelletto Molina',
    codice: '005027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C161',
    cap: ['14040'],
    popolazione: 184,
  },
  {
    nome: 'Castelletto Monferrato',
    codice: '006051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C162',
    cap: ['15040'],
    popolazione: 1558,
  },
  {
    nome: 'Castelletto sopra Ticino',
    codice: '003043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C166',
    cap: ['28053'],
    popolazione: 10005,
  },
  {
    nome: 'Castelletto Stura',
    codice: '004049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C165',
    cap: ['12040'],
    popolazione: 1351,
  },
  {
    nome: 'Castelletto Uzzone',
    codice: '004050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C167',
    cap: ['12070'],
    popolazione: 365,
  },
  {
    nome: 'Castelli',
    codice: '067012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C169',
    cap: ['64041'],
    popolazione: 1224,
  },
  {
    nome: 'Castelli Calepio',
    codice: '016062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C079',
    cap: ['24060'],
    popolazione: 9612,
  },
  {
    nome: 'Castellina in Chianti',
    codice: '052005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C172',
    cap: ['53011'],
    popolazione: 2863,
  },
  {
    nome: 'Castellina Marittima',
    codice: '050010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'C174',
    cap: ['56040'],
    popolazione: 1985,
  },
  {
    nome: "Castellinaldo d'Alba",
    codice: '004051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C173',
    cap: ['12050'],
    popolazione: 897,
  },
  {
    nome: 'Castellino del Biferno',
    codice: '070014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C175',
    cap: ['86020'],
    popolazione: 589,
  },
  {
    nome: 'Castellino Tanaro',
    codice: '004052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C176',
    cap: ['12060'],
    popolazione: 337,
  },
  {
    nome: 'Castelliri',
    codice: '060020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C177',
    cap: ['03030'],
    popolazione: 3533,
  },
  {
    nome: 'Castello Cabiaglio',
    codice: '012043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'B312',
    cap: ['21030'],
    popolazione: 530,
  },
  {
    nome: "Castello d'Agogna",
    codice: '018039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C184',
    cap: ['27030'],
    popolazione: 1091,
  },
  {
    nome: "Castello d'Argile",
    codice: '037017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C185',
    cap: ['40050'],
    popolazione: 6458,
  },
  {
    nome: 'Castello del Matese',
    codice: '061025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C178',
    cap: ['81016'],
    popolazione: 1509,
  },
  {
    nome: "Castello dell'Acqua",
    codice: '014014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C186',
    cap: ['23030'],
    popolazione: 643,
  },
  {
    nome: 'Castello di Annone',
    codice: '005028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'A300',
    cap: ['14034'],
    popolazione: 1928,
  },
  {
    nome: 'Castello di Brianza',
    codice: '097019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C187',
    cap: ['23884'],
    popolazione: 2475,
  },
  {
    nome: 'Castello di Cisterna',
    codice: '063025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'C188',
    cap: ['80030'],
    popolazione: 7452,
  },
  {
    nome: 'Castello di Godego',
    codice: '026013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C190',
    cap: ['31030'],
    popolazione: 7013,
  },
  {
    nome: 'Castello Tesino',
    codice: '022048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C194',
    cap: ['38053'],
    popolazione: 1315,
  },
  {
    nome: 'Castello-Molina di Fiemme',
    codice: '022047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C189',
    cap: ['38030'],
    popolazione: 2267,
  },
  {
    nome: 'Castellucchio',
    codice: '020016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C195',
    cap: ['46014'],
    popolazione: 5205,
  },
  {
    nome: 'Castelluccio dei Sauri',
    codice: '071015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C198',
    cap: ['71025'],
    popolazione: 2119,
  },
  {
    nome: 'Castelluccio Inferiore',
    codice: '076022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C199',
    cap: ['85040'],
    popolazione: 2179,
  },
  {
    nome: 'Castelluccio Superiore',
    codice: '076023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C201',
    cap: ['85040'],
    popolazione: 860,
  },
  {
    nome: 'Castelluccio Valmaggiore',
    codice: '071016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C202',
    cap: ['71020'],
    popolazione: 1331,
  },
  {
    nome: 'Castelmagno',
    codice: '004053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C205',
    cap: ['12020'],
    popolazione: 82,
  },
  {
    nome: 'Castelmarte',
    codice: '013058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C206',
    cap: ['22030'],
    popolazione: 1286,
  },
  {
    nome: 'Castelmassa',
    codice: '029012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'C207',
    cap: ['45035'],
    popolazione: 4291,
  },
  {
    nome: 'Castelmauro',
    codice: '070015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C197',
    cap: ['86031'],
    popolazione: 1638,
  },
  {
    nome: 'Castelmezzano',
    codice: '076024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C209',
    cap: ['85010'],
    popolazione: 852,
  },
  {
    nome: 'Castelmola',
    codice: '083015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'C210',
    cap: ['98030'],
    popolazione: 1073,
  },
  {
    nome: 'Castelnovetto',
    codice: '018040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C213',
    cap: ['27030'],
    popolazione: 624,
  },
  {
    nome: 'Castelnovo Bariano',
    codice: '029013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'C215',
    cap: ['45030'],
    popolazione: 2931,
  },
  {
    nome: 'Castelnovo del Friuli',
    codice: '093011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C217',
    cap: ['33090'],
    popolazione: 913,
  },
  {
    nome: 'Castelnovo di Sotto',
    codice: '035015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'C218',
    cap: ['42024'],
    popolazione: 8594,
  },
  {
    nome: "Castelnovo ne' Monti",
    codice: '035016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'C219',
    cap: ['42035'],
    popolazione: 10481,
  },
  {
    nome: 'Castelnuovo',
    codice: '022049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C216',
    cap: ['38050'],
    popolazione: 1035,
  },
  {
    nome: 'Castelnuovo Belbo',
    codice: '005029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C226',
    cap: ['14043'],
    popolazione: 895,
  },
  {
    nome: 'Castelnuovo Berardenga',
    codice: '052006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C227',
    cap: ['53019'],
    popolazione: 8787,
  },
  {
    nome: "Castelnuovo Bocca d'Adda",
    codice: '098013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C228',
    cap: ['26843'],
    popolazione: 1700,
  },
  {
    nome: 'Castelnuovo Bormida',
    codice: '006052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C229',
    cap: ['15017'],
    popolazione: 680,
  },
  {
    nome: 'Castelnuovo Bozzente',
    codice: '013059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C220',
    cap: ['22070'],
    popolazione: 889,
  },
  {
    nome: 'Castelnuovo Calcea',
    codice: '005030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C230',
    cap: ['14040'],
    popolazione: 765,
  },
  {
    nome: 'Castelnuovo Cilento',
    codice: '065032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C231',
    cap: ['84040'],
    popolazione: 2598,
  },
  {
    nome: 'Castelnuovo del Garda',
    codice: '023022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C225',
    cap: ['37014'],
    popolazione: 12199,
  },
  {
    nome: 'Castelnuovo della Daunia',
    codice: '071017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C222',
    cap: ['71034'],
    popolazione: 1557,
  },
  {
    nome: 'Castelnuovo di Ceva',
    codice: '004054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C214',
    cap: ['12070'],
    popolazione: 139,
  },
  {
    nome: 'Castelnuovo di Conza',
    codice: '065033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C235',
    cap: ['84020'],
    popolazione: 641,
  },
  {
    nome: 'Castelnuovo di Farfa',
    codice: '057014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C224',
    cap: ['02031'],
    popolazione: 1047,
  },
  {
    nome: 'Castelnuovo di Garfagnana',
    codice: '046009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'C236',
    cap: ['55032'],
    popolazione: 6059,
  },
  {
    nome: 'Castelnuovo di Porto',
    codice: '058024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C237',
    cap: ['00060'],
    popolazione: 8059,
  },
  {
    nome: 'Castelnuovo di Val di Cecina',
    codice: '050011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'C244',
    cap: ['56041'],
    popolazione: 2290,
  },
  {
    nome: 'Castelnuovo Don Bosco',
    codice: '005031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C232',
    cap: ['14022'],
    popolazione: 3260,
  },
  {
    nome: 'Castelnuovo Magra',
    codice: '011011',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'C240',
    cap: ['19033'],
    popolazione: 8269,
  },
  {
    nome: 'Castelnuovo Nigra',
    codice: '001067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C241',
    cap: ['10080'],
    popolazione: 417,
  },
  {
    nome: 'Castelnuovo Parano',
    codice: '060021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C223',
    cap: ['03040'],
    popolazione: 902,
  },
  {
    nome: 'Castelnuovo Rangone',
    codice: '036007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'C242',
    cap: ['41051'],
    popolazione: 14116,
  },
  {
    nome: 'Castelnuovo Scrivia',
    codice: '006053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C243',
    cap: ['15053'],
    popolazione: 5414,
  },
  {
    nome: 'Castelpagano',
    codice: '062017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C245',
    cap: ['82024'],
    popolazione: 1547,
  },
  {
    nome: 'Castelpetroso',
    codice: '094010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C246',
    cap: ['86090'],
    popolazione: 1662,
  },
  {
    nome: 'Castelpizzuto',
    codice: '094011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C247',
    cap: ['86090'],
    popolazione: 159,
  },
  {
    nome: 'Castelplanio',
    codice: '042012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'C248',
    cap: ['60031'],
    popolazione: 3482,
  },
  {
    nome: 'Castelpoto',
    codice: '062018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C250',
    cap: ['82030'],
    popolazione: 1326,
  },
  {
    nome: 'Castelraimondo',
    codice: '043009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'C251',
    cap: ['62022'],
    popolazione: 4741,
  },
  {
    nome: 'Castelrotto',
    codice: '021019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'C254',
    cap: ['39040'],
    popolazione: 6459,
  },
  {
    nome: 'Castelsantangelo sul Nera',
    codice: '043010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'C267',
    cap: ['62039'],
    popolazione: 310,
  },
  {
    nome: 'Castelsaraceno',
    codice: '076025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C271',
    cap: ['85031'],
    popolazione: 1480,
  },
  {
    nome: 'Castelsardo',
    codice: '090023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'C272',
    cap: ['07031'],
    popolazione: 5737,
  },
  {
    nome: 'Castelseprio',
    codice: '012044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C273',
    cap: ['21050'],
    popolazione: 1281,
  },
  {
    nome: 'Castelsilano',
    codice: '101005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'B968',
    cap: ['88834'],
    popolazione: 1034,
  },
  {
    nome: 'Castelspina',
    codice: '006054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C274',
    cap: ['15070'],
    popolazione: 422,
  },
  {
    nome: 'Casteltermini',
    codice: '084012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'C275',
    cap: ['92025'],
    popolazione: 8422,
  },
  {
    nome: 'Castelveccana',
    codice: '012045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C181',
    cap: ['21010'],
    popolazione: 2000,
  },
  {
    nome: 'Castelvecchio Calvisio',
    codice: '066030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C278',
    cap: ['67020'],
    popolazione: 159,
  },
  {
    nome: 'Castelvecchio di Rocca Barbena',
    codice: '009021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'C276',
    cap: ['17034'],
    popolazione: 172,
  },
  {
    nome: 'Castelvecchio Subequo',
    codice: '066031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C279',
    cap: ['67024'],
    popolazione: 1067,
  },
  {
    nome: 'Castelvenere',
    codice: '062019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C280',
    cap: ['82037'],
    popolazione: 2620,
  },
  {
    nome: 'Castelverde',
    codice: '019026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B129',
    cap: ['26022'],
    popolazione: 5651,
  },
  {
    nome: 'Castelverrino',
    codice: '094013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C200',
    cap: ['86080'],
    popolazione: 124,
  },
  {
    nome: 'Castelvetere in Val Fortore',
    codice: '062020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C284',
    cap: ['82023'],
    popolazione: 1389,
  },
  {
    nome: 'Castelvetere sul Calore',
    codice: '064024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C283',
    cap: ['83040'],
    popolazione: 1672,
  },
  {
    nome: 'Castelvetrano',
    codice: '081006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'C286',
    cap: ['91022'],
    popolazione: 31824,
  },
  {
    nome: 'Castelvetro di Modena',
    codice: '036008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'C287',
    cap: ['41014'],
    popolazione: 11012,
  },
  {
    nome: 'Castelvetro Piacentino',
    codice: '033014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'C288',
    cap: ['29010'],
    popolazione: 5584,
  },
  {
    nome: 'Castelvisconti',
    codice: '019027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C290',
    cap: ['26010'],
    popolazione: 330,
  },
  {
    nome: 'Castenaso',
    codice: '037021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C292',
    cap: ['40055'],
    popolazione: 14352,
  },
  {
    nome: 'Castenedolo',
    codice: '017043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C293',
    cap: ['25014'],
    popolazione: 11160,
  },
  {
    nome: 'Castiadas',
    codice: '111011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M288',
    cap: ['09040'],
    popolazione: 1507,
  },
  {
    nome: 'Castiglion Fibocchi',
    codice: '051011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C318',
    cap: ['52029'],
    popolazione: 2218,
  },
  {
    nome: 'Castiglion Fiorentino',
    codice: '051012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C319',
    cap: ['52043'],
    popolazione: 13166,
  },
  {
    nome: 'Castiglione a Casauria',
    codice: '068009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C308',
    cap: ['65020'],
    popolazione: 873,
  },
  {
    nome: 'Castiglione Chiavarese',
    codice: '010013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C302',
    cap: ['16030'],
    popolazione: 1642,
  },
  {
    nome: 'Castiglione Cosentino',
    codice: '078030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C301',
    cap: ['87040'],
    popolazione: 2978,
  },
  {
    nome: "Castiglione d'Adda",
    codice: '098014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C304',
    cap: ['26823'],
    popolazione: 4818,
  },
  {
    nome: "Castiglione d'Orcia",
    codice: '052007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C313',
    cap: ['53023'],
    popolazione: 2453,
  },
  {
    nome: 'Castiglione dei Pepoli',
    codice: '037022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'C296',
    cap: ['40035'],
    popolazione: 5870,
  },
  {
    nome: 'Castiglione del Genovesi',
    codice: '065036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C306',
    cap: ['84090'],
    popolazione: 1356,
  },
  {
    nome: 'Castiglione del Lago',
    codice: '054009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C309',
    cap: ['06061'],
    popolazione: 15422,
  },
  {
    nome: 'Castiglione della Pescaia',
    codice: '053006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'C310',
    cap: ['58043'],
    popolazione: 7076,
  },
  {
    nome: 'Castiglione delle Stiviere',
    codice: '020017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C312',
    cap: ['46043'],
    popolazione: 22052,
  },
  {
    nome: 'Castiglione di Garfagnana',
    codice: '046010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'C303',
    cap: ['55033'],
    popolazione: 1860,
  },
  {
    nome: 'Castiglione di Sicilia',
    codice: '087014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'C297',
    cap: ['95012'],
    popolazione: 3298,
  },
  {
    nome: 'Castiglione Falletto',
    codice: '004055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C314',
    cap: ['12060'],
    popolazione: 708,
  },
  {
    nome: 'Castiglione in Teverina',
    codice: '056018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C315',
    cap: ['01024'],
    popolazione: 2385,
  },
  {
    nome: 'Castiglione Messer Marino',
    codice: '069020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C298',
    cap: ['66033'],
    popolazione: 1898,
  },
  {
    nome: 'Castiglione Messer Raimondo',
    codice: '067013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C316',
    cap: ['64034'],
    popolazione: 2364,
  },
  {
    nome: 'Castiglione Olona',
    codice: '012046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C300',
    cap: ['21043'],
    popolazione: 7836,
  },
  {
    nome: 'Castiglione Tinella',
    codice: '004056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C317',
    cap: ['12053'],
    popolazione: 871,
  },
  {
    nome: 'Castiglione Torinese',
    codice: '001068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C307',
    cap: ['10090'],
    popolazione: 6363,
  },
  {
    nome: 'Castignano',
    codice: '044012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'C321',
    cap: ['63072'],
    popolazione: 2947,
  },
  {
    nome: 'Castilenti',
    codice: '067014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C322',
    cap: ['64035'],
    popolazione: 1551,
  },
  {
    nome: 'Castino',
    codice: '004057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C323',
    cap: ['12050'],
    popolazione: 500,
  },
  {
    nome: 'Castione Andevenno',
    codice: '014015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C325',
    cap: ['23012'],
    popolazione: 1562,
  },
  {
    nome: 'Castione della Presolana',
    codice: '016064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C324',
    cap: ['24020'],
    popolazione: 3455,
  },
  {
    nome: 'Castions di Strada',
    codice: '030020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C327',
    cap: ['33050'],
    popolazione: 3866,
  },
  {
    nome: 'Castiraga Vidardo',
    codice: '098015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C329',
    cap: ['26866'],
    popolazione: 2624,
  },
  {
    nome: 'Casto',
    codice: '017044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C330',
    cap: ['25070'],
    popolazione: 1864,
  },
  {
    nome: 'Castorano',
    codice: '044013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'C331',
    cap: ['63081'],
    popolazione: 2322,
  },
  {
    nome: 'Castrezzato',
    codice: '017045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C332',
    cap: ['25030'],
    popolazione: 7083,
  },
  {
    nome: 'Castri di Lecce',
    codice: '075017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'C334',
    cap: ['73020'],
    popolazione: 2975,
  },
  {
    nome: "Castrignano de' Greci",
    codice: '075018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'C335',
    cap: ['73020'],
    popolazione: 4070,
  },
  {
    nome: 'Castrignano del Capo',
    codice: '075019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'C336',
    cap: ['73040'],
    popolazione: 5334,
  },
  {
    nome: 'Castro',
    codice: '016065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C337',
    cap: ['24063'],
    popolazione: 1383,
  },
  {
    nome: 'Castro',
    codice: '075096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'M261',
    cap: ['73030'],
    popolazione: 2473,
  },
  {
    nome: 'Castro dei Volsci',
    codice: '060023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C338',
    cap: ['03020'],
    popolazione: 4903,
  },
  {
    nome: 'Castrocaro Terme e Terra del Sole',
    codice: '040005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'C339',
    cap: ['47011'],
    popolazione: 6512,
  },
  {
    nome: 'Castrocielo',
    codice: '060022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C340',
    cap: ['03030'],
    popolazione: 3969,
  },
  {
    nome: 'Castrofilippo',
    codice: '084013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'C341',
    cap: ['92020'],
    popolazione: 3020,
  },
  {
    nome: 'Castrolibero',
    codice: '078031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C108',
    cap: ['87040'],
    popolazione: 9967,
  },
  {
    nome: 'Castronno',
    codice: '012047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C343',
    cap: ['21040'],
    popolazione: 5248,
  },
  {
    nome: 'Castronovo di Sicilia',
    codice: '082025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C344',
    cap: ['90030'],
    popolazione: 3175,
  },
  {
    nome: "Castronuovo di Sant'Andrea",
    codice: '076026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C345',
    cap: ['85030'],
    popolazione: 1138,
  },
  {
    nome: 'Castropignano',
    codice: '070016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C346',
    cap: ['86010'],
    popolazione: 1029,
  },
  {
    nome: 'Castroreale',
    codice: '083016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'C347',
    cap: ['98053'],
    popolazione: 2548,
  },
  {
    nome: 'Castroregio',
    codice: '078032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C348',
    cap: ['87070'],
    popolazione: 345,
  },
  {
    nome: 'Castrovillari',
    codice: '078033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C349',
    cap: ['87012'],
    popolazione: 22515,
  },
  {
    nome: 'Catania',
    codice: '087015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'C351',
    cap: [
      '95121',
      '95122',
      '95123',
      '95124',
      '95125',
      '95126',
      '95127',
      '95128',
      '95129',
      '95130',
      '95131',
    ],
    popolazione: 293902,
  },
  {
    nome: 'Catanzaro',
    codice: '079023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C352',
    cap: ['88100'],
    popolazione: 89364,
  },
  {
    nome: 'Catenanuova',
    codice: '086006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'C353',
    cap: ['94010'],
    popolazione: 4999,
  },
  {
    nome: 'Catignano',
    codice: '068010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C354',
    cap: ['65011'],
    popolazione: 1449,
  },
  {
    nome: 'Cattolica',
    codice: '099002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'C357',
    cap: ['47841'],
    popolazione: 16550,
  },
  {
    nome: 'Cattolica Eraclea',
    codice: '084014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'C356',
    cap: ['92011'],
    popolazione: 3975,
  },
  {
    nome: 'Caulonia',
    codice: '080025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'C285',
    cap: ['89041'],
    popolazione: 7060,
  },
  {
    nome: 'Cautano',
    codice: '062021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C359',
    cap: ['82030'],
    popolazione: 2091,
  },
  {
    nome: "Cava de' Tirreni",
    codice: '065037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C361',
    cap: ['84013'],
    popolazione: 53885,
  },
  {
    nome: 'Cava Manara',
    codice: '018041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C360',
    cap: ['27051'],
    popolazione: 6586,
  },
  {
    nome: 'Cavaglià',
    codice: '096016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'C363',
    cap: ['13881'],
    popolazione: 3625,
  },
  {
    nome: 'Cavaglietto',
    codice: '003044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C364',
    cap: ['28010'],
    popolazione: 407,
  },
  {
    nome: "Cavaglio d'Agogna",
    codice: '003045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C365',
    cap: ['28010'],
    popolazione: 1280,
  },
  {
    nome: 'Cavagnolo',
    codice: '001069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C369',
    cap: ['10020'],
    popolazione: 2309,
  },
  {
    nome: 'Cavaion Veronese',
    codice: '023023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C370',
    cap: ['37010'],
    popolazione: 5471,
  },
  {
    nome: 'Cavalese',
    codice: '022050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C372',
    cap: ['38033'],
    popolazione: 3953,
  },
  {
    nome: 'Cavallerleone',
    codice: '004058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C375',
    cap: ['12030'],
    popolazione: 652,
  },
  {
    nome: 'Cavallermaggiore',
    codice: '004059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C376',
    cap: ['12030'],
    popolazione: 5472,
  },
  {
    nome: 'Cavallino',
    codice: '075020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'C377',
    cap: ['73020'],
    popolazione: 11913,
  },
  {
    nome: 'Cavallino-Treporti',
    codice: '027044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'M308',
    cap: ['30013'],
    popolazione: 13162,
  },
  {
    nome: 'Cavallirio',
    codice: '003047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C378',
    cap: ['28010'],
    popolazione: 1249,
  },
  {
    nome: 'Cavareno',
    codice: '022051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C380',
    cap: ['38011'],
    popolazione: 1037,
  },
  {
    nome: 'Cavargna',
    codice: '013062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C381',
    cap: ['22010'],
    popolazione: 242,
  },
  {
    nome: 'Cavaria con Premezzo',
    codice: '012048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C382',
    cap: ['21044'],
    popolazione: 5713,
  },
  {
    nome: 'Cavarzere',
    codice: '027006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C383',
    cap: ['30014'],
    popolazione: 14816,
  },
  {
    nome: 'Cavaso del Tomba',
    codice: '026014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C384',
    cap: ['31034'],
    popolazione: 2996,
  },
  {
    nome: 'Cavasso Nuovo',
    codice: '093012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C385',
    cap: ['33092'],
    popolazione: 1606,
  },
  {
    nome: 'Cavatore',
    codice: '006055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C387',
    cap: ['15010'],
    popolazione: 301,
  },
  {
    nome: 'Cavazzo Carnico',
    codice: '030021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C389',
    cap: ['33020'],
    popolazione: 1087,
  },
  {
    nome: 'Cave',
    codice: '058026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C390',
    cap: ['00033'],
    popolazione: 10421,
  },
  {
    nome: 'Cavedago',
    codice: '022052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C392',
    cap: ['38010'],
    popolazione: 530,
  },
  {
    nome: 'Cavedine',
    codice: '022053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C393',
    cap: ['38073'],
    popolazione: 2916,
  },
  {
    nome: "Cavenago d'Adda",
    codice: '098017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C394',
    cap: ['26824'],
    popolazione: 2259,
  },
  {
    nome: 'Cavenago di Brianza',
    codice: '108017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'C395',
    cap: ['20873'],
    popolazione: 6883,
  },
  {
    nome: 'Cavernago',
    codice: '016066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C396',
    cap: ['24050'],
    popolazione: 2508,
  },
  {
    nome: 'Cavezzo',
    codice: '036009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'C398',
    cap: ['41032'],
    popolazione: 7196,
  },
  {
    nome: 'Cavizzana',
    codice: '022054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C400',
    cap: ['38022'],
    popolazione: 259,
  },
  {
    nome: 'Cavour',
    codice: '001070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C404',
    cap: ['10061'],
    popolazione: 5568,
  },
  {
    nome: 'Cavriago',
    codice: '035017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'C405',
    cap: ['42025'],
    popolazione: 9698,
  },
  {
    nome: 'Cavriana',
    codice: '020018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C406',
    cap: ['46040'],
    popolazione: 3911,
  },
  {
    nome: 'Cavriglia',
    codice: '051013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C407',
    cap: ['52022'],
    popolazione: 9458,
  },
  {
    nome: 'Cazzago Brabbia',
    codice: '012049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C409',
    cap: ['21020'],
    popolazione: 828,
  },
  {
    nome: 'Cazzago San Martino',
    codice: '017046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C408',
    cap: ['25046'],
    popolazione: 10959,
  },
  {
    nome: 'Cazzano di Tramigna',
    codice: '023024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C412',
    cap: ['37030'],
    popolazione: 1555,
  },
  {
    nome: "Cazzano Sant'Andrea",
    codice: '016067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C410',
    cap: ['24026'],
    popolazione: 1594,
  },
  {
    nome: 'Ceccano',
    codice: '060024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C413',
    cap: ['03023'],
    popolazione: 23098,
  },
  {
    nome: 'Cecima',
    codice: '018042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C414',
    cap: ['27050'],
    popolazione: 233,
  },
  {
    nome: 'Cecina',
    codice: '049007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'C415',
    cap: ['57023'],
    popolazione: 27992,
  },
  {
    nome: 'Cedegolo',
    codice: '017047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C417',
    cap: ['25051'],
    popolazione: 1246,
  },
  {
    nome: 'Cedrasco',
    codice: '014016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C418',
    cap: ['23010'],
    popolazione: 455,
  },
  {
    nome: 'Cefalà Diana',
    codice: '082026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C420',
    cap: ['90030'],
    popolazione: 1007,
  },
  {
    nome: 'Cefalù',
    codice: '082027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C421',
    cap: ['90015'],
    popolazione: 14354,
  },
  {
    nome: 'Ceggia',
    codice: '027007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C422',
    cap: ['30022'],
    popolazione: 6213,
  },
  {
    nome: 'Ceglie Messapica',
    codice: '074003',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'C424',
    cap: ['72013'],
    popolazione: 20209,
  },
  {
    nome: 'Celano',
    codice: '066032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C426',
    cap: ['67043'],
    popolazione: 10828,
  },
  {
    nome: 'Celenza sul Trigno',
    codice: '069021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C428',
    cap: ['66050'],
    popolazione: 974,
  },
  {
    nome: 'Celenza Valfortore',
    codice: '071018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C429',
    cap: ['71035'],
    popolazione: 1724,
  },
  {
    nome: 'Celico',
    codice: '078034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C430',
    cap: ['87053'],
    popolazione: 2883,
  },
  {
    nome: 'Cella Dati',
    codice: '019028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C435',
    cap: ['26040'],
    popolazione: 553,
  },
  {
    nome: 'Cella Monte',
    codice: '006056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C432',
    cap: ['15034'],
    popolazione: 528,
  },
  {
    nome: 'Cellamare',
    codice: '072018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'C436',
    cap: ['70010'],
    popolazione: 5491,
  },
  {
    nome: 'Cellara',
    codice: '078035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C437',
    cap: ['87050'],
    popolazione: 511,
  },
  {
    nome: 'Cellarengo',
    codice: '005033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C438',
    cap: ['14010'],
    popolazione: 714,
  },
  {
    nome: 'Cellatica',
    codice: '017048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C439',
    cap: ['25060'],
    popolazione: 4945,
  },
  {
    nome: 'Celle di Bulgheria',
    codice: '065038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C444',
    cap: ['84040'],
    popolazione: 1968,
  },
  {
    nome: 'Celle di Macra',
    codice: '004060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C441',
    cap: ['12020'],
    popolazione: 93,
  },
  {
    nome: 'Celle di San Vito',
    codice: '071019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C442',
    cap: ['71020'],
    popolazione: 172,
  },
  {
    nome: 'Celle Enomondo',
    codice: '005034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C440',
    cap: ['14010'],
    popolazione: 480,
  },
  {
    nome: 'Celle Ligure',
    codice: '009022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'C443',
    cap: ['17015'],
    popolazione: 5353,
  },
  {
    nome: 'Celleno',
    codice: '056019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C446',
    cap: ['01020'],
    popolazione: 1343,
  },
  {
    nome: 'Cellere',
    codice: '056020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C447',
    cap: ['01010'],
    popolazione: 1230,
  },
  {
    nome: 'Cellino Attanasio',
    codice: '067015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C449',
    cap: ['64036'],
    popolazione: 2590,
  },
  {
    nome: 'Cellino San Marco',
    codice: '074004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'C448',
    cap: ['72020'],
    popolazione: 6799,
  },
  {
    nome: 'Cellio con Breia',
    codice: '002171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'M398',
    cap: ['13024'],
    popolazione: 1027,
  },
  {
    nome: 'Cellole',
    codice: '061102',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'M262',
    cap: ['81030'],
    popolazione: 7684,
  },
  {
    nome: 'Cembra Lisignago',
    codice: '022241',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M355',
    cap: ['38034'],
    popolazione: 2332,
  },
  {
    nome: 'Cenadi',
    codice: '079024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C453',
    cap: ['88067'],
    popolazione: 598,
  },
  {
    nome: 'Cenate Sopra',
    codice: '016068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C456',
    cap: ['24060'],
    popolazione: 2505,
  },
  {
    nome: 'Cenate Sotto',
    codice: '016069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C457',
    cap: ['24069'],
    popolazione: 3494,
  },
  {
    nome: 'Cencenighe Agordino',
    codice: '025010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C458',
    cap: ['32020'],
    popolazione: 1402,
  },
  {
    nome: 'Cene',
    codice: '016070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C459',
    cap: ['24020'],
    popolazione: 4229,
  },
  {
    nome: 'Ceneselli',
    codice: '029014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'C461',
    cap: ['45030'],
    popolazione: 1813,
  },
  {
    nome: 'Cengio',
    codice: '009023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'C463',
    cap: ['17056'],
    popolazione: 3678,
  },
  {
    nome: 'Centallo',
    codice: '004061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C466',
    cap: ['12044'],
    popolazione: 6817,
  },
  {
    nome: 'Cento',
    codice: '038004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'C469',
    cap: ['44042'],
    popolazione: 34723,
  },
  {
    nome: 'Centola',
    codice: '065039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C470',
    cap: ['84051'],
    popolazione: 5073,
  },
  {
    nome: 'Centrache',
    codice: '079025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C472',
    cap: ['88067'],
    popolazione: 415,
  },
  {
    nome: 'Centro Valle Intelvi',
    codice: '013254',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M394',
    cap: ['22023'],
    popolazione: 3227,
  },
  {
    nome: 'Centuripe',
    codice: '086007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'C471',
    cap: ['94010'],
    popolazione: 5599,
  },
  {
    nome: 'Cepagatti',
    codice: '068011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C474',
    cap: ['65012'],
    popolazione: 10449,
  },
  {
    nome: 'Ceppaloni',
    codice: '062022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C476',
    cap: ['82014'],
    popolazione: 3375,
  },
  {
    nome: 'Ceppo Morelli',
    codice: '103021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'C478',
    cap: ['28875'],
    popolazione: 341,
  },
  {
    nome: 'Ceprano',
    codice: '060025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C479',
    cap: ['03024'],
    popolazione: 8740,
  },
  {
    nome: 'Cerami',
    codice: '086008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'C480',
    cap: ['94010'],
    popolazione: 2150,
  },
  {
    nome: 'Ceranesi',
    codice: '010014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C481',
    cap: ['16014'],
    popolazione: 4006,
  },
  {
    nome: 'Cerano',
    codice: '003049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C483',
    cap: ['28065'],
    popolazione: 6728,
  },
  {
    nome: "Cerano d'Intelvi",
    codice: '013063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C482',
    cap: ['22020'],
    popolazione: 536,
  },
  {
    nome: 'Ceranova',
    codice: '018043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C484',
    cap: ['27010'],
    popolazione: 1894,
  },
  {
    nome: 'Ceraso',
    codice: '065040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C485',
    cap: ['84052'],
    popolazione: 2508,
  },
  {
    nome: 'Cercemaggiore',
    codice: '070017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C486',
    cap: ['86012'],
    popolazione: 3927,
  },
  {
    nome: 'Cercenasco',
    codice: '001071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C487',
    cap: ['10060'],
    popolazione: 1857,
  },
  {
    nome: 'Cercepiccola',
    codice: '070018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C488',
    cap: ['86010'],
    popolazione: 685,
  },
  {
    nome: 'Cerchiara di Calabria',
    codice: '078036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C489',
    cap: ['87070'],
    popolazione: 2467,
  },
  {
    nome: 'Cerchio',
    codice: '066033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C492',
    cap: ['67044'],
    popolazione: 1653,
  },
  {
    nome: 'Cercino',
    codice: '014017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C493',
    cap: ['23016'],
    popolazione: 754,
  },
  {
    nome: 'Cercivento',
    codice: '030022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C494',
    cap: ['33020'],
    popolazione: 696,
  },
  {
    nome: 'Cercola',
    codice: '063026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'C495',
    cap: ['80040'],
    popolazione: 18128,
  },
  {
    nome: 'Cerda',
    codice: '082028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C496',
    cap: ['90010'],
    popolazione: 5391,
  },
  {
    nome: 'Cerea',
    codice: '023025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C498',
    cap: ['37053'],
    popolazione: 16251,
  },
  {
    nome: 'Ceregnano',
    codice: '029015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'C500',
    cap: ['45010'],
    popolazione: 3691,
  },
  {
    nome: 'Cerenzia',
    codice: '101006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'C501',
    cap: ['88833'],
    popolazione: 1215,
  },
  {
    nome: 'Ceres',
    codice: '001072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C497',
    cap: ['10070'],
    popolazione: 1056,
  },
  {
    nome: 'Ceresara',
    codice: '020019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C502',
    cap: ['46040'],
    popolazione: 2712,
  },
  {
    nome: 'Cereseto',
    codice: '006057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C503',
    cap: ['15020'],
    popolazione: 457,
  },
  {
    nome: 'Ceresole Alba',
    codice: '004062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C504',
    cap: ['12040'],
    popolazione: 2115,
  },
  {
    nome: 'Ceresole Reale',
    codice: '001073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C505',
    cap: ['10080'],
    popolazione: 159,
  },
  {
    nome: 'Cerete',
    codice: '016071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C506',
    cap: ['24020'],
    popolazione: 1646,
  },
  {
    nome: 'Ceretto Lomellina',
    codice: '018044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C508',
    cap: ['27030'],
    popolazione: 205,
  },
  {
    nome: 'Cergnago',
    codice: '018045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C509',
    cap: ['27020'],
    popolazione: 754,
  },
  {
    nome: 'Ceriale',
    codice: '009024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'C510',
    cap: ['17023'],
    popolazione: 5815,
  },
  {
    nome: 'Ceriana',
    codice: '008016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C511',
    cap: ['18034'],
    popolazione: 1253,
  },
  {
    nome: 'Ceriano Laghetto',
    codice: '108018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'C512',
    cap: ['20816'],
    popolazione: 6324,
  },
  {
    nome: 'Cerignale',
    codice: '033015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'C513',
    cap: ['29020'],
    popolazione: 155,
  },
  {
    nome: 'Cerignola',
    codice: '071020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C514',
    cap: ['71042'],
    popolazione: 56653,
  },
  {
    nome: 'Cerisano',
    codice: '078037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C515',
    cap: ['87044'],
    popolazione: 3271,
  },
  {
    nome: 'Cermenate',
    codice: '013064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C516',
    cap: ['22072'],
    popolazione: 9023,
  },
  {
    nome: 'Cermes',
    codice: '021020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'A022',
    cap: ['39010'],
    popolazione: 1411,
  },
  {
    nome: 'Cermignano',
    codice: '067016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C517',
    cap: ['64037'],
    popolazione: 1787,
  },
  {
    nome: 'Cernobbio',
    codice: '013065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C520',
    cap: ['22012'],
    popolazione: 6830,
  },
  {
    nome: 'Cernusco Lombardone',
    codice: '097020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C521',
    cap: ['23870'],
    popolazione: 3851,
  },
  {
    nome: 'Cernusco sul Naviglio',
    codice: '015070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C523',
    cap: ['20063'],
    popolazione: 30697,
  },
  {
    nome: "Cerreto d'Asti",
    codice: '005035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C528',
    cap: ['14020'],
    popolazione: 220,
  },
  {
    nome: "Cerreto d'Esi",
    codice: '042013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'C524',
    cap: ['60043'],
    popolazione: 3967,
  },
  {
    nome: 'Cerreto di Spoleto',
    codice: '054010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C527',
    cap: ['06041'],
    popolazione: 1122,
  },
  {
    nome: 'Cerreto Grue',
    codice: '006058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C507',
    cap: ['15050'],
    popolazione: 325,
  },
  {
    nome: 'Cerreto Guidi',
    codice: '048011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'C529',
    cap: ['50050'],
    popolazione: 10364,
  },
  {
    nome: 'Cerreto Laziale',
    codice: '058027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C518',
    cap: ['00020'],
    popolazione: 1192,
  },
  {
    nome: 'Cerreto Sannita',
    codice: '062023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C525',
    cap: ['82032'],
    popolazione: 4083,
  },
  {
    nome: 'Cerretto Langhe',
    codice: '004063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C530',
    cap: ['12050'],
    popolazione: 455,
  },
  {
    nome: 'Cerrina Monferrato',
    codice: '006059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C531',
    cap: ['15020'],
    popolazione: 1495,
  },
  {
    nome: 'Cerrione',
    codice: '096018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'C532',
    cap: ['13882'],
    popolazione: 2894,
  },
  {
    nome: 'Cerro al Lambro',
    codice: '015071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C536',
    cap: ['20070'],
    popolazione: 4956,
  },
  {
    nome: 'Cerro al Volturno',
    codice: '094014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C534',
    cap: ['86072'],
    popolazione: 1341,
  },
  {
    nome: 'Cerro Maggiore',
    codice: '015072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C537',
    cap: ['20023'],
    popolazione: 14735,
  },
  {
    nome: 'Cerro Tanaro',
    codice: '005036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C533',
    cap: ['14030'],
    popolazione: 670,
  },
  {
    nome: 'Cerro Veronese',
    codice: '023026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C538',
    cap: ['37020'],
    popolazione: 2434,
  },
  {
    nome: 'Cersosimo',
    codice: '076027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C539',
    cap: ['85030'],
    popolazione: 718,
  },
  {
    nome: 'Certaldo',
    codice: '048012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'C540',
    cap: ['50052'],
    popolazione: 15935,
  },
  {
    nome: 'Certosa di Pavia',
    codice: '018046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C541',
    cap: ['27012'],
    popolazione: 5004,
  },
  {
    nome: 'Cerva',
    codice: '079027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C542',
    cap: ['88050'],
    popolazione: 1269,
  },
  {
    nome: 'Cervara di Roma',
    codice: '058028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C543',
    cap: ['00020'],
    popolazione: 472,
  },
  {
    nome: 'Cervarese Santa Croce',
    codice: '028030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'C544',
    cap: ['35030'],
    popolazione: 5698,
  },
  {
    nome: 'Cervaro',
    codice: '060026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C545',
    cap: ['03044'],
    popolazione: 7744,
  },
  {
    nome: 'Cervasca',
    codice: '004064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C547',
    cap: ['12010'],
    popolazione: 4804,
  },
  {
    nome: 'Cervatto',
    codice: '002041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'C548',
    cap: ['13025'],
    popolazione: 48,
  },
  {
    nome: 'Cerveno',
    codice: '017049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C549',
    cap: ['25040'],
    popolazione: 663,
  },
  {
    nome: 'Cervere',
    codice: '004065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C550',
    cap: ['12040'],
    popolazione: 2162,
  },
  {
    nome: 'Cervesina',
    codice: '018047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C551',
    cap: ['27050'],
    popolazione: 1224,
  },
  {
    nome: 'Cerveteri',
    codice: '058029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C552',
    cap: ['00052'],
    popolazione: 35207,
  },
  {
    nome: 'Cervia',
    codice: '039007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'C553',
    cap: ['48015'],
    popolazione: 28896,
  },
  {
    nome: 'Cervicati',
    codice: '078038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C554',
    cap: ['87010'],
    popolazione: 888,
  },
  {
    nome: "Cervignano d'Adda",
    codice: '098018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C555',
    cap: ['26832'],
    popolazione: 2119,
  },
  {
    nome: 'Cervignano del Friuli',
    codice: '030023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C556',
    cap: ['33052'],
    popolazione: 13409,
  },
  {
    nome: 'Cervinara',
    codice: '064025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C557',
    cap: ['83012'],
    popolazione: 9969,
  },
  {
    nome: 'Cervino',
    codice: '061028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C558',
    cap: ['81023'],
    popolazione: 5024,
  },
  {
    nome: 'Cervo',
    codice: '008017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C559',
    cap: ['18010'],
    popolazione: 1128,
  },
  {
    nome: 'Cerzeto',
    codice: '078039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C560',
    cap: ['87040'],
    popolazione: 1328,
  },
  {
    nome: 'Cesa',
    codice: '061029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C561',
    cap: ['81030'],
    popolazione: 8496,
  },
  {
    nome: 'Cesana Brianza',
    codice: '097021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C563',
    cap: ['23861'],
    popolazione: 2348,
  },
  {
    nome: 'Cesana Torinese',
    codice: '001074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C564',
    cap: ['10054'],
    popolazione: 1007,
  },
  {
    nome: 'Cesano Boscone',
    codice: '015074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C565',
    cap: ['20090'],
    popolazione: 23398,
  },
  {
    nome: 'Cesano Maderno',
    codice: '108019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'C566',
    cap: ['20811'],
    popolazione: 37010,
  },
  {
    nome: 'Cesara',
    codice: '103022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'C567',
    cap: ['28891'],
    popolazione: 598,
  },
  {
    nome: 'Cesarò',
    codice: '083017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'C568',
    cap: ['98033'],
    popolazione: 2572,
  },
  {
    nome: 'Cesate',
    codice: '015076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C569',
    cap: ['20020'],
    popolazione: 13858,
  },
  {
    nome: 'Cesena',
    codice: '040007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'C573',
    cap: ['47521', '47522'],
    popolazione: 95990,
  },
  {
    nome: 'Cesenatico',
    codice: '040008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'C574',
    cap: ['47042'],
    popolazione: 25412,
  },
  {
    nome: 'Cesinali',
    codice: '064026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C576',
    cap: ['83020'],
    popolazione: 2472,
  },
  {
    nome: 'Cesio',
    codice: '008018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C578',
    cap: ['18022'],
    popolazione: 286,
  },
  {
    nome: 'Cesiomaggiore',
    codice: '025011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C577',
    cap: ['32030'],
    popolazione: 4145,
  },
  {
    nome: 'Cessalto',
    codice: '026015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C580',
    cap: ['31040'],
    popolazione: 3834,
  },
  {
    nome: 'Cessaniti',
    codice: '102006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'C581',
    cap: ['89816'],
    popolazione: 3405,
  },
  {
    nome: 'Cessapalombo',
    codice: '043011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'C582',
    cap: ['62020'],
    popolazione: 546,
  },
  {
    nome: 'Cessole',
    codice: '005037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C583',
    cap: ['14050'],
    popolazione: 420,
  },
  {
    nome: 'Cetara',
    codice: '065041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C584',
    cap: ['84010'],
    popolazione: 2302,
  },
  {
    nome: 'Ceto',
    codice: '017050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C585',
    cap: ['25040'],
    popolazione: 1950,
  },
  {
    nome: 'Cetona',
    codice: '052008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C587',
    cap: ['53040'],
    popolazione: 2845,
  },
  {
    nome: 'Cetraro',
    codice: '078040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C588',
    cap: ['87022'],
    popolazione: 10260,
  },
  {
    nome: 'Ceva',
    codice: '004066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C589',
    cap: ['12073'],
    popolazione: 5757,
  },
  {
    nome: 'Cevo',
    codice: '017051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C591',
    cap: ['25040'],
    popolazione: 930,
  },
  {
    nome: 'Challand-Saint-Anselme',
    codice: '007013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C593',
    cap: ['11020'],
    popolazione: 756,
  },
  {
    nome: 'Challand-Saint-Victor',
    codice: '007014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C594',
    cap: ['11020'],
    popolazione: 611,
  },
  {
    nome: 'Chambave',
    codice: '007015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C595',
    cap: ['11023'],
    popolazione: 939,
  },
  {
    nome: 'Chamois',
    codice: '007016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'B491',
    cap: ['11020'],
    popolazione: 94,
  },
  {
    nome: 'Champdepraz',
    codice: '007017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C596',
    cap: ['11020'],
    popolazione: 702,
  },
  {
    nome: 'Champorcher',
    codice: '007018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'B540',
    cap: ['11020'],
    popolazione: 398,
  },
  {
    nome: 'Charvensod',
    codice: '007019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C598',
    cap: ['11020'],
    popolazione: 2507,
  },
  {
    nome: 'Châtillon',
    codice: '007020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C294',
    cap: ['11024'],
    popolazione: 4946,
  },
  {
    nome: 'Cherasco',
    codice: '004067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C599',
    cap: ['12062'],
    popolazione: 8652,
  },
  {
    nome: 'Cheremule',
    codice: '090024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'C600',
    cap: ['07040'],
    popolazione: 455,
  },
  {
    nome: 'Chialamberto',
    codice: '001075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C604',
    cap: ['10070'],
    popolazione: 364,
  },
  {
    nome: 'Chiampo',
    codice: '024029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'C605',
    cap: ['36072'],
    popolazione: 12859,
  },
  {
    nome: 'Chianche',
    codice: '064027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C606',
    cap: ['83010'],
    popolazione: 551,
  },
  {
    nome: 'Chianciano Terme',
    codice: '052009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C608',
    cap: ['53042'],
    popolazione: 7058,
  },
  {
    nome: 'Chianni',
    codice: '050012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'C609',
    cap: ['56034'],
    popolazione: 1457,
  },
  {
    nome: 'Chianocco',
    codice: '001076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C610',
    cap: ['10050'],
    popolazione: 1700,
  },
  {
    nome: 'Chiaramonte Gulfi',
    codice: '088002',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'C612',
    cap: ['97012'],
    popolazione: 8224,
  },
  {
    nome: 'Chiaramonti',
    codice: '090025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'C613',
    cap: ['07030'],
    popolazione: 1735,
  },
  {
    nome: 'Chiarano',
    codice: '026016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C614',
    cap: ['31040'],
    popolazione: 3695,
  },
  {
    nome: 'Chiaravalle',
    codice: '042014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'C615',
    cap: ['60033'],
    popolazione: 14858,
  },
  {
    nome: 'Chiaravalle Centrale',
    codice: '079029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C616',
    cap: ['88064'],
    popolazione: 5883,
  },
  {
    nome: 'Chiari',
    codice: '017052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C618',
    cap: ['25032'],
    popolazione: 18391,
  },
  {
    nome: 'Chiaromonte',
    codice: '076028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'C619',
    cap: ['85032'],
    popolazione: 1954,
  },
  {
    nome: 'Chiauci',
    codice: '094015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C620',
    cap: ['86097'],
    popolazione: 268,
  },
  {
    nome: 'Chiavari',
    codice: '010015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C621',
    cap: ['16043'],
    popolazione: 27338,
  },
  {
    nome: 'Chiavenna',
    codice: '014018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C623',
    cap: ['23022'],
    popolazione: 7297,
  },
  {
    nome: 'Chiaverano',
    codice: '001077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C624',
    cap: ['10010'],
    popolazione: 2106,
  },
  {
    nome: 'Chienes',
    codice: '021021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'C625',
    cap: ['39030'],
    popolazione: 2695,
  },
  {
    nome: 'Chieri',
    codice: '001078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C627',
    cap: ['10023'],
    popolazione: 35962,
  },
  {
    nome: "Chies d'Alpago",
    codice: '025012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C630',
    cap: ['32010'],
    popolazione: 1411,
  },
  {
    nome: 'Chiesa in Valmalenco',
    codice: '014019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C628',
    cap: ['23023'],
    popolazione: 2591,
  },
  {
    nome: 'Chiesanuova',
    codice: '001079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C629',
    cap: ['10080'],
    popolazione: 203,
  },
  {
    nome: 'Chiesina Uzzanese',
    codice: '047022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'C631',
    cap: ['51013'],
    popolazione: 4479,
  },
  {
    nome: 'Chieti',
    codice: '069022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C632',
    cap: ['66100'],
    popolazione: 51484,
  },
  {
    nome: 'Chieuti',
    codice: '071021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'C633',
    cap: ['71010'],
    popolazione: 1772,
  },
  {
    nome: 'Chieve',
    codice: '019029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C634',
    cap: ['26010'],
    popolazione: 2240,
  },
  {
    nome: "Chignolo d'Isola",
    codice: '016072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C635',
    cap: ['24040'],
    popolazione: 3214,
  },
  {
    nome: 'Chignolo Po',
    codice: '018048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C637',
    cap: ['27013'],
    popolazione: 3992,
  },
  {
    nome: 'Chioggia',
    codice: '027008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C638',
    cap: ['30015'],
    popolazione: 49735,
  },
  {
    nome: 'Chiomonte',
    codice: '001080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C639',
    cap: ['10050'],
    popolazione: 932,
  },
  {
    nome: 'Chions',
    codice: '093013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C640',
    cap: ['33083'],
    popolazione: 5188,
  },
  {
    nome: 'Chiopris-Viscone',
    codice: '030024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C641',
    cap: ['33048'],
    popolazione: 620,
  },
  {
    nome: 'Chitignano',
    codice: '051014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C648',
    cap: ['52010'],
    popolazione: 933,
  },
  {
    nome: 'Chiuduno',
    codice: '016073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C649',
    cap: ['24060'],
    popolazione: 5852,
  },
  {
    nome: 'Chiuppano',
    codice: '024030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'C650',
    cap: ['36010'],
    popolazione: 2626,
  },
  {
    nome: 'Chiuro',
    codice: '014020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C651',
    cap: ['23030'],
    popolazione: 2524,
  },
  {
    nome: 'Chiusa',
    codice: '021022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'C652',
    cap: ['39043'],
    popolazione: 5144,
  },
  {
    nome: 'Chiusa di Pesio',
    codice: '004068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C653',
    cap: ['12013'],
    popolazione: 3730,
  },
  {
    nome: 'Chiusa di San Michele',
    codice: '001081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C655',
    cap: ['10050'],
    popolazione: 1691,
  },
  {
    nome: 'Chiusa Sclafani',
    codice: '082029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C654',
    cap: ['90033'],
    popolazione: 2957,
  },
  {
    nome: 'Chiusaforte',
    codice: '030025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C656',
    cap: ['33010'],
    popolazione: 703,
  },
  {
    nome: 'Chiusanico',
    codice: '008019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C657',
    cap: ['18027'],
    popolazione: 603,
  },
  {
    nome: "Chiusano d'Asti",
    codice: '005038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C658',
    cap: ['14025'],
    popolazione: 226,
  },
  {
    nome: 'Chiusano di San Domenico',
    codice: '064028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C659',
    cap: ['83040'],
    popolazione: 2351,
  },
  {
    nome: 'Chiusavecchia',
    codice: '008020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C660',
    cap: ['18027'],
    popolazione: 565,
  },
  {
    nome: 'Chiusdino',
    codice: '052010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C661',
    cap: ['53012'],
    popolazione: 1877,
  },
  {
    nome: 'Chiusi',
    codice: '052011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C662',
    cap: ['53043'],
    popolazione: 8836,
  },
  {
    nome: 'Chiusi della Verna',
    codice: '051015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C663',
    cap: ['52010'],
    popolazione: 2058,
  },
  {
    nome: 'Chivasso',
    codice: '001082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C665',
    cap: ['10034'],
    popolazione: 25914,
  },
  {
    nome: 'Ciampino',
    codice: '058118',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M272',
    cap: ['00043'],
    popolazione: 37235,
  },
  {
    nome: 'Cianciana',
    codice: '084015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'C668',
    cap: ['92012'],
    popolazione: 3517,
  },
  {
    nome: 'Cibiana di Cadore',
    codice: '025013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C672',
    cap: ['32040'],
    popolazione: 423,
  },
  {
    nome: 'Cicagna',
    codice: '010016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C673',
    cap: ['16044'],
    popolazione: 2566,
  },
  {
    nome: 'Cicala',
    codice: '079030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C674',
    cap: ['88040'],
    popolazione: 1008,
  },
  {
    nome: 'Cicciano',
    codice: '063027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'C675',
    cap: ['80033'],
    popolazione: 12698,
  },
  {
    nome: 'Cicerale',
    codice: '065042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C676',
    cap: ['84053'],
    popolazione: 1233,
  },
  {
    nome: 'Ciciliano',
    codice: '058030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C677',
    cap: ['00020'],
    popolazione: 1353,
  },
  {
    nome: 'Cicognolo',
    codice: '019030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C678',
    cap: ['26030'],
    popolazione: 955,
  },
  {
    nome: 'Ciconio',
    codice: '001083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C679',
    cap: ['10080'],
    popolazione: 371,
  },
  {
    nome: 'Cigliano',
    codice: '002042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'C680',
    cap: ['13043'],
    popolazione: 4547,
  },
  {
    nome: 'Cigliè',
    codice: '004069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C681',
    cap: ['12060'],
    popolazione: 196,
  },
  {
    nome: 'Cigognola',
    codice: '018049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C684',
    cap: ['27040'],
    popolazione: 1363,
  },
  {
    nome: 'Cigole',
    codice: '017053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C685',
    cap: ['25020'],
    popolazione: 1619,
  },
  {
    nome: 'Cilavegna',
    codice: '018050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C686',
    cap: ['27024'],
    popolazione: 5656,
  },
  {
    nome: 'Cimadolmo',
    codice: '026017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C689',
    cap: ['31010'],
    popolazione: 3413,
  },
  {
    nome: 'Cimbergo',
    codice: '017054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C691',
    cap: ['25050'],
    popolazione: 562,
  },
  {
    nome: 'Ciminà',
    codice: '080026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'C695',
    cap: ['89040'],
    popolazione: 595,
  },
  {
    nome: 'Ciminna',
    codice: '082030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C696',
    cap: ['90023'],
    popolazione: 3845,
  },
  {
    nome: 'Cimitile',
    codice: '063028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'C697',
    cap: ['80030'],
    popolazione: 7093,
  },
  {
    nome: 'Cimolais',
    codice: '093014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C699',
    cap: ['33080'],
    popolazione: 421,
  },
  {
    nome: 'Cimone',
    codice: '022058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C700',
    cap: ['38060'],
    popolazione: 685,
  },
  {
    nome: 'Cinaglio',
    codice: '005039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C701',
    cap: ['14020'],
    popolazione: 455,
  },
  {
    nome: 'Cineto Romano',
    codice: '058031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C702',
    cap: ['00020'],
    popolazione: 641,
  },
  {
    nome: "Cingia de' Botti",
    codice: '019031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'C703',
    cap: ['26042'],
    popolazione: 1371,
  },
  {
    nome: 'Cingoli',
    codice: '043012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'C704',
    cap: ['62011'],
    popolazione: 10509,
  },
  {
    nome: 'Cinigiano',
    codice: '053007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'C705',
    cap: ['58044'],
    popolazione: 2662,
  },
  {
    nome: 'Cinisello Balsamo',
    codice: '015077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C707',
    cap: ['20092'],
    popolazione: 71128,
  },
  {
    nome: 'Cinisi',
    codice: '082031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C708',
    cap: ['90045'],
    popolazione: 12031,
  },
  {
    nome: 'Cino',
    codice: '014021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C709',
    cap: ['23010'],
    popolazione: 373,
  },
  {
    nome: 'Cinquefrondi',
    codice: '080027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'C710',
    cap: ['89021'],
    popolazione: 6492,
  },
  {
    nome: 'Cintano',
    codice: '001084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C711',
    cap: ['10080'],
    popolazione: 261,
  },
  {
    nome: 'Cinte Tesino',
    codice: '022059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C712',
    cap: ['38050'],
    popolazione: 372,
  },
  {
    nome: 'Cinto Caomaggiore',
    codice: '027009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C714',
    cap: ['30020'],
    popolazione: 3285,
  },
  {
    nome: 'Cinto Euganeo',
    codice: '028031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'C713',
    cap: ['35030'],
    popolazione: 2023,
  },
  {
    nome: 'Cinzano',
    codice: '001085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C715',
    cap: ['10090'],
    popolazione: 375,
  },
  {
    nome: 'Ciorlano',
    codice: '061030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C716',
    cap: ['81010'],
    popolazione: 440,
  },
  {
    nome: 'Cipressa',
    codice: '008021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C718',
    cap: ['18017'],
    popolazione: 1271,
  },
  {
    nome: 'Circello',
    codice: '062024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C719',
    cap: ['82020'],
    popolazione: 2476,
  },
  {
    nome: 'Ciriè',
    codice: '001086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C722',
    cap: ['10073'],
    popolazione: 18415,
  },
  {
    nome: 'Cirigliano',
    codice: '077005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'C723',
    cap: ['75010'],
    popolazione: 361,
  },
  {
    nome: 'Cirimido',
    codice: '013068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C724',
    cap: ['22070'],
    popolazione: 2109,
  },
  {
    nome: 'Cirò',
    codice: '101007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'C725',
    cap: ['88813'],
    popolazione: 3125,
  },
  {
    nome: 'Cirò Marina',
    codice: '101008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'C726',
    cap: ['88811'],
    popolazione: 15051,
  },
  {
    nome: 'Cis',
    codice: '022060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C727',
    cap: ['38020'],
    popolazione: 309,
  },
  {
    nome: 'Cisano Bergamasco',
    codice: '016074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C728',
    cap: ['24034'],
    popolazione: 6268,
  },
  {
    nome: 'Cisano sul Neva',
    codice: '009025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'C729',
    cap: ['17035'],
    popolazione: 1964,
  },
  {
    nome: 'Ciserano',
    codice: '016075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C730',
    cap: ['24040'],
    popolazione: 5688,
  },
  {
    nome: 'Cislago',
    codice: '012050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C732',
    cap: ['21040'],
    popolazione: 9984,
  },
  {
    nome: 'Cisliano',
    codice: '015078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C733',
    cap: ['20080'],
    popolazione: 4263,
  },
  {
    nome: 'Cison di Valmarino',
    codice: '026018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C735',
    cap: ['31030'],
    popolazione: 2711,
  },
  {
    nome: 'Cissone',
    codice: '004070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C738',
    cap: ['12050'],
    popolazione: 82,
  },
  {
    nome: "Cisterna d'Asti",
    codice: '005040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C739',
    cap: ['14010'],
    popolazione: 1286,
  },
  {
    nome: 'Cisterna di Latina',
    codice: '059005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'C740',
    cap: ['04012'],
    popolazione: 35551,
  },
  {
    nome: 'Cisternino',
    codice: '074005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'C741',
    cap: ['72014'],
    popolazione: 11745,
  },
  {
    nome: 'Citerna',
    codice: '054011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C742',
    cap: ['06010'],
    popolazione: 3458,
  },
  {
    nome: 'Città della Pieve',
    codice: '054012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C744',
    cap: ['06062'],
    popolazione: 7803,
  },
  {
    nome: 'Città di Castello',
    codice: '054013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C745',
    cap: ['06012'],
    popolazione: 40064,
  },
  {
    nome: "Città Sant'Angelo",
    codice: '068012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C750',
    cap: ['65013'],
    popolazione: 14379,
  },
  {
    nome: 'Cittadella',
    codice: '028032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'C743',
    cap: ['35013'],
    popolazione: 19956,
  },
  {
    nome: 'Cittaducale',
    codice: '057016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C746',
    cap: ['02015'],
    popolazione: 6900,
  },
  {
    nome: 'Cittanova',
    codice: '080028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'C747',
    cap: ['89022'],
    popolazione: 10344,
  },
  {
    nome: 'Cittareale',
    codice: '057017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C749',
    cap: ['02010'],
    popolazione: 470,
  },
  {
    nome: 'Cittiglio',
    codice: '012051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C751',
    cap: ['21033'],
    popolazione: 3972,
  },
  {
    nome: 'Civate',
    codice: '097022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C752',
    cap: ['23862'],
    popolazione: 4019,
  },
  {
    nome: 'Civezza',
    codice: '008022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'C755',
    cap: ['18017'],
    popolazione: 640,
  },
  {
    nome: 'Civezzano',
    codice: '022061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C756',
    cap: ['38045'],
    popolazione: 3904,
  },
  {
    nome: 'Civiasco',
    codice: '002043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'C757',
    cap: ['13010'],
    popolazione: 265,
  },
  {
    nome: 'Cividale del Friuli',
    codice: '030026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C758',
    cap: ['33043'],
    popolazione: 11378,
  },
  {
    nome: 'Cividate al Piano',
    codice: '016076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C759',
    cap: ['24050'],
    popolazione: 5157,
  },
  {
    nome: 'Cividate Camuno',
    codice: '017055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C760',
    cap: ['25040'],
    popolazione: 2762,
  },
  {
    nome: 'Civita',
    codice: '078041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C763',
    cap: ['87010'],
    popolazione: 956,
  },
  {
    nome: 'Civita Castellana',
    codice: '056021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C765',
    cap: ['01033'],
    popolazione: 15596,
  },
  {
    nome: "Civita d'Antino",
    codice: '066034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C766',
    cap: ['67050'],
    popolazione: 994,
  },
  {
    nome: 'Civitacampomarano',
    codice: '070019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C764',
    cap: ['86030'],
    popolazione: 451,
  },
  {
    nome: 'Civitaluparella',
    codice: '069023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C768',
    cap: ['66040'],
    popolazione: 349,
  },
  {
    nome: 'Civitanova del Sannio',
    codice: '094016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C769',
    cap: ['86094'],
    popolazione: 955,
  },
  {
    nome: 'Civitanova Marche',
    codice: '043013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'C770',
    cap: ['62012'],
    popolazione: 40217,
  },
  {
    nome: 'Civitaquana',
    codice: '068013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C771',
    cap: ['65010'],
    popolazione: 1322,
  },
  {
    nome: 'Civitavecchia',
    codice: '058032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C773',
    cap: ['00053'],
    popolazione: 51229,
  },
  {
    nome: 'Civitella Alfedena',
    codice: '066035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C778',
    cap: ['67030'],
    popolazione: 303,
  },
  {
    nome: 'Civitella Casanova',
    codice: '068014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C779',
    cap: ['65010'],
    popolazione: 1875,
  },
  {
    nome: "Civitella d'Agliano",
    codice: '056022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C780',
    cap: ['01020'],
    popolazione: 1658,
  },
  {
    nome: 'Civitella del Tronto',
    codice: '067017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C781',
    cap: ['64010'],
    popolazione: 5333,
  },
  {
    nome: 'Civitella di Romagna',
    codice: '040009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'C777',
    cap: ['47012'],
    popolazione: 3792,
  },
  {
    nome: 'Civitella in Val di Chiana',
    codice: '051016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'C774',
    cap: ['52041'],
    popolazione: 9111,
  },
  {
    nome: 'Civitella Messer Raimondo',
    codice: '069024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C776',
    cap: ['66010'],
    popolazione: 861,
  },
  {
    nome: 'Civitella Paganico',
    codice: '053008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'C782',
    cap: ['58045'],
    popolazione: 3136,
  },
  {
    nome: 'Civitella Roveto',
    codice: '066036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C783',
    cap: ['67054'],
    popolazione: 3374,
  },
  {
    nome: 'Civitella San Paolo',
    codice: '058033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C784',
    cap: ['00060'],
    popolazione: 1754,
  },
  {
    nome: 'Civo',
    codice: '014022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C785',
    cap: ['23010'],
    popolazione: 1114,
  },
  {
    nome: 'Claino con Osteno',
    codice: '013071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C787',
    cap: ['22010'],
    popolazione: 543,
  },
  {
    nome: 'Claut',
    codice: '093015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C790',
    cap: ['33080'],
    popolazione: 1005,
  },
  {
    nome: 'Clauzetto',
    codice: '093016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C791',
    cap: ['33090'],
    popolazione: 390,
  },
  {
    nome: 'Clavesana',
    codice: '004071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'C792',
    cap: ['12060'],
    popolazione: 900,
  },
  {
    nome: 'Claviere',
    codice: '001087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C793',
    cap: ['10050'],
    popolazione: 192,
  },
  {
    nome: 'Cles',
    codice: '022062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C794',
    cap: ['38023'],
    popolazione: 6769,
  },
  {
    nome: 'Cleto',
    codice: '078042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C795',
    cap: ['87030'],
    popolazione: 1320,
  },
  {
    nome: 'Clivio',
    codice: '012052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C796',
    cap: ['21050'],
    popolazione: 1942,
  },
  {
    nome: 'Clusone',
    codice: '016077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C800',
    cap: ['24023'],
    popolazione: 8678,
  },
  {
    nome: 'Coassolo Torinese',
    codice: '001088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C801',
    cap: ['10070'],
    popolazione: 1550,
  },
  {
    nome: 'Coazze',
    codice: '001089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C803',
    cap: ['10050'],
    popolazione: 3084,
  },
  {
    nome: 'Coazzolo',
    codice: '005041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C804',
    cap: ['14054'],
    popolazione: 316,
  },
  {
    nome: 'Coccaglio',
    codice: '017056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C806',
    cap: ['25030'],
    popolazione: 8469,
  },
  {
    nome: 'Cocconato',
    codice: '005042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'C807',
    cap: ['14023'],
    popolazione: 1564,
  },
  {
    nome: 'Cocquio-Trevisago',
    codice: '012053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C810',
    cap: ['21034'],
    popolazione: 4756,
  },
  {
    nome: 'Cocullo',
    codice: '066037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C811',
    cap: ['67030'],
    popolazione: 265,
  },
  {
    nome: 'Codevigo',
    codice: '028033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'C812',
    cap: ['35020'],
    popolazione: 6453,
  },
  {
    nome: 'Codevilla',
    codice: '018051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C813',
    cap: ['27050'],
    popolazione: 1000,
  },
  {
    nome: 'Codigoro',
    codice: '038005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'C814',
    cap: ['44021'],
    popolazione: 12389,
  },
  {
    nome: 'Codognè',
    codice: '026019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C815',
    cap: ['31013'],
    popolazione: 5311,
  },
  {
    nome: 'Codogno',
    codice: '098019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C816',
    cap: ['26845'],
    popolazione: 15371,
  },
  {
    nome: 'Codroipo',
    codice: '030027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C817',
    cap: ['33033'],
    popolazione: 15806,
  },
  {
    nome: 'Codrongianos',
    codice: '090026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'C818',
    cap: ['07040'],
    popolazione: 1346,
  },
  {
    nome: 'Coggiola',
    codice: '096019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'C819',
    cap: ['13863'],
    popolazione: 1996,
  },
  {
    nome: 'Cogliate',
    codice: '108020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'C820',
    cap: ['20815'],
    popolazione: 8450,
  },
  {
    nome: 'Cogne',
    codice: '007021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C821',
    cap: ['11012'],
    popolazione: 1453,
  },
  {
    nome: 'Cogoleto',
    codice: '010017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C823',
    cap: ['16016'],
    popolazione: 9145,
  },
  {
    nome: 'Cogollo del Cengio',
    codice: '024032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'C824',
    cap: ['36010'],
    popolazione: 3390,
  },
  {
    nome: 'Cogorno',
    codice: '010018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C826',
    cap: ['16030'],
    popolazione: 5641,
  },
  {
    nome: 'Colazza',
    codice: '003051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C829',
    cap: ['28010'],
    popolazione: 463,
  },
  {
    nome: 'Colceresa',
    codice: '024126',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M426',
    cap: ['36064'],
    popolazione: 6113,
  },
  {
    nome: 'Colere',
    codice: '016078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C835',
    cap: ['24020'],
    popolazione: 1137,
  },
  {
    nome: 'Colfelice',
    codice: '060027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C836',
    cap: ['03030'],
    popolazione: 1853,
  },
  {
    nome: 'Coli',
    codice: '033016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'C838',
    cap: ['29020'],
    popolazione: 955,
  },
  {
    nome: 'Colico',
    codice: '097023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C839',
    cap: ['23823'],
    popolazione: 7473,
  },
  {
    nome: 'Collalto Sabino',
    codice: '057018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C841',
    cap: ['02022'],
    popolazione: 440,
  },
  {
    nome: 'Collarmele',
    codice: '066038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C844',
    cap: ['67040'],
    popolazione: 950,
  },
  {
    nome: 'Collazzone',
    codice: '054014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C845',
    cap: ['06050'],
    popolazione: 3578,
  },
  {
    nome: 'Colle Brianza',
    codice: '097024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'C851',
    cap: ['23886'],
    popolazione: 1736,
  },
  {
    nome: "Colle d'Anchise",
    codice: '070020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C854',
    cap: ['86020'],
    popolazione: 802,
  },
  {
    nome: 'Colle di Tora',
    codice: '057019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C857',
    cap: ['02020'],
    popolazione: 384,
  },
  {
    nome: "Colle di Val d'Elsa",
    codice: '052012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'C847',
    cap: ['53034'],
    popolazione: 21256,
  },
  {
    nome: 'Colle San Magno',
    codice: '060029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C870',
    cap: ['03030'],
    popolazione: 744,
  },
  {
    nome: 'Colle Sannita',
    codice: '062025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'C846',
    cap: ['82024'],
    popolazione: 2513,
  },
  {
    nome: 'Colle Santa Lucia',
    codice: '025014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C872',
    cap: ['32020'],
    popolazione: 391,
  },
  {
    nome: 'Colle Umberto',
    codice: '026020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C848',
    cap: ['31014'],
    popolazione: 5177,
  },
  {
    nome: 'Collebeato',
    codice: '017057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C850',
    cap: ['25060'],
    popolazione: 4698,
  },
  {
    nome: 'Collecchio',
    codice: '034009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'C852',
    cap: ['43044'],
    popolazione: 13893,
  },
  {
    nome: 'Collecorvino',
    codice: '068015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'C853',
    cap: ['65010'],
    popolazione: 5908,
  },
  {
    nome: 'Colledara',
    codice: '067018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C311',
    cap: ['64042'],
    popolazione: 2237,
  },
  {
    nome: 'Colledimacine',
    codice: '069025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C855',
    cap: ['66010'],
    popolazione: 237,
  },
  {
    nome: 'Colledimezzo',
    codice: '069026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'C856',
    cap: ['66040'],
    popolazione: 531,
  },
  {
    nome: 'Colleferro',
    codice: '058034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C858',
    cap: ['00034'],
    popolazione: 21574,
  },
  {
    nome: 'Collegiove',
    codice: '057020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C859',
    cap: ['02020'],
    popolazione: 169,
  },
  {
    nome: 'Collegno',
    codice: '001090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C860',
    cap: ['10093'],
    popolazione: 49083,
  },
  {
    nome: 'Collelongo',
    codice: '066039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C862',
    cap: ['67050'],
    popolazione: 1313,
  },
  {
    nome: 'Collepardo',
    codice: '060028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C864',
    cap: ['03010'],
    popolazione: 975,
  },
  {
    nome: 'Collepasso',
    codice: '075021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'C865',
    cap: ['73040'],
    popolazione: 6352,
  },
  {
    nome: 'Collepietro',
    codice: '066040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C866',
    cap: ['67020'],
    popolazione: 235,
  },
  {
    nome: 'Colleretto Castelnuovo',
    codice: '001091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C867',
    cap: ['10080'],
    popolazione: 347,
  },
  {
    nome: 'Colleretto Giacosa',
    codice: '001092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C868',
    cap: ['10010'],
    popolazione: 603,
  },
  {
    nome: 'Collesalvetti',
    codice: '049008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'C869',
    cap: ['57014'],
    popolazione: 16707,
  },
  {
    nome: 'Collesano',
    codice: '082032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C871',
    cap: ['90016'],
    popolazione: 4095,
  },
  {
    nome: 'Colletorto',
    codice: '070021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C875',
    cap: ['86044'],
    popolazione: 2087,
  },
  {
    nome: 'Collevecchio',
    codice: '057021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C876',
    cap: ['02042'],
    popolazione: 1595,
  },
  {
    nome: 'Colli a Volturno',
    codice: '094017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C878',
    cap: ['86073'],
    popolazione: 1382,
  },
  {
    nome: 'Colli al Metauro',
    codice: '041069',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'M380',
    cap: ['61036'],
    popolazione: 12166,
  },
  {
    nome: 'Colli del Tronto',
    codice: '044014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'C877',
    cap: ['63079'],
    popolazione: 3566,
  },
  {
    nome: 'Colli sul Velino',
    codice: '057022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C880',
    cap: ['02010'],
    popolazione: 524,
  },
  {
    nome: 'Colli Verdi',
    codice: '018193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M419',
    cap: ['27061'],
    popolazione: 1156,
  },
  {
    nome: 'Colliano',
    codice: '065043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C879',
    cap: ['84020'],
    popolazione: 3764,
  },
  {
    nome: 'Collinas',
    codice: '111012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'C882',
    cap: ['09020'],
    popolazione: 885,
  },
  {
    nome: 'Collio',
    codice: '017058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C883',
    cap: ['25060'],
    popolazione: 2227,
  },
  {
    nome: 'Collobiano',
    codice: '002045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'C884',
    cap: ['13030'],
    popolazione: 105,
  },
  {
    nome: 'Colloredo di Monte Albano',
    codice: '030028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C885',
    cap: ['33010'],
    popolazione: 2231,
  },
  {
    nome: 'Colmurano',
    codice: '043014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'C886',
    cap: ['62020'],
    popolazione: 1278,
  },
  {
    nome: 'Colobraro',
    codice: '077006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'C888',
    cap: ['75021'],
    popolazione: 1342,
  },
  {
    nome: 'Cologna Veneta',
    codice: '023027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C890',
    cap: ['37044'],
    popolazione: 8607,
  },
  {
    nome: 'Cologne',
    codice: '017059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C893',
    cap: ['25033'],
    popolazione: 7534,
  },
  {
    nome: 'Cologno al Serio',
    codice: '016079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C894',
    cap: ['24055'],
    popolazione: 10596,
  },
  {
    nome: 'Cologno Monzese',
    codice: '015081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C895',
    cap: ['20093'],
    popolazione: 45786,
  },
  {
    nome: 'Colognola ai Colli',
    codice: '023028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C897',
    cap: ['37030'],
    popolazione: 8141,
  },
  {
    nome: 'Colonna',
    codice: '058035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C900',
    cap: ['00030'],
    popolazione: 4002,
  },
  {
    nome: 'Colonnella',
    codice: '067019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C901',
    cap: ['64010'],
    popolazione: 3768,
  },
  {
    nome: 'Colonno',
    codice: '013074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C902',
    cap: ['22010'],
    popolazione: 529,
  },
  {
    nome: 'Colorina',
    codice: '014023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'C903',
    cap: ['23010'],
    popolazione: 1460,
  },
  {
    nome: 'Colorno',
    codice: '034010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'C904',
    cap: ['43052'],
    popolazione: 8920,
  },
  {
    nome: 'Colosimi',
    codice: '078043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C905',
    cap: ['87050'],
    popolazione: 1313,
  },
  {
    nome: 'Colturano',
    codice: '015082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C908',
    cap: ['20060'],
    popolazione: 1952,
  },
  {
    nome: 'Colverde',
    codice: '013251',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M336',
    cap: ['22041'],
    popolazione: 5234,
  },
  {
    nome: 'Colzate',
    codice: '016080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C910',
    cap: ['24020'],
    popolazione: 1664,
  },
  {
    nome: 'Comabbio',
    codice: '012054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C911',
    cap: ['21020'],
    popolazione: 1194,
  },
  {
    nome: 'Comacchio',
    codice: '038006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'C912',
    cap: ['44022'],
    popolazione: 22648,
  },
  {
    nome: 'Comano',
    codice: '045005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'C914',
    cap: ['54015'],
    popolazione: 755,
  },
  {
    nome: 'Comano Terme',
    codice: '022228',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M314',
    cap: ['38077'],
    popolazione: 2913,
  },
  {
    nome: 'Comazzo',
    codice: '098020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'C917',
    cap: ['26833'],
    popolazione: 2183,
  },
  {
    nome: 'Comeglians',
    codice: '030029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'C918',
    cap: ['33023'],
    popolazione: 532,
  },
  {
    nome: 'Comelico Superiore',
    codice: '025015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C920',
    cap: ['32040'],
    popolazione: 2341,
  },
  {
    nome: 'Comerio',
    codice: '012055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'C922',
    cap: ['21025'],
    popolazione: 2616,
  },
  {
    nome: 'Comezzano-Cizzago',
    codice: '017060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C925',
    cap: ['25030'],
    popolazione: 3717,
  },
  {
    nome: 'Comignago',
    codice: '003052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'C926',
    cap: ['28060'],
    popolazione: 1223,
  },
  {
    nome: 'Comiso',
    codice: '088003',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'C927',
    cap: ['97013'],
    popolazione: 29184,
  },
  {
    nome: 'Comitini',
    codice: '084016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'C928',
    cap: ['92020'],
    popolazione: 944,
  },
  {
    nome: 'Comiziano',
    codice: '063029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'C929',
    cap: ['80030'],
    popolazione: 1842,
  },
  {
    nome: 'Commessaggio',
    codice: '020020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'C930',
    cap: ['46010'],
    popolazione: 1169,
  },
  {
    nome: 'Commezzadura',
    codice: '022064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'C931',
    cap: ['38020'],
    popolazione: 993,
  },
  {
    nome: 'Como',
    codice: '013075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C933',
    cap: ['22100'],
    popolazione: 82045,
  },
  {
    nome: 'Compiano',
    codice: '034011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'C934',
    cap: ['43053'],
    popolazione: 1122,
  },
  {
    nome: 'Comun Nuovo',
    codice: '016081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C937',
    cap: ['24040'],
    popolazione: 4163,
  },
  {
    nome: 'Comunanza',
    codice: '044015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'C935',
    cap: ['63087'],
    popolazione: 3204,
  },
  {
    nome: 'Cona',
    codice: '027010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C938',
    cap: ['30010'],
    popolazione: 3175,
  },
  {
    nome: 'Conca Casale',
    codice: '094018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'C941',
    cap: ['86070'],
    popolazione: 214,
  },
  {
    nome: 'Conca dei Marini',
    codice: '065044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C940',
    cap: ['84010'],
    popolazione: 730,
  },
  {
    nome: 'Conca della Campania',
    codice: '061031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'C939',
    cap: ['81044'],
    popolazione: 1256,
  },
  {
    nome: 'Concamarise',
    codice: '023029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'C943',
    cap: ['37050'],
    popolazione: 1078,
  },
  {
    nome: 'Concerviano',
    codice: '057023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C946',
    cap: ['02020'],
    popolazione: 311,
  },
  {
    nome: 'Concesio',
    codice: '017061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C948',
    cap: ['25062'],
    popolazione: 14813,
  },
  {
    nome: 'Concordia Sagittaria',
    codice: '027011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C950',
    cap: ['30023'],
    popolazione: 10640,
  },
  {
    nome: 'Concordia sulla Secchia',
    codice: '036010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'C951',
    cap: ['41033'],
    popolazione: 8968,
  },
  {
    nome: 'Concorezzo',
    codice: '108021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'C952',
    cap: ['20863'],
    popolazione: 15193,
  },
  {
    nome: 'Condofuri',
    codice: '080029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'C954',
    cap: ['89030'],
    popolazione: 5074,
  },
  {
    nome: 'Condove',
    codice: '001093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'C955',
    cap: ['10055'],
    popolazione: 4670,
  },
  {
    nome: 'Condrò',
    codice: '083018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'C956',
    cap: ['98040'],
    popolazione: 481,
  },
  {
    nome: 'Conegliano',
    codice: '026021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C957',
    cap: ['31015'],
    popolazione: 34428,
  },
  {
    nome: 'Confienza',
    codice: '018052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C958',
    cap: ['27030'],
    popolazione: 1671,
  },
  {
    nome: 'Configni',
    codice: '057024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C959',
    cap: ['02040'],
    popolazione: 672,
  },
  {
    nome: 'Conflenti',
    codice: '079033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'C960',
    cap: ['88040'],
    popolazione: 1437,
  },
  {
    nome: 'Coniolo',
    codice: '006060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C962',
    cap: ['15030'],
    popolazione: 451,
  },
  {
    nome: 'Conselice',
    codice: '039008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'C963',
    cap: ['48017'],
    popolazione: 9837,
  },
  {
    nome: 'Conselve',
    codice: '028034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'C964',
    cap: ['35026'],
    popolazione: 10293,
  },
  {
    nome: 'Contà',
    codice: '022242',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M356',
    cap: ['38093'],
    popolazione: 1383,
  },
  {
    nome: 'Contessa Entellina',
    codice: '082033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'C968',
    cap: ['90030'],
    popolazione: 1865,
  },
  {
    nome: 'Contigliano',
    codice: '057025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'C969',
    cap: ['02043'],
    popolazione: 3601,
  },
  {
    nome: 'Contrada',
    codice: '064029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C971',
    cap: ['83020'],
    popolazione: 3005,
  },
  {
    nome: 'Controguerra',
    codice: '067020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'C972',
    cap: ['64010'],
    popolazione: 2422,
  },
  {
    nome: 'Controne',
    codice: '065045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C973',
    cap: ['84020'],
    popolazione: 872,
  },
  {
    nome: 'Contursi Terme',
    codice: '065046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C974',
    cap: ['84024'],
    popolazione: 3337,
  },
  {
    nome: 'Conversano',
    codice: '072019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'C975',
    cap: ['70014'],
    popolazione: 25683,
  },
  {
    nome: 'Conza della Campania',
    codice: '064030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'C976',
    cap: ['83040'],
    popolazione: 1432,
  },
  {
    nome: 'Conzano',
    codice: '006061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'C977',
    cap: ['15030'],
    popolazione: 1015,
  },
  {
    nome: 'Copertino',
    codice: '075022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'C978',
    cap: ['73043'],
    popolazione: 23870,
  },
  {
    nome: 'Copiano',
    codice: '018053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C979',
    cap: ['27010'],
    popolazione: 1794,
  },
  {
    nome: 'Copparo',
    codice: '038007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'C980',
    cap: ['44034'],
    popolazione: 17017,
  },
  {
    nome: 'Corana',
    codice: '018054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'C982',
    cap: ['27050'],
    popolazione: 798,
  },
  {
    nome: 'Corato',
    codice: '072020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'C983',
    cap: ['70033'],
    popolazione: 48072,
  },
  {
    nome: 'Corbara',
    codice: '065047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'C984',
    cap: ['84010'],
    popolazione: 2521,
  },
  {
    nome: 'Corbetta',
    codice: '015085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C986',
    cap: ['20011'],
    popolazione: 17460,
  },
  {
    nome: 'Corbola',
    codice: '029017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'C987',
    cap: ['45015'],
    popolazione: 2514,
  },
  {
    nome: 'Corchiano',
    codice: '056023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'C988',
    cap: ['01030'],
    popolazione: 3740,
  },
  {
    nome: 'Corciano',
    codice: '054015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'C990',
    cap: ['06073'],
    popolazione: 20255,
  },
  {
    nome: 'Cordenons',
    codice: '093017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C991',
    cap: ['33084'],
    popolazione: 18203,
  },
  {
    nome: 'Cordignano',
    codice: '026022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C992',
    cap: ['31016'],
    popolazione: 7096,
  },
  {
    nome: 'Cordovado',
    codice: '093018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'C993',
    cap: ['33075'],
    popolazione: 2748,
  },
  {
    nome: 'Coreglia Antelminelli',
    codice: '046011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'C996',
    cap: ['55025'],
    popolazione: 5232,
  },
  {
    nome: 'Coreglia Ligure',
    codice: '010019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'C995',
    cap: ['16040'],
    popolazione: 274,
  },
  {
    nome: 'Coreno Ausonio',
    codice: '060030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'C998',
    cap: ['03040'],
    popolazione: 1671,
  },
  {
    nome: 'Corfinio',
    codice: '066041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'C999',
    cap: ['67030'],
    popolazione: 1079,
  },
  {
    nome: 'Cori',
    codice: '059006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'D003',
    cap: ['04010'],
    popolazione: 11025,
  },
  {
    nome: 'Coriano',
    codice: '099003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'D004',
    cap: ['47853'],
    popolazione: 10028,
  },
  {
    nome: "Corigliano d'Otranto",
    codice: '075023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D006',
    cap: ['73022'],
    popolazione: 5771,
  },
  {
    nome: 'Corigliano-Rossano',
    codice: '078157',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'M403',
    cap: ['87064'],
    popolazione: 74848,
  },
  {
    nome: 'Corinaldo',
    codice: '042015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'D007',
    cap: ['60013'],
    popolazione: 5106,
  },
  {
    nome: 'Corio',
    codice: '001094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D008',
    cap: ['10070'],
    popolazione: 3330,
  },
  {
    nome: 'Corleone',
    codice: '082034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'D009',
    cap: ['90034'],
    popolazione: 11286,
  },
  {
    nome: 'Corleto Monforte',
    codice: '065048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D011',
    cap: ['84020'],
    popolazione: 643,
  },
  {
    nome: 'Corleto Perticara',
    codice: '076029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D010',
    cap: ['85012'],
    popolazione: 2607,
  },
  {
    nome: 'Cormano',
    codice: '015086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D013',
    cap: ['20032'],
    popolazione: 19944,
  },
  {
    nome: 'Cormons',
    codice: '031002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'D014',
    cap: ['34071'],
    popolazione: 7543,
  },
  {
    nome: 'Corna Imagna',
    codice: '016082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D015',
    cap: ['24030'],
    popolazione: 953,
  },
  {
    nome: 'Cornalba',
    codice: '016249',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D016',
    cap: ['24017'],
    popolazione: 301,
  },
  {
    nome: 'Cornale e Bastida',
    codice: '018191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M338',
    cap: ['27056'],
    popolazione: 895,
  },
  {
    nome: 'Cornaredo',
    codice: '015087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D018',
    cap: ['20010'],
    popolazione: 20121,
  },
  {
    nome: "Cornate d'Adda",
    codice: '108053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'D019',
    cap: ['20872'],
    popolazione: 10363,
  },
  {
    nome: "Cornedo all'Isarco",
    codice: '021023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'B799',
    cap: ['39053'],
    popolazione: 3312,
  },
  {
    nome: 'Cornedo Vicentino',
    codice: '024034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D020',
    cap: ['36073'],
    popolazione: 11939,
  },
  {
    nome: 'Cornegliano Laudense',
    codice: '098021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D021',
    cap: ['26854'],
    popolazione: 2838,
  },
  {
    nome: "Corneliano d'Alba",
    codice: '004072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D022',
    cap: ['12040'],
    popolazione: 2037,
  },
  {
    nome: 'Corniglio',
    codice: '034012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'D026',
    cap: ['43021'],
    popolazione: 1997,
  },
  {
    nome: 'Corno di Rosazzo',
    codice: '030030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D027',
    cap: ['33040'],
    popolazione: 3269,
  },
  {
    nome: 'Corno Giovine',
    codice: '098022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D028',
    cap: ['26846'],
    popolazione: 1193,
  },
  {
    nome: 'Cornovecchio',
    codice: '098023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D029',
    cap: ['26842'],
    popolazione: 226,
  },
  {
    nome: 'Cornuda',
    codice: '026023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D030',
    cap: ['31041'],
    popolazione: 6217,
  },
  {
    nome: 'Correggio',
    codice: '035020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'D037',
    cap: ['42015'],
    popolazione: 24825,
  },
  {
    nome: 'Correzzana',
    codice: '108022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'D038',
    cap: ['20856'],
    popolazione: 2657,
  },
  {
    nome: 'Correzzola',
    codice: '028035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D040',
    cap: ['35020'],
    popolazione: 5488,
  },
  {
    nome: 'Corrido',
    codice: '013077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D041',
    cap: ['22010'],
    popolazione: 841,
  },
  {
    nome: 'Corridonia',
    codice: '043015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'D042',
    cap: ['62014'],
    popolazione: 15322,
  },
  {
    nome: 'Corropoli',
    codice: '067021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'D043',
    cap: ['64013'],
    popolazione: 4750,
  },
  {
    nome: 'Corsano',
    codice: '075024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D044',
    cap: ['73033'],
    popolazione: 5632,
  },
  {
    nome: 'Corsico',
    codice: '015093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D045',
    cap: ['20094'],
    popolazione: 33669,
  },
  {
    nome: 'Corsione',
    codice: '005044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D046',
    cap: ['14020'],
    popolazione: 205,
  },
  {
    nome: 'Cortaccia sulla strada del vino',
    codice: '021024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D048',
    cap: ['39040'],
    popolazione: 2228,
  },
  {
    nome: 'Cortale',
    codice: '079034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D049',
    cap: ['88020'],
    popolazione: 2217,
  },
  {
    nome: 'Cortandone',
    codice: '005045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D050',
    cap: ['14013'],
    popolazione: 323,
  },
  {
    nome: 'Cortanze',
    codice: '005046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D051',
    cap: ['14020'],
    popolazione: 288,
  },
  {
    nome: 'Cortazzone',
    codice: '005047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D052',
    cap: ['14010'],
    popolazione: 657,
  },
  {
    nome: 'Corte Brugnatella',
    codice: '033017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'D054',
    cap: ['29020'],
    popolazione: 671,
  },
  {
    nome: "Corte de' Cortesi con Cignone",
    codice: '019032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D056',
    cap: ['26020'],
    popolazione: 1123,
  },
  {
    nome: "Corte de' Frati",
    codice: '019033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D057',
    cap: ['26010'],
    popolazione: 1423,
  },
  {
    nome: 'Corte Franca',
    codice: '017062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D058',
    cap: ['25040'],
    popolazione: 7078,
  },
  {
    nome: 'Corte Palasio',
    codice: '098024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D068',
    cap: ['26834'],
    popolazione: 1550,
  },
  {
    nome: 'Cortemaggiore',
    codice: '033018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'D061',
    cap: ['29016'],
    popolazione: 4456,
  },
  {
    nome: 'Cortemilia',
    codice: '004073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D062',
    cap: ['12074'],
    popolazione: 2388,
  },
  {
    nome: 'Corteno Golgi',
    codice: '017063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D064',
    cap: ['25040'],
    popolazione: 2015,
  },
  {
    nome: 'Cortenova',
    codice: '097025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D065',
    cap: ['23813'],
    popolazione: 1265,
  },
  {
    nome: 'Cortenuova',
    codice: '016083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D066',
    cap: ['24050'],
    popolazione: 1942,
  },
  {
    nome: 'Corteolona e Genzone',
    codice: '018192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M372',
    cap: ['27014'],
    popolazione: 2490,
  },
  {
    nome: 'Cortiglione',
    codice: '005048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D072',
    cap: ['14040'],
    popolazione: 576,
  },
  {
    nome: "Cortina d'Ampezzo",
    codice: '025016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'A266',
    cap: ['32043'],
    popolazione: 5890,
  },
  {
    nome: 'Cortina sulla strada del vino',
    codice: '021025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D075',
    cap: ['39040'],
    popolazione: 656,
  },
  {
    nome: 'Cortino',
    codice: '067022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'D076',
    cap: ['64040'],
    popolazione: 683,
  },
  {
    nome: 'Cortona',
    codice: '051017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'D077',
    cap: ['52044'],
    popolazione: 22495,
  },
  {
    nome: 'Corvara',
    codice: '068016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'D078',
    cap: ['65020'],
    popolazione: 278,
  },
  {
    nome: 'Corvara in Badia',
    codice: '021026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D079',
    cap: ['39033'],
    popolazione: 1320,
  },
  {
    nome: 'Corvino San Quirico',
    codice: '018057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D081',
    cap: ['27050'],
    popolazione: 1034,
  },
  {
    nome: 'Corzano',
    codice: '017064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D082',
    cap: ['25030'],
    popolazione: 1397,
  },
  {
    nome: 'Coseano',
    codice: '030031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D085',
    cap: ['33030'],
    popolazione: 2247,
  },
  {
    nome: 'Cosenza',
    codice: '078045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D086',
    cap: ['87100'],
    popolazione: 69484,
  },
  {
    nome: "Cosio d'Arroscia",
    codice: '008023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D087',
    cap: ['18023'],
    popolazione: 248,
  },
  {
    nome: 'Cosio Valtellino',
    codice: '014024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D088',
    cap: ['23013'],
    popolazione: 5400,
  },
  {
    nome: 'Cosoleto',
    codice: '080030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D089',
    cap: ['89050'],
    popolazione: 916,
  },
  {
    nome: 'Cossano Belbo',
    codice: '004074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D093',
    cap: ['12054'],
    popolazione: 1030,
  },
  {
    nome: 'Cossano Canavese',
    codice: '001095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D092',
    cap: ['10010'],
    popolazione: 522,
  },
  {
    nome: 'Cossato',
    codice: '096020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'D094',
    cap: ['13836'],
    popolazione: 14810,
  },
  {
    nome: 'Cosseria',
    codice: '009026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'D095',
    cap: ['17017'],
    popolazione: 1080,
  },
  {
    nome: 'Cossignano',
    codice: '044016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'D096',
    cap: ['63067'],
    popolazione: 1015,
  },
  {
    nome: 'Cossogno',
    codice: '103023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D099',
    cap: ['28801'],
    popolazione: 588,
  },
  {
    nome: 'Cossoine',
    codice: '090027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'D100',
    cap: ['07010'],
    popolazione: 900,
  },
  {
    nome: 'Cossombrato',
    codice: '005049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D101',
    cap: ['14020'],
    popolazione: 541,
  },
  {
    nome: "Costa de' Nobili",
    codice: '018058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D109',
    cap: ['27010'],
    popolazione: 351,
  },
  {
    nome: 'Costa di Mezzate',
    codice: '016084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D110',
    cap: ['24060'],
    popolazione: 3271,
  },
  {
    nome: 'Costa di Rovigo',
    codice: '029018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D105',
    cap: ['45023'],
    popolazione: 2683,
  },
  {
    nome: 'Costa Masnaga',
    codice: '097026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D112',
    cap: ['23845'],
    popolazione: 4751,
  },
  {
    nome: 'Costa Serina',
    codice: '016247',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D111',
    cap: ['24010'],
    popolazione: 973,
  },
  {
    nome: 'Costa Valle Imagna',
    codice: '016085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D103',
    cap: ['24030'],
    popolazione: 620,
  },
  {
    nome: 'Costa Vescovato',
    codice: '006062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D102',
    cap: ['15050'],
    popolazione: 357,
  },
  {
    nome: 'Costa Volpino',
    codice: '016086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D117',
    cap: ['24062'],
    popolazione: 9194,
  },
  {
    nome: 'Costabissara',
    codice: '024035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D107',
    cap: ['36030'],
    popolazione: 7161,
  },
  {
    nome: 'Costacciaro',
    codice: '054016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'D108',
    cap: ['06021'],
    popolazione: 1283,
  },
  {
    nome: 'Costanzana',
    codice: '002047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D113',
    cap: ['13033'],
    popolazione: 816,
  },
  {
    nome: 'Costarainera',
    codice: '008024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D114',
    cap: ['18017'],
    popolazione: 803,
  },
  {
    nome: 'Costermano sul Garda',
    codice: '023030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D118',
    cap: ['37010'],
    popolazione: 3586,
  },
  {
    nome: "Costigliole d'Asti",
    codice: '005050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D119',
    cap: ['14055'],
    popolazione: 5969,
  },
  {
    nome: 'Costigliole Saluzzo',
    codice: '004075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D120',
    cap: ['12024'],
    popolazione: 3344,
  },
  {
    nome: 'Cotignola',
    codice: '039009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'D121',
    cap: ['48033'],
    popolazione: 7384,
  },
  {
    nome: 'Cotronei',
    codice: '101009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'D123',
    cap: ['88836'],
    popolazione: 5434,
  },
  {
    nome: 'Cottanello',
    codice: '057026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'D124',
    cap: ['02040'],
    popolazione: 562,
  },
  {
    nome: 'Courmayeur',
    codice: '007022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D012',
    cap: ['11013'],
    popolazione: 2815,
  },
  {
    nome: 'Covo',
    codice: '016087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D126',
    cap: ['24050'],
    popolazione: 4066,
  },
  {
    nome: 'Cozzo',
    codice: '018059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D127',
    cap: ['27030'],
    popolazione: 371,
  },
  {
    nome: 'Craco',
    codice: '077007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'D128',
    cap: ['75010'],
    popolazione: 766,
  },
  {
    nome: 'Crandola Valsassina',
    codice: '097027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D131',
    cap: ['23832'],
    popolazione: 273,
  },
  {
    nome: 'Cravagliana',
    codice: '002048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D132',
    cap: ['13020'],
    popolazione: 278,
  },
  {
    nome: 'Cravanzana',
    codice: '004076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D133',
    cap: ['12050'],
    popolazione: 408,
  },
  {
    nome: 'Craveggia',
    codice: '103024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D134',
    cap: ['28852'],
    popolazione: 728,
  },
  {
    nome: 'Creazzo',
    codice: '024036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D136',
    cap: ['36051'],
    popolazione: 11038,
  },
  {
    nome: 'Crecchio',
    codice: '069027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D137',
    cap: ['66014'],
    popolazione: 2932,
  },
  {
    nome: 'Credaro',
    codice: '016088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D139',
    cap: ['24060'],
    popolazione: 3336,
  },
  {
    nome: 'Credera Rubbiano',
    codice: '019034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D141',
    cap: ['26010'],
    popolazione: 1645,
  },
  {
    nome: 'Crema',
    codice: '019035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D142',
    cap: ['26013'],
    popolazione: 33091,
  },
  {
    nome: 'Cremella',
    codice: '097028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D143',
    cap: ['23894'],
    popolazione: 1762,
  },
  {
    nome: 'Cremenaga',
    codice: '012056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D144',
    cap: ['21030'],
    popolazione: 768,
  },
  {
    nome: 'Cremeno',
    codice: '097029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D145',
    cap: ['23814'],
    popolazione: 1438,
  },
  {
    nome: 'Cremia',
    codice: '013083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D147',
    cap: ['22010'],
    popolazione: 709,
  },
  {
    nome: 'Cremolino',
    codice: '006063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D149',
    cap: ['15010'],
    popolazione: 1062,
  },
  {
    nome: 'Cremona',
    codice: '019036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D150',
    cap: ['26100'],
    popolazione: 69589,
  },
  {
    nome: 'Cremosano',
    codice: '019037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D151',
    cap: ['26010'],
    popolazione: 1580,
  },
  {
    nome: 'Crescentino',
    codice: '002049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D154',
    cap: ['13044'],
    popolazione: 7984,
  },
  {
    nome: 'Crespadoro',
    codice: '024037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D156',
    cap: ['36070'],
    popolazione: 1452,
  },
  {
    nome: 'Crespiatica',
    codice: '098025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D159',
    cap: ['26835'],
    popolazione: 2137,
  },
  {
    nome: 'Crespina Lorenzana',
    codice: '050041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'M328',
    cap: ['56042'],
    popolazione: 5325,
  },
  {
    nome: 'Crespino',
    codice: '029019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D161',
    cap: ['45030'],
    popolazione: 1970,
  },
  {
    nome: 'Cressa',
    codice: '003055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D162',
    cap: ['28012'],
    popolazione: 1571,
  },
  {
    nome: 'Crevacuore',
    codice: '096021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'D165',
    cap: ['13864'],
    popolazione: 1610,
  },
  {
    nome: 'Crevalcore',
    codice: '037024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'D166',
    cap: ['40014'],
    popolazione: 13527,
  },
  {
    nome: 'Crevoladossola',
    codice: '103025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D168',
    cap: ['28865'],
    popolazione: 4726,
  },
  {
    nome: 'Crispano',
    codice: '063030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'D170',
    cap: ['80020'],
    popolazione: 12411,
  },
  {
    nome: 'Crispiano',
    codice: '073004',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'D171',
    cap: ['74012'],
    popolazione: 13568,
  },
  {
    nome: 'Crissolo',
    codice: '004077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D172',
    cap: ['12030'],
    popolazione: 172,
  },
  {
    nome: 'Crocefieschi',
    codice: '010020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'D175',
    cap: ['16010'],
    popolazione: 561,
  },
  {
    nome: 'Crocetta del Montello',
    codice: '026025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'C670',
    cap: ['31035'],
    popolazione: 6029,
  },
  {
    nome: 'Crodo',
    codice: '103026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D177',
    cap: ['28862'],
    popolazione: 1472,
  },
  {
    nome: 'Crognaleto',
    codice: '067023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'D179',
    cap: ['64043'],
    popolazione: 1416,
  },
  {
    nome: 'Cropalati',
    codice: '078046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D180',
    cap: ['87060'],
    popolazione: 1097,
  },
  {
    nome: 'Cropani',
    codice: '079036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D181',
    cap: ['88051'],
    popolazione: 4306,
  },
  {
    nome: 'Crosia',
    codice: '078047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D184',
    cap: ['87060'],
    popolazione: 9481,
  },
  {
    nome: 'Crosio della Valle',
    codice: '012057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D185',
    cap: ['21020'],
    popolazione: 611,
  },
  {
    nome: 'Crotone',
    codice: '101010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'D122',
    cap: ['88900'],
    popolazione: 58881,
  },
  {
    nome: "Crotta d'Adda",
    codice: '019038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D186',
    cap: ['26020'],
    popolazione: 654,
  },
  {
    nome: 'Crova',
    codice: '002052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D187',
    cap: ['13040'],
    popolazione: 410,
  },
  {
    nome: 'Croviana',
    codice: '022068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D188',
    cap: ['38027'],
    popolazione: 693,
  },
  {
    nome: 'Crucoli',
    codice: '101011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'D189',
    cap: ['88812'],
    popolazione: 3243,
  },
  {
    nome: 'Cuasso al Monte',
    codice: '012058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D192',
    cap: ['21050'],
    popolazione: 3604,
  },
  {
    nome: 'Cuccaro Vetere',
    codice: '065049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D195',
    cap: ['84050'],
    popolazione: 580,
  },
  {
    nome: 'Cucciago',
    codice: '013084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D196',
    cap: ['22060'],
    popolazione: 3450,
  },
  {
    nome: 'Cuceglio',
    codice: '001096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D197',
    cap: ['10090'],
    popolazione: 997,
  },
  {
    nome: 'Cuggiono',
    codice: '015096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D198',
    cap: ['20012'],
    popolazione: 8142,
  },
  {
    nome: 'Cugliate-Fabiasco',
    codice: '012059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D199',
    cap: ['21030'],
    popolazione: 3074,
  },
  {
    nome: 'Cuglieri',
    codice: '095019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'D200',
    cap: ['09073'],
    popolazione: 2811,
  },
  {
    nome: 'Cugnoli',
    codice: '068017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'D201',
    cap: ['65020'],
    popolazione: 1590,
  },
  {
    nome: 'Cumiana',
    codice: '001097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D202',
    cap: ['10040'],
    popolazione: 7825,
  },
  {
    nome: 'Cumignano sul Naviglio',
    codice: '019039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D203',
    cap: ['26020'],
    popolazione: 447,
  },
  {
    nome: 'Cunardo',
    codice: '012060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D204',
    cap: ['21035'],
    popolazione: 2887,
  },
  {
    nome: 'Cuneo',
    codice: '004078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D205',
    cap: ['12100'],
    popolazione: 55013,
  },
  {
    nome: 'Cunico',
    codice: '005051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D207',
    cap: ['14026'],
    popolazione: 528,
  },
  {
    nome: 'Cuorgnè',
    codice: '001098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D208',
    cap: ['10082'],
    popolazione: 10084,
  },
  {
    nome: 'Cupello',
    codice: '069028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D209',
    cap: ['66051'],
    popolazione: 4848,
  },
  {
    nome: 'Cupra Marittima',
    codice: '044017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'D210',
    cap: ['63064'],
    popolazione: 5378,
  },
  {
    nome: 'Cupramontana',
    codice: '042016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'D211',
    cap: ['60034'],
    popolazione: 4838,
  },
  {
    nome: 'Cura Carpignano',
    codice: '018060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B824',
    cap: ['27010'],
    popolazione: 4371,
  },
  {
    nome: 'Curcuris',
    codice: '095077',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'D214',
    cap: ['09090'],
    popolazione: 314,
  },
  {
    nome: 'Cureggio',
    codice: '003058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D216',
    cap: ['28060'],
    popolazione: 2604,
  },
  {
    nome: 'Curiglia con Monteviasco',
    codice: '012061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D217',
    cap: ['21010'],
    popolazione: 190,
  },
  {
    nome: 'Curinga',
    codice: '079039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D218',
    cap: ['88022'],
    popolazione: 6708,
  },
  {
    nome: 'Curino',
    codice: '096023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'D219',
    cap: ['13865'],
    popolazione: 453,
  },
  {
    nome: 'Curno',
    codice: '016089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D221',
    cap: ['24035'],
    popolazione: 7635,
  },
  {
    nome: 'Curon Venosta',
    codice: '021027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D222',
    cap: ['39027'],
    popolazione: 2423,
  },
  {
    nome: 'Cursi',
    codice: '075025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D223',
    cap: ['73020'],
    popolazione: 4251,
  },
  {
    nome: 'Curtarolo',
    codice: '028036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D226',
    cap: ['35010'],
    popolazione: 7175,
  },
  {
    nome: 'Curtatone',
    codice: '020021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'D227',
    cap: ['46010'],
    popolazione: 14570,
  },
  {
    nome: 'Curti',
    codice: '061032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D228',
    cap: ['81040'],
    popolazione: 7110,
  },
  {
    nome: 'Cusago',
    codice: '015097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D229',
    cap: ['20090'],
    popolazione: 3597,
  },
  {
    nome: 'Cusano Milanino',
    codice: '015098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D231',
    cap: ['20095'],
    popolazione: 18905,
  },
  {
    nome: 'Cusano Mutri',
    codice: '062026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D230',
    cap: ['82033'],
    popolazione: 4186,
  },
  {
    nome: 'Cusino',
    codice: '013085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D232',
    cap: ['22010'],
    popolazione: 238,
  },
  {
    nome: 'Cusio',
    codice: '016090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D233',
    cap: ['24010'],
    popolazione: 252,
  },
  {
    nome: 'Custonaci',
    codice: '081007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'D234',
    cap: ['91015'],
    popolazione: 5392,
  },
  {
    nome: 'Cutro',
    codice: '101012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'D236',
    cap: ['88842'],
    popolazione: 10065,
  },
  {
    nome: 'Cutrofiano',
    codice: '075026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D237',
    cap: ['73020'],
    popolazione: 9182,
  },
  {
    nome: 'Cuveglio',
    codice: '012062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D238',
    cap: ['21030'],
    popolazione: 3397,
  },
  {
    nome: 'Cuvio',
    codice: '012063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D239',
    cap: ['21030'],
    popolazione: 1698,
  },
  {
    nome: 'Dairago',
    codice: '015099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D244',
    cap: ['20020'],
    popolazione: 5862,
  },
  {
    nome: 'Dalmine',
    codice: '016091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D245',
    cap: ['24044'],
    popolazione: 22881,
  },
  {
    nome: 'Dambel',
    codice: '022071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D246',
    cap: ['38010'],
    popolazione: 435,
  },
  {
    nome: 'Danta di Cadore',
    codice: '025017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'D247',
    cap: ['32040'],
    popolazione: 514,
  },
  {
    nome: 'Darfo Boario Terme',
    codice: '017065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D251',
    cap: ['25047'],
    popolazione: 15524,
  },
  {
    nome: 'Dasà',
    codice: '102007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D253',
    cap: ['89832'],
    popolazione: 1272,
  },
  {
    nome: 'Davagna',
    codice: '010021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'D255',
    cap: ['16022'],
    popolazione: 1927,
  },
  {
    nome: 'Daverio',
    codice: '012064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D256',
    cap: ['21020'],
    popolazione: 3075,
  },
  {
    nome: 'Davoli',
    codice: '079042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D257',
    cap: ['88060'],
    popolazione: 5407,
  },
  {
    nome: 'Dazio',
    codice: '014025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D258',
    cap: ['23010'],
    popolazione: 424,
  },
  {
    nome: 'Decimomannu',
    codice: '092015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'D259',
    cap: ['09033'],
    popolazione: 7831,
  },
  {
    nome: 'Decimoputzu',
    codice: '111013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D260',
    cap: ['09010'],
    popolazione: 4315,
  },
  {
    nome: 'Decollatura',
    codice: '079043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D261',
    cap: ['88041'],
    popolazione: 3252,
  },
  {
    nome: 'Dego',
    codice: '009027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'D264',
    cap: ['17058'],
    popolazione: 2003,
  },
  {
    nome: 'Deiva Marina',
    codice: '011012',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'D265',
    cap: ['19013'],
    popolazione: 1438,
  },
  {
    nome: 'Delebio',
    codice: '014026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D266',
    cap: ['23014'],
    popolazione: 3173,
  },
  {
    nome: 'Delia',
    codice: '085006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'D267',
    cap: ['93010'],
    popolazione: 4325,
  },
  {
    nome: 'Delianuova',
    codice: '080031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D268',
    cap: ['89012'],
    popolazione: 3436,
  },
  {
    nome: 'Deliceto',
    codice: '071022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'D269',
    cap: ['71026'],
    popolazione: 3919,
  },
  {
    nome: 'Dello',
    codice: '017066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D270',
    cap: ['25020'],
    popolazione: 5576,
  },
  {
    nome: 'Demonte',
    codice: '004079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D271',
    cap: ['12014'],
    popolazione: 2059,
  },
  {
    nome: 'Denice',
    codice: '006065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D272',
    cap: ['15010'],
    popolazione: 190,
  },
  {
    nome: 'Denno',
    codice: '022074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D273',
    cap: ['38010'],
    popolazione: 1252,
  },
  {
    nome: 'Dernice',
    codice: '006066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D277',
    cap: ['15056'],
    popolazione: 210,
  },
  {
    nome: 'Derovere',
    codice: '019040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D278',
    cap: ['26040'],
    popolazione: 310,
  },
  {
    nome: 'Deruta',
    codice: '054017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'D279',
    cap: ['06053'],
    popolazione: 9456,
  },
  {
    nome: 'Dervio',
    codice: '097030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D280',
    cap: ['23824'],
    popolazione: 2681,
  },
  {
    nome: 'Desana',
    codice: '002054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D281',
    cap: ['13034'],
    popolazione: 1055,
  },
  {
    nome: 'Desenzano del Garda',
    codice: '017067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D284',
    cap: ['25015'],
    popolazione: 26793,
  },
  {
    nome: 'Desio',
    codice: '108023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'D286',
    cap: ['20832'],
    popolazione: 40397,
  },
  {
    nome: 'Desulo',
    codice: '091016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D287',
    cap: ['08032'],
    popolazione: 2465,
  },
  {
    nome: 'Diamante',
    codice: '078048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D289',
    cap: ['87023'],
    popolazione: 5055,
  },
  {
    nome: 'Diano Arentino',
    codice: '008025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D293',
    cap: ['18013'],
    popolazione: 678,
  },
  {
    nome: 'Diano Castello',
    codice: '008026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D296',
    cap: ['18013'],
    popolazione: 2257,
  },
  {
    nome: "Diano d'Alba",
    codice: '004080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D291',
    cap: ['12055'],
    popolazione: 3451,
  },
  {
    nome: 'Diano Marina',
    codice: '008027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D297',
    cap: ['18013'],
    popolazione: 6004,
  },
  {
    nome: 'Diano San Pietro',
    codice: '008028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D298',
    cap: ['18013'],
    popolazione: 1101,
  },
  {
    nome: 'Dicomano',
    codice: '048013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D299',
    cap: ['50062'],
    popolazione: 5670,
  },
  {
    nome: 'Dignano',
    codice: '030032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D300',
    cap: ['33030'],
    popolazione: 2389,
  },
  {
    nome: 'Dimaro Folgarida',
    codice: '022233',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M366',
    cap: ['38025'],
    popolazione: 2132,
  },
  {
    nome: 'Dinami',
    codice: '102008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D303',
    cap: ['89833'],
    popolazione: 2433,
  },
  {
    nome: 'Dipignano',
    codice: '078049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D304',
    cap: ['87045'],
    popolazione: 4440,
  },
  {
    nome: 'Diso',
    codice: '075027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D305',
    cap: ['73030'],
    popolazione: 3073,
  },
  {
    nome: 'Divignano',
    codice: '003060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D309',
    cap: ['28010'],
    popolazione: 1445,
  },
  {
    nome: 'Dizzasco',
    codice: '013087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D310',
    cap: ['22020'],
    popolazione: 565,
  },
  {
    nome: 'Dobbiaco',
    codice: '021028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D311',
    cap: ['39034'],
    popolazione: 3314,
  },
  {
    nome: 'Doberdò del Lago',
    codice: '031003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'D312',
    cap: ['34070'],
    popolazione: 1441,
  },
  {
    nome: 'Dogliani',
    codice: '004081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D314',
    cap: ['12063'],
    popolazione: 4805,
  },
  {
    nome: 'Dogliola',
    codice: '069029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D315',
    cap: ['66050'],
    popolazione: 389,
  },
  {
    nome: 'Dogna',
    codice: '030033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D316',
    cap: ['33010'],
    popolazione: 192,
  },
  {
    nome: 'Dolcè',
    codice: '023031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D317',
    cap: ['37020'],
    popolazione: 2573,
  },
  {
    nome: 'Dolceacqua',
    codice: '008029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D318',
    cap: ['18035'],
    popolazione: 1990,
  },
  {
    nome: 'Dolcedo',
    codice: '008030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'D319',
    cap: ['18020'],
    popolazione: 1451,
  },
  {
    nome: 'Dolegna del Collio',
    codice: '031004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'D321',
    cap: ['34070'],
    popolazione: 390,
  },
  {
    nome: 'Dolianova',
    codice: '111014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D323',
    cap: ['09041'],
    popolazione: 9404,
  },
  {
    nome: 'Dolo',
    codice: '027012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'D325',
    cap: ['30031'],
    popolazione: 14982,
  },
  {
    nome: 'Dolzago',
    codice: '097031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D327',
    cap: ['23843'],
    popolazione: 2307,
  },
  {
    nome: 'Domanico',
    codice: '078050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D328',
    cap: ['87030'],
    popolazione: 943,
  },
  {
    nome: 'Domaso',
    codice: '013089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D329',
    cap: ['22013'],
    popolazione: 1455,
  },
  {
    nome: 'Domegge di Cadore',
    codice: '025018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'D330',
    cap: ['32040'],
    popolazione: 2575,
  },
  {
    nome: 'Domicella',
    codice: '064031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'D331',
    cap: ['83020'],
    popolazione: 1873,
  },
  {
    nome: 'Domodossola',
    codice: '103028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D332',
    cap: ['28845'],
    popolazione: 18175,
  },
  {
    nome: 'Domus de Maria',
    codice: '111015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D333',
    cap: ['09010'],
    popolazione: 1675,
  },
  {
    nome: 'Domusnovas',
    codice: '111016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D334',
    cap: ['09015'],
    popolazione: 6416,
  },
  {
    nome: 'Donato',
    codice: '096024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'D339',
    cap: ['13893'],
    popolazione: 719,
  },
  {
    nome: 'Dongo',
    codice: '013090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D341',
    cap: ['22014'],
    popolazione: 3489,
  },
  {
    nome: 'Donnas',
    codice: '007023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D338',
    cap: ['11020'],
    popolazione: 2624,
  },
  {
    nome: 'Donori',
    codice: '111017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D344',
    cap: ['09040'],
    popolazione: 2119,
  },
  {
    nome: 'Dorgali',
    codice: '091017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D345',
    cap: ['08022'],
    popolazione: 8524,
  },
  {
    nome: 'Dorio',
    codice: '097032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D346',
    cap: ['23824'],
    popolazione: 337,
  },
  {
    nome: 'Dormelletto',
    codice: '003062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D347',
    cap: ['28040'],
    popolazione: 2643,
  },
  {
    nome: 'Dorno',
    codice: '018061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D348',
    cap: ['27020'],
    popolazione: 4584,
  },
  {
    nome: 'Dorzano',
    codice: '096025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'D350',
    cap: ['13881'],
    popolazione: 508,
  },
  {
    nome: 'Dosolo',
    codice: '020022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'D351',
    cap: ['46030'],
    popolazione: 3385,
  },
  {
    nome: 'Dossena',
    codice: '016092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D352',
    cap: ['24010'],
    popolazione: 962,
  },
  {
    nome: 'Dosso del Liro',
    codice: '013092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D355',
    cap: ['22010'],
    popolazione: 275,
  },
  {
    nome: 'Doues',
    codice: '007024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D356',
    cap: ['11010'],
    popolazione: 477,
  },
  {
    nome: 'Dovadola',
    codice: '040011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'D357',
    cap: ['47013'],
    popolazione: 1661,
  },
  {
    nome: 'Dovera',
    codice: '019041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D358',
    cap: ['26010'],
    popolazione: 3865,
  },
  {
    nome: 'Dozza',
    codice: '037025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'D360',
    cap: ['40060'],
    popolazione: 6440,
  },
  {
    nome: 'Dragoni',
    codice: '061033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D361',
    cap: ['81010'],
    popolazione: 2167,
  },
  {
    nome: 'Drapia',
    codice: '102009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D364',
    cap: ['89862'],
    popolazione: 2102,
  },
  {
    nome: 'Drena',
    codice: '022078',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D365',
    cap: ['38074'],
    popolazione: 551,
  },
  {
    nome: 'Drenchia',
    codice: '030034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D366',
    cap: ['33040'],
    popolazione: 134,
  },
  {
    nome: 'Dresano',
    codice: '015101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D367',
    cap: ['20070'],
    popolazione: 3023,
  },
  {
    nome: 'Dro',
    codice: '022079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D371',
    cap: ['38074'],
    popolazione: 4566,
  },
  {
    nome: 'Dronero',
    codice: '004082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D372',
    cap: ['12025'],
    popolazione: 7205,
  },
  {
    nome: 'Druento',
    codice: '001099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D373',
    cap: ['10040'],
    popolazione: 8436,
  },
  {
    nome: 'Druogno',
    codice: '103029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D374',
    cap: ['28853'],
    popolazione: 977,
  },
  {
    nome: 'Dualchi',
    codice: '091018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D376',
    cap: ['08010'],
    popolazione: 668,
  },
  {
    nome: 'Dubino',
    codice: '014027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D377',
    cap: ['23015'],
    popolazione: 3551,
  },
  {
    nome: 'Due Carrare',
    codice: '028106',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'M300',
    cap: ['35020'],
    popolazione: 8943,
  },
  {
    nome: 'Dueville',
    codice: '024038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D379',
    cap: ['36031'],
    popolazione: 13888,
  },
  {
    nome: 'Dugenta',
    codice: '062027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D380',
    cap: ['82030'],
    popolazione: 2752,
  },
  {
    nome: 'Duino Aurisina',
    codice: '032001',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '032', nome: 'Trieste' },
    sigla: 'TS',
    codiceCatastale: 'D383',
    cap: ['34011'],
    popolazione: 8586,
  },
  {
    nome: 'Dumenza',
    codice: '012065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D384',
    cap: ['21010'],
    popolazione: 1433,
  },
  {
    nome: 'Duno',
    codice: '012066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D385',
    cap: ['21030'],
    popolazione: 159,
  },
  {
    nome: 'Durazzano',
    codice: '062028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D386',
    cap: ['82015'],
    popolazione: 2247,
  },
  {
    nome: 'Duronia',
    codice: '070022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'C772',
    cap: ['86020'],
    popolazione: 431,
  },
  {
    nome: 'Dusino San Michele',
    codice: '005052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D388',
    cap: ['14010'],
    popolazione: 1044,
  },
  {
    nome: 'Eboli',
    codice: '065050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D390',
    cap: ['84025'],
    popolazione: 38219,
  },
  {
    nome: 'Edolo',
    codice: '017068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D391',
    cap: ['25048'],
    popolazione: 4509,
  },
  {
    nome: 'Egna',
    codice: '021029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D392',
    cap: ['39044'],
    popolazione: 4992,
  },
  {
    nome: 'Elice',
    codice: '068018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'D394',
    cap: ['65010'],
    popolazione: 1729,
  },
  {
    nome: 'Elini',
    codice: '091019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D395',
    cap: ['08040'],
    popolazione: 550,
  },
  {
    nome: 'Ello',
    codice: '097033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D398',
    cap: ['23848'],
    popolazione: 1242,
  },
  {
    nome: 'Elmas',
    codice: '092108',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'D399',
    cap: ['09067'],
    popolazione: 8949,
  },
  {
    nome: 'Elva',
    codice: '004083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D401',
    cap: ['12020'],
    popolazione: 94,
  },
  {
    nome: 'Emarèse',
    codice: '007025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D402',
    cap: ['11020'],
    popolazione: 228,
  },
  {
    nome: 'Empoli',
    codice: '048014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D403',
    cap: ['50053'],
    popolazione: 46541,
  },
  {
    nome: 'Endine Gaiano',
    codice: '016093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D406',
    cap: ['24060'],
    popolazione: 3519,
  },
  {
    nome: 'Enego',
    codice: '024039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D407',
    cap: ['36052'],
    popolazione: 1825,
  },
  {
    nome: 'Enemonzo',
    codice: '030035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D408',
    cap: ['33020'],
    popolazione: 1351,
  },
  {
    nome: 'Enna',
    codice: '086009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'C342',
    cap: ['94100'],
    popolazione: 27894,
  },
  {
    nome: 'Entracque',
    codice: '004084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D410',
    cap: ['12010'],
    popolazione: 807,
  },
  {
    nome: 'Entratico',
    codice: '016094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D411',
    cap: ['24060'],
    popolazione: 1878,
  },
  {
    nome: 'Envie',
    codice: '004085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D412',
    cap: ['12030'],
    popolazione: 2057,
  },
  {
    nome: 'Episcopia',
    codice: '076030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D414',
    cap: ['85033'],
    popolazione: 1467,
  },
  {
    nome: 'Eraclea',
    codice: '027013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'D415',
    cap: ['30020'],
    popolazione: 12689,
  },
  {
    nome: 'Erba',
    codice: '013095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D416',
    cap: ['22036'],
    popolazione: 16503,
  },
  {
    nome: 'Erbè',
    codice: '023032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D419',
    cap: ['37060'],
    popolazione: 1841,
  },
  {
    nome: 'Erbezzo',
    codice: '023033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D420',
    cap: ['37020'],
    popolazione: 767,
  },
  {
    nome: 'Erbusco',
    codice: '017069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D421',
    cap: ['25030'],
    popolazione: 8286,
  },
  {
    nome: 'Erchie',
    codice: '074006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'D422',
    cap: ['72020'],
    popolazione: 8772,
  },
  {
    nome: 'Ercolano',
    codice: '063064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H243',
    cap: ['80056'],
    popolazione: 53677,
  },
  {
    nome: 'Erice',
    codice: '081008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'D423',
    cap: ['91016'],
    popolazione: 28012,
  },
  {
    nome: 'Erli',
    codice: '009028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'D424',
    cap: ['17030'],
    popolazione: 256,
  },
  {
    nome: 'Erto e Casso',
    codice: '093019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'D426',
    cap: ['33080'],
    popolazione: 387,
  },
  {
    nome: 'Erula',
    codice: '090088',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M292',
    cap: ['07030'],
    popolazione: 766,
  },
  {
    nome: 'Erve',
    codice: '097034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D428',
    cap: ['23805'],
    popolazione: 764,
  },
  {
    nome: 'Esanatoglia',
    codice: '043016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'D429',
    cap: ['62024'],
    popolazione: 2147,
  },
  {
    nome: 'Escalaplano',
    codice: '111018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D430',
    cap: ['09051'],
    popolazione: 2268,
  },
  {
    nome: 'Escolca',
    codice: '111019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D431',
    cap: ['09052'],
    popolazione: 624,
  },
  {
    nome: 'Esine',
    codice: '017070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D434',
    cap: ['25040'],
    popolazione: 5351,
  },
  {
    nome: 'Esino Lario',
    codice: '097035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D436',
    cap: ['23825'],
    popolazione: 750,
  },
  {
    nome: 'Esperia',
    codice: '060031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D440',
    cap: ['03045'],
    popolazione: 3903,
  },
  {
    nome: 'Esporlatu',
    codice: '090028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'D441',
    cap: ['07010'],
    popolazione: 412,
  },
  {
    nome: 'Este',
    codice: '028037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D442',
    cap: ['35042'],
    popolazione: 16576,
  },
  {
    nome: 'Esterzili',
    codice: '111020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D443',
    cap: ['09053'],
    popolazione: 721,
  },
  {
    nome: 'Etroubles',
    codice: '007026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D444',
    cap: ['11014'],
    popolazione: 496,
  },
  {
    nome: 'Eupilio',
    codice: '013097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D445',
    cap: ['22030'],
    popolazione: 2769,
  },
  {
    nome: 'Exilles',
    codice: '001100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D433',
    cap: ['10050'],
    popolazione: 266,
  },
  {
    nome: 'Fabbrica Curone',
    codice: '006067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D447',
    cap: ['15054'],
    popolazione: 695,
  },
  {
    nome: 'Fabbriche di Vergemoli',
    codice: '046036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'M319',
    cap: ['55021'],
    popolazione: 820,
  },
  {
    nome: 'Fabbrico',
    codice: '035021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'D450',
    cap: ['42042'],
    popolazione: 6696,
  },
  {
    nome: 'Fabriano',
    codice: '042017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'D451',
    cap: ['60044'],
    popolazione: 31020,
  },
  {
    nome: 'Fabrica di Roma',
    codice: '056024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'D452',
    cap: ['01034'],
    popolazione: 8136,
  },
  {
    nome: 'Fabrizia',
    codice: '102010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D453',
    cap: ['89823'],
    popolazione: 2373,
  },
  {
    nome: 'Fabro',
    codice: '055011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'D454',
    cap: ['05015'],
    popolazione: 2906,
  },
  {
    nome: 'Faedis',
    codice: '030036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D455',
    cap: ['33040'],
    popolazione: 3014,
  },
  {
    nome: 'Faedo Valtellino',
    codice: '014028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D456',
    cap: ['23020'],
    popolazione: 545,
  },
  {
    nome: 'Faenza',
    codice: '039010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'D458',
    cap: ['48018'],
    popolazione: 57748,
  },
  {
    nome: 'Faeto',
    codice: '071023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'D459',
    cap: ['71020'],
    popolazione: 644,
  },
  {
    nome: 'Fagagna',
    codice: '030037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D461',
    cap: ['33034'],
    popolazione: 6279,
  },
  {
    nome: 'Faggeto Lario',
    codice: '013098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D462',
    cap: ['22020'],
    popolazione: 1235,
  },
  {
    nome: 'Faggiano',
    codice: '073005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'D463',
    cap: ['74020'],
    popolazione: 3540,
  },
  {
    nome: 'Fagnano Alto',
    codice: '066042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'D465',
    cap: ['67020'],
    popolazione: 440,
  },
  {
    nome: 'Fagnano Castello',
    codice: '078051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D464',
    cap: ['87013'],
    popolazione: 3949,
  },
  {
    nome: 'Fagnano Olona',
    codice: '012067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D467',
    cap: ['21054'],
    popolazione: 12141,
  },
  {
    nome: 'Fai della Paganella',
    codice: '022081',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D468',
    cap: ['38010'],
    popolazione: 898,
  },
  {
    nome: 'Faicchio',
    codice: '062029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D469',
    cap: ['82030'],
    popolazione: 3698,
  },
  {
    nome: 'Falcade',
    codice: '025019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'D470',
    cap: ['32020'],
    popolazione: 2055,
  },
  {
    nome: 'Falciano del Massico',
    codice: '061101',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D471',
    cap: ['81030'],
    popolazione: 3673,
  },
  {
    nome: 'Falconara Albanese',
    codice: '078052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D473',
    cap: ['87030'],
    popolazione: 1405,
  },
  {
    nome: 'Falconara Marittima',
    codice: '042018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'D472',
    cap: ['60015'],
    popolazione: 26710,
  },
  {
    nome: 'Falcone',
    codice: '083019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D474',
    cap: ['98060'],
    popolazione: 2874,
  },
  {
    nome: 'Faleria',
    codice: '056025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'D475',
    cap: ['01030'],
    popolazione: 2115,
  },
  {
    nome: 'Falerna',
    codice: '079047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D476',
    cap: ['88042'],
    popolazione: 3801,
  },
  {
    nome: 'Falerone',
    codice: '109005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'D477',
    cap: ['63837'],
    popolazione: 3395,
  },
  {
    nome: 'Fallo',
    codice: '069104',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D480',
    cap: ['66040'],
    popolazione: 146,
  },
  {
    nome: 'Faloppio',
    codice: '013099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D482',
    cap: ['22020'],
    popolazione: 4274,
  },
  {
    nome: 'Falvaterra',
    codice: '060032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D483',
    cap: ['03020'],
    popolazione: 567,
  },
  {
    nome: 'Falzes',
    codice: '021030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D484',
    cap: ['39030'],
    popolazione: 2668,
  },
  {
    nome: 'Fanano',
    codice: '036011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'D486',
    cap: ['41021'],
    popolazione: 3028,
  },
  {
    nome: 'Fanna',
    codice: '093020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'D487',
    cap: ['33092'],
    popolazione: 1556,
  },
  {
    nome: 'Fano',
    codice: '041013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D488',
    cap: ['61032'],
    popolazione: 62901,
  },
  {
    nome: 'Fano Adriano',
    codice: '067024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'D489',
    cap: ['64044'],
    popolazione: 354,
  },
  {
    nome: 'Fara Filiorum Petri',
    codice: '069030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D494',
    cap: ['66010'],
    popolazione: 1955,
  },
  {
    nome: "Fara Gera d'Adda",
    codice: '016096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D490',
    cap: ['24045'],
    popolazione: 7913,
  },
  {
    nome: 'Fara in Sabina',
    codice: '057027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'D493',
    cap: ['02032'],
    popolazione: 12326,
  },
  {
    nome: 'Fara Novarese',
    codice: '003065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D492',
    cap: ['28073'],
    popolazione: 2113,
  },
  {
    nome: 'Fara Olivana con Sola',
    codice: '016097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D491',
    cap: ['24058'],
    popolazione: 1268,
  },
  {
    nome: 'Fara San Martino',
    codice: '069031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D495',
    cap: ['66015'],
    popolazione: 1524,
  },
  {
    nome: 'Fara Vicentino',
    codice: '024040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D496',
    cap: ['36030'],
    popolazione: 3943,
  },
  {
    nome: 'Fardella',
    codice: '076031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D497',
    cap: ['85034'],
    popolazione: 625,
  },
  {
    nome: 'Farigliano',
    codice: '004086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D499',
    cap: ['12060'],
    popolazione: 1747,
  },
  {
    nome: 'Farindola',
    codice: '068019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'D501',
    cap: ['65010'],
    popolazione: 1601,
  },
  {
    nome: 'Farini',
    codice: '033019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'D502',
    cap: ['29023'],
    popolazione: 1455,
  },
  {
    nome: 'Farnese',
    codice: '056026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'D503',
    cap: ['01010'],
    popolazione: 1631,
  },
  {
    nome: "Farra d'Isonzo",
    codice: '031005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'D504',
    cap: ['34072'],
    popolazione: 1752,
  },
  {
    nome: 'Farra di Soligo',
    codice: '026026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D505',
    cap: ['31010'],
    popolazione: 8956,
  },
  {
    nome: 'Fasano',
    codice: '074007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'D508',
    cap: ['72015'],
    popolazione: 39482,
  },
  {
    nome: 'Fascia',
    codice: '010022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'D509',
    cap: ['16020'],
    popolazione: 100,
  },
  {
    nome: 'Fauglia',
    codice: '050014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'D510',
    cap: ['56043'],
    popolazione: 3592,
  },
  {
    nome: 'Faule',
    codice: '004087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D511',
    cap: ['12030'],
    popolazione: 496,
  },
  {
    nome: 'Favale di Malvaro',
    codice: '010023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'D512',
    cap: ['16040'],
    popolazione: 504,
  },
  {
    nome: 'Favara',
    codice: '084017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'D514',
    cap: ['92026'],
    popolazione: 32972,
  },
  {
    nome: 'Favignana',
    codice: '081009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'D518',
    cap: ['91023'],
    popolazione: 4185,
  },
  {
    nome: 'Favria',
    codice: '001101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D520',
    cap: ['10083'],
    popolazione: 5230,
  },
  {
    nome: 'Feisoglio',
    codice: '004088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D523',
    cap: ['12050'],
    popolazione: 344,
  },
  {
    nome: 'Feletto',
    codice: '001102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D524',
    cap: ['10080'],
    popolazione: 2269,
  },
  {
    nome: 'Felino',
    codice: '034013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'D526',
    cap: ['43035'],
    popolazione: 8621,
  },
  {
    nome: 'Felitto',
    codice: '065051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D527',
    cap: ['84055'],
    popolazione: 1296,
  },
  {
    nome: 'Felizzano',
    codice: '006068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D528',
    cap: ['15023'],
    popolazione: 2421,
  },
  {
    nome: 'Feltre',
    codice: '025021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'D530',
    cap: ['32032'],
    popolazione: 20525,
  },
  {
    nome: 'Fenegrò',
    codice: '013100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D531',
    cap: ['22070'],
    popolazione: 3147,
  },
  {
    nome: 'Fenestrelle',
    codice: '001103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D532',
    cap: ['10060'],
    popolazione: 553,
  },
  {
    nome: 'Fénis',
    codice: '007027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D537',
    cap: ['11020'],
    popolazione: 1766,
  },
  {
    nome: 'Ferentillo',
    codice: '055012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'D538',
    cap: ['05034'],
    popolazione: 1963,
  },
  {
    nome: 'Ferentino',
    codice: '060033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D539',
    cap: ['03013'],
    popolazione: 20966,
  },
  {
    nome: 'Ferla',
    codice: '089008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'D540',
    cap: ['96010'],
    popolazione: 2600,
  },
  {
    nome: 'Fermignano',
    codice: '041014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D541',
    cap: ['61033'],
    popolazione: 8615,
  },
  {
    nome: 'Fermo',
    codice: '109006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'D542',
    cap: ['63900'],
    popolazione: 37016,
  },
  {
    nome: 'Ferno',
    codice: '012068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D543',
    cap: ['21010'],
    popolazione: 6786,
  },
  {
    nome: 'Feroleto Antico',
    codice: '079048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D544',
    cap: ['88040'],
    popolazione: 2087,
  },
  {
    nome: 'Feroleto della Chiesa',
    codice: '080032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D545',
    cap: ['89050'],
    popolazione: 1772,
  },
  {
    nome: 'Ferrandina',
    codice: '077008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'D547',
    cap: ['75013'],
    popolazione: 8973,
  },
  {
    nome: 'Ferrara',
    codice: '038008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'D548',
    cap: ['44121', '44122', '44123', '44124'],
    popolazione: 132545,
  },
  {
    nome: 'Ferrara di Monte Baldo',
    codice: '023034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D549',
    cap: ['37020'],
    popolazione: 221,
  },
  {
    nome: 'Ferrazzano',
    codice: '070023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'D550',
    cap: ['86010'],
    popolazione: 3287,
  },
  {
    nome: 'Ferrera di Varese',
    codice: '012069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D551',
    cap: ['21030'],
    popolazione: 693,
  },
  {
    nome: 'Ferrera Erbognone',
    codice: '018062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D552',
    cap: ['27032'],
    popolazione: 1121,
  },
  {
    nome: 'Ferrere',
    codice: '005053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D554',
    cap: ['14012'],
    popolazione: 1602,
  },
  {
    nome: 'Ferriere',
    codice: '033020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'D555',
    cap: ['29024'],
    popolazione: 1425,
  },
  {
    nome: 'Ferruzzano',
    codice: '080033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D557',
    cap: ['89030'],
    popolazione: 745,
  },
  {
    nome: 'Fiamignano',
    codice: '057028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'D560',
    cap: ['02023'],
    popolazione: 1455,
  },
  {
    nome: 'Fiano',
    codice: '001104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D562',
    cap: ['10070'],
    popolazione: 2713,
  },
  {
    nome: 'Fiano Romano',
    codice: '058036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D561',
    cap: ['00065'],
    popolazione: 13059,
  },
  {
    nome: 'Fiastra',
    codice: '043017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'D564',
    cap: ['62035'],
    popolazione: 700,
  },
  {
    nome: 'Fiavè',
    codice: '022083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D565',
    cap: ['38075'],
    popolazione: 1098,
  },
  {
    nome: 'Ficarazzi',
    codice: '082035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'D567',
    cap: ['90010'],
    popolazione: 11484,
  },
  {
    nome: 'Ficarolo',
    codice: '029021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D568',
    cap: ['45036'],
    popolazione: 2609,
  },
  {
    nome: 'Ficarra',
    codice: '083020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D569',
    cap: ['98062'],
    popolazione: 1566,
  },
  {
    nome: 'Ficulle',
    codice: '055013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'D570',
    cap: ['05016'],
    popolazione: 1695,
  },
  {
    nome: 'Fidenza',
    codice: '034014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'B034',
    cap: ['43036'],
    popolazione: 25521,
  },
  {
    nome: 'Fiè allo Sciliar',
    codice: '021031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D571',
    cap: ['39050'],
    popolazione: 3451,
  },
  {
    nome: 'Fierozzo',
    codice: '022085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D573',
    cap: ['38050'],
    popolazione: 481,
  },
  {
    nome: 'Fiesco',
    codice: '019043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D574',
    cap: ['26010'],
    popolazione: 1200,
  },
  {
    nome: 'Fiesole',
    codice: '048015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D575',
    cap: ['50014'],
    popolazione: 13990,
  },
  {
    nome: 'Fiesse',
    codice: '017071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D576',
    cap: ['25020'],
    popolazione: 2175,
  },
  {
    nome: "Fiesso d'Artico",
    codice: '027014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'D578',
    cap: ['30032'],
    popolazione: 7728,
  },
  {
    nome: 'Fiesso Umbertiano',
    codice: '029022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D577',
    cap: ['45024'],
    popolazione: 4275,
  },
  {
    nome: 'Figino Serenza',
    codice: '013101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D579',
    cap: ['22060'],
    popolazione: 5226,
  },
  {
    nome: 'Figline e Incisa Valdarno',
    codice: '048052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'M321',
    cap: ['50063'],
    popolazione: 23124,
  },
  {
    nome: 'Figline Vegliaturo',
    codice: '078053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D582',
    cap: ['87050'],
    popolazione: 1097,
  },
  {
    nome: 'Filacciano',
    codice: '058037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D586',
    cap: ['00060'],
    popolazione: 490,
  },
  {
    nome: 'Filadelfia',
    codice: '102011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D587',
    cap: ['89814'],
    popolazione: 5638,
  },
  {
    nome: 'Filago',
    codice: '016098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D588',
    cap: ['24040'],
    popolazione: 3176,
  },
  {
    nome: 'Filandari',
    codice: '102012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D589',
    cap: ['89841'],
    popolazione: 1844,
  },
  {
    nome: 'Filattiera',
    codice: '045006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'D590',
    cap: ['54023'],
    popolazione: 2361,
  },
  {
    nome: 'Filettino',
    codice: '060034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D591',
    cap: ['03010'],
    popolazione: 551,
  },
  {
    nome: 'Filetto',
    codice: '069032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D592',
    cap: ['66030'],
    popolazione: 1026,
  },
  {
    nome: 'Filiano',
    codice: '076032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D593',
    cap: ['85020'],
    popolazione: 3089,
  },
  {
    nome: 'Filighera',
    codice: '018063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D594',
    cap: ['27010'],
    popolazione: 849,
  },
  {
    nome: 'Filignano',
    codice: '094019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'D595',
    cap: ['86074'],
    popolazione: 689,
  },
  {
    nome: 'Filogaso',
    codice: '102013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D596',
    cap: ['89843'],
    popolazione: 1439,
  },
  {
    nome: 'Filottrano',
    codice: '042019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'D597',
    cap: ['60024'],
    popolazione: 9622,
  },
  {
    nome: 'Finale Emilia',
    codice: '036012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'D599',
    cap: ['41034'],
    popolazione: 15713,
  },
  {
    nome: 'Finale Ligure',
    codice: '009029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'D600',
    cap: ['17024'],
    popolazione: 11724,
  },
  {
    nome: 'Fino del Monte',
    codice: '016099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D604',
    cap: ['24020'],
    popolazione: 1131,
  },
  {
    nome: 'Fino Mornasco',
    codice: '013102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D605',
    cap: ['22073'],
    popolazione: 9569,
  },
  {
    nome: 'Fiorano al Serio',
    codice: '016100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D606',
    cap: ['24020'],
    popolazione: 3071,
  },
  {
    nome: 'Fiorano Canavese',
    codice: '001105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D608',
    cap: ['10010'],
    popolazione: 837,
  },
  {
    nome: 'Fiorano Modenese',
    codice: '036013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'D607',
    cap: ['41042'],
    popolazione: 16945,
  },
  {
    nome: "Fiorenzuola d'Arda",
    codice: '033021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'D611',
    cap: ['29017'],
    popolazione: 14886,
  },
  {
    nome: 'Firenze',
    codice: '048017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D612',
    cap: [
      '50121',
      '50122',
      '50123',
      '50124',
      '50125',
      '50126',
      '50127',
      '50128',
      '50129',
      '50130',
      '50131',
      '50132',
      '50133',
      '50134',
      '50135',
      '50136',
      '50137',
      '50138',
      '50139',
      '50140',
      '50141',
      '50142',
      '50143',
      '50144',
      '50145',
    ],
    popolazione: 358079,
  },
  {
    nome: 'Firenzuola',
    codice: '048018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D613',
    cap: ['50033'],
    popolazione: 4828,
  },
  {
    nome: 'Firmo',
    codice: '078054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D614',
    cap: ['87010'],
    popolazione: 2184,
  },
  {
    nome: 'Fiscaglia',
    codice: '038027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'M323',
    cap: ['44027'],
    popolazione: 9519,
  },
  {
    nome: 'Fisciano',
    codice: '065052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D615',
    cap: ['84084'],
    popolazione: 13677,
  },
  {
    nome: 'Fiuggi',
    codice: '060035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A310',
    cap: ['03014'],
    popolazione: 9645,
  },
  {
    nome: 'Fiumalbo',
    codice: '036014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'D617',
    cap: ['41022'],
    popolazione: 1304,
  },
  {
    nome: 'Fiumara',
    codice: '080034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D619',
    cap: ['89050'],
    popolazione: 1021,
  },
  {
    nome: 'Fiume Veneto',
    codice: '093021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'D621',
    cap: ['33080'],
    popolazione: 11486,
  },
  {
    nome: 'Fiumedinisi',
    codice: '083021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D622',
    cap: ['98022'],
    popolazione: 1559,
  },
  {
    nome: 'Fiumefreddo Bruzio',
    codice: '078055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D624',
    cap: ['87030'],
    popolazione: 3078,
  },
  {
    nome: 'Fiumefreddo di Sicilia',
    codice: '087016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'D623',
    cap: ['95013'],
    popolazione: 9690,
  },
  {
    nome: 'Fiumicello Villa Vicentina',
    codice: '030190',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'M400',
    cap: ['33059'],
    popolazione: 6408,
  },
  {
    nome: 'Fiumicino',
    codice: '058120',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M297',
    cap: ['00054'],
    popolazione: 67626,
  },
  {
    nome: 'Fiuminata',
    codice: '043019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'D628',
    cap: ['62025'],
    popolazione: 1497,
  },
  {
    nome: 'Fivizzano',
    codice: '045007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'D629',
    cap: ['54013'],
    popolazione: 8267,
  },
  {
    nome: 'Flaibano',
    codice: '030039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D630',
    cap: ['33030'],
    popolazione: 1197,
  },
  {
    nome: 'Flero',
    codice: '017072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D634',
    cap: ['25020'],
    popolazione: 8440,
  },
  {
    nome: 'Floresta',
    codice: '083022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D635',
    cap: ['98030'],
    popolazione: 516,
  },
  {
    nome: 'Floridia',
    codice: '089009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'D636',
    cap: ['96014'],
    popolazione: 22685,
  },
  {
    nome: 'Florinas',
    codice: '090029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'D637',
    cap: ['07030'],
    popolazione: 1547,
  },
  {
    nome: 'Flumeri',
    codice: '064032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'D638',
    cap: ['83040'],
    popolazione: 3045,
  },
  {
    nome: 'Fluminimaggiore',
    codice: '111021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D639',
    cap: ['09010'],
    popolazione: 2966,
  },
  {
    nome: 'Flussio',
    codice: '095080',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'D640',
    cap: ['09090'],
    popolazione: 454,
  },
  {
    nome: 'Fobello',
    codice: '002057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D641',
    cap: ['13025'],
    popolazione: 219,
  },
  {
    nome: 'Foggia',
    codice: '071024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'D643',
    cap: ['71121', '71122'],
    popolazione: 147036,
  },
  {
    nome: 'Foglianise',
    codice: '062030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D644',
    cap: ['82030'],
    popolazione: 3509,
  },
  {
    nome: 'Fogliano Redipuglia',
    codice: '031006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'D645',
    cap: ['34070'],
    popolazione: 3052,
  },
  {
    nome: 'Foglizzo',
    codice: '001106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D646',
    cap: ['10090'],
    popolazione: 2331,
  },
  {
    nome: 'Foiano della Chiana',
    codice: '051018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'D649',
    cap: ['52045'],
    popolazione: 9348,
  },
  {
    nome: 'Foiano di Val Fortore',
    codice: '062031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D650',
    cap: ['82020'],
    popolazione: 1477,
  },
  {
    nome: 'Folgaria',
    codice: '022087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D651',
    cap: ['38064'],
    popolazione: 3130,
  },
  {
    nome: 'Folignano',
    codice: '044020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'D652',
    cap: ['63084'],
    popolazione: 9302,
  },
  {
    nome: 'Foligno',
    codice: '054018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'D653',
    cap: ['06034'],
    popolazione: 56045,
  },
  {
    nome: 'Follina',
    codice: '026027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D654',
    cap: ['31051'],
    popolazione: 3939,
  },
  {
    nome: 'Follo',
    codice: '011013',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'D655',
    cap: ['19020'],
    popolazione: 6337,
  },
  {
    nome: 'Follonica',
    codice: '053009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'D656',
    cap: ['58022'],
    popolazione: 21479,
  },
  {
    nome: 'Fombio',
    codice: '098026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D660',
    cap: ['26861'],
    popolazione: 2288,
  },
  {
    nome: 'Fondachelli-Fantina',
    codice: '083023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D661',
    cap: ['98050'],
    popolazione: 1090,
  },
  {
    nome: 'Fondi',
    codice: '059007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'D662',
    cap: ['04022'],
    popolazione: 37180,
  },
  {
    nome: 'Fonni',
    codice: '091024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D665',
    cap: ['08023'],
    popolazione: 4062,
  },
  {
    nome: 'Fontainemore',
    codice: '007028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D666',
    cap: ['11020'],
    popolazione: 449,
  },
  {
    nome: 'Fontana Liri',
    codice: '060036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D667',
    cap: ['03035'],
    popolazione: 2993,
  },
  {
    nome: 'Fontanafredda',
    codice: '093022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'D670',
    cap: ['33074'],
    popolazione: 11537,
  },
  {
    nome: 'Fontanarosa',
    codice: '064033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'D671',
    cap: ['83040'],
    popolazione: 3301,
  },
  {
    nome: 'Fontanelice',
    codice: '037026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'D668',
    cap: ['40025'],
    popolazione: 1927,
  },
  {
    nome: 'Fontanella',
    codice: '016101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D672',
    cap: ['24056'],
    popolazione: 4339,
  },
  {
    nome: 'Fontanellato',
    codice: '034015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'D673',
    cap: ['43012'],
    popolazione: 6963,
  },
  {
    nome: 'Fontanelle',
    codice: '026028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D674',
    cap: ['31043'],
    popolazione: 5804,
  },
  {
    nome: "Fontaneto d'Agogna",
    codice: '003066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D675',
    cap: ['28010'],
    popolazione: 2731,
  },
  {
    nome: 'Fontanetto Po',
    codice: '002058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D676',
    cap: ['13040'],
    popolazione: 1203,
  },
  {
    nome: 'Fontanigorda',
    codice: '010024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'D677',
    cap: ['16023'],
    popolazione: 274,
  },
  {
    nome: 'Fontanile',
    codice: '005054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D678',
    cap: ['14044'],
    popolazione: 566,
  },
  {
    nome: 'Fontaniva',
    codice: '028038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D679',
    cap: ['35014'],
    popolazione: 8168,
  },
  {
    nome: 'Fonte',
    codice: '026029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D680',
    cap: ['31010'],
    popolazione: 6019,
  },
  {
    nome: 'Fonte Nuova',
    codice: '058122',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M309',
    cap: ['00013'],
    popolazione: 30572,
  },
  {
    nome: 'Fontecchio',
    codice: '066043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'D681',
    cap: ['67020'],
    popolazione: 410,
  },
  {
    nome: 'Fontechiari',
    codice: '060037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D682',
    cap: ['03030'],
    popolazione: 1318,
  },
  {
    nome: 'Fontegreca',
    codice: '061034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D683',
    cap: ['81014'],
    popolazione: 849,
  },
  {
    nome: 'Fonteno',
    codice: '016102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D684',
    cap: ['24060'],
    popolazione: 685,
  },
  {
    nome: 'Fontevivo',
    codice: '034016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'D685',
    cap: ['43010'],
    popolazione: 5428,
  },
  {
    nome: 'Fonzaso',
    codice: '025022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'D686',
    cap: ['32030'],
    popolazione: 3322,
  },
  {
    nome: 'Foppolo',
    codice: '016103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D688',
    cap: ['24010'],
    popolazione: 202,
  },
  {
    nome: 'Forano',
    codice: '057029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'D689',
    cap: ['02044'],
    popolazione: 2933,
  },
  {
    nome: 'Force',
    codice: '044021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'D691',
    cap: ['63086'],
    popolazione: 1428,
  },
  {
    nome: 'Forchia',
    codice: '062032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D693',
    cap: ['82011'],
    popolazione: 1238,
  },
  {
    nome: 'Forcola',
    codice: '014029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D694',
    cap: ['23010'],
    popolazione: 835,
  },
  {
    nome: 'Fordongianus',
    codice: '095020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'D695',
    cap: ['09083'],
    popolazione: 939,
  },
  {
    nome: 'Forenza',
    codice: '076033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D696',
    cap: ['85023'],
    popolazione: 2209,
  },
  {
    nome: 'Foresto Sparso',
    codice: '016104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D697',
    cap: ['24060'],
    popolazione: 3153,
  },
  {
    nome: 'Forgaria nel Friuli',
    codice: '030137',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D700',
    cap: ['33030'],
    popolazione: 1826,
  },
  {
    nome: 'Forino',
    codice: '064034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'D701',
    cap: ['83020'],
    popolazione: 5397,
  },
  {
    nome: 'Forio',
    codice: '063031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'D702',
    cap: ['80075'],
    popolazione: 16597,
  },
  {
    nome: 'Forlì',
    codice: '040012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'D704',
    cap: ['47121', '47122'],
    popolazione: 116434,
  },
  {
    nome: 'Forlì del Sannio',
    codice: '094020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'D703',
    cap: ['86084'],
    popolazione: 735,
  },
  {
    nome: 'Forlimpopoli',
    codice: '040013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'D705',
    cap: ['47034'],
    popolazione: 12982,
  },
  {
    nome: 'Formazza',
    codice: '103031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D706',
    cap: ['28863'],
    popolazione: 442,
  },
  {
    nome: 'Formello',
    codice: '058038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D707',
    cap: ['00060'],
    popolazione: 11909,
  },
  {
    nome: 'Formia',
    codice: '059008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'D708',
    cap: ['04023'],
    popolazione: 36331,
  },
  {
    nome: 'Formicola',
    codice: '061035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D709',
    cap: ['81040'],
    popolazione: 1504,
  },
  {
    nome: 'Formigara',
    codice: '019044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D710',
    cap: ['26020'],
    popolazione: 1116,
  },
  {
    nome: 'Formigine',
    codice: '036015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'D711',
    cap: ['41043'],
    popolazione: 33667,
  },
  {
    nome: 'Formigliana',
    codice: '002059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D712',
    cap: ['13030'],
    popolazione: 550,
  },
  {
    nome: 'Fornace',
    codice: '022089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D714',
    cap: ['38040'],
    popolazione: 1321,
  },
  {
    nome: 'Fornelli',
    codice: '094021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'D715',
    cap: ['86070'],
    popolazione: 1925,
  },
  {
    nome: 'Forni Avoltri',
    codice: '030040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D718',
    cap: ['33020'],
    popolazione: 642,
  },
  {
    nome: 'Forni di Sopra',
    codice: '030041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D719',
    cap: ['33024'],
    popolazione: 1027,
  },
  {
    nome: 'Forni di Sotto',
    codice: '030042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D720',
    cap: ['33020'],
    popolazione: 620,
  },
  {
    nome: 'Forno Canavese',
    codice: '001107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D725',
    cap: ['10084'],
    popolazione: 3612,
  },
  {
    nome: 'Fornovo di Taro',
    codice: '034017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'D728',
    cap: ['43045'],
    popolazione: 6192,
  },
  {
    nome: 'Fornovo San Giovanni',
    codice: '016105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D727',
    cap: ['24040'],
    popolazione: 3319,
  },
  {
    nome: 'Forte dei Marmi',
    codice: '046013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'D730',
    cap: ['55042'],
    popolazione: 7660,
  },
  {
    nome: 'Fortezza',
    codice: '021032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D731',
    cap: ['39045'],
    popolazione: 970,
  },
  {
    nome: 'Fortunago',
    codice: '018064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D732',
    cap: ['27040'],
    popolazione: 383,
  },
  {
    nome: "Forza d'Agrò",
    codice: '083024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D733',
    cap: ['98030'],
    popolazione: 878,
  },
  {
    nome: 'Fosciandora',
    codice: '046014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'D734',
    cap: ['55020'],
    popolazione: 621,
  },
  {
    nome: 'Fosdinovo',
    codice: '045008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'D735',
    cap: ['54035'],
    popolazione: 4971,
  },
  {
    nome: 'Fossa',
    codice: '066044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'D736',
    cap: ['67020'],
    popolazione: 690,
  },
  {
    nome: 'Fossacesia',
    codice: '069033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D738',
    cap: ['66022'],
    popolazione: 6217,
  },
  {
    nome: 'Fossalta di Piave',
    codice: '027015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'D740',
    cap: ['30020'],
    popolazione: 4214,
  },
  {
    nome: 'Fossalta di Portogruaro',
    codice: '027016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'D741',
    cap: ['30025'],
    popolazione: 6075,
  },
  {
    nome: 'Fossalto',
    codice: '070024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'D737',
    cap: ['86020'],
    popolazione: 1480,
  },
  {
    nome: 'Fossano',
    codice: '004089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D742',
    cap: ['12045'],
    popolazione: 24710,
  },
  {
    nome: 'Fossato di Vico',
    codice: '054019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'D745',
    cap: ['06022'],
    popolazione: 2817,
  },
  {
    nome: 'Fossato Serralta',
    codice: '079052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D744',
    cap: ['88050'],
    popolazione: 614,
  },
  {
    nome: 'Fossò',
    codice: '027017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'D748',
    cap: ['30030'],
    popolazione: 6786,
  },
  {
    nome: 'Fossombrone',
    codice: '041015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D749',
    cap: ['61034'],
    popolazione: 9858,
  },
  {
    nome: 'Foza',
    codice: '024041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D750',
    cap: ['36010'],
    popolazione: 717,
  },
  {
    nome: 'Frabosa Soprana',
    codice: '004090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D751',
    cap: ['12082'],
    popolazione: 815,
  },
  {
    nome: 'Frabosa Sottana',
    codice: '004091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D752',
    cap: ['12083'],
    popolazione: 1494,
  },
  {
    nome: 'Fraconalto',
    codice: '006069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D559',
    cap: ['15060'],
    popolazione: 352,
  },
  {
    nome: 'Fragagnano',
    codice: '073006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'D754',
    cap: ['74022'],
    popolazione: 5353,
  },
  {
    nome: "Fragneto l'Abate",
    codice: '062033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D755',
    cap: ['82020'],
    popolazione: 1116,
  },
  {
    nome: 'Fragneto Monforte',
    codice: '062034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D756',
    cap: ['82020'],
    popolazione: 1889,
  },
  {
    nome: 'Fraine',
    codice: '069034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D757',
    cap: ['66050'],
    popolazione: 396,
  },
  {
    nome: 'Framura',
    codice: '011014',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'D758',
    cap: ['19014'],
    popolazione: 683,
  },
  {
    nome: 'Francavilla al Mare',
    codice: '069035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D763',
    cap: ['66023'],
    popolazione: 23816,
  },
  {
    nome: 'Francavilla Angitola',
    codice: '102014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D762',
    cap: ['89815'],
    popolazione: 1976,
  },
  {
    nome: 'Francavilla Bisio',
    codice: '006070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D759',
    cap: ['15060'],
    popolazione: 518,
  },
  {
    nome: "Francavilla d'Ete",
    codice: '109007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'D760',
    cap: ['63816'],
    popolazione: 1009,
  },
  {
    nome: 'Francavilla di Sicilia',
    codice: '083025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D765',
    cap: ['98034'],
    popolazione: 3901,
  },
  {
    nome: 'Francavilla Fontana',
    codice: '074008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'D761',
    cap: ['72021'],
    popolazione: 36955,
  },
  {
    nome: 'Francavilla in Sinni',
    codice: '076034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D766',
    cap: ['85034'],
    popolazione: 4282,
  },
  {
    nome: 'Francavilla Marittima',
    codice: '078056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D764',
    cap: ['87072'],
    popolazione: 3025,
  },
  {
    nome: 'Francica',
    codice: '102015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D767',
    cap: ['89851'],
    popolazione: 1663,
  },
  {
    nome: 'Francofonte',
    codice: '089010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'D768',
    cap: ['96015'],
    popolazione: 12923,
  },
  {
    nome: 'Francolise',
    codice: '061036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D769',
    cap: ['81050'],
    popolazione: 4921,
  },
  {
    nome: 'Frascaro',
    codice: '006071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D770',
    cap: ['15010'],
    popolazione: 446,
  },
  {
    nome: 'Frascarolo',
    codice: '018065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D771',
    cap: ['27030'],
    popolazione: 1214,
  },
  {
    nome: 'Frascati',
    codice: '058039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D773',
    cap: ['00044'],
    popolazione: 20755,
  },
  {
    nome: 'Frascineto',
    codice: '078057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D774',
    cap: ['87010'],
    popolazione: 2239,
  },
  {
    nome: 'Frassilongo',
    codice: '022090',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D775',
    cap: ['38050'],
    popolazione: 321,
  },
  {
    nome: 'Frassinelle Polesine',
    codice: '029023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D776',
    cap: ['45030'],
    popolazione: 1529,
  },
  {
    nome: 'Frassinello Monferrato',
    codice: '006072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D777',
    cap: ['15035'],
    popolazione: 533,
  },
  {
    nome: 'Frassineto Po',
    codice: '006073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D780',
    cap: ['15040'],
    popolazione: 1471,
  },
  {
    nome: 'Frassinetto',
    codice: '001108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D781',
    cap: ['10080'],
    popolazione: 272,
  },
  {
    nome: 'Frassino',
    codice: '004092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D782',
    cap: ['12020'],
    popolazione: 290,
  },
  {
    nome: 'Frassinoro',
    codice: '036016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'D783',
    cap: ['41044'],
    popolazione: 1997,
  },
  {
    nome: 'Frasso Sabino',
    codice: '057030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'D785',
    cap: ['02030'],
    popolazione: 688,
  },
  {
    nome: 'Frasso Telesino',
    codice: '062035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'D784',
    cap: ['82030'],
    popolazione: 2404,
  },
  {
    nome: 'Fratta Polesine',
    codice: '029024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D788',
    cap: ['45025'],
    popolazione: 2782,
  },
  {
    nome: 'Fratta Todina',
    codice: '054020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'D787',
    cap: ['06054'],
    popolazione: 1840,
  },
  {
    nome: 'Frattamaggiore',
    codice: '063032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'D789',
    cap: ['80027'],
    popolazione: 30241,
  },
  {
    nome: 'Frattaminore',
    codice: '063033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'D790',
    cap: ['80020'],
    popolazione: 15708,
  },
  {
    nome: 'Fratte Rosa',
    codice: '041016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D791',
    cap: ['61040'],
    popolazione: 1017,
  },
  {
    nome: 'Frazzanò',
    codice: '083026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D793',
    cap: ['98070'],
    popolazione: 755,
  },
  {
    nome: 'Fregona',
    codice: '026030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D794',
    cap: ['31010'],
    popolazione: 3169,
  },
  {
    nome: 'Fresagrandinaria',
    codice: '069036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D796',
    cap: ['66050'],
    popolazione: 1056,
  },
  {
    nome: 'Fresonara',
    codice: '006074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D797',
    cap: ['15064'],
    popolazione: 739,
  },
  {
    nome: 'Frigento',
    codice: '064035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'D798',
    cap: ['83040'],
    popolazione: 3965,
  },
  {
    nome: 'Frignano',
    codice: '061037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D799',
    cap: ['81030'],
    popolazione: 8733,
  },
  {
    nome: 'Frinco',
    codice: '005055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'D802',
    cap: ['14030'],
    popolazione: 731,
  },
  {
    nome: 'Frisa',
    codice: '069037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D803',
    cap: ['66030'],
    popolazione: 1889,
  },
  {
    nome: 'Frisanco',
    codice: '093024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'D804',
    cap: ['33080'],
    popolazione: 645,
  },
  {
    nome: 'Front',
    codice: '001109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D805',
    cap: ['10070'],
    popolazione: 1726,
  },
  {
    nome: 'Frontino',
    codice: '041017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D807',
    cap: ['61021'],
    popolazione: 313,
  },
  {
    nome: 'Frontone',
    codice: '041018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D808',
    cap: ['61040'],
    popolazione: 1348,
  },
  {
    nome: 'Frosinone',
    codice: '060038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D810',
    cap: ['03100'],
    popolazione: 46649,
  },
  {
    nome: 'Frosolone',
    codice: '094022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'D811',
    cap: ['86095'],
    popolazione: 3255,
  },
  {
    nome: 'Frossasco',
    codice: '001110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D812',
    cap: ['10060'],
    popolazione: 2840,
  },
  {
    nome: 'Frugarolo',
    codice: '006075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D813',
    cap: ['15065'],
    popolazione: 2012,
  },
  {
    nome: 'Fubine Monferrato',
    codice: '006076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D814',
    cap: ['15043'],
    popolazione: 1657,
  },
  {
    nome: 'Fucecchio',
    codice: '048019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D815',
    cap: ['50054'],
    popolazione: 22785,
  },
  {
    nome: 'Fuipiano Valle Imagna',
    codice: '016106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D817',
    cap: ['24030'],
    popolazione: 221,
  },
  {
    nome: 'Fumane',
    codice: '023035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D818',
    cap: ['37022'],
    popolazione: 4151,
  },
  {
    nome: 'Fumone',
    codice: '060039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D819',
    cap: ['03010'],
    popolazione: 2180,
  },
  {
    nome: 'Funes',
    codice: '021033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D821',
    cap: ['39040'],
    popolazione: 2571,
  },
  {
    nome: 'Furci',
    codice: '069038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D823',
    cap: ['66050'],
    popolazione: 1088,
  },
  {
    nome: 'Furci Siculo',
    codice: '083027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D824',
    cap: ['98023'],
    popolazione: 3428,
  },
  {
    nome: 'Furnari',
    codice: '083028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D825',
    cap: ['98054'],
    popolazione: 3665,
  },
  {
    nome: 'Furore',
    codice: '065053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D826',
    cap: ['84010'],
    popolazione: 846,
  },
  {
    nome: 'Furtei',
    codice: '111022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D827',
    cap: ['09040'],
    popolazione: 1674,
  },
  {
    nome: 'Fuscaldo',
    codice: '078058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D828',
    cap: ['87024'],
    popolazione: 8072,
  },
  {
    nome: 'Fusignano',
    codice: '039011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'D829',
    cap: ['48034'],
    popolazione: 8259,
  },
  {
    nome: 'Fusine',
    codice: '014030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D830',
    cap: ['23010'],
    popolazione: 611,
  },
  {
    nome: 'Futani',
    codice: '065054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D832',
    cap: ['84050'],
    popolazione: 1234,
  },
  {
    nome: 'Gabbioneta-Binanuova',
    codice: '019045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D834',
    cap: ['26030'],
    popolazione: 899,
  },
  {
    nome: 'Gabiano',
    codice: '006077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D835',
    cap: ['15020'],
    popolazione: 1212,
  },
  {
    nome: 'Gabicce Mare',
    codice: '041019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'D836',
    cap: ['61011'],
    popolazione: 5845,
  },
  {
    nome: 'Gaby',
    codice: '007029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'D839',
    cap: ['11020'],
    popolazione: 487,
  },
  {
    nome: 'Gadesco-Pieve Delmona',
    codice: '019046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D841',
    cap: ['26030'],
    popolazione: 2015,
  },
  {
    nome: 'Gadoni',
    codice: '091025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D842',
    cap: ['08030'],
    popolazione: 886,
  },
  {
    nome: 'Gaeta',
    codice: '059009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'D843',
    cap: ['04024'],
    popolazione: 20762,
  },
  {
    nome: 'Gaggi',
    codice: '083029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D844',
    cap: ['98030'],
    popolazione: 3138,
  },
  {
    nome: 'Gaggiano',
    codice: '015103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D845',
    cap: ['20083'],
    popolazione: 8933,
  },
  {
    nome: 'Gaggio Montano',
    codice: '037027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'D847',
    cap: ['40041'],
    popolazione: 5066,
  },
  {
    nome: 'Gaglianico',
    codice: '096026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'D848',
    cap: ['13894'],
    popolazione: 3899,
  },
  {
    nome: 'Gagliano Aterno',
    codice: '066045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'D850',
    cap: ['67020'],
    popolazione: 255,
  },
  {
    nome: 'Gagliano Castelferrato',
    codice: '086010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'D849',
    cap: ['94010'],
    popolazione: 3722,
  },
  {
    nome: 'Gagliano del Capo',
    codice: '075028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D851',
    cap: ['73034'],
    popolazione: 5402,
  },
  {
    nome: 'Gagliato',
    codice: '079055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D852',
    cap: ['88060'],
    popolazione: 524,
  },
  {
    nome: 'Gagliole',
    codice: '043020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'D853',
    cap: ['62022'],
    popolazione: 655,
  },
  {
    nome: 'Gaiarine',
    codice: '026031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'D854',
    cap: ['31018'],
    popolazione: 6136,
  },
  {
    nome: 'Gaiba',
    codice: '029025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D855',
    cap: ['45030'],
    popolazione: 1094,
  },
  {
    nome: 'Gaiola',
    codice: '004093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D856',
    cap: ['12010'],
    popolazione: 600,
  },
  {
    nome: 'Gaiole in Chianti',
    codice: '052013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'D858',
    cap: ['53013'],
    popolazione: 2758,
  },
  {
    nome: 'Gairo',
    codice: '091026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D859',
    cap: ['08040'],
    popolazione: 1514,
  },
  {
    nome: 'Gais',
    codice: '021034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D860',
    cap: ['39030'],
    popolazione: 3167,
  },
  {
    nome: 'Galati Mamertino',
    codice: '083030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D861',
    cap: ['98070'],
    popolazione: 2794,
  },
  {
    nome: 'Galatina',
    codice: '075029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D862',
    cap: ['73013'],
    popolazione: 27214,
  },
  {
    nome: 'Galatone',
    codice: '075030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D863',
    cap: ['73044'],
    popolazione: 15754,
  },
  {
    nome: 'Galatro',
    codice: '080035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D864',
    cap: ['89054'],
    popolazione: 1778,
  },
  {
    nome: 'Galbiate',
    codice: '097036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D865',
    cap: ['23851'],
    popolazione: 8587,
  },
  {
    nome: 'Galeata',
    codice: '040014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'D867',
    cap: ['47010'],
    popolazione: 2516,
  },
  {
    nome: 'Galgagnano',
    codice: '098027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'D868',
    cap: ['26832'],
    popolazione: 1208,
  },
  {
    nome: 'Gallarate',
    codice: '012070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D869',
    cap: ['21013'],
    popolazione: 50456,
  },
  {
    nome: 'Gallese',
    codice: '056027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'D870',
    cap: ['01035'],
    popolazione: 2994,
  },
  {
    nome: 'Galliate',
    codice: '003068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D872',
    cap: ['28066'],
    popolazione: 15008,
  },
  {
    nome: 'Galliate Lombardo',
    codice: '012071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D871',
    cap: ['21020'],
    popolazione: 982,
  },
  {
    nome: 'Galliavola',
    codice: '018066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D873',
    cap: ['27034'],
    popolazione: 213,
  },
  {
    nome: 'Gallicano',
    codice: '046015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'D874',
    cap: ['55027'],
    popolazione: 3882,
  },
  {
    nome: 'Gallicano nel Lazio',
    codice: '058040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D875',
    cap: ['00010'],
    popolazione: 5749,
  },
  {
    nome: 'Gallicchio',
    codice: '076035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D876',
    cap: ['85010'],
    popolazione: 894,
  },
  {
    nome: 'Galliera',
    codice: '037028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'D878',
    cap: ['40015'],
    popolazione: 5462,
  },
  {
    nome: 'Galliera Veneta',
    codice: '028039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D879',
    cap: ['35015'],
    popolazione: 7042,
  },
  {
    nome: 'Gallinaro',
    codice: '060040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'D881',
    cap: ['03040'],
    popolazione: 1246,
  },
  {
    nome: 'Gallio',
    codice: '024042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D882',
    cap: ['36032'],
    popolazione: 2413,
  },
  {
    nome: 'Gallipoli',
    codice: '075031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'D883',
    cap: ['73014'],
    popolazione: 20398,
  },
  {
    nome: 'Gallo Matese',
    codice: '061038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D884',
    cap: ['81010'],
    popolazione: 648,
  },
  {
    nome: 'Gallodoro',
    codice: '083031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'D885',
    cap: ['98030'],
    popolazione: 381,
  },
  {
    nome: 'Galluccio',
    codice: '061039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D886',
    cap: ['81044'],
    popolazione: 2239,
  },
  {
    nome: 'Galtellì',
    codice: '091027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D888',
    cap: ['08020'],
    popolazione: 2472,
  },
  {
    nome: 'Galzignano Terme',
    codice: '028040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D889',
    cap: ['35030'],
    popolazione: 4416,
  },
  {
    nome: 'Gamalero',
    codice: '006078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D890',
    cap: ['15010'],
    popolazione: 847,
  },
  {
    nome: 'Gambara',
    codice: '017073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D891',
    cap: ['25020'],
    popolazione: 4697,
  },
  {
    nome: 'Gambarana',
    codice: '018067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D892',
    cap: ['27030'],
    popolazione: 242,
  },
  {
    nome: 'Gambasca',
    codice: '004094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D894',
    cap: ['12030'],
    popolazione: 408,
  },
  {
    nome: 'Gambassi Terme',
    codice: '048020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'D895',
    cap: ['50050'],
    popolazione: 4900,
  },
  {
    nome: 'Gambatesa',
    codice: '070025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'D896',
    cap: ['86013'],
    popolazione: 1487,
  },
  {
    nome: 'Gambellara',
    codice: '024043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D897',
    cap: ['36053'],
    popolazione: 3319,
  },
  {
    nome: 'Gamberale',
    codice: '069039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D898',
    cap: ['66040'],
    popolazione: 328,
  },
  {
    nome: 'Gambettola',
    codice: '040015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'D899',
    cap: ['47035'],
    popolazione: 10238,
  },
  {
    nome: 'Gambolò',
    codice: '018068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D901',
    cap: ['27025'],
    popolazione: 9779,
  },
  {
    nome: 'Gambugliano',
    codice: '024044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D902',
    cap: ['36050'],
    popolazione: 851,
  },
  {
    nome: 'Gandellino',
    codice: '016107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D903',
    cap: ['24020'],
    popolazione: 1046,
  },
  {
    nome: 'Gandino',
    codice: '016108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D905',
    cap: ['24024'],
    popolazione: 5576,
  },
  {
    nome: 'Gandosso',
    codice: '016109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D906',
    cap: ['24060'],
    popolazione: 1502,
  },
  {
    nome: 'Gangi',
    codice: '082036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'D907',
    cap: ['90024'],
    popolazione: 7063,
  },
  {
    nome: 'Garaguso',
    codice: '077009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'D909',
    cap: ['75010'],
    popolazione: 1134,
  },
  {
    nome: 'Garbagna',
    codice: '006079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D910',
    cap: ['15050'],
    popolazione: 707,
  },
  {
    nome: 'Garbagna Novarese',
    codice: '003069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D911',
    cap: ['28070'],
    popolazione: 1350,
  },
  {
    nome: 'Garbagnate Milanese',
    codice: '015105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D912',
    cap: ['20024'],
    popolazione: 26262,
  },
  {
    nome: 'Garbagnate Monastero',
    codice: '097037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D913',
    cap: ['23846'],
    popolazione: 2430,
  },
  {
    nome: 'Garda',
    codice: '023036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D915',
    cap: ['37016'],
    popolazione: 3978,
  },
  {
    nome: 'Gardone Riviera',
    codice: '017074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D917',
    cap: ['25083'],
    popolazione: 2713,
  },
  {
    nome: 'Gardone Val Trompia',
    codice: '017075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D918',
    cap: ['25063'],
    popolazione: 11700,
  },
  {
    nome: 'Garessio',
    codice: '004095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D920',
    cap: ['12075'],
    popolazione: 3362,
  },
  {
    nome: 'Gargallo',
    codice: '003070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'D921',
    cap: ['28010'],
    popolazione: 1869,
  },
  {
    nome: 'Gargazzone',
    codice: '021035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'D923',
    cap: ['39010'],
    popolazione: 1665,
  },
  {
    nome: 'Gargnano',
    codice: '017076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D924',
    cap: ['25084'],
    popolazione: 3033,
  },
  {
    nome: 'Garlasco',
    codice: '018069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D925',
    cap: ['27026'],
    popolazione: 9791,
  },
  {
    nome: 'Garlate',
    codice: '097038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'D926',
    cap: ['23852'],
    popolazione: 2617,
  },
  {
    nome: 'Garlenda',
    codice: '009030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'D927',
    cap: ['17033'],
    popolazione: 1214,
  },
  {
    nome: 'Garniga Terme',
    codice: '022091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'D928',
    cap: ['38060'],
    popolazione: 381,
  },
  {
    nome: 'Garzeno',
    codice: '013106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D930',
    cap: ['22010'],
    popolazione: 852,
  },
  {
    nome: 'Garzigliana',
    codice: '001111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D931',
    cap: ['10060'],
    popolazione: 557,
  },
  {
    nome: 'Gasperina',
    codice: '079056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D932',
    cap: ['88060'],
    popolazione: 2160,
  },
  {
    nome: 'Gassino Torinese',
    codice: '001112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D933',
    cap: ['10090'],
    popolazione: 9536,
  },
  {
    nome: 'Gattatico',
    codice: '035022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'D934',
    cap: ['42043'],
    popolazione: 5899,
  },
  {
    nome: 'Gatteo',
    codice: '040016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'D935',
    cap: ['47043'],
    popolazione: 8910,
  },
  {
    nome: 'Gattico-Veruno',
    codice: '003166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'M416',
    cap: ['28013'],
    popolazione: 5205,
  },
  {
    nome: 'Gattinara',
    codice: '002061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'D938',
    cap: ['13045'],
    popolazione: 8272,
  },
  {
    nome: 'Gavardo',
    codice: '017077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D940',
    cap: ['25085'],
    popolazione: 11686,
  },
  {
    nome: 'Gavello',
    codice: '029026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'D942',
    cap: ['45010'],
    popolazione: 1605,
  },
  {
    nome: 'Gaverina Terme',
    codice: '016110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D943',
    cap: ['24060'],
    popolazione: 871,
  },
  {
    nome: 'Gavi',
    codice: '006081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'D944',
    cap: ['15066'],
    popolazione: 4707,
  },
  {
    nome: 'Gavignano',
    codice: '058041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D945',
    cap: ['00030'],
    popolazione: 1956,
  },
  {
    nome: 'Gavirate',
    codice: '012072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D946',
    cap: ['21026'],
    popolazione: 9209,
  },
  {
    nome: 'Gavoi',
    codice: '091028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'D947',
    cap: ['08020'],
    popolazione: 2790,
  },
  {
    nome: 'Gavorrano',
    codice: '053010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'D948',
    cap: ['58023'],
    popolazione: 8660,
  },
  {
    nome: 'Gazoldo degli Ippoliti',
    codice: '020024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'D949',
    cap: ['46040'],
    popolazione: 2968,
  },
  {
    nome: 'Gazzada Schianno',
    codice: '012073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D951',
    cap: ['21045'],
    popolazione: 4644,
  },
  {
    nome: 'Gazzaniga',
    codice: '016111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'D952',
    cap: ['24025'],
    popolazione: 5160,
  },
  {
    nome: 'Gazzo',
    codice: '028041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'D956',
    cap: ['35010'],
    popolazione: 4291,
  },
  {
    nome: 'Gazzo Veronese',
    codice: '023037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D957',
    cap: ['37060'],
    popolazione: 5477,
  },
  {
    nome: 'Gazzola',
    codice: '033022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'D958',
    cap: ['29010'],
    popolazione: 1999,
  },
  {
    nome: 'Gazzuolo',
    codice: '020025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'D959',
    cap: ['46010'],
    popolazione: 2392,
  },
  {
    nome: 'Gela',
    codice: '085007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'D960',
    cap: ['93012'],
    popolazione: 75668,
  },
  {
    nome: 'Gemmano',
    codice: '099004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'D961',
    cap: ['47855'],
    popolazione: 1152,
  },
  {
    nome: 'Gemona del Friuli',
    codice: '030043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'D962',
    cap: ['33013'],
    popolazione: 11141,
  },
  {
    nome: 'Gemonio',
    codice: '012074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D963',
    cap: ['21036'],
    popolazione: 2883,
  },
  {
    nome: 'Genazzano',
    codice: '058042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D964',
    cap: ['00030'],
    popolazione: 5959,
  },
  {
    nome: 'Genga',
    codice: '042020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'D965',
    cap: ['60040'],
    popolazione: 1875,
  },
  {
    nome: 'Genivolta',
    codice: '019047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D966',
    cap: ['26020'],
    popolazione: 1178,
  },
  {
    nome: 'Genola',
    codice: '004096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'D967',
    cap: ['12040'],
    popolazione: 2596,
  },
  {
    nome: 'Genoni',
    codice: '111023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D968',
    cap: ['09054'],
    popolazione: 885,
  },
  {
    nome: 'Genova',
    codice: '010025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'D969',
    cap: [
      '16121',
      '16122',
      '16123',
      '16124',
      '16125',
      '16126',
      '16127',
      '16128',
      '16129',
      '16130',
      '16131',
      '16132',
      '16133',
      '16134',
      '16135',
      '16136',
      '16137',
      '16138',
      '16139',
      '16140',
      '16141',
      '16142',
      '16143',
      '16144',
      '16145',
      '16146',
      '16147',
      '16148',
      '16149',
      '16150',
      '16151',
      '16152',
      '16153',
      '16154',
      '16155',
      '16156',
      '16157',
      '16158',
      '16159',
      '16160',
      '16161',
      '16162',
      '16163',
      '16164',
      '16165',
      '16166',
      '16167',
    ],
    popolazione: 586180,
  },
  {
    nome: 'Genuri',
    codice: '111024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D970',
    cap: ['09020'],
    popolazione: 345,
  },
  {
    nome: 'Genzano di Lucania',
    codice: '076036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'D971',
    cap: ['85013'],
    popolazione: 5915,
  },
  {
    nome: 'Genzano di Roma',
    codice: '058043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D972',
    cap: ['00045'],
    popolazione: 23780,
  },
  {
    nome: 'Gera Lario',
    codice: '013107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'D974',
    cap: ['22010'],
    popolazione: 1016,
  },
  {
    nome: 'Gerace',
    codice: '080036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D975',
    cap: ['89040'],
    popolazione: 2772,
  },
  {
    nome: 'Geraci Siculo',
    codice: '082037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'D977',
    cap: ['90010'],
    popolazione: 1925,
  },
  {
    nome: 'Gerano',
    codice: '058044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'D978',
    cap: ['00025'],
    popolazione: 1248,
  },
  {
    nome: 'Gerenzago',
    codice: '018071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'D980',
    cap: ['27010'],
    popolazione: 1386,
  },
  {
    nome: 'Gerenzano',
    codice: '012075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D981',
    cap: ['21040'],
    popolazione: 10411,
  },
  {
    nome: 'Gergei',
    codice: '111025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D982',
    cap: ['09055'],
    popolazione: 1298,
  },
  {
    nome: 'Germagnano',
    codice: '001113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D983',
    cap: ['10070'],
    popolazione: 1256,
  },
  {
    nome: 'Germagno',
    codice: '103032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'D984',
    cap: ['28887'],
    popolazione: 186,
  },
  {
    nome: 'Germignaga',
    codice: '012076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'D987',
    cap: ['21010'],
    popolazione: 3724,
  },
  {
    nome: 'Gerocarne',
    codice: '102016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'D988',
    cap: ['89831'],
    popolazione: 2380,
  },
  {
    nome: 'Gerola Alta',
    codice: '014031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'D990',
    cap: ['23010'],
    popolazione: 189,
  },
  {
    nome: "Gerre de' Caprioli",
    codice: '019048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'D993',
    cap: ['26040'],
    popolazione: 1283,
  },
  {
    nome: 'Gesico',
    codice: '111026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D994',
    cap: ['09040'],
    popolazione: 886,
  },
  {
    nome: 'Gessate',
    codice: '015106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D995',
    cap: ['20060'],
    popolazione: 8664,
  },
  {
    nome: 'Gessopalena',
    codice: '069040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D996',
    cap: ['66010'],
    popolazione: 1550,
  },
  {
    nome: 'Gesturi',
    codice: '111027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'D997',
    cap: ['09020'],
    popolazione: 1280,
  },
  {
    nome: 'Gesualdo',
    codice: '064036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'D998',
    cap: ['83040'],
    popolazione: 3603,
  },
  {
    nome: 'Ghedi',
    codice: '017078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'D999',
    cap: ['25016'],
    popolazione: 18321,
  },
  {
    nome: 'Ghemme',
    codice: '003073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E001',
    cap: ['28074'],
    popolazione: 3617,
  },
  {
    nome: 'Ghiffa',
    codice: '103033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E003',
    cap: ['28823'],
    popolazione: 2394,
  },
  {
    nome: 'Ghilarza',
    codice: '095021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'E004',
    cap: ['09074'],
    popolazione: 4615,
  },
  {
    nome: 'Ghisalba',
    codice: '016113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E006',
    cap: ['24050'],
    popolazione: 5923,
  },
  {
    nome: 'Ghislarengo',
    codice: '002062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E007',
    cap: ['13030'],
    popolazione: 899,
  },
  {
    nome: 'Giacciano con Baruchella',
    codice: '029027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'E008',
    cap: ['45020'],
    popolazione: 2182,
  },
  {
    nome: 'Giaglione',
    codice: '001114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E009',
    cap: ['10050'],
    popolazione: 653,
  },
  {
    nome: 'Gianico',
    codice: '017079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E010',
    cap: ['25040'],
    popolazione: 2196,
  },
  {
    nome: "Giano dell'Umbria",
    codice: '054021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E012',
    cap: ['06030'],
    popolazione: 3816,
  },
  {
    nome: 'Giano Vetusto',
    codice: '061040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E011',
    cap: ['81042'],
    popolazione: 663,
  },
  {
    nome: 'Giardinello',
    codice: '082038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E013',
    cap: ['90040'],
    popolazione: 2258,
  },
  {
    nome: 'Giardini-Naxos',
    codice: '083032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E014',
    cap: ['98035'],
    popolazione: 9268,
  },
  {
    nome: 'Giarole',
    codice: '006082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E015',
    cap: ['15036'],
    popolazione: 720,
  },
  {
    nome: 'Giarratana',
    codice: '088004',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'E016',
    cap: ['97010'],
    popolazione: 3143,
  },
  {
    nome: 'Giarre',
    codice: '087017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'E017',
    cap: ['95014'],
    popolazione: 28114,
  },
  {
    nome: 'Giave',
    codice: '090030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E019',
    cap: ['07010'],
    popolazione: 586,
  },
  {
    nome: 'Giaveno',
    codice: '001115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E020',
    cap: ['10094'],
    popolazione: 16281,
  },
  {
    nome: 'Giavera del Montello',
    codice: '026032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E021',
    cap: ['31040'],
    popolazione: 5144,
  },
  {
    nome: 'Giba',
    codice: '111028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E022',
    cap: ['09010'],
    popolazione: 2120,
  },
  {
    nome: 'Gibellina',
    codice: '081010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'E023',
    cap: ['91024'],
    popolazione: 4264,
  },
  {
    nome: 'Gifflenga',
    codice: '096027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'E024',
    cap: ['13874'],
    popolazione: 130,
  },
  {
    nome: 'Giffone',
    codice: '080037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E025',
    cap: ['89020'],
    popolazione: 1946,
  },
  {
    nome: 'Giffoni Sei Casali',
    codice: '065055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E026',
    cap: ['84090'],
    popolazione: 5262,
  },
  {
    nome: 'Giffoni Valle Piana',
    codice: '065056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E027',
    cap: ['84095'],
    popolazione: 12024,
  },
  {
    nome: 'Gignese',
    codice: '103034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E028',
    cap: ['28836'],
    popolazione: 943,
  },
  {
    nome: 'Gignod',
    codice: '007030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E029',
    cap: ['11010'],
    popolazione: 1692,
  },
  {
    nome: 'Gildone',
    codice: '070026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E030',
    cap: ['86010'],
    popolazione: 850,
  },
  {
    nome: 'Gimigliano',
    codice: '079058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E031',
    cap: ['88045'],
    popolazione: 3421,
  },
  {
    nome: 'Ginestra',
    codice: '076099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E033',
    cap: ['85020'],
    popolazione: 741,
  },
  {
    nome: 'Ginestra degli Schiavoni',
    codice: '062036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'E034',
    cap: ['82020'],
    popolazione: 532,
  },
  {
    nome: 'Ginosa',
    codice: '073007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E036',
    cap: ['74013'],
    popolazione: 22582,
  },
  {
    nome: 'Gioi',
    codice: '065057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E037',
    cap: ['84056'],
    popolazione: 1339,
  },
  {
    nome: 'Gioia dei Marsi',
    codice: '066046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E040',
    cap: ['67055'],
    popolazione: 2111,
  },
  {
    nome: 'Gioia del Colle',
    codice: '072021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'E038',
    cap: ['70023'],
    popolazione: 27889,
  },
  {
    nome: 'Gioia Sannitica',
    codice: '061041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E039',
    cap: ['81010'],
    popolazione: 3640,
  },
  {
    nome: 'Gioia Tauro',
    codice: '080038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E041',
    cap: ['89013'],
    popolazione: 19063,
  },
  {
    nome: 'Gioiosa Ionica',
    codice: '080039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E044',
    cap: ['89042'],
    popolazione: 7014,
  },
  {
    nome: 'Gioiosa Marea',
    codice: '083033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E043',
    cap: ['98063'],
    popolazione: 7114,
  },
  {
    nome: 'Giove',
    codice: '055014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'E045',
    cap: ['05024'],
    popolazione: 1900,
  },
  {
    nome: 'Giovinazzo',
    codice: '072022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'E047',
    cap: ['70054'],
    popolazione: 20433,
  },
  {
    nome: 'Giovo',
    codice: '022092',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E048',
    cap: ['38030'],
    popolazione: 2465,
  },
  {
    nome: 'Girasole',
    codice: '091031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E049',
    cap: ['08040'],
    popolazione: 1191,
  },
  {
    nome: 'Girifalco',
    codice: '079059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E050',
    cap: ['88024'],
    popolazione: 6120,
  },
  {
    nome: 'Gissi',
    codice: '069041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E052',
    cap: ['66052'],
    popolazione: 2935,
  },
  {
    nome: 'Giuggianello',
    codice: '075032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E053',
    cap: ['73030'],
    popolazione: 1249,
  },
  {
    nome: 'Giugliano in Campania',
    codice: '063034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E054',
    cap: ['80014'],
    popolazione: 108793,
  },
  {
    nome: 'Giuliana',
    codice: '082039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E055',
    cap: ['90030'],
    popolazione: 2032,
  },
  {
    nome: 'Giuliano di Roma',
    codice: '060041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'E057',
    cap: ['03020'],
    popolazione: 2343,
  },
  {
    nome: 'Giuliano Teatino',
    codice: '069042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E056',
    cap: ['66010'],
    popolazione: 1270,
  },
  {
    nome: 'Giulianova',
    codice: '067025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'E058',
    cap: ['64021'],
    popolazione: 23199,
  },
  {
    nome: 'Giungano',
    codice: '065058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E060',
    cap: ['84050'],
    popolazione: 1249,
  },
  {
    nome: 'Giurdignano',
    codice: '075033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E061',
    cap: ['73020'],
    popolazione: 1928,
  },
  {
    nome: 'Giussago',
    codice: '018072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E062',
    cap: ['27010'],
    popolazione: 5049,
  },
  {
    nome: 'Giussano',
    codice: '108024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E063',
    cap: ['20833'],
    popolazione: 24527,
  },
  {
    nome: 'Giustenice',
    codice: '009031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'E064',
    cap: ['17027'],
    popolazione: 946,
  },
  {
    nome: 'Giustino',
    codice: '022093',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E065',
    cap: ['38086'],
    popolazione: 743,
  },
  {
    nome: 'Giusvalla',
    codice: '009032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'E066',
    cap: ['17010'],
    popolazione: 460,
  },
  {
    nome: 'Givoletto',
    codice: '001116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E067',
    cap: ['10040'],
    popolazione: 3640,
  },
  {
    nome: 'Gizzeria',
    codice: '079060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E068',
    cap: ['88040'],
    popolazione: 4522,
  },
  {
    nome: 'Glorenza',
    codice: '021036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E069',
    cap: ['39020'],
    popolazione: 889,
  },
  {
    nome: "Godega di Sant'Urbano",
    codice: '026033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E071',
    cap: ['31010'],
    popolazione: 6112,
  },
  {
    nome: 'Godiasco Salice Terme',
    codice: '018073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E072',
    cap: ['27052'],
    popolazione: 3130,
  },
  {
    nome: 'Godrano',
    codice: '082040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E074',
    cap: ['90030'],
    popolazione: 1153,
  },
  {
    nome: 'Goito',
    codice: '020026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E078',
    cap: ['46044'],
    popolazione: 10289,
  },
  {
    nome: 'Golasecca',
    codice: '012077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E079',
    cap: ['21010'],
    popolazione: 2653,
  },
  {
    nome: 'Golferenzo',
    codice: '018074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E081',
    cap: ['27047'],
    popolazione: 206,
  },
  {
    nome: 'Golfo Aranci',
    codice: '090083',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M274',
    cap: ['07020'],
    popolazione: 2288,
  },
  {
    nome: 'Gombito',
    codice: '019049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E082',
    cap: ['26020'],
    popolazione: 636,
  },
  {
    nome: 'Gonars',
    codice: '030044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E083',
    cap: ['33050'],
    popolazione: 4790,
  },
  {
    nome: 'Goni',
    codice: '111029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E084',
    cap: ['09040'],
    popolazione: 504,
  },
  {
    nome: 'Gonnesa',
    codice: '111030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E086',
    cap: ['09010'],
    popolazione: 5135,
  },
  {
    nome: 'Gonnoscodina',
    codice: '095022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'E087',
    cap: ['09090'],
    popolazione: 505,
  },
  {
    nome: 'Gonnosfanadiga',
    codice: '111031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E085',
    cap: ['09035'],
    popolazione: 6702,
  },
  {
    nome: 'Gonnosnò',
    codice: '095023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'D585',
    cap: ['09090'],
    popolazione: 800,
  },
  {
    nome: 'Gonnostramatza',
    codice: '095024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'E088',
    cap: ['09093'],
    popolazione: 943,
  },
  {
    nome: 'Gonzaga',
    codice: '020027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E089',
    cap: ['46023'],
    popolazione: 9138,
  },
  {
    nome: 'Gordona',
    codice: '014032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E090',
    cap: ['23020'],
    popolazione: 1903,
  },
  {
    nome: 'Gorga',
    codice: '058045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E091',
    cap: ['00030'],
    popolazione: 767,
  },
  {
    nome: 'Gorgo al Monticano',
    codice: '026034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E092',
    cap: ['31040'],
    popolazione: 4182,
  },
  {
    nome: 'Gorgoglione',
    codice: '077010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'E093',
    cap: ['75010'],
    popolazione: 1053,
  },
  {
    nome: 'Gorgonzola',
    codice: '015108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E094',
    cap: ['20064'],
    popolazione: 19402,
  },
  {
    nome: 'Goriano Sicoli',
    codice: '066047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E096',
    cap: ['67030'],
    popolazione: 597,
  },
  {
    nome: 'Gorizia',
    codice: '031007',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'E098',
    cap: ['34170'],
    popolazione: 35212,
  },
  {
    nome: 'Gorla Maggiore',
    codice: '012078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E101',
    cap: ['21050'],
    popolazione: 5081,
  },
  {
    nome: 'Gorla Minore',
    codice: '012079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E102',
    cap: ['21055'],
    popolazione: 8398,
  },
  {
    nome: 'Gorlago',
    codice: '016114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E100',
    cap: ['24060'],
    popolazione: 5028,
  },
  {
    nome: 'Gorle',
    codice: '016115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E103',
    cap: ['24020'],
    popolazione: 6445,
  },
  {
    nome: 'Gornate Olona',
    codice: '012080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E104',
    cap: ['21040'],
    popolazione: 2227,
  },
  {
    nome: 'Gorno',
    codice: '016116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E106',
    cap: ['24020'],
    popolazione: 1636,
  },
  {
    nome: 'Goro',
    codice: '038025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'E107',
    cap: ['44020'],
    popolazione: 3895,
  },
  {
    nome: 'Gorreto',
    codice: '010026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'E109',
    cap: ['16020'],
    popolazione: 107,
  },
  {
    nome: 'Gorzegno',
    codice: '004097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E111',
    cap: ['12070'],
    popolazione: 322,
  },
  {
    nome: 'Gosaldo',
    codice: '025025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E113',
    cap: ['32020'],
    popolazione: 723,
  },
  {
    nome: 'Gossolengo',
    codice: '033023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'E114',
    cap: ['29020'],
    popolazione: 5431,
  },
  {
    nome: 'Gottasecca',
    codice: '004098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E115',
    cap: ['12070'],
    popolazione: 174,
  },
  {
    nome: 'Gottolengo',
    codice: '017080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E116',
    cap: ['25023'],
    popolazione: 5238,
  },
  {
    nome: 'Govone',
    codice: '004099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E118',
    cap: ['12040'],
    popolazione: 2157,
  },
  {
    nome: 'Gozzano',
    codice: '003076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E120',
    cap: ['28024'],
    popolazione: 5601,
  },
  {
    nome: 'Gradara',
    codice: '041020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'E122',
    cap: ['61012'],
    popolazione: 4758,
  },
  {
    nome: "Gradisca d'Isonzo",
    codice: '031008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'E124',
    cap: ['34072'],
    popolazione: 6528,
  },
  {
    nome: 'Grado',
    codice: '031009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'E125',
    cap: ['34073'],
    popolazione: 8462,
  },
  {
    nome: 'Gradoli',
    codice: '056028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E126',
    cap: ['01010'],
    popolazione: 1474,
  },
  {
    nome: 'Graffignana',
    codice: '098028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E127',
    cap: ['26813'],
    popolazione: 2604,
  },
  {
    nome: 'Graffignano',
    codice: '056029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E128',
    cap: ['01020'],
    popolazione: 2319,
  },
  {
    nome: 'Graglia',
    codice: '096028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'E130',
    cap: ['13895'],
    popolazione: 1588,
  },
  {
    nome: 'Gragnano',
    codice: '063035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E131',
    cap: ['80054'],
    popolazione: 29509,
  },
  {
    nome: 'Gragnano Trebbiense',
    codice: '033024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'E132',
    cap: ['29010'],
    popolazione: 4386,
  },
  {
    nome: 'Grammichele',
    codice: '087018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'E133',
    cap: ['95042'],
    popolazione: 13064,
  },
  {
    nome: 'Grana',
    codice: '005056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E134',
    cap: ['14031'],
    popolazione: 622,
  },
  {
    nome: "Granarolo dell'Emilia",
    codice: '037030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'E136',
    cap: ['40057'],
    popolazione: 10766,
  },
  {
    nome: 'Grandate',
    codice: '013110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E139',
    cap: ['22070'],
    popolazione: 2862,
  },
  {
    nome: 'Grandola ed Uniti',
    codice: '013111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E141',
    cap: ['22010'],
    popolazione: 1313,
  },
  {
    nome: 'Graniti',
    codice: '083034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E142',
    cap: ['98036'],
    popolazione: 1522,
  },
  {
    nome: 'Granozzo con Monticello',
    codice: '003077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E143',
    cap: ['28060'],
    popolazione: 1432,
  },
  {
    nome: 'Grantola',
    codice: '012081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E144',
    cap: ['21030'],
    popolazione: 1271,
  },
  {
    nome: 'Grantorto',
    codice: '028042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'E145',
    cap: ['35010'],
    popolazione: 4661,
  },
  {
    nome: 'Granze',
    codice: '028043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'E146',
    cap: ['35040'],
    popolazione: 2022,
  },
  {
    nome: 'Grassano',
    codice: '077011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'E147',
    cap: ['75014'],
    popolazione: 5395,
  },
  {
    nome: 'Grassobbio',
    codice: '016117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E148',
    cap: ['24050'],
    popolazione: 6345,
  },
  {
    nome: 'Gratteri',
    codice: '082041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E149',
    cap: ['90010'],
    popolazione: 1019,
  },
  {
    nome: 'Gravedona ed Uniti',
    codice: '013249',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M315',
    cap: ['22015'],
    popolazione: 4209,
  },
  {
    nome: 'Gravellona Lomellina',
    codice: '018075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E152',
    cap: ['27020'],
    popolazione: 2688,
  },
  {
    nome: 'Gravellona Toce',
    codice: '103035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E153',
    cap: ['28883'],
    popolazione: 7751,
  },
  {
    nome: 'Gravere',
    codice: '001117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E154',
    cap: ['10050'],
    popolazione: 715,
  },
  {
    nome: 'Gravina di Catania',
    codice: '087019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'E156',
    cap: ['95030'],
    popolazione: 26543,
  },
  {
    nome: 'Gravina in Puglia',
    codice: '072023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'E155',
    cap: ['70024'],
    popolazione: 43614,
  },
  {
    nome: 'Grazzanise',
    codice: '061042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E158',
    cap: ['81046'],
    popolazione: 7085,
  },
  {
    nome: 'Grazzano Badoglio',
    codice: '005057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E159',
    cap: ['14035'],
    popolazione: 618,
  },
  {
    nome: 'Greccio',
    codice: '057031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'E160',
    cap: ['02045'],
    popolazione: 1520,
  },
  {
    nome: 'Greci',
    codice: '064037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E161',
    cap: ['83030'],
    popolazione: 736,
  },
  {
    nome: 'Greggio',
    codice: '002065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E163',
    cap: ['13030'],
    popolazione: 382,
  },
  {
    nome: 'Gremiasco',
    codice: '006083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E164',
    cap: ['15056'],
    popolazione: 344,
  },
  {
    nome: 'Gressan',
    codice: '007031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E165',
    cap: ['11020'],
    popolazione: 3306,
  },
  {
    nome: 'Gressoney-La-Trinité',
    codice: '007032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E167',
    cap: ['11020'],
    popolazione: 311,
  },
  {
    nome: 'Gressoney-Saint-Jean',
    codice: '007033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E168',
    cap: ['11025'],
    popolazione: 814,
  },
  {
    nome: 'Greve in Chianti',
    codice: '048021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'E169',
    cap: ['50022'],
    popolazione: 13886,
  },
  {
    nome: 'Grezzago',
    codice: '015110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E170',
    cap: ['20056'],
    popolazione: 2842,
  },
  {
    nome: 'Grezzana',
    codice: '023038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E171',
    cap: ['37023'],
    popolazione: 10827,
  },
  {
    nome: 'Griante',
    codice: '013113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E172',
    cap: ['22011'],
    popolazione: 623,
  },
  {
    nome: 'Gricignano di Aversa',
    codice: '061043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E173',
    cap: ['81030'],
    popolazione: 10559,
  },
  {
    nome: 'Grignasco',
    codice: '003079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E177',
    cap: ['28075'],
    popolazione: 4691,
  },
  {
    nome: 'Grigno',
    codice: '022095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E178',
    cap: ['38055'],
    popolazione: 2271,
  },
  {
    nome: 'Grimacco',
    codice: '030045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E179',
    cap: ['33040'],
    popolazione: 374,
  },
  {
    nome: 'Grimaldi',
    codice: '078059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E180',
    cap: ['87034'],
    popolazione: 1739,
  },
  {
    nome: 'Grinzane Cavour',
    codice: '004100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E182',
    cap: ['12060'],
    popolazione: 1938,
  },
  {
    nome: 'Grisignano di Zocco',
    codice: '024046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E184',
    cap: ['36040'],
    popolazione: 4292,
  },
  {
    nome: 'Grisolia',
    codice: '078060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E185',
    cap: ['87020'],
    popolazione: 2310,
  },
  {
    nome: 'Grizzana Morandi',
    codice: '037031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'E187',
    cap: ['40030'],
    popolazione: 3982,
  },
  {
    nome: 'Grognardo',
    codice: '006084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E188',
    cap: ['15010'],
    popolazione: 296,
  },
  {
    nome: 'Gromo',
    codice: '016118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E189',
    cap: ['24020'],
    popolazione: 1239,
  },
  {
    nome: 'Grondona',
    codice: '006085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E191',
    cap: ['15060'],
    popolazione: 545,
  },
  {
    nome: 'Grone',
    codice: '016119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E192',
    cap: ['24060'],
    popolazione: 913,
  },
  {
    nome: 'Grontardo',
    codice: '019050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E193',
    cap: ['26044'],
    popolazione: 1469,
  },
  {
    nome: 'Gropello Cairoli',
    codice: '018076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E195',
    cap: ['27027'],
    popolazione: 4592,
  },
  {
    nome: 'Gropparello',
    codice: '033025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'E196',
    cap: ['29025'],
    popolazione: 2324,
  },
  {
    nome: 'Groscavallo',
    codice: '001118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E199',
    cap: ['10070'],
    popolazione: 191,
  },
  {
    nome: 'Grosio',
    codice: '014033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E200',
    cap: ['23033'],
    popolazione: 4619,
  },
  {
    nome: 'Grosotto',
    codice: '014034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E201',
    cap: ['23034'],
    popolazione: 1615,
  },
  {
    nome: 'Grosseto',
    codice: '053011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'E202',
    cap: ['58100'],
    popolazione: 78630,
  },
  {
    nome: 'Grosso',
    codice: '001119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E203',
    cap: ['10070'],
    popolazione: 1040,
  },
  {
    nome: 'Grottaferrata',
    codice: '058046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E204',
    cap: ['00046'],
    popolazione: 19156,
  },
  {
    nome: 'Grottaglie',
    codice: '073008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E205',
    cap: ['74023'],
    popolazione: 32503,
  },
  {
    nome: 'Grottaminarda',
    codice: '064038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E206',
    cap: ['83035'],
    popolazione: 8297,
  },
  {
    nome: 'Grottammare',
    codice: '044023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'E207',
    cap: ['63066'],
    popolazione: 15615,
  },
  {
    nome: 'Grottazzolina',
    codice: '109008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'E208',
    cap: ['63844'],
    popolazione: 3287,
  },
  {
    nome: 'Grotte',
    codice: '084018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'E209',
    cap: ['92020'],
    popolazione: 5839,
  },
  {
    nome: 'Grotte di Castro',
    codice: '056030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E210',
    cap: ['01025'],
    popolazione: 2795,
  },
  {
    nome: 'Grotteria',
    codice: '080040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E212',
    cap: ['89043'],
    popolazione: 3274,
  },
  {
    nome: 'Grottole',
    codice: '077012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'E213',
    cap: ['75010'],
    popolazione: 2371,
  },
  {
    nome: 'Grottolella',
    codice: '064039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E214',
    cap: ['83010'],
    popolazione: 1955,
  },
  {
    nome: 'Gruaro',
    codice: '027018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'E215',
    cap: ['30020'],
    popolazione: 2802,
  },
  {
    nome: 'Grugliasco',
    codice: '001120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E216',
    cap: ['10095'],
    popolazione: 37194,
  },
  {
    nome: 'Grumello Cremonese ed Uniti',
    codice: '019051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E217',
    cap: ['26023'],
    popolazione: 1873,
  },
  {
    nome: 'Grumello del Monte',
    codice: '016120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E219',
    cap: ['24064'],
    popolazione: 7228,
  },
  {
    nome: 'Grumento Nova',
    codice: '076037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E221',
    cap: ['85050'],
    popolazione: 1704,
  },
  {
    nome: 'Grumo Appula',
    codice: '072024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'E223',
    cap: ['70025'],
    popolazione: 12940,
  },
  {
    nome: 'Grumo Nevano',
    codice: '063036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E224',
    cap: ['80028'],
    popolazione: 18017,
  },
  {
    nome: 'Grumolo delle Abbadesse',
    codice: '024047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E226',
    cap: ['36040'],
    popolazione: 3741,
  },
  {
    nome: 'Guagnano',
    codice: '075034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E227',
    cap: ['73010'],
    popolazione: 5900,
  },
  {
    nome: 'Gualdo',
    codice: '043021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'E228',
    cap: ['62020'],
    popolazione: 868,
  },
  {
    nome: 'Gualdo Cattaneo',
    codice: '054022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E229',
    cap: ['06035'],
    popolazione: 6278,
  },
  {
    nome: 'Gualdo Tadino',
    codice: '054023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E230',
    cap: ['06023'],
    popolazione: 15453,
  },
  {
    nome: 'Gualtieri',
    codice: '035023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'E232',
    cap: ['42044'],
    popolazione: 6639,
  },
  {
    nome: 'Gualtieri Sicaminò',
    codice: '083035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E233',
    cap: ['98040'],
    popolazione: 1834,
  },
  {
    nome: 'Guamaggiore',
    codice: '111032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E234',
    cap: ['09040'],
    popolazione: 1050,
  },
  {
    nome: 'Guanzate',
    codice: '013114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E235',
    cap: ['22070'],
    popolazione: 5705,
  },
  {
    nome: 'Guarcino',
    codice: '060042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'E236',
    cap: ['03016'],
    popolazione: 1658,
  },
  {
    nome: 'Guarda Veneta',
    codice: '029028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'E240',
    cap: ['45030'],
    popolazione: 1185,
  },
  {
    nome: 'Guardabosone',
    codice: '002066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E237',
    cap: ['13010'],
    popolazione: 340,
  },
  {
    nome: 'Guardamiglio',
    codice: '098029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E238',
    cap: ['26862'],
    popolazione: 2687,
  },
  {
    nome: 'Guardavalle',
    codice: '079061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E239',
    cap: ['88065'],
    popolazione: 4752,
  },
  {
    nome: 'Guardea',
    codice: '055015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'E241',
    cap: ['05025'],
    popolazione: 1863,
  },
  {
    nome: 'Guardia Lombardi',
    codice: '064040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E245',
    cap: ['83040'],
    popolazione: 1803,
  },
  {
    nome: 'Guardia Perticara',
    codice: '076038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E246',
    cap: ['85010'],
    popolazione: 580,
  },
  {
    nome: 'Guardia Piemontese',
    codice: '078061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E242',
    cap: ['87020'],
    popolazione: 1895,
  },
  {
    nome: 'Guardia Sanframondi',
    codice: '062037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'E249',
    cap: ['82034'],
    popolazione: 5246,
  },
  {
    nome: 'Guardiagrele',
    codice: '069043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E243',
    cap: ['66016'],
    popolazione: 9367,
  },
  {
    nome: 'Guardialfiera',
    codice: '070027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E244',
    cap: ['86030'],
    popolazione: 1120,
  },
  {
    nome: 'Guardiaregia',
    codice: '070028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E248',
    cap: ['86014'],
    popolazione: 787,
  },
  {
    nome: 'Guardistallo',
    codice: '050015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'E250',
    cap: ['56040'],
    popolazione: 1254,
  },
  {
    nome: 'Guarene',
    codice: '004101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E251',
    cap: ['12050'],
    popolazione: 3435,
  },
  {
    nome: 'Guasila',
    codice: '111033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E252',
    cap: ['09040'],
    popolazione: 2775,
  },
  {
    nome: 'Guastalla',
    codice: '035024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'E253',
    cap: ['42016'],
    popolazione: 14786,
  },
  {
    nome: 'Guazzora',
    codice: '006086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E255',
    cap: ['15050'],
    popolazione: 313,
  },
  {
    nome: 'Gubbio',
    codice: '054024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E256',
    cap: ['06024'],
    popolazione: 32432,
  },
  {
    nome: 'Gudo Visconti',
    codice: '015112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E258',
    cap: ['20088'],
    popolazione: 1711,
  },
  {
    nome: 'Guglionesi',
    codice: '070029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E259',
    cap: ['86034'],
    popolazione: 5449,
  },
  {
    nome: 'Guidizzolo',
    codice: '020028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E261',
    cap: ['46040'],
    popolazione: 6147,
  },
  {
    nome: 'Guidonia Montecelio',
    codice: '058047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E263',
    cap: ['00012'],
    popolazione: 81447,
  },
  {
    nome: 'Guiglia',
    codice: '036017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'E264',
    cap: ['41052'],
    popolazione: 3999,
  },
  {
    nome: 'Guilmi',
    codice: '069044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E266',
    cap: ['66050'],
    popolazione: 432,
  },
  {
    nome: 'Gurro',
    codice: '103036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E269',
    cap: ['28828'],
    popolazione: 247,
  },
  {
    nome: 'Guspini',
    codice: '111034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E270',
    cap: ['09036'],
    popolazione: 12272,
  },
  {
    nome: 'Gussago',
    codice: '017081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E271',
    cap: ['25064'],
    popolazione: 16403,
  },
  {
    nome: 'Gussola',
    codice: '019052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E272',
    cap: ['26040'],
    popolazione: 2873,
  },
  {
    nome: 'Hône',
    codice: '007034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E273',
    cap: ['11020'],
    popolazione: 1175,
  },
  {
    nome: 'Idro',
    codice: '017082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E280',
    cap: ['25074'],
    popolazione: 1892,
  },
  {
    nome: 'Iglesias',
    codice: '111035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E281',
    cap: ['09016'],
    popolazione: 27674,
  },
  {
    nome: 'Igliano',
    codice: '004102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E282',
    cap: ['12060'],
    popolazione: 84,
  },
  {
    nome: 'Ilbono',
    codice: '091032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E283',
    cap: ['08040'],
    popolazione: 2207,
  },
  {
    nome: 'Illasi',
    codice: '023039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E284',
    cap: ['37031'],
    popolazione: 5302,
  },
  {
    nome: 'Illorai',
    codice: '090031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E285',
    cap: ['07010'],
    popolazione: 953,
  },
  {
    nome: 'Imbersago',
    codice: '097039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E287',
    cap: ['23898'],
    popolazione: 2408,
  },
  {
    nome: 'Imer',
    codice: '022097',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E288',
    cap: ['38050'],
    popolazione: 1183,
  },
  {
    nome: 'Imola',
    codice: '037032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'E289',
    cap: ['40026'],
    popolazione: 67892,
  },
  {
    nome: 'Imperia',
    codice: '008031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'E290',
    cap: ['18100'],
    popolazione: 42322,
  },
  {
    nome: 'Impruneta',
    codice: '048022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'E291',
    cap: ['50023'],
    popolazione: 14667,
  },
  {
    nome: 'Inarzo',
    codice: '012082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E292',
    cap: ['21020'],
    popolazione: 1073,
  },
  {
    nome: 'Incisa Scapaccino',
    codice: '005058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E295',
    cap: ['14045'],
    popolazione: 2276,
  },
  {
    nome: 'Incudine',
    codice: '017083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E297',
    cap: ['25040'],
    popolazione: 403,
  },
  {
    nome: 'Induno Olona',
    codice: '012083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E299',
    cap: ['21056'],
    popolazione: 10336,
  },
  {
    nome: 'Ingria',
    codice: '001121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E301',
    cap: ['10080'],
    popolazione: 49,
  },
  {
    nome: 'Intragna',
    codice: '103037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E304',
    cap: ['28816'],
    popolazione: 107,
  },
  {
    nome: 'Introbio',
    codice: '097040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E305',
    cap: ['23815'],
    popolazione: 2003,
  },
  {
    nome: 'Introd',
    codice: '007035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E306',
    cap: ['11010'],
    popolazione: 632,
  },
  {
    nome: 'Introdacqua',
    codice: '066048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E307',
    cap: ['67030'],
    popolazione: 2129,
  },
  {
    nome: 'Inverigo',
    codice: '013118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E309',
    cap: ['22044'],
    popolazione: 8926,
  },
  {
    nome: 'Inverno e Monteleone',
    codice: '018077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E310',
    cap: ['27010'],
    popolazione: 1390,
  },
  {
    nome: 'Inverso Pinasca',
    codice: '001122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E311',
    cap: ['10060'],
    popolazione: 741,
  },
  {
    nome: 'Inveruno',
    codice: '015113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E313',
    cap: ['20010'],
    popolazione: 8609,
  },
  {
    nome: 'Invorio',
    codice: '003082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E314',
    cap: ['28045'],
    popolazione: 4464,
  },
  {
    nome: 'Inzago',
    codice: '015114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E317',
    cap: ['20065'],
    popolazione: 10540,
  },
  {
    nome: 'Ionadi',
    codice: '102017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'E321',
    cap: ['89851'],
    popolazione: 3822,
  },
  {
    nome: 'Irgoli',
    codice: '091033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E323',
    cap: ['08020'],
    popolazione: 2345,
  },
  {
    nome: 'Irma',
    codice: '017084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E325',
    cap: ['25061'],
    popolazione: 147,
  },
  {
    nome: 'Irsina',
    codice: '077013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'E326',
    cap: ['75022'],
    popolazione: 5100,
  },
  {
    nome: 'Isasca',
    codice: '004103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E327',
    cap: ['12020'],
    popolazione: 78,
  },
  {
    nome: 'Isca sullo Ionio',
    codice: '079063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E328',
    cap: ['88060'],
    popolazione: 1614,
  },
  {
    nome: 'Ischia',
    codice: '063037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E329',
    cap: ['80077'],
    popolazione: 18688,
  },
  {
    nome: 'Ischia di Castro',
    codice: '056031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E330',
    cap: ['01010'],
    popolazione: 2377,
  },
  {
    nome: 'Ischitella',
    codice: '071025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'E332',
    cap: ['71010'],
    popolazione: 4316,
  },
  {
    nome: 'Iseo',
    codice: '017085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E333',
    cap: ['25049'],
    popolazione: 9100,
  },
  {
    nome: 'Isera',
    codice: '022098',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E334',
    cap: ['38060'],
    popolazione: 2625,
  },
  {
    nome: 'Isernia',
    codice: '094023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'E335',
    cap: ['86170'],
    popolazione: 22025,
  },
  {
    nome: 'Isili',
    codice: '111036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E336',
    cap: ['09056'],
    popolazione: 2842,
  },
  {
    nome: 'Isnello',
    codice: '082042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E337',
    cap: ['90010'],
    popolazione: 1598,
  },
  {
    nome: "Isola d'Asti",
    codice: '005059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E338',
    cap: ['14057'],
    popolazione: 2121,
  },
  {
    nome: 'Isola del Cantone',
    codice: '010027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'E341',
    cap: ['16017'],
    popolazione: 1535,
  },
  {
    nome: 'Isola del Giglio',
    codice: '053012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'E348',
    cap: ['58012'],
    popolazione: 1418,
  },
  {
    nome: "Isola del Gran Sasso d'Italia",
    codice: '067026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'E343',
    cap: ['64045'],
    popolazione: 4840,
  },
  {
    nome: 'Isola del Liri',
    codice: '060043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'E340',
    cap: ['03036'],
    popolazione: 11963,
  },
  {
    nome: 'Isola del Piano',
    codice: '041021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'E351',
    cap: ['61030'],
    popolazione: 635,
  },
  {
    nome: 'Isola della Scala',
    codice: '023040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E349',
    cap: ['37063'],
    popolazione: 11457,
  },
  {
    nome: 'Isola delle Femmine',
    codice: '082043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E350',
    cap: ['90040'],
    popolazione: 7100,
  },
  {
    nome: 'Isola di Capo Rizzuto',
    codice: '101013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'E339',
    cap: ['88841'],
    popolazione: 15827,
  },
  {
    nome: 'Isola di Fondra',
    codice: '016121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E353',
    cap: ['24010'],
    popolazione: 192,
  },
  {
    nome: 'Isola Dovarese',
    codice: '019053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E356',
    cap: ['26031'],
    popolazione: 1231,
  },
  {
    nome: 'Isola Rizza',
    codice: '023041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E358',
    cap: ['37050'],
    popolazione: 3255,
  },
  {
    nome: "Isola Sant'Antonio",
    codice: '006087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E360',
    cap: ['15050'],
    popolazione: 734,
  },
  {
    nome: 'Isola Vicentina',
    codice: '024048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E354',
    cap: ['36033'],
    popolazione: 9639,
  },
  {
    nome: 'Isolabella',
    codice: '001123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E345',
    cap: ['10046'],
    popolazione: 393,
  },
  {
    nome: 'Isolabona',
    codice: '008032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'E346',
    cap: ['18035'],
    popolazione: 678,
  },
  {
    nome: 'Isole Tremiti',
    codice: '071026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'E363',
    cap: ['71051'],
    popolazione: 455,
  },
  {
    nome: 'Isorella',
    codice: '017086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E364',
    cap: ['25010'],
    popolazione: 4091,
  },
  {
    nome: 'Ispani',
    codice: '065059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E365',
    cap: ['84050'],
    popolazione: 1020,
  },
  {
    nome: 'Ispica',
    codice: '088005',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'E366',
    cap: ['97014'],
    popolazione: 15122,
  },
  {
    nome: 'Ispra',
    codice: '012084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E367',
    cap: ['21027'],
    popolazione: 5178,
  },
  {
    nome: 'Issiglio',
    codice: '001124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E368',
    cap: ['10080'],
    popolazione: 425,
  },
  {
    nome: 'Issime',
    codice: '007036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E369',
    cap: ['11020'],
    popolazione: 422,
  },
  {
    nome: 'Isso',
    codice: '016122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E370',
    cap: ['24040'],
    popolazione: 660,
  },
  {
    nome: 'Issogne',
    codice: '007037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E371',
    cap: ['11020'],
    popolazione: 1396,
  },
  {
    nome: 'Istrana',
    codice: '026035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E373',
    cap: ['31036'],
    popolazione: 9041,
  },
  {
    nome: 'Itala',
    codice: '083036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E374',
    cap: ['98025'],
    popolazione: 1663,
  },
  {
    nome: 'Itri',
    codice: '059010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'E375',
    cap: ['04020'],
    popolazione: 10460,
  },
  {
    nome: 'Ittireddu',
    codice: '090032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E376',
    cap: ['07010'],
    popolazione: 580,
  },
  {
    nome: 'Ittiri',
    codice: '090033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E377',
    cap: ['07044'],
    popolazione: 8868,
  },
  {
    nome: 'Ivrea',
    codice: '001125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E379',
    cap: ['10015'],
    popolazione: 23592,
  },
  {
    nome: 'Izano',
    codice: '019054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E380',
    cap: ['26010'],
    popolazione: 2068,
  },
  {
    nome: 'Jacurso',
    codice: '079065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E274',
    cap: ['88020'],
    popolazione: 623,
  },
  {
    nome: 'Jelsi',
    codice: '070030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E381',
    cap: ['86015'],
    popolazione: 1797,
  },
  {
    nome: 'Jenne',
    codice: '058048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E382',
    cap: ['00020'],
    popolazione: 398,
  },
  {
    nome: 'Jerago con Orago',
    codice: '012085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E386',
    cap: ['21040'],
    popolazione: 5084,
  },
  {
    nome: 'Jerzu',
    codice: '091035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E387',
    cap: ['08044'],
    popolazione: 3228,
  },
  {
    nome: 'Jesi',
    codice: '042021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'E388',
    cap: ['60035'],
    popolazione: 40303,
  },
  {
    nome: 'Jesolo',
    codice: '027019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'C388',
    cap: ['30016'],
    popolazione: 24479,
  },
  {
    nome: 'Jolanda di Savoia',
    codice: '038010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'E320',
    cap: ['44037'],
    popolazione: 3003,
  },
  {
    nome: 'Joppolo',
    codice: '102018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'E389',
    cap: ['89863'],
    popolazione: 2090,
  },
  {
    nome: 'Joppolo Giancaxio',
    codice: '084019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'E390',
    cap: ['92010'],
    popolazione: 1210,
  },
  {
    nome: 'Jovençan',
    codice: '007038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E391',
    cap: ['11020'],
    popolazione: 758,
  },
  {
    nome: "L'Aquila",
    codice: '066049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'A345',
    cap: ['67100'],
    popolazione: 66964,
  },
  {
    nome: 'La Cassa',
    codice: '001126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E394',
    cap: ['10040'],
    popolazione: 1781,
  },
  {
    nome: 'La Loggia',
    codice: '001127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E423',
    cap: ['10040'],
    popolazione: 8631,
  },
  {
    nome: 'La Maddalena',
    codice: '090035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E425',
    cap: ['07024'],
    popolazione: 10936,
  },
  {
    nome: 'La Magdeleine',
    codice: '007039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'A308',
    cap: ['11020'],
    popolazione: 111,
  },
  {
    nome: 'La Morra',
    codice: '004105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E430',
    cap: ['12064'],
    popolazione: 2718,
  },
  {
    nome: 'La Salle',
    codice: '007040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E458',
    cap: ['11015'],
    popolazione: 2071,
  },
  {
    nome: 'La Spezia',
    codice: '011015',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'E463',
    cap: [
      '19121',
      '19122',
      '19123',
      '19124',
      '19125',
      '19126',
      '19127',
      '19128',
      '19129',
      '19130',
      '19131',
      '19132',
      '19133',
      '19134',
      '19135',
      '19136',
      '19137',
    ],
    popolazione: 92659,
  },
  {
    nome: 'La Thuile',
    codice: '007041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E470',
    cap: ['11016'],
    popolazione: 776,
  },
  {
    nome: 'La Valle',
    codice: '021117',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E491',
    cap: ['39030'],
    popolazione: 1299,
  },
  {
    nome: 'La Valle Agordina',
    codice: '025027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E490',
    cap: ['32020'],
    popolazione: 1150,
  },
  {
    nome: 'La Valletta Brianza',
    codice: '097092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'M348',
    cap: ['23888'],
    popolazione: 4676,
  },
  {
    nome: 'Labico',
    codice: '058049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E392',
    cap: ['00030'],
    popolazione: 5979,
  },
  {
    nome: 'Labro',
    codice: '057032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'E393',
    cap: ['02010'],
    popolazione: 344,
  },
  {
    nome: 'Lacchiarella',
    codice: '015115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E395',
    cap: ['20084'],
    popolazione: 8390,
  },
  {
    nome: 'Lacco Ameno',
    codice: '063038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E396',
    cap: ['80076'],
    popolazione: 4675,
  },
  {
    nome: 'Lacedonia',
    codice: '064041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E397',
    cap: ['83046'],
    popolazione: 2465,
  },
  {
    nome: 'Laces',
    codice: '021037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E398',
    cap: ['39021'],
    popolazione: 5126,
  },
  {
    nome: 'Laconi',
    codice: '095082',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'E400',
    cap: ['09090'],
    popolazione: 2008,
  },
  {
    nome: 'Ladispoli',
    codice: '058116',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M212',
    cap: ['00055'],
    popolazione: 37293,
  },
  {
    nome: 'Laerru',
    codice: '090034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E401',
    cap: ['07030'],
    popolazione: 945,
  },
  {
    nome: 'Laganadi',
    codice: '080041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E402',
    cap: ['89050'],
    popolazione: 412,
  },
  {
    nome: 'Laghi',
    codice: '024049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E403',
    cap: ['36010'],
    popolazione: 123,
  },
  {
    nome: 'Laglio',
    codice: '013119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E405',
    cap: ['22010'],
    popolazione: 917,
  },
  {
    nome: 'Lagnasco',
    codice: '004104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E406',
    cap: ['12030'],
    popolazione: 1405,
  },
  {
    nome: 'Lago',
    codice: '078062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E407',
    cap: ['87035'],
    popolazione: 2689,
  },
  {
    nome: 'Lagonegro',
    codice: '076039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E409',
    cap: ['85042'],
    popolazione: 5725,
  },
  {
    nome: 'Lagosanto',
    codice: '038011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'E410',
    cap: ['44023'],
    popolazione: 4952,
  },
  {
    nome: 'Lagundo',
    codice: '021038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E412',
    cap: ['39022'],
    popolazione: 4873,
  },
  {
    nome: 'Laigueglia',
    codice: '009033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'E414',
    cap: ['17053'],
    popolazione: 1800,
  },
  {
    nome: 'Lainate',
    codice: '015116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E415',
    cap: ['20020'],
    popolazione: 25054,
  },
  {
    nome: 'Laino',
    codice: '013120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E416',
    cap: ['22020'],
    popolazione: 517,
  },
  {
    nome: 'Laino Borgo',
    codice: '078063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E417',
    cap: ['87014'],
    popolazione: 2027,
  },
  {
    nome: 'Laino Castello',
    codice: '078064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E419',
    cap: ['87015'],
    popolazione: 879,
  },
  {
    nome: 'Laion',
    codice: '021039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E420',
    cap: ['39040'],
    popolazione: 2619,
  },
  {
    nome: 'Laives',
    codice: '021040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E421',
    cap: ['39055'],
    popolazione: 16909,
  },
  {
    nome: 'Lajatico',
    codice: '050016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'E413',
    cap: ['56030'],
    popolazione: 1376,
  },
  {
    nome: 'Lallio',
    codice: '016123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E422',
    cap: ['24040'],
    popolazione: 4124,
  },
  {
    nome: 'Lama dei Peligni',
    codice: '069045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E424',
    cap: ['66010'],
    popolazione: 1364,
  },
  {
    nome: 'Lama Mocogno',
    codice: '036018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'E426',
    cap: ['41023'],
    popolazione: 2844,
  },
  {
    nome: 'Lambrugo',
    codice: '013121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E428',
    cap: ['22045'],
    popolazione: 2469,
  },
  {
    nome: 'Lamezia Terme',
    codice: '079160',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'M208',
    cap: ['88046'],
    popolazione: 70336,
  },
  {
    nome: 'Lamon',
    codice: '025026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E429',
    cap: ['32033'],
    popolazione: 3046,
  },
  {
    nome: 'Lampedusa e Linosa',
    codice: '084020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'E431',
    cap: ['92031'],
    popolazione: 6105,
  },
  {
    nome: 'Lamporecchio',
    codice: '047005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'E432',
    cap: ['51035'],
    popolazione: 7583,
  },
  {
    nome: 'Lamporo',
    codice: '002067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E433',
    cap: ['13046'],
    popolazione: 546,
  },
  {
    nome: 'Lana',
    codice: '021041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E434',
    cap: ['39011'],
    popolazione: 11251,
  },
  {
    nome: 'Lanciano',
    codice: '069046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E435',
    cap: ['66034'],
    popolazione: 35921,
  },
  {
    nome: 'Landiona',
    codice: '003083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E436',
    cap: ['28064'],
    popolazione: 590,
  },
  {
    nome: 'Landriano',
    codice: '018078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E437',
    cap: ['27015'],
    popolazione: 5917,
  },
  {
    nome: 'Langhirano',
    codice: '034018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'E438',
    cap: ['43013'],
    popolazione: 9784,
  },
  {
    nome: 'Langosco',
    codice: '018079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E439',
    cap: ['27030'],
    popolazione: 436,
  },
  {
    nome: 'Lanusei',
    codice: '091037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E441',
    cap: ['08045'],
    popolazione: 5492,
  },
  {
    nome: 'Lanuvio',
    codice: '058050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'C767',
    cap: ['00075'],
    popolazione: 13006,
  },
  {
    nome: 'Lanzada',
    codice: '014036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E443',
    cap: ['23020'],
    popolazione: 1372,
  },
  {
    nome: 'Lanzo Torinese',
    codice: '001128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E445',
    cap: ['10074'],
    popolazione: 5150,
  },
  {
    nome: 'Lapedona',
    codice: '109009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'E447',
    cap: ['63823'],
    popolazione: 1175,
  },
  {
    nome: 'Lapio',
    codice: '064042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E448',
    cap: ['83030'],
    popolazione: 1648,
  },
  {
    nome: 'Lappano',
    codice: '078065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E450',
    cap: ['87050'],
    popolazione: 986,
  },
  {
    nome: 'Larciano',
    codice: '047006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'E451',
    cap: ['51036'],
    popolazione: 6418,
  },
  {
    nome: 'Lardirago',
    codice: '018080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E454',
    cap: ['27016'],
    popolazione: 1200,
  },
  {
    nome: 'Lariano',
    codice: '058115',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M207',
    cap: ['00076'],
    popolazione: 12893,
  },
  {
    nome: 'Larino',
    codice: '070031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E456',
    cap: ['86035'],
    popolazione: 7142,
  },
  {
    nome: 'Las Plassas',
    codice: '111037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E464',
    cap: ['09020'],
    popolazione: 257,
  },
  {
    nome: 'Lasa',
    codice: '021042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E457',
    cap: ['39023'],
    popolazione: 3932,
  },
  {
    nome: 'Lascari',
    codice: '082044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E459',
    cap: ['90010'],
    popolazione: 3500,
  },
  {
    nome: 'Lasnigo',
    codice: '013123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E462',
    cap: ['22030'],
    popolazione: 462,
  },
  {
    nome: 'Lastebasse',
    codice: '024050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E465',
    cap: ['36040'],
    popolazione: 229,
  },
  {
    nome: 'Lastra a Signa',
    codice: '048024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'E466',
    cap: ['50055'],
    popolazione: 18960,
  },
  {
    nome: 'Latera',
    codice: '056032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E467',
    cap: ['01010'],
    popolazione: 933,
  },
  {
    nome: 'Laterina Pergine Valdarno',
    codice: '051042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'M392',
    cap: ['52019'],
    popolazione: 6759,
  },
  {
    nome: 'Laterza',
    codice: '073009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E469',
    cap: ['74014'],
    popolazione: 15296,
  },
  {
    nome: 'Latiano',
    codice: '074009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'E471',
    cap: ['72022'],
    popolazione: 15045,
  },
  {
    nome: 'Latina',
    codice: '059011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'E472',
    cap: ['04100'],
    popolazione: 117892,
  },
  {
    nome: 'Latisana',
    codice: '030046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E473',
    cap: ['33053'],
    popolazione: 13647,
  },
  {
    nome: 'Latronico',
    codice: '076040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E474',
    cap: ['85043'],
    popolazione: 4748,
  },
  {
    nome: 'Lattarico',
    codice: '078066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E475',
    cap: ['87010'],
    popolazione: 4058,
  },
  {
    nome: 'Lauco',
    codice: '030047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E476',
    cap: ['33029'],
    popolazione: 784,
  },
  {
    nome: 'Laureana Cilento',
    codice: '065060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E480',
    cap: ['84050'],
    popolazione: 1151,
  },
  {
    nome: 'Laureana di Borrello',
    codice: '080042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E479',
    cap: ['89023'],
    popolazione: 5289,
  },
  {
    nome: 'Lauregno',
    codice: '021043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E481',
    cap: ['39040'],
    popolazione: 344,
  },
  {
    nome: 'Laurenzana',
    codice: '076041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E482',
    cap: ['85014'],
    popolazione: 1944,
  },
  {
    nome: 'Lauria',
    codice: '076042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E483',
    cap: ['85044'],
    popolazione: 13262,
  },
  {
    nome: 'Lauriano',
    codice: '001129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E484',
    cap: ['10020'],
    popolazione: 1493,
  },
  {
    nome: 'Laurino',
    codice: '065061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E485',
    cap: ['84057'],
    popolazione: 1708,
  },
  {
    nome: 'Laurito',
    codice: '065062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E486',
    cap: ['84050'],
    popolazione: 843,
  },
  {
    nome: 'Lauro',
    codice: '064043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E487',
    cap: ['83023'],
    popolazione: 3608,
  },
  {
    nome: 'Lavagna',
    codice: '010028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'E488',
    cap: ['16033'],
    popolazione: 12579,
  },
  {
    nome: 'Lavagno',
    codice: '023042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E489',
    cap: ['37030'],
    popolazione: 8101,
  },
  {
    nome: 'Lavarone',
    codice: '022102',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E492',
    cap: ['38046'],
    popolazione: 1087,
  },
  {
    nome: 'Lavello',
    codice: '076043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E493',
    cap: ['85024'],
    popolazione: 13590,
  },
  {
    nome: 'Lavena Ponte Tresa',
    codice: '012086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E494',
    cap: ['21037'],
    popolazione: 5414,
  },
  {
    nome: 'Laveno-Mombello',
    codice: '012087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E496',
    cap: ['21014'],
    popolazione: 8905,
  },
  {
    nome: 'Lavenone',
    codice: '017087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E497',
    cap: ['25074'],
    popolazione: 607,
  },
  {
    nome: 'Laviano',
    codice: '065063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E498',
    cap: ['84020'],
    popolazione: 1485,
  },
  {
    nome: 'Lavis',
    codice: '022103',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E500',
    cap: ['38015'],
    popolazione: 8636,
  },
  {
    nome: 'Lazise',
    codice: '023043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E502',
    cap: ['37017'],
    popolazione: 6695,
  },
  {
    nome: 'Lazzate',
    codice: '108025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E504',
    cap: ['20824'],
    popolazione: 7603,
  },
  {
    nome: 'Lecce',
    codice: '075035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E506',
    cap: ['73100'],
    popolazione: 89916,
  },
  {
    nome: 'Lecce nei Marsi',
    codice: '066050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E505',
    cap: ['67050'],
    popolazione: 1735,
  },
  {
    nome: 'Lecco',
    codice: '097042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E507',
    cap: ['23900'],
    popolazione: 46705,
  },
  {
    nome: 'Ledro',
    codice: '022229',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M313',
    cap: ['38067'],
    popolazione: 5448,
  },
  {
    nome: 'Leffe',
    codice: '016124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E509',
    cap: ['24026'],
    popolazione: 4671,
  },
  {
    nome: 'Leggiuno',
    codice: '012088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E510',
    cap: ['21038'],
    popolazione: 3571,
  },
  {
    nome: 'Legnago',
    codice: '023044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E512',
    cap: ['37045'],
    popolazione: 24992,
  },
  {
    nome: 'Legnano',
    codice: '015118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E514',
    cap: ['20025'],
    popolazione: 57647,
  },
  {
    nome: 'Legnaro',
    codice: '028044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'E515',
    cap: ['35020'],
    popolazione: 8544,
  },
  {
    nome: 'Lei',
    codice: '091038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E517',
    cap: ['08010'],
    popolazione: 566,
  },
  {
    nome: 'Leini',
    codice: '001130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E518',
    cap: ['10040'],
    popolazione: 15320,
  },
  {
    nome: 'Leivi',
    codice: '010029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'E519',
    cap: ['16040'],
    popolazione: 2349,
  },
  {
    nome: 'Lemie',
    codice: '001131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E520',
    cap: ['10070'],
    popolazione: 189,
  },
  {
    nome: 'Lendinara',
    codice: '029029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'E522',
    cap: ['45026'],
    popolazione: 12024,
  },
  {
    nome: 'Leni',
    codice: '083037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E523',
    cap: ['98050'],
    popolazione: 702,
  },
  {
    nome: 'Lenna',
    codice: '016125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E524',
    cap: ['24010'],
    popolazione: 641,
  },
  {
    nome: 'Leno',
    codice: '017088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E526',
    cap: ['25024'],
    popolazione: 14364,
  },
  {
    nome: 'Lenola',
    codice: '059012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'E527',
    cap: ['04025'],
    popolazione: 4155,
  },
  {
    nome: 'Lenta',
    codice: '002068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E528',
    cap: ['13035'],
    popolazione: 878,
  },
  {
    nome: 'Lentate sul Seveso',
    codice: '108054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E530',
    cap: ['20823'],
    popolazione: 15486,
  },
  {
    nome: 'Lentella',
    codice: '069047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E531',
    cap: ['66050'],
    popolazione: 725,
  },
  {
    nome: 'Lentini',
    codice: '089011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'E532',
    cap: ['96016'],
    popolazione: 24484,
  },
  {
    nome: 'Leonessa',
    codice: '057033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'E535',
    cap: ['02016'],
    popolazione: 2480,
  },
  {
    nome: 'Leonforte',
    codice: '086011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'E536',
    cap: ['94013'],
    popolazione: 13878,
  },
  {
    nome: 'Leporano',
    codice: '073010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E537',
    cap: ['74020'],
    popolazione: 7802,
  },
  {
    nome: 'Lequile',
    codice: '075036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E538',
    cap: ['73010'],
    popolazione: 8624,
  },
  {
    nome: 'Lequio Berria',
    codice: '004106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E540',
    cap: ['12050'],
    popolazione: 494,
  },
  {
    nome: 'Lequio Tanaro',
    codice: '004107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E539',
    cap: ['12060'],
    popolazione: 819,
  },
  {
    nome: 'Lercara Friddi',
    codice: '082045',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E541',
    cap: ['90025'],
    popolazione: 6935,
  },
  {
    nome: 'Lerici',
    codice: '011016',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'E542',
    cap: ['19032'],
    popolazione: 10090,
  },
  {
    nome: 'Lerma',
    codice: '006088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E543',
    cap: ['15070'],
    popolazione: 873,
  },
  {
    nome: 'Lesa',
    codice: '003084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E544',
    cap: ['28040'],
    popolazione: 2236,
  },
  {
    nome: 'Lesegno',
    codice: '004108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E546',
    cap: ['12076'],
    popolazione: 854,
  },
  {
    nome: "Lesignano de' Bagni",
    codice: '034019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'E547',
    cap: ['43037'],
    popolazione: 4759,
  },
  {
    nome: 'Lesina',
    codice: '071027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'E549',
    cap: ['71010'],
    popolazione: 6319,
  },
  {
    nome: 'Lesmo',
    codice: '108026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E550',
    cap: ['20855'],
    popolazione: 8094,
  },
  {
    nome: 'Lessolo',
    codice: '001132',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E551',
    cap: ['10010'],
    popolazione: 1982,
  },
  {
    nome: 'Lessona',
    codice: '096085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M371',
    cap: ['13853'],
    popolazione: 2835,
  },
  {
    nome: 'Lestizza',
    codice: '030048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E553',
    cap: ['33050'],
    popolazione: 3885,
  },
  {
    nome: 'Letino',
    codice: '061044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E554',
    cap: ['81010'],
    popolazione: 715,
  },
  {
    nome: 'Letojanni',
    codice: '083038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E555',
    cap: ['98037'],
    popolazione: 2699,
  },
  {
    nome: 'Lettere',
    codice: '063039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E557',
    cap: ['80050'],
    popolazione: 6153,
  },
  {
    nome: 'Lettomanoppello',
    codice: '068020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'E558',
    cap: ['65020'],
    popolazione: 3019,
  },
  {
    nome: 'Lettopalena',
    codice: '069048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E559',
    cap: ['66010'],
    popolazione: 365,
  },
  {
    nome: 'Levanto',
    codice: '011017',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'E560',
    cap: ['19015'],
    popolazione: 5509,
  },
  {
    nome: 'Levate',
    codice: '016126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E562',
    cap: ['24040'],
    popolazione: 3802,
  },
  {
    nome: 'Leverano',
    codice: '075037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E563',
    cap: ['73045'],
    popolazione: 14069,
  },
  {
    nome: 'Levice',
    codice: '004109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E564',
    cap: ['12070'],
    popolazione: 246,
  },
  {
    nome: 'Levico Terme',
    codice: '022104',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E565',
    cap: ['38056'],
    popolazione: 7516,
  },
  {
    nome: 'Levone',
    codice: '001133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E566',
    cap: ['10070'],
    popolazione: 439,
  },
  {
    nome: 'Lezzeno',
    codice: '013126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E569',
    cap: ['22025'],
    popolazione: 2052,
  },
  {
    nome: 'Liberi',
    codice: '061045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E570',
    cap: ['81040'],
    popolazione: 1157,
  },
  {
    nome: 'Librizzi',
    codice: '083039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E571',
    cap: ['98064'],
    popolazione: 1771,
  },
  {
    nome: 'Licata',
    codice: '084021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'E573',
    cap: ['92027'],
    popolazione: 38125,
  },
  {
    nome: 'Licciana Nardi',
    codice: '045009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'E574',
    cap: ['54016'],
    popolazione: 4955,
  },
  {
    nome: 'Licenza',
    codice: '058051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E576',
    cap: ['00026'],
    popolazione: 1012,
  },
  {
    nome: 'Licodia Eubea',
    codice: '087020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'E578',
    cap: ['95040'],
    popolazione: 3047,
  },
  {
    nome: 'Lierna',
    codice: '097043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E581',
    cap: ['23827'],
    popolazione: 2232,
  },
  {
    nome: 'Lignana',
    codice: '002070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E583',
    cap: ['13034'],
    popolazione: 579,
  },
  {
    nome: 'Lignano Sabbiadoro',
    codice: '030049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E584',
    cap: ['33054'],
    popolazione: 6447,
  },
  {
    nome: 'Lillianes',
    codice: '007042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'E587',
    cap: ['11020'],
    popolazione: 465,
  },
  {
    nome: 'Limana',
    codice: '025029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E588',
    cap: ['32020'],
    popolazione: 5061,
  },
  {
    nome: 'Limatola',
    codice: '062038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'E589',
    cap: ['82030'],
    popolazione: 4077,
  },
  {
    nome: 'Limbadi',
    codice: '102019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'E590',
    cap: ['89844'],
    popolazione: 3498,
  },
  {
    nome: 'Limbiate',
    codice: '108027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E591',
    cap: ['20812'],
    popolazione: 33903,
  },
  {
    nome: 'Limena',
    codice: '028045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'E592',
    cap: ['35010'],
    popolazione: 7699,
  },
  {
    nome: 'Limido Comasco',
    codice: '013128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E593',
    cap: ['22070'],
    popolazione: 3793,
  },
  {
    nome: 'Limina',
    codice: '083040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E594',
    cap: ['98030'],
    popolazione: 900,
  },
  {
    nome: 'Limone Piemonte',
    codice: '004110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E597',
    cap: ['12015'],
    popolazione: 1490,
  },
  {
    nome: 'Limone sul Garda',
    codice: '017089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E596',
    cap: ['25010'],
    popolazione: 1151,
  },
  {
    nome: 'Limosano',
    codice: '070032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E599',
    cap: ['86022'],
    popolazione: 826,
  },
  {
    nome: 'Linarolo',
    codice: '018081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E600',
    cap: ['27010'],
    popolazione: 2754,
  },
  {
    nome: 'Linguaglossa',
    codice: '087021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'E602',
    cap: ['95015'],
    popolazione: 5416,
  },
  {
    nome: 'Lioni',
    codice: '064044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E605',
    cap: ['83047'],
    popolazione: 6335,
  },
  {
    nome: 'Lipari',
    codice: '083041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E606',
    cap: ['98055'],
    popolazione: 11642,
  },
  {
    nome: 'Lipomo',
    codice: '013129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E607',
    cap: ['22030'],
    popolazione: 5800,
  },
  {
    nome: 'Lirio',
    codice: '018082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E608',
    cap: ['27040'],
    popolazione: 136,
  },
  {
    nome: 'Liscate',
    codice: '015122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E610',
    cap: ['20060'],
    popolazione: 4050,
  },
  {
    nome: 'Liscia',
    codice: '069049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E611',
    cap: ['66050'],
    popolazione: 712,
  },
  {
    nome: 'Lisciano Niccone',
    codice: '054025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E613',
    cap: ['06060'],
    popolazione: 624,
  },
  {
    nome: 'Lisio',
    codice: '004111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E615',
    cap: ['12070'],
    popolazione: 214,
  },
  {
    nome: 'Lissone',
    codice: '108028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E617',
    cap: ['20851'],
    popolazione: 42220,
  },
  {
    nome: 'Liveri',
    codice: '063040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E620',
    cap: ['80030'],
    popolazione: 1679,
  },
  {
    nome: 'Livigno',
    codice: '014037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E621',
    cap: ['23030'],
    popolazione: 5976,
  },
  {
    nome: 'Livinallongo del Col di Lana',
    codice: '025030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E622',
    cap: ['32020'],
    popolazione: 1384,
  },
  {
    nome: 'Livo',
    codice: '013130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E623',
    cap: ['22010'],
    popolazione: 190,
  },
  {
    nome: 'Livo',
    codice: '022106',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E624',
    cap: ['38020'],
    popolazione: 887,
  },
  {
    nome: 'Livorno',
    codice: '049009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'E625',
    cap: ['57121', '57122', '57123', '57124', '57125', '57126', '57127', '57128'],
    popolazione: 157052,
  },
  {
    nome: 'Livorno Ferraris',
    codice: '002071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E626',
    cap: ['13046'],
    popolazione: 4450,
  },
  {
    nome: 'Livraga',
    codice: '098030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E627',
    cap: ['26814'],
    popolazione: 2602,
  },
  {
    nome: 'Lizzanello',
    codice: '075038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E629',
    cap: ['73023'],
    popolazione: 11549,
  },
  {
    nome: 'Lizzano',
    codice: '073011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E630',
    cap: ['74020'],
    popolazione: 10238,
  },
  {
    nome: 'Lizzano in Belvedere',
    codice: '037033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'A771',
    cap: ['40042'],
    popolazione: 2309,
  },
  {
    nome: 'Loano',
    codice: '009034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'E632',
    cap: ['17025'],
    popolazione: 11563,
  },
  {
    nome: 'Loazzolo',
    codice: '005060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E633',
    cap: ['14051'],
    popolazione: 337,
  },
  {
    nome: 'Locana',
    codice: '001134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E635',
    cap: ['10080'],
    popolazione: 1601,
  },
  {
    nome: 'Locate di Triulzi',
    codice: '015125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E639',
    cap: ['20085'],
    popolazione: 9655,
  },
  {
    nome: 'Locate Varesino',
    codice: '013131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E638',
    cap: ['22070'],
    popolazione: 4216,
  },
  {
    nome: 'Locatello',
    codice: '016127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E640',
    cap: ['24030'],
    popolazione: 819,
  },
  {
    nome: 'Loceri',
    codice: '091039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E644',
    cap: ['08040'],
    popolazione: 1278,
  },
  {
    nome: 'Locorotondo',
    codice: '072025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'E645',
    cap: ['70010'],
    popolazione: 14161,
  },
  {
    nome: 'Locri',
    codice: '080043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D976',
    cap: ['89044'],
    popolazione: 12459,
  },
  {
    nome: 'Loculi',
    codice: '091040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E646',
    cap: ['08020'],
    popolazione: 515,
  },
  {
    nome: 'Lodè',
    codice: '091041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E647',
    cap: ['08020'],
    popolazione: 1894,
  },
  {
    nome: 'Lodi',
    codice: '098031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E648',
    cap: ['26900'],
    popolazione: 43332,
  },
  {
    nome: 'Lodi Vecchio',
    codice: '098032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E651',
    cap: ['26855'],
    popolazione: 7356,
  },
  {
    nome: 'Lodine',
    codice: '091104',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E649',
    cap: ['08020'],
    popolazione: 358,
  },
  {
    nome: 'Lodrino',
    codice: '017090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E652',
    cap: ['25060'],
    popolazione: 1750,
  },
  {
    nome: 'Lograto',
    codice: '017091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E654',
    cap: ['25030'],
    popolazione: 3793,
  },
  {
    nome: 'Loiano',
    codice: '037034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'E655',
    cap: ['40050'],
    popolazione: 4434,
  },
  {
    nome: 'Loiri Porto San Paolo',
    codice: '090084',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M275',
    cap: ['07020'],
    popolazione: 3234,
  },
  {
    nome: 'Lomagna',
    codice: '097044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E656',
    cap: ['23871'],
    popolazione: 4899,
  },
  {
    nome: 'Lomazzo',
    codice: '013133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E659',
    cap: ['22074'],
    popolazione: 9194,
  },
  {
    nome: 'Lombardore',
    codice: '001135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E660',
    cap: ['10040'],
    popolazione: 1706,
  },
  {
    nome: 'Lombriasco',
    codice: '001136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E661',
    cap: ['10040'],
    popolazione: 1056,
  },
  {
    nome: 'Lomello',
    codice: '018083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E662',
    cap: ['27034'],
    popolazione: 2295,
  },
  {
    nome: 'Lona-Lases',
    codice: '022108',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E664',
    cap: ['38040'],
    popolazione: 876,
  },
  {
    nome: 'Lonate Ceppino',
    codice: '012089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E665',
    cap: ['21050'],
    popolazione: 4860,
  },
  {
    nome: 'Lonate Pozzolo',
    codice: '012090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E666',
    cap: ['21015'],
    popolazione: 11748,
  },
  {
    nome: 'Lonato del Garda',
    codice: '017092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'M312',
    cap: ['25017'],
    popolazione: 15559,
  },
  {
    nome: 'Londa',
    codice: '048025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'E668',
    cap: ['50060'],
    popolazione: 1827,
  },
  {
    nome: 'Longano',
    codice: '094024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'E669',
    cap: ['86090'],
    popolazione: 697,
  },
  {
    nome: 'Longare',
    codice: '024051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E671',
    cap: ['36023'],
    popolazione: 5654,
  },
  {
    nome: 'Longarone',
    codice: '025071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M342',
    cap: ['32013'],
    popolazione: 5555,
  },
  {
    nome: 'Longhena',
    codice: '017093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E673',
    cap: ['25030'],
    popolazione: 607,
  },
  {
    nome: 'Longi',
    codice: '083042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E674',
    cap: ['98070'],
    popolazione: 1562,
  },
  {
    nome: 'Longiano',
    codice: '040018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'E675',
    cap: ['47020'],
    popolazione: 6837,
  },
  {
    nome: 'Longobardi',
    codice: '078067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E677',
    cap: ['87030'],
    popolazione: 2256,
  },
  {
    nome: 'Longobucco',
    codice: '078068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E678',
    cap: ['87066'],
    popolazione: 3479,
  },
  {
    nome: 'Longone al Segrino',
    codice: '013134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E679',
    cap: ['22030'],
    popolazione: 1780,
  },
  {
    nome: 'Longone Sabino',
    codice: '057034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'E681',
    cap: ['02020'],
    popolazione: 583,
  },
  {
    nome: 'Lonigo',
    codice: '024052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E682',
    cap: ['36045'],
    popolazione: 15581,
  },
  {
    nome: 'Loranzè',
    codice: '001137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E683',
    cap: ['10010'],
    popolazione: 1126,
  },
  {
    nome: 'Loreggia',
    codice: '028046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'E684',
    cap: ['35010'],
    popolazione: 7297,
  },
  {
    nome: 'Loreglia',
    codice: '103038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E685',
    cap: ['28893'],
    popolazione: 262,
  },
  {
    nome: 'Lorenzago di Cadore',
    codice: '025032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E687',
    cap: ['32040'],
    popolazione: 567,
  },
  {
    nome: 'Loreo',
    codice: '029030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'E689',
    cap: ['45017'],
    popolazione: 3568,
  },
  {
    nome: 'Loreto',
    codice: '042022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'E690',
    cap: ['60025'],
    popolazione: 12533,
  },
  {
    nome: 'Loreto Aprutino',
    codice: '068021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'E691',
    cap: ['65014'],
    popolazione: 7619,
  },
  {
    nome: 'Loria',
    codice: '026036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E692',
    cap: ['31037'],
    popolazione: 9097,
  },
  {
    nome: 'Loro Ciuffenna',
    codice: '051020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'E693',
    cap: ['52024'],
    popolazione: 5892,
  },
  {
    nome: 'Loro Piceno',
    codice: '043022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'E694',
    cap: ['62020'],
    popolazione: 2481,
  },
  {
    nome: 'Lorsica',
    codice: '010030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'E695',
    cap: ['16045'],
    popolazione: 519,
  },
  {
    nome: 'Losine',
    codice: '017094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E698',
    cap: ['25050'],
    popolazione: 591,
  },
  {
    nome: 'Lotzorai',
    codice: '091042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E700',
    cap: ['08040'],
    popolazione: 2151,
  },
  {
    nome: 'Lovere',
    codice: '016128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E704',
    cap: ['24065'],
    popolazione: 5318,
  },
  {
    nome: 'Lovero',
    codice: '014038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E705',
    cap: ['23030'],
    popolazione: 667,
  },
  {
    nome: 'Lozio',
    codice: '017095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E706',
    cap: ['25040'],
    popolazione: 418,
  },
  {
    nome: 'Lozza',
    codice: '012091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E707',
    cap: ['21040'],
    popolazione: 1237,
  },
  {
    nome: 'Lozzo Atestino',
    codice: '028047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'E709',
    cap: ['35034'],
    popolazione: 3179,
  },
  {
    nome: 'Lozzo di Cadore',
    codice: '025033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'E708',
    cap: ['32040'],
    popolazione: 1497,
  },
  {
    nome: 'Lozzolo',
    codice: '002072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'E711',
    cap: ['13045'],
    popolazione: 819,
  },
  {
    nome: 'Lu e Cuccaro Monferrato',
    codice: '006193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M420',
    cap: ['15037'],
    popolazione: 1520,
  },
  {
    nome: 'Lubriano',
    codice: '056033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E713',
    cap: ['01020'],
    popolazione: 919,
  },
  {
    nome: 'Lucca',
    codice: '046017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'E715',
    cap: ['55100'],
    popolazione: 87200,
  },
  {
    nome: 'Lucca Sicula',
    codice: '084022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'E714',
    cap: ['92010'],
    popolazione: 1917,
  },
  {
    nome: 'Lucera',
    codice: '071028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'E716',
    cap: ['71036'],
    popolazione: 34333,
  },
  {
    nome: 'Lucignano',
    codice: '051021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'E718',
    cap: ['52046'],
    popolazione: 3615,
  },
  {
    nome: 'Lucinasco',
    codice: '008033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'E719',
    cap: ['18020'],
    popolazione: 280,
  },
  {
    nome: 'Lucito',
    codice: '070033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E722',
    cap: ['86030'],
    popolazione: 734,
  },
  {
    nome: 'Luco dei Marsi',
    codice: '066051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E723',
    cap: ['67056'],
    popolazione: 5868,
  },
  {
    nome: 'Lucoli',
    codice: '066052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E724',
    cap: ['67045'],
    popolazione: 1019,
  },
  {
    nome: "Lugagnano Val d'Arda",
    codice: '033026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'E726',
    cap: ['29018'],
    popolazione: 4155,
  },
  {
    nome: 'Lugnano in Teverina',
    codice: '055016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'E729',
    cap: ['05020'],
    popolazione: 1539,
  },
  {
    nome: 'Lugo',
    codice: '039012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'E730',
    cap: ['48022'],
    popolazione: 32062,
  },
  {
    nome: 'Lugo di Vicenza',
    codice: '024053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E731',
    cap: ['36030'],
    popolazione: 3739,
  },
  {
    nome: 'Luino',
    codice: '012092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E734',
    cap: ['21016'],
    popolazione: 14276,
  },
  {
    nome: 'Luisago',
    codice: '013135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E735',
    cap: ['22070'],
    popolazione: 2686,
  },
  {
    nome: 'Lula',
    codice: '091043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E736',
    cap: ['08020'],
    popolazione: 1495,
  },
  {
    nome: 'Lumarzo',
    codice: '010031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'E737',
    cap: ['16024'],
    popolazione: 1594,
  },
  {
    nome: 'Lumezzane',
    codice: '017096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E738',
    cap: ['25065'],
    popolazione: 23390,
  },
  {
    nome: 'Lunamatrona',
    codice: '111038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E742',
    cap: ['09022'],
    popolazione: 1783,
  },
  {
    nome: 'Lunano',
    codice: '041022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'E743',
    cap: ['61026'],
    popolazione: 1528,
  },
  {
    nome: 'Lungavilla',
    codice: '018084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B387',
    cap: ['27053'],
    popolazione: 2412,
  },
  {
    nome: 'Lungro',
    codice: '078069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E745',
    cap: ['87010'],
    popolazione: 2517,
  },
  {
    nome: 'Luni',
    codice: '011020',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'G143',
    cap: ['19034'],
    popolazione: 8405,
  },
  {
    nome: 'Luogosano',
    codice: '064045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E746',
    cap: ['83040'],
    popolazione: 1238,
  },
  {
    nome: 'Luogosanto',
    codice: '090036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E747',
    cap: ['07020'],
    popolazione: 1905,
  },
  {
    nome: 'Lupara',
    codice: '070034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E748',
    cap: ['86030'],
    popolazione: 538,
  },
  {
    nome: "Lurago d'Erba",
    codice: '013136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E749',
    cap: ['22040'],
    popolazione: 5335,
  },
  {
    nome: 'Lurago Marinone',
    codice: '013137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E750',
    cap: ['22070'],
    popolazione: 2456,
  },
  {
    nome: 'Lurano',
    codice: '016129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E751',
    cap: ['24050'],
    popolazione: 2580,
  },
  {
    nome: 'Luras',
    codice: '090037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E752',
    cap: ['07025'],
    popolazione: 2651,
  },
  {
    nome: 'Lurate Caccivio',
    codice: '013138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E753',
    cap: ['22075'],
    popolazione: 9922,
  },
  {
    nome: 'Lusciano',
    codice: '061046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E754',
    cap: ['81030'],
    popolazione: 14539,
  },
  {
    nome: 'Luserna',
    codice: '022109',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E757',
    cap: ['38040'],
    popolazione: 279,
  },
  {
    nome: 'Luserna San Giovanni',
    codice: '001139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E758',
    cap: ['10062'],
    popolazione: 7531,
  },
  {
    nome: 'Lusernetta',
    codice: '001140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E759',
    cap: ['10060'],
    popolazione: 524,
  },
  {
    nome: 'Lusevera',
    codice: '030051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E760',
    cap: ['33010'],
    popolazione: 700,
  },
  {
    nome: 'Lusia',
    codice: '029031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'E761',
    cap: ['45020'],
    popolazione: 3595,
  },
  {
    nome: 'Lusiana Conco',
    codice: '024127',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M427',
    cap: ['36046'],
    popolazione: 4946,
  },
  {
    nome: 'Lusigliè',
    codice: '001141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E763',
    cap: ['10080'],
    popolazione: 556,
  },
  {
    nome: 'Luson',
    codice: '021044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E764',
    cap: ['39040'],
    popolazione: 1527,
  },
  {
    nome: 'Lustra',
    codice: '065064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E767',
    cap: ['84050'],
    popolazione: 1100,
  },
  {
    nome: 'Luvinate',
    codice: '012093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E769',
    cap: ['21020'],
    popolazione: 1309,
  },
  {
    nome: 'Luzzana',
    codice: '016130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E770',
    cap: ['24069'],
    popolazione: 891,
  },
  {
    nome: 'Luzzara',
    codice: '035026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'E772',
    cap: ['42045'],
    popolazione: 9169,
  },
  {
    nome: 'Luzzi',
    codice: '078070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E773',
    cap: ['87040'],
    popolazione: 9568,
  },
  {
    nome: 'Maccagno con Pino e Veddasca',
    codice: '012142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'M339',
    cap: ['21061'],
    popolazione: 2444,
  },
  {
    nome: 'Maccastorna',
    codice: '098033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E777',
    cap: ['26843'],
    popolazione: 60,
  },
  {
    nome: "Macchia d'Isernia",
    codice: '094025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'E778',
    cap: ['86070'],
    popolazione: 979,
  },
  {
    nome: 'Macchia Valfortore',
    codice: '070035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E780',
    cap: ['86040'],
    popolazione: 621,
  },
  {
    nome: 'Macchiagodena',
    codice: '094026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'E779',
    cap: ['86096'],
    popolazione: 1844,
  },
  {
    nome: 'Macello',
    codice: '001142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E782',
    cap: ['10060'],
    popolazione: 1238,
  },
  {
    nome: 'Macerata',
    codice: '043023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'E783',
    cap: ['62100'],
    popolazione: 42019,
  },
  {
    nome: 'Macerata Campania',
    codice: '061047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E784',
    cap: ['81047'],
    popolazione: 10558,
  },
  {
    nome: 'Macerata Feltria',
    codice: '041023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'E785',
    cap: ['61023'],
    popolazione: 2072,
  },
  {
    nome: 'Macherio',
    codice: '108029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'E786',
    cap: ['20846'],
    popolazione: 7130,
  },
  {
    nome: 'Maclodio',
    codice: '017097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E787',
    cap: ['25030'],
    popolazione: 1501,
  },
  {
    nome: 'Macomer',
    codice: '091044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E788',
    cap: ['08015'],
    popolazione: 10511,
  },
  {
    nome: 'Macra',
    codice: '004112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E789',
    cap: ['12020'],
    popolazione: 52,
  },
  {
    nome: 'Macugnaga',
    codice: '103039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E790',
    cap: ['28876'],
    popolazione: 601,
  },
  {
    nome: 'Maddaloni',
    codice: '061048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E791',
    cap: ['81024'],
    popolazione: 39409,
  },
  {
    nome: 'Madesimo',
    codice: '014035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E342',
    cap: ['23024'],
    popolazione: 540,
  },
  {
    nome: 'Madignano',
    codice: '019055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E793',
    cap: ['26020'],
    popolazione: 2931,
  },
  {
    nome: 'Madone',
    codice: '016131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E794',
    cap: ['24040'],
    popolazione: 3943,
  },
  {
    nome: 'Madonna del Sasso',
    codice: '103040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E795',
    cap: ['28894'],
    popolazione: 396,
  },
  {
    nome: 'Madruzzo',
    codice: '022243',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M357',
    cap: ['38076'],
    popolazione: 2783,
  },
  {
    nome: 'Maenza',
    codice: '059013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'E798',
    cap: ['04010'],
    popolazione: 3078,
  },
  {
    nome: 'Mafalda',
    codice: '070036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'E799',
    cap: ['86030'],
    popolazione: 1231,
  },
  {
    nome: 'Magasa',
    codice: '017098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E800',
    cap: ['25080'],
    popolazione: 145,
  },
  {
    nome: 'Magenta',
    codice: '015130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E801',
    cap: ['20013'],
    popolazione: 22877,
  },
  {
    nome: 'Maggiora',
    codice: '003088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E803',
    cap: ['28014'],
    popolazione: 1742,
  },
  {
    nome: 'Magherno',
    codice: '018085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E804',
    cap: ['27010'],
    popolazione: 1690,
  },
  {
    nome: 'Magione',
    codice: '054026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E805',
    cap: ['06063'],
    popolazione: 14589,
  },
  {
    nome: 'Magisano',
    codice: '079068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E806',
    cap: ['88050'],
    popolazione: 1272,
  },
  {
    nome: 'Magliano Alfieri',
    codice: '004113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E809',
    cap: ['12050'],
    popolazione: 2026,
  },
  {
    nome: 'Magliano Alpi',
    codice: '004114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E808',
    cap: ['12060'],
    popolazione: 2231,
  },
  {
    nome: "Magliano de' Marsi",
    codice: '066053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'E811',
    cap: ['67062'],
    popolazione: 3753,
  },
  {
    nome: 'Magliano di Tenna',
    codice: '109010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'E807',
    cap: ['63832'],
    popolazione: 1426,
  },
  {
    nome: 'Magliano in Toscana',
    codice: '053013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'E810',
    cap: ['58051'],
    popolazione: 3633,
  },
  {
    nome: 'Magliano Romano',
    codice: '058052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E813',
    cap: ['00060'],
    popolazione: 1470,
  },
  {
    nome: 'Magliano Sabina',
    codice: '057035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'E812',
    cap: ['02046'],
    popolazione: 3799,
  },
  {
    nome: 'Magliano Vetere',
    codice: '065065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E814',
    cap: ['84050'],
    popolazione: 739,
  },
  {
    nome: 'Maglie',
    codice: '075039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E815',
    cap: ['73024'],
    popolazione: 14819,
  },
  {
    nome: 'Magliolo',
    codice: '009035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'E816',
    cap: ['17020'],
    popolazione: 917,
  },
  {
    nome: 'Maglione',
    codice: '001143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E817',
    cap: ['10030'],
    popolazione: 468,
  },
  {
    nome: 'Magnacavallo',
    codice: '020029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E818',
    cap: ['46020'],
    popolazione: 1669,
  },
  {
    nome: 'Magnago',
    codice: '015131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E819',
    cap: ['20020'],
    popolazione: 9085,
  },
  {
    nome: 'Magnano',
    codice: '096030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'E821',
    cap: ['13887'],
    popolazione: 378,
  },
  {
    nome: 'Magnano in Riviera',
    codice: '030052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E820',
    cap: ['33010'],
    popolazione: 2366,
  },
  {
    nome: 'Magomadas',
    codice: '095083',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'E825',
    cap: ['09090'],
    popolazione: 643,
  },
  {
    nome: 'Magrè sulla strada del vino',
    codice: '021045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E829',
    cap: ['39040'],
    popolazione: 1281,
  },
  {
    nome: 'Magreglio',
    codice: '013139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E830',
    cap: ['22030'],
    popolazione: 645,
  },
  {
    nome: 'Maida',
    codice: '079069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E834',
    cap: ['88025'],
    popolazione: 4457,
  },
  {
    nome: 'Maierà',
    codice: '078071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E835',
    cap: ['87020'],
    popolazione: 1231,
  },
  {
    nome: 'Maierato',
    codice: '102020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'E836',
    cap: ['89843'],
    popolazione: 2198,
  },
  {
    nome: 'Maiolati Spontini',
    codice: '042023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'E837',
    cap: ['60030'],
    popolazione: 6175,
  },
  {
    nome: 'Maiolo',
    codice: '099022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'E838',
    cap: ['47862'],
    popolazione: 848,
  },
  {
    nome: 'Maiori',
    codice: '065066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'E839',
    cap: ['84010'],
    popolazione: 5575,
  },
  {
    nome: 'Mairago',
    codice: '098034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E840',
    cap: ['26825'],
    popolazione: 1397,
  },
  {
    nome: 'Mairano',
    codice: '017099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E841',
    cap: ['25030'],
    popolazione: 3329,
  },
  {
    nome: 'Maissana',
    codice: '011018',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'E842',
    cap: ['19010'],
    popolazione: 659,
  },
  {
    nome: 'Majano',
    codice: '030053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E833',
    cap: ['33030'],
    popolazione: 6051,
  },
  {
    nome: 'Malagnino',
    codice: '019056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E843',
    cap: ['26030'],
    popolazione: 1525,
  },
  {
    nome: 'Malalbergo',
    codice: '037035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'E844',
    cap: ['40051'],
    popolazione: 8771,
  },
  {
    nome: 'Malborghetto Valbruna',
    codice: '030054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E847',
    cap: ['33010'],
    popolazione: 969,
  },
  {
    nome: 'Malcesine',
    codice: '023045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E848',
    cap: ['37018'],
    popolazione: 3685,
  },
  {
    nome: 'Malé',
    codice: '022110',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'E850',
    cap: ['38027'],
    popolazione: 2135,
  },
  {
    nome: 'Malegno',
    codice: '017100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E851',
    cap: ['25053'],
    popolazione: 2078,
  },
  {
    nome: 'Maleo',
    codice: '098035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E852',
    cap: ['26847'],
    popolazione: 3249,
  },
  {
    nome: 'Malesco',
    codice: '103041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'E853',
    cap: ['28854'],
    popolazione: 1465,
  },
  {
    nome: 'Maletto',
    codice: '087022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'E854',
    cap: ['95035'],
    popolazione: 4015,
  },
  {
    nome: 'Malfa',
    codice: '083043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E855',
    cap: ['98050'],
    popolazione: 988,
  },
  {
    nome: 'Malgesso',
    codice: '012095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E856',
    cap: ['21020'],
    popolazione: 1298,
  },
  {
    nome: 'Malgrate',
    codice: '097045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E858',
    cap: ['23864'],
    popolazione: 4216,
  },
  {
    nome: 'Malito',
    codice: '078072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E859',
    cap: ['87030'],
    popolazione: 812,
  },
  {
    nome: 'Mallare',
    codice: '009036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'E860',
    cap: ['17045'],
    popolazione: 1200,
  },
  {
    nome: 'Malles Venosta',
    codice: '021046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E862',
    cap: ['39024'],
    popolazione: 5086,
  },
  {
    nome: 'Malnate',
    codice: '012096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E863',
    cap: ['21046'],
    popolazione: 16604,
  },
  {
    nome: 'Malo',
    codice: '024055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E864',
    cap: ['36034'],
    popolazione: 14641,
  },
  {
    nome: 'Malonno',
    codice: '017101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E865',
    cap: ['25040'],
    popolazione: 3320,
  },
  {
    nome: 'Maltignano',
    codice: '044027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'E868',
    cap: ['63085'],
    popolazione: 2483,
  },
  {
    nome: 'Malvagna',
    codice: '083044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E869',
    cap: ['98030'],
    popolazione: 794,
  },
  {
    nome: 'Malvicino',
    codice: '006090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'E870',
    cap: ['15015'],
    popolazione: 84,
  },
  {
    nome: 'Malvito',
    codice: '078073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E872',
    cap: ['87010'],
    popolazione: 1867,
  },
  {
    nome: 'Mammola',
    codice: '080044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E873',
    cap: ['89045'],
    popolazione: 2971,
  },
  {
    nome: 'Mamoiada',
    codice: '091046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'E874',
    cap: ['08024'],
    popolazione: 2559,
  },
  {
    nome: 'Manciano',
    codice: '053014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'E875',
    cap: ['58014'],
    popolazione: 7259,
  },
  {
    nome: 'Mandanici',
    codice: '083045',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'E876',
    cap: ['98020'],
    popolazione: 629,
  },
  {
    nome: 'Mandas',
    codice: '111039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'E877',
    cap: ['09040'],
    popolazione: 2238,
  },
  {
    nome: 'Mandatoriccio',
    codice: '078074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E878',
    cap: ['87060'],
    popolazione: 2900,
  },
  {
    nome: 'Mandela',
    codice: '058053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'B632',
    cap: ['00020'],
    popolazione: 897,
  },
  {
    nome: 'Mandello del Lario',
    codice: '097046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E879',
    cap: ['23826'],
    popolazione: 10578,
  },
  {
    nome: 'Mandello Vitta',
    codice: '003090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E880',
    cap: ['28060'],
    popolazione: 244,
  },
  {
    nome: 'Manduria',
    codice: '073012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E882',
    cap: ['74024'],
    popolazione: 30921,
  },
  {
    nome: 'Manerba del Garda',
    codice: '017102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E883',
    cap: ['25080'],
    popolazione: 4902,
  },
  {
    nome: 'Manerbio',
    codice: '017103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E884',
    cap: ['25025'],
    popolazione: 12869,
  },
  {
    nome: 'Manfredonia',
    codice: '071029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'E885',
    cap: ['71043'],
    popolazione: 56257,
  },
  {
    nome: 'Mango',
    codice: '004115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E887',
    cap: ['12056'],
    popolazione: 1334,
  },
  {
    nome: 'Mangone',
    codice: '078075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E888',
    cap: ['87050'],
    popolazione: 1823,
  },
  {
    nome: 'Maniace',
    codice: '087057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'M283',
    cap: ['95030'],
    popolazione: 3671,
  },
  {
    nome: 'Maniago',
    codice: '093025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'E889',
    cap: ['33085'],
    popolazione: 11818,
  },
  {
    nome: 'Manocalzati',
    codice: '064046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E891',
    cap: ['83030'],
    popolazione: 3234,
  },
  {
    nome: 'Manoppello',
    codice: '068022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'E892',
    cap: ['65024'],
    popolazione: 7008,
  },
  {
    nome: 'Mansuè',
    codice: '026037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E893',
    cap: ['31040'],
    popolazione: 4974,
  },
  {
    nome: 'Manta',
    codice: '004116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E894',
    cap: ['12030'],
    popolazione: 3735,
  },
  {
    nome: 'Mantello',
    codice: '014039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'E896',
    cap: ['23016'],
    popolazione: 732,
  },
  {
    nome: 'Mantova',
    codice: '020030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E897',
    cap: ['46100'],
    popolazione: 46649,
  },
  {
    nome: 'Manzano',
    codice: '030055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E899',
    cap: ['33044'],
    popolazione: 6581,
  },
  {
    nome: 'Manziana',
    codice: '058054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E900',
    cap: ['00066'],
    popolazione: 7082,
  },
  {
    nome: 'Mapello',
    codice: '016132',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E901',
    cap: ['24030'],
    popolazione: 6453,
  },
  {
    nome: 'Mappano',
    codice: '001316',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M316',
    cap: ['10079'],
    popolazione: 7012,
  },
  {
    nome: 'Mara',
    codice: '090038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E902',
    cap: ['07010'],
    popolazione: 682,
  },
  {
    nome: 'Maracalagonis',
    codice: '092037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'E903',
    cap: ['09069'],
    popolazione: 7523,
  },
  {
    nome: 'Maranello',
    codice: '036019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'E904',
    cap: ['41053'],
    popolazione: 16622,
  },
  {
    nome: 'Marano di Napoli',
    codice: '063041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E906',
    cap: ['80016'],
    popolazione: 57204,
  },
  {
    nome: 'Marano di Valpolicella',
    codice: '023046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'E911',
    cap: ['37020'],
    popolazione: 3083,
  },
  {
    nome: 'Marano Equo',
    codice: '058055',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E908',
    cap: ['00020'],
    popolazione: 786,
  },
  {
    nome: 'Marano Lagunare',
    codice: '030056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E910',
    cap: ['33050'],
    popolazione: 1963,
  },
  {
    nome: 'Marano Marchesato',
    codice: '078076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E914',
    cap: ['87040'],
    popolazione: 3474,
  },
  {
    nome: 'Marano Principato',
    codice: '078077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'E915',
    cap: ['87040'],
    popolazione: 3119,
  },
  {
    nome: 'Marano sul Panaro',
    codice: '036020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'E905',
    cap: ['41054'],
    popolazione: 4787,
  },
  {
    nome: 'Marano Ticino',
    codice: '003091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'E907',
    cap: ['28040'],
    popolazione: 1554,
  },
  {
    nome: 'Marano Vicentino',
    codice: '024056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E912',
    cap: ['36035'],
    popolazione: 9662,
  },
  {
    nome: 'Maranzana',
    codice: '005061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E917',
    cap: ['14040'],
    popolazione: 307,
  },
  {
    nome: 'Maratea',
    codice: '076044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E919',
    cap: ['85046'],
    popolazione: 5150,
  },
  {
    nome: 'Marcallo con Casone',
    codice: '015134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'E921',
    cap: ['20010'],
    popolazione: 6032,
  },
  {
    nome: 'Marcaria',
    codice: '020031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E922',
    cap: ['46010'],
    popolazione: 6913,
  },
  {
    nome: 'Marcedusa',
    codice: '079071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E923',
    cap: ['88050'],
    popolazione: 425,
  },
  {
    nome: 'Marcellina',
    codice: '058056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E924',
    cap: ['00010'],
    popolazione: 6901,
  },
  {
    nome: 'Marcellinara',
    codice: '079072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E925',
    cap: ['88044'],
    popolazione: 2253,
  },
  {
    nome: 'Marcetelli',
    codice: '057036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'E927',
    cap: ['02020'],
    popolazione: 97,
  },
  {
    nome: 'Marcheno',
    codice: '017104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E928',
    cap: ['25060'],
    popolazione: 4359,
  },
  {
    nome: 'Marchirolo',
    codice: '012097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E929',
    cap: ['21030'],
    popolazione: 3381,
  },
  {
    nome: 'Marciana',
    codice: '049010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'E930',
    cap: ['57030'],
    popolazione: 2208,
  },
  {
    nome: 'Marciana Marina',
    codice: '049011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'E931',
    cap: ['57033'],
    popolazione: 1946,
  },
  {
    nome: 'Marcianise',
    codice: '061049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E932',
    cap: ['81025'],
    popolazione: 40297,
  },
  {
    nome: 'Marciano della Chiana',
    codice: '051022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'E933',
    cap: ['52047'],
    popolazione: 3422,
  },
  {
    nome: 'Marcignago',
    codice: '018086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E934',
    cap: ['27020'],
    popolazione: 2440,
  },
  {
    nome: 'Marcon',
    codice: '027020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'E936',
    cap: ['30020'],
    popolazione: 16215,
  },
  {
    nome: 'Marebbe',
    codice: '021047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E938',
    cap: ['39030'],
    popolazione: 2907,
  },
  {
    nome: 'Marene',
    codice: '004117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E939',
    cap: ['12030'],
    popolazione: 3055,
  },
  {
    nome: 'Mareno di Piave',
    codice: '026038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'E940',
    cap: ['31010'],
    popolazione: 9667,
  },
  {
    nome: 'Marentino',
    codice: '001144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'E941',
    cap: ['10020'],
    popolazione: 1383,
  },
  {
    nome: 'Maretto',
    codice: '005062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'E944',
    cap: ['14018'],
    popolazione: 405,
  },
  {
    nome: 'Margarita',
    codice: '004118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E945',
    cap: ['12040'],
    popolazione: 1432,
  },
  {
    nome: 'Margherita di Savoia',
    codice: '110005',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'E946',
    cap: ['76016'],
    popolazione: 12193,
  },
  {
    nome: 'Margno',
    codice: '097047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'E947',
    cap: ['23832'],
    popolazione: 375,
  },
  {
    nome: 'Mariana Mantovana',
    codice: '020032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E949',
    cap: ['46010'],
    popolazione: 729,
  },
  {
    nome: 'Mariano Comense',
    codice: '013143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'E951',
    cap: ['22066'],
    popolazione: 23500,
  },
  {
    nome: 'Mariano del Friuli',
    codice: '031010',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'E952',
    cap: ['34070'],
    popolazione: 1600,
  },
  {
    nome: 'Marianopoli',
    codice: '085008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'E953',
    cap: ['93010'],
    popolazione: 2006,
  },
  {
    nome: 'Mariglianella',
    codice: '063042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E954',
    cap: ['80030'],
    popolazione: 7572,
  },
  {
    nome: 'Marigliano',
    codice: '063043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'E955',
    cap: ['80034'],
    popolazione: 30247,
  },
  {
    nome: 'Marina di Gioiosa Ionica',
    codice: '080045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E956',
    cap: ['89046'],
    popolazione: 6515,
  },
  {
    nome: 'Marineo',
    codice: '082046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'E957',
    cap: ['90035'],
    popolazione: 6779,
  },
  {
    nome: 'Marino',
    codice: '058057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'E958',
    cap: ['00047'],
    popolazione: 38245,
  },
  {
    nome: 'Marlengo',
    codice: '021048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E959',
    cap: ['39020'],
    popolazione: 2533,
  },
  {
    nome: 'Marliana',
    codice: '047007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'E960',
    cap: ['51010'],
    popolazione: 3201,
  },
  {
    nome: 'Marmentino',
    codice: '017105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E961',
    cap: ['25060'],
    popolazione: 676,
  },
  {
    nome: 'Marmirolo',
    codice: '020033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'E962',
    cap: ['46045'],
    popolazione: 7759,
  },
  {
    nome: 'Marmora',
    codice: '004119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E963',
    cap: ['12020'],
    popolazione: 74,
  },
  {
    nome: 'Marnate',
    codice: '012098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'E965',
    cap: ['21050'],
    popolazione: 7299,
  },
  {
    nome: 'Marone',
    codice: '017106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'E967',
    cap: ['25054'],
    popolazione: 3293,
  },
  {
    nome: 'Maropati',
    codice: '080046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E968',
    cap: ['89020'],
    popolazione: 1583,
  },
  {
    nome: 'Marostica',
    codice: '024057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'E970',
    cap: ['36063'],
    popolazione: 13783,
  },
  {
    nome: 'Marradi',
    codice: '048026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'E971',
    cap: ['50034'],
    popolazione: 3257,
  },
  {
    nome: 'Marrubiu',
    codice: '095025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'E972',
    cap: ['09094'],
    popolazione: 4921,
  },
  {
    nome: 'Marsaglia',
    codice: '004120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E973',
    cap: ['12060'],
    popolazione: 261,
  },
  {
    nome: 'Marsala',
    codice: '081011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'E974',
    cap: ['91025'],
    popolazione: 80218,
  },
  {
    nome: 'Marsciano',
    codice: '054027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'E975',
    cap: ['06055'],
    popolazione: 18701,
  },
  {
    nome: 'Marsico Nuovo',
    codice: '076045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E976',
    cap: ['85052'],
    popolazione: 4358,
  },
  {
    nome: 'Marsicovetere',
    codice: '076046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'E977',
    cap: ['85050'],
    popolazione: 5341,
  },
  {
    nome: 'Marta',
    codice: '056034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'E978',
    cap: ['01010'],
    popolazione: 3520,
  },
  {
    nome: 'Martano',
    codice: '075040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E979',
    cap: ['73025'],
    popolazione: 9320,
  },
  {
    nome: 'Martellago',
    codice: '027021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'E980',
    cap: ['30030'],
    popolazione: 21171,
  },
  {
    nome: 'Martello',
    codice: '021049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'E981',
    cap: ['39020'],
    popolazione: 879,
  },
  {
    nome: 'Martignacco',
    codice: '030057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'E982',
    cap: ['33035'],
    popolazione: 6796,
  },
  {
    nome: 'Martignana di Po',
    codice: '019057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'E983',
    cap: ['26040'],
    popolazione: 1893,
  },
  {
    nome: 'Martignano',
    codice: '075041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'E984',
    cap: ['73020'],
    popolazione: 1730,
  },
  {
    nome: 'Martina Franca',
    codice: '073013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E986',
    cap: ['74015'],
    popolazione: 49009,
  },
  {
    nome: 'Martinengo',
    codice: '016133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'E987',
    cap: ['24057'],
    popolazione: 10088,
  },
  {
    nome: 'Martiniana Po',
    codice: '004121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'E988',
    cap: ['12030'],
    popolazione: 781,
  },
  {
    nome: 'Martinsicuro',
    codice: '067047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'E989',
    cap: ['64014'],
    popolazione: 15484,
  },
  {
    nome: 'Martirano',
    codice: '079073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E990',
    cap: ['88040'],
    popolazione: 937,
  },
  {
    nome: 'Martirano Lombardo',
    codice: '079074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'E991',
    cap: ['88040'],
    popolazione: 1167,
  },
  {
    nome: 'Martis',
    codice: '090039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'E992',
    cap: ['07030'],
    popolazione: 553,
  },
  {
    nome: 'Martone',
    codice: '080047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'E993',
    cap: ['89040'],
    popolazione: 554,
  },
  {
    nome: 'Marudo',
    codice: '098036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'E994',
    cap: ['26866'],
    popolazione: 1568,
  },
  {
    nome: 'Maruggio',
    codice: '073014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'E995',
    cap: ['74020'],
    popolazione: 5411,
  },
  {
    nome: 'Marzabotto',
    codice: '037036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'B689',
    cap: ['40043'],
    popolazione: 6684,
  },
  {
    nome: 'Marzano',
    codice: '018087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E999',
    cap: ['27010'],
    popolazione: 1559,
  },
  {
    nome: 'Marzano Appio',
    codice: '061050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'E998',
    cap: ['81035'],
    popolazione: 2345,
  },
  {
    nome: 'Marzano di Nola',
    codice: '064047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'E997',
    cap: ['83020'],
    popolazione: 1680,
  },
  {
    nome: 'Marzi',
    codice: '078078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F001',
    cap: ['87050'],
    popolazione: 996,
  },
  {
    nome: 'Marzio',
    codice: '012099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F002',
    cap: ['21030'],
    popolazione: 303,
  },
  {
    nome: 'Masainas',
    codice: '111040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M270',
    cap: ['09010'],
    popolazione: 1350,
  },
  {
    nome: 'Masate',
    codice: '015136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F003',
    cap: ['20060'],
    popolazione: 3312,
  },
  {
    nome: 'Mascali',
    codice: '087023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F004',
    cap: ['95016'],
    popolazione: 13792,
  },
  {
    nome: 'Mascalucia',
    codice: '087024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F005',
    cap: ['95030'],
    popolazione: 29984,
  },
  {
    nome: 'Maschito',
    codice: '076047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F006',
    cap: ['85020'],
    popolazione: 1730,
  },
  {
    nome: 'Masciago Primo',
    codice: '012100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F007',
    cap: ['21030'],
    popolazione: 290,
  },
  {
    nome: 'Maser',
    codice: '026039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F009',
    cap: ['31010'],
    popolazione: 4962,
  },
  {
    nome: 'Masera',
    codice: '103042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F010',
    cap: ['28855'],
    popolazione: 1527,
  },
  {
    nome: 'Maserà di Padova',
    codice: '028048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F011',
    cap: ['35020'],
    popolazione: 9045,
  },
  {
    nome: 'Maserada sul Piave',
    codice: '026040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F012',
    cap: ['31052'],
    popolazione: 9293,
  },
  {
    nome: 'Masi',
    codice: '028049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F013',
    cap: ['35040'],
    popolazione: 1782,
  },
  {
    nome: 'Masi Torello',
    codice: '038012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'F016',
    cap: ['44020'],
    popolazione: 2368,
  },
  {
    nome: 'Masio',
    codice: '006091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F015',
    cap: ['15024'],
    popolazione: 1465,
  },
  {
    nome: 'Maslianico',
    codice: '013144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F017',
    cap: ['22026'],
    popolazione: 3305,
  },
  {
    nome: 'Masone',
    codice: '010032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F020',
    cap: ['16010'],
    popolazione: 3758,
  },
  {
    nome: 'Massa',
    codice: '045010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'F023',
    cap: ['54100'],
    popolazione: 68856,
  },
  {
    nome: "Massa d'Albe",
    codice: '066054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'F022',
    cap: ['67050'],
    popolazione: 1509,
  },
  {
    nome: 'Massa di Somma',
    codice: '063092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'M289',
    cap: ['80040'],
    popolazione: 5587,
  },
  {
    nome: 'Massa e Cozzile',
    codice: '047008',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'F025',
    cap: ['51010'],
    popolazione: 7786,
  },
  {
    nome: 'Massa Fermana',
    codice: '109011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F021',
    cap: ['63834'],
    popolazione: 1002,
  },
  {
    nome: 'Massa Lombarda',
    codice: '039013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'F029',
    cap: ['48024'],
    popolazione: 10449,
  },
  {
    nome: 'Massa Lubrense',
    codice: '063044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F030',
    cap: ['80061'],
    popolazione: 14020,
  },
  {
    nome: 'Massa Marittima',
    codice: '053015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'F032',
    cap: ['58024'],
    popolazione: 8614,
  },
  {
    nome: 'Massa Martana',
    codice: '054028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F024',
    cap: ['06056'],
    popolazione: 3822,
  },
  {
    nome: 'Massafra',
    codice: '073015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'F027',
    cap: ['74016'],
    popolazione: 32381,
  },
  {
    nome: 'Massalengo',
    codice: '098037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'F028',
    cap: ['26815'],
    popolazione: 4192,
  },
  {
    nome: 'Massanzago',
    codice: '028050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F033',
    cap: ['35010'],
    popolazione: 5872,
  },
  {
    nome: 'Massarosa',
    codice: '046018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'F035',
    cap: ['55054'],
    popolazione: 22330,
  },
  {
    nome: 'Massazza',
    codice: '096031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F037',
    cap: ['13873'],
    popolazione: 542,
  },
  {
    nome: 'Massello',
    codice: '001145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F041',
    cap: ['10060'],
    popolazione: 58,
  },
  {
    nome: 'Masserano',
    codice: '096032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F042',
    cap: ['13866'],
    popolazione: 2202,
  },
  {
    nome: 'Massignano',
    codice: '044029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F044',
    cap: ['63061'],
    popolazione: 1655,
  },
  {
    nome: 'Massimeno',
    codice: '022112',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F045',
    cap: ['38086'],
    popolazione: 124,
  },
  {
    nome: 'Massimino',
    codice: '009037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'F046',
    cap: ['12071'],
    popolazione: 121,
  },
  {
    nome: 'Massino Visconti',
    codice: '003093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F047',
    cap: ['28040'],
    popolazione: 1111,
  },
  {
    nome: 'Massiola',
    codice: '103043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F048',
    cap: ['28895'],
    popolazione: 137,
  },
  {
    nome: 'Masullas',
    codice: '095026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F050',
    cap: ['09090'],
    popolazione: 1129,
  },
  {
    nome: 'Matelica',
    codice: '043024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F051',
    cap: ['62024'],
    popolazione: 10178,
  },
  {
    nome: 'Matera',
    codice: '077014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'F052',
    cap: ['75100'],
    popolazione: 59796,
  },
  {
    nome: 'Mathi',
    codice: '001146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F053',
    cap: ['10075'],
    popolazione: 3985,
  },
  {
    nome: 'Matino',
    codice: '075042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F054',
    cap: ['73046'],
    popolazione: 11704,
  },
  {
    nome: 'Matrice',
    codice: '070037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F055',
    cap: ['86030'],
    popolazione: 1110,
  },
  {
    nome: 'Mattie',
    codice: '001147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F058',
    cap: ['10050'],
    popolazione: 707,
  },
  {
    nome: 'Mattinata',
    codice: '071031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'F059',
    cap: ['71030'],
    popolazione: 6360,
  },
  {
    nome: 'Mazara del Vallo',
    codice: '081012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'F061',
    cap: ['91026'],
    popolazione: 49995,
  },
  {
    nome: 'Mazzano',
    codice: '017107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F063',
    cap: ['25080'],
    popolazione: 11487,
  },
  {
    nome: 'Mazzano Romano',
    codice: '058058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F064',
    cap: ['00060'],
    popolazione: 3056,
  },
  {
    nome: 'Mazzarino',
    codice: '085009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'F065',
    cap: ['93013'],
    popolazione: 12333,
  },
  {
    nome: "Mazzarrà Sant'Andrea",
    codice: '083046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F066',
    cap: ['98056'],
    popolazione: 1567,
  },
  {
    nome: 'Mazzarrone',
    codice: '087056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'M271',
    cap: ['95040'],
    popolazione: 3989,
  },
  {
    nome: 'Mazzè',
    codice: '001148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F067',
    cap: ['10035'],
    popolazione: 4152,
  },
  {
    nome: 'Mazzin',
    codice: '022113',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F068',
    cap: ['38030'],
    popolazione: 494,
  },
  {
    nome: 'Mazzo di Valtellina',
    codice: '014040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'F070',
    cap: ['23030'],
    popolazione: 1041,
  },
  {
    nome: 'Meana di Susa',
    codice: '001149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F074',
    cap: ['10050'],
    popolazione: 880,
  },
  {
    nome: 'Meana Sardo',
    codice: '091047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'F073',
    cap: ['08030'],
    popolazione: 1913,
  },
  {
    nome: 'Meda',
    codice: '108030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'F078',
    cap: ['20821'],
    popolazione: 23073,
  },
  {
    nome: 'Mede',
    codice: '018088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F080',
    cap: ['27035'],
    popolazione: 6912,
  },
  {
    nome: 'Medea',
    codice: '031011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'F081',
    cap: ['34076'],
    popolazione: 970,
  },
  {
    nome: 'Medesano',
    codice: '034020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'F082',
    cap: ['43014'],
    popolazione: 10663,
  },
  {
    nome: 'Medicina',
    codice: '037037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F083',
    cap: ['40059'],
    popolazione: 16526,
  },
  {
    nome: 'Mediglia',
    codice: '015139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F084',
    cap: ['20060'],
    popolazione: 12080,
  },
  {
    nome: 'Medolago',
    codice: '016250',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F085',
    cap: ['24030'],
    popolazione: 2340,
  },
  {
    nome: 'Medole',
    codice: '020034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'F086',
    cap: ['46046'],
    popolazione: 4038,
  },
  {
    nome: 'Medolla',
    codice: '036021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F087',
    cap: ['41036'],
    popolazione: 6322,
  },
  {
    nome: 'Meduna di Livenza',
    codice: '026041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F088',
    cap: ['31040'],
    popolazione: 2947,
  },
  {
    nome: 'Meduno',
    codice: '093026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'F089',
    cap: ['33092'],
    popolazione: 1674,
  },
  {
    nome: 'Megliadino San Vitale',
    codice: '028052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F092',
    cap: ['35040'],
    popolazione: 1977,
  },
  {
    nome: 'Meina',
    codice: '003095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F093',
    cap: ['28046'],
    popolazione: 2556,
  },
  {
    nome: 'Melara',
    codice: '029032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'F095',
    cap: ['45037'],
    popolazione: 1870,
  },
  {
    nome: 'Melazzo',
    codice: '006092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F096',
    cap: ['15010'],
    popolazione: 1315,
  },
  {
    nome: 'Meldola',
    codice: '040019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'F097',
    cap: ['47014'],
    popolazione: 10000,
  },
  {
    nome: 'Mele',
    codice: '010033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F098',
    cap: ['16010'],
    popolazione: 2687,
  },
  {
    nome: 'Melegnano',
    codice: '015140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F100',
    cap: ['20077'],
    popolazione: 16774,
  },
  {
    nome: 'Melendugno',
    codice: '075043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F101',
    cap: ['73026'],
    popolazione: 9646,
  },
  {
    nome: 'Meleti',
    codice: '098038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'F102',
    cap: ['26843'],
    popolazione: 466,
  },
  {
    nome: 'Melfi',
    codice: '076048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F104',
    cap: ['85025'],
    popolazione: 17425,
  },
  {
    nome: 'Melicuccà',
    codice: '080048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'F105',
    cap: ['89020'],
    popolazione: 1001,
  },
  {
    nome: 'Melicucco',
    codice: '080049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'F106',
    cap: ['89020'],
    popolazione: 5045,
  },
  {
    nome: 'Melilli',
    codice: '089012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'F107',
    cap: ['96010'],
    popolazione: 13076,
  },
  {
    nome: 'Melissa',
    codice: '101014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'F108',
    cap: ['88814'],
    popolazione: 3529,
  },
  {
    nome: 'Melissano',
    codice: '075044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F109',
    cap: ['73040'],
    popolazione: 7307,
  },
  {
    nome: 'Melito di Napoli',
    codice: '063045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F111',
    cap: ['80017'],
    popolazione: 36933,
  },
  {
    nome: 'Melito di Porto Salvo',
    codice: '080050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'F112',
    cap: ['89063'],
    popolazione: 11115,
  },
  {
    nome: 'Melito Irpino',
    codice: '064048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F110',
    cap: ['83030'],
    popolazione: 1936,
  },
  {
    nome: 'Melizzano',
    codice: '062039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F113',
    cap: ['82030'],
    popolazione: 1892,
  },
  {
    nome: 'Melle',
    codice: '004122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F114',
    cap: ['12020'],
    popolazione: 326,
  },
  {
    nome: 'Mello',
    codice: '014041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'F115',
    cap: ['23010'],
    popolazione: 1001,
  },
  {
    nome: 'Melpignano',
    codice: '075045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F117',
    cap: ['73020'],
    popolazione: 2209,
  },
  {
    nome: 'Meltina',
    codice: '021050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F118',
    cap: ['39010'],
    popolazione: 1648,
  },
  {
    nome: 'Melzo',
    codice: '015142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F119',
    cap: ['20066'],
    popolazione: 18203,
  },
  {
    nome: 'Menaggio',
    codice: '013145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F120',
    cap: ['22017'],
    popolazione: 3182,
  },
  {
    nome: 'Menconico',
    codice: '018089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F122',
    cap: ['27050'],
    popolazione: 378,
  },
  {
    nome: 'Mendatica',
    codice: '008034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'F123',
    cap: ['18025'],
    popolazione: 205,
  },
  {
    nome: 'Mendicino',
    codice: '078079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F125',
    cap: ['87040'],
    popolazione: 9238,
  },
  {
    nome: 'Menfi',
    codice: '084023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'F126',
    cap: ['92013'],
    popolazione: 12711,
  },
  {
    nome: 'Mentana',
    codice: '058059',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F127',
    cap: ['00013'],
    popolazione: 20772,
  },
  {
    nome: 'Meolo',
    codice: '027022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'F130',
    cap: ['30020'],
    popolazione: 6465,
  },
  {
    nome: 'Merana',
    codice: '006093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F131',
    cap: ['15010'],
    popolazione: 185,
  },
  {
    nome: 'Merano',
    codice: '021051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F132',
    cap: ['39012'],
    popolazione: 37368,
  },
  {
    nome: 'Merate',
    codice: '097048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F133',
    cap: ['23807'],
    popolazione: 14583,
  },
  {
    nome: 'Mercallo',
    codice: '012101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F134',
    cap: ['21020'],
    popolazione: 1827,
  },
  {
    nome: 'Mercatello sul Metauro',
    codice: '041025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F135',
    cap: ['61040'],
    popolazione: 1437,
  },
  {
    nome: 'Mercatino Conca',
    codice: '041026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F136',
    cap: ['61013'],
    popolazione: 1108,
  },
  {
    nome: 'Mercato San Severino',
    codice: '065067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F138',
    cap: ['84085'],
    popolazione: 22036,
  },
  {
    nome: 'Mercato Saraceno',
    codice: '040020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'F139',
    cap: ['47025'],
    popolazione: 6997,
  },
  {
    nome: 'Mercenasco',
    codice: '001150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F140',
    cap: ['10010'],
    popolazione: 1267,
  },
  {
    nome: 'Mercogliano',
    codice: '064049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F141',
    cap: ['83013'],
    popolazione: 12403,
  },
  {
    nome: 'Mereto di Tomba',
    codice: '030058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F144',
    cap: ['33036'],
    popolazione: 2709,
  },
  {
    nome: 'Mergo',
    codice: '042024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F145',
    cap: ['60030'],
    popolazione: 1083,
  },
  {
    nome: 'Mergozzo',
    codice: '103044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F146',
    cap: ['28802'],
    popolazione: 2196,
  },
  {
    nome: 'Merì',
    codice: '083047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F147',
    cap: ['98040'],
    popolazione: 2396,
  },
  {
    nome: 'Merlara',
    codice: '028053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F148',
    cap: ['35040'],
    popolazione: 2831,
  },
  {
    nome: 'Merlino',
    codice: '098039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'F149',
    cap: ['26833'],
    popolazione: 1772,
  },
  {
    nome: 'Merone',
    codice: '013147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F151',
    cap: ['22046'],
    popolazione: 4155,
  },
  {
    nome: 'Mesagne',
    codice: '074010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'F152',
    cap: ['72023'],
    popolazione: 27753,
  },
  {
    nome: 'Mese',
    codice: '014043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'F153',
    cap: ['23020'],
    popolazione: 1747,
  },
  {
    nome: 'Mesenzana',
    codice: '012102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F154',
    cap: ['21030'],
    popolazione: 1486,
  },
  {
    nome: 'Mesero',
    codice: '015144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F155',
    cap: ['20010'],
    popolazione: 3909,
  },
  {
    nome: 'Mesola',
    codice: '038014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'F156',
    cap: ['44026'],
    popolazione: 7140,
  },
  {
    nome: 'Mesoraca',
    codice: '101015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'F157',
    cap: ['88838'],
    popolazione: 6718,
  },
  {
    nome: 'Messina',
    codice: '083048',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F158',
    cap: [
      '98121',
      '98122',
      '98123',
      '98124',
      '98125',
      '98126',
      '98127',
      '98128',
      '98129',
      '98130',
      '98131',
      '98132',
      '98133',
      '98134',
      '98135',
      '98136',
      '98137',
      '98138',
      '98139',
      '98140',
      '98141',
      '98142',
      '98143',
      '98144',
      '98145',
      '98146',
      '98147',
      '98148',
      '98149',
      '98150',
      '98151',
      '98152',
      '98153',
      '98154',
      '98155',
      '98156',
      '98157',
      '98158',
      '98159',
      '98160',
      '98161',
      '98162',
      '98163',
      '98164',
      '98165',
      '98166',
      '98167',
      '98168',
    ],
    popolazione: 243262,
  },
  {
    nome: 'Mestrino',
    codice: '028054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F161',
    cap: ['35035'],
    popolazione: 10961,
  },
  {
    nome: 'Meta',
    codice: '063046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F162',
    cap: ['80062'],
    popolazione: 7969,
  },
  {
    nome: 'Mezzago',
    codice: '108031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'F165',
    cap: ['20883'],
    popolazione: 4106,
  },
  {
    nome: 'Mezzana',
    codice: '022114',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F168',
    cap: ['38020'],
    popolazione: 884,
  },
  {
    nome: 'Mezzana Bigli',
    codice: '018090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F170',
    cap: ['27030'],
    popolazione: 1108,
  },
  {
    nome: 'Mezzana Mortigliengo',
    codice: '096033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F167',
    cap: ['13831'],
    popolazione: 561,
  },
  {
    nome: 'Mezzana Rabattone',
    codice: '018091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F171',
    cap: ['27030'],
    popolazione: 502,
  },
  {
    nome: 'Mezzane di Sotto',
    codice: '023047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F172',
    cap: ['37030'],
    popolazione: 2463,
  },
  {
    nome: 'Mezzanego',
    codice: '010034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F173',
    cap: ['16046'],
    popolazione: 1624,
  },
  {
    nome: 'Mezzanino',
    codice: '018092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F175',
    cap: ['27040'],
    popolazione: 1494,
  },
  {
    nome: 'Mezzano',
    codice: '022115',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F176',
    cap: ['38050'],
    popolazione: 1615,
  },
  {
    nome: 'Mezzenile',
    codice: '001152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F182',
    cap: ['10070'],
    popolazione: 834,
  },
  {
    nome: 'Mezzocorona',
    codice: '022116',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F183',
    cap: ['38016'],
    popolazione: 5161,
  },
  {
    nome: 'Mezzojuso',
    codice: '082047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'F184',
    cap: ['90030'],
    popolazione: 3020,
  },
  {
    nome: 'Mezzoldo',
    codice: '016134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F186',
    cap: ['24010'],
    popolazione: 193,
  },
  {
    nome: 'Mezzolombardo',
    codice: '022117',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F187',
    cap: ['38017'],
    popolazione: 6817,
  },
  {
    nome: 'Mezzomerico',
    codice: '003097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F188',
    cap: ['28040'],
    popolazione: 1176,
  },
  {
    nome: 'Miagliano',
    codice: '096034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F189',
    cap: ['13816'],
    popolazione: 638,
  },
  {
    nome: 'Miane',
    codice: '026042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F190',
    cap: ['31050'],
    popolazione: 3436,
  },
  {
    nome: 'Miasino',
    codice: '003098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F191',
    cap: ['28010'],
    popolazione: 887,
  },
  {
    nome: 'Miazzina',
    codice: '103045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F192',
    cap: ['28817'],
    popolazione: 414,
  },
  {
    nome: 'Micigliano',
    codice: '057037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F193',
    cap: ['02010'],
    popolazione: 131,
  },
  {
    nome: 'Miggiano',
    codice: '075046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F194',
    cap: ['73035'],
    popolazione: 3684,
  },
  {
    nome: 'Miglianico',
    codice: '069050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F196',
    cap: ['66010'],
    popolazione: 4844,
  },
  {
    nome: 'Miglierina',
    codice: '079077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'F200',
    cap: ['88040'],
    popolazione: 799,
  },
  {
    nome: 'Miglionico',
    codice: '077015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'F201',
    cap: ['75010'],
    popolazione: 2543,
  },
  {
    nome: 'Mignanego',
    codice: '010035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F202',
    cap: ['16018'],
    popolazione: 3756,
  },
  {
    nome: 'Mignano Monte Lungo',
    codice: '061051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'F203',
    cap: ['81049'],
    popolazione: 3258,
  },
  {
    nome: 'Milano',
    codice: '015146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F205',
    cap: [
      '20121',
      '20122',
      '20123',
      '20124',
      '20125',
      '20126',
      '20127',
      '20128',
      '20129',
      '20130',
      '20131',
      '20132',
      '20133',
      '20134',
      '20135',
      '20136',
      '20137',
      '20138',
      '20139',
      '20140',
      '20141',
      '20142',
      '20143',
      '20144',
      '20145',
      '20146',
      '20147',
      '20148',
      '20149',
      '20150',
      '20151',
      '20152',
      '20153',
      '20154',
      '20155',
      '20156',
      '20157',
      '20158',
      '20159',
      '20160',
      '20161',
      '20162',
    ],
    popolazione: 1242123,
  },
  {
    nome: 'Milazzo',
    codice: '083049',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F206',
    cap: ['98057'],
    popolazione: 32146,
  },
  {
    nome: 'Milena',
    codice: '085010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'E618',
    cap: ['93010'],
    popolazione: 3178,
  },
  {
    nome: 'Mileto',
    codice: '102021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'F207',
    cap: ['89852'],
    popolazione: 6894,
  },
  {
    nome: 'Milis',
    codice: '095027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F208',
    cap: ['09070'],
    popolazione: 1591,
  },
  {
    nome: 'Militello in Val di Catania',
    codice: '087025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F209',
    cap: ['95043'],
    popolazione: 7807,
  },
  {
    nome: 'Militello Rosmarino',
    codice: '083050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F210',
    cap: ['98070'],
    popolazione: 1334,
  },
  {
    nome: 'Millesimo',
    codice: '009038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'F213',
    cap: ['17017'],
    popolazione: 3426,
  },
  {
    nome: 'Milo',
    codice: '087026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F214',
    cap: ['95010'],
    popolazione: 1065,
  },
  {
    nome: 'Milzano',
    codice: '017108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F216',
    cap: ['25020'],
    popolazione: 1799,
  },
  {
    nome: 'Mineo',
    codice: '087027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F217',
    cap: ['95044'],
    popolazione: 5216,
  },
  {
    nome: 'Minerbe',
    codice: '023048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F218',
    cap: ['37046'],
    popolazione: 4667,
  },
  {
    nome: 'Minerbio',
    codice: '037038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F219',
    cap: ['40061'],
    popolazione: 8674,
  },
  {
    nome: 'Minervino di Lecce',
    codice: '075047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F221',
    cap: ['73027'],
    popolazione: 3729,
  },
  {
    nome: 'Minervino Murge',
    codice: '110006',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'F220',
    cap: ['76013'],
    popolazione: 9333,
  },
  {
    nome: 'Minori',
    codice: '065068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F223',
    cap: ['84010'],
    popolazione: 2822,
  },
  {
    nome: 'Minturno',
    codice: '059014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'F224',
    cap: ['04026'],
    popolazione: 19472,
  },
  {
    nome: 'Minucciano',
    codice: '046019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'F225',
    cap: ['55034'],
    popolazione: 2221,
  },
  {
    nome: 'Mioglia',
    codice: '009039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'F226',
    cap: ['17040'],
    popolazione: 539,
  },
  {
    nome: 'Mira',
    codice: '027023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'F229',
    cap: ['30034'],
    popolazione: 38552,
  },
  {
    nome: 'Mirabella Eclano',
    codice: '064050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F230',
    cap: ['83036'],
    popolazione: 7904,
  },
  {
    nome: 'Mirabella Imbaccari',
    codice: '087028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F231',
    cap: ['95040'],
    popolazione: 5191,
  },
  {
    nome: 'Mirabello Monferrato',
    codice: '006094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F232',
    cap: ['15040'],
    popolazione: 1401,
  },
  {
    nome: 'Mirabello Sannitico',
    codice: '070038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F233',
    cap: ['86010'],
    popolazione: 2157,
  },
  {
    nome: 'Miradolo Terme',
    codice: '018093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F238',
    cap: ['27010'],
    popolazione: 3792,
  },
  {
    nome: 'Miranda',
    codice: '094027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'F239',
    cap: ['86080'],
    popolazione: 1064,
  },
  {
    nome: 'Mirandola',
    codice: '036022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F240',
    cap: ['41037'],
    popolazione: 23960,
  },
  {
    nome: 'Mirano',
    codice: '027024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'F241',
    cap: ['30035'],
    popolazione: 26456,
  },
  {
    nome: 'Mirto',
    codice: '083051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F242',
    cap: ['98070'],
    popolazione: 1000,
  },
  {
    nome: 'Misano Adriatico',
    codice: '099005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'F244',
    cap: ['47843'],
    popolazione: 12252,
  },
  {
    nome: "Misano di Gera d'Adda",
    codice: '016135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F243',
    cap: ['24040'],
    popolazione: 2927,
  },
  {
    nome: 'Misilmeri',
    codice: '082048',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'F246',
    cap: ['90036'],
    popolazione: 27570,
  },
  {
    nome: 'Misinto',
    codice: '108032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'F247',
    cap: ['20826'],
    popolazione: 5195,
  },
  {
    nome: 'Missaglia',
    codice: '097049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F248',
    cap: ['23873'],
    popolazione: 8579,
  },
  {
    nome: 'Missanello',
    codice: '076049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F249',
    cap: ['85010'],
    popolazione: 548,
  },
  {
    nome: 'Misterbianco',
    codice: '087029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F250',
    cap: ['95045'],
    popolazione: 47356,
  },
  {
    nome: 'Mistretta',
    codice: '083052',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F251',
    cap: ['98073'],
    popolazione: 5014,
  },
  {
    nome: 'Moasca',
    codice: '005063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F254',
    cap: ['14050'],
    popolazione: 470,
  },
  {
    nome: 'Moconesi',
    codice: '010036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F256',
    cap: ['16047'],
    popolazione: 2695,
  },
  {
    nome: 'Modena',
    codice: '036023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F257',
    cap: ['41121', '41122', '41123', '41124', '41125', '41126'],
    popolazione: 179149,
  },
  {
    nome: 'Modica',
    codice: '088006',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'F258',
    cap: ['97015'],
    popolazione: 53959,
  },
  {
    nome: 'Modigliana',
    codice: '040022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'F259',
    cap: ['47015'],
    popolazione: 4726,
  },
  {
    nome: 'Modolo',
    codice: '095084',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F261',
    cap: ['09090'],
    popolazione: 165,
  },
  {
    nome: 'Modugno',
    codice: '072027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'F262',
    cap: ['70026'],
    popolazione: 37532,
  },
  {
    nome: 'Moena',
    codice: '022118',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F263',
    cap: ['38035'],
    popolazione: 2690,
  },
  {
    nome: 'Moggio',
    codice: '097050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F265',
    cap: ['23817'],
    popolazione: 503,
  },
  {
    nome: 'Moggio Udinese',
    codice: '030059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F266',
    cap: ['33015'],
    popolazione: 1814,
  },
  {
    nome: 'Moglia',
    codice: '020035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'F267',
    cap: ['46024'],
    popolazione: 5923,
  },
  {
    nome: 'Mogliano',
    codice: '043025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F268',
    cap: ['62010'],
    popolazione: 4773,
  },
  {
    nome: 'Mogliano Veneto',
    codice: '026043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F269',
    cap: ['31021'],
    popolazione: 27608,
  },
  {
    nome: 'Mogorella',
    codice: '095028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F270',
    cap: ['09080'],
    popolazione: 463,
  },
  {
    nome: 'Mogoro',
    codice: '095029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F272',
    cap: ['09095'],
    popolazione: 4354,
  },
  {
    nome: 'Moiano',
    codice: '062040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F274',
    cap: ['82010'],
    popolazione: 4121,
  },
  {
    nome: 'Moimacco',
    codice: '030060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F275',
    cap: ['33040'],
    popolazione: 1648,
  },
  {
    nome: 'Moio Alcantara',
    codice: '083053',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F277',
    cap: ['98030'],
    popolazione: 756,
  },
  {
    nome: "Moio de' Calvi",
    codice: '016136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F276',
    cap: ['24010'],
    popolazione: 213,
  },
  {
    nome: 'Moio della Civitella',
    codice: '065069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F278',
    cap: ['84060'],
    popolazione: 1856,
  },
  {
    nome: 'Moiola',
    codice: '004123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F279',
    cap: ['12010'],
    popolazione: 252,
  },
  {
    nome: 'Mola di Bari',
    codice: '072028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'F280',
    cap: ['70042'],
    popolazione: 25567,
  },
  {
    nome: 'Molare',
    codice: '006095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F281',
    cap: ['15074'],
    popolazione: 2269,
  },
  {
    nome: 'Molazzana',
    codice: '046020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'F283',
    cap: ['55020'],
    popolazione: 1127,
  },
  {
    nome: 'Molfetta',
    codice: '072029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'F284',
    cap: ['70056'],
    popolazione: 60433,
  },
  {
    nome: 'Molina Aterno',
    codice: '066055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'M255',
    cap: ['67020'],
    popolazione: 419,
  },
  {
    nome: 'Molinara',
    codice: '062041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F287',
    cap: ['82020'],
    popolazione: 1662,
  },
  {
    nome: 'Molinella',
    codice: '037039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F288',
    cap: ['40062'],
    popolazione: 15651,
  },
  {
    nome: 'Molini di Triora',
    codice: '008035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'F290',
    cap: ['18010'],
    popolazione: 626,
  },
  {
    nome: 'Molino dei Torti',
    codice: '006096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F293',
    cap: ['15050'],
    popolazione: 653,
  },
  {
    nome: 'Molise',
    codice: '070039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F294',
    cap: ['86020'],
    popolazione: 162,
  },
  {
    nome: 'Moliterno',
    codice: '076050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F295',
    cap: ['85047'],
    popolazione: 4182,
  },
  {
    nome: 'Mollia',
    codice: '002078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'F297',
    cap: ['13020'],
    popolazione: 104,
  },
  {
    nome: 'Molochio',
    codice: '080051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'F301',
    cap: ['89010'],
    popolazione: 2643,
  },
  {
    nome: 'Molteno',
    codice: '097051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F304',
    cap: ['23847'],
    popolazione: 3587,
  },
  {
    nome: 'Moltrasio',
    codice: '013152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F305',
    cap: ['22010'],
    popolazione: 1640,
  },
  {
    nome: 'Molveno',
    codice: '022120',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F307',
    cap: ['38018'],
    popolazione: 1110,
  },
  {
    nome: 'Mombaldone',
    codice: '005064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F308',
    cap: ['14050'],
    popolazione: 221,
  },
  {
    nome: 'Mombarcaro',
    codice: '004124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F309',
    cap: ['12070'],
    popolazione: 274,
  },
  {
    nome: 'Mombaroccio',
    codice: '041027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F310',
    cap: ['61024'],
    popolazione: 2134,
  },
  {
    nome: 'Mombaruzzo',
    codice: '005065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F311',
    cap: ['14046'],
    popolazione: 1153,
  },
  {
    nome: 'Mombasiglio',
    codice: '004125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F312',
    cap: ['12070'],
    popolazione: 616,
  },
  {
    nome: 'Mombello di Torino',
    codice: '001153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F315',
    cap: ['10020'],
    popolazione: 411,
  },
  {
    nome: 'Mombello Monferrato',
    codice: '006097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F313',
    cap: ['15020'],
    popolazione: 1087,
  },
  {
    nome: 'Mombercelli',
    codice: '005066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F316',
    cap: ['14047'],
    popolazione: 2343,
  },
  {
    nome: 'Momo',
    codice: '003100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F317',
    cap: ['28015'],
    popolazione: 2673,
  },
  {
    nome: 'Mompantero',
    codice: '001154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F318',
    cap: ['10059'],
    popolazione: 651,
  },
  {
    nome: 'Mompeo',
    codice: '057038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F319',
    cap: ['02040'],
    popolazione: 534,
  },
  {
    nome: 'Momperone',
    codice: '006098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F320',
    cap: ['15050'],
    popolazione: 219,
  },
  {
    nome: 'Monacilioni',
    codice: '070040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F322',
    cap: ['86040'],
    popolazione: 553,
  },
  {
    nome: 'Monale',
    codice: '005067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F323',
    cap: ['14013'],
    popolazione: 1026,
  },
  {
    nome: 'Monasterace',
    codice: '080052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'F324',
    cap: ['89040'],
    popolazione: 3369,
  },
  {
    nome: 'Monastero Bormida',
    codice: '005068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F325',
    cap: ['14058'],
    popolazione: 1006,
  },
  {
    nome: 'Monastero di Lanzo',
    codice: '001155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F327',
    cap: ['10070'],
    popolazione: 381,
  },
  {
    nome: 'Monastero di Vasco',
    codice: '004126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F326',
    cap: ['12080'],
    popolazione: 1319,
  },
  {
    nome: 'Monasterolo Casotto',
    codice: '004127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F329',
    cap: ['12080'],
    popolazione: 101,
  },
  {
    nome: 'Monasterolo del Castello',
    codice: '016137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F328',
    cap: ['24060'],
    popolazione: 1190,
  },
  {
    nome: 'Monasterolo di Savigliano',
    codice: '004128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F330',
    cap: ['12030'],
    popolazione: 1357,
  },
  {
    nome: 'Monastier di Treviso',
    codice: '026044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F332',
    cap: ['31050'],
    popolazione: 4087,
  },
  {
    nome: 'Monastir',
    codice: '111041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F333',
    cap: ['09023'],
    popolazione: 4505,
  },
  {
    nome: 'Moncalieri',
    codice: '001156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F335',
    cap: ['10024'],
    popolazione: 55875,
  },
  {
    nome: 'Moncalvo',
    codice: '005069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F336',
    cap: ['14036'],
    popolazione: 3184,
  },
  {
    nome: 'Moncenisio',
    codice: '001157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'D553',
    cap: ['10050'],
    popolazione: 42,
  },
  {
    nome: 'Moncestino',
    codice: '006099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F337',
    cap: ['15020'],
    popolazione: 228,
  },
  {
    nome: 'Monchiero',
    codice: '004129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F338',
    cap: ['12060'],
    popolazione: 558,
  },
  {
    nome: 'Monchio delle Corti',
    codice: '034022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'F340',
    cap: ['43010'],
    popolazione: 985,
  },
  {
    nome: 'Moncrivello',
    codice: '002079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'F342',
    cap: ['13040'],
    popolazione: 1465,
  },
  {
    nome: 'Moncucco Torinese',
    codice: '005070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F343',
    cap: ['14024'],
    popolazione: 878,
  },
  {
    nome: 'Mondaino',
    codice: '099006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'F346',
    cap: ['47836'],
    popolazione: 1441,
  },
  {
    nome: 'Mondavio',
    codice: '041028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F347',
    cap: ['61040'],
    popolazione: 3929,
  },
  {
    nome: 'Mondolfo',
    codice: '041029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F348',
    cap: ['61037'],
    popolazione: 11735,
  },
  {
    nome: 'Mondovì',
    codice: '004130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F351',
    cap: ['12084'],
    popolazione: 22253,
  },
  {
    nome: 'Mondragone',
    codice: '061052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'F352',
    cap: ['81034'],
    popolazione: 27070,
  },
  {
    nome: 'Moneglia',
    codice: '010037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F354',
    cap: ['16030'],
    popolazione: 2890,
  },
  {
    nome: 'Monesiglio',
    codice: '004131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F355',
    cap: ['12077'],
    popolazione: 712,
  },
  {
    nome: 'Monfalcone',
    codice: '031012',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'F356',
    cap: ['34074'],
    popolazione: 27041,
  },
  {
    nome: "Monforte d'Alba",
    codice: '004132',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F358',
    cap: ['12065'],
    popolazione: 2042,
  },
  {
    nome: 'Monforte San Giorgio',
    codice: '083054',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F359',
    cap: ['98041'],
    popolazione: 2880,
  },
  {
    nome: 'Monfumo',
    codice: '026045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F360',
    cap: ['31010'],
    popolazione: 1442,
  },
  {
    nome: 'Mongardino',
    codice: '005071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F361',
    cap: ['14040'],
    popolazione: 951,
  },
  {
    nome: 'Monghidoro',
    codice: '037040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F363',
    cap: ['40063'],
    popolazione: 3806,
  },
  {
    nome: 'Mongiana',
    codice: '102022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'F364',
    cap: ['89823'],
    popolazione: 796,
  },
  {
    nome: 'Mongiardino Ligure',
    codice: '006100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F365',
    cap: ['15060'],
    popolazione: 177,
  },
  {
    nome: 'Mongiuffi Melia',
    codice: '083055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F368',
    cap: ['98030'],
    popolazione: 653,
  },
  {
    nome: 'Mongrando',
    codice: '096035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F369',
    cap: ['13888'],
    popolazione: 3977,
  },
  {
    nome: 'Mongrassano',
    codice: '078080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F370',
    cap: ['87040'],
    popolazione: 1661,
  },
  {
    nome: 'Monguelfo-Tesido',
    codice: '021052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F371',
    cap: ['39035'],
    popolazione: 2797,
  },
  {
    nome: 'Monguzzo',
    codice: '013153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F372',
    cap: ['22040'],
    popolazione: 2230,
  },
  {
    nome: 'Moniga del Garda',
    codice: '017109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F373',
    cap: ['25080'],
    popolazione: 2436,
  },
  {
    nome: 'Monleale',
    codice: '006101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F374',
    cap: ['15059'],
    popolazione: 593,
  },
  {
    nome: 'Monno',
    codice: '017110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F375',
    cap: ['25040'],
    popolazione: 564,
  },
  {
    nome: 'Monopoli',
    codice: '072030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'F376',
    cap: ['70043'],
    popolazione: 48529,
  },
  {
    nome: 'Monreale',
    codice: '082049',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'F377',
    cap: ['90046'],
    popolazione: 38018,
  },
  {
    nome: 'Monrupino',
    codice: '032002',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '032', nome: 'Trieste' },
    sigla: 'TS',
    codiceCatastale: 'F378',
    cap: ['34016'],
    popolazione: 881,
  },
  {
    nome: 'Monsampietro Morico',
    codice: '109012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F379',
    cap: ['63842'],
    popolazione: 682,
  },
  {
    nome: 'Monsampolo del Tronto',
    codice: '044031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F380',
    cap: ['63077'],
    popolazione: 4563,
  },
  {
    nome: 'Monsano',
    codice: '042025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F381',
    cap: ['60030'],
    popolazione: 3353,
  },
  {
    nome: 'Monselice',
    codice: '028055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F382',
    cap: ['35043'],
    popolazione: 17451,
  },
  {
    nome: 'Monserrato',
    codice: '092109',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'F383',
    cap: ['09042'],
    popolazione: 20449,
  },
  {
    nome: 'Monsummano Terme',
    codice: '047009',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'F384',
    cap: ['51015'],
    popolazione: 20767,
  },
  {
    nome: 'Montà',
    codice: '004133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F385',
    cap: ['12046'],
    popolazione: 4715,
  },
  {
    nome: 'Montabone',
    codice: '005072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F386',
    cap: ['14040'],
    popolazione: 347,
  },
  {
    nome: 'Montacuto',
    codice: '006102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F387',
    cap: ['15050'],
    popolazione: 306,
  },
  {
    nome: 'Montafia',
    codice: '005073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F390',
    cap: ['14014'],
    popolazione: 904,
  },
  {
    nome: 'Montagano',
    codice: '070041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F391',
    cap: ['86023'],
    popolazione: 1150,
  },
  {
    nome: 'Montagna',
    codice: '021053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F392',
    cap: ['39040'],
    popolazione: 1627,
  },
  {
    nome: 'Montagna in Valtellina',
    codice: '014044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'F393',
    cap: ['23020'],
    popolazione: 3031,
  },
  {
    nome: 'Montagnana',
    codice: '028056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F394',
    cap: ['35044'],
    popolazione: 9421,
  },
  {
    nome: 'Montagnareale',
    codice: '083056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F395',
    cap: ['98060'],
    popolazione: 1631,
  },
  {
    nome: 'Montaguto',
    codice: '064051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F397',
    cap: ['83030'],
    popolazione: 451,
  },
  {
    nome: 'Montaione',
    codice: '048027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'F398',
    cap: ['50050'],
    popolazione: 3776,
  },
  {
    nome: 'Montalbano Elicona',
    codice: '083057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F400',
    cap: ['98065'],
    popolazione: 2420,
  },
  {
    nome: 'Montalbano Jonico',
    codice: '077016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'F399',
    cap: ['75023'],
    popolazione: 7427,
  },
  {
    nome: 'Montalcino',
    codice: '052037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'M378',
    cap: ['53024'],
    popolazione: 6043,
  },
  {
    nome: 'Montaldeo',
    codice: '006103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F403',
    cap: ['15060'],
    popolazione: 291,
  },
  {
    nome: 'Montaldo Bormida',
    codice: '006104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F404',
    cap: ['15010'],
    popolazione: 708,
  },
  {
    nome: 'Montaldo di Mondovì',
    codice: '004134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F405',
    cap: ['12080'],
    popolazione: 571,
  },
  {
    nome: 'Montaldo Roero',
    codice: '004135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F408',
    cap: ['12040'],
    popolazione: 872,
  },
  {
    nome: 'Montaldo Scarampi',
    codice: '005074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F409',
    cap: ['14048'],
    popolazione: 788,
  },
  {
    nome: 'Montaldo Torinese',
    codice: '001158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F407',
    cap: ['10020'],
    popolazione: 749,
  },
  {
    nome: 'Montale',
    codice: '047010',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'F410',
    cap: ['51037'],
    popolazione: 10682,
  },
  {
    nome: 'Montalenghe',
    codice: '001159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F411',
    cap: ['10090'],
    popolazione: 1030,
  },
  {
    nome: 'Montallegro',
    codice: '084024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'F414',
    cap: ['92010'],
    popolazione: 2543,
  },
  {
    nome: 'Montalto Carpasio',
    codice: '008068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'M387',
    cap: ['18028'],
    popolazione: 517,
  },
  {
    nome: 'Montalto delle Marche',
    codice: '044032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F415',
    cap: ['63068'],
    popolazione: 2260,
  },
  {
    nome: 'Montalto di Castro',
    codice: '056035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'F419',
    cap: ['01014'],
    popolazione: 8770,
  },
  {
    nome: 'Montalto Dora',
    codice: '001160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F420',
    cap: ['10016'],
    popolazione: 3450,
  },
  {
    nome: 'Montalto Pavese',
    codice: '018094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F417',
    cap: ['27040'],
    popolazione: 924,
  },
  {
    nome: 'Montalto Uffugo',
    codice: '078081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F416',
    cap: ['87046'],
    popolazione: 18168,
  },
  {
    nome: 'Montanaro',
    codice: '001161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F422',
    cap: ['10017'],
    popolazione: 5375,
  },
  {
    nome: 'Montanaso Lombardo',
    codice: '098040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'F423',
    cap: ['26836'],
    popolazione: 2231,
  },
  {
    nome: 'Montanera',
    codice: '004136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F424',
    cap: ['12040'],
    popolazione: 733,
  },
  {
    nome: 'Montano Antilia',
    codice: '065070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F426',
    cap: ['84060'],
    popolazione: 2233,
  },
  {
    nome: 'Montano Lucino',
    codice: '013154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F427',
    cap: ['22070'],
    popolazione: 4775,
  },
  {
    nome: 'Montappone',
    codice: '109013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F428',
    cap: ['63835'],
    popolazione: 1749,
  },
  {
    nome: 'Montaquila',
    codice: '094028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'F429',
    cap: ['86070'],
    popolazione: 2451,
  },
  {
    nome: 'Montasola',
    codice: '057039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F430',
    cap: ['02040'],
    popolazione: 403,
  },
  {
    nome: 'Montauro',
    codice: '079080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'F432',
    cap: ['88060'],
    popolazione: 1583,
  },
  {
    nome: 'Montazzoli',
    codice: '069051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F433',
    cap: ['66030'],
    popolazione: 1032,
  },
  {
    nome: 'Monte Argentario',
    codice: '053016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'F437',
    cap: ['58019'],
    popolazione: 12556,
  },
  {
    nome: 'Monte Castello di Vibio',
    codice: '054029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F456',
    cap: ['06057'],
    popolazione: 1620,
  },
  {
    nome: 'Monte Cavallo',
    codice: '043027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F460',
    cap: ['62036'],
    popolazione: 149,
  },
  {
    nome: 'Monte Cerignone',
    codice: '041031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F467',
    cap: ['61010'],
    popolazione: 678,
  },
  {
    nome: 'Monte Compatri',
    codice: '058060',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F477',
    cap: ['00077'],
    popolazione: 11234,
  },
  {
    nome: 'Monte Cremasco',
    codice: '019058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'F434',
    cap: ['26010'],
    popolazione: 2356,
  },
  {
    nome: 'Monte di Malo',
    codice: '024063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F486',
    cap: ['36030'],
    popolazione: 2887,
  },
  {
    nome: 'Monte di Procida',
    codice: '063047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F488',
    cap: ['80070'],
    popolazione: 12975,
  },
  {
    nome: 'Monte Giberto',
    codice: '109016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F517',
    cap: ['63846'],
    popolazione: 815,
  },
  {
    nome: 'Monte Grimano Terme',
    codice: '041035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F524',
    cap: ['61010'],
    popolazione: 1166,
  },
  {
    nome: 'Monte Isola',
    codice: '017111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F532',
    cap: ['25050'],
    popolazione: 1804,
  },
  {
    nome: 'Monte Marenzo',
    codice: '097052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F561',
    cap: ['23804'],
    popolazione: 1971,
  },
  {
    nome: 'Monte Porzio',
    codice: '041038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F589',
    cap: ['61040'],
    popolazione: 2802,
  },
  {
    nome: 'Monte Porzio Catone',
    codice: '058064',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F590',
    cap: ['00078'],
    popolazione: 8617,
  },
  {
    nome: 'Monte Rinaldo',
    codice: '109021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F599',
    cap: ['63852'],
    popolazione: 397,
  },
  {
    nome: 'Monte Roberto',
    codice: '042029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F600',
    cap: ['60030'],
    popolazione: 3026,
  },
  {
    nome: 'Monte Romano',
    codice: '056037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'F603',
    cap: ['01010'],
    popolazione: 2007,
  },
  {
    nome: 'Monte San Biagio',
    codice: '059015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'F616',
    cap: ['04020'],
    popolazione: 6144,
  },
  {
    nome: 'Monte San Giacomo',
    codice: '065075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F618',
    cap: ['84030'],
    popolazione: 1630,
  },
  {
    nome: 'Monte San Giovanni Campano',
    codice: '060044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'F620',
    cap: ['03025'],
    popolazione: 12882,
  },
  {
    nome: 'Monte San Giovanni in Sabina',
    codice: '057043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F619',
    cap: ['02040'],
    popolazione: 752,
  },
  {
    nome: 'Monte San Giusto',
    codice: '043031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F621',
    cap: ['62015'],
    popolazione: 8071,
  },
  {
    nome: 'Monte San Martino',
    codice: '043032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F622',
    cap: ['62020'],
    popolazione: 792,
  },
  {
    nome: 'Monte San Pietrangeli',
    codice: '109023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F626',
    cap: ['63815'],
    popolazione: 2547,
  },
  {
    nome: 'Monte San Pietro',
    codice: '037042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F627',
    cap: ['40050'],
    popolazione: 10820,
  },
  {
    nome: 'Monte San Savino',
    codice: '051025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'F628',
    cap: ['52048'],
    popolazione: 8743,
  },
  {
    nome: 'Monte San Vito',
    codice: '042030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F634',
    cap: ['60037'],
    popolazione: 6706,
  },
  {
    nome: "Monte Sant'Angelo",
    codice: '071033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'F631',
    cap: ['71037'],
    popolazione: 13098,
  },
  {
    nome: 'Monte Santa Maria Tiberina',
    codice: '054032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F629',
    cap: ['06010'],
    popolazione: 1216,
  },
  {
    nome: 'Monte Urano',
    codice: '109024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F653',
    cap: ['63813'],
    popolazione: 8283,
  },
  {
    nome: 'Monte Vidon Combatte',
    codice: '109025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F664',
    cap: ['63847'],
    popolazione: 459,
  },
  {
    nome: 'Monte Vidon Corrado',
    codice: '109026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F665',
    cap: ['63836'],
    popolazione: 777,
  },
  {
    nome: 'Montebello della Battaglia',
    codice: '018095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F440',
    cap: ['27054'],
    popolazione: 1689,
  },
  {
    nome: 'Montebello di Bertona',
    codice: '068023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'F441',
    cap: ['65010'],
    popolazione: 1023,
  },
  {
    nome: 'Montebello Jonico',
    codice: '080053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'D746',
    cap: ['89064'],
    popolazione: 6242,
  },
  {
    nome: 'Montebello sul Sangro',
    codice: '069009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'B268',
    cap: ['66040'],
    popolazione: 99,
  },
  {
    nome: 'Montebello Vicentino',
    codice: '024060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F442',
    cap: ['36054'],
    popolazione: 6531,
  },
  {
    nome: 'Montebelluna',
    codice: '026046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F443',
    cap: ['31044'],
    popolazione: 30765,
  },
  {
    nome: 'Montebruno',
    codice: '010038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F445',
    cap: ['16025'],
    popolazione: 217,
  },
  {
    nome: 'Montebuono',
    codice: '057040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F446',
    cap: ['02040'],
    popolazione: 917,
  },
  {
    nome: 'Montecalvo in Foglia',
    codice: '041030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F450',
    cap: ['61020'],
    popolazione: 2700,
  },
  {
    nome: 'Montecalvo Irpino',
    codice: '064052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F448',
    cap: ['83037'],
    popolazione: 3907,
  },
  {
    nome: 'Montecalvo Versiggia',
    codice: '018096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F449',
    cap: ['27047'],
    popolazione: 561,
  },
  {
    nome: 'Montecarlo',
    codice: '046021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'F452',
    cap: ['55015'],
    popolazione: 4454,
  },
  {
    nome: 'Montecarotto',
    codice: '042026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F453',
    cap: ['60036'],
    popolazione: 2080,
  },
  {
    nome: 'Montecassiano',
    codice: '043026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F454',
    cap: ['62010'],
    popolazione: 7185,
  },
  {
    nome: 'Montecastello',
    codice: '006105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F455',
    cap: ['15040'],
    popolazione: 324,
  },
  {
    nome: 'Montecastrilli',
    codice: '055017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'F457',
    cap: ['05026'],
    popolazione: 5190,
  },
  {
    nome: 'Montecatini Val di Cecina',
    codice: '050019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'F458',
    cap: ['56040'],
    popolazione: 1820,
  },
  {
    nome: 'Montecatini-Terme',
    codice: '047011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'A561',
    cap: ['51016'],
    popolazione: 19674,
  },
  {
    nome: 'Montecchia di Crosara',
    codice: '023049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F461',
    cap: ['37030'],
    popolazione: 4462,
  },
  {
    nome: 'Montecchio',
    codice: '055018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'F462',
    cap: ['05020'],
    popolazione: 1723,
  },
  {
    nome: 'Montecchio Emilia',
    codice: '035027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'F463',
    cap: ['42027'],
    popolazione: 10201,
  },
  {
    nome: 'Montecchio Maggiore',
    codice: '024061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F464',
    cap: ['36075'],
    popolazione: 23315,
  },
  {
    nome: 'Montecchio Precalcino',
    codice: '024062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F465',
    cap: ['36030'],
    popolazione: 4988,
  },
  {
    nome: "Montechiaro d'Acqui",
    codice: '006106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F469',
    cap: ['15010'],
    popolazione: 568,
  },
  {
    nome: "Montechiaro d'Asti",
    codice: '005075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F468',
    cap: ['14025'],
    popolazione: 1380,
  },
  {
    nome: 'Montechiarugolo',
    codice: '034023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'F473',
    cap: ['43022'],
    popolazione: 10482,
  },
  {
    nome: 'Monteciccardo',
    codice: '041032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F474',
    cap: ['61024'],
    popolazione: 1686,
  },
  {
    nome: 'Montecilfone',
    codice: '070042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F475',
    cap: ['86032'],
    popolazione: 1423,
  },
  {
    nome: 'Montecopiolo',
    codice: '041033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F478',
    cap: ['61014'],
    popolazione: 1175,
  },
  {
    nome: 'Montecorice',
    codice: '065071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F479',
    cap: ['84060'],
    popolazione: 2545,
  },
  {
    nome: 'Montecorvino Pugliano',
    codice: '065072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F480',
    cap: ['84090'],
    popolazione: 10019,
  },
  {
    nome: 'Montecorvino Rovella',
    codice: '065073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F481',
    cap: ['84096'],
    popolazione: 12553,
  },
  {
    nome: 'Montecosaro',
    codice: '043028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F482',
    cap: ['62010'],
    popolazione: 6918,
  },
  {
    nome: 'Montecrestese',
    codice: '103046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F483',
    cap: ['28864'],
    popolazione: 1255,
  },
  {
    nome: 'Montecreto',
    codice: '036024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F484',
    cap: ['41025'],
    popolazione: 1000,
  },
  {
    nome: 'Montedinove',
    codice: '044034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F487',
    cap: ['63069'],
    popolazione: 505,
  },
  {
    nome: 'Montedoro',
    codice: '085011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'F489',
    cap: ['93010'],
    popolazione: 1643,
  },
  {
    nome: 'Montefalcione',
    codice: '064053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F491',
    cap: ['83030'],
    popolazione: 3442,
  },
  {
    nome: 'Montefalco',
    codice: '054030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F492',
    cap: ['06036'],
    popolazione: 5691,
  },
  {
    nome: 'Montefalcone Appennino',
    codice: '109014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F493',
    cap: ['63855'],
    popolazione: 445,
  },
  {
    nome: 'Montefalcone di Val Fortore',
    codice: '062042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F494',
    cap: ['82025'],
    popolazione: 1650,
  },
  {
    nome: 'Montefalcone nel Sannio',
    codice: '070043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F495',
    cap: ['86033'],
    popolazione: 1650,
  },
  {
    nome: 'Montefano',
    codice: '043029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F496',
    cap: ['62010'],
    popolazione: 3555,
  },
  {
    nome: 'Montefelcino',
    codice: '041034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F497',
    cap: ['61030'],
    popolazione: 2726,
  },
  {
    nome: 'Monteferrante',
    codice: '069052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F498',
    cap: ['66040'],
    popolazione: 141,
  },
  {
    nome: 'Montefiascone',
    codice: '056036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'F499',
    cap: ['01027'],
    popolazione: 13388,
  },
  {
    nome: 'Montefino',
    codice: '067027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F500',
    cap: ['64030'],
    popolazione: 1091,
  },
  {
    nome: 'Montefiore Conca',
    codice: '099008',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'F502',
    cap: ['47834'],
    popolazione: 2195,
  },
  {
    nome: "Montefiore dell'Aso",
    codice: '044036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F501',
    cap: ['63062'],
    popolazione: 2180,
  },
  {
    nome: 'Montefiorino',
    codice: '036025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F503',
    cap: ['41045'],
    popolazione: 2253,
  },
  {
    nome: 'Monteflavio',
    codice: '058061',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F504',
    cap: ['00010'],
    popolazione: 1399,
  },
  {
    nome: 'Monteforte Cilento',
    codice: '065074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F507',
    cap: ['84060'],
    popolazione: 565,
  },
  {
    nome: "Monteforte d'Alpone",
    codice: '023050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F508',
    cap: ['37032'],
    popolazione: 8410,
  },
  {
    nome: 'Monteforte Irpino',
    codice: '064054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F506',
    cap: ['83024'],
    popolazione: 10878,
  },
  {
    nome: 'Montefortino',
    codice: '109015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F509',
    cap: ['63858'],
    popolazione: 1214,
  },
  {
    nome: 'Montefranco',
    codice: '055019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'F510',
    cap: ['05030'],
    popolazione: 1289,
  },
  {
    nome: 'Montefredane',
    codice: '064055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F511',
    cap: ['83030'],
    popolazione: 2308,
  },
  {
    nome: 'Montefusco',
    codice: '064056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F512',
    cap: ['83030'],
    popolazione: 1393,
  },
  {
    nome: 'Montegabbione',
    codice: '055020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'F513',
    cap: ['05010'],
    popolazione: 1235,
  },
  {
    nome: 'Montegalda',
    codice: '024064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F514',
    cap: ['36047'],
    popolazione: 3343,
  },
  {
    nome: 'Montegaldella',
    codice: '024065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F515',
    cap: ['36047'],
    popolazione: 1788,
  },
  {
    nome: 'Montegallo',
    codice: '044038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F516',
    cap: ['63094'],
    popolazione: 573,
  },
  {
    nome: 'Montegioco',
    codice: '006107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F518',
    cap: ['15050'],
    popolazione: 326,
  },
  {
    nome: 'Montegiordano',
    codice: '078082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F519',
    cap: ['87070'],
    popolazione: 1988,
  },
  {
    nome: 'Montegiorgio',
    codice: '109017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F520',
    cap: ['63833'],
    popolazione: 6965,
  },
  {
    nome: 'Montegranaro',
    codice: '109018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F522',
    cap: ['63812'],
    popolazione: 13153,
  },
  {
    nome: 'Montegridolfo',
    codice: '099009',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'F523',
    cap: ['47837'],
    popolazione: 1029,
  },
  {
    nome: 'Montegrino Valtravaglia',
    codice: '012103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F526',
    cap: ['21010'],
    popolazione: 1414,
  },
  {
    nome: "Montegrosso d'Asti",
    codice: '005076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F527',
    cap: ['14048'],
    popolazione: 2264,
  },
  {
    nome: 'Montegrosso Pian Latte',
    codice: '008037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'F528',
    cap: ['18025'],
    popolazione: 121,
  },
  {
    nome: 'Montegrotto Terme',
    codice: '028057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F529',
    cap: ['35036'],
    popolazione: 11074,
  },
  {
    nome: 'Monteiasi',
    codice: '073016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'F531',
    cap: ['74020'],
    popolazione: 5522,
  },
  {
    nome: 'Montelabbate',
    codice: '041036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'F533',
    cap: ['61025'],
    popolazione: 6719,
  },
  {
    nome: 'Montelanico',
    codice: '058062',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F534',
    cap: ['00030'],
    popolazione: 2152,
  },
  {
    nome: 'Montelapiano',
    codice: '069053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F535',
    cap: ['66040'],
    popolazione: 80,
  },
  {
    nome: "Monteleone d'Orvieto",
    codice: '055021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'F543',
    cap: ['05017'],
    popolazione: 1559,
  },
  {
    nome: 'Monteleone di Fermo',
    codice: '109019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F536',
    cap: ['63841'],
    popolazione: 436,
  },
  {
    nome: 'Monteleone di Puglia',
    codice: '071032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'F538',
    cap: ['71020'],
    popolazione: 1067,
  },
  {
    nome: 'Monteleone di Spoleto',
    codice: '054031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F540',
    cap: ['06045'],
    popolazione: 626,
  },
  {
    nome: 'Monteleone Rocca Doria',
    codice: '090040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F542',
    cap: ['07010'],
    popolazione: 117,
  },
  {
    nome: 'Monteleone Sabino',
    codice: '057041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F541',
    cap: ['02033'],
    popolazione: 1232,
  },
  {
    nome: 'Montelepre',
    codice: '082050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'F544',
    cap: ['90040'],
    popolazione: 6421,
  },
  {
    nome: 'Montelibretti',
    codice: '058063',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F545',
    cap: ['00010'],
    popolazione: 5213,
  },
  {
    nome: 'Montella',
    codice: '064057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F546',
    cap: ['83048'],
    popolazione: 7877,
  },
  {
    nome: 'Montello',
    codice: '016139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F547',
    cap: ['24060'],
    popolazione: 3173,
  },
  {
    nome: 'Montelongo',
    codice: '070044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F548',
    cap: ['86040'],
    popolazione: 384,
  },
  {
    nome: 'Montelparo',
    codice: '109020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F549',
    cap: ['63853'],
    popolazione: 861,
  },
  {
    nome: 'Montelupo Albese',
    codice: '004137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F550',
    cap: ['12050'],
    popolazione: 531,
  },
  {
    nome: 'Montelupo Fiorentino',
    codice: '048028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'F551',
    cap: ['50056'],
    popolazione: 13653,
  },
  {
    nome: 'Montelupone',
    codice: '043030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F552',
    cap: ['62010'],
    popolazione: 3658,
  },
  {
    nome: 'Montemaggiore Belsito',
    codice: '082051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'F553',
    cap: ['90020'],
    popolazione: 3566,
  },
  {
    nome: 'Montemagno',
    codice: '005077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F556',
    cap: ['14030'],
    popolazione: 1162,
  },
  {
    nome: 'Montemale di Cuneo',
    codice: '004138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F558',
    cap: ['12025'],
    popolazione: 218,
  },
  {
    nome: 'Montemarano',
    codice: '064058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F559',
    cap: ['83040'],
    popolazione: 3005,
  },
  {
    nome: 'Montemarciano',
    codice: '042027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F560',
    cap: ['60018'],
    popolazione: 10110,
  },
  {
    nome: 'Montemarzino',
    codice: '006108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F562',
    cap: ['15050'],
    popolazione: 341,
  },
  {
    nome: 'Montemesola',
    codice: '073017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'F563',
    cap: ['74020'],
    popolazione: 4088,
  },
  {
    nome: 'Montemezzo',
    codice: '013155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F564',
    cap: ['22010'],
    popolazione: 261,
  },
  {
    nome: 'Montemignaio',
    codice: '051023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'F565',
    cap: ['52010'],
    popolazione: 576,
  },
  {
    nome: 'Montemiletto',
    codice: '064059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F566',
    cap: ['83038'],
    popolazione: 5361,
  },
  {
    nome: 'Montemilone',
    codice: '076051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F568',
    cap: ['85020'],
    popolazione: 1725,
  },
  {
    nome: 'Montemitro',
    codice: '070045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F569',
    cap: ['86030'],
    popolazione: 454,
  },
  {
    nome: 'Montemonaco',
    codice: '044044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F570',
    cap: ['63088'],
    popolazione: 635,
  },
  {
    nome: 'Montemurlo',
    codice: '100003',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'F572',
    cap: ['59013'],
    popolazione: 17908,
  },
  {
    nome: 'Montemurro',
    codice: '076052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F573',
    cap: ['85053'],
    popolazione: 1312,
  },
  {
    nome: 'Montenars',
    codice: '030061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F574',
    cap: ['33010'],
    popolazione: 558,
  },
  {
    nome: 'Montenero di Bisaccia',
    codice: '070046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F576',
    cap: ['86036'],
    popolazione: 6649,
  },
  {
    nome: 'Montenero Sabino',
    codice: '057042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F579',
    cap: ['02040'],
    popolazione: 295,
  },
  {
    nome: 'Montenero Val Cocchiara',
    codice: '094029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'F580',
    cap: ['86080'],
    popolazione: 558,
  },
  {
    nome: 'Montenerodomo',
    codice: '069054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F578',
    cap: ['66010'],
    popolazione: 736,
  },
  {
    nome: 'Monteodorisio',
    codice: '069055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F582',
    cap: ['66050'],
    popolazione: 2564,
  },
  {
    nome: 'Montepaone',
    codice: '079081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'F586',
    cap: ['88060'],
    popolazione: 4681,
  },
  {
    nome: 'Monteparano',
    codice: '073018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'F587',
    cap: ['74020'],
    popolazione: 2395,
  },
  {
    nome: 'Monteprandone',
    codice: '044045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'F591',
    cap: ['63076'],
    popolazione: 12211,
  },
  {
    nome: 'Montepulciano',
    codice: '052015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'F592',
    cap: ['53045'],
    popolazione: 14237,
  },
  {
    nome: 'Monterchi',
    codice: '051024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'F594',
    cap: ['52035'],
    popolazione: 1822,
  },
  {
    nome: 'Montereale',
    codice: '066056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'F595',
    cap: ['67015'],
    popolazione: 2812,
  },
  {
    nome: 'Montereale Valcellina',
    codice: '093027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'F596',
    cap: ['33086'],
    popolazione: 4517,
  },
  {
    nome: 'Monterenzio',
    codice: '037041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F597',
    cap: ['40050'],
    popolazione: 5853,
  },
  {
    nome: 'Monteriggioni',
    codice: '052016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'F598',
    cap: ['53035'],
    popolazione: 9264,
  },
  {
    nome: 'Monteroduni',
    codice: '094030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'F601',
    cap: ['86075'],
    popolazione: 2267,
  },
  {
    nome: "Monteroni d'Arbia",
    codice: '052017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'F605',
    cap: ['53014'],
    popolazione: 8744,
  },
  {
    nome: 'Monteroni di Lecce',
    codice: '075048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F604',
    cap: ['73047'],
    popolazione: 13881,
  },
  {
    nome: 'Monterosi',
    codice: '056038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'F606',
    cap: ['01030'],
    popolazione: 3868,
  },
  {
    nome: 'Monterosso al Mare',
    codice: '011019',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'F609',
    cap: ['19016'],
    popolazione: 1481,
  },
  {
    nome: 'Monterosso Almo',
    codice: '088007',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'F610',
    cap: ['97010'],
    popolazione: 3183,
  },
  {
    nome: 'Monterosso Calabro',
    codice: '102023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'F607',
    cap: ['89819'],
    popolazione: 1796,
  },
  {
    nome: 'Monterosso Grana',
    codice: '004139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F608',
    cap: ['12020'],
    popolazione: 536,
  },
  {
    nome: 'Monterotondo',
    codice: '058065',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F611',
    cap: ['00015'],
    popolazione: 39502,
  },
  {
    nome: 'Monterotondo Marittimo',
    codice: '053027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'F612',
    cap: ['58025'],
    popolazione: 1414,
  },
  {
    nome: 'Monterubbiano',
    codice: '109022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F614',
    cap: ['63825'],
    popolazione: 2351,
  },
  {
    nome: 'Montesano Salentino',
    codice: '075049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F623',
    cap: ['73030'],
    popolazione: 2677,
  },
  {
    nome: 'Montesano sulla Marcellana',
    codice: '065076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F625',
    cap: ['84033'],
    popolazione: 6781,
  },
  {
    nome: 'Montesarchio',
    codice: '062043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F636',
    cap: ['82016'],
    popolazione: 13198,
  },
  {
    nome: 'Montescaglioso',
    codice: '077017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'F637',
    cap: ['75024'],
    popolazione: 10102,
  },
  {
    nome: 'Montescano',
    codice: '018097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F638',
    cap: ['27040'],
    popolazione: 383,
  },
  {
    nome: 'Montescheno',
    codice: '103047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F639',
    cap: ['28843'],
    popolazione: 414,
  },
  {
    nome: 'Montescudaio',
    codice: '050020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'F640',
    cap: ['56040'],
    popolazione: 1958,
  },
  {
    nome: 'Montescudo-Monte Colombo',
    codice: '099029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'M368',
    cap: ['47854'],
    popolazione: 6606,
  },
  {
    nome: 'Montese',
    codice: '036026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F642',
    cap: ['41055'],
    popolazione: 3357,
  },
  {
    nome: 'Montesegale',
    codice: '018098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F644',
    cap: ['27052'],
    popolazione: 307,
  },
  {
    nome: 'Montesilvano',
    codice: '068024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'F646',
    cap: ['65015'],
    popolazione: 50413,
  },
  {
    nome: 'Montespertoli',
    codice: '048030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'F648',
    cap: ['50025'],
    popolazione: 13195,
  },
  {
    nome: 'Monteu da Po',
    codice: '001162',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F651',
    cap: ['10020'],
    popolazione: 901,
  },
  {
    nome: 'Monteu Roero',
    codice: '004140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F654',
    cap: ['12040'],
    popolazione: 1667,
  },
  {
    nome: 'Montevago',
    codice: '084025',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'F655',
    cap: ['92010'],
    popolazione: 3015,
  },
  {
    nome: 'Montevarchi',
    codice: '051026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'F656',
    cap: ['52025'],
    popolazione: 23971,
  },
  {
    nome: 'Montevecchia',
    codice: '097053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F657',
    cap: ['23874'],
    popolazione: 2480,
  },
  {
    nome: 'Monteverde',
    codice: '064060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F660',
    cap: ['83049'],
    popolazione: 831,
  },
  {
    nome: 'Monteverdi Marittimo',
    codice: '050021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'F661',
    cap: ['56040'],
    popolazione: 778,
  },
  {
    nome: 'Monteviale',
    codice: '024066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F662',
    cap: ['36050'],
    popolazione: 2600,
  },
  {
    nome: 'Montezemolo',
    codice: '004141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F666',
    cap: ['12070'],
    popolazione: 270,
  },
  {
    nome: 'Monti',
    codice: '090041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F667',
    cap: ['07020'],
    popolazione: 2488,
  },
  {
    nome: 'Montiano',
    codice: '040028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'F668',
    cap: ['47020'],
    popolazione: 1701,
  },
  {
    nome: 'Monticelli Brusati',
    codice: '017112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F672',
    cap: ['25040'],
    popolazione: 4401,
  },
  {
    nome: "Monticelli d'Ongina",
    codice: '033027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'F671',
    cap: ['29010'],
    popolazione: 5428,
  },
  {
    nome: 'Monticelli Pavese',
    codice: '018099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F670',
    cap: ['27010'],
    popolazione: 711,
  },
  {
    nome: 'Monticello Brianza',
    codice: '097054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F674',
    cap: ['23876'],
    popolazione: 4217,
  },
  {
    nome: 'Monticello Conte Otto',
    codice: '024067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F675',
    cap: ['36010'],
    popolazione: 9156,
  },
  {
    nome: "Monticello d'Alba",
    codice: '004142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F669',
    cap: ['12066'],
    popolazione: 2216,
  },
  {
    nome: 'Montichiari',
    codice: '017113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F471',
    cap: ['25018'],
    popolazione: 23734,
  },
  {
    nome: 'Monticiano',
    codice: '052018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'F676',
    cap: ['53015'],
    popolazione: 1505,
  },
  {
    nome: 'Montieri',
    codice: '053017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'F677',
    cap: ['58026'],
    popolazione: 1147,
  },
  {
    nome: 'Montiglio Monferrato',
    codice: '005121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'M302',
    cap: ['14026'],
    popolazione: 1687,
  },
  {
    nome: 'Montignoso',
    codice: '045011',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'F679',
    cap: ['54038'],
    popolazione: 10226,
  },
  {
    nome: 'Montirone',
    codice: '017114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F680',
    cap: ['25010'],
    popolazione: 5044,
  },
  {
    nome: 'Montjovet',
    codice: '007043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'F367',
    cap: ['11020'],
    popolazione: 1831,
  },
  {
    nome: 'Montodine',
    codice: '019059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'F681',
    cap: ['26010'],
    popolazione: 2606,
  },
  {
    nome: 'Montoggio',
    codice: '010039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F682',
    cap: ['16026'],
    popolazione: 2062,
  },
  {
    nome: 'Montone',
    codice: '054033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F685',
    cap: ['06014'],
    popolazione: 1663,
  },
  {
    nome: 'Montopoli di Sabina',
    codice: '057044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F687',
    cap: ['02034'],
    popolazione: 4222,
  },
  {
    nome: "Montopoli in Val d'Arno",
    codice: '050022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'F686',
    cap: ['56020'],
    popolazione: 11167,
  },
  {
    nome: 'Montorfano',
    codice: '013157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F688',
    cap: ['22030'],
    popolazione: 2619,
  },
  {
    nome: 'Montorio al Vomano',
    codice: '067028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F690',
    cap: ['64046'],
    popolazione: 8201,
  },
  {
    nome: 'Montorio nei Frentani',
    codice: '070047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F689',
    cap: ['86040'],
    popolazione: 466,
  },
  {
    nome: 'Montorio Romano',
    codice: '058066',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F692',
    cap: ['00010'],
    popolazione: 2035,
  },
  {
    nome: 'Montoro',
    codice: '064121',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'M330',
    cap: ['83025'],
    popolazione: 19456,
  },
  {
    nome: 'Montorso Vicentino',
    codice: '024068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F696',
    cap: ['36050'],
    popolazione: 3179,
  },
  {
    nome: 'Montottone',
    codice: '109027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F697',
    cap: ['63843'],
    popolazione: 1011,
  },
  {
    nome: 'Montresta',
    codice: '095085',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F698',
    cap: ['09090'],
    popolazione: 535,
  },
  {
    nome: 'Montù Beccaria',
    codice: '018100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F701',
    cap: ['27040'],
    popolazione: 1722,
  },
  {
    nome: 'Monvalle',
    codice: '012104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F703',
    cap: ['21020'],
    popolazione: 1944,
  },
  {
    nome: 'Monza',
    codice: '108033',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'F704',
    cap: ['20900'],
    popolazione: 119856,
  },
  {
    nome: 'Monzambano',
    codice: '020036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'F705',
    cap: ['46040'],
    popolazione: 4837,
  },
  {
    nome: 'Monzuno',
    codice: '037044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F706',
    cap: ['40036'],
    popolazione: 6133,
  },
  {
    nome: 'Morano Calabro',
    codice: '078083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F708',
    cap: ['87016'],
    popolazione: 4615,
  },
  {
    nome: 'Morano sul Po',
    codice: '006109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F707',
    cap: ['15025'],
    popolazione: 1511,
  },
  {
    nome: 'Moransengo',
    codice: '005079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F709',
    cap: ['14023'],
    popolazione: 212,
  },
  {
    nome: 'Moraro',
    codice: '031013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'F710',
    cap: ['34070'],
    popolazione: 767,
  },
  {
    nome: 'Morazzone',
    codice: '012105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F711',
    cap: ['21040'],
    popolazione: 4303,
  },
  {
    nome: 'Morbegno',
    codice: '014045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'F712',
    cap: ['23017'],
    popolazione: 11786,
  },
  {
    nome: 'Morbello',
    codice: '006110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F713',
    cap: ['15010'],
    popolazione: 408,
  },
  {
    nome: 'Morciano di Leuca',
    codice: '075050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F716',
    cap: ['73040'],
    popolazione: 3416,
  },
  {
    nome: 'Morciano di Romagna',
    codice: '099011',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'F715',
    cap: ['47833'],
    popolazione: 6892,
  },
  {
    nome: 'Morcone',
    codice: '062044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F717',
    cap: ['82026'],
    popolazione: 5042,
  },
  {
    nome: 'Mordano',
    codice: '037045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'F718',
    cap: ['40027'],
    popolazione: 4644,
  },
  {
    nome: 'Morengo',
    codice: '016140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F720',
    cap: ['24050'],
    popolazione: 2581,
  },
  {
    nome: 'Mores',
    codice: '090042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F721',
    cap: ['07013'],
    popolazione: 1945,
  },
  {
    nome: 'Moresco',
    codice: '109028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'F722',
    cap: ['63826'],
    popolazione: 605,
  },
  {
    nome: 'Moretta',
    codice: '004143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F723',
    cap: ['12033'],
    popolazione: 4237,
  },
  {
    nome: 'Morfasso',
    codice: '033028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'F724',
    cap: ['29020'],
    popolazione: 1105,
  },
  {
    nome: 'Morgano',
    codice: '026047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F725',
    cap: ['31050'],
    popolazione: 4362,
  },
  {
    nome: 'Morgex',
    codice: '007044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'F726',
    cap: ['11017'],
    popolazione: 2069,
  },
  {
    nome: 'Morgongiori',
    codice: '095030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F727',
    cap: ['09090'],
    popolazione: 777,
  },
  {
    nome: 'Mori',
    codice: '022123',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F728',
    cap: ['38065'],
    popolazione: 9456,
  },
  {
    nome: 'Moriago della Battaglia',
    codice: '026048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F729',
    cap: ['31010'],
    popolazione: 2785,
  },
  {
    nome: 'Moricone',
    codice: '058067',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F730',
    cap: ['00010'],
    popolazione: 2683,
  },
  {
    nome: 'Morigerati',
    codice: '065077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F731',
    cap: ['84030'],
    popolazione: 699,
  },
  {
    nome: 'Morimondo',
    codice: '015150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'D033',
    cap: ['20081'],
    popolazione: 1183,
  },
  {
    nome: 'Morino',
    codice: '066057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'F732',
    cap: ['67050'],
    popolazione: 1505,
  },
  {
    nome: 'Moriondo Torinese',
    codice: '001163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F733',
    cap: ['10020'],
    popolazione: 820,
  },
  {
    nome: 'Morlupo',
    codice: '058068',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F734',
    cap: ['00067'],
    popolazione: 8122,
  },
  {
    nome: 'Mormanno',
    codice: '078084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F735',
    cap: ['87026'],
    popolazione: 3264,
  },
  {
    nome: 'Mornago',
    codice: '012106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'F736',
    cap: ['21020'],
    popolazione: 4834,
  },
  {
    nome: 'Mornese',
    codice: '006111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F737',
    cap: ['15075'],
    popolazione: 726,
  },
  {
    nome: 'Mornico al Serio',
    codice: '016141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F738',
    cap: ['24050'],
    popolazione: 2890,
  },
  {
    nome: 'Mornico Losana',
    codice: '018101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F739',
    cap: ['27040'],
    popolazione: 721,
  },
  {
    nome: 'Morolo',
    codice: '060045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'F740',
    cap: ['03017'],
    popolazione: 3267,
  },
  {
    nome: 'Morozzo',
    codice: '004144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F743',
    cap: ['12040'],
    popolazione: 2115,
  },
  {
    nome: 'Morra De Sanctis',
    codice: '064063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F744',
    cap: ['83040'],
    popolazione: 1309,
  },
  {
    nome: "Morro d'Alba",
    codice: '042031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F745',
    cap: ['60030'],
    popolazione: 1977,
  },
  {
    nome: "Morro d'Oro",
    codice: '067029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F747',
    cap: ['64020'],
    popolazione: 3628,
  },
  {
    nome: 'Morro Reatino',
    codice: '057045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F746',
    cap: ['02010'],
    popolazione: 356,
  },
  {
    nome: 'Morrone del Sannio',
    codice: '070048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'F748',
    cap: ['86040'],
    popolazione: 648,
  },
  {
    nome: 'Morrovalle',
    codice: '043033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F749',
    cap: ['62010'],
    popolazione: 10287,
  },
  {
    nome: 'Morsano al Tagliamento',
    codice: '093028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'F750',
    cap: ['33075'],
    popolazione: 2865,
  },
  {
    nome: 'Morsasco',
    codice: '006112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F751',
    cap: ['15010'],
    popolazione: 712,
  },
  {
    nome: 'Mortara',
    codice: '018102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F754',
    cap: ['27036'],
    popolazione: 15156,
  },
  {
    nome: 'Mortegliano',
    codice: '030062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F756',
    cap: ['33050'],
    popolazione: 5045,
  },
  {
    nome: 'Morterone',
    codice: '097055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F758',
    cap: ['23811'],
    popolazione: 34,
  },
  {
    nome: 'Moruzzo',
    codice: '030063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F760',
    cap: ['33030'],
    popolazione: 2391,
  },
  {
    nome: 'Moscazzano',
    codice: '019060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'F761',
    cap: ['26010'],
    popolazione: 822,
  },
  {
    nome: 'Moschiano',
    codice: '064064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F762',
    cap: ['83020'],
    popolazione: 1667,
  },
  {
    nome: "Mosciano Sant'Angelo",
    codice: '067030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F764',
    cap: ['64023'],
    popolazione: 9251,
  },
  {
    nome: 'Moscufo',
    codice: '068025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'F765',
    cap: ['65010'],
    popolazione: 3264,
  },
  {
    nome: 'Moso in Passiria',
    codice: '021054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F766',
    cap: ['39013'],
    popolazione: 2171,
  },
  {
    nome: 'Mossa',
    codice: '031014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'F767',
    cap: ['34070'],
    popolazione: 1659,
  },
  {
    nome: 'Motta Baluffi',
    codice: '019061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'F771',
    cap: ['26045'],
    popolazione: 980,
  },
  {
    nome: 'Motta Camastra',
    codice: '083058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F772',
    cap: ['98030'],
    popolazione: 882,
  },
  {
    nome: "Motta d'Affermo",
    codice: '083059',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F773',
    cap: ['98070'],
    popolazione: 828,
  },
  {
    nome: "Motta de' Conti",
    codice: '002082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'F774',
    cap: ['13010'],
    popolazione: 804,
  },
  {
    nome: 'Motta di Livenza',
    codice: '026049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F770',
    cap: ['31045'],
    popolazione: 10681,
  },
  {
    nome: 'Motta Montecorvino',
    codice: '071034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'F777',
    cap: ['71030'],
    popolazione: 768,
  },
  {
    nome: 'Motta San Giovanni',
    codice: '080054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'F779',
    cap: ['89065'],
    popolazione: 6122,
  },
  {
    nome: "Motta Sant'Anastasia",
    codice: '087030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F781',
    cap: ['95040'],
    popolazione: 11394,
  },
  {
    nome: 'Motta Santa Lucia',
    codice: '079083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'F780',
    cap: ['88040'],
    popolazione: 871,
  },
  {
    nome: 'Motta Visconti',
    codice: '015151',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F783',
    cap: ['20086'],
    popolazione: 7601,
  },
  {
    nome: 'Mottafollone',
    codice: '078085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F775',
    cap: ['87010'],
    popolazione: 1274,
  },
  {
    nome: 'Mottalciata',
    codice: '096037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F776',
    cap: ['13874'],
    popolazione: 1431,
  },
  {
    nome: 'Motteggiana',
    codice: '020037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'B012',
    cap: ['46020'],
    popolazione: 2516,
  },
  {
    nome: 'Mottola',
    codice: '073019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'F784',
    cap: ['74017'],
    popolazione: 16241,
  },
  {
    nome: 'Mozzagrogna',
    codice: '069056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'F785',
    cap: ['66030'],
    popolazione: 2291,
  },
  {
    nome: 'Mozzanica',
    codice: '016142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F786',
    cap: ['24050'],
    popolazione: 4574,
  },
  {
    nome: 'Mozzate',
    codice: '013159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F788',
    cap: ['22076'],
    popolazione: 8208,
  },
  {
    nome: 'Mozzecane',
    codice: '023051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F789',
    cap: ['37060'],
    popolazione: 7005,
  },
  {
    nome: 'Mozzo',
    codice: '016143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F791',
    cap: ['24030'],
    popolazione: 7460,
  },
  {
    nome: 'Muccia',
    codice: '043034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F793',
    cap: ['62034'],
    popolazione: 929,
  },
  {
    nome: 'Muggia',
    codice: '032003',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '032', nome: 'Trieste' },
    sigla: 'TS',
    codiceCatastale: 'F795',
    cap: ['34015'],
    popolazione: 13022,
  },
  {
    nome: 'Muggiò',
    codice: '108034',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'F797',
    cap: ['20835'],
    popolazione: 23208,
  },
  {
    nome: 'Mugnano del Cardinale',
    codice: '064065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F798',
    cap: ['83027'],
    popolazione: 5312,
  },
  {
    nome: 'Mugnano di Napoli',
    codice: '063048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F799',
    cap: ['80018'],
    popolazione: 34504,
  },
  {
    nome: 'Mulazzano',
    codice: '098041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'F801',
    cap: ['26837'],
    popolazione: 5733,
  },
  {
    nome: 'Mulazzo',
    codice: '045012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'F802',
    cap: ['54026'],
    popolazione: 2566,
  },
  {
    nome: 'Mura',
    codice: '017115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F806',
    cap: ['25070'],
    popolazione: 790,
  },
  {
    nome: 'Muravera',
    codice: '111042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F808',
    cap: ['09043'],
    popolazione: 5162,
  },
  {
    nome: 'Murazzano',
    codice: '004145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F809',
    cap: ['12060'],
    popolazione: 840,
  },
  {
    nome: 'Murello',
    codice: '004146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F811',
    cap: ['12030'],
    popolazione: 962,
  },
  {
    nome: 'Murialdo',
    codice: '009040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'F813',
    cap: ['17013'],
    popolazione: 838,
  },
  {
    nome: 'Murisengo',
    codice: '006113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F814',
    cap: ['15020'],
    popolazione: 1450,
  },
  {
    nome: 'Murlo',
    codice: '052019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'F815',
    cap: ['53016'],
    popolazione: 2388,
  },
  {
    nome: 'Muro Leccese',
    codice: '075051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F816',
    cap: ['73036'],
    popolazione: 5091,
  },
  {
    nome: 'Muro Lucano',
    codice: '076053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F817',
    cap: ['85054'],
    popolazione: 5568,
  },
  {
    nome: 'Muros',
    codice: '090043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F818',
    cap: ['07030'],
    popolazione: 837,
  },
  {
    nome: 'Muscoline',
    codice: '017116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F820',
    cap: ['25080'],
    popolazione: 2547,
  },
  {
    nome: 'Musei',
    codice: '111043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F822',
    cap: ['09010'],
    popolazione: 1522,
  },
  {
    nome: 'Musile di Piave',
    codice: '027025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'F826',
    cap: ['30024'],
    popolazione: 11518,
  },
  {
    nome: 'Musso',
    codice: '013160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F828',
    cap: ['22010'],
    popolazione: 1019,
  },
  {
    nome: 'Mussolente',
    codice: '024070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F829',
    cap: ['36065'],
    popolazione: 7653,
  },
  {
    nome: 'Mussomeli',
    codice: '085012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'F830',
    cap: ['93014'],
    popolazione: 11010,
  },
  {
    nome: 'Muzzana del Turgnano',
    codice: '030064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F832',
    cap: ['33055'],
    popolazione: 2641,
  },
  {
    nome: 'Muzzano',
    codice: '096038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F833',
    cap: ['13895'],
    popolazione: 614,
  },
  {
    nome: 'Nago-Torbole',
    codice: '022124',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F835',
    cap: ['38069'],
    popolazione: 2728,
  },
  {
    nome: 'Nalles',
    codice: '021055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F836',
    cap: ['39010'],
    popolazione: 1831,
  },
  {
    nome: 'Nanto',
    codice: '024071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F838',
    cap: ['36024'],
    popolazione: 3082,
  },
  {
    nome: 'Napoli',
    codice: '063049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F839',
    cap: [
      '80121',
      '80122',
      '80123',
      '80124',
      '80125',
      '80126',
      '80127',
      '80128',
      '80129',
      '80130',
      '80131',
      '80132',
      '80133',
      '80134',
      '80135',
      '80136',
      '80137',
      '80138',
      '80139',
      '80140',
      '80141',
      '80142',
      '80143',
      '80144',
      '80145',
      '80146',
      '80147',
    ],
    popolazione: 962003,
  },
  {
    nome: 'Narbolia',
    codice: '095031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F840',
    cap: ['09070'],
    popolazione: 1801,
  },
  {
    nome: 'Narcao',
    codice: '111044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F841',
    cap: ['09010'],
    popolazione: 3373,
  },
  {
    nome: 'Nardò',
    codice: '075052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F842',
    cap: ['73048'],
    popolazione: 31688,
  },
  {
    nome: 'Nardodipace',
    codice: '102024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'F843',
    cap: ['89824'],
    popolazione: 1384,
  },
  {
    nome: 'Narni',
    codice: '055022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'F844',
    cap: ['05035'],
    popolazione: 20054,
  },
  {
    nome: 'Naro',
    codice: '084026',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'F845',
    cap: ['92028'],
    popolazione: 8103,
  },
  {
    nome: 'Narzole',
    codice: '004147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F846',
    cap: ['12068'],
    popolazione: 3532,
  },
  {
    nome: 'Nasino',
    codice: '009041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'F847',
    cap: ['17030'],
    popolazione: 222,
  },
  {
    nome: 'Naso',
    codice: '083060',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F848',
    cap: ['98074'],
    popolazione: 4015,
  },
  {
    nome: 'Naturno',
    codice: '021056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F849',
    cap: ['39025'],
    popolazione: 5554,
  },
  {
    nome: 'Nave',
    codice: '017117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F851',
    cap: ['25075'],
    popolazione: 10957,
  },
  {
    nome: 'Navelli',
    codice: '066058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'F852',
    cap: ['67020'],
    popolazione: 550,
  },
  {
    nome: 'Naz-Sciaves',
    codice: '021057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F856',
    cap: ['39040'],
    popolazione: 2886,
  },
  {
    nome: 'Nazzano',
    codice: '058069',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F857',
    cap: ['00060'],
    popolazione: 1361,
  },
  {
    nome: 'Ne',
    codice: '010040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F858',
    cap: ['16040'],
    popolazione: 2361,
  },
  {
    nome: 'Nebbiuno',
    codice: '003103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F859',
    cap: ['28010'],
    popolazione: 1856,
  },
  {
    nome: 'Negrar di Valpolicella',
    codice: '023052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F861',
    cap: ['37024'],
    popolazione: 16935,
  },
  {
    nome: 'Neirone',
    codice: '010041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'F862',
    cap: ['16040'],
    popolazione: 984,
  },
  {
    nome: 'Neive',
    codice: '004148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F863',
    cap: ['12052'],
    popolazione: 3341,
  },
  {
    nome: 'Nembro',
    codice: '016144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F864',
    cap: ['24027'],
    popolazione: 11542,
  },
  {
    nome: 'Nemi',
    codice: '058070',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F865',
    cap: ['00074'],
    popolazione: 1925,
  },
  {
    nome: 'Nemoli',
    codice: '076054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F866',
    cap: ['85040'],
    popolazione: 1512,
  },
  {
    nome: 'Neoneli',
    codice: '095032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F867',
    cap: ['09080'],
    popolazione: 713,
  },
  {
    nome: 'Nepi',
    codice: '056039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'F868',
    cap: ['01036'],
    popolazione: 9353,
  },
  {
    nome: 'Nereto',
    codice: '067031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F870',
    cap: ['64015'],
    popolazione: 5075,
  },
  {
    nome: 'Nerola',
    codice: '058071',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F871',
    cap: ['00017'],
    popolazione: 1821,
  },
  {
    nome: 'Nervesa della Battaglia',
    codice: '026050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F872',
    cap: ['31040'],
    popolazione: 6854,
  },
  {
    nome: 'Nerviano',
    codice: '015154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F874',
    cap: ['20014'],
    popolazione: 17089,
  },
  {
    nome: 'Nespolo',
    codice: '057046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'F876',
    cap: ['02020'],
    popolazione: 274,
  },
  {
    nome: 'Nesso',
    codice: '013161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F877',
    cap: ['22020'],
    popolazione: 1241,
  },
  {
    nome: 'Netro',
    codice: '096039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F878',
    cap: ['13896'],
    popolazione: 1015,
  },
  {
    nome: 'Nettuno',
    codice: '058072',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'F880',
    cap: ['00048'],
    popolazione: 45460,
  },
  {
    nome: 'Neviano',
    codice: '075053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F881',
    cap: ['73040'],
    popolazione: 5514,
  },
  {
    nome: 'Neviano degli Arduini',
    codice: '034024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'F882',
    cap: ['43024'],
    popolazione: 3691,
  },
  {
    nome: 'Neviglie',
    codice: '004149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F883',
    cap: ['12050'],
    popolazione: 425,
  },
  {
    nome: 'Niardo',
    codice: '017118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F884',
    cap: ['25050'],
    popolazione: 1950,
  },
  {
    nome: 'Nibbiola',
    codice: '003104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F886',
    cap: ['28070'],
    popolazione: 792,
  },
  {
    nome: 'Nibionno',
    codice: '097056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'F887',
    cap: ['23895'],
    popolazione: 3630,
  },
  {
    nome: 'Nichelino',
    codice: '001164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F889',
    cap: ['10042'],
    popolazione: 47851,
  },
  {
    nome: 'Nicolosi',
    codice: '087031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'F890',
    cap: ['95030'],
    popolazione: 7156,
  },
  {
    nome: 'Nicorvo',
    codice: '018103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'F891',
    cap: ['27020'],
    popolazione: 364,
  },
  {
    nome: 'Nicosia',
    codice: '086012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'F892',
    cap: ['94014'],
    popolazione: 14272,
  },
  {
    nome: 'Nicotera',
    codice: '102025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'F893',
    cap: ['89844'],
    popolazione: 6490,
  },
  {
    nome: 'Niella Belbo',
    codice: '004150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F894',
    cap: ['12050'],
    popolazione: 401,
  },
  {
    nome: 'Niella Tanaro',
    codice: '004151',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F895',
    cap: ['12060'],
    popolazione: 1035,
  },
  {
    nome: 'Nimis',
    codice: '030065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'F898',
    cap: ['33045'],
    popolazione: 2778,
  },
  {
    nome: 'Niscemi',
    codice: '085013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'F899',
    cap: ['93015'],
    popolazione: 27975,
  },
  {
    nome: 'Nissoria',
    codice: '086013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'F900',
    cap: ['94010'],
    popolazione: 2969,
  },
  {
    nome: 'Nizza di Sicilia',
    codice: '083061',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F901',
    cap: ['98026'],
    popolazione: 3723,
  },
  {
    nome: 'Nizza Monferrato',
    codice: '005080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'F902',
    cap: ['14049'],
    popolazione: 10372,
  },
  {
    nome: 'Noale',
    codice: '027026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'F904',
    cap: ['30033'],
    popolazione: 15708,
  },
  {
    nome: 'Noasca',
    codice: '001165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F906',
    cap: ['10080'],
    popolazione: 169,
  },
  {
    nome: 'Nocara',
    codice: '078086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'F907',
    cap: ['87070'],
    popolazione: 422,
  },
  {
    nome: 'Nocciano',
    codice: '068026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'F908',
    cap: ['65010'],
    popolazione: 1800,
  },
  {
    nome: 'Nocera Inferiore',
    codice: '065078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F912',
    cap: ['84014'],
    popolazione: 46563,
  },
  {
    nome: 'Nocera Superiore',
    codice: '065079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F913',
    cap: ['84015'],
    popolazione: 24151,
  },
  {
    nome: 'Nocera Terinese',
    codice: '079087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'F910',
    cap: ['88047'],
    popolazione: 4725,
  },
  {
    nome: 'Nocera Umbra',
    codice: '054034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F911',
    cap: ['06025'],
    popolazione: 5953,
  },
  {
    nome: 'Noceto',
    codice: '034025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'F914',
    cap: ['43015'],
    popolazione: 12705,
  },
  {
    nome: 'Noci',
    codice: '072031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'F915',
    cap: ['70015'],
    popolazione: 19285,
  },
  {
    nome: 'Nociglia',
    codice: '075054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F916',
    cap: ['73020'],
    popolazione: 2456,
  },
  {
    nome: 'Noepoli',
    codice: '076055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'F917',
    cap: ['85035'],
    popolazione: 974,
  },
  {
    nome: 'Nogara',
    codice: '023053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F918',
    cap: ['37054'],
    popolazione: 8574,
  },
  {
    nome: 'Nogaredo',
    codice: '022127',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F920',
    cap: ['38060'],
    popolazione: 1917,
  },
  {
    nome: 'Nogarole Rocca',
    codice: '023054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'F921',
    cap: ['37060'],
    popolazione: 3455,
  },
  {
    nome: 'Nogarole Vicentino',
    codice: '024072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F922',
    cap: ['36070'],
    popolazione: 1136,
  },
  {
    nome: 'Noicattaro',
    codice: '072032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'F923',
    cap: ['70016'],
    popolazione: 25710,
  },
  {
    nome: 'Nola',
    codice: '063050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'F924',
    cap: ['80035'],
    popolazione: 33979,
  },
  {
    nome: 'Nole',
    codice: '001166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F925',
    cap: ['10076'],
    popolazione: 6910,
  },
  {
    nome: 'Noli',
    codice: '009042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'F926',
    cap: ['17026'],
    popolazione: 2801,
  },
  {
    nome: 'Nomaglio',
    codice: '001167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F927',
    cap: ['10010'],
    popolazione: 312,
  },
  {
    nome: 'Nomi',
    codice: '022128',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F929',
    cap: ['38060'],
    popolazione: 1403,
  },
  {
    nome: 'Nonantola',
    codice: '036027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F930',
    cap: ['41015'],
    popolazione: 15179,
  },
  {
    nome: 'None',
    codice: '001168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F931',
    cap: ['10060'],
    popolazione: 7998,
  },
  {
    nome: 'Nonio',
    codice: '103048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'F932',
    cap: ['28891'],
    popolazione: 878,
  },
  {
    nome: 'Noragugume',
    codice: '091050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'F933',
    cap: ['08010'],
    popolazione: 338,
  },
  {
    nome: 'Norbello',
    codice: '095033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F934',
    cap: ['09070'],
    popolazione: 1178,
  },
  {
    nome: 'Norcia',
    codice: '054035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'F935',
    cap: ['06046'],
    popolazione: 4915,
  },
  {
    nome: 'Norma',
    codice: '059016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'F937',
    cap: ['04010'],
    popolazione: 4035,
  },
  {
    nome: 'Nosate',
    codice: '015155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F939',
    cap: ['20020'],
    popolazione: 689,
  },
  {
    nome: 'Notaresco',
    codice: '067032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F942',
    cap: ['64024'],
    popolazione: 6863,
  },
  {
    nome: 'Noto',
    codice: '089013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'F943',
    cap: ['96017'],
    popolazione: 23704,
  },
  {
    nome: 'Nova Levante',
    codice: '021058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F949',
    cap: ['39056'],
    popolazione: 1903,
  },
  {
    nome: 'Nova Milanese',
    codice: '108035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'F944',
    cap: ['20834'],
    popolazione: 22315,
  },
  {
    nome: 'Nova Ponente',
    codice: '021059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'F950',
    cap: ['39050'],
    popolazione: 3898,
  },
  {
    nome: 'Nova Siri',
    codice: '077018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'A942',
    cap: ['75020'],
    popolazione: 6596,
  },
  {
    nome: 'Novafeltria',
    codice: '099023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'F137',
    cap: ['47863'],
    popolazione: 7282,
  },
  {
    nome: 'Novaledo',
    codice: '022129',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'F947',
    cap: ['38050'],
    popolazione: 1018,
  },
  {
    nome: 'Novalesa',
    codice: '001169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'F948',
    cap: ['10050'],
    popolazione: 560,
  },
  {
    nome: 'Novara',
    codice: '003106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'F952',
    cap: ['28100'],
    popolazione: 101952,
  },
  {
    nome: 'Novara di Sicilia',
    codice: '083062',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'F951',
    cap: ['98058'],
    popolazione: 1413,
  },
  {
    nome: 'Novate Mezzola',
    codice: '014046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'F956',
    cap: ['23025'],
    popolazione: 1814,
  },
  {
    nome: 'Novate Milanese',
    codice: '015157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F955',
    cap: ['20026'],
    popolazione: 19938,
  },
  {
    nome: 'Nove',
    codice: '024073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F957',
    cap: ['36055'],
    popolazione: 5069,
  },
  {
    nome: 'Novedrate',
    codice: '013163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'F958',
    cap: ['22060'],
    popolazione: 2873,
  },
  {
    nome: 'Novella',
    codice: '022253',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M430',
    cap: ['38021', '38028', '38020'],
    popolazione: 3664,
  },
  {
    nome: 'Novellara',
    codice: '035028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'F960',
    cap: ['42017'],
    popolazione: 13455,
  },
  {
    nome: 'Novello',
    codice: '004152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F961',
    cap: ['12060'],
    popolazione: 1023,
  },
  {
    nome: 'Noventa di Piave',
    codice: '027027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'F963',
    cap: ['30020'],
    popolazione: 6843,
  },
  {
    nome: 'Noventa Padovana',
    codice: '028058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'F962',
    cap: ['35027'],
    popolazione: 10881,
  },
  {
    nome: 'Noventa Vicentina',
    codice: '024074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F964',
    cap: ['36025'],
    popolazione: 8810,
  },
  {
    nome: 'Novi di Modena',
    codice: '036028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F966',
    cap: ['41016'],
    popolazione: 10972,
  },
  {
    nome: 'Novi Ligure',
    codice: '006114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F965',
    cap: ['15067'],
    popolazione: 27682,
  },
  {
    nome: 'Novi Velia',
    codice: '065080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'F967',
    cap: ['84060'],
    popolazione: 2298,
  },
  {
    nome: 'Noviglio',
    codice: '015158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'F968',
    cap: ['20082'],
    popolazione: 4237,
  },
  {
    nome: 'Novoli',
    codice: '075055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'F970',
    cap: ['73051'],
    popolazione: 8211,
  },
  {
    nome: 'Nucetto',
    codice: '004153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'F972',
    cap: ['12070'],
    popolazione: 432,
  },
  {
    nome: 'Nughedu San Nicolò',
    codice: '090044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F975',
    cap: ['07010'],
    popolazione: 874,
  },
  {
    nome: 'Nughedu Santa Vittoria',
    codice: '095034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F974',
    cap: ['09080'],
    popolazione: 508,
  },
  {
    nome: 'Nule',
    codice: '090045',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F976',
    cap: ['07010'],
    popolazione: 1427,
  },
  {
    nome: 'Nulvi',
    codice: '090046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'F977',
    cap: ['07032'],
    popolazione: 2851,
  },
  {
    nome: 'Numana',
    codice: '042032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F978',
    cap: ['60026'],
    popolazione: 3716,
  },
  {
    nome: 'Nuoro',
    codice: '091051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'F979',
    cap: ['08100'],
    popolazione: 36674,
  },
  {
    nome: 'Nurachi',
    codice: '095035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F980',
    cap: ['09070'],
    popolazione: 1790,
  },
  {
    nome: 'Nuragus',
    codice: '111045',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F981',
    cap: ['09057'],
    popolazione: 942,
  },
  {
    nome: 'Nurallao',
    codice: '111046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F982',
    cap: ['09058'],
    popolazione: 1357,
  },
  {
    nome: 'Nuraminis',
    codice: '111047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F983',
    cap: ['09024'],
    popolazione: 2606,
  },
  {
    nome: 'Nureci',
    codice: '095036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F985',
    cap: ['09080'],
    popolazione: 348,
  },
  {
    nome: 'Nurri',
    codice: '111048',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F986',
    cap: ['09059'],
    popolazione: 2233,
  },
  {
    nome: 'Nus',
    codice: '007045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'F987',
    cap: ['11020'],
    popolazione: 2943,
  },
  {
    nome: 'Nusco',
    codice: '064066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'F988',
    cap: ['83051'],
    popolazione: 4258,
  },
  {
    nome: 'Nuvolento',
    codice: '017119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F989',
    cap: ['25080'],
    popolazione: 4011,
  },
  {
    nome: 'Nuvolera',
    codice: '017120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'F990',
    cap: ['25080'],
    popolazione: 4535,
  },
  {
    nome: 'Nuxis',
    codice: '111049',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'F991',
    cap: ['09010'],
    popolazione: 1631,
  },
  {
    nome: 'Occhieppo Inferiore',
    codice: '096040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F992',
    cap: ['13897'],
    popolazione: 3980,
  },
  {
    nome: 'Occhieppo Superiore',
    codice: '096041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'F993',
    cap: ['13898'],
    popolazione: 2821,
  },
  {
    nome: 'Occhiobello',
    codice: '029033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'F994',
    cap: ['45030'],
    popolazione: 11351,
  },
  {
    nome: 'Occimiano',
    codice: '006115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F995',
    cap: ['15040'],
    popolazione: 1367,
  },
  {
    nome: 'Ocre',
    codice: '066059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'F996',
    cap: ['67040'],
    popolazione: 1110,
  },
  {
    nome: 'Odalengo Grande',
    codice: '006116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F997',
    cap: ['15020'],
    popolazione: 487,
  },
  {
    nome: 'Odalengo Piccolo',
    codice: '006117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'F998',
    cap: ['15020'],
    popolazione: 270,
  },
  {
    nome: 'Oderzo',
    codice: '026051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F999',
    cap: ['31046'],
    popolazione: 20068,
  },
  {
    nome: 'Odolo',
    codice: '017121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G001',
    cap: ['25076'],
    popolazione: 2086,
  },
  {
    nome: 'Ofena',
    codice: '066060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G002',
    cap: ['67025'],
    popolazione: 527,
  },
  {
    nome: 'Offagna',
    codice: '042033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'G003',
    cap: ['60020'],
    popolazione: 1880,
  },
  {
    nome: 'Offanengo',
    codice: '019062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G004',
    cap: ['26010'],
    popolazione: 5869,
  },
  {
    nome: 'Offida',
    codice: '044054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'G005',
    cap: ['63073'],
    popolazione: 5215,
  },
  {
    nome: 'Offlaga',
    codice: '017122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G006',
    cap: ['25020'],
    popolazione: 4269,
  },
  {
    nome: 'Oggebbio',
    codice: '103049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'G007',
    cap: ['28824'],
    popolazione: 881,
  },
  {
    nome: 'Oggiona con Santo Stefano',
    codice: '012107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'G008',
    cap: ['21040'],
    popolazione: 4295,
  },
  {
    nome: 'Oggiono',
    codice: '097057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G009',
    cap: ['23848'],
    popolazione: 8750,
  },
  {
    nome: 'Oglianico',
    codice: '001170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G010',
    cap: ['10080'],
    popolazione: 1426,
  },
  {
    nome: 'Ogliastro Cilento',
    codice: '065081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G011',
    cap: ['84061'],
    popolazione: 2241,
  },
  {
    nome: 'Olbia',
    codice: '090047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G015',
    cap: ['07026'],
    popolazione: 53307,
  },
  {
    nome: 'Olcenengo',
    codice: '002088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G016',
    cap: ['13047'],
    popolazione: 754,
  },
  {
    nome: 'Oldenico',
    codice: '002089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G018',
    cap: ['13030'],
    popolazione: 252,
  },
  {
    nome: 'Oleggio',
    codice: '003108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G019',
    cap: ['28047'],
    popolazione: 13650,
  },
  {
    nome: 'Oleggio Castello',
    codice: '003109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G020',
    cap: ['28040'],
    popolazione: 1968,
  },
  {
    nome: 'Olevano di Lomellina',
    codice: '018104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G021',
    cap: ['27020'],
    popolazione: 783,
  },
  {
    nome: 'Olevano Romano',
    codice: '058073',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G022',
    cap: ['00035'],
    popolazione: 6742,
  },
  {
    nome: 'Olevano sul Tusciano',
    codice: '065082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G023',
    cap: ['84062'],
    popolazione: 6883,
  },
  {
    nome: 'Olgiate Comasco',
    codice: '013165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G025',
    cap: ['22077'],
    popolazione: 11401,
  },
  {
    nome: 'Olgiate Molgora',
    codice: '097058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G026',
    cap: ['23887'],
    popolazione: 6190,
  },
  {
    nome: 'Olgiate Olona',
    codice: '012108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'G028',
    cap: ['21057'],
    popolazione: 12215,
  },
  {
    nome: 'Olginate',
    codice: '097059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G030',
    cap: ['23854'],
    popolazione: 7102,
  },
  {
    nome: 'Oliena',
    codice: '091055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G031',
    cap: ['08025'],
    popolazione: 7355,
  },
  {
    nome: 'Oliva Gessi',
    codice: '018105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G032',
    cap: ['27050'],
    popolazione: 173,
  },
  {
    nome: 'Olivadi',
    codice: '079088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'G034',
    cap: ['88067'],
    popolazione: 587,
  },
  {
    nome: 'Oliveri',
    codice: '083063',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'G036',
    cap: ['98060'],
    popolazione: 2157,
  },
  {
    nome: 'Oliveto Citra',
    codice: '065083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G039',
    cap: ['84020'],
    popolazione: 3832,
  },
  {
    nome: 'Oliveto Lario',
    codice: '097060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G040',
    cap: ['23865'],
    popolazione: 1192,
  },
  {
    nome: 'Oliveto Lucano',
    codice: '077019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'G037',
    cap: ['75010'],
    popolazione: 494,
  },
  {
    nome: 'Olivetta San Michele',
    codice: '008038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G041',
    cap: ['18030'],
    popolazione: 225,
  },
  {
    nome: 'Olivola',
    codice: '006118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G042',
    cap: ['15030'],
    popolazione: 123,
  },
  {
    nome: 'Ollastra',
    codice: '095037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'G043',
    cap: ['09088'],
    popolazione: 1255,
  },
  {
    nome: 'Ollolai',
    codice: '091056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G044',
    cap: ['08020'],
    popolazione: 1373,
  },
  {
    nome: 'Ollomont',
    codice: '007046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G045',
    cap: ['11010'],
    popolazione: 158,
  },
  {
    nome: 'Olmedo',
    codice: '090048',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G046',
    cap: ['07040'],
    popolazione: 4001,
  },
  {
    nome: 'Olmeneta',
    codice: '019063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G047',
    cap: ['26010'],
    popolazione: 967,
  },
  {
    nome: 'Olmo al Brembo',
    codice: '016145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G049',
    cap: ['24010'],
    popolazione: 518,
  },
  {
    nome: 'Olmo Gentile',
    codice: '005081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G048',
    cap: ['14050'],
    popolazione: 90,
  },
  {
    nome: 'Oltre il Colle',
    codice: '016146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G050',
    cap: ['24013'],
    popolazione: 1058,
  },
  {
    nome: 'Oltressenda Alta',
    codice: '016147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G054',
    cap: ['24020'],
    popolazione: 174,
  },
  {
    nome: 'Oltrona di San Mamette',
    codice: '013169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G056',
    cap: ['22070'],
    popolazione: 2289,
  },
  {
    nome: 'Olzai',
    codice: '091057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G058',
    cap: ['08020'],
    popolazione: 903,
  },
  {
    nome: 'Ome',
    codice: '017123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G061',
    cap: ['25050'],
    popolazione: 3238,
  },
  {
    nome: 'Omegna',
    codice: '103050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'G062',
    cap: ['28887'],
    popolazione: 15744,
  },
  {
    nome: 'Omignano',
    codice: '065084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G063',
    cap: ['84060'],
    popolazione: 1579,
  },
  {
    nome: 'Onanì',
    codice: '091058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G064',
    cap: ['08020'],
    popolazione: 430,
  },
  {
    nome: 'Onano',
    codice: '056040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'G065',
    cap: ['01010'],
    popolazione: 1017,
  },
  {
    nome: 'Oncino',
    codice: '004154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G066',
    cap: ['12030'],
    popolazione: 82,
  },
  {
    nome: 'Oneta',
    codice: '016148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G068',
    cap: ['24020'],
    popolazione: 652,
  },
  {
    nome: 'Onifai',
    codice: '091059',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G070',
    cap: ['08020'],
    popolazione: 742,
  },
  {
    nome: 'Oniferi',
    codice: '091060',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G071',
    cap: ['08020'],
    popolazione: 925,
  },
  {
    nome: 'Ono San Pietro',
    codice: '017124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G074',
    cap: ['25040'],
    popolazione: 1002,
  },
  {
    nome: 'Onore',
    codice: '016149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G075',
    cap: ['24020'],
    popolazione: 846,
  },
  {
    nome: 'Onzo',
    codice: '009043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G076',
    cap: ['17037'],
    popolazione: 234,
  },
  {
    nome: 'Opera',
    codice: '015159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G078',
    cap: ['20090'],
    popolazione: 13226,
  },
  {
    nome: 'Opi',
    codice: '066061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G079',
    cap: ['67030'],
    popolazione: 428,
  },
  {
    nome: 'Oppeano',
    codice: '023055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'G080',
    cap: ['37050'],
    popolazione: 9427,
  },
  {
    nome: 'Oppido Lucano',
    codice: '076056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G081',
    cap: ['85015'],
    popolazione: 3860,
  },
  {
    nome: 'Oppido Mamertina',
    codice: '080055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G082',
    cap: ['89014'],
    popolazione: 5406,
  },
  {
    nome: 'Ora',
    codice: '021060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G083',
    cap: ['39040'],
    popolazione: 3554,
  },
  {
    nome: 'Orani',
    codice: '091061',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G084',
    cap: ['08026'],
    popolazione: 3007,
  },
  {
    nome: 'Oratino',
    codice: '070049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G086',
    cap: ['86010'],
    popolazione: 1560,
  },
  {
    nome: 'Orbassano',
    codice: '001171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G087',
    cap: ['10043'],
    popolazione: 22537,
  },
  {
    nome: 'Orbetello',
    codice: '053018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'G088',
    cap: ['58015'],
    popolazione: 14705,
  },
  {
    nome: 'Orciano Pisano',
    codice: '050023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G090',
    cap: ['56040'],
    popolazione: 635,
  },
  {
    nome: 'Orco Feglino',
    codice: '009044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'D522',
    cap: ['17024'],
    popolazione: 895,
  },
  {
    nome: 'Ordona',
    codice: '071063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'M266',
    cap: ['71040'],
    popolazione: 2654,
  },
  {
    nome: 'Orero',
    codice: '010042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'G093',
    cap: ['16040'],
    popolazione: 604,
  },
  {
    nome: 'Orgiano',
    codice: '024075',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G095',
    cap: ['36040'],
    popolazione: 3151,
  },
  {
    nome: 'Orgosolo',
    codice: '091062',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G097',
    cap: ['08027'],
    popolazione: 4347,
  },
  {
    nome: 'Oria',
    codice: '074011',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'G098',
    cap: ['72024'],
    popolazione: 15228,
  },
  {
    nome: 'Oricola',
    codice: '066062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G102',
    cap: ['67063'],
    popolazione: 1155,
  },
  {
    nome: 'Origgio',
    codice: '012109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'G103',
    cap: ['21040'],
    popolazione: 7416,
  },
  {
    nome: 'Orino',
    codice: '012110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'G105',
    cap: ['21030'],
    popolazione: 840,
  },
  {
    nome: 'Orio al Serio',
    codice: '016150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G108',
    cap: ['24050'],
    popolazione: 1733,
  },
  {
    nome: 'Orio Canavese',
    codice: '001172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G109',
    cap: ['10010'],
    popolazione: 829,
  },
  {
    nome: 'Orio Litta',
    codice: '098042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'G107',
    cap: ['26863'],
    popolazione: 2023,
  },
  {
    nome: 'Oriolo',
    codice: '078087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G110',
    cap: ['87073'],
    popolazione: 2386,
  },
  {
    nome: 'Oriolo Romano',
    codice: '056041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'G111',
    cap: ['01010'],
    popolazione: 3648,
  },
  {
    nome: 'Oristano',
    codice: '095038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'G113',
    cap: ['09170'],
    popolazione: 31155,
  },
  {
    nome: 'Ormea',
    codice: '004155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G114',
    cap: ['12078'],
    popolazione: 1723,
  },
  {
    nome: 'Ormelle',
    codice: '026052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G115',
    cap: ['31024'],
    popolazione: 4464,
  },
  {
    nome: 'Ornago',
    codice: '108036',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'G116',
    cap: ['20876'],
    popolazione: 4702,
  },
  {
    nome: 'Ornavasso',
    codice: '103051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'G117',
    cap: ['28877'],
    popolazione: 3407,
  },
  {
    nome: 'Ornica',
    codice: '016151',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G118',
    cap: ['24010'],
    popolazione: 172,
  },
  {
    nome: 'Orosei',
    codice: '091063',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G119',
    cap: ['08028'],
    popolazione: 6794,
  },
  {
    nome: 'Orotelli',
    codice: '091064',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G120',
    cap: ['08020'],
    popolazione: 2152,
  },
  {
    nome: 'Orria',
    codice: '065085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G121',
    cap: ['84060'],
    popolazione: 1161,
  },
  {
    nome: 'Orroli',
    codice: '111050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G122',
    cap: ['09061'],
    popolazione: 2397,
  },
  {
    nome: 'Orsago',
    codice: '026053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G123',
    cap: ['31010'],
    popolazione: 3917,
  },
  {
    nome: 'Orsara Bormida',
    codice: '006119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G124',
    cap: ['15010'],
    popolazione: 406,
  },
  {
    nome: 'Orsara di Puglia',
    codice: '071035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'G125',
    cap: ['71027'],
    popolazione: 2914,
  },
  {
    nome: 'Orsenigo',
    codice: '013170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G126',
    cap: ['22030'],
    popolazione: 2757,
  },
  {
    nome: 'Orsogna',
    codice: '069057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G128',
    cap: ['66036'],
    popolazione: 4008,
  },
  {
    nome: 'Orsomarso',
    codice: '078088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G129',
    cap: ['87020'],
    popolazione: 1338,
  },
  {
    nome: 'Orta di Atella',
    codice: '061053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G130',
    cap: ['81030'],
    popolazione: 24796,
  },
  {
    nome: 'Orta Nova',
    codice: '071036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'G131',
    cap: ['71045'],
    popolazione: 16999,
  },
  {
    nome: 'Orta San Giulio',
    codice: '003112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G134',
    cap: ['28016'],
    popolazione: 1163,
  },
  {
    nome: 'Ortacesus',
    codice: '111051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G133',
    cap: ['09040'],
    popolazione: 933,
  },
  {
    nome: 'Orte',
    codice: '056042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'G135',
    cap: ['01028'],
    popolazione: 8665,
  },
  {
    nome: 'Ortelle',
    codice: '075056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'G136',
    cap: ['73030'],
    popolazione: 2359,
  },
  {
    nome: 'Ortezzano',
    codice: '109029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'G137',
    cap: ['63851'],
    popolazione: 791,
  },
  {
    nome: 'Ortignano Raggiolo',
    codice: '051027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'G139',
    cap: ['52010'],
    popolazione: 878,
  },
  {
    nome: 'Ortisei',
    codice: '021061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G140',
    cap: ['39046'],
    popolazione: 4653,
  },
  {
    nome: 'Ortona',
    codice: '069058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G141',
    cap: ['66026'],
    popolazione: 23425,
  },
  {
    nome: 'Ortona dei Marsi',
    codice: '066063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G142',
    cap: ['67050'],
    popolazione: 592,
  },
  {
    nome: 'Ortovero',
    codice: '009045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G144',
    cap: ['17037'],
    popolazione: 1583,
  },
  {
    nome: 'Ortucchio',
    codice: '066064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G145',
    cap: ['67050'],
    popolazione: 1863,
  },
  {
    nome: 'Ortueri',
    codice: '091066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G146',
    cap: ['08036'],
    popolazione: 1262,
  },
  {
    nome: 'Orune',
    codice: '091067',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G147',
    cap: ['08020'],
    popolazione: 2561,
  },
  {
    nome: 'Orvieto',
    codice: '055023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'G148',
    cap: ['05018'],
    popolazione: 21064,
  },
  {
    nome: 'Orvinio',
    codice: '057047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'B595',
    cap: ['02035'],
    popolazione: 448,
  },
  {
    nome: 'Orzinuovi',
    codice: '017125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G149',
    cap: ['25034'],
    popolazione: 12343,
  },
  {
    nome: 'Orzivecchi',
    codice: '017126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G150',
    cap: ['25030'],
    popolazione: 2485,
  },
  {
    nome: 'Osasco',
    codice: '001173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G151',
    cap: ['10060'],
    popolazione: 1124,
  },
  {
    nome: 'Osasio',
    codice: '001174',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G152',
    cap: ['10040'],
    popolazione: 913,
  },
  {
    nome: 'Oschiri',
    codice: '090049',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G153',
    cap: ['07027'],
    popolazione: 3436,
  },
  {
    nome: 'Osidda',
    codice: '091068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G154',
    cap: ['08020'],
    popolazione: 230,
  },
  {
    nome: 'Osiglia',
    codice: '009046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G155',
    cap: ['17010'],
    popolazione: 469,
  },
  {
    nome: 'Osilo',
    codice: '090050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G156',
    cap: ['07033'],
    popolazione: 3204,
  },
  {
    nome: 'Osimo',
    codice: '042034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'G157',
    cap: ['60027'],
    popolazione: 33991,
  },
  {
    nome: 'Osini',
    codice: '091069',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G158',
    cap: ['08040'],
    popolazione: 811,
  },
  {
    nome: 'Osio Sopra',
    codice: '016152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G159',
    cap: ['24040'],
    popolazione: 5079,
  },
  {
    nome: 'Osio Sotto',
    codice: '016153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G160',
    cap: ['24046'],
    popolazione: 12080,
  },
  {
    nome: 'Osnago',
    codice: '097061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G161',
    cap: ['23875'],
    popolazione: 4807,
  },
  {
    nome: 'Osoppo',
    codice: '030066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G163',
    cap: ['33010'],
    popolazione: 3006,
  },
  {
    nome: 'Ospedaletti',
    codice: '008039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G164',
    cap: ['18014'],
    popolazione: 3386,
  },
  {
    nome: 'Ospedaletto',
    codice: '022130',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G168',
    cap: ['38050'],
    popolazione: 820,
  },
  {
    nome: "Ospedaletto d'Alpinolo",
    codice: '064067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G165',
    cap: ['83014'],
    popolazione: 1970,
  },
  {
    nome: 'Ospedaletto Euganeo',
    codice: '028059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G167',
    cap: ['35045'],
    popolazione: 5876,
  },
  {
    nome: 'Ospedaletto Lodigiano',
    codice: '098043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'G166',
    cap: ['26864'],
    popolazione: 1853,
  },
  {
    nome: 'Ospitale di Cadore',
    codice: '025035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'G169',
    cap: ['32010'],
    popolazione: 326,
  },
  {
    nome: 'Ospitaletto',
    codice: '017127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G170',
    cap: ['25035'],
    popolazione: 13579,
  },
  {
    nome: 'Ossago Lodigiano',
    codice: '098044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'G171',
    cap: ['26816'],
    popolazione: 1412,
  },
  {
    nome: 'Ossana',
    codice: '022131',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G173',
    cap: ['38026'],
    popolazione: 844,
  },
  {
    nome: 'Ossi',
    codice: '090051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G178',
    cap: ['07045'],
    popolazione: 5876,
  },
  {
    nome: 'Ossimo',
    codice: '017128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G179',
    cap: ['25050'],
    popolazione: 1442,
  },
  {
    nome: 'Ossona',
    codice: '015164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G181',
    cap: ['20010'],
    popolazione: 4134,
  },
  {
    nome: 'Ostana',
    codice: '004156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G183',
    cap: ['12030'],
    popolazione: 81,
  },
  {
    nome: 'Ostellato',
    codice: '038017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'G184',
    cap: ['44020'],
    popolazione: 6453,
  },
  {
    nome: 'Ostiano',
    codice: '019064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G185',
    cap: ['26032'],
    popolazione: 3020,
  },
  {
    nome: 'Ostiglia',
    codice: '020038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G186',
    cap: ['46035'],
    popolazione: 6940,
  },
  {
    nome: 'Ostra',
    codice: '042035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F401',
    cap: ['60010'],
    popolazione: 6743,
  },
  {
    nome: 'Ostra Vetere',
    codice: '042036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'F581',
    cap: ['60010'],
    popolazione: 3471,
  },
  {
    nome: 'Ostuni',
    codice: '074012',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'G187',
    cap: ['72017'],
    popolazione: 31860,
  },
  {
    nome: 'Otranto',
    codice: '075057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'G188',
    cap: ['73028'],
    popolazione: 5622,
  },
  {
    nome: 'Otricoli',
    codice: '055024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'G189',
    cap: ['05030'],
    popolazione: 1915,
  },
  {
    nome: 'Ottana',
    codice: '091070',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G191',
    cap: ['08020'],
    popolazione: 2384,
  },
  {
    nome: 'Ottati',
    codice: '065086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G192',
    cap: ['84020'],
    popolazione: 680,
  },
  {
    nome: 'Ottaviano',
    codice: '063051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G190',
    cap: ['80044'],
    popolazione: 23543,
  },
  {
    nome: 'Ottiglio',
    codice: '006120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G193',
    cap: ['15038'],
    popolazione: 672,
  },
  {
    nome: 'Ottobiano',
    codice: '018106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G194',
    cap: ['27030'],
    popolazione: 1181,
  },
  {
    nome: 'Ottone',
    codice: '033030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G195',
    cap: ['29026'],
    popolazione: 570,
  },
  {
    nome: 'Oulx',
    codice: '001175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G196',
    cap: ['10056'],
    popolazione: 3160,
  },
  {
    nome: 'Ovada',
    codice: '006121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G197',
    cap: ['15076'],
    popolazione: 11685,
  },
  {
    nome: 'Ovaro',
    codice: '030067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G198',
    cap: ['33025'],
    popolazione: 2010,
  },
  {
    nome: 'Oviglio',
    codice: '006122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G199',
    cap: ['15026'],
    popolazione: 1319,
  },
  {
    nome: 'Ovindoli',
    codice: '066065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G200',
    cap: ['67046'],
    popolazione: 1190,
  },
  {
    nome: 'Ovodda',
    codice: '091071',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G201',
    cap: ['08020'],
    popolazione: 1627,
  },
  {
    nome: 'Oyace',
    codice: '007047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G012',
    cap: ['11010'],
    popolazione: 225,
  },
  {
    nome: 'Ozegna',
    codice: '001176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G202',
    cap: ['10080'],
    popolazione: 1235,
  },
  {
    nome: 'Ozieri',
    codice: '090052',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G203',
    cap: ['07014'],
    popolazione: 10881,
  },
  {
    nome: "Ozzano dell'Emilia",
    codice: '037046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'G205',
    cap: ['40064'],
    popolazione: 12870,
  },
  {
    nome: 'Ozzano Monferrato',
    codice: '006123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G204',
    cap: ['15039'],
    popolazione: 1506,
  },
  {
    nome: 'Ozzero',
    codice: '015165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G206',
    cap: ['20080'],
    popolazione: 1467,
  },
  {
    nome: 'Pabillonis',
    codice: '111052',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G207',
    cap: ['09030'],
    popolazione: 2948,
  },
  {
    nome: 'Pace del Mela',
    codice: '083064',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'G209',
    cap: ['98042'],
    popolazione: 6388,
  },
  {
    nome: 'Paceco',
    codice: '081013',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'G208',
    cap: ['91027'],
    popolazione: 11487,
  },
  {
    nome: 'Pacentro',
    codice: '066066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G210',
    cap: ['67030'],
    popolazione: 1211,
  },
  {
    nome: 'Pachino',
    codice: '089014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'G211',
    cap: ['96018'],
    popolazione: 22068,
  },
  {
    nome: 'Paciano',
    codice: '054036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G212',
    cap: ['06060'],
    popolazione: 982,
  },
  {
    nome: 'Padenghe sul Garda',
    codice: '017129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G213',
    cap: ['25080'],
    popolazione: 4276,
  },
  {
    nome: 'Paderna',
    codice: '006124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G215',
    cap: ['15050'],
    popolazione: 231,
  },
  {
    nome: "Paderno d'Adda",
    codice: '097062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G218',
    cap: ['23877'],
    popolazione: 3881,
  },
  {
    nome: 'Paderno Dugnano',
    codice: '015166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G220',
    cap: ['20037'],
    popolazione: 46562,
  },
  {
    nome: 'Paderno Franciacorta',
    codice: '017130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G217',
    cap: ['25050'],
    popolazione: 3699,
  },
  {
    nome: 'Paderno Ponchielli',
    codice: '019065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G222',
    cap: ['26024'],
    popolazione: 1480,
  },
  {
    nome: 'Padova',
    codice: '028060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G224',
    cap: [
      '35121',
      '35122',
      '35123',
      '35124',
      '35125',
      '35126',
      '35127',
      '35128',
      '35129',
      '35130',
      '35131',
      '35132',
      '35133',
      '35134',
      '35135',
      '35136',
      '35137',
      '35138',
      '35139',
      '35140',
      '35141',
      '35142',
      '35143',
    ],
    popolazione: 206192,
  },
  {
    nome: 'Padria',
    codice: '090053',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G225',
    cap: ['07015'],
    popolazione: 695,
  },
  {
    nome: 'Padru',
    codice: '090090',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M301',
    cap: ['07020'],
    popolazione: 2130,
  },
  {
    nome: 'Padula',
    codice: '065087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G226',
    cap: ['84034'],
    popolazione: 5279,
  },
  {
    nome: 'Paduli',
    codice: '062045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G227',
    cap: ['82020'],
    popolazione: 4085,
  },
  {
    nome: 'Paesana',
    codice: '004157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G228',
    cap: ['12034'],
    popolazione: 2868,
  },
  {
    nome: 'Paese',
    codice: '026055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G229',
    cap: ['31038'],
    popolazione: 21432,
  },
  {
    nome: 'Pagani',
    codice: '065088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G230',
    cap: ['84016'],
    popolazione: 34671,
  },
  {
    nome: 'Paganico Sabino',
    codice: '057048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G232',
    cap: ['02020'],
    popolazione: 172,
  },
  {
    nome: 'Pagazzano',
    codice: '016154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G233',
    cap: ['24040'],
    popolazione: 2082,
  },
  {
    nome: 'Pagliara',
    codice: '083065',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'G234',
    cap: ['98020'],
    popolazione: 1230,
  },
  {
    nome: 'Paglieta',
    codice: '069059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G237',
    cap: ['66020'],
    popolazione: 4466,
  },
  {
    nome: 'Pagnacco',
    codice: '030068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G238',
    cap: ['33010'],
    popolazione: 5044,
  },
  {
    nome: 'Pagno',
    codice: '004158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G240',
    cap: ['12030'],
    popolazione: 572,
  },
  {
    nome: 'Pagnona',
    codice: '097063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G241',
    cap: ['23833'],
    popolazione: 402,
  },
  {
    nome: 'Pago del Vallo di Lauro',
    codice: '064068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G242',
    cap: ['83020'],
    popolazione: 1851,
  },
  {
    nome: 'Pago Veiano',
    codice: '062046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G243',
    cap: ['82020'],
    popolazione: 2545,
  },
  {
    nome: 'Paisco Loveno',
    codice: '017131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G247',
    cap: ['25050'],
    popolazione: 198,
  },
  {
    nome: 'Paitone',
    codice: '017132',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G248',
    cap: ['25080'],
    popolazione: 2091,
  },
  {
    nome: 'Paladina',
    codice: '016155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G249',
    cap: ['24030'],
    popolazione: 3996,
  },
  {
    nome: 'Palagano',
    codice: '036029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'G250',
    cap: ['41046'],
    popolazione: 2354,
  },
  {
    nome: 'Palagianello',
    codice: '073020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'G251',
    cap: ['74018'],
    popolazione: 7854,
  },
  {
    nome: 'Palagiano',
    codice: '073021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'G252',
    cap: ['74019'],
    popolazione: 16052,
  },
  {
    nome: 'Palagonia',
    codice: '087032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'G253',
    cap: ['95046'],
    popolazione: 16540,
  },
  {
    nome: 'Palaia',
    codice: '050024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G254',
    cap: ['56036'],
    popolazione: 4572,
  },
  {
    nome: 'Palanzano',
    codice: '034026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'G255',
    cap: ['43025'],
    popolazione: 1165,
  },
  {
    nome: 'Palata',
    codice: '070050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G257',
    cap: ['86037'],
    popolazione: 1769,
  },
  {
    nome: 'Palau',
    codice: '090054',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G258',
    cap: ['07020'],
    popolazione: 3772,
  },
  {
    nome: 'Palazzago',
    codice: '016156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G259',
    cap: ['24030'],
    popolazione: 4293,
  },
  {
    nome: 'Palazzo Adriano',
    codice: '082052',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G263',
    cap: ['90030'],
    popolazione: 2227,
  },
  {
    nome: 'Palazzo Canavese',
    codice: '001177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G262',
    cap: ['10010'],
    popolazione: 843,
  },
  {
    nome: 'Palazzo Pignano',
    codice: '019066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G260',
    cap: ['26020'],
    popolazione: 3848,
  },
  {
    nome: 'Palazzo San Gervasio',
    codice: '076057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G261',
    cap: ['85026'],
    popolazione: 5027,
  },
  {
    nome: 'Palazzolo Acreide',
    codice: '089015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'G267',
    cap: ['96010'],
    popolazione: 9091,
  },
  {
    nome: 'Palazzolo dello Stella',
    codice: '030069',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G268',
    cap: ['33056'],
    popolazione: 3008,
  },
  {
    nome: "Palazzolo sull'Oglio",
    codice: '017133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G264',
    cap: ['25036'],
    popolazione: 19472,
  },
  {
    nome: 'Palazzolo Vercellese',
    codice: '002090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G266',
    cap: ['13040'],
    popolazione: 1292,
  },
  {
    nome: 'Palazzuolo sul Senio',
    codice: '048031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'G270',
    cap: ['50035'],
    popolazione: 1188,
  },
  {
    nome: 'Palena',
    codice: '069060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G271',
    cap: ['66017'],
    popolazione: 1412,
  },
  {
    nome: 'Palermiti',
    codice: '079089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'G272',
    cap: ['88050'],
    popolazione: 1275,
  },
  {
    nome: 'Palermo',
    codice: '082053',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G273',
    cap: [
      '90121',
      '90122',
      '90123',
      '90124',
      '90125',
      '90126',
      '90127',
      '90128',
      '90129',
      '90130',
      '90131',
      '90132',
      '90133',
      '90134',
      '90135',
      '90136',
      '90137',
      '90138',
      '90139',
      '90140',
      '90141',
      '90142',
      '90143',
      '90144',
      '90145',
      '90146',
      '90147',
      '90148',
      '90149',
      '90150',
      '90151',
    ],
    popolazione: 657561,
  },
  {
    nome: 'Palestrina',
    codice: '058074',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G274',
    cap: ['00036'],
    popolazione: 20498,
  },
  {
    nome: 'Palestro',
    codice: '018107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G275',
    cap: ['27030'],
    popolazione: 1885,
  },
  {
    nome: 'Paliano',
    codice: '060046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G276',
    cap: ['03018'],
    popolazione: 8146,
  },
  {
    nome: 'Palizzi',
    codice: '080056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G277',
    cap: ['89038'],
    popolazione: 2297,
  },
  {
    nome: 'Pallagorio',
    codice: '101016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'G278',
    cap: ['88818'],
    popolazione: 1337,
  },
  {
    nome: 'Pallanzeno',
    codice: '103052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'G280',
    cap: ['28884'],
    popolazione: 1176,
  },
  {
    nome: 'Pallare',
    codice: '009047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G281',
    cap: ['17043'],
    popolazione: 957,
  },
  {
    nome: 'Palma Campania',
    codice: '063052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G283',
    cap: ['80036'],
    popolazione: 14905,
  },
  {
    nome: 'Palma di Montechiaro',
    codice: '084027',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'G282',
    cap: ['92020'],
    popolazione: 23643,
  },
  {
    nome: 'Palmanova',
    codice: '030070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G284',
    cap: ['33057'],
    popolazione: 5409,
  },
  {
    nome: 'Palmariggi',
    codice: '075058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'G285',
    cap: ['73020'],
    popolazione: 1554,
  },
  {
    nome: 'Palmas Arborea',
    codice: '095039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'G286',
    cap: ['09090'],
    popolazione: 1482,
  },
  {
    nome: 'Palmi',
    codice: '080057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G288',
    cap: ['89015'],
    popolazione: 18721,
  },
  {
    nome: 'Palmiano',
    codice: '044056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'G289',
    cap: ['63092'],
    popolazione: 214,
  },
  {
    nome: 'Palmoli',
    codice: '069061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G290',
    cap: ['66050'],
    popolazione: 980,
  },
  {
    nome: 'Palo del Colle',
    codice: '072033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'G291',
    cap: ['70027'],
    popolazione: 21555,
  },
  {
    nome: 'Palombara Sabina',
    codice: '058075',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G293',
    cap: ['00018'],
    popolazione: 12167,
  },
  {
    nome: 'Palombaro',
    codice: '069062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G294',
    cap: ['66010'],
    popolazione: 1108,
  },
  {
    nome: 'Palomonte',
    codice: '065089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G292',
    cap: ['84020'],
    popolazione: 4049,
  },
  {
    nome: 'Palosco',
    codice: '016157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G295',
    cap: ['24050'],
    popolazione: 5773,
  },
  {
    nome: 'Palù',
    codice: '023056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'G297',
    cap: ['37050'],
    popolazione: 1284,
  },
  {
    nome: 'Palù del Fersina',
    codice: '022133',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G296',
    cap: ['38050'],
    popolazione: 169,
  },
  {
    nome: 'Paludi',
    codice: '078089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G298',
    cap: ['87060'],
    popolazione: 1134,
  },
  {
    nome: 'Paluzza',
    codice: '030071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G300',
    cap: ['33026'],
    popolazione: 2372,
  },
  {
    nome: 'Pamparato',
    codice: '004159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G302',
    cap: ['12087'],
    popolazione: 329,
  },
  {
    nome: 'Pancalieri',
    codice: '001178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G303',
    cap: ['10060'],
    popolazione: 1985,
  },
  {
    nome: 'Pancarana',
    codice: '018108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G304',
    cap: ['27050'],
    popolazione: 316,
  },
  {
    nome: 'Panchià',
    codice: '022134',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G305',
    cap: ['38030'],
    popolazione: 771,
  },
  {
    nome: 'Pandino',
    codice: '019067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G306',
    cap: ['26025'],
    popolazione: 8885,
  },
  {
    nome: 'Panettieri',
    codice: '078090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G307',
    cap: ['87050'],
    popolazione: 345,
  },
  {
    nome: 'Panicale',
    codice: '054037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G308',
    cap: ['06064'],
    popolazione: 5734,
  },
  {
    nome: 'Pannarano',
    codice: '062047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G311',
    cap: ['82017'],
    popolazione: 2082,
  },
  {
    nome: 'Panni',
    codice: '071037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'G312',
    cap: ['71020'],
    popolazione: 858,
  },
  {
    nome: 'Pantelleria',
    codice: '081014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'G315',
    cap: ['91017'],
    popolazione: 7493,
  },
  {
    nome: 'Pantigliate',
    codice: '015167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G316',
    cap: ['20090'],
    popolazione: 5841,
  },
  {
    nome: 'Paola',
    codice: '078091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G317',
    cap: ['87027'],
    popolazione: 16416,
  },
  {
    nome: 'Paolisi',
    codice: '062048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G318',
    cap: ['82011'],
    popolazione: 1983,
  },
  {
    nome: 'Papasidero',
    codice: '078092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G320',
    cap: ['87020'],
    popolazione: 808,
  },
  {
    nome: 'Papozze',
    codice: '029034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G323',
    cap: ['45010'],
    popolazione: 1645,
  },
  {
    nome: 'Parabiago',
    codice: '015168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G324',
    cap: ['20015'],
    popolazione: 26617,
  },
  {
    nome: 'Parabita',
    codice: '075059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'G325',
    cap: ['73052'],
    popolazione: 9323,
  },
  {
    nome: 'Paratico',
    codice: '017134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G327',
    cap: ['25030'],
    popolazione: 4464,
  },
  {
    nome: 'Parcines',
    codice: '021062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G328',
    cap: ['39020'],
    popolazione: 3536,
  },
  {
    nome: 'Parella',
    codice: '001179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G330',
    cap: ['10010'],
    popolazione: 468,
  },
  {
    nome: 'Parenti',
    codice: '078093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G331',
    cap: ['87040'],
    popolazione: 2249,
  },
  {
    nome: 'Parete',
    codice: '061054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G333',
    cap: ['81030'],
    popolazione: 11012,
  },
  {
    nome: 'Pareto',
    codice: '006125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G334',
    cap: ['15010'],
    popolazione: 602,
  },
  {
    nome: 'Parghelia',
    codice: '102026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'G335',
    cap: ['89861'],
    popolazione: 1303,
  },
  {
    nome: 'Parlasco',
    codice: '097064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G336',
    cap: ['23837'],
    popolazione: 141,
  },
  {
    nome: 'Parma',
    codice: '034027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'G337',
    cap: ['43121', '43122', '43123', '43124', '43125', '43126'],
    popolazione: 175895,
  },
  {
    nome: 'Parodi Ligure',
    codice: '006126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G338',
    cap: ['15060'],
    popolazione: 710,
  },
  {
    nome: 'Paroldo',
    codice: '004160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G339',
    cap: ['12070'],
    popolazione: 228,
  },
  {
    nome: 'Parolise',
    codice: '064069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G340',
    cap: ['83050'],
    popolazione: 686,
  },
  {
    nome: 'Parona',
    codice: '018109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G342',
    cap: ['27020'],
    popolazione: 1987,
  },
  {
    nome: 'Parrano',
    codice: '055025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'G344',
    cap: ['05010'],
    popolazione: 590,
  },
  {
    nome: 'Parre',
    codice: '016158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G346',
    cap: ['24020'],
    popolazione: 2807,
  },
  {
    nome: 'Partanna',
    codice: '081015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'G347',
    cap: ['91028'],
    popolazione: 10854,
  },
  {
    nome: 'Partinico',
    codice: '082054',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G348',
    cap: ['90047'],
    popolazione: 31401,
  },
  {
    nome: 'Paruzzaro',
    codice: '003114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G349',
    cap: ['28040'],
    popolazione: 2088,
  },
  {
    nome: 'Parzanica',
    codice: '016159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G350',
    cap: ['24060'],
    popolazione: 373,
  },
  {
    nome: 'Pasian di Prato',
    codice: '030072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G352',
    cap: ['33037'],
    popolazione: 9375,
  },
  {
    nome: 'Pasiano di Pordenone',
    codice: '093029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'G353',
    cap: ['33087'],
    popolazione: 7843,
  },
  {
    nome: 'Paspardo',
    codice: '017135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G354',
    cap: ['25050'],
    popolazione: 646,
  },
  {
    nome: 'Passerano Marmorito',
    codice: '005082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G358',
    cap: ['14020'],
    popolazione: 443,
  },
  {
    nome: 'Passignano sul Trasimeno',
    codice: '054038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G359',
    cap: ['06065'],
    popolazione: 5522,
  },
  {
    nome: 'Passirano',
    codice: '017136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G361',
    cap: ['25050'],
    popolazione: 7114,
  },
  {
    nome: 'Pastena',
    codice: '060047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G362',
    cap: ['03020'],
    popolazione: 1528,
  },
  {
    nome: 'Pastorano',
    codice: '061055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G364',
    cap: ['81050'],
    popolazione: 2920,
  },
  {
    nome: 'Pastrengo',
    codice: '023057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'G365',
    cap: ['37010'],
    popolazione: 2893,
  },
  {
    nome: 'Pasturana',
    codice: '006127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G367',
    cap: ['15060'],
    popolazione: 1256,
  },
  {
    nome: 'Pasturo',
    codice: '097065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G368',
    cap: ['23818'],
    popolazione: 1961,
  },
  {
    nome: 'Paterno',
    codice: '076100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'M269',
    cap: ['85050'],
    popolazione: 3423,
  },
  {
    nome: 'Paternò',
    codice: '087033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'G371',
    cap: ['95047'],
    popolazione: 47870,
  },
  {
    nome: 'Paterno Calabro',
    codice: '078094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G372',
    cap: ['87040'],
    popolazione: 1366,
  },
  {
    nome: 'Paternopoli',
    codice: '064070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G370',
    cap: ['83052'],
    popolazione: 2489,
  },
  {
    nome: 'Patrica',
    codice: '060048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G374',
    cap: ['03010'],
    popolazione: 3084,
  },
  {
    nome: 'Pattada',
    codice: '090055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G376',
    cap: ['07016'],
    popolazione: 3253,
  },
  {
    nome: 'Patti',
    codice: '083066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'G377',
    cap: ['98066'],
    popolazione: 13325,
  },
  {
    nome: 'Patù',
    codice: '075060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'G378',
    cap: ['73053'],
    popolazione: 1721,
  },
  {
    nome: 'Pau',
    codice: '095040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'G379',
    cap: ['09090'],
    popolazione: 300,
  },
  {
    nome: 'Paularo',
    codice: '030073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G381',
    cap: ['33027'],
    popolazione: 2737,
  },
  {
    nome: 'Pauli Arbarei',
    codice: '111053',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G382',
    cap: ['09020'],
    popolazione: 651,
  },
  {
    nome: 'Paulilatino',
    codice: '095041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'G384',
    cap: ['09070'],
    popolazione: 2347,
  },
  {
    nome: 'Paullo',
    codice: '015169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G385',
    cap: ['20067'],
    popolazione: 11014,
  },
  {
    nome: 'Paupisi',
    codice: '062049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G386',
    cap: ['82030'],
    popolazione: 1560,
  },
  {
    nome: 'Pavarolo',
    codice: '001180',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G387',
    cap: ['10020'],
    popolazione: 1119,
  },
  {
    nome: 'Pavia',
    codice: '018110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G388',
    cap: ['27100'],
    popolazione: 68280,
  },
  {
    nome: 'Pavia di Udine',
    codice: '030074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G389',
    cap: ['33050'],
    popolazione: 5698,
  },
  {
    nome: 'Pavone Canavese',
    codice: '001181',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G392',
    cap: ['10018'],
    popolazione: 3890,
  },
  {
    nome: 'Pavone del Mella',
    codice: '017137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G391',
    cap: ['25020'],
    popolazione: 2838,
  },
  {
    nome: 'Pavullo nel Frignano',
    codice: '036030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'G393',
    cap: ['41026'],
    popolazione: 17198,
  },
  {
    nome: 'Pazzano',
    codice: '080058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G394',
    cap: ['89040'],
    popolazione: 640,
  },
  {
    nome: 'Peccioli',
    codice: '050025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G395',
    cap: ['56037'],
    popolazione: 4939,
  },
  {
    nome: 'Pecetto di Valenza',
    codice: '006128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G397',
    cap: ['15040'],
    popolazione: 1233,
  },
  {
    nome: 'Pecetto Torinese',
    codice: '001183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G398',
    cap: ['10020'],
    popolazione: 3877,
  },
  {
    nome: 'Pedara',
    codice: '087034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'G402',
    cap: ['95030'],
    popolazione: 12896,
  },
  {
    nome: 'Pedaso',
    codice: '109030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'G403',
    cap: ['63827'],
    popolazione: 2771,
  },
  {
    nome: 'Pedavena',
    codice: '025036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'G404',
    cap: ['32034'],
    popolazione: 4429,
  },
  {
    nome: 'Pedemonte',
    codice: '024076',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G406',
    cap: ['36040'],
    popolazione: 768,
  },
  {
    nome: 'Pederobba',
    codice: '026056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G408',
    cap: ['31040'],
    popolazione: 7573,
  },
  {
    nome: 'Pedesina',
    codice: '014047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G410',
    cap: ['23010'],
    popolazione: 30,
  },
  {
    nome: 'Pedivigliano',
    codice: '078096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G411',
    cap: ['87050'],
    popolazione: 878,
  },
  {
    nome: 'Pedrengo',
    codice: '016160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G412',
    cap: ['24066'],
    popolazione: 5799,
  },
  {
    nome: 'Peglio',
    codice: '013178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G415',
    cap: ['22010'],
    popolazione: 185,
  },
  {
    nome: 'Peglio',
    codice: '041041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G416',
    cap: ['61049'],
    popolazione: 735,
  },
  {
    nome: 'Pegognaga',
    codice: '020039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G417',
    cap: ['46020'],
    popolazione: 7244,
  },
  {
    nome: 'Peia',
    codice: '016161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G418',
    cap: ['24020'],
    popolazione: 1864,
  },
  {
    nome: 'Peio',
    codice: '022136',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G419',
    cap: ['38024'],
    popolazione: 1891,
  },
  {
    nome: 'Pelago',
    codice: '048032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'G420',
    cap: ['50060'],
    popolazione: 7509,
  },
  {
    nome: 'Pella',
    codice: '003115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G421',
    cap: ['28010'],
    popolazione: 1038,
  },
  {
    nome: 'Pellegrino Parmense',
    codice: '034028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'G424',
    cap: ['43047'],
    popolazione: 1066,
  },
  {
    nome: 'Pellezzano',
    codice: '065090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G426',
    cap: ['84080'],
    popolazione: 10580,
  },
  {
    nome: 'Pellizzano',
    codice: '022137',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G428',
    cap: ['38020'],
    popolazione: 811,
  },
  {
    nome: 'Pelugo',
    codice: '022138',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G429',
    cap: ['38079'],
    popolazione: 379,
  },
  {
    nome: 'Penango',
    codice: '005083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G430',
    cap: ['14030'],
    popolazione: 516,
  },
  {
    nome: 'Penna in Teverina',
    codice: '055026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'G432',
    cap: ['05028'],
    popolazione: 1056,
  },
  {
    nome: 'Penna San Giovanni',
    codice: '043035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'G436',
    cap: ['62020'],
    popolazione: 1154,
  },
  {
    nome: "Penna Sant'Andrea",
    codice: '067033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'G437',
    cap: ['64039'],
    popolazione: 1728,
  },
  {
    nome: 'Pennabilli',
    codice: '099024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'G433',
    cap: ['47864'],
    popolazione: 3017,
  },
  {
    nome: 'Pennadomo',
    codice: '069063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G434',
    cap: ['66040'],
    popolazione: 311,
  },
  {
    nome: 'Pennapiedimonte',
    codice: '069064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G435',
    cap: ['66010'],
    popolazione: 515,
  },
  {
    nome: 'Penne',
    codice: '068027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G438',
    cap: ['65017'],
    popolazione: 12717,
  },
  {
    nome: 'Pentone',
    codice: '079092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'G439',
    cap: ['88050'],
    popolazione: 2215,
  },
  {
    nome: 'Perano',
    codice: '069065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G441',
    cap: ['66040'],
    popolazione: 1664,
  },
  {
    nome: 'Perarolo di Cadore',
    codice: '025037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'G442',
    cap: ['32010'],
    popolazione: 383,
  },
  {
    nome: 'Perca',
    codice: '021063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G443',
    cap: ['39030'],
    popolazione: 1460,
  },
  {
    nome: 'Percile',
    codice: '058076',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G444',
    cap: ['00020'],
    popolazione: 277,
  },
  {
    nome: 'Perdasdefogu',
    codice: '091072',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G445',
    cap: ['08046'],
    popolazione: 2042,
  },
  {
    nome: 'Perdaxius',
    codice: '111054',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G446',
    cap: ['09010'],
    popolazione: 1475,
  },
  {
    nome: 'Perdifumo',
    codice: '065091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G447',
    cap: ['84060'],
    popolazione: 1768,
  },
  {
    nome: 'Pereto',
    codice: '066067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G449',
    cap: ['67064'],
    popolazione: 739,
  },
  {
    nome: 'Perfugas',
    codice: '090056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G450',
    cap: ['07034'],
    popolazione: 2415,
  },
  {
    nome: 'Pergine Valsugana',
    codice: '022139',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G452',
    cap: ['38057'],
    popolazione: 20446,
  },
  {
    nome: 'Pergola',
    codice: '041043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G453',
    cap: ['61045'],
    popolazione: 6555,
  },
  {
    nome: 'Perinaldo',
    codice: '008040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G454',
    cap: ['18032'],
    popolazione: 912,
  },
  {
    nome: 'Perito',
    codice: '065092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G455',
    cap: ['84060'],
    popolazione: 1007,
  },
  {
    nome: 'Perledo',
    codice: '097067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G456',
    cap: ['23828'],
    popolazione: 1025,
  },
  {
    nome: 'Perletto',
    codice: '004161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G457',
    cap: ['12070'],
    popolazione: 305,
  },
  {
    nome: 'Perlo',
    codice: '004162',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G458',
    cap: ['12070'],
    popolazione: 126,
  },
  {
    nome: 'Perloz',
    codice: '007048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G459',
    cap: ['11020'],
    popolazione: 453,
  },
  {
    nome: 'Pernumia',
    codice: '028061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G461',
    cap: ['35020'],
    popolazione: 3915,
  },
  {
    nome: 'Pero',
    codice: '015170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'C013',
    cap: ['20016'],
    popolazione: 10291,
  },
  {
    nome: 'Perosa Argentina',
    codice: '001184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G463',
    cap: ['10063'],
    popolazione: 3405,
  },
  {
    nome: 'Perosa Canavese',
    codice: '001185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G462',
    cap: ['10010'],
    popolazione: 556,
  },
  {
    nome: 'Perrero',
    codice: '001186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G465',
    cap: ['10060'],
    popolazione: 723,
  },
  {
    nome: 'Persico Dosimo',
    codice: '019068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G469',
    cap: ['26043'],
    popolazione: 3372,
  },
  {
    nome: 'Pertengo',
    codice: '002091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G471',
    cap: ['13030'],
    popolazione: 321,
  },
  {
    nome: 'Pertica Alta',
    codice: '017139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G474',
    cap: ['25070'],
    popolazione: 599,
  },
  {
    nome: 'Pertica Bassa',
    codice: '017140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G475',
    cap: ['25078'],
    popolazione: 686,
  },
  {
    nome: 'Pertosa',
    codice: '065093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G476',
    cap: ['84030'],
    popolazione: 705,
  },
  {
    nome: 'Pertusio',
    codice: '001187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G477',
    cap: ['10080'],
    popolazione: 773,
  },
  {
    nome: 'Perugia',
    codice: '054039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G478',
    cap: [
      '06121',
      '06122',
      '06123',
      '06124',
      '06125',
      '06126',
      '06127',
      '06128',
      '06129',
      '06130',
      '06131',
      '06132',
      '06133',
      '06134',
      '06135',
    ],
    popolazione: 162449,
  },
  {
    nome: 'Pesaro',
    codice: '041044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G479',
    cap: ['61121', '61122'],
    popolazione: 94237,
  },
  {
    nome: 'Pescaglia',
    codice: '046022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'G480',
    cap: ['55064'],
    popolazione: 3645,
  },
  {
    nome: 'Pescantina',
    codice: '023058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'G481',
    cap: ['37026'],
    popolazione: 16326,
  },
  {
    nome: 'Pescara',
    codice: '068028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G482',
    cap: ['65121', '65122', '65123', '65124', '65125', '65126', '65127', '65128', '65129'],
    popolazione: 117166,
  },
  {
    nome: 'Pescarolo ed Uniti',
    codice: '019069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G483',
    cap: ['26033'],
    popolazione: 1608,
  },
  {
    nome: 'Pescasseroli',
    codice: '066068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G484',
    cap: ['67032'],
    popolazione: 2227,
  },
  {
    nome: 'Pescate',
    codice: '097068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G485',
    cap: ['23855'],
    popolazione: 2188,
  },
  {
    nome: 'Pesche',
    codice: '094031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G486',
    cap: ['86090'],
    popolazione: 1577,
  },
  {
    nome: 'Peschici',
    codice: '071038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'G487',
    cap: ['71010'],
    popolazione: 4197,
  },
  {
    nome: 'Peschiera Borromeo',
    codice: '015171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G488',
    cap: ['20068'],
    popolazione: 22254,
  },
  {
    nome: 'Peschiera del Garda',
    codice: '023059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'G489',
    cap: ['37019'],
    popolazione: 9598,
  },
  {
    nome: 'Pescia',
    codice: '047012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'G491',
    cap: ['51017'],
    popolazione: 19435,
  },
  {
    nome: 'Pescina',
    codice: '066069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G492',
    cap: ['67057'],
    popolazione: 4264,
  },
  {
    nome: 'Pesco Sannita',
    codice: '062050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G494',
    cap: ['82020'],
    popolazione: 2081,
  },
  {
    nome: 'Pescocostanzo',
    codice: '066070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G493',
    cap: ['67033'],
    popolazione: 1161,
  },
  {
    nome: 'Pescolanciano',
    codice: '094032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G495',
    cap: ['86097'],
    popolazione: 878,
  },
  {
    nome: 'Pescopagano',
    codice: '076058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G496',
    cap: ['85020'],
    popolazione: 2022,
  },
  {
    nome: 'Pescopennataro',
    codice: '094033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G497',
    cap: ['86080'],
    popolazione: 300,
  },
  {
    nome: 'Pescorocchiano',
    codice: '057049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G498',
    cap: ['02024'],
    popolazione: 2211,
  },
  {
    nome: 'Pescosansonesco',
    codice: '068029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G499',
    cap: ['65020'],
    popolazione: 517,
  },
  {
    nome: 'Pescosolido',
    codice: '060049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G500',
    cap: ['03030'],
    popolazione: 1552,
  },
  {
    nome: 'Pessano con Bornago',
    codice: '015172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G502',
    cap: ['20060'],
    popolazione: 9064,
  },
  {
    nome: 'Pessina Cremonese',
    codice: '019070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G504',
    cap: ['26030'],
    popolazione: 673,
  },
  {
    nome: 'Pessinetto',
    codice: '001188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G505',
    cap: ['10070'],
    popolazione: 607,
  },
  {
    nome: 'Petacciato',
    codice: '070051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G506',
    cap: ['86038'],
    popolazione: 3638,
  },
  {
    nome: 'Petilia Policastro',
    codice: '101017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'G508',
    cap: ['88837'],
    popolazione: 9267,
  },
  {
    nome: 'Petina',
    codice: '065094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G509',
    cap: ['84020'],
    popolazione: 1214,
  },
  {
    nome: 'Petralia Soprana',
    codice: '082055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G510',
    cap: ['90026'],
    popolazione: 3443,
  },
  {
    nome: 'Petralia Sottana',
    codice: '082056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G511',
    cap: ['90027'],
    popolazione: 2975,
  },
  {
    nome: 'Petrella Salto',
    codice: '057050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G513',
    cap: ['02025'],
    popolazione: 1212,
  },
  {
    nome: 'Petrella Tifernina',
    codice: '070052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G512',
    cap: ['86024'],
    popolazione: 1206,
  },
  {
    nome: 'Petriano',
    codice: '041045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G514',
    cap: ['61020'],
    popolazione: 2814,
  },
  {
    nome: 'Petriolo',
    codice: '043036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'G515',
    cap: ['62014'],
    popolazione: 1977,
  },
  {
    nome: 'Petritoli',
    codice: '109031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'G516',
    cap: ['63848'],
    popolazione: 2440,
  },
  {
    nome: 'Petrizzi',
    codice: '079094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'G517',
    cap: ['88060'],
    popolazione: 1167,
  },
  {
    nome: 'Petronà',
    codice: '079095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'G518',
    cap: ['88050'],
    popolazione: 2685,
  },
  {
    nome: 'Petrosino',
    codice: '081024',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'M281',
    cap: ['91020'],
    popolazione: 7760,
  },
  {
    nome: 'Petruro Irpino',
    codice: '064071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G519',
    cap: ['83010'],
    popolazione: 341,
  },
  {
    nome: 'Pettenasco',
    codice: '003116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G520',
    cap: ['28028'],
    popolazione: 1368,
  },
  {
    nome: 'Pettinengo',
    codice: '096042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G521',
    cap: ['13843'],
    popolazione: 1624,
  },
  {
    nome: 'Pettineo',
    codice: '083067',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'G522',
    cap: ['98070'],
    popolazione: 1433,
  },
  {
    nome: 'Pettoranello del Molise',
    codice: '094034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G523',
    cap: ['86090'],
    popolazione: 459,
  },
  {
    nome: 'Pettorano sul Gizio',
    codice: '066071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G524',
    cap: ['67034'],
    popolazione: 1363,
  },
  {
    nome: 'Pettorazza Grimani',
    codice: '029035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G525',
    cap: ['45010'],
    popolazione: 1669,
  },
  {
    nome: 'Peveragno',
    codice: '004163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G526',
    cap: ['12016'],
    popolazione: 5481,
  },
  {
    nome: 'Pezzana',
    codice: '002093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G528',
    cap: ['13010'],
    popolazione: 1346,
  },
  {
    nome: 'Pezzaze',
    codice: '017141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G529',
    cap: ['25060'],
    popolazione: 1586,
  },
  {
    nome: 'Pezzolo Valle Uzzone',
    codice: '004164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G532',
    cap: ['12070'],
    popolazione: 354,
  },
  {
    nome: 'Piacenza',
    codice: '033032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G535',
    cap: ['29121', '29122'],
    popolazione: 100311,
  },
  {
    nome: "Piacenza d'Adige",
    codice: '028062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G534',
    cap: ['35040'],
    popolazione: 1379,
  },
  {
    nome: 'Piadena Drizzona',
    codice: '019116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'M418',
    cap: ['26034'],
    popolazione: 4132,
  },
  {
    nome: 'Piaggine',
    codice: '065095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G538',
    cap: ['84065'],
    popolazione: 1447,
  },
  {
    nome: 'Pian Camuno',
    codice: '017142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G546',
    cap: ['25050'],
    popolazione: 4400,
  },
  {
    nome: 'Piana Crixia',
    codice: '009048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G542',
    cap: ['17058'],
    popolazione: 857,
  },
  {
    nome: 'Piana degli Albanesi',
    codice: '082057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G543',
    cap: ['90037'],
    popolazione: 6010,
  },
  {
    nome: 'Piana di Monte Verna',
    codice: '061056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G541',
    cap: ['81013'],
    popolazione: 2382,
  },
  {
    nome: 'Piancastagnaio',
    codice: '052020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'G547',
    cap: ['53025'],
    popolazione: 4176,
  },
  {
    nome: 'Piancogno',
    codice: '017206',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G549',
    cap: ['25052'],
    popolazione: 4679,
  },
  {
    nome: 'Piandimeleto',
    codice: '041047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G551',
    cap: ['61026'],
    popolazione: 2146,
  },
  {
    nome: 'Piane Crati',
    codice: '078097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G553',
    cap: ['87050'],
    popolazione: 1414,
  },
  {
    nome: 'Pianella',
    codice: '068030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G555',
    cap: ['65019'],
    popolazione: 8437,
  },
  {
    nome: 'Pianello del Lario',
    codice: '013183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G556',
    cap: ['22010'],
    popolazione: 1041,
  },
  {
    nome: 'Pianello Val Tidone',
    codice: '033033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G557',
    cap: ['29010'],
    popolazione: 2290,
  },
  {
    nome: 'Pianengo',
    codice: '019072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G558',
    cap: ['26010'],
    popolazione: 2561,
  },
  {
    nome: 'Pianezza',
    codice: '001189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G559',
    cap: ['10044'],
    popolazione: 14169,
  },
  {
    nome: 'Pianezze',
    codice: '024077',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G560',
    cap: ['36060'],
    popolazione: 2060,
  },
  {
    nome: 'Pianfei',
    codice: '004165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G561',
    cap: ['12080'],
    popolazione: 2222,
  },
  {
    nome: 'Pianico',
    codice: '016162',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G564',
    cap: ['24060'],
    popolazione: 1512,
  },
  {
    nome: 'Pianiga',
    codice: '027028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'G565',
    cap: ['30030'],
    popolazione: 11968,
  },
  {
    nome: 'Piano di Sorrento',
    codice: '063053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G568',
    cap: ['80063'],
    popolazione: 12991,
  },
  {
    nome: 'Pianopoli',
    codice: '079096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'D546',
    cap: ['88040'],
    popolazione: 2559,
  },
  {
    nome: 'Pianoro',
    codice: '037047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'G570',
    cap: ['40065'],
    popolazione: 16890,
  },
  {
    nome: 'Piansano',
    codice: '056043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'G571',
    cap: ['01010'],
    popolazione: 2147,
  },
  {
    nome: 'Piantedo',
    codice: '014048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G572',
    cap: ['23010'],
    popolazione: 1321,
  },
  {
    nome: 'Piario',
    codice: '016163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G574',
    cap: ['24020'],
    popolazione: 1109,
  },
  {
    nome: 'Piasco',
    codice: '004166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G575',
    cap: ['12026'],
    popolazione: 2821,
  },
  {
    nome: 'Piateda',
    codice: '014049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G576',
    cap: ['23020'],
    popolazione: 2309,
  },
  {
    nome: 'Piatto',
    codice: '096043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G577',
    cap: ['13844'],
    popolazione: 547,
  },
  {
    nome: 'Piazza al Serchio',
    codice: '046023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'G582',
    cap: ['55035'],
    popolazione: 2458,
  },
  {
    nome: 'Piazza Armerina',
    codice: '086014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'G580',
    cap: ['94015'],
    popolazione: 22196,
  },
  {
    nome: 'Piazza Brembana',
    codice: '016164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G579',
    cap: ['24014'],
    popolazione: 1235,
  },
  {
    nome: 'Piazzatorre',
    codice: '016165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G583',
    cap: ['24010'],
    popolazione: 437,
  },
  {
    nome: 'Piazzola sul Brenta',
    codice: '028063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G587',
    cap: ['35016'],
    popolazione: 11130,
  },
  {
    nome: 'Piazzolo',
    codice: '016166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G588',
    cap: ['24010'],
    popolazione: 84,
  },
  {
    nome: 'Picciano',
    codice: '068031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G589',
    cap: ['65010'],
    popolazione: 1338,
  },
  {
    nome: 'Picerno',
    codice: '076059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G590',
    cap: ['85055'],
    popolazione: 6080,
  },
  {
    nome: 'Picinisco',
    codice: '060050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G591',
    cap: ['03040'],
    popolazione: 1255,
  },
  {
    nome: 'Pico',
    codice: '060051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G592',
    cap: ['03020'],
    popolazione: 3004,
  },
  {
    nome: 'Piea',
    codice: '005084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G593',
    cap: ['14020'],
    popolazione: 612,
  },
  {
    nome: 'Piedicavallo',
    codice: '096044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G594',
    cap: ['13812'],
    popolazione: 203,
  },
  {
    nome: 'Piedimonte Etneo',
    codice: '087035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'G597',
    cap: ['95017'],
    popolazione: 4079,
  },
  {
    nome: 'Piedimonte Matese',
    codice: '061057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G596',
    cap: ['81016'],
    popolazione: 11504,
  },
  {
    nome: 'Piedimonte San Germano',
    codice: '060052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G598',
    cap: ['03030'],
    popolazione: 6036,
  },
  {
    nome: 'Piedimulera',
    codice: '103053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'G600',
    cap: ['28885'],
    popolazione: 1559,
  },
  {
    nome: 'Piegaro',
    codice: '054040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G601',
    cap: ['06066'],
    popolazione: 3799,
  },
  {
    nome: 'Pienza',
    codice: '052021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'G602',
    cap: ['53026'],
    popolazione: 2141,
  },
  {
    nome: 'Pieranica',
    codice: '019073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G603',
    cap: ['26017'],
    popolazione: 1152,
  },
  {
    nome: "Pietra de' Giorgi",
    codice: '018111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G612',
    cap: ['27040'],
    popolazione: 915,
  },
  {
    nome: 'Pietra Ligure',
    codice: '009049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G605',
    cap: ['17027'],
    popolazione: 8880,
  },
  {
    nome: 'Pietra Marazzi',
    codice: '006129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G619',
    cap: ['15040'],
    popolazione: 900,
  },
  {
    nome: 'Pietrabbondante',
    codice: '094035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G606',
    cap: ['86085'],
    popolazione: 826,
  },
  {
    nome: 'Pietrabruna',
    codice: '008041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G607',
    cap: ['18010'],
    popolazione: 541,
  },
  {
    nome: 'Pietracamela',
    codice: '067034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'G608',
    cap: ['64047'],
    popolazione: 304,
  },
  {
    nome: 'Pietracatella',
    codice: '070053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G609',
    cap: ['86040'],
    popolazione: 1433,
  },
  {
    nome: 'Pietracupa',
    codice: '070054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G610',
    cap: ['86020'],
    popolazione: 228,
  },
  {
    nome: 'Pietradefusi',
    codice: '064072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G611',
    cap: ['83030'],
    popolazione: 2375,
  },
  {
    nome: 'Pietraferrazzana',
    codice: '069103',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G613',
    cap: ['66040'],
    popolazione: 128,
  },
  {
    nome: 'Pietrafitta',
    codice: '078098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G615',
    cap: ['87050'],
    popolazione: 1377,
  },
  {
    nome: 'Pietragalla',
    codice: '076060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G616',
    cap: ['85016'],
    popolazione: 4267,
  },
  {
    nome: 'Pietralunga',
    codice: '054041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G618',
    cap: ['06026'],
    popolazione: 2182,
  },
  {
    nome: 'Pietramelara',
    codice: '061058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G620',
    cap: ['81051'],
    popolazione: 4657,
  },
  {
    nome: 'Pietramontecorvino',
    codice: '071039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'G604',
    cap: ['71038'],
    popolazione: 2745,
  },
  {
    nome: 'Pietranico',
    codice: '068032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G621',
    cap: ['65020'],
    popolazione: 509,
  },
  {
    nome: 'Pietrapaola',
    codice: '078099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G622',
    cap: ['87060'],
    popolazione: 1173,
  },
  {
    nome: 'Pietrapertosa',
    codice: '076061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G623',
    cap: ['85010'],
    popolazione: 1108,
  },
  {
    nome: 'Pietraperzia',
    codice: '086015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'G624',
    cap: ['94016'],
    popolazione: 7227,
  },
  {
    nome: 'Pietraporzio',
    codice: '004167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G625',
    cap: ['12010'],
    popolazione: 91,
  },
  {
    nome: 'Pietraroja',
    codice: '062051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G626',
    cap: ['82030'],
    popolazione: 587,
  },
  {
    nome: 'Pietrarubbia',
    codice: '041048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G627',
    cap: ['61023'],
    popolazione: 689,
  },
  {
    nome: 'Pietrasanta',
    codice: '046024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'G628',
    cap: ['55045'],
    popolazione: 24179,
  },
  {
    nome: 'Pietrastornina',
    codice: '064073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G629',
    cap: ['83015'],
    popolazione: 1568,
  },
  {
    nome: 'Pietravairano',
    codice: '061059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G630',
    cap: ['81040'],
    popolazione: 3018,
  },
  {
    nome: 'Pietrelcina',
    codice: '062052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G631',
    cap: ['82020'],
    popolazione: 3081,
  },
  {
    nome: 'Pieve a Nievole',
    codice: '047013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'G636',
    cap: ['51018'],
    popolazione: 9460,
  },
  {
    nome: 'Pieve Albignola',
    codice: '018112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G635',
    cap: ['27030'],
    popolazione: 916,
  },
  {
    nome: "Pieve d'Olmi",
    codice: '019074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G647',
    cap: ['26040'],
    popolazione: 1295,
  },
  {
    nome: 'Pieve del Cairo',
    codice: '018113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G639',
    cap: ['27037'],
    popolazione: 2108,
  },
  {
    nome: 'Pieve del Grappa',
    codice: '026096',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'M422',
    cap: ['31017'],
    popolazione: 6882,
  },
  {
    nome: 'Pieve di Bono-Prezzo',
    codice: '022234',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M365',
    cap: ['38085'],
    popolazione: 1541,
  },
  {
    nome: 'Pieve di Cadore',
    codice: '025039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'G642',
    cap: ['32044'],
    popolazione: 3956,
  },
  {
    nome: 'Pieve di Cento',
    codice: '037048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'G643',
    cap: ['40066'],
    popolazione: 6895,
  },
  {
    nome: 'Pieve di Soligo',
    codice: '026057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G645',
    cap: ['31053'],
    popolazione: 12057,
  },
  {
    nome: 'Pieve di Teco',
    codice: '008042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G632',
    cap: ['18026'],
    popolazione: 1400,
  },
  {
    nome: 'Pieve Emanuele',
    codice: '015173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G634',
    cap: ['20090'],
    popolazione: 14868,
  },
  {
    nome: 'Pieve Fissiraga',
    codice: '098045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'G096',
    cap: ['26854'],
    popolazione: 1604,
  },
  {
    nome: 'Pieve Fosciana',
    codice: '046025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'G648',
    cap: ['55036'],
    popolazione: 2418,
  },
  {
    nome: 'Pieve Ligure',
    codice: '010043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'G646',
    cap: ['16031'],
    popolazione: 2582,
  },
  {
    nome: 'Pieve Porto Morone',
    codice: '018114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G650',
    cap: ['27017'],
    popolazione: 2788,
  },
  {
    nome: 'Pieve San Giacomo',
    codice: '019075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G651',
    cap: ['26035'],
    popolazione: 1624,
  },
  {
    nome: 'Pieve Santo Stefano',
    codice: '051030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'G653',
    cap: ['52036'],
    popolazione: 3190,
  },
  {
    nome: 'Pieve Tesino',
    codice: '022142',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G656',
    cap: ['38050'],
    popolazione: 681,
  },
  {
    nome: 'Pieve Torina',
    codice: '043038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'G657',
    cap: ['62036'],
    popolazione: 1483,
  },
  {
    nome: 'Pieve Vergonte',
    codice: '103054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'G658',
    cap: ['28886'],
    popolazione: 2644,
  },
  {
    nome: 'Pievepelago',
    codice: '036031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'G649',
    cap: ['41027'],
    popolazione: 2241,
  },
  {
    nome: 'Piglio',
    codice: '060053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G659',
    cap: ['03010'],
    popolazione: 4657,
  },
  {
    nome: 'Pigna',
    codice: '008043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G660',
    cap: ['18037'],
    popolazione: 894,
  },
  {
    nome: 'Pignataro Interamna',
    codice: '060054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G662',
    cap: ['03040'],
    popolazione: 2558,
  },
  {
    nome: 'Pignataro Maggiore',
    codice: '061060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G661',
    cap: ['81052'],
    popolazione: 6230,
  },
  {
    nome: 'Pignola',
    codice: '076062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G663',
    cap: ['85010'],
    popolazione: 6699,
  },
  {
    nome: 'Pignone',
    codice: '011021',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'G664',
    cap: ['19020'],
    popolazione: 599,
  },
  {
    nome: 'Pigra',
    codice: '013184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G665',
    cap: ['22020'],
    popolazione: 266,
  },
  {
    nome: 'Pila',
    codice: '002096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G666',
    cap: ['13020'],
    popolazione: 137,
  },
  {
    nome: 'Pimentel',
    codice: '111055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G669',
    cap: ['09020'],
    popolazione: 1193,
  },
  {
    nome: 'Pimonte',
    codice: '063054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G670',
    cap: ['80050'],
    popolazione: 6000,
  },
  {
    nome: 'Pinarolo Po',
    codice: '018115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G671',
    cap: ['27040'],
    popolazione: 1702,
  },
  {
    nome: 'Pinasca',
    codice: '001190',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G672',
    cap: ['10060'],
    popolazione: 3049,
  },
  {
    nome: 'Pincara',
    codice: '029036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G673',
    cap: ['45020'],
    popolazione: 1262,
  },
  {
    nome: 'Pinerolo',
    codice: '001191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G674',
    cap: ['10064'],
    popolazione: 34854,
  },
  {
    nome: 'Pineto',
    codice: '067035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F831',
    cap: ['64025'],
    popolazione: 14631,
  },
  {
    nome: "Pino d'Asti",
    codice: '005085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G676',
    cap: ['14020'],
    popolazione: 221,
  },
  {
    nome: 'Pino Torinese',
    codice: '001192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G678',
    cap: ['10025'],
    popolazione: 8481,
  },
  {
    nome: 'Pinzano al Tagliamento',
    codice: '093030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'G680',
    cap: ['33094'],
    popolazione: 1567,
  },
  {
    nome: 'Pinzolo',
    codice: '022143',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G681',
    cap: ['38086'],
    popolazione: 3117,
  },
  {
    nome: 'Piobbico',
    codice: '041049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'G682',
    cap: ['61046'],
    popolazione: 2109,
  },
  {
    nome: "Piobesi d'Alba",
    codice: '004168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G683',
    cap: ['12040'],
    popolazione: 1248,
  },
  {
    nome: 'Piobesi Torinese',
    codice: '001193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G684',
    cap: ['10040'],
    popolazione: 3713,
  },
  {
    nome: 'Piode',
    codice: '002097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G685',
    cap: ['13020'],
    popolazione: 193,
  },
  {
    nome: 'Pioltello',
    codice: '015175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G686',
    cap: ['20096'],
    popolazione: 35066,
  },
  {
    nome: 'Piombino',
    codice: '049012',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'G687',
    cap: ['57025'],
    popolazione: 34419,
  },
  {
    nome: 'Piombino Dese',
    codice: '028064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G688',
    cap: ['35017'],
    popolazione: 9276,
  },
  {
    nome: 'Pioraco',
    codice: '043039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'G690',
    cap: ['62025'],
    popolazione: 1250,
  },
  {
    nome: 'Piossasco',
    codice: '001194',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G691',
    cap: ['10045'],
    popolazione: 18186,
  },
  {
    nome: 'Piovà Massaia',
    codice: '005086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G692',
    cap: ['14026'],
    popolazione: 680,
  },
  {
    nome: 'Piove di Sacco',
    codice: '028065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G693',
    cap: ['35028'],
    popolazione: 19067,
  },
  {
    nome: 'Piovene Rocchette',
    codice: '024078',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G694',
    cap: ['36013'],
    popolazione: 8295,
  },
  {
    nome: 'Piozzano',
    codice: '033034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G696',
    cap: ['29010'],
    popolazione: 642,
  },
  {
    nome: 'Piozzo',
    codice: '004169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G697',
    cap: ['12060'],
    popolazione: 1007,
  },
  {
    nome: 'Piraino',
    codice: '083068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'G699',
    cap: ['98060'],
    popolazione: 3964,
  },
  {
    nome: 'Pisa',
    codice: '050026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G702',
    cap: ['56121', '56122', '56123', '56124', '56125', '56126', '56127', '56128'],
    popolazione: 85858,
  },
  {
    nome: 'Pisano',
    codice: '003119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G703',
    cap: ['28010'],
    popolazione: 770,
  },
  {
    nome: 'Piscina',
    codice: '001195',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G705',
    cap: ['10060'],
    popolazione: 3450,
  },
  {
    nome: 'Piscinas',
    codice: '111056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M291',
    cap: ['09010'],
    popolazione: 872,
  },
  {
    nome: 'Pisciotta',
    codice: '065096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G707',
    cap: ['84066'],
    popolazione: 2748,
  },
  {
    nome: 'Pisogne',
    codice: '017143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G710',
    cap: ['25055'],
    popolazione: 8112,
  },
  {
    nome: 'Pisoniano',
    codice: '058077',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G704',
    cap: ['00020'],
    popolazione: 803,
  },
  {
    nome: 'Pisticci',
    codice: '077020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'G712',
    cap: ['75015'],
    popolazione: 17361,
  },
  {
    nome: 'Pistoia',
    codice: '047014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'G713',
    cap: ['51100'],
    popolazione: 89101,
  },
  {
    nome: 'Pitigliano',
    codice: '053019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'G716',
    cap: ['58017'],
    popolazione: 3870,
  },
  {
    nome: 'Piubega',
    codice: '020041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G717',
    cap: ['46040'],
    popolazione: 1768,
  },
  {
    nome: 'Piuro',
    codice: '014050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G718',
    cap: ['23020'],
    popolazione: 1950,
  },
  {
    nome: 'Piverone',
    codice: '001196',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G719',
    cap: ['10010'],
    popolazione: 1378,
  },
  {
    nome: 'Pizzale',
    codice: '018116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G720',
    cap: ['27050'],
    popolazione: 722,
  },
  {
    nome: 'Pizzighettone',
    codice: '019076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'G721',
    cap: ['26026'],
    popolazione: 6703,
  },
  {
    nome: 'Pizzo',
    codice: '102027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'G722',
    cap: ['89812'],
    popolazione: 8885,
  },
  {
    nome: 'Pizzoferrato',
    codice: '069066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G724',
    cap: ['66040'],
    popolazione: 1127,
  },
  {
    nome: 'Pizzoli',
    codice: '066072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G726',
    cap: ['67017'],
    popolazione: 3773,
  },
  {
    nome: 'Pizzone',
    codice: '094036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G727',
    cap: ['86071'],
    popolazione: 335,
  },
  {
    nome: 'Pizzoni',
    codice: '102028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'G728',
    cap: ['89834'],
    popolazione: 1233,
  },
  {
    nome: 'Placanica',
    codice: '080059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G729',
    cap: ['89040'],
    popolazione: 1250,
  },
  {
    nome: 'Plataci',
    codice: '078100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G733',
    cap: ['87070'],
    popolazione: 830,
  },
  {
    nome: 'Platania',
    codice: '079099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'G734',
    cap: ['88040'],
    popolazione: 2232,
  },
  {
    nome: 'Platì',
    codice: '080060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G735',
    cap: ['89039'],
    popolazione: 3711,
  },
  {
    nome: 'Plaus',
    codice: '021064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G299',
    cap: ['39025'],
    popolazione: 692,
  },
  {
    nome: 'Plesio',
    codice: '013185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G737',
    cap: ['22010'],
    popolazione: 842,
  },
  {
    nome: 'Ploaghe',
    codice: '090057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G740',
    cap: ['07017'],
    popolazione: 4653,
  },
  {
    nome: 'Plodio',
    codice: '009050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G741',
    cap: ['17043'],
    popolazione: 648,
  },
  {
    nome: 'Pocapaglia',
    codice: '004170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G742',
    cap: ['12060'],
    popolazione: 3330,
  },
  {
    nome: 'Pocenia',
    codice: '030075',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G743',
    cap: ['33050'],
    popolazione: 2595,
  },
  {
    nome: 'Podenzana',
    codice: '045013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'G746',
    cap: ['54010'],
    popolazione: 2142,
  },
  {
    nome: 'Podenzano',
    codice: '033035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G747',
    cap: ['29027'],
    popolazione: 8990,
  },
  {
    nome: 'Pofi',
    codice: '060055',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G749',
    cap: ['03026'],
    popolazione: 4303,
  },
  {
    nome: 'Poggiardo',
    codice: '075061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'G751',
    cap: ['73037'],
    popolazione: 6119,
  },
  {
    nome: 'Poggibonsi',
    codice: '052022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'G752',
    cap: ['53036'],
    popolazione: 28952,
  },
  {
    nome: 'Poggio a Caiano',
    codice: '100004',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'G754',
    cap: ['59016'],
    popolazione: 9626,
  },
  {
    nome: 'Poggio Bustone',
    codice: '057051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G756',
    cap: ['02018'],
    popolazione: 2130,
  },
  {
    nome: 'Poggio Catino',
    codice: '057052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G757',
    cap: ['02040'],
    popolazione: 1335,
  },
  {
    nome: 'Poggio Imperiale',
    codice: '071040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'G761',
    cap: ['71010'],
    popolazione: 2819,
  },
  {
    nome: 'Poggio Mirteto',
    codice: '057053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G763',
    cap: ['02047'],
    popolazione: 5995,
  },
  {
    nome: 'Poggio Moiano',
    codice: '057054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G764',
    cap: ['02037'],
    popolazione: 2798,
  },
  {
    nome: 'Poggio Nativo',
    codice: '057055',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G765',
    cap: ['02030'],
    popolazione: 2456,
  },
  {
    nome: 'Poggio Picenze',
    codice: '066073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G766',
    cap: ['67026'],
    popolazione: 1068,
  },
  {
    nome: 'Poggio Renatico',
    codice: '038018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'G768',
    cap: ['44028'],
    popolazione: 9674,
  },
  {
    nome: 'Poggio Rusco',
    codice: '020042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G753',
    cap: ['46025'],
    popolazione: 6521,
  },
  {
    nome: 'Poggio San Lorenzo',
    codice: '057056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G770',
    cap: ['02030'],
    popolazione: 580,
  },
  {
    nome: 'Poggio San Marcello',
    codice: '042037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'G771',
    cap: ['60030'],
    popolazione: 731,
  },
  {
    nome: 'Poggio San Vicino',
    codice: '043040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'D566',
    cap: ['62021'],
    popolazione: 297,
  },
  {
    nome: 'Poggio Sannita',
    codice: '094037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'B317',
    cap: ['86086'],
    popolazione: 764,
  },
  {
    nome: 'Poggio Torriana',
    codice: '099028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'M324',
    cap: ['47824'],
    popolazione: 4960,
  },
  {
    nome: 'Poggiodomo',
    codice: '054042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'G758',
    cap: ['06040'],
    popolazione: 135,
  },
  {
    nome: 'Poggiofiorito',
    codice: '069067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G760',
    cap: ['66030'],
    popolazione: 943,
  },
  {
    nome: 'Poggiomarino',
    codice: '063055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G762',
    cap: ['80040'],
    popolazione: 21206,
  },
  {
    nome: 'Poggioreale',
    codice: '081016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'G767',
    cap: ['91020'],
    popolazione: 1534,
  },
  {
    nome: 'Poggiorsini',
    codice: '072034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'G769',
    cap: ['70020'],
    popolazione: 1418,
  },
  {
    nome: 'Poggiridenti',
    codice: '014051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G431',
    cap: ['23020'],
    popolazione: 1865,
  },
  {
    nome: 'Pogliano Milanese',
    codice: '015176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G772',
    cap: ['20010'],
    popolazione: 8141,
  },
  {
    nome: 'Pognana Lario',
    codice: '013186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G773',
    cap: ['22020'],
    popolazione: 764,
  },
  {
    nome: 'Pognano',
    codice: '016167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G774',
    cap: ['24040'],
    popolazione: 1581,
  },
  {
    nome: 'Pogno',
    codice: '003120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G775',
    cap: ['28076'],
    popolazione: 1538,
  },
  {
    nome: 'Poirino',
    codice: '001197',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G777',
    cap: ['10046'],
    popolazione: 10220,
  },
  {
    nome: 'Pojana Maggiore',
    codice: '024079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G776',
    cap: ['36026'],
    popolazione: 4459,
  },
  {
    nome: 'Polaveno',
    codice: '017144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G779',
    cap: ['25060'],
    popolazione: 2661,
  },
  {
    nome: 'Polcenigo',
    codice: '093031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'G780',
    cap: ['33070'],
    popolazione: 3176,
  },
  {
    nome: 'Polesella',
    codice: '029037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G782',
    cap: ['45038'],
    popolazione: 4079,
  },
  {
    nome: 'Polesine Zibello',
    codice: '034050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'M367',
    cap: ['43016'],
    popolazione: 3348,
  },
  {
    nome: 'Poli',
    codice: '058078',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G784',
    cap: ['00010'],
    popolazione: 2433,
  },
  {
    nome: 'Polia',
    codice: '102029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'G785',
    cap: ['89813'],
    popolazione: 1048,
  },
  {
    nome: 'Policoro',
    codice: '077021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'G786',
    cap: ['75025'],
    popolazione: 15976,
  },
  {
    nome: 'Polignano a Mare',
    codice: '072035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'G787',
    cap: ['70044'],
    popolazione: 17567,
  },
  {
    nome: 'Polinago',
    codice: '036032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'G789',
    cap: ['41040'],
    popolazione: 1742,
  },
  {
    nome: 'Polino',
    codice: '055027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'G790',
    cap: ['05030'],
    popolazione: 246,
  },
  {
    nome: 'Polistena',
    codice: '080061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G791',
    cap: ['89024'],
    popolazione: 10742,
  },
  {
    nome: 'Polizzi Generosa',
    codice: '082058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G792',
    cap: ['90028'],
    popolazione: 3607,
  },
  {
    nome: 'Polla',
    codice: '065097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G793',
    cap: ['84035'],
    popolazione: 5327,
  },
  {
    nome: 'Pollein',
    codice: '007049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G794',
    cap: ['11020'],
    popolazione: 1531,
  },
  {
    nome: 'Pollena Trocchia',
    codice: '063056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G795',
    cap: ['80040'],
    popolazione: 13514,
  },
  {
    nome: 'Pollenza',
    codice: '043041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F567',
    cap: ['62010'],
    popolazione: 6583,
  },
  {
    nome: 'Pollica',
    codice: '065098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G796',
    cap: ['84068'],
    popolazione: 2393,
  },
  {
    nome: 'Pollina',
    codice: '082059',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'G797',
    cap: ['90010'],
    popolazione: 2998,
  },
  {
    nome: 'Pollone',
    codice: '096046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G798',
    cap: ['13814'],
    popolazione: 2153,
  },
  {
    nome: 'Pollutri',
    codice: '069068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'G799',
    cap: ['66020'],
    popolazione: 2306,
  },
  {
    nome: 'Polonghera',
    codice: '004171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G800',
    cap: ['12030'],
    popolazione: 1193,
  },
  {
    nome: 'Polpenazze del Garda',
    codice: '017145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G801',
    cap: ['25080'],
    popolazione: 2468,
  },
  {
    nome: 'Polverara',
    codice: '028066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G802',
    cap: ['35020'],
    popolazione: 3104,
  },
  {
    nome: 'Polverigi',
    codice: '042038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'G803',
    cap: ['60020'],
    popolazione: 4327,
  },
  {
    nome: 'Pomarance',
    codice: '050027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G804',
    cap: ['56045'],
    popolazione: 5845,
  },
  {
    nome: 'Pomaretto',
    codice: '001198',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G805',
    cap: ['10063'],
    popolazione: 1068,
  },
  {
    nome: 'Pomarico',
    codice: '077022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'G806',
    cap: ['75016'],
    popolazione: 4238,
  },
  {
    nome: 'Pomaro Monferrato',
    codice: '006131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G807',
    cap: ['15040'],
    popolazione: 390,
  },
  {
    nome: 'Pomarolo',
    codice: '022144',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'G808',
    cap: ['38060'],
    popolazione: 2355,
  },
  {
    nome: 'Pombia',
    codice: '003121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'G809',
    cap: ['28050'],
    popolazione: 2182,
  },
  {
    nome: 'Pomezia',
    codice: '058079',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G811',
    cap: ['00071'],
    popolazione: 56372,
  },
  {
    nome: "Pomigliano d'Arco",
    codice: '063057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G812',
    cap: ['80038'],
    popolazione: 40083,
  },
  {
    nome: 'Pompei',
    codice: '063058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G813',
    cap: ['80045'],
    popolazione: 25440,
  },
  {
    nome: 'Pompeiana',
    codice: '008044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G814',
    cap: ['18015'],
    popolazione: 809,
  },
  {
    nome: 'Pompiano',
    codice: '017146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G815',
    cap: ['25030'],
    popolazione: 3893,
  },
  {
    nome: 'Pomponesco',
    codice: '020043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G816',
    cap: ['46030'],
    popolazione: 1763,
  },
  {
    nome: 'Pompu',
    codice: '095042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'G817',
    cap: ['09093'],
    popolazione: 278,
  },
  {
    nome: 'Poncarale',
    codice: '017147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G818',
    cap: ['25020'],
    popolazione: 5219,
  },
  {
    nome: 'Ponderano',
    codice: '096047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G820',
    cap: ['13875'],
    popolazione: 3927,
  },
  {
    nome: 'Ponna',
    codice: '013187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G821',
    cap: ['22020'],
    popolazione: 267,
  },
  {
    nome: 'Ponsacco',
    codice: '050028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G822',
    cap: ['56038'],
    popolazione: 15237,
  },
  {
    nome: 'Ponso',
    codice: '028067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G823',
    cap: ['35040'],
    popolazione: 2449,
  },
  {
    nome: 'Pont-Canavese',
    codice: '001199',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G826',
    cap: ['10085'],
    popolazione: 3676,
  },
  {
    nome: 'Pont-Saint-Martin',
    codice: '007052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G854',
    cap: ['11026'],
    popolazione: 4005,
  },
  {
    nome: 'Pontassieve',
    codice: '048033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'G825',
    cap: ['50065'],
    popolazione: 20529,
  },
  {
    nome: 'Pontboset',
    codice: '007050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G545',
    cap: ['11020'],
    popolazione: 195,
  },
  {
    nome: 'Ponte',
    codice: '062053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G827',
    cap: ['82030'],
    popolazione: 2661,
  },
  {
    nome: 'Ponte Buggianese',
    codice: '047016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'G833',
    cap: ['51019'],
    popolazione: 8771,
  },
  {
    nome: "Ponte dell'Olio",
    codice: '033036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G842',
    cap: ['29028'],
    popolazione: 4936,
  },
  {
    nome: 'Ponte di Legno',
    codice: '017148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G844',
    cap: ['25056'],
    popolazione: 1754,
  },
  {
    nome: 'Ponte di Piave',
    codice: '026058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G846',
    cap: ['31047'],
    popolazione: 8312,
  },
  {
    nome: 'Ponte Gardena',
    codice: '021065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G830',
    cap: ['39040'],
    popolazione: 195,
  },
  {
    nome: 'Ponte in Valtellina',
    codice: '014052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G829',
    cap: ['23026'],
    popolazione: 2304,
  },
  {
    nome: 'Ponte Lambro',
    codice: '013188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G847',
    cap: ['22037'],
    popolazione: 4345,
  },
  {
    nome: 'Ponte nelle Alpi',
    codice: '025040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'B662',
    cap: ['32014'],
    popolazione: 8387,
  },
  {
    nome: 'Ponte Nizza',
    codice: '018117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G851',
    cap: ['27050'],
    popolazione: 811,
  },
  {
    nome: 'Ponte Nossa',
    codice: '016168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'F941',
    cap: ['24028'],
    popolazione: 1913,
  },
  {
    nome: 'Ponte San Nicolò',
    codice: '028069',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G855',
    cap: ['35020'],
    popolazione: 13237,
  },
  {
    nome: 'Ponte San Pietro',
    codice: '016170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G856',
    cap: ['24036'],
    popolazione: 11273,
  },
  {
    nome: 'Pontebba',
    codice: '030076',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G831',
    cap: ['33016'],
    popolazione: 1503,
  },
  {
    nome: 'Pontecagnano Faiano',
    codice: '065099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G834',
    cap: ['84098'],
    popolazione: 25096,
  },
  {
    nome: 'Pontecchio Polesine',
    codice: '029038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G836',
    cap: ['45030'],
    popolazione: 2078,
  },
  {
    nome: 'Pontechianale',
    codice: '004172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G837',
    cap: ['12020'],
    popolazione: 182,
  },
  {
    nome: 'Pontecorvo',
    codice: '060056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G838',
    cap: ['03037'],
    popolazione: 13223,
  },
  {
    nome: 'Pontecurone',
    codice: '006132',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G839',
    cap: ['15055'],
    popolazione: 3850,
  },
  {
    nome: 'Pontedassio',
    codice: '008045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G840',
    cap: ['18027'],
    popolazione: 2356,
  },
  {
    nome: 'Pontedera',
    codice: '050029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'G843',
    cap: ['56025'],
    popolazione: 28061,
  },
  {
    nome: 'Pontelandolfo',
    codice: '062054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'G848',
    cap: ['82027'],
    popolazione: 2288,
  },
  {
    nome: 'Pontelatone',
    codice: '061061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G849',
    cap: ['81040'],
    popolazione: 1758,
  },
  {
    nome: 'Pontelongo',
    codice: '028068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G850',
    cap: ['35029'],
    popolazione: 3902,
  },
  {
    nome: 'Pontenure',
    codice: '033037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G852',
    cap: ['29010'],
    popolazione: 6373,
  },
  {
    nome: 'Ponteranica',
    codice: '016169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G853',
    cap: ['24010'],
    popolazione: 6788,
  },
  {
    nome: 'Pontestura',
    codice: '006133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G858',
    cap: ['15027'],
    popolazione: 1508,
  },
  {
    nome: 'Pontevico',
    codice: '017149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G859',
    cap: ['25026'],
    popolazione: 7121,
  },
  {
    nome: 'Pontey',
    codice: '007051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'G860',
    cap: ['11024'],
    popolazione: 818,
  },
  {
    nome: 'Ponti',
    codice: '006134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G861',
    cap: ['15010'],
    popolazione: 618,
  },
  {
    nome: 'Ponti sul Mincio',
    codice: '020044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G862',
    cap: ['46040'],
    popolazione: 2272,
  },
  {
    nome: 'Pontida',
    codice: '016171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G864',
    cap: ['24030'],
    popolazione: 3210,
  },
  {
    nome: 'Pontinia',
    codice: '059017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'G865',
    cap: ['04014'],
    popolazione: 13812,
  },
  {
    nome: 'Pontinvrea',
    codice: '009051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'G866',
    cap: ['17042'],
    popolazione: 846,
  },
  {
    nome: 'Pontirolo Nuovo',
    codice: '016172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G867',
    cap: ['24040'],
    popolazione: 4993,
  },
  {
    nome: 'Pontoglio',
    codice: '017150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G869',
    cap: ['25037'],
    popolazione: 6894,
  },
  {
    nome: 'Pontremoli',
    codice: '045014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'G870',
    cap: ['54027'],
    popolazione: 7633,
  },
  {
    nome: 'Ponza',
    codice: '059018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'G871',
    cap: ['04027'],
    popolazione: 3255,
  },
  {
    nome: 'Ponzano di Fermo',
    codice: '109032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'G873',
    cap: ['63845'],
    popolazione: 1708,
  },
  {
    nome: 'Ponzano Monferrato',
    codice: '006135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G872',
    cap: ['15020'],
    popolazione: 380,
  },
  {
    nome: 'Ponzano Romano',
    codice: '058080',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'G874',
    cap: ['00060'],
    popolazione: 1158,
  },
  {
    nome: 'Ponzano Veneto',
    codice: '026059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G875',
    cap: ['31050'],
    popolazione: 12194,
  },
  {
    nome: 'Ponzone',
    codice: '006136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G877',
    cap: ['15010'],
    popolazione: 1071,
  },
  {
    nome: 'Popoli',
    codice: '068033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'G878',
    cap: ['65026'],
    popolazione: 5450,
  },
  {
    nome: 'Poppi',
    codice: '051031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'G879',
    cap: ['52014'],
    popolazione: 6196,
  },
  {
    nome: 'Porano',
    codice: '055028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'G881',
    cap: ['05010'],
    popolazione: 1989,
  },
  {
    nome: 'Porcari',
    codice: '046026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'G882',
    cap: ['55016'],
    popolazione: 8604,
  },
  {
    nome: 'Porcia',
    codice: '093032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'G886',
    cap: ['33080'],
    popolazione: 15251,
  },
  {
    nome: 'Pordenone',
    codice: '093033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'G888',
    cap: ['33170'],
    popolazione: 50583,
  },
  {
    nome: 'Porlezza',
    codice: '013189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'G889',
    cap: ['22018'],
    popolazione: 4661,
  },
  {
    nome: 'Pornassio',
    codice: '008046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'G890',
    cap: ['18024'],
    popolazione: 584,
  },
  {
    nome: 'Porpetto',
    codice: '030077',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G891',
    cap: ['33050'],
    popolazione: 2650,
  },
  {
    nome: 'Portacomaro',
    codice: '005087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'G894',
    cap: ['14037'],
    popolazione: 1976,
  },
  {
    nome: 'Portalbera',
    codice: '018118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'G895',
    cap: ['27040'],
    popolazione: 1551,
  },
  {
    nome: 'Porte',
    codice: '001200',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G900',
    cap: ['10060'],
    popolazione: 1113,
  },
  {
    nome: 'Porte di Rendena',
    codice: '022244',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M358',
    cap: ['38094'],
    popolazione: 1757,
  },
  {
    nome: 'Portici',
    codice: '063059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G902',
    cap: ['80055'],
    popolazione: 55765,
  },
  {
    nome: 'Portico di Caserta',
    codice: '061062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G903',
    cap: ['81050'],
    popolazione: 7719,
  },
  {
    nome: 'Portico e San Benedetto',
    codice: '040031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'G904',
    cap: ['47010'],
    popolazione: 769,
  },
  {
    nome: 'Portigliola',
    codice: '080062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'G905',
    cap: ['89040'],
    popolazione: 1205,
  },
  {
    nome: 'Porto Azzurro',
    codice: '049013',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'E680',
    cap: ['57036'],
    popolazione: 3826,
  },
  {
    nome: 'Porto Ceresio',
    codice: '012113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'G906',
    cap: ['21050'],
    popolazione: 3001,
  },
  {
    nome: 'Porto Cesareo',
    codice: '075097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'M263',
    cap: ['73010'],
    popolazione: 5448,
  },
  {
    nome: 'Porto Empedocle',
    codice: '084028',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'F299',
    cap: ['92014'],
    popolazione: 16841,
  },
  {
    nome: 'Porto Mantovano',
    codice: '020045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'G917',
    cap: ['46047'],
    popolazione: 15918,
  },
  {
    nome: 'Porto Recanati',
    codice: '043042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'G919',
    cap: ['62017'],
    popolazione: 11495,
  },
  {
    nome: 'Porto San Giorgio',
    codice: '109033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'G920',
    cap: ['63822'],
    popolazione: 15957,
  },
  {
    nome: "Porto Sant'Elpidio",
    codice: '109034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'G921',
    cap: ['63821'],
    popolazione: 25324,
  },
  {
    nome: 'Porto Tolle',
    codice: '029039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G923',
    cap: ['45018'],
    popolazione: 10058,
  },
  {
    nome: 'Porto Torres',
    codice: '090058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G924',
    cap: ['07046'],
    popolazione: 22391,
  },
  {
    nome: 'Porto Valtravaglia',
    codice: '012114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'G907',
    cap: ['21010'],
    popolazione: 2348,
  },
  {
    nome: 'Porto Viro',
    codice: '029052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'G926',
    cap: ['45014'],
    popolazione: 14645,
  },
  {
    nome: 'Portobuffolè',
    codice: '026060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G909',
    cap: ['31040'],
    popolazione: 790,
  },
  {
    nome: 'Portocannone',
    codice: '070055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'G910',
    cap: ['86045'],
    popolazione: 2549,
  },
  {
    nome: 'Portoferraio',
    codice: '049014',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'G912',
    cap: ['57037'],
    popolazione: 11641,
  },
  {
    nome: 'Portofino',
    codice: '010044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'G913',
    cap: ['16034'],
    popolazione: 453,
  },
  {
    nome: 'Portogruaro',
    codice: '027029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'G914',
    cap: ['30026'],
    popolazione: 25140,
  },
  {
    nome: 'Portomaggiore',
    codice: '038019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'G916',
    cap: ['44015'],
    popolazione: 12185,
  },
  {
    nome: 'Portopalo di Capo Passero',
    codice: '089020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'M257',
    cap: ['96010'],
    popolazione: 3749,
  },
  {
    nome: 'Portoscuso',
    codice: '111057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G922',
    cap: ['09010'],
    popolazione: 5236,
  },
  {
    nome: 'Portovenere',
    codice: '011022',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'G925',
    cap: ['19025'],
    popolazione: 3702,
  },
  {
    nome: 'Portula',
    codice: '096048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G927',
    cap: ['13833'],
    popolazione: 1365,
  },
  {
    nome: 'Posada',
    codice: '091073',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'G929',
    cap: ['08020'],
    popolazione: 2737,
  },
  {
    nome: 'Posina',
    codice: '024080',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G931',
    cap: ['36010'],
    popolazione: 577,
  },
  {
    nome: 'Positano',
    codice: '065100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G932',
    cap: ['84017'],
    popolazione: 3858,
  },
  {
    nome: 'Possagno',
    codice: '026061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G933',
    cap: ['31054'],
    popolazione: 2195,
  },
  {
    nome: 'Posta',
    codice: '057057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G934',
    cap: ['02019'],
    popolazione: 686,
  },
  {
    nome: 'Posta Fibreno',
    codice: '060057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'G935',
    cap: ['03030'],
    popolazione: 1217,
  },
  {
    nome: 'Postal',
    codice: '021066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'G936',
    cap: ['39014'],
    popolazione: 1763,
  },
  {
    nome: 'Postalesio',
    codice: '014053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G937',
    cap: ['23010'],
    popolazione: 659,
  },
  {
    nome: 'Postiglione',
    codice: '065101',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G939',
    cap: ['84026'],
    popolazione: 2198,
  },
  {
    nome: 'Postua',
    codice: '002102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G940',
    cap: ['13010'],
    popolazione: 594,
  },
  {
    nome: 'Potenza',
    codice: '076063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G942',
    cap: ['85100'],
    popolazione: 66777,
  },
  {
    nome: 'Potenza Picena',
    codice: '043043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'F632',
    cap: ['62018'],
    popolazione: 15843,
  },
  {
    nome: 'Pove del Grappa',
    codice: '024081',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G943',
    cap: ['36020'],
    popolazione: 3093,
  },
  {
    nome: 'Povegliano',
    codice: '026062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'G944',
    cap: ['31050'],
    popolazione: 5052,
  },
  {
    nome: 'Povegliano Veronese',
    codice: '023060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'G945',
    cap: ['37064'],
    popolazione: 7064,
  },
  {
    nome: 'Poviglio',
    codice: '035029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'G947',
    cap: ['42028'],
    popolazione: 7045,
  },
  {
    nome: 'Povoletto',
    codice: '030078',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G949',
    cap: ['33040'],
    popolazione: 5572,
  },
  {
    nome: 'Pozzaglia Sabina',
    codice: '057058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G951',
    cap: ['02030'],
    popolazione: 361,
  },
  {
    nome: 'Pozzaglio ed Uniti',
    codice: '019077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'B914',
    cap: ['26010'],
    popolazione: 1471,
  },
  {
    nome: 'Pozzallo',
    codice: '088008',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'G953',
    cap: ['97016'],
    popolazione: 18929,
  },
  {
    nome: 'Pozzilli',
    codice: '094038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'G954',
    cap: ['86077'],
    popolazione: 2275,
  },
  {
    nome: "Pozzo d'Adda",
    codice: '015177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G955',
    cap: ['20060'],
    popolazione: 5667,
  },
  {
    nome: 'Pozzol Groppo',
    codice: '006137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G960',
    cap: ['15050'],
    popolazione: 365,
  },
  {
    nome: 'Pozzolengo',
    codice: '017151',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G959',
    cap: ['25010'],
    popolazione: 3438,
  },
  {
    nome: 'Pozzoleone',
    codice: '024082',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'G957',
    cap: ['36050'],
    popolazione: 2793,
  },
  {
    nome: 'Pozzolo Formigaro',
    codice: '006138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G961',
    cap: ['15068'],
    popolazione: 4910,
  },
  {
    nome: 'Pozzomaggiore',
    codice: '090059',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'G962',
    cap: ['07018'],
    popolazione: 2717,
  },
  {
    nome: 'Pozzonovo',
    codice: '028070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'G963',
    cap: ['35020'],
    popolazione: 3653,
  },
  {
    nome: 'Pozzuoli',
    codice: '063060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G964',
    cap: ['80078'],
    popolazione: 80357,
  },
  {
    nome: 'Pozzuolo del Friuli',
    codice: '030079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G966',
    cap: ['33050'],
    popolazione: 6880,
  },
  {
    nome: 'Pozzuolo Martesana',
    codice: '015178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'G965',
    cap: ['20060'],
    popolazione: 7983,
  },
  {
    nome: 'Pradalunga',
    codice: '016173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'G968',
    cap: ['24020'],
    popolazione: 4636,
  },
  {
    nome: 'Pradamano',
    codice: '030080',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G969',
    cap: ['33040'],
    popolazione: 3536,
  },
  {
    nome: 'Pradleves',
    codice: '004173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'G970',
    cap: ['12027'],
    popolazione: 272,
  },
  {
    nome: 'Pragelato',
    codice: '001201',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G973',
    cap: ['10060'],
    popolazione: 724,
  },
  {
    nome: 'Praia a Mare',
    codice: '078101',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'G975',
    cap: ['87028'],
    popolazione: 6496,
  },
  {
    nome: 'Praiano',
    codice: '065102',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G976',
    cap: ['84010'],
    popolazione: 2087,
  },
  {
    nome: 'Pralboino',
    codice: '017152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G977',
    cap: ['25020'],
    popolazione: 2912,
  },
  {
    nome: 'Prali',
    codice: '001202',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G978',
    cap: ['10060'],
    popolazione: 272,
  },
  {
    nome: 'Pralormo',
    codice: '001203',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G979',
    cap: ['10040'],
    popolazione: 1945,
  },
  {
    nome: 'Pralungo',
    codice: '096049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G980',
    cap: ['13899'],
    popolazione: 2639,
  },
  {
    nome: 'Pramaggiore',
    codice: '027030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'G981',
    cap: ['30020'],
    popolazione: 4640,
  },
  {
    nome: 'Pramollo',
    codice: '001204',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G982',
    cap: ['10065'],
    popolazione: 242,
  },
  {
    nome: 'Prarolo',
    codice: '002104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'G985',
    cap: ['13012'],
    popolazione: 672,
  },
  {
    nome: 'Prarostino',
    codice: '001205',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G986',
    cap: ['10060'],
    popolazione: 1289,
  },
  {
    nome: 'Prasco',
    codice: '006139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'G987',
    cap: ['15010'],
    popolazione: 552,
  },
  {
    nome: 'Prascorsano',
    codice: '001206',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G988',
    cap: ['10080'],
    popolazione: 781,
  },
  {
    nome: 'Prata Camportaccio',
    codice: '014054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'G993',
    cap: ['23020'],
    popolazione: 2921,
  },
  {
    nome: "Prata d'Ansidonia",
    codice: '066074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'G992',
    cap: ['67020'],
    popolazione: 501,
  },
  {
    nome: 'Prata di Pordenone',
    codice: '093034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'G994',
    cap: ['33080'],
    popolazione: 8451,
  },
  {
    nome: 'Prata di Principato Ultra',
    codice: '064074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'G990',
    cap: ['83030'],
    popolazione: 2978,
  },
  {
    nome: 'Prata Sannita',
    codice: '061063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G991',
    cap: ['81010'],
    popolazione: 1571,
  },
  {
    nome: 'Pratella',
    codice: '061064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'G995',
    cap: ['81010'],
    popolazione: 1615,
  },
  {
    nome: 'Pratiglione',
    codice: '001207',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'G997',
    cap: ['10080'],
    popolazione: 548,
  },
  {
    nome: 'Prato',
    codice: '100005',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'G999',
    cap: ['59100'],
    popolazione: 185456,
  },
  {
    nome: 'Prato allo Stelvio',
    codice: '021067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H004',
    cap: ['39026'],
    popolazione: 3356,
  },
  {
    nome: 'Prato Carnico',
    codice: '030081',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H002',
    cap: ['33020'],
    popolazione: 927,
  },
  {
    nome: 'Prato Sesia',
    codice: '003122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'H001',
    cap: ['28077'],
    popolazione: 1993,
  },
  {
    nome: 'Pratola Peligna',
    codice: '066075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H007',
    cap: ['67035'],
    popolazione: 7840,
  },
  {
    nome: 'Pratola Serra',
    codice: '064075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H006',
    cap: ['83039'],
    popolazione: 3708,
  },
  {
    nome: 'Pratovecchio Stia',
    codice: '051041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'M329',
    cap: ['52015'],
    popolazione: 6011,
  },
  {
    nome: 'Pravisdomini',
    codice: '093035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'H010',
    cap: ['33076'],
    popolazione: 3471,
  },
  {
    nome: 'Pray',
    codice: '096050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'G974',
    cap: ['13867'],
    popolazione: 2307,
  },
  {
    nome: 'Prazzo',
    codice: '004174',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H011',
    cap: ['12028'],
    popolazione: 175,
  },
  {
    nome: 'Pré-Saint-Didier',
    codice: '007053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H042',
    cap: ['11010'],
    popolazione: 1012,
  },
  {
    nome: 'Precenicco',
    codice: '030082',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H014',
    cap: ['33050'],
    popolazione: 1484,
  },
  {
    nome: 'Preci',
    codice: '054043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'H015',
    cap: ['06047'],
    popolazione: 757,
  },
  {
    nome: 'Predaia',
    codice: '022230',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M344',
    cap: ['38012'],
    popolazione: 6522,
  },
  {
    nome: 'Predappio',
    codice: '040032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'H017',
    cap: ['47016'],
    popolazione: 6519,
  },
  {
    nome: 'Predazzo',
    codice: '022147',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H018',
    cap: ['38037'],
    popolazione: 4531,
  },
  {
    nome: 'Predoi',
    codice: '021068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H019',
    cap: ['39030'],
    popolazione: 604,
  },
  {
    nome: 'Predore',
    codice: '016174',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H020',
    cap: ['24060'],
    popolazione: 1859,
  },
  {
    nome: 'Predosa',
    codice: '006140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H021',
    cap: ['15077'],
    popolazione: 2092,
  },
  {
    nome: 'Preganziol',
    codice: '026063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H022',
    cap: ['31022'],
    popolazione: 16772,
  },
  {
    nome: 'Pregnana Milanese',
    codice: '015179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H026',
    cap: ['20010'],
    popolazione: 6867,
  },
  {
    nome: 'Prelà',
    codice: '008047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H027',
    cap: ['18020'],
    popolazione: 500,
  },
  {
    nome: 'Premana',
    codice: '097069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'H028',
    cap: ['23834'],
    popolazione: 2288,
  },
  {
    nome: 'Premariacco',
    codice: '030083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H029',
    cap: ['33040'],
    popolazione: 4187,
  },
  {
    nome: 'Premeno',
    codice: '103055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H030',
    cap: ['28818'],
    popolazione: 746,
  },
  {
    nome: 'Premia',
    codice: '103056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H033',
    cap: ['28866'],
    popolazione: 577,
  },
  {
    nome: 'Premilcuore',
    codice: '040033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'H034',
    cap: ['47010'],
    popolazione: 803,
  },
  {
    nome: 'Premolo',
    codice: '016175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H036',
    cap: ['24020'],
    popolazione: 1171,
  },
  {
    nome: 'Premosello-Chiovenda',
    codice: '103057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H037',
    cap: ['28803'],
    popolazione: 2034,
  },
  {
    nome: 'Preone',
    codice: '030084',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H038',
    cap: ['33020'],
    popolazione: 266,
  },
  {
    nome: 'Prepotto',
    codice: '030085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H040',
    cap: ['33040'],
    popolazione: 809,
  },
  {
    nome: 'Preseglie',
    codice: '017153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H043',
    cap: ['25070'],
    popolazione: 1577,
  },
  {
    nome: 'Presenzano',
    codice: '061065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H045',
    cap: ['81050'],
    popolazione: 1747,
  },
  {
    nome: 'Presezzo',
    codice: '016176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H046',
    cap: ['24030'],
    popolazione: 4934,
  },
  {
    nome: 'Presicce-Acquarica',
    codice: '075098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'M428',
    cap: ['73054'],
    popolazione: 10487,
  },
  {
    nome: 'Pressana',
    codice: '023061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H048',
    cap: ['37040'],
    popolazione: 2564,
  },
  {
    nome: 'Pretoro',
    codice: '069069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H052',
    cap: ['66010'],
    popolazione: 989,
  },
  {
    nome: 'Prevalle',
    codice: '017155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H055',
    cap: ['25080'],
    popolazione: 6816,
  },
  {
    nome: 'Prezza',
    codice: '066076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H056',
    cap: ['67030'],
    popolazione: 1015,
  },
  {
    nome: 'Priero',
    codice: '004175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H059',
    cap: ['12070'],
    popolazione: 487,
  },
  {
    nome: 'Prignano Cilento',
    codice: '065103',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H062',
    cap: ['84060'],
    popolazione: 997,
  },
  {
    nome: 'Prignano sulla Secchia',
    codice: '036033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'H061',
    cap: ['41048'],
    popolazione: 3773,
  },
  {
    nome: 'Primaluna',
    codice: '097070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'H063',
    cap: ['23819'],
    popolazione: 2187,
  },
  {
    nome: 'Primiero San Martino di Castrozza',
    codice: '022245',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M359',
    cap: ['38054'],
    popolazione: 5406,
  },
  {
    nome: 'Priocca',
    codice: '004176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H068',
    cap: ['12040'],
    popolazione: 2001,
  },
  {
    nome: 'Priola',
    codice: '004177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H069',
    cap: ['12070'],
    popolazione: 719,
  },
  {
    nome: 'Priolo Gargallo',
    codice: '089021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'M279',
    cap: ['96010'],
    popolazione: 12167,
  },
  {
    nome: 'Priverno',
    codice: '059019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'G698',
    cap: ['04015'],
    popolazione: 13891,
  },
  {
    nome: 'Prizzi',
    codice: '082060',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'H070',
    cap: ['90038'],
    popolazione: 5055,
  },
  {
    nome: 'Proceno',
    codice: '056044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'H071',
    cap: ['01020'],
    popolazione: 605,
  },
  {
    nome: 'Procida',
    codice: '063061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H072',
    cap: ['80079'],
    popolazione: 10228,
  },
  {
    nome: 'Propata',
    codice: '010045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H073',
    cap: ['16027'],
    popolazione: 161,
  },
  {
    nome: 'Proserpio',
    codice: '013192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H074',
    cap: ['22030'],
    popolazione: 892,
  },
  {
    nome: 'Prossedi',
    codice: '059020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'H076',
    cap: ['04010'],
    popolazione: 1233,
  },
  {
    nome: "Provaglio d'Iseo",
    codice: '017156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H078',
    cap: ['25050'],
    popolazione: 7136,
  },
  {
    nome: 'Provaglio Val Sabbia',
    codice: '017157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H077',
    cap: ['25070'],
    popolazione: 966,
  },
  {
    nome: 'Proves',
    codice: '021069',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H081',
    cap: ['39040'],
    popolazione: 267,
  },
  {
    nome: 'Provvidenti',
    codice: '070056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H083',
    cap: ['86040'],
    popolazione: 122,
  },
  {
    nome: 'Prunetto',
    codice: '004178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H085',
    cap: ['12077'],
    popolazione: 471,
  },
  {
    nome: 'Puegnago del Garda',
    codice: '017158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H086',
    cap: ['25080'],
    popolazione: 3263,
  },
  {
    nome: 'Puglianello',
    codice: '062055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H087',
    cap: ['82030'],
    popolazione: 1380,
  },
  {
    nome: 'Pula',
    codice: '092050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'H088',
    cap: ['09050'],
    popolazione: 7141,
  },
  {
    nome: 'Pulfero',
    codice: '030086',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H089',
    cap: ['33046'],
    popolazione: 1033,
  },
  {
    nome: 'Pulsano',
    codice: '073022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'H090',
    cap: ['74026'],
    popolazione: 11062,
  },
  {
    nome: 'Pumenengo',
    codice: '016177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H091',
    cap: ['24050'],
    popolazione: 1695,
  },
  {
    nome: 'Pusiano',
    codice: '013193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H094',
    cap: ['22030'],
    popolazione: 1327,
  },
  {
    nome: 'Putifigari',
    codice: '090060',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'H095',
    cap: ['07040'],
    popolazione: 757,
  },
  {
    nome: 'Putignano',
    codice: '072036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'H096',
    cap: ['70017'],
    popolazione: 27083,
  },
  {
    nome: 'Quadrelle',
    codice: '064076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H097',
    cap: ['83020'],
    popolazione: 1893,
  },
  {
    nome: 'Quadri',
    codice: '069070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H098',
    cap: ['66040'],
    popolazione: 863,
  },
  {
    nome: 'Quagliuzzo',
    codice: '001208',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H100',
    cap: ['10010'],
    popolazione: 331,
  },
  {
    nome: 'Qualiano',
    codice: '063062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H101',
    cap: ['80019'],
    popolazione: 24744,
  },
  {
    nome: 'Quaranti',
    codice: '005088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H102',
    cap: ['14040'],
    popolazione: 184,
  },
  {
    nome: 'Quaregna Cerreto',
    codice: '096087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M414',
    cap: ['13854'],
    popolazione: 2018,
  },
  {
    nome: 'Quargnento',
    codice: '006141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H104',
    cap: ['15044'],
    popolazione: 1397,
  },
  {
    nome: 'Quarna Sopra',
    codice: '103058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H106',
    cap: ['28898'],
    popolazione: 289,
  },
  {
    nome: 'Quarna Sotto',
    codice: '103059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H107',
    cap: ['28896'],
    popolazione: 420,
  },
  {
    nome: 'Quarona',
    codice: '002107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H108',
    cap: ['13017'],
    popolazione: 4246,
  },
  {
    nome: 'Quarrata',
    codice: '047017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'H109',
    cap: ['51039'],
    popolazione: 25378,
  },
  {
    nome: 'Quart',
    codice: '007054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H110',
    cap: ['11020'],
    popolazione: 3872,
  },
  {
    nome: 'Quarto',
    codice: '063063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H114',
    cap: ['80010'],
    popolazione: 39221,
  },
  {
    nome: "Quarto d'Altino",
    codice: '027031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'H117',
    cap: ['30020'],
    popolazione: 8199,
  },
  {
    nome: "Quartu Sant'Elena",
    codice: '092051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'H118',
    cap: ['09045'],
    popolazione: 69296,
  },
  {
    nome: 'Quartucciu',
    codice: '092105',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'H119',
    cap: ['09044'],
    popolazione: 12825,
  },
  {
    nome: 'Quassolo',
    codice: '001209',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H120',
    cap: ['10010'],
    popolazione: 359,
  },
  {
    nome: 'Quattordio',
    codice: '006142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H121',
    cap: ['15028'],
    popolazione: 1668,
  },
  {
    nome: 'Quattro Castella',
    codice: '035030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'H122',
    cap: ['42020'],
    popolazione: 12909,
  },
  {
    nome: 'Quero Vas',
    codice: '025070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M332',
    cap: ['32038'],
    popolazione: 3338,
  },
  {
    nome: 'Quiliano',
    codice: '009052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'H126',
    cap: ['17047'],
    popolazione: 7336,
  },
  {
    nome: 'Quincinetto',
    codice: '001210',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H127',
    cap: ['10010'],
    popolazione: 1048,
  },
  {
    nome: 'Quindici',
    codice: '064077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H128',
    cap: ['83020'],
    popolazione: 1785,
  },
  {
    nome: 'Quingentole',
    codice: '020046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H129',
    cap: ['46020'],
    popolazione: 1194,
  },
  {
    nome: 'Quintano',
    codice: '019078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H130',
    cap: ['26017'],
    popolazione: 910,
  },
  {
    nome: 'Quinto di Treviso',
    codice: '026064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H131',
    cap: ['31055'],
    popolazione: 9745,
  },
  {
    nome: 'Quinto Vercellese',
    codice: '002108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H132',
    cap: ['13030'],
    popolazione: 400,
  },
  {
    nome: 'Quinto Vicentino',
    codice: '024083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H134',
    cap: ['36050'],
    popolazione: 5694,
  },
  {
    nome: "Quinzano d'Oglio",
    codice: '017159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H140',
    cap: ['25027'],
    popolazione: 6390,
  },
  {
    nome: 'Quistello',
    codice: '020047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H143',
    cap: ['46026'],
    popolazione: 5722,
  },
  {
    nome: 'Rabbi',
    codice: '022150',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H146',
    cap: ['38020'],
    popolazione: 1400,
  },
  {
    nome: 'Racale',
    codice: '075063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H147',
    cap: ['73055'],
    popolazione: 10734,
  },
  {
    nome: 'Racalmuto',
    codice: '084029',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H148',
    cap: ['92020'],
    popolazione: 8345,
  },
  {
    nome: 'Racconigi',
    codice: '004179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H150',
    cap: ['12035'],
    popolazione: 10028,
  },
  {
    nome: 'Raccuja',
    codice: '083069',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H151',
    cap: ['98067'],
    popolazione: 1139,
  },
  {
    nome: 'Racines',
    codice: '021070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H152',
    cap: ['39040'],
    popolazione: 4389,
  },
  {
    nome: 'Radda in Chianti',
    codice: '052023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'H153',
    cap: ['53017'],
    popolazione: 1693,
  },
  {
    nome: 'Raddusa',
    codice: '087036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H154',
    cap: ['95040'],
    popolazione: 3280,
  },
  {
    nome: 'Radicofani',
    codice: '052024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'H156',
    cap: ['53040'],
    popolazione: 1151,
  },
  {
    nome: 'Radicondoli',
    codice: '052025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'H157',
    cap: ['53030'],
    popolazione: 931,
  },
  {
    nome: 'Raffadali',
    codice: '084030',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H159',
    cap: ['92015'],
    popolazione: 12837,
  },
  {
    nome: 'Ragalna',
    codice: '087058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'M287',
    cap: ['95030'],
    popolazione: 3676,
  },
  {
    nome: 'Ragogna',
    codice: '030087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H161',
    cap: ['33030'],
    popolazione: 3023,
  },
  {
    nome: 'Ragusa',
    codice: '088009',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'H163',
    cap: ['97100'],
    popolazione: 69794,
  },
  {
    nome: 'Raiano',
    codice: '066077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H166',
    cap: ['67027'],
    popolazione: 2812,
  },
  {
    nome: 'Ramacca',
    codice: '087037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H168',
    cap: ['95040'],
    popolazione: 10775,
  },
  {
    nome: 'Rancio Valcuvia',
    codice: '012115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'H173',
    cap: ['21030'],
    popolazione: 935,
  },
  {
    nome: 'Ranco',
    codice: '012116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'H174',
    cap: ['21020'],
    popolazione: 1326,
  },
  {
    nome: 'Randazzo',
    codice: '087038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H175',
    cap: ['95036'],
    popolazione: 11108,
  },
  {
    nome: 'Ranica',
    codice: '016178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H176',
    cap: ['24020'],
    popolazione: 5986,
  },
  {
    nome: 'Ranzanico',
    codice: '016179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H177',
    cap: ['24060'],
    popolazione: 1261,
  },
  {
    nome: 'Ranzo',
    codice: '008048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H180',
    cap: ['18020'],
    popolazione: 556,
  },
  {
    nome: 'Rapagnano',
    codice: '109035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'H182',
    cap: ['63831'],
    popolazione: 2044,
  },
  {
    nome: 'Rapallo',
    codice: '010046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H183',
    cap: ['16035'],
    popolazione: 29226,
  },
  {
    nome: 'Rapino',
    codice: '069071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H184',
    cap: ['66010'],
    popolazione: 1356,
  },
  {
    nome: 'Rapolano Terme',
    codice: '052026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'H185',
    cap: ['53040'],
    popolazione: 5129,
  },
  {
    nome: 'Rapolla',
    codice: '076064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H186',
    cap: ['85027'],
    popolazione: 4430,
  },
  {
    nome: 'Rapone',
    codice: '076065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H187',
    cap: ['85020'],
    popolazione: 1013,
  },
  {
    nome: 'Rassa',
    codice: '002110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H188',
    cap: ['13020'],
    popolazione: 66,
  },
  {
    nome: 'Rasun-Anterselva',
    codice: '021071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H189',
    cap: ['39030'],
    popolazione: 2869,
  },
  {
    nome: 'Rasura',
    codice: '014055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'H192',
    cap: ['23010'],
    popolazione: 292,
  },
  {
    nome: 'Ravanusa',
    codice: '084031',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H194',
    cap: ['92029'],
    popolazione: 12128,
  },
  {
    nome: 'Ravarino',
    codice: '036034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'H195',
    cap: ['41017'],
    popolazione: 6165,
  },
  {
    nome: 'Ravascletto',
    codice: '030088',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H196',
    cap: ['33020'],
    popolazione: 560,
  },
  {
    nome: 'Ravello',
    codice: '065104',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H198',
    cap: ['84010'],
    popolazione: 2462,
  },
  {
    nome: 'Ravenna',
    codice: '039014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'H199',
    cap: ['48121', '48122', '48123', '48124', '48125'],
    popolazione: 153740,
  },
  {
    nome: 'Raveo',
    codice: '030089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H200',
    cap: ['33029'],
    popolazione: 508,
  },
  {
    nome: 'Raviscanina',
    codice: '061066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H202',
    cap: ['81017'],
    popolazione: 1376,
  },
  {
    nome: 'Re',
    codice: '103060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H203',
    cap: ['28856'],
    popolazione: 757,
  },
  {
    nome: 'Rea',
    codice: '018119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H204',
    cap: ['27040'],
    popolazione: 432,
  },
  {
    nome: 'Realmonte',
    codice: '084032',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H205',
    cap: ['92010'],
    popolazione: 4487,
  },
  {
    nome: 'Reana del Rojale',
    codice: '030090',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H206',
    cap: ['33010'],
    popolazione: 5032,
  },
  {
    nome: 'Reano',
    codice: '001211',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H207',
    cap: ['10090'],
    popolazione: 1689,
  },
  {
    nome: 'Recale',
    codice: '061067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H210',
    cap: ['81020'],
    popolazione: 7611,
  },
  {
    nome: 'Recanati',
    codice: '043044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'H211',
    cap: ['62019'],
    popolazione: 21416,
  },
  {
    nome: 'Recco',
    codice: '010047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H212',
    cap: ['16036'],
    popolazione: 10106,
  },
  {
    nome: 'Recetto',
    codice: '003129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'H213',
    cap: ['28060'],
    popolazione: 916,
  },
  {
    nome: 'Recoaro Terme',
    codice: '024084',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H214',
    cap: ['36076'],
    popolazione: 6764,
  },
  {
    nome: 'Redavalle',
    codice: '018120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H216',
    cap: ['27050'],
    popolazione: 1056,
  },
  {
    nome: 'Redondesco',
    codice: '020048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H218',
    cap: ['46010'],
    popolazione: 1335,
  },
  {
    nome: 'Refrancore',
    codice: '005089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H219',
    cap: ['14030'],
    popolazione: 1669,
  },
  {
    nome: 'Refrontolo',
    codice: '026065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H220',
    cap: ['31020'],
    popolazione: 1824,
  },
  {
    nome: 'Regalbuto',
    codice: '086016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'H221',
    cap: ['94017'],
    popolazione: 7388,
  },
  {
    nome: 'Reggello',
    codice: '048035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'H222',
    cap: ['50066'],
    popolazione: 16076,
  },
  {
    nome: 'Reggio di Calabria',
    codice: '080063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H224',
    cap: [
      '89121',
      '89122',
      '89123',
      '89124',
      '89125',
      '89126',
      '89127',
      '89128',
      '89129',
      '89130',
      '89131',
      '89132',
      '89133',
      '89134',
      '89135',
    ],
    popolazione: 180817,
  },
  {
    nome: "Reggio nell'Emilia",
    codice: '035033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'H223',
    cap: ['42121', '42122', '42123', '42124'],
    popolazione: 162082,
  },
  {
    nome: 'Reggiolo',
    codice: '035032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'H225',
    cap: ['42046'],
    popolazione: 9217,
  },
  {
    nome: 'Reino',
    codice: '062056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H227',
    cap: ['82020'],
    popolazione: 1262,
  },
  {
    nome: 'Reitano',
    codice: '083070',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H228',
    cap: ['98070'],
    popolazione: 829,
  },
  {
    nome: 'Remanzacco',
    codice: '030091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H229',
    cap: ['33047'],
    popolazione: 6066,
  },
  {
    nome: 'Remedello',
    codice: '017160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H230',
    cap: ['25010'],
    popolazione: 3387,
  },
  {
    nome: 'Renate',
    codice: '108037',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'H233',
    cap: ['20838'],
    popolazione: 4177,
  },
  {
    nome: 'Rende',
    codice: '078102',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H235',
    cap: ['87036'],
    popolazione: 33555,
  },
  {
    nome: 'Renon',
    codice: '021072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H236',
    cap: ['39054'],
    popolazione: 7642,
  },
  {
    nome: 'Resana',
    codice: '026066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H238',
    cap: ['31023'],
    popolazione: 9290,
  },
  {
    nome: 'Rescaldina',
    codice: '015181',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H240',
    cap: ['20027'],
    popolazione: 13920,
  },
  {
    nome: 'Resia',
    codice: '030092',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H242',
    cap: ['33010'],
    popolazione: 1091,
  },
  {
    nome: 'Resiutta',
    codice: '030093',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H244',
    cap: ['33010'],
    popolazione: 315,
  },
  {
    nome: 'Resuttano',
    codice: '085014',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'H245',
    cap: ['93010'],
    popolazione: 2139,
  },
  {
    nome: 'Retorbido',
    codice: '018121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H246',
    cap: ['27050'],
    popolazione: 1506,
  },
  {
    nome: 'Revello',
    codice: '004180',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H247',
    cap: ['12036'],
    popolazione: 4203,
  },
  {
    nome: "Revigliasco d'Asti",
    codice: '005090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H250',
    cap: ['14010'],
    popolazione: 833,
  },
  {
    nome: 'Revine Lago',
    codice: '026067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H253',
    cap: ['31020'],
    popolazione: 2241,
  },
  {
    nome: 'Rezzago',
    codice: '013195',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H255',
    cap: ['22030'],
    popolazione: 317,
  },
  {
    nome: 'Rezzato',
    codice: '017161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H256',
    cap: ['25086'],
    popolazione: 12933,
  },
  {
    nome: 'Rezzo',
    codice: '008049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H257',
    cap: ['18026'],
    popolazione: 371,
  },
  {
    nome: 'Rezzoaglio',
    codice: '010048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H258',
    cap: ['16048'],
    popolazione: 1080,
  },
  {
    nome: 'Rhêmes-Notre-Dame',
    codice: '007055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H262',
    cap: ['11010'],
    popolazione: 114,
  },
  {
    nome: 'Rhêmes-Saint-Georges',
    codice: '007056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H263',
    cap: ['11010'],
    popolazione: 196,
  },
  {
    nome: 'Rho',
    codice: '015182',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H264',
    cap: ['20017'],
    popolazione: 50052,
  },
  {
    nome: 'Riace',
    codice: '080064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H265',
    cap: ['89040'],
    popolazione: 1793,
  },
  {
    nome: 'Rialto',
    codice: '009053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'H266',
    cap: ['17020'],
    popolazione: 564,
  },
  {
    nome: 'Riano',
    codice: '058081',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H267',
    cap: ['00060'],
    popolazione: 9536,
  },
  {
    nome: 'Riardo',
    codice: '061068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H268',
    cap: ['81053'],
    popolazione: 2412,
  },
  {
    nome: 'Ribera',
    codice: '084033',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H269',
    cap: ['92016'],
    popolazione: 19302,
  },
  {
    nome: 'Ribordone',
    codice: '001212',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H270',
    cap: ['10080'],
    popolazione: 67,
  },
  {
    nome: 'Ricadi',
    codice: '102030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'H271',
    cap: ['89866'],
    popolazione: 4750,
  },
  {
    nome: 'Ricaldone',
    codice: '006143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H272',
    cap: ['15010'],
    popolazione: 675,
  },
  {
    nome: 'Riccia',
    codice: '070057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H273',
    cap: ['86016'],
    popolazione: 5403,
  },
  {
    nome: 'Riccione',
    codice: '099013',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'H274',
    cap: ['47838'],
    popolazione: 34536,
  },
  {
    nome: 'Riccò del Golfo di Spezia',
    codice: '011023',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'H275',
    cap: ['19020'],
    popolazione: 3537,
  },
  {
    nome: 'Ricengo',
    codice: '019079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H276',
    cap: ['26010'],
    popolazione: 1770,
  },
  {
    nome: 'Ricigliano',
    codice: '065105',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H277',
    cap: ['84020'],
    popolazione: 1207,
  },
  {
    nome: 'Riese Pio X',
    codice: '026068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H280',
    cap: ['31039'],
    popolazione: 10858,
  },
  {
    nome: 'Riesi',
    codice: '085015',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'H281',
    cap: ['93016'],
    popolazione: 11814,
  },
  {
    nome: 'Rieti',
    codice: '057059',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'H282',
    cap: ['02100'],
    popolazione: 46187,
  },
  {
    nome: 'Rifiano',
    codice: '021073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H284',
    cap: ['39010'],
    popolazione: 1333,
  },
  {
    nome: 'Rifreddo',
    codice: '004181',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H285',
    cap: ['12030'],
    popolazione: 1072,
  },
  {
    nome: 'Rignano Flaminio',
    codice: '058082',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H288',
    cap: ['00068'],
    popolazione: 9573,
  },
  {
    nome: 'Rignano Garganico',
    codice: '071041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H287',
    cap: ['71010'],
    popolazione: 2200,
  },
  {
    nome: "Rignano sull'Arno",
    codice: '048036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'H286',
    cap: ['50067'],
    popolazione: 8600,
  },
  {
    nome: 'Rigolato',
    codice: '030094',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H289',
    cap: ['33020'],
    popolazione: 502,
  },
  {
    nome: 'Rimella',
    codice: '002113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H293',
    cap: ['13020'],
    popolazione: 137,
  },
  {
    nome: 'Rimini',
    codice: '099014',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'H294',
    cap: ['47921', '47922', '47923', '47924'],
    popolazione: 139601,
  },
  {
    nome: 'Rio',
    codice: '049021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'M391',
    cap: ['57038'],
    popolazione: 3405,
  },
  {
    nome: 'Rio di Pusteria',
    codice: '021074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H299',
    cap: ['39037'],
    popolazione: 2912,
  },
  {
    nome: 'Rio Saliceto',
    codice: '035034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'H298',
    cap: ['42010'],
    popolazione: 6092,
  },
  {
    nome: 'Riofreddo',
    codice: '058083',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H300',
    cap: ['00020'],
    popolazione: 762,
  },
  {
    nome: 'Riola Sardo',
    codice: '095043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'H301',
    cap: ['09070'],
    popolazione: 2146,
  },
  {
    nome: 'Riolo Terme',
    codice: '039015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'H302',
    cap: ['48025'],
    popolazione: 5777,
  },
  {
    nome: 'Riolunato',
    codice: '036035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'H303',
    cap: ['41020'],
    popolazione: 759,
  },
  {
    nome: 'Riomaggiore',
    codice: '011024',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'H304',
    cap: ['19017'],
    popolazione: 1669,
  },
  {
    nome: 'Rionero in Vulture',
    codice: '076066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H307',
    cap: ['85028'],
    popolazione: 13444,
  },
  {
    nome: 'Rionero Sannitico',
    codice: '094039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'H308',
    cap: ['86087'],
    popolazione: 1129,
  },
  {
    nome: 'Ripa Teatina',
    codice: '069072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H320',
    cap: ['66010'],
    popolazione: 4188,
  },
  {
    nome: 'Ripabottoni',
    codice: '070058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H311',
    cap: ['86040'],
    popolazione: 544,
  },
  {
    nome: 'Ripacandida',
    codice: '076067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H312',
    cap: ['85020'],
    popolazione: 1733,
  },
  {
    nome: 'Ripalimosani',
    codice: '070059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H313',
    cap: ['86025'],
    popolazione: 2972,
  },
  {
    nome: 'Ripalta Arpina',
    codice: '019080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H314',
    cap: ['26010'],
    popolazione: 1055,
  },
  {
    nome: 'Ripalta Cremasca',
    codice: '019081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H315',
    cap: ['26010'],
    popolazione: 3394,
  },
  {
    nome: 'Ripalta Guerina',
    codice: '019082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H316',
    cap: ['26010'],
    popolazione: 535,
  },
  {
    nome: 'Riparbella',
    codice: '050030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'H319',
    cap: ['56046'],
    popolazione: 1631,
  },
  {
    nome: 'Ripatransone',
    codice: '044063',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'H321',
    cap: ['63065'],
    popolazione: 4341,
  },
  {
    nome: 'Ripe San Ginesio',
    codice: '043045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'H323',
    cap: ['62020'],
    popolazione: 860,
  },
  {
    nome: 'Ripi',
    codice: '060058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H324',
    cap: ['03027'],
    popolazione: 5346,
  },
  {
    nome: 'Riposto',
    codice: '087039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H325',
    cap: ['95018'],
    popolazione: 14181,
  },
  {
    nome: 'Rittana',
    codice: '004182',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H326',
    cap: ['12010'],
    popolazione: 135,
  },
  {
    nome: 'Riva del Garda',
    codice: '022153',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H330',
    cap: ['38066'],
    popolazione: 15838,
  },
  {
    nome: 'Riva del Po',
    codice: '038029',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'M410',
    cap: ['44033'],
    popolazione: 8508,
  },
  {
    nome: 'Riva di Solto',
    codice: '016180',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H331',
    cap: ['24060'],
    popolazione: 862,
  },
  {
    nome: 'Riva Ligure',
    codice: '008050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H328',
    cap: ['18015'],
    popolazione: 2861,
  },
  {
    nome: 'Riva presso Chieri',
    codice: '001215',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H337',
    cap: ['10020'],
    popolazione: 4207,
  },
  {
    nome: 'Rivalba',
    codice: '001213',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H333',
    cap: ['10090'],
    popolazione: 1159,
  },
  {
    nome: 'Rivalta Bormida',
    codice: '006144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H334',
    cap: ['15010'],
    popolazione: 1417,
  },
  {
    nome: 'Rivalta di Torino',
    codice: '001214',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H335',
    cap: ['10040'],
    popolazione: 19245,
  },
  {
    nome: 'Rivamonte Agordino',
    codice: '025043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'H327',
    cap: ['32020'],
    popolazione: 666,
  },
  {
    nome: 'Rivanazzano Terme',
    codice: '018122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H336',
    cap: ['27055'],
    popolazione: 5186,
  },
  {
    nome: 'Rivara',
    codice: '001216',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H338',
    cap: ['10080'],
    popolazione: 2666,
  },
  {
    nome: 'Rivarolo Canavese',
    codice: '001217',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H340',
    cap: ['10086'],
    popolazione: 12356,
  },
  {
    nome: 'Rivarolo del Re ed Uniti',
    codice: '019083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H341',
    cap: ['26036'],
    popolazione: 2077,
  },
  {
    nome: 'Rivarolo Mantovano',
    codice: '020050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H342',
    cap: ['46017'],
    popolazione: 2608,
  },
  {
    nome: 'Rivarone',
    codice: '006145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H343',
    cap: ['15040'],
    popolazione: 363,
  },
  {
    nome: 'Rivarossa',
    codice: '001218',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H344',
    cap: ['10040'],
    popolazione: 1626,
  },
  {
    nome: 'Rive',
    codice: '002115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H346',
    cap: ['13030'],
    popolazione: 469,
  },
  {
    nome: "Rive d'Arcano",
    codice: '030095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H347',
    cap: ['33030'],
    popolazione: 2479,
  },
  {
    nome: 'Rivello',
    codice: '076068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H348',
    cap: ['85040'],
    popolazione: 2843,
  },
  {
    nome: 'Rivergaro',
    codice: '033038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'H350',
    cap: ['29029'],
    popolazione: 6853,
  },
  {
    nome: 'Rivignano Teor',
    codice: '030188',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'M317',
    cap: ['33061'],
    popolazione: 6403,
  },
  {
    nome: 'Rivisondoli',
    codice: '066078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H353',
    cap: ['67036'],
    popolazione: 663,
  },
  {
    nome: 'Rivodutri',
    codice: '057060',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'H354',
    cap: ['02010'],
    popolazione: 1297,
  },
  {
    nome: 'Rivoli',
    codice: '001219',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H355',
    cap: ['10098'],
    popolazione: 48632,
  },
  {
    nome: 'Rivoli Veronese',
    codice: '023062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H356',
    cap: ['37010'],
    popolazione: 2127,
  },
  {
    nome: "Rivolta d'Adda",
    codice: '019084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H357',
    cap: ['26027'],
    popolazione: 7918,
  },
  {
    nome: 'Rizziconi',
    codice: '080065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H359',
    cap: ['89016'],
    popolazione: 7806,
  },
  {
    nome: 'Roana',
    codice: '024085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H361',
    cap: ['36010'],
    popolazione: 4317,
  },
  {
    nome: 'Roaschia',
    codice: '004183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H362',
    cap: ['12010'],
    popolazione: 138,
  },
  {
    nome: 'Roascio',
    codice: '004184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H363',
    cap: ['12073'],
    popolazione: 83,
  },
  {
    nome: 'Roasio',
    codice: '002116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H365',
    cap: ['13060'],
    popolazione: 2465,
  },
  {
    nome: 'Roatto',
    codice: '005091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H366',
    cap: ['14018'],
    popolazione: 374,
  },
  {
    nome: 'Robassomero',
    codice: '001220',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H367',
    cap: ['10070'],
    popolazione: 2976,
  },
  {
    nome: 'Robbiate',
    codice: '097071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'G223',
    cap: ['23899'],
    popolazione: 6101,
  },
  {
    nome: 'Robbio',
    codice: '018123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H369',
    cap: ['27038'],
    popolazione: 6164,
  },
  {
    nome: 'Robecchetto con Induno',
    codice: '015183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H371',
    cap: ['20020'],
    popolazione: 4869,
  },
  {
    nome: "Robecco d'Oglio",
    codice: '019085',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H372',
    cap: ['26010'],
    popolazione: 2438,
  },
  {
    nome: 'Robecco Pavese',
    codice: '018124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H375',
    cap: ['27042'],
    popolazione: 569,
  },
  {
    nome: 'Robecco sul Naviglio',
    codice: '015184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H373',
    cap: ['20087'],
    popolazione: 6842,
  },
  {
    nome: 'Robella',
    codice: '005092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H376',
    cap: ['14020'],
    popolazione: 504,
  },
  {
    nome: 'Robilante',
    codice: '004185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H377',
    cap: ['12017'],
    popolazione: 2424,
  },
  {
    nome: 'Roburent',
    codice: '004186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H378',
    cap: ['12080'],
    popolazione: 513,
  },
  {
    nome: 'Rocca Canavese',
    codice: '001221',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H386',
    cap: ['10070'],
    popolazione: 1754,
  },
  {
    nome: 'Rocca Canterano',
    codice: '058084',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H387',
    cap: ['00020'],
    popolazione: 207,
  },
  {
    nome: 'Rocca Cigliè',
    codice: '004188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H391',
    cap: ['12060'],
    popolazione: 154,
  },
  {
    nome: "Rocca d'Arazzo",
    codice: '005093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H392',
    cap: ['14030'],
    popolazione: 944,
  },
  {
    nome: "Rocca d'Arce",
    codice: '060059',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H393',
    cap: ['03030'],
    popolazione: 971,
  },
  {
    nome: "Rocca d'Evandro",
    codice: '061069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H398',
    cap: ['81040'],
    popolazione: 3366,
  },
  {
    nome: "Rocca de' Baldi",
    codice: '004189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H395',
    cap: ['12047'],
    popolazione: 1698,
  },
  {
    nome: "Rocca de' Giorgi",
    codice: '018125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H396',
    cap: ['27040'],
    popolazione: 79,
  },
  {
    nome: 'Rocca di Botte',
    codice: '066080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H399',
    cap: ['67066'],
    popolazione: 830,
  },
  {
    nome: 'Rocca di Cambio',
    codice: '066081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H400',
    cap: ['67047'],
    popolazione: 504,
  },
  {
    nome: 'Rocca di Cave',
    codice: '058085',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H401',
    cap: ['00030'],
    popolazione: 396,
  },
  {
    nome: 'Rocca di Mezzo',
    codice: '066082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H402',
    cap: ['67048'],
    popolazione: 1468,
  },
  {
    nome: 'Rocca di Neto',
    codice: '101019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'H403',
    cap: ['88821'],
    popolazione: 5594,
  },
  {
    nome: 'Rocca di Papa',
    codice: '058086',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H404',
    cap: ['00040'],
    popolazione: 15576,
  },
  {
    nome: 'Rocca Grimalda',
    codice: '006147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H414',
    cap: ['15078'],
    popolazione: 1495,
  },
  {
    nome: 'Rocca Imperiale',
    codice: '078103',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H416',
    cap: ['87074'],
    popolazione: 3292,
  },
  {
    nome: 'Rocca Massima',
    codice: '059022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'H421',
    cap: ['04010'],
    popolazione: 1094,
  },
  {
    nome: 'Rocca Pia',
    codice: '066083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H429',
    cap: ['67030'],
    popolazione: 167,
  },
  {
    nome: 'Rocca Pietore',
    codice: '025044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'H379',
    cap: ['32023'],
    popolazione: 1322,
  },
  {
    nome: 'Rocca Priora',
    codice: '058088',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H432',
    cap: ['00079'],
    popolazione: 10819,
  },
  {
    nome: 'Rocca San Casciano',
    codice: '040036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'H437',
    cap: ['47017'],
    popolazione: 2000,
  },
  {
    nome: 'Rocca San Felice',
    codice: '064079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H438',
    cap: ['83050'],
    popolazione: 869,
  },
  {
    nome: 'Rocca San Giovanni',
    codice: '069074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H439',
    cap: ['66020'],
    popolazione: 2348,
  },
  {
    nome: 'Rocca Santa Maria',
    codice: '067036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'H440',
    cap: ['64010'],
    popolazione: 569,
  },
  {
    nome: 'Rocca Santo Stefano',
    codice: '058089',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H441',
    cap: ['00030'],
    popolazione: 1028,
  },
  {
    nome: 'Rocca Sinibalda',
    codice: '057062',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'H446',
    cap: ['02026'],
    popolazione: 853,
  },
  {
    nome: 'Rocca Susella',
    codice: '018126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H450',
    cap: ['27052'],
    popolazione: 234,
  },
  {
    nome: 'Roccabascerana',
    codice: '064078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H382',
    cap: ['83016'],
    popolazione: 2366,
  },
  {
    nome: 'Roccabernarda',
    codice: '101018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'H383',
    cap: ['88835'],
    popolazione: 3467,
  },
  {
    nome: 'Roccabianca',
    codice: '034030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'H384',
    cap: ['43010'],
    popolazione: 3069,
  },
  {
    nome: 'Roccabruna',
    codice: '004187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H385',
    cap: ['12020'],
    popolazione: 1589,
  },
  {
    nome: 'Roccacasale',
    codice: '066079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H389',
    cap: ['67030'],
    popolazione: 724,
  },
  {
    nome: 'Roccadaspide',
    codice: '065106',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H394',
    cap: ['84069'],
    popolazione: 7354,
  },
  {
    nome: 'Roccafiorita',
    codice: '083071',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H405',
    cap: ['98030'],
    popolazione: 228,
  },
  {
    nome: 'Roccafluvione',
    codice: '044064',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'H390',
    cap: ['63093'],
    popolazione: 2061,
  },
  {
    nome: 'Roccaforte del Greco',
    codice: '080066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H408',
    cap: ['89060'],
    popolazione: 550,
  },
  {
    nome: 'Roccaforte Ligure',
    codice: '006146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H406',
    cap: ['15060'],
    popolazione: 154,
  },
  {
    nome: 'Roccaforte Mondovì',
    codice: '004190',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H407',
    cap: ['12088'],
    popolazione: 2148,
  },
  {
    nome: 'Roccaforzata',
    codice: '073023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'H409',
    cap: ['74020'],
    popolazione: 1823,
  },
  {
    nome: 'Roccafranca',
    codice: '017162',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H410',
    cap: ['25030'],
    popolazione: 4767,
  },
  {
    nome: 'Roccagiovine',
    codice: '058087',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H411',
    cap: ['00020'],
    popolazione: 280,
  },
  {
    nome: 'Roccagloriosa',
    codice: '065107',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H412',
    cap: ['84060'],
    popolazione: 1716,
  },
  {
    nome: 'Roccagorga',
    codice: '059021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'H413',
    cap: ['04010'],
    popolazione: 4552,
  },
  {
    nome: 'Roccalbegna',
    codice: '053020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'H417',
    cap: ['58053'],
    popolazione: 1099,
  },
  {
    nome: 'Roccalumera',
    codice: '083072',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H418',
    cap: ['98027'],
    popolazione: 4105,
  },
  {
    nome: 'Roccamandolfi',
    codice: '094040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'H420',
    cap: ['86092'],
    popolazione: 987,
  },
  {
    nome: 'Roccamena',
    codice: '082061',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'H422',
    cap: ['90040'],
    popolazione: 1562,
  },
  {
    nome: 'Roccamonfina',
    codice: '061070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H423',
    cap: ['81035'],
    popolazione: 3626,
  },
  {
    nome: 'Roccamontepiano',
    codice: '069073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H424',
    cap: ['66010'],
    popolazione: 1792,
  },
  {
    nome: 'Roccamorice',
    codice: '068034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'H425',
    cap: ['65020'],
    popolazione: 989,
  },
  {
    nome: 'Roccanova',
    codice: '076069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H426',
    cap: ['85036'],
    popolazione: 1644,
  },
  {
    nome: 'Roccantica',
    codice: '057061',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'H427',
    cap: ['02040'],
    popolazione: 605,
  },
  {
    nome: 'Roccapalumba',
    codice: '082062',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'H428',
    cap: ['90020'],
    popolazione: 2634,
  },
  {
    nome: 'Roccapiemonte',
    codice: '065108',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H431',
    cap: ['84086'],
    popolazione: 9124,
  },
  {
    nome: 'Roccarainola',
    codice: '063065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H433',
    cap: ['80030'],
    popolazione: 7164,
  },
  {
    nome: 'Roccaraso',
    codice: '066084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H434',
    cap: ['67037'],
    popolazione: 1636,
  },
  {
    nome: 'Roccaromana',
    codice: '061071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H436',
    cap: ['81051'],
    popolazione: 878,
  },
  {
    nome: 'Roccascalegna',
    codice: '069075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H442',
    cap: ['66040'],
    popolazione: 1285,
  },
  {
    nome: 'Roccasecca',
    codice: '060060',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H443',
    cap: ['03038'],
    popolazione: 7536,
  },
  {
    nome: 'Roccasecca dei Volsci',
    codice: '059023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'H444',
    cap: ['04010'],
    popolazione: 1126,
  },
  {
    nome: 'Roccasicura',
    codice: '094041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'H445',
    cap: ['86080'],
    popolazione: 559,
  },
  {
    nome: 'Roccasparvera',
    codice: '004191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H447',
    cap: ['12010'],
    popolazione: 737,
  },
  {
    nome: 'Roccaspinalveti',
    codice: '069076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H448',
    cap: ['66050'],
    popolazione: 1434,
  },
  {
    nome: 'Roccastrada',
    codice: '053021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'H449',
    cap: ['58036'],
    popolazione: 9378,
  },
  {
    nome: 'Roccavaldina',
    codice: '083073',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H380',
    cap: ['98040'],
    popolazione: 1149,
  },
  {
    nome: 'Roccaverano',
    codice: '005094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H451',
    cap: ['14050'],
    popolazione: 447,
  },
  {
    nome: 'Roccavignale',
    codice: '009054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'H452',
    cap: ['17017'],
    popolazione: 746,
  },
  {
    nome: 'Roccavione',
    codice: '004192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H453',
    cap: ['12018'],
    popolazione: 2876,
  },
  {
    nome: 'Roccavivara',
    codice: '070060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H454',
    cap: ['86020'],
    popolazione: 840,
  },
  {
    nome: 'Roccella Ionica',
    codice: '080067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H456',
    cap: ['89047'],
    popolazione: 6434,
  },
  {
    nome: 'Roccella Valdemone',
    codice: '083074',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H455',
    cap: ['98030'],
    popolazione: 711,
  },
  {
    nome: 'Rocchetta a Volturno',
    codice: '094042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'H458',
    cap: ['86070'],
    popolazione: 1064,
  },
  {
    nome: 'Rocchetta Belbo',
    codice: '004193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H462',
    cap: ['12050'],
    popolazione: 181,
  },
  {
    nome: 'Rocchetta di Vara',
    codice: '011025',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'H461',
    cap: ['19020'],
    popolazione: 785,
  },
  {
    nome: 'Rocchetta e Croce',
    codice: '061072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H459',
    cap: ['81042'],
    popolazione: 463,
  },
  {
    nome: 'Rocchetta Ligure',
    codice: '006148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H465',
    cap: ['15060'],
    popolazione: 210,
  },
  {
    nome: 'Rocchetta Nervina',
    codice: '008051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H460',
    cap: ['18030'],
    popolazione: 272,
  },
  {
    nome: 'Rocchetta Palafea',
    codice: '005095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H466',
    cap: ['14042'],
    popolazione: 347,
  },
  {
    nome: "Rocchetta Sant'Antonio",
    codice: '071042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H467',
    cap: ['71020'],
    popolazione: 1954,
  },
  {
    nome: 'Rocchetta Tanaro',
    codice: '005096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H468',
    cap: ['14030'],
    popolazione: 1437,
  },
  {
    nome: 'Rodano',
    codice: '015185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H470',
    cap: ['20090'],
    popolazione: 4526,
  },
  {
    nome: 'Roddi',
    codice: '004194',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H472',
    cap: ['12060'],
    popolazione: 1546,
  },
  {
    nome: 'Roddino',
    codice: '004195',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H473',
    cap: ['12050'],
    popolazione: 377,
  },
  {
    nome: 'Rodello',
    codice: '004196',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H474',
    cap: ['12050'],
    popolazione: 1004,
  },
  {
    nome: 'Rodengo',
    codice: '021075',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H475',
    cap: ['39037'],
    popolazione: 1195,
  },
  {
    nome: 'Rodengo Saiano',
    codice: '017163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H477',
    cap: ['25050'],
    popolazione: 8795,
  },
  {
    nome: 'Rodero',
    codice: '013197',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H478',
    cap: ['22070'],
    popolazione: 1210,
  },
  {
    nome: 'Rodi Garganico',
    codice: '071043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H480',
    cap: ['71012'],
    popolazione: 3663,
  },
  {
    nome: 'Rodì Milici',
    codice: '083075',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H479',
    cap: ['98059'],
    popolazione: 2130,
  },
  {
    nome: 'Rodigo',
    codice: '020051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H481',
    cap: ['46040'],
    popolazione: 5360,
  },
  {
    nome: 'Roè Volciano',
    codice: '017164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H484',
    cap: ['25077'],
    popolazione: 4465,
  },
  {
    nome: 'Rofrano',
    codice: '065109',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H485',
    cap: ['84070'],
    popolazione: 1655,
  },
  {
    nome: 'Rogeno',
    codice: '097072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'H486',
    cap: ['23849'],
    popolazione: 3197,
  },
  {
    nome: 'Roggiano Gravina',
    codice: '078104',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H488',
    cap: ['87017'],
    popolazione: 7228,
  },
  {
    nome: 'Roghudi',
    codice: '080068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H489',
    cap: ['89060'],
    popolazione: 1172,
  },
  {
    nome: 'Rogliano',
    codice: '078105',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H490',
    cap: ['87054'],
    popolazione: 5697,
  },
  {
    nome: 'Rognano',
    codice: '018127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H491',
    cap: ['27010'],
    popolazione: 619,
  },
  {
    nome: 'Rogno',
    codice: '016182',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H492',
    cap: ['24060'],
    popolazione: 3888,
  },
  {
    nome: 'Rogolo',
    codice: '014056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'H493',
    cap: ['23010'],
    popolazione: 564,
  },
  {
    nome: 'Roiate',
    codice: '058090',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H494',
    cap: ['00030'],
    popolazione: 749,
  },
  {
    nome: 'Roio del Sangro',
    codice: '069077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H495',
    cap: ['66040'],
    popolazione: 103,
  },
  {
    nome: 'Roisan',
    codice: '007057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H497',
    cap: ['11010'],
    popolazione: 1042,
  },
  {
    nome: 'Roletto',
    codice: '001222',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H498',
    cap: ['10060'],
    popolazione: 1989,
  },
  {
    nome: 'Rolo',
    codice: '035035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'H500',
    cap: ['42047'],
    popolazione: 4038,
  },
  {
    nome: 'Roma',
    codice: '058091',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H501',
    cap: [
      '00118',
      '00119',
      '00120',
      '00121',
      '00122',
      '00123',
      '00124',
      '00125',
      '00126',
      '00127',
      '00128',
      '00129',
      '00130',
      '00131',
      '00132',
      '00133',
      '00134',
      '00135',
      '00136',
      '00137',
      '00138',
      '00139',
      '00140',
      '00141',
      '00142',
      '00143',
      '00144',
      '00145',
      '00146',
      '00147',
      '00148',
      '00149',
      '00150',
      '00151',
      '00152',
      '00153',
      '00154',
      '00155',
      '00156',
      '00157',
      '00158',
      '00159',
      '00160',
      '00161',
      '00162',
      '00163',
      '00164',
      '00165',
      '00166',
      '00167',
      '00168',
      '00169',
      '00170',
      '00171',
      '00172',
      '00173',
      '00174',
      '00175',
      '00176',
      '00177',
      '00178',
      '00179',
      '00180',
      '00181',
      '00182',
      '00183',
      '00184',
      '00185',
      '00186',
      '00187',
      '00188',
      '00189',
      '00190',
      '00191',
      '00192',
      '00193',
      '00194',
      '00195',
      '00196',
      '00197',
      '00198',
      '00199',
    ],
    popolazione: 2617175,
  },
  {
    nome: 'Romagnano al Monte',
    codice: '065110',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H503',
    cap: ['84020'],
    popolazione: 391,
  },
  {
    nome: 'Romagnano Sesia',
    codice: '003130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'H502',
    cap: ['28078'],
    popolazione: 4049,
  },
  {
    nome: 'Romagnese',
    codice: '018128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H505',
    cap: ['27050'],
    popolazione: 744,
  },
  {
    nome: 'Romana',
    codice: '090061',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'H507',
    cap: ['07010'],
    popolazione: 578,
  },
  {
    nome: 'Romanengo',
    codice: '019086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H508',
    cap: ['26014'],
    popolazione: 3021,
  },
  {
    nome: 'Romano Canavese',
    codice: '001223',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H511',
    cap: ['10090'],
    popolazione: 2937,
  },
  {
    nome: "Romano d'Ezzelino",
    codice: '024086',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H512',
    cap: ['36060'],
    popolazione: 14484,
  },
  {
    nome: 'Romano di Lombardia',
    codice: '016183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H509',
    cap: ['24058'],
    popolazione: 18784,
  },
  {
    nome: "Romans d'Isonzo",
    codice: '031015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'H514',
    cap: ['34076'],
    popolazione: 3702,
  },
  {
    nome: 'Rombiolo',
    codice: '102031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'H516',
    cap: ['89841'],
    popolazione: 4649,
  },
  {
    nome: 'Romeno',
    codice: '022155',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H517',
    cap: ['38010'],
    popolazione: 1374,
  },
  {
    nome: 'Romentino',
    codice: '003131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'H518',
    cap: ['28068'],
    popolazione: 5379,
  },
  {
    nome: 'Rometta',
    codice: '083076',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H519',
    cap: ['98043'],
    popolazione: 6541,
  },
  {
    nome: 'Ronago',
    codice: '013199',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H521',
    cap: ['22027'],
    popolazione: 1754,
  },
  {
    nome: 'Roncà',
    codice: '023063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H522',
    cap: ['37030'],
    popolazione: 3726,
  },
  {
    nome: 'Roncade',
    codice: '026069',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H523',
    cap: ['31056'],
    popolazione: 14037,
  },
  {
    nome: 'Roncadelle',
    codice: '017165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H525',
    cap: ['25030'],
    popolazione: 9265,
  },
  {
    nome: 'Roncaro',
    codice: '018129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H527',
    cap: ['27010'],
    popolazione: 1385,
  },
  {
    nome: 'Roncegno Terme',
    codice: '022156',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H528',
    cap: ['38050'],
    popolazione: 2814,
  },
  {
    nome: 'Roncello',
    codice: '108055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'H529',
    cap: ['20877'],
    popolazione: 3925,
  },
  {
    nome: 'Ronchi dei Legionari',
    codice: '031016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'H531',
    cap: ['34077'],
    popolazione: 11960,
  },
  {
    nome: 'Ronchi Valsugana',
    codice: '022157',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H532',
    cap: ['38050'],
    popolazione: 421,
  },
  {
    nome: 'Ronchis',
    codice: '030097',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H533',
    cap: ['33050'],
    popolazione: 2054,
  },
  {
    nome: 'Ronciglione',
    codice: '056045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'H534',
    cap: ['01037'],
    popolazione: 8308,
  },
  {
    nome: "Ronco all'Adige",
    codice: '023064',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H540',
    cap: ['37055'],
    popolazione: 6179,
  },
  {
    nome: 'Ronco Biellese',
    codice: '096053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H538',
    cap: ['13845'],
    popolazione: 1514,
  },
  {
    nome: 'Ronco Briantino',
    codice: '108038',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'H537',
    cap: ['20885'],
    popolazione: 3389,
  },
  {
    nome: 'Ronco Canavese',
    codice: '001224',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H539',
    cap: ['10080'],
    popolazione: 313,
  },
  {
    nome: 'Ronco Scrivia',
    codice: '010049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H536',
    cap: ['16019'],
    popolazione: 4558,
  },
  {
    nome: 'Roncobello',
    codice: '016184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H535',
    cap: ['24010'],
    popolazione: 429,
  },
  {
    nome: 'Roncoferraro',
    codice: '020052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H541',
    cap: ['46037'],
    popolazione: 7201,
  },
  {
    nome: 'Roncofreddo',
    codice: '040037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'H542',
    cap: ['47020'],
    popolazione: 3395,
  },
  {
    nome: 'Roncola',
    codice: '016185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H544',
    cap: ['24030'],
    popolazione: 739,
  },
  {
    nome: 'Rondanina',
    codice: '010050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H546',
    cap: ['16025'],
    popolazione: 69,
  },
  {
    nome: 'Rondissone',
    codice: '001225',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H547',
    cap: ['10030'],
    popolazione: 1834,
  },
  {
    nome: 'Ronsecco',
    codice: '002118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H549',
    cap: ['13036'],
    popolazione: 580,
  },
  {
    nome: 'Ronzo-Chienis',
    codice: '022135',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M303',
    cap: ['38060'],
    popolazione: 1001,
  },
  {
    nome: 'Ronzone',
    codice: '022159',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H552',
    cap: ['38010'],
    popolazione: 391,
  },
  {
    nome: 'Roppolo',
    codice: '096054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H553',
    cap: ['13883'],
    popolazione: 921,
  },
  {
    nome: 'Rorà',
    codice: '001226',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H554',
    cap: ['10060'],
    popolazione: 255,
  },
  {
    nome: 'Rosà',
    codice: '024087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H556',
    cap: ['36027'],
    popolazione: 14071,
  },
  {
    nome: 'Rosarno',
    codice: '080069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H558',
    cap: ['89025'],
    popolazione: 14380,
  },
  {
    nome: 'Rosasco',
    codice: '018130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H559',
    cap: ['27030'],
    popolazione: 638,
  },
  {
    nome: 'Rosate',
    codice: '015188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H560',
    cap: ['20088'],
    popolazione: 5395,
  },
  {
    nome: 'Rosazza',
    codice: '096055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H561',
    cap: ['13815'],
    popolazione: 87,
  },
  {
    nome: 'Rosciano',
    codice: '068035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'H562',
    cap: ['65020'],
    popolazione: 3663,
  },
  {
    nome: 'Roscigno',
    codice: '065111',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H564',
    cap: ['84020'],
    popolazione: 827,
  },
  {
    nome: 'Rose',
    codice: '078106',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H565',
    cap: ['87040'],
    popolazione: 4316,
  },
  {
    nome: 'Rosello',
    codice: '069078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H566',
    cap: ['66040'],
    popolazione: 253,
  },
  {
    nome: 'Roseto Capo Spulico',
    codice: '078107',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H572',
    cap: ['87070'],
    popolazione: 1873,
  },
  {
    nome: 'Roseto degli Abruzzi',
    codice: '067037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'F585',
    cap: ['64026'],
    popolazione: 24940,
  },
  {
    nome: 'Roseto Valfortore',
    codice: '071044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H568',
    cap: ['71039'],
    popolazione: 1149,
  },
  {
    nome: 'Rosignano Marittimo',
    codice: '049017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'H570',
    cap: ['57016'],
    popolazione: 31752,
  },
  {
    nome: 'Rosignano Monferrato',
    codice: '006149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H569',
    cap: ['15030'],
    popolazione: 1641,
  },
  {
    nome: 'Rosolina',
    codice: '029040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'H573',
    cap: ['45010'],
    popolazione: 6481,
  },
  {
    nome: 'Rosolini',
    codice: '089016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'H574',
    cap: ['96019'],
    popolazione: 21526,
  },
  {
    nome: 'Rosora',
    codice: '042040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'H575',
    cap: ['60030'],
    popolazione: 1988,
  },
  {
    nome: 'Rossa',
    codice: '002121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H577',
    cap: ['13020'],
    popolazione: 194,
  },
  {
    nome: 'Rossana',
    codice: '004197',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H578',
    cap: ['12020'],
    popolazione: 909,
  },
  {
    nome: 'Rossano Veneto',
    codice: '024088',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H580',
    cap: ['36028'],
    popolazione: 7922,
  },
  {
    nome: 'Rossiglione',
    codice: '010051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H581',
    cap: ['16010'],
    popolazione: 2932,
  },
  {
    nome: 'Rosta',
    codice: '001228',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H583',
    cap: ['10090'],
    popolazione: 4621,
  },
  {
    nome: "Rota d'Imagna",
    codice: '016186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H584',
    cap: ['24037'],
    popolazione: 926,
  },
  {
    nome: 'Rota Greca',
    codice: '078109',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H585',
    cap: ['87010'],
    popolazione: 1178,
  },
  {
    nome: 'Rotella',
    codice: '044065',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'H588',
    cap: ['63071'],
    popolazione: 936,
  },
  {
    nome: 'Rotello',
    codice: '070061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H589',
    cap: ['86040'],
    popolazione: 1219,
  },
  {
    nome: 'Rotonda',
    codice: '076070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H590',
    cap: ['85048'],
    popolazione: 3519,
  },
  {
    nome: 'Rotondella',
    codice: '077023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'H591',
    cap: ['75026'],
    popolazione: 2707,
  },
  {
    nome: 'Rotondi',
    codice: '064080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H592',
    cap: ['83017'],
    popolazione: 3580,
  },
  {
    nome: 'Rottofreno',
    codice: '033039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'H593',
    cap: ['29010'],
    popolazione: 11641,
  },
  {
    nome: 'Rotzo',
    codice: '024089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H594',
    cap: ['36010'],
    popolazione: 638,
  },
  {
    nome: 'Roure',
    codice: '001227',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H555',
    cap: ['10060'],
    popolazione: 888,
  },
  {
    nome: 'Rovasenda',
    codice: '002122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H364',
    cap: ['13040'],
    popolazione: 979,
  },
  {
    nome: 'Rovato',
    codice: '017166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H598',
    cap: ['25038'],
    popolazione: 17562,
  },
  {
    nome: 'Rovegno',
    codice: '010052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H599',
    cap: ['16028'],
    popolazione: 568,
  },
  {
    nome: 'Rovellasca',
    codice: '013201',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H601',
    cap: ['22069'],
    popolazione: 7565,
  },
  {
    nome: 'Rovello Porro',
    codice: '013202',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H602',
    cap: ['22070'],
    popolazione: 6035,
  },
  {
    nome: 'Roverbella',
    codice: '020053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H604',
    cap: ['46048'],
    popolazione: 8500,
  },
  {
    nome: 'Roverchiara',
    codice: '023065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H606',
    cap: ['37050'],
    popolazione: 2740,
  },
  {
    nome: 'Roverè della Luna',
    codice: '022160',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H607',
    cap: ['38030'],
    popolazione: 1573,
  },
  {
    nome: 'Roverè Veronese',
    codice: '023067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H608',
    cap: ['37028'],
    popolazione: 2127,
  },
  {
    nome: 'Roveredo di Guà',
    codice: '023066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H610',
    cap: ['37040'],
    popolazione: 1541,
  },
  {
    nome: 'Roveredo in Piano',
    codice: '093036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'H609',
    cap: ['33080'],
    popolazione: 5779,
  },
  {
    nome: 'Rovereto',
    codice: '022161',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H612',
    cap: ['38068'],
    popolazione: 37754,
  },
  {
    nome: 'Rovescala',
    codice: '018131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H614',
    cap: ['27040'],
    popolazione: 917,
  },
  {
    nome: 'Rovetta',
    codice: '016187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H615',
    cap: ['24020'],
    popolazione: 3953,
  },
  {
    nome: 'Roviano',
    codice: '058092',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H618',
    cap: ['00027'],
    popolazione: 1392,
  },
  {
    nome: 'Rovigo',
    codice: '029041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'H620',
    cap: ['45100'],
    popolazione: 50164,
  },
  {
    nome: 'Rovito',
    codice: '078110',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H621',
    cap: ['87050'],
    popolazione: 3078,
  },
  {
    nome: 'Rovolon',
    codice: '028071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'H622',
    cap: ['35030'],
    popolazione: 4777,
  },
  {
    nome: 'Rozzano',
    codice: '015189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H623',
    cap: ['20089'],
    popolazione: 39983,
  },
  {
    nome: 'Rubano',
    codice: '028072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'H625',
    cap: ['35030'],
    popolazione: 15669,
  },
  {
    nome: 'Rubiana',
    codice: '001229',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H627',
    cap: ['10040'],
    popolazione: 2417,
  },
  {
    nome: 'Rubiera',
    codice: '035036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'H628',
    cap: ['42048'],
    popolazione: 14421,
  },
  {
    nome: 'Ruda',
    codice: '030098',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H629',
    cap: ['33050'],
    popolazione: 2995,
  },
  {
    nome: 'Rudiano',
    codice: '017167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H630',
    cap: ['25030'],
    popolazione: 5699,
  },
  {
    nome: 'Rueglio',
    codice: '001230',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H631',
    cap: ['10010'],
    popolazione: 773,
  },
  {
    nome: 'Ruffano',
    codice: '075064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H632',
    cap: ['73049'],
    popolazione: 9854,
  },
  {
    nome: 'Ruffia',
    codice: '004198',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H633',
    cap: ['12030'],
    popolazione: 350,
  },
  {
    nome: 'Ruffrè-Mendola',
    codice: '022162',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H634',
    cap: ['38010'],
    popolazione: 416,
  },
  {
    nome: 'Rufina',
    codice: '048037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'H635',
    cap: ['50068'],
    popolazione: 7382,
  },
  {
    nome: 'Ruinas',
    codice: '095044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'F271',
    cap: ['09085'],
    popolazione: 728,
  },
  {
    nome: 'Rumo',
    codice: '022163',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H639',
    cap: ['38020'],
    popolazione: 822,
  },
  {
    nome: 'Ruoti',
    codice: '076071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H641',
    cap: ['85056'],
    popolazione: 3542,
  },
  {
    nome: 'Russi',
    codice: '039016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'H642',
    cap: ['48026'],
    popolazione: 12083,
  },
  {
    nome: 'Rutigliano',
    codice: '072037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'H643',
    cap: ['70018'],
    popolazione: 18418,
  },
  {
    nome: 'Rutino',
    codice: '065112',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H644',
    cap: ['84070'],
    popolazione: 889,
  },
  {
    nome: 'Ruviano',
    codice: '061073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H165',
    cap: ['81010'],
    popolazione: 1822,
  },
  {
    nome: 'Ruvo del Monte',
    codice: '076072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H646',
    cap: ['85020'],
    popolazione: 1099,
  },
  {
    nome: 'Ruvo di Puglia',
    codice: '072038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'H645',
    cap: ['70037'],
    popolazione: 25662,
  },
  {
    nome: 'Sabaudia',
    codice: '059024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'H647',
    cap: ['04016'],
    popolazione: 18812,
  },
  {
    nome: 'Sabbio Chiese',
    codice: '017168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H650',
    cap: ['25070'],
    popolazione: 3831,
  },
  {
    nome: 'Sabbioneta',
    codice: '020054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H652',
    cap: ['46018'],
    popolazione: 4313,
  },
  {
    nome: 'Sacco',
    codice: '065113',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H654',
    cap: ['84070'],
    popolazione: 559,
  },
  {
    nome: 'Saccolongo',
    codice: '028073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'H655',
    cap: ['35030'],
    popolazione: 4934,
  },
  {
    nome: 'Sacile',
    codice: '093037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'H657',
    cap: ['33077'],
    popolazione: 19897,
  },
  {
    nome: 'Sacrofano',
    codice: '058093',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H658',
    cap: ['00060'],
    popolazione: 6668,
  },
  {
    nome: 'Sadali',
    codice: '111058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'H659',
    cap: ['09062'],
    popolazione: 918,
  },
  {
    nome: 'Sagama',
    codice: '095086',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'H661',
    cap: ['09090'],
    popolazione: 193,
  },
  {
    nome: 'Sagliano Micca',
    codice: '096056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H662',
    cap: ['13816'],
    popolazione: 1654,
  },
  {
    nome: 'Sagrado',
    codice: '031017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'H665',
    cap: ['34078'],
    popolazione: 2236,
  },
  {
    nome: 'Sagron Mis',
    codice: '022164',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H666',
    cap: ['38050'],
    popolazione: 183,
  },
  {
    nome: 'Saint-Christophe',
    codice: '007058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H669',
    cap: ['11020'],
    popolazione: 3356,
  },
  {
    nome: 'Saint-Denis',
    codice: '007059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H670',
    cap: ['11023'],
    popolazione: 382,
  },
  {
    nome: 'Saint-Marcel',
    codice: '007060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H671',
    cap: ['11020'],
    popolazione: 1275,
  },
  {
    nome: 'Saint-Nicolas',
    codice: '007061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H672',
    cap: ['11010'],
    popolazione: 315,
  },
  {
    nome: 'Saint-Oyen',
    codice: '007062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H673',
    cap: ['11014'],
    popolazione: 217,
  },
  {
    nome: 'Saint-Pierre',
    codice: '007063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H674',
    cap: ['11010'],
    popolazione: 3112,
  },
  {
    nome: 'Saint-Rhémy-en-Bosses',
    codice: '007064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H675',
    cap: ['11010'],
    popolazione: 367,
  },
  {
    nome: 'Saint-Vincent',
    codice: '007065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'H676',
    cap: ['11027'],
    popolazione: 4654,
  },
  {
    nome: 'Sala Baganza',
    codice: '034031',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'H682',
    cap: ['43038'],
    popolazione: 5392,
  },
  {
    nome: 'Sala Biellese',
    codice: '096057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H681',
    cap: ['13884'],
    popolazione: 627,
  },
  {
    nome: 'Sala Bolognese',
    codice: '037050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'H678',
    cap: ['40010'],
    popolazione: 8245,
  },
  {
    nome: 'Sala Comacina',
    codice: '013203',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H679',
    cap: ['22010'],
    popolazione: 614,
  },
  {
    nome: 'Sala Consilina',
    codice: '065114',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H683',
    cap: ['84036'],
    popolazione: 12258,
  },
  {
    nome: 'Sala Monferrato',
    codice: '006150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H677',
    cap: ['15030'],
    popolazione: 377,
  },
  {
    nome: 'Salandra',
    codice: '077024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'H687',
    cap: ['75017'],
    popolazione: 2934,
  },
  {
    nome: 'Salaparuta',
    codice: '081017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'H688',
    cap: ['91020'],
    popolazione: 1721,
  },
  {
    nome: 'Salara',
    codice: '029042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'H689',
    cap: ['45030'],
    popolazione: 1214,
  },
  {
    nome: 'Salasco',
    codice: '002126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H690',
    cap: ['13040'],
    popolazione: 237,
  },
  {
    nome: 'Salassa',
    codice: '001231',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H691',
    cap: ['10080'],
    popolazione: 1795,
  },
  {
    nome: 'Salbertrand',
    codice: '001232',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H684',
    cap: ['10050'],
    popolazione: 579,
  },
  {
    nome: 'Salcedo',
    codice: '024090',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'F810',
    cap: ['36040'],
    popolazione: 1038,
  },
  {
    nome: 'Salcito',
    codice: '070062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H693',
    cap: ['86026'],
    popolazione: 695,
  },
  {
    nome: 'Sale',
    codice: '006151',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H694',
    cap: ['15045'],
    popolazione: 4218,
  },
  {
    nome: 'Sale delle Langhe',
    codice: '004199',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H695',
    cap: ['12070'],
    popolazione: 525,
  },
  {
    nome: 'Sale Marasino',
    codice: '017169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H699',
    cap: ['25057'],
    popolazione: 3370,
  },
  {
    nome: 'Sale San Giovanni',
    codice: '004200',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H704',
    cap: ['12070'],
    popolazione: 178,
  },
  {
    nome: 'Salemi',
    codice: '081018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'H700',
    cap: ['91018'],
    popolazione: 10871,
  },
  {
    nome: 'Salento',
    codice: '065115',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H686',
    cap: ['84070'],
    popolazione: 2005,
  },
  {
    nome: 'Salerano Canavese',
    codice: '001233',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H702',
    cap: ['10010'],
    popolazione: 522,
  },
  {
    nome: 'Salerano sul Lambro',
    codice: '098046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'H701',
    cap: ['26857'],
    popolazione: 2653,
  },
  {
    nome: 'Salerno',
    codice: '065116',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H703',
    cap: [
      '84121',
      '84122',
      '84123',
      '84124',
      '84125',
      '84126',
      '84127',
      '84128',
      '84129',
      '84130',
      '84131',
      '84132',
      '84133',
      '84134',
      '84135',
    ],
    popolazione: 132608,
  },
  {
    nome: 'Salgareda',
    codice: '026070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H706',
    cap: ['31040'],
    popolazione: 6599,
  },
  {
    nome: 'Sali Vercellese',
    codice: '002127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H707',
    cap: ['13040'],
    popolazione: 114,
  },
  {
    nome: 'Salice Salentino',
    codice: '075065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H708',
    cap: ['73015'],
    popolazione: 8642,
  },
  {
    nome: 'Saliceto',
    codice: '004201',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H710',
    cap: ['12079'],
    popolazione: 1382,
  },
  {
    nome: 'Salisano',
    codice: '057063',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'H713',
    cap: ['02040'],
    popolazione: 558,
  },
  {
    nome: 'Salizzole',
    codice: '023068',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H714',
    cap: ['37056'],
    popolazione: 3745,
  },
  {
    nome: 'Salle',
    codice: '068036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'H715',
    cap: ['65020'],
    popolazione: 317,
  },
  {
    nome: 'Salmour',
    codice: '004202',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H716',
    cap: ['12040'],
    popolazione: 706,
  },
  {
    nome: 'Salò',
    codice: '017170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H717',
    cap: ['25087'],
    popolazione: 10350,
  },
  {
    nome: 'Salorno',
    codice: '021076',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H719',
    cap: ['39040'],
    popolazione: 3533,
  },
  {
    nome: 'Salsomaggiore Terme',
    codice: '034032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'H720',
    cap: ['43039'],
    popolazione: 19505,
  },
  {
    nome: 'Saltrio',
    codice: '012117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'H723',
    cap: ['21050'],
    popolazione: 3013,
  },
  {
    nome: 'Saludecio',
    codice: '099015',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'H724',
    cap: ['47835'],
    popolazione: 3028,
  },
  {
    nome: 'Saluggia',
    codice: '002128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H725',
    cap: ['13040'],
    popolazione: 4170,
  },
  {
    nome: 'Salussola',
    codice: '096058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H726',
    cap: ['13885'],
    popolazione: 2015,
  },
  {
    nome: 'Saluzzo',
    codice: '004203',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H727',
    cap: ['12037'],
    popolazione: 17224,
  },
  {
    nome: 'Salve',
    codice: '075066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H729',
    cap: ['73050'],
    popolazione: 4737,
  },
  {
    nome: 'Salvirola',
    codice: '019087',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H731',
    cap: ['26010'],
    popolazione: 1169,
  },
  {
    nome: 'Salvitelle',
    codice: '065117',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H732',
    cap: ['84020'],
    popolazione: 582,
  },
  {
    nome: 'Salza di Pinerolo',
    codice: '001234',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H734',
    cap: ['10060'],
    popolazione: 78,
  },
  {
    nome: 'Salza Irpina',
    codice: '064081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H733',
    cap: ['83050'],
    popolazione: 767,
  },
  {
    nome: 'Salzano',
    codice: '027032',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'H735',
    cap: ['30030'],
    popolazione: 12678,
  },
  {
    nome: 'Samarate',
    codice: '012118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'H736',
    cap: ['21017'],
    popolazione: 16168,
  },
  {
    nome: 'Samassi',
    codice: '111059',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'H738',
    cap: ['09030'],
    popolazione: 5321,
  },
  {
    nome: 'Samatzai',
    codice: '111060',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'H739',
    cap: ['09020'],
    popolazione: 1720,
  },
  {
    nome: 'Sambuca di Sicilia',
    codice: '084034',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H743',
    cap: ['92017'],
    popolazione: 6114,
  },
  {
    nome: 'Sambuca Pistoiese',
    codice: '047018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'H744',
    cap: ['51020'],
    popolazione: 1680,
  },
  {
    nome: 'Sambuci',
    codice: '058094',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H745',
    cap: ['00020'],
    popolazione: 936,
  },
  {
    nome: 'Sambuco',
    codice: '004204',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H746',
    cap: ['12010'],
    popolazione: 100,
  },
  {
    nome: 'Sammichele di Bari',
    codice: '072039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'H749',
    cap: ['70010'],
    popolazione: 6715,
  },
  {
    nome: 'Samo',
    codice: '080070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H013',
    cap: ['89030'],
    popolazione: 871,
  },
  {
    nome: 'Samolaco',
    codice: '014057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'H752',
    cap: ['23027'],
    popolazione: 2884,
  },
  {
    nome: 'Samone',
    codice: '001235',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H753',
    cap: ['10010'],
    popolazione: 1614,
  },
  {
    nome: 'Samone',
    codice: '022165',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'H754',
    cap: ['38059'],
    popolazione: 544,
  },
  {
    nome: 'Sampeyre',
    codice: '004205',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H755',
    cap: ['12020'],
    popolazione: 1069,
  },
  {
    nome: 'Samugheo',
    codice: '095045',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'H756',
    cap: ['09086'],
    popolazione: 3183,
  },
  {
    nome: 'San Bartolomeo al Mare',
    codice: '008052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H763',
    cap: ['18016'],
    popolazione: 3127,
  },
  {
    nome: 'San Bartolomeo in Galdo',
    codice: '062057',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H764',
    cap: ['82028'],
    popolazione: 5090,
  },
  {
    nome: 'San Bartolomeo Val Cavargna',
    codice: '013204',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H760',
    cap: ['22010'],
    popolazione: 1041,
  },
  {
    nome: 'San Basile',
    codice: '078111',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H765',
    cap: ['87010'],
    popolazione: 1065,
  },
  {
    nome: 'San Basilio',
    codice: '111061',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'H766',
    cap: ['09040'],
    popolazione: 1281,
  },
  {
    nome: 'San Bassano',
    codice: '019088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H767',
    cap: ['26020'],
    popolazione: 2207,
  },
  {
    nome: 'San Bellino',
    codice: '029043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'H768',
    cap: ['45020'],
    popolazione: 1156,
  },
  {
    nome: 'San Benedetto Belbo',
    codice: '004206',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H770',
    cap: ['12050'],
    popolazione: 191,
  },
  {
    nome: 'San Benedetto dei Marsi',
    codice: '066085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H772',
    cap: ['67058'],
    popolazione: 3910,
  },
  {
    nome: 'San Benedetto del Tronto',
    codice: '044066',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'H769',
    cap: ['63074'],
    popolazione: 46963,
  },
  {
    nome: 'San Benedetto in Perillis',
    codice: '066086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H773',
    cap: ['67020'],
    popolazione: 127,
  },
  {
    nome: 'San Benedetto Po',
    codice: '020055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H771',
    cap: ['46027'],
    popolazione: 7724,
  },
  {
    nome: 'San Benedetto Ullano',
    codice: '078112',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H774',
    cap: ['87040'],
    popolazione: 1598,
  },
  {
    nome: 'San Benedetto Val di Sambro',
    codice: '037051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'G566',
    cap: ['40048'],
    popolazione: 4393,
  },
  {
    nome: 'San Benigno Canavese',
    codice: '001236',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H775',
    cap: ['10080'],
    popolazione: 5615,
  },
  {
    nome: 'San Bernardino Verbano',
    codice: '103061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'H777',
    cap: ['28804'],
    popolazione: 1384,
  },
  {
    nome: 'San Biagio della Cima',
    codice: '008053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H780',
    cap: ['18036'],
    popolazione: 1278,
  },
  {
    nome: 'San Biagio di Callalta',
    codice: '026071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H781',
    cap: ['31048'],
    popolazione: 13039,
  },
  {
    nome: 'San Biagio Platani',
    codice: '084035',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H778',
    cap: ['92020'],
    popolazione: 3501,
  },
  {
    nome: 'San Biagio Saracinisco',
    codice: '060061',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H779',
    cap: ['03040'],
    popolazione: 361,
  },
  {
    nome: 'San Biase',
    codice: '070063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H782',
    cap: ['86020'],
    popolazione: 209,
  },
  {
    nome: 'San Bonifacio',
    codice: '023069',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H783',
    cap: ['37047'],
    popolazione: 20275,
  },
  {
    nome: 'San Buono',
    codice: '069079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H784',
    cap: ['66050'],
    popolazione: 1020,
  },
  {
    nome: 'San Calogero',
    codice: '102032',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'H785',
    cap: ['89842'],
    popolazione: 4460,
  },
  {
    nome: 'San Candido',
    codice: '021077',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H786',
    cap: ['39038'],
    popolazione: 3204,
  },
  {
    nome: "San Canzian d'Isonzo",
    codice: '031018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'H787',
    cap: ['34075'],
    popolazione: 6309,
  },
  {
    nome: 'San Carlo Canavese',
    codice: '001237',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H789',
    cap: ['10070'],
    popolazione: 3874,
  },
  {
    nome: 'San Casciano dei Bagni',
    codice: '052027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'H790',
    cap: ['53040'],
    popolazione: 1637,
  },
  {
    nome: 'San Casciano in Val di Pesa',
    codice: '048038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'H791',
    cap: ['50026'],
    popolazione: 16883,
  },
  {
    nome: 'San Cassiano',
    codice: '075095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'M264',
    cap: ['73020'],
    popolazione: 2105,
  },
  {
    nome: 'San Cataldo',
    codice: '085016',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'H792',
    cap: ['93017'],
    popolazione: 23424,
  },
  {
    nome: 'San Cesareo',
    codice: '058119',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M295',
    cap: ['00030'],
    popolazione: 13806,
  },
  {
    nome: 'San Cesario di Lecce',
    codice: '075068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H793',
    cap: ['73016'],
    popolazione: 8297,
  },
  {
    nome: 'San Cesario sul Panaro',
    codice: '036036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'H794',
    cap: ['41018'],
    popolazione: 6117,
  },
  {
    nome: 'San Chirico Nuovo',
    codice: '076073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H795',
    cap: ['85010'],
    popolazione: 1475,
  },
  {
    nome: 'San Chirico Raparo',
    codice: '076074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H796',
    cap: ['85030'],
    popolazione: 1161,
  },
  {
    nome: 'San Cipirello',
    codice: '082063',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'H797',
    cap: ['90040'],
    popolazione: 5478,
  },
  {
    nome: "San Cipriano d'Aversa",
    codice: '061074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H798',
    cap: ['81036'],
    popolazione: 13416,
  },
  {
    nome: 'San Cipriano Picentino',
    codice: '065118',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H800',
    cap: ['84099'],
    popolazione: 6643,
  },
  {
    nome: 'San Cipriano Po',
    codice: '018133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H799',
    cap: ['27043'],
    popolazione: 516,
  },
  {
    nome: 'San Clemente',
    codice: '099016',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'H801',
    cap: ['47832'],
    popolazione: 5234,
  },
  {
    nome: 'San Colombano al Lambro',
    codice: '015191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H803',
    cap: ['20078'],
    popolazione: 7336,
  },
  {
    nome: 'San Colombano Belmonte',
    codice: '001238',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H804',
    cap: ['10080'],
    popolazione: 376,
  },
  {
    nome: 'San Colombano Certenoli',
    codice: '010053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'H802',
    cap: ['16040'],
    popolazione: 2687,
  },
  {
    nome: 'San Cono',
    codice: '087040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H805',
    cap: ['95040'],
    popolazione: 2790,
  },
  {
    nome: 'San Cosmo Albanese',
    codice: '078113',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H806',
    cap: ['87060'],
    popolazione: 629,
  },
  {
    nome: 'San Costantino Albanese',
    codice: '076075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H808',
    cap: ['85030'],
    popolazione: 778,
  },
  {
    nome: 'San Costantino Calabro',
    codice: '102033',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'H807',
    cap: ['89851'],
    popolazione: 2238,
  },
  {
    nome: 'San Costanzo',
    codice: '041051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'H809',
    cap: ['61039'],
    popolazione: 4841,
  },
  {
    nome: 'San Cristoforo',
    codice: '006152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H810',
    cap: ['15060'],
    popolazione: 607,
  },
  {
    nome: 'San Damiano al Colle',
    codice: '018134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H814',
    cap: ['27040'],
    popolazione: 719,
  },
  {
    nome: "San Damiano d'Asti",
    codice: '005097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H811',
    cap: ['14015'],
    popolazione: 8373,
  },
  {
    nome: 'San Damiano Macra',
    codice: '004207',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H812',
    cap: ['12029'],
    popolazione: 439,
  },
  {
    nome: 'San Daniele del Friuli',
    codice: '030099',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H816',
    cap: ['33038'],
    popolazione: 8072,
  },
  {
    nome: 'San Daniele Po',
    codice: '019089',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H815',
    cap: ['26046'],
    popolazione: 1419,
  },
  {
    nome: 'San Demetrio Corone',
    codice: '078114',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H818',
    cap: ['87069'],
    popolazione: 3665,
  },
  {
    nome: "San Demetrio ne' Vestini",
    codice: '066087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'H819',
    cap: ['67028'],
    popolazione: 1836,
  },
  {
    nome: 'San Didero',
    codice: '001239',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H820',
    cap: ['10050'],
    popolazione: 566,
  },
  {
    nome: 'San Donà di Piave',
    codice: '027033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'H823',
    cap: ['30027'],
    popolazione: 40646,
  },
  {
    nome: 'San Donaci',
    codice: '074013',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'H822',
    cap: ['72025'],
    popolazione: 6869,
  },
  {
    nome: 'San Donato di Lecce',
    codice: '075069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H826',
    cap: ['73010'],
    popolazione: 5792,
  },
  {
    nome: 'San Donato di Ninea',
    codice: '078115',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H825',
    cap: ['87010'],
    popolazione: 1491,
  },
  {
    nome: 'San Donato Milanese',
    codice: '015192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H827',
    cap: ['20097'],
    popolazione: 30992,
  },
  {
    nome: 'San Donato Val di Comino',
    codice: '060062',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H824',
    cap: ['03046'],
    popolazione: 2122,
  },
  {
    nome: 'San Dorligo della Valle',
    codice: '032004',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '032', nome: 'Trieste' },
    sigla: 'TS',
    codiceCatastale: 'D324',
    cap: ['34018'],
    popolazione: 5912,
  },
  {
    nome: 'San Fele',
    codice: '076076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H831',
    cap: ['85020'],
    popolazione: 3168,
  },
  {
    nome: 'San Felice a Cancello',
    codice: '061075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H834',
    cap: ['81027'],
    popolazione: 17110,
  },
  {
    nome: 'San Felice Circeo',
    codice: '059025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'H836',
    cap: ['04017'],
    popolazione: 8709,
  },
  {
    nome: 'San Felice del Benaco',
    codice: '017171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H838',
    cap: ['25010'],
    popolazione: 3403,
  },
  {
    nome: 'San Felice del Molise',
    codice: '070064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H833',
    cap: ['86030'],
    popolazione: 694,
  },
  {
    nome: 'San Felice sul Panaro',
    codice: '036037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'H835',
    cap: ['41038'],
    popolazione: 11026,
  },
  {
    nome: 'San Ferdinando',
    codice: '080097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'M277',
    cap: ['89026'],
    popolazione: 4299,
  },
  {
    nome: 'San Ferdinando di Puglia',
    codice: '110007',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'H839',
    cap: ['76017'],
    popolazione: 13916,
  },
  {
    nome: 'San Fermo della Battaglia',
    codice: '013206',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H840',
    cap: ['22042'],
    popolazione: 7380,
  },
  {
    nome: 'San Fili',
    codice: '078116',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H841',
    cap: ['87037'],
    popolazione: 2715,
  },
  {
    nome: 'San Filippo del Mela',
    codice: '083077',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H842',
    cap: ['98044'],
    popolazione: 7065,
  },
  {
    nome: 'San Fior',
    codice: '026072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'H843',
    cap: ['31020'],
    popolazione: 6813,
  },
  {
    nome: 'San Fiorano',
    codice: '098047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'H844',
    cap: ['26848'],
    popolazione: 1804,
  },
  {
    nome: 'San Floriano del Collio',
    codice: '031019',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'H845',
    cap: ['34070'],
    popolazione: 798,
  },
  {
    nome: 'San Floro',
    codice: '079108',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'H846',
    cap: ['88021'],
    popolazione: 700,
  },
  {
    nome: 'San Francesco al Campo',
    codice: '001240',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H847',
    cap: ['10070'],
    popolazione: 4825,
  },
  {
    nome: 'San Fratello',
    codice: '083078',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H850',
    cap: ['98075'],
    popolazione: 3942,
  },
  {
    nome: 'San Gavino Monreale',
    codice: '111062',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'H856',
    cap: ['09037'],
    popolazione: 8894,
  },
  {
    nome: 'San Gemini',
    codice: '055029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'H857',
    cap: ['05029'],
    popolazione: 4921,
  },
  {
    nome: 'San Genesio Atesino',
    codice: '021079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H858',
    cap: ['39050'],
    popolazione: 2937,
  },
  {
    nome: 'San Genesio ed Uniti',
    codice: '018135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H859',
    cap: ['27010'],
    popolazione: 3788,
  },
  {
    nome: 'San Gennaro Vesuviano',
    codice: '063066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H860',
    cap: ['80040'],
    popolazione: 11073,
  },
  {
    nome: 'San Germano Chisone',
    codice: '001242',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H862',
    cap: ['10065'],
    popolazione: 1874,
  },
  {
    nome: 'San Germano Vercellese',
    codice: '002131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'H861',
    cap: ['13047'],
    popolazione: 1768,
  },
  {
    nome: 'San Gervasio Bresciano',
    codice: '017172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'H865',
    cap: ['25020'],
    popolazione: 2469,
  },
  {
    nome: 'San Giacomo degli Schiavoni',
    codice: '070065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H867',
    cap: ['86030'],
    popolazione: 1410,
  },
  {
    nome: 'San Giacomo delle Segnate',
    codice: '020056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H870',
    cap: ['46020'],
    popolazione: 1779,
  },
  {
    nome: 'San Giacomo Filippo',
    codice: '014058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'H868',
    cap: ['23020'],
    popolazione: 395,
  },
  {
    nome: 'San Giacomo Vercellese',
    codice: '002035',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'B952',
    cap: ['13030'],
    popolazione: 329,
  },
  {
    nome: 'San Gillio',
    codice: '001243',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H873',
    cap: ['10040'],
    popolazione: 3023,
  },
  {
    nome: 'San Gimignano',
    codice: '052028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'H875',
    cap: ['53037'],
    popolazione: 7677,
  },
  {
    nome: 'San Ginesio',
    codice: '043046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'H876',
    cap: ['62026'],
    popolazione: 3644,
  },
  {
    nome: 'San Giorgio a Cremano',
    codice: '063067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H892',
    cap: ['80046'],
    popolazione: 45523,
  },
  {
    nome: 'San Giorgio a Liri',
    codice: '060063',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H880',
    cap: ['03047'],
    popolazione: 3166,
  },
  {
    nome: 'San Giorgio Albanese',
    codice: '078118',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H881',
    cap: ['87060'],
    popolazione: 1555,
  },
  {
    nome: 'San Giorgio Bigarello',
    codice: '020057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H883',
    cap: ['46051'],
    popolazione: 11481,
  },
  {
    nome: 'San Giorgio Canavese',
    codice: '001244',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H890',
    cap: ['10090'],
    popolazione: 2705,
  },
  {
    nome: 'San Giorgio del Sannio',
    codice: '062058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H894',
    cap: ['82018'],
    popolazione: 9809,
  },
  {
    nome: 'San Giorgio della Richinvelda',
    codice: '093038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'H891',
    cap: ['33095'],
    popolazione: 4530,
  },
  {
    nome: 'San Giorgio delle Pertiche',
    codice: '028075',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'H893',
    cap: ['35010'],
    popolazione: 9979,
  },
  {
    nome: 'San Giorgio di Lomellina',
    codice: '018136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'H885',
    cap: ['27020'],
    popolazione: 1155,
  },
  {
    nome: 'San Giorgio di Nogaro',
    codice: '030100',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H895',
    cap: ['33058'],
    popolazione: 7681,
  },
  {
    nome: 'San Giorgio di Piano',
    codice: '037052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'H896',
    cap: ['40016'],
    popolazione: 8201,
  },
  {
    nome: 'San Giorgio in Bosco',
    codice: '028076',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'H897',
    cap: ['35010'],
    popolazione: 6244,
  },
  {
    nome: 'San Giorgio Ionico',
    codice: '073024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'H882',
    cap: ['74027'],
    popolazione: 15676,
  },
  {
    nome: 'San Giorgio La Molara',
    codice: '062059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H898',
    cap: ['82020'],
    popolazione: 3050,
  },
  {
    nome: 'San Giorgio Lucano',
    codice: '077025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'H888',
    cap: ['75027'],
    popolazione: 1290,
  },
  {
    nome: 'San Giorgio Monferrato',
    codice: '006153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'H878',
    cap: ['15020'],
    popolazione: 1279,
  },
  {
    nome: 'San Giorgio Morgeto',
    codice: '080071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H889',
    cap: ['89017'],
    popolazione: 3158,
  },
  {
    nome: 'San Giorgio Piacentino',
    codice: '033040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'H887',
    cap: ['29019'],
    popolazione: 5818,
  },
  {
    nome: 'San Giorgio Scarampi',
    codice: '005098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H899',
    cap: ['14059'],
    popolazione: 131,
  },
  {
    nome: 'San Giorgio su Legnano',
    codice: '015194',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H884',
    cap: ['20010'],
    popolazione: 6730,
  },
  {
    nome: 'San Giorio di Susa',
    codice: '001245',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H900',
    cap: ['10050'],
    popolazione: 1040,
  },
  {
    nome: 'San Giovanni a Piro',
    codice: '065119',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H907',
    cap: ['84070'],
    popolazione: 3818,
  },
  {
    nome: 'San Giovanni al Natisone',
    codice: '030101',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H906',
    cap: ['33048'],
    popolazione: 6117,
  },
  {
    nome: 'San Giovanni Bianco',
    codice: '016188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'H910',
    cap: ['24015'],
    popolazione: 5071,
  },
  {
    nome: 'San Giovanni del Dosso',
    codice: '020058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'H912',
    cap: ['46020'],
    popolazione: 1298,
  },
  {
    nome: 'San Giovanni di Fassa',
    codice: '022250',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M390',
    cap: ['38036'],
    popolazione: 3345,
  },
  {
    nome: 'San Giovanni di Gerace',
    codice: '080072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H903',
    cap: ['89040'],
    popolazione: 537,
  },
  {
    nome: 'San Giovanni Gemini',
    codice: '084036',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'H914',
    cap: ['92020'],
    popolazione: 8127,
  },
  {
    nome: 'San Giovanni Ilarione',
    codice: '023070',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H916',
    cap: ['37035'],
    popolazione: 5111,
  },
  {
    nome: 'San Giovanni in Croce',
    codice: '019090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'H918',
    cap: ['26037'],
    popolazione: 1875,
  },
  {
    nome: 'San Giovanni in Fiore',
    codice: '078119',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H919',
    cap: ['87055'],
    popolazione: 17912,
  },
  {
    nome: 'San Giovanni in Galdo',
    codice: '070066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H920',
    cap: ['86010'],
    popolazione: 624,
  },
  {
    nome: 'San Giovanni in Marignano',
    codice: '099017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'H921',
    cap: ['47842'],
    popolazione: 8973,
  },
  {
    nome: 'San Giovanni in Persiceto',
    codice: '037053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'G467',
    cap: ['40017'],
    popolazione: 26992,
  },
  {
    nome: 'San Giovanni Incarico',
    codice: '060064',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'H917',
    cap: ['03028'],
    popolazione: 3410,
  },
  {
    nome: 'San Giovanni la Punta',
    codice: '087041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H922',
    cap: ['95037'],
    popolazione: 22049,
  },
  {
    nome: 'San Giovanni Lipioni',
    codice: '069080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H923',
    cap: ['66050'],
    popolazione: 210,
  },
  {
    nome: 'San Giovanni Lupatoto',
    codice: '023071',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H924',
    cap: ['37057'],
    popolazione: 24148,
  },
  {
    nome: 'San Giovanni Rotondo',
    codice: '071046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H926',
    cap: ['71013'],
    popolazione: 27329,
  },
  {
    nome: 'San Giovanni Suergiu',
    codice: '111063',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G287',
    cap: ['09010'],
    popolazione: 6020,
  },
  {
    nome: 'San Giovanni Teatino',
    codice: '069081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'D690',
    cap: ['66020'],
    popolazione: 12733,
  },
  {
    nome: 'San Giovanni Valdarno',
    codice: '051033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'H901',
    cap: ['52027'],
    popolazione: 16890,
  },
  {
    nome: 'San Giuliano del Sannio',
    codice: '070067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H928',
    cap: ['86010'],
    popolazione: 1050,
  },
  {
    nome: 'San Giuliano di Puglia',
    codice: '070068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H929',
    cap: ['86040'],
    popolazione: 1057,
  },
  {
    nome: 'San Giuliano Milanese',
    codice: '015195',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'H930',
    cap: ['20098'],
    popolazione: 35971,
  },
  {
    nome: 'San Giuliano Terme',
    codice: '050031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'A562',
    cap: ['56017'],
    popolazione: 31103,
  },
  {
    nome: 'San Giuseppe Jato',
    codice: '082064',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'H933',
    cap: ['90048'],
    popolazione: 8511,
  },
  {
    nome: 'San Giuseppe Vesuviano',
    codice: '063068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'H931',
    cap: ['80047'],
    popolazione: 27467,
  },
  {
    nome: 'San Giustino',
    codice: '054044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'H935',
    cap: ['06016'],
    popolazione: 11337,
  },
  {
    nome: 'San Giusto Canavese',
    codice: '001246',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H936',
    cap: ['10090'],
    popolazione: 3397,
  },
  {
    nome: 'San Godenzo',
    codice: '048039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'H937',
    cap: ['50060'],
    popolazione: 1231,
  },
  {
    nome: "San Gregorio d'Ippona",
    codice: '102034',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'H941',
    cap: ['89853'],
    popolazione: 2422,
  },
  {
    nome: 'San Gregorio da Sassola',
    codice: '058095',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'H942',
    cap: ['00010'],
    popolazione: 1553,
  },
  {
    nome: 'San Gregorio di Catania',
    codice: '087042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'H940',
    cap: ['95027'],
    popolazione: 11497,
  },
  {
    nome: 'San Gregorio Magno',
    codice: '065120',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H943',
    cap: ['84020'],
    popolazione: 4417,
  },
  {
    nome: 'San Gregorio Matese',
    codice: '061076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H939',
    cap: ['81010'],
    popolazione: 1022,
  },
  {
    nome: 'San Gregorio nelle Alpi',
    codice: '025045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'H938',
    cap: ['32030'],
    popolazione: 1607,
  },
  {
    nome: 'San Lazzaro di Savena',
    codice: '037054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'H945',
    cap: ['40068'],
    popolazione: 31091,
  },
  {
    nome: 'San Leo',
    codice: '099025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'H949',
    cap: ['47865'],
    popolazione: 2970,
  },
  {
    nome: 'San Leonardo',
    codice: '030102',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'H951',
    cap: ['33040'],
    popolazione: 1161,
  },
  {
    nome: 'San Leonardo in Passiria',
    codice: '021080',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H952',
    cap: ['39015'],
    popolazione: 3508,
  },
  {
    nome: 'San Leucio del Sannio',
    codice: '062060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H953',
    cap: ['82010'],
    popolazione: 3238,
  },
  {
    nome: 'San Lorenzello',
    codice: '062061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H955',
    cap: ['82030'],
    popolazione: 2320,
  },
  {
    nome: 'San Lorenzo',
    codice: '080073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H959',
    cap: ['89069'],
    popolazione: 2685,
  },
  {
    nome: 'San Lorenzo al Mare',
    codice: '008054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'H957',
    cap: ['18017'],
    popolazione: 1373,
  },
  {
    nome: 'San Lorenzo Bellizzi',
    codice: '078120',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H961',
    cap: ['87070'],
    popolazione: 746,
  },
  {
    nome: 'San Lorenzo del Vallo',
    codice: '078121',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H962',
    cap: ['87040'],
    popolazione: 3465,
  },
  {
    nome: 'San Lorenzo di Sebato',
    codice: '021081',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H956',
    cap: ['39030'],
    popolazione: 3767,
  },
  {
    nome: 'San Lorenzo Dorsino',
    codice: '022231',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M345',
    cap: ['38078'],
    popolazione: 1607,
  },
  {
    nome: 'San Lorenzo in Campo',
    codice: '041054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'H958',
    cap: ['61047'],
    popolazione: 3496,
  },
  {
    nome: 'San Lorenzo Isontino',
    codice: '031020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'H964',
    cap: ['34070'],
    popolazione: 1548,
  },
  {
    nome: 'San Lorenzo Maggiore',
    codice: '062062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H967',
    cap: ['82034'],
    popolazione: 2165,
  },
  {
    nome: 'San Lorenzo Nuovo',
    codice: '056047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'H969',
    cap: ['01020'],
    popolazione: 2166,
  },
  {
    nome: 'San Luca',
    codice: '080074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'H970',
    cap: ['89030'],
    popolazione: 4044,
  },
  {
    nome: 'San Lucido',
    codice: '078122',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H971',
    cap: ['87038'],
    popolazione: 5940,
  },
  {
    nome: 'San Lupo',
    codice: '062063',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H973',
    cap: ['82034'],
    popolazione: 863,
  },
  {
    nome: "San Mango d'Aquino",
    codice: '079110',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'H976',
    cap: ['88040'],
    popolazione: 1639,
  },
  {
    nome: 'San Mango Piemonte',
    codice: '065121',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'H977',
    cap: ['84090'],
    popolazione: 2587,
  },
  {
    nome: 'San Mango sul Calore',
    codice: '064082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'H975',
    cap: ['83050'],
    popolazione: 1192,
  },
  {
    nome: 'San Marcellino',
    codice: '061077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'H978',
    cap: ['81030'],
    popolazione: 12643,
  },
  {
    nome: 'San Marcello',
    codice: '042041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'H979',
    cap: ['60030'],
    popolazione: 2069,
  },
  {
    nome: 'San Marcello Piteglio',
    codice: '047024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'M377',
    cap: ['51028'],
    popolazione: 8469,
  },
  {
    nome: 'San Marco Argentano',
    codice: '078123',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H981',
    cap: ['87018'],
    popolazione: 7282,
  },
  {
    nome: "San Marco d'Alunzio",
    codice: '083079',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'H982',
    cap: ['98070'],
    popolazione: 2083,
  },
  {
    nome: 'San Marco dei Cavoti',
    codice: '062064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'H984',
    cap: ['82029'],
    popolazione: 3544,
  },
  {
    nome: 'San Marco Evangelista',
    codice: '061104',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'F043',
    cap: ['81020'],
    popolazione: 6306,
  },
  {
    nome: 'San Marco in Lamis',
    codice: '071047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H985',
    cap: ['71014'],
    popolazione: 14218,
  },
  {
    nome: 'San Marco la Catola',
    codice: '071048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'H986',
    cap: ['71030'],
    popolazione: 1082,
  },
  {
    nome: 'San Martino al Tagliamento',
    codice: '093039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'H999',
    cap: ['33098'],
    popolazione: 1496,
  },
  {
    nome: 'San Martino Alfieri',
    codice: '005099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'H987',
    cap: ['14010'],
    popolazione: 712,
  },
  {
    nome: 'San Martino Buon Albergo',
    codice: '023073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I003',
    cap: ['37036'],
    popolazione: 14283,
  },
  {
    nome: 'San Martino Canavese',
    codice: '001247',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H997',
    cap: ['10010'],
    popolazione: 851,
  },
  {
    nome: "San Martino d'Agri",
    codice: '076077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H994',
    cap: ['85030'],
    popolazione: 825,
  },
  {
    nome: "San Martino dall'Argine",
    codice: '020059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'I005',
    cap: ['46010'],
    popolazione: 1811,
  },
  {
    nome: 'San Martino del Lago',
    codice: '019091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I007',
    cap: ['26040'],
    popolazione: 473,
  },
  {
    nome: 'San Martino di Finita',
    codice: '078124',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H992',
    cap: ['87010'],
    popolazione: 1207,
  },
  {
    nome: 'San Martino di Lupari',
    codice: '028077',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I008',
    cap: ['35018'],
    popolazione: 13052,
  },
  {
    nome: 'San Martino di Venezze',
    codice: '029044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'H996',
    cap: ['45030'],
    popolazione: 4035,
  },
  {
    nome: 'San Martino in Badia',
    codice: '021082',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H988',
    cap: ['39030'],
    popolazione: 1729,
  },
  {
    nome: 'San Martino in Passiria',
    codice: '021083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'H989',
    cap: ['39010'],
    popolazione: 3136,
  },
  {
    nome: 'San Martino in Pensilis',
    codice: '070069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'H990',
    cap: ['86046'],
    popolazione: 4797,
  },
  {
    nome: 'San Martino in Rio',
    codice: '035037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'I011',
    cap: ['42018'],
    popolazione: 7773,
  },
  {
    nome: 'San Martino in Strada',
    codice: '098048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I012',
    cap: ['26817'],
    popolazione: 3624,
  },
  {
    nome: 'San Martino Sannita',
    codice: '062065',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I002',
    cap: ['82010'],
    popolazione: 1277,
  },
  {
    nome: 'San Martino Siccomario',
    codice: '018137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I014',
    cap: ['27028'],
    popolazione: 5738,
  },
  {
    nome: 'San Martino sulla Marrucina',
    codice: '069082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'H991',
    cap: ['66010'],
    popolazione: 960,
  },
  {
    nome: 'San Martino Valle Caudina',
    codice: '064083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I016',
    cap: ['83018'],
    popolazione: 4745,
  },
  {
    nome: 'San Marzano di San Giuseppe',
    codice: '073025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'I018',
    cap: ['74020'],
    popolazione: 9269,
  },
  {
    nome: 'San Marzano Oliveto',
    codice: '005100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I017',
    cap: ['14050'],
    popolazione: 1067,
  },
  {
    nome: 'San Marzano sul Sarno',
    codice: '065122',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I019',
    cap: ['84010'],
    popolazione: 10225,
  },
  {
    nome: 'San Massimo',
    codice: '070070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I023',
    cap: ['86027'],
    popolazione: 836,
  },
  {
    nome: 'San Maurizio Canavese',
    codice: '001248',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I024',
    cap: ['10077'],
    popolazione: 9646,
  },
  {
    nome: "San Maurizio d'Opaglio",
    codice: '003133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I025',
    cap: ['28017'],
    popolazione: 3104,
  },
  {
    nome: 'San Mauro Castelverde',
    codice: '082065',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'I028',
    cap: ['90010'],
    popolazione: 1847,
  },
  {
    nome: 'San Mauro Cilento',
    codice: '065123',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I031',
    cap: ['84070'],
    popolazione: 985,
  },
  {
    nome: 'San Mauro di Saline',
    codice: '023074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H712',
    cap: ['37030'],
    popolazione: 553,
  },
  {
    nome: 'San Mauro Forte',
    codice: '077026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'I029',
    cap: ['75010'],
    popolazione: 1710,
  },
  {
    nome: 'San Mauro la Bruca',
    codice: '065124',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I032',
    cap: ['84070'],
    popolazione: 653,
  },
  {
    nome: 'San Mauro Marchesato',
    codice: '101020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'I026',
    cap: ['88831'],
    popolazione: 2192,
  },
  {
    nome: 'San Mauro Pascoli',
    codice: '040041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'I027',
    cap: ['47030'],
    popolazione: 11090,
  },
  {
    nome: 'San Mauro Torinese',
    codice: '001249',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I030',
    cap: ['10099'],
    popolazione: 18925,
  },
  {
    nome: 'San Michele al Tagliamento',
    codice: '027034',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'I040',
    cap: ['30028'],
    popolazione: 12028,
  },
  {
    nome: "San Michele all'Adige",
    codice: '022167',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I042',
    cap: ['38010'],
    popolazione: 3521,
  },
  {
    nome: 'San Michele di Ganzaria',
    codice: '087043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I035',
    cap: ['95040'],
    popolazione: 3463,
  },
  {
    nome: 'San Michele di Serino',
    codice: '064084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I034',
    cap: ['83020'],
    popolazione: 2591,
  },
  {
    nome: 'San Michele Mondovì',
    codice: '004210',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I037',
    cap: ['12080'],
    popolazione: 2034,
  },
  {
    nome: 'San Michele Salentino',
    codice: '074014',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'I045',
    cap: ['72018'],
    popolazione: 6371,
  },
  {
    nome: 'San Miniato',
    codice: '050032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'I046',
    cap: ['56028'],
    popolazione: 27585,
  },
  {
    nome: 'San Nazzaro',
    codice: '062066',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I049',
    cap: ['82018'],
    popolazione: 914,
  },
  {
    nome: 'San Nazzaro Sesia',
    codice: '003134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I052',
    cap: ['28060'],
    popolazione: 690,
  },
  {
    nome: 'San Nazzaro Val Cavargna',
    codice: '013207',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I051',
    cap: ['22010'],
    popolazione: 338,
  },
  {
    nome: 'San Nicandro Garganico',
    codice: '071049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I054',
    cap: ['71015'],
    popolazione: 15927,
  },
  {
    nome: 'San Nicola Arcella',
    codice: '078125',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I060',
    cap: ['87020'],
    popolazione: 1751,
  },
  {
    nome: 'San Nicola Baronia',
    codice: '064085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I061',
    cap: ['83050'],
    popolazione: 784,
  },
  {
    nome: 'San Nicola da Crissa',
    codice: '102035',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I058',
    cap: ['89821'],
    popolazione: 1416,
  },
  {
    nome: "San Nicola dell'Alto",
    codice: '101021',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'I057',
    cap: ['88817'],
    popolazione: 898,
  },
  {
    nome: 'San Nicola la Strada',
    codice: '061078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I056',
    cap: ['81020'],
    popolazione: 21157,
  },
  {
    nome: 'San Nicola Manfredi',
    codice: '062067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I062',
    cap: ['82010'],
    popolazione: 3624,
  },
  {
    nome: "San Nicolò d'Arcidano",
    codice: '095046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A368',
    cap: ['09097'],
    popolazione: 2811,
  },
  {
    nome: 'San Nicolò di Comelico',
    codice: '025046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I063',
    cap: ['32040'],
    popolazione: 405,
  },
  {
    nome: 'San Nicolò Gerrei',
    codice: '111064',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'G383',
    cap: ['09040'],
    popolazione: 846,
  },
  {
    nome: 'San Pancrazio',
    codice: '021084',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I065',
    cap: ['39010'],
    popolazione: 1584,
  },
  {
    nome: 'San Pancrazio Salentino',
    codice: '074015',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'I066',
    cap: ['72026'],
    popolazione: 10289,
  },
  {
    nome: 'San Paolo',
    codice: '017138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'G407',
    cap: ['25020'],
    popolazione: 4504,
  },
  {
    nome: 'San Paolo Albanese',
    codice: '076020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'B906',
    cap: ['85030'],
    popolazione: 306,
  },
  {
    nome: 'San Paolo Bel Sito',
    codice: '063069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I073',
    cap: ['80030'],
    popolazione: 3422,
  },
  {
    nome: "San Paolo d'Argon",
    codice: '016189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'B310',
    cap: ['24060'],
    popolazione: 5386,
  },
  {
    nome: 'San Paolo di Civitate',
    codice: '071050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I072',
    cap: ['71010'],
    popolazione: 5935,
  },
  {
    nome: 'San Paolo di Jesi',
    codice: '042042',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I071',
    cap: ['60038'],
    popolazione: 902,
  },
  {
    nome: 'San Paolo Solbrito',
    codice: '005101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I076',
    cap: ['14010'],
    popolazione: 1197,
  },
  {
    nome: 'San Pellegrino Terme',
    codice: '016190',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I079',
    cap: ['24016'],
    popolazione: 4950,
  },
  {
    nome: "San Pier d'Isonzo",
    codice: '031021',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'I082',
    cap: ['34070'],
    popolazione: 2019,
  },
  {
    nome: 'San Pier Niceto',
    codice: '083080',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I084',
    cap: ['98045'],
    popolazione: 2911,
  },
  {
    nome: 'San Piero Patti',
    codice: '083081',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I086',
    cap: ['98068'],
    popolazione: 3082,
  },
  {
    nome: 'San Pietro a Maida',
    codice: '079114',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I093',
    cap: ['88025'],
    popolazione: 4298,
  },
  {
    nome: 'San Pietro al Natisone',
    codice: '030103',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I092',
    cap: ['33049'],
    popolazione: 2223,
  },
  {
    nome: 'San Pietro al Tanagro',
    codice: '065125',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I089',
    cap: ['84030'],
    popolazione: 1737,
  },
  {
    nome: 'San Pietro Apostolo',
    codice: '079115',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I095',
    cap: ['88040'],
    popolazione: 1778,
  },
  {
    nome: 'San Pietro Avellana',
    codice: '094043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I096',
    cap: ['86088'],
    popolazione: 537,
  },
  {
    nome: 'San Pietro Clarenza',
    codice: '087044',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I098',
    cap: ['95030'],
    popolazione: 7102,
  },
  {
    nome: 'San Pietro di Cadore',
    codice: '025047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I088',
    cap: ['32040'],
    popolazione: 1690,
  },
  {
    nome: 'San Pietro di Caridà',
    codice: '080075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I102',
    cap: ['89020'],
    popolazione: 1265,
  },
  {
    nome: 'San Pietro di Feletto',
    codice: '026073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I103',
    cap: ['31020'],
    popolazione: 5355,
  },
  {
    nome: 'San Pietro di Morubio',
    codice: '023075',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I105',
    cap: ['37050'],
    popolazione: 3024,
  },
  {
    nome: 'San Pietro in Amantea',
    codice: '078126',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I108',
    cap: ['87030'],
    popolazione: 534,
  },
  {
    nome: 'San Pietro in Cariano',
    codice: '023076',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I109',
    cap: ['37029'],
    popolazione: 12930,
  },
  {
    nome: 'San Pietro in Casale',
    codice: '037055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'I110',
    cap: ['40018'],
    popolazione: 11736,
  },
  {
    nome: 'San Pietro in Cerro',
    codice: '033041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'G788',
    cap: ['29010'],
    popolazione: 926,
  },
  {
    nome: 'San Pietro in Gu',
    codice: '028078',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I107',
    cap: ['35010'],
    popolazione: 4576,
  },
  {
    nome: 'San Pietro in Guarano',
    codice: '078127',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I114',
    cap: ['87047'],
    popolazione: 3649,
  },
  {
    nome: 'San Pietro in Lama',
    codice: '075071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I115',
    cap: ['73010'],
    popolazione: 3600,
  },
  {
    nome: 'San Pietro Infine',
    codice: '061079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I113',
    cap: ['81049'],
    popolazione: 949,
  },
  {
    nome: 'San Pietro Mosezzo',
    codice: '003135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I116',
    cap: ['28060'],
    popolazione: 1996,
  },
  {
    nome: 'San Pietro Mussolino',
    codice: '024094',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I117',
    cap: ['36070'],
    popolazione: 1613,
  },
  {
    nome: 'San Pietro Val Lemina',
    codice: '001250',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I090',
    cap: ['10060'],
    popolazione: 1430,
  },
  {
    nome: 'San Pietro Vernotico',
    codice: '074016',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'I119',
    cap: ['72027'],
    popolazione: 13974,
  },
  {
    nome: 'San Pietro Viminario',
    codice: '028079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I120',
    cap: ['35020'],
    popolazione: 3003,
  },
  {
    nome: 'San Pio delle Camere',
    codice: '066088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I121',
    cap: ['67020'],
    popolazione: 631,
  },
  {
    nome: "San Polo d'Enza",
    codice: '035038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'I123',
    cap: ['42020'],
    popolazione: 5949,
  },
  {
    nome: 'San Polo dei Cavalieri',
    codice: '058096',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I125',
    cap: ['00010'],
    popolazione: 2984,
  },
  {
    nome: 'San Polo di Piave',
    codice: '026074',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I124',
    cap: ['31020'],
    popolazione: 4929,
  },
  {
    nome: 'San Polo Matese',
    codice: '070071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I122',
    cap: ['86020'],
    popolazione: 458,
  },
  {
    nome: 'San Ponso',
    codice: '001251',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I126',
    cap: ['10080'],
    popolazione: 279,
  },
  {
    nome: 'San Possidonio',
    codice: '036038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I128',
    cap: ['41039'],
    popolazione: 3621,
  },
  {
    nome: 'San Potito Sannitico',
    codice: '061080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I130',
    cap: ['81016'],
    popolazione: 2000,
  },
  {
    nome: 'San Potito Ultra',
    codice: '064086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I129',
    cap: ['83050'],
    popolazione: 1598,
  },
  {
    nome: 'San Prisco',
    codice: '061081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I131',
    cap: ['81054'],
    popolazione: 11903,
  },
  {
    nome: 'San Procopio',
    codice: '080076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I132',
    cap: ['89020'],
    popolazione: 539,
  },
  {
    nome: 'San Prospero',
    codice: '036039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I133',
    cap: ['41030'],
    popolazione: 5841,
  },
  {
    nome: "San Quirico d'Orcia",
    codice: '052030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'I135',
    cap: ['53027'],
    popolazione: 2680,
  },
  {
    nome: 'San Quirino',
    codice: '093040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'I136',
    cap: ['33080'],
    popolazione: 4274,
  },
  {
    nome: 'San Raffaele Cimena',
    codice: '001252',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I137',
    cap: ['10090'],
    popolazione: 3107,
  },
  {
    nome: 'San Roberto',
    codice: '080077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I139',
    cap: ['89050'],
    popolazione: 1833,
  },
  {
    nome: 'San Rocco al Porto',
    codice: '098049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I140',
    cap: ['26865'],
    popolazione: 3464,
  },
  {
    nome: 'San Romano in Garfagnana',
    codice: '046027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'I142',
    cap: ['55038'],
    popolazione: 1459,
  },
  {
    nome: 'San Rufo',
    codice: '065126',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I143',
    cap: ['84030'],
    popolazione: 1729,
  },
  {
    nome: 'San Salvatore di Fitalia',
    codice: '083082',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I147',
    cap: ['98070'],
    popolazione: 1378,
  },
  {
    nome: 'San Salvatore Monferrato',
    codice: '006154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I144',
    cap: ['15046'],
    popolazione: 4449,
  },
  {
    nome: 'San Salvatore Telesino',
    codice: '062068',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I145',
    cap: ['82030'],
    popolazione: 4038,
  },
  {
    nome: 'San Salvo',
    codice: '069083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'I148',
    cap: ['66050'],
    popolazione: 18848,
  },
  {
    nome: 'San Sebastiano al Vesuvio',
    codice: '063070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I151',
    cap: ['80040'],
    popolazione: 9167,
  },
  {
    nome: 'San Sebastiano Curone',
    codice: '006155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I150',
    cap: ['15056'],
    popolazione: 591,
  },
  {
    nome: 'San Sebastiano da Po',
    codice: '001253',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I152',
    cap: ['10020'],
    popolazione: 1909,
  },
  {
    nome: 'San Secondo di Pinerolo',
    codice: '001254',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I154',
    cap: ['10060'],
    popolazione: 3608,
  },
  {
    nome: 'San Secondo Parmense',
    codice: '034033',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'I153',
    cap: ['43017'],
    popolazione: 5519,
  },
  {
    nome: 'San Severino Lucano',
    codice: '076078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I157',
    cap: ['85030'],
    popolazione: 1667,
  },
  {
    nome: 'San Severino Marche',
    codice: '043047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'I156',
    cap: ['62027'],
    popolazione: 13018,
  },
  {
    nome: 'San Severo',
    codice: '071051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I158',
    cap: ['71016'],
    popolazione: 54906,
  },
  {
    nome: 'San Siro',
    codice: '013248',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I162',
    cap: ['22010'],
    popolazione: 1758,
  },
  {
    nome: 'San Sossio Baronia',
    codice: '064087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I163',
    cap: ['83050'],
    popolazione: 1697,
  },
  {
    nome: 'San Sostene',
    codice: '079116',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I164',
    cap: ['88060'],
    popolazione: 1311,
  },
  {
    nome: 'San Sosti',
    codice: '078128',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I165',
    cap: ['87010'],
    popolazione: 2200,
  },
  {
    nome: 'San Sperate',
    codice: '111065',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I166',
    cap: ['09026'],
    popolazione: 7972,
  },
  {
    nome: 'San Stino di Livenza',
    codice: '027036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'I373',
    cap: ['30029'],
    popolazione: 13042,
  },
  {
    nome: 'San Tammaro',
    codice: '061085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I261',
    cap: ['81050'],
    popolazione: 5064,
  },
  {
    nome: 'San Teodoro',
    codice: '083090',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I328',
    cap: ['98030'],
    popolazione: 1421,
  },
  {
    nome: 'San Teodoro',
    codice: '090092',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I329',
    cap: ['07052'],
    popolazione: 4540,
  },
  {
    nome: 'San Tomaso Agordino',
    codice: '025049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I347',
    cap: ['32020'],
    popolazione: 683,
  },
  {
    nome: 'San Valentino in Abruzzo Citeriore',
    codice: '068038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'I376',
    cap: ['65020'],
    popolazione: 1930,
  },
  {
    nome: 'San Valentino Torio',
    codice: '065132',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I377',
    cap: ['84010'],
    popolazione: 10439,
  },
  {
    nome: 'San Venanzo',
    codice: '055030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'I381',
    cap: ['05010'],
    popolazione: 2311,
  },
  {
    nome: 'San Vendemiano',
    codice: '026076',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I382',
    cap: ['31020'],
    popolazione: 10080,
  },
  {
    nome: 'San Vero Milis',
    codice: '095050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I384',
    cap: ['09070'],
    popolazione: 2526,
  },
  {
    nome: 'San Vincenzo',
    codice: '049018',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'I390',
    cap: ['57027'],
    popolazione: 7023,
  },
  {
    nome: 'San Vincenzo La Costa',
    codice: '078135',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I388',
    cap: ['87030'],
    popolazione: 2158,
  },
  {
    nome: 'San Vincenzo Valle Roveto',
    codice: '066092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I389',
    cap: ['67050'],
    popolazione: 2433,
  },
  {
    nome: 'San Vitaliano',
    codice: '063075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I391',
    cap: ['80030'],
    popolazione: 6220,
  },
  {
    nome: 'San Vito',
    codice: '111066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I402',
    cap: ['09040'],
    popolazione: 3822,
  },
  {
    nome: 'San Vito al Tagliamento',
    codice: '093041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'I403',
    cap: ['33078'],
    popolazione: 15011,
  },
  {
    nome: 'San Vito al Torre',
    codice: '030105',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I404',
    cap: ['33050'],
    popolazione: 1333,
  },
  {
    nome: 'San Vito Chietino',
    codice: '069086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'I394',
    cap: ['66038'],
    popolazione: 5226,
  },
  {
    nome: 'San Vito dei Normanni',
    codice: '074017',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'I396',
    cap: ['72019'],
    popolazione: 19620,
  },
  {
    nome: 'San Vito di Cadore',
    codice: '025051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I392',
    cap: ['32046'],
    popolazione: 1813,
  },
  {
    nome: 'San Vito di Fagagna',
    codice: '030106',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I405',
    cap: ['33030'],
    popolazione: 1682,
  },
  {
    nome: 'San Vito di Leguzzano',
    codice: '024096',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I401',
    cap: ['36030'],
    popolazione: 3584,
  },
  {
    nome: 'San Vito Lo Capo',
    codice: '081020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'I407',
    cap: ['91010'],
    popolazione: 4415,
  },
  {
    nome: 'San Vito Romano',
    codice: '058100',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I400',
    cap: ['00030'],
    popolazione: 3366,
  },
  {
    nome: 'San Vito sullo Ionio',
    codice: '079122',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I393',
    cap: ['88067'],
    popolazione: 1830,
  },
  {
    nome: 'San Vittore del Lazio',
    codice: '060070',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I408',
    cap: ['03040'],
    popolazione: 2679,
  },
  {
    nome: 'San Vittore Olona',
    codice: '015201',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I409',
    cap: ['20028'],
    popolazione: 8254,
  },
  {
    nome: 'San Zeno di Montagna',
    codice: '023079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I414',
    cap: ['37010'],
    popolazione: 1367,
  },
  {
    nome: 'San Zeno Naviglio',
    codice: '017173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I412',
    cap: ['25010'],
    popolazione: 4601,
  },
  {
    nome: 'San Zenone al Lambro',
    codice: '015202',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I415',
    cap: ['20070'],
    popolazione: 4186,
  },
  {
    nome: 'San Zenone al Po',
    codice: '018145',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I416',
    cap: ['27010'],
    popolazione: 598,
  },
  {
    nome: 'San Zenone degli Ezzelini',
    codice: '026077',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I417',
    cap: ['31020'],
    popolazione: 7391,
  },
  {
    nome: 'Sanarica',
    codice: '075067',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'H757',
    cap: ['73030'],
    popolazione: 1503,
  },
  {
    nome: 'Sandigliano',
    codice: '096059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'H821',
    cap: ['13876'],
    popolazione: 2762,
  },
  {
    nome: 'Sandrigo',
    codice: '024091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'H829',
    cap: ['36066'],
    popolazione: 8392,
  },
  {
    nome: 'Sanfrè',
    codice: '004208',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H851',
    cap: ['12040'],
    popolazione: 2901,
  },
  {
    nome: 'Sanfront',
    codice: '004209',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'H852',
    cap: ['12030'],
    popolazione: 2530,
  },
  {
    nome: 'Sangano',
    codice: '001241',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'H855',
    cap: ['10090'],
    popolazione: 3807,
  },
  {
    nome: 'Sangiano',
    codice: '012141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'H872',
    cap: ['21038'],
    popolazione: 1536,
  },
  {
    nome: 'Sangineto',
    codice: '078117',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'H877',
    cap: ['87020'],
    popolazione: 1337,
  },
  {
    nome: 'Sanguinetto',
    codice: '023072',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'H944',
    cap: ['37058'],
    popolazione: 4140,
  },
  {
    nome: 'Sanluri',
    codice: '111067',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'H974',
    cap: ['09025'],
    popolazione: 8460,
  },
  {
    nome: "Sannazzaro de' Burgondi",
    codice: '018138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I048',
    cap: ['27039'],
    popolazione: 5644,
  },
  {
    nome: 'Sannicandro di Bari',
    codice: '072040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'I053',
    cap: ['70028'],
    popolazione: 9713,
  },
  {
    nome: 'Sannicola',
    codice: '075070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I059',
    cap: ['73017'],
    popolazione: 5902,
  },
  {
    nome: 'Sanremo',
    codice: '008055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'I138',
    cap: ['18038'],
    popolazione: 54137,
  },
  {
    nome: 'Sansepolcro',
    codice: '051034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'I155',
    cap: ['52037'],
    popolazione: 16108,
  },
  {
    nome: "Sant'Agapito",
    codice: '094044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I189',
    cap: ['86070'],
    popolazione: 1387,
  },
  {
    nome: "Sant'Agata Bolognese",
    codice: '037056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'I191',
    cap: ['40019'],
    popolazione: 7140,
  },
  {
    nome: "Sant'Agata de' Goti",
    codice: '062070',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I197',
    cap: ['82019'],
    popolazione: 11310,
  },
  {
    nome: "Sant'Agata del Bianco",
    codice: '080079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I198',
    cap: ['89030'],
    popolazione: 679,
  },
  {
    nome: "Sant'Agata di Esaro",
    codice: '078131',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I192',
    cap: ['87010'],
    popolazione: 1990,
  },
  {
    nome: "Sant'Agata di Militello",
    codice: '083084',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I199',
    cap: ['98076'],
    popolazione: 12803,
  },
  {
    nome: "Sant'Agata di Puglia",
    codice: '071052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I193',
    cap: ['71028'],
    popolazione: 2096,
  },
  {
    nome: "Sant'Agata Feltria",
    codice: '099026',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'I201',
    cap: ['47866'],
    popolazione: 2280,
  },
  {
    nome: "Sant'Agata Fossili",
    codice: '006156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I190',
    cap: ['15050'],
    popolazione: 441,
  },
  {
    nome: "Sant'Agata li Battiati",
    codice: '087045',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I202',
    cap: ['95030'],
    popolazione: 9829,
  },
  {
    nome: "Sant'Agata sul Santerno",
    codice: '039017',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'I196',
    cap: ['48020'],
    popolazione: 2822,
  },
  {
    nome: "Sant'Agnello",
    codice: '063071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I208',
    cap: ['80065'],
    popolazione: 9029,
  },
  {
    nome: "Sant'Albano Stura",
    codice: '004211',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I210',
    cap: ['12040'],
    popolazione: 2380,
  },
  {
    nome: "Sant'Alessio con Vialone",
    codice: '018141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I213',
    cap: ['27016'],
    popolazione: 840,
  },
  {
    nome: "Sant'Alessio in Aspromonte",
    codice: '080080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I214',
    cap: ['89050'],
    popolazione: 323,
  },
  {
    nome: "Sant'Alessio Siculo",
    codice: '083085',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I215',
    cap: ['98030'],
    popolazione: 1497,
  },
  {
    nome: "Sant'Alfio",
    codice: '087046',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I216',
    cap: ['95010'],
    popolazione: 1631,
  },
  {
    nome: "Sant'Ambrogio di Torino",
    codice: '001255',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I258',
    cap: ['10057'],
    popolazione: 4753,
  },
  {
    nome: "Sant'Ambrogio di Valpolicella",
    codice: '023077',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I259',
    cap: ['37015'],
    popolazione: 11422,
  },
  {
    nome: "Sant'Ambrogio sul Garigliano",
    codice: '060065',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I256',
    cap: ['03040'],
    popolazione: 994,
  },
  {
    nome: "Sant'Anastasia",
    codice: '063072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I262',
    cap: ['80048'],
    popolazione: 27296,
  },
  {
    nome: "Sant'Anatolia di Narco",
    codice: '054045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I263',
    cap: ['06040'],
    popolazione: 558,
  },
  {
    nome: "Sant'Andrea Apostolo dello Ionio",
    codice: '079118',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I266',
    cap: ['88060'],
    popolazione: 2072,
  },
  {
    nome: "Sant'Andrea del Garigliano",
    codice: '060066',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I265',
    cap: ['03040'],
    popolazione: 1566,
  },
  {
    nome: "Sant'Andrea di Conza",
    codice: '064089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I264',
    cap: ['83053'],
    popolazione: 1662,
  },
  {
    nome: "Sant'Andrea Frius",
    codice: '111069',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I271',
    cap: ['09040'],
    popolazione: 1834,
  },
  {
    nome: "Sant'Angelo a Cupolo",
    codice: '062071',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I277',
    cap: ['82010'],
    popolazione: 4264,
  },
  {
    nome: "Sant'Angelo a Fasanella",
    codice: '065128',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I278',
    cap: ['84027'],
    popolazione: 718,
  },
  {
    nome: "Sant'Angelo a Scala",
    codice: '064091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I280',
    cap: ['83010'],
    popolazione: 755,
  },
  {
    nome: "Sant'Angelo all'Esca",
    codice: '064090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I279',
    cap: ['83050'],
    popolazione: 836,
  },
  {
    nome: "Sant'Angelo d'Alife",
    codice: '061086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I273',
    cap: ['81017'],
    popolazione: 2276,
  },
  {
    nome: "Sant'Angelo dei Lombardi",
    codice: '064092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I281',
    cap: ['83054'],
    popolazione: 4304,
  },
  {
    nome: "Sant'Angelo del Pesco",
    codice: '094046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I282',
    cap: ['86080'],
    popolazione: 368,
  },
  {
    nome: "Sant'Angelo di Brolo",
    codice: '083088',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I283',
    cap: ['98060'],
    popolazione: 3297,
  },
  {
    nome: "Sant'Angelo di Piove di Sacco",
    codice: '028082',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I275',
    cap: ['35020'],
    popolazione: 7211,
  },
  {
    nome: "Sant'Angelo in Pontano",
    codice: '043048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'I286',
    cap: ['62020'],
    popolazione: 1483,
  },
  {
    nome: "Sant'Angelo in Vado",
    codice: '041057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'I287',
    cap: ['61048'],
    popolazione: 4107,
  },
  {
    nome: "Sant'Angelo Le Fratte",
    codice: '076079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I288',
    cap: ['85050'],
    popolazione: 1457,
  },
  {
    nome: "Sant'Angelo Limosano",
    codice: '070073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I289',
    cap: ['86020'],
    popolazione: 348,
  },
  {
    nome: "Sant'Angelo Lodigiano",
    codice: '098050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I274',
    cap: ['26866'],
    popolazione: 12665,
  },
  {
    nome: "Sant'Angelo Lomellina",
    codice: '018144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I276',
    cap: ['27030'],
    popolazione: 864,
  },
  {
    nome: "Sant'Angelo Muxaro",
    codice: '084039',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'I290',
    cap: ['92020'],
    popolazione: 1471,
  },
  {
    nome: "Sant'Angelo Romano",
    codice: '058098',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I284',
    cap: ['00010'],
    popolazione: 4488,
  },
  {
    nome: "Sant'Anna Arresi",
    codice: '111070',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M209',
    cap: ['09010'],
    popolazione: 2712,
  },
  {
    nome: "Sant'Anna d'Alfaedo",
    codice: '023078',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I292',
    cap: ['37020'],
    popolazione: 2564,
  },
  {
    nome: "Sant'Antimo",
    codice: '063073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I293',
    cap: ['80029'],
    popolazione: 34107,
  },
  {
    nome: "Sant'Antioco",
    codice: '111071',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I294',
    cap: ['09017'],
    popolazione: 11496,
  },
  {
    nome: "Sant'Antonino di Susa",
    codice: '001256',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I296',
    cap: ['10050'],
    popolazione: 4333,
  },
  {
    nome: "Sant'Antonio Abate",
    codice: '063074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I300',
    cap: ['80057'],
    popolazione: 19546,
  },
  {
    nome: "Sant'Antonio di Gallura",
    codice: '090085',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M276',
    cap: ['07030'],
    popolazione: 1619,
  },
  {
    nome: "Sant'Apollinare",
    codice: '060067',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I302',
    cap: ['03048'],
    popolazione: 1931,
  },
  {
    nome: "Sant'Arcangelo",
    codice: '076080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I305',
    cap: ['85037'],
    popolazione: 6506,
  },
  {
    nome: "Sant'Arcangelo Trimonte",
    codice: '062078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'F557',
    cap: ['82021'],
    popolazione: 641,
  },
  {
    nome: "Sant'Arpino",
    codice: '061087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I306',
    cap: ['81030'],
    popolazione: 14076,
  },
  {
    nome: "Sant'Arsenio",
    codice: '065129',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I307',
    cap: ['84037'],
    popolazione: 2747,
  },
  {
    nome: "Sant'Egidio alla Vibrata",
    codice: '067038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'I318',
    cap: ['64016'],
    popolazione: 9668,
  },
  {
    nome: "Sant'Egidio del Monte Albino",
    codice: '065130',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I317',
    cap: ['84010'],
    popolazione: 8715,
  },
  {
    nome: "Sant'Elena",
    codice: '028083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I319',
    cap: ['35040'],
    popolazione: 2430,
  },
  {
    nome: "Sant'Elena Sannita",
    codice: '094047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'B466',
    cap: ['86095'],
    popolazione: 260,
  },
  {
    nome: "Sant'Elia a Pianisi",
    codice: '070074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I320',
    cap: ['86048'],
    popolazione: 1910,
  },
  {
    nome: "Sant'Elia Fiumerapido",
    codice: '060068',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I321',
    cap: ['03049'],
    popolazione: 6227,
  },
  {
    nome: "Sant'Elpidio a Mare",
    codice: '109037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'I324',
    cap: ['63811'],
    popolazione: 16968,
  },
  {
    nome: "Sant'Eufemia a Maiella",
    codice: '068037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'I332',
    cap: ['65020'],
    popolazione: 299,
  },
  {
    nome: "Sant'Eufemia d'Aspromonte",
    codice: '080081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I333',
    cap: ['89027'],
    popolazione: 4053,
  },
  {
    nome: "Sant'Eusanio del Sangro",
    codice: '069085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'I335',
    cap: ['66037'],
    popolazione: 2453,
  },
  {
    nome: "Sant'Eusanio Forconese",
    codice: '066090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I336',
    cap: ['67020'],
    popolazione: 418,
  },
  {
    nome: "Sant'Ilario d'Enza",
    codice: '035039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'I342',
    cap: ['42049'],
    popolazione: 10939,
  },
  {
    nome: "Sant'Ilario dello Ionio",
    codice: '080082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I341',
    cap: ['89040'],
    popolazione: 1332,
  },
  {
    nome: "Sant'Ippolito",
    codice: '041058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'I344',
    cap: ['61040'],
    popolazione: 1574,
  },
  {
    nome: "Sant'Olcese",
    codice: '010055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I346',
    cap: ['16010'],
    popolazione: 5911,
  },
  {
    nome: "Sant'Omero",
    codice: '067039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'I348',
    cap: ['64027'],
    popolazione: 5313,
  },
  {
    nome: "Sant'Omobono Terme",
    codice: '016252',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M333',
    cap: ['24038'],
    popolazione: 3893,
  },
  {
    nome: "Sant'Onofrio",
    codice: '102036',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I350',
    cap: ['89843'],
    popolazione: 3148,
  },
  {
    nome: "Sant'Oreste",
    codice: '058099',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I352',
    cap: ['00060'],
    popolazione: 3702,
  },
  {
    nome: "Sant'Orsola Terme",
    codice: '022168',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I354',
    cap: ['38050'],
    popolazione: 1073,
  },
  {
    nome: "Sant'Urbano",
    codice: '028084',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I375',
    cap: ['35040'],
    popolazione: 2162,
  },
  {
    nome: 'Santa Brigida',
    codice: '016191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I168',
    cap: ['24010'],
    popolazione: 597,
  },
  {
    nome: 'Santa Caterina Albanese',
    codice: '078129',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I171',
    cap: ['87010'],
    popolazione: 1244,
  },
  {
    nome: 'Santa Caterina dello Ionio',
    codice: '079117',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I170',
    cap: ['88060'],
    popolazione: 2142,
  },
  {
    nome: 'Santa Caterina Villarmosa',
    codice: '085017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'I169',
    cap: ['93018'],
    popolazione: 5727,
  },
  {
    nome: 'Santa Cesarea Terme',
    codice: '075072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I172',
    cap: ['73020'],
    popolazione: 3032,
  },
  {
    nome: "Santa Cristina d'Aspromonte",
    codice: '080078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I176',
    cap: ['89056'],
    popolazione: 1017,
  },
  {
    nome: 'Santa Cristina e Bissone',
    codice: '018139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I175',
    cap: ['27010'],
    popolazione: 2028,
  },
  {
    nome: 'Santa Cristina Gela',
    codice: '082066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'I174',
    cap: ['90030'],
    popolazione: 925,
  },
  {
    nome: 'Santa Cristina Valgardena',
    codice: '021085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I173',
    cap: ['39047'],
    popolazione: 1871,
  },
  {
    nome: 'Santa Croce Camerina',
    codice: '088010',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'I178',
    cap: ['97017'],
    popolazione: 9452,
  },
  {
    nome: 'Santa Croce del Sannio',
    codice: '062069',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I179',
    cap: ['82020'],
    popolazione: 985,
  },
  {
    nome: 'Santa Croce di Magliano',
    codice: '070072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I181',
    cap: ['86047'],
    popolazione: 4692,
  },
  {
    nome: "Santa Croce sull'Arno",
    codice: '050033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'I177',
    cap: ['56029'],
    popolazione: 14061,
  },
  {
    nome: 'Santa Domenica Talao',
    codice: '078130',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I183',
    cap: ['87020'],
    popolazione: 1272,
  },
  {
    nome: 'Santa Domenica Vittoria',
    codice: '083083',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I184',
    cap: ['98030'],
    popolazione: 1067,
  },
  {
    nome: 'Santa Elisabetta',
    codice: '084037',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'I185',
    cap: ['92020'],
    popolazione: 2608,
  },
  {
    nome: 'Santa Fiora',
    codice: '053022',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'I187',
    cap: ['58037'],
    popolazione: 2702,
  },
  {
    nome: 'Santa Flavia',
    codice: '082067',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'I188',
    cap: ['90017'],
    popolazione: 10751,
  },
  {
    nome: 'Santa Giuletta',
    codice: '018140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I203',
    cap: ['27046'],
    popolazione: 1685,
  },
  {
    nome: 'Santa Giusta',
    codice: '095047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I205',
    cap: ['09096'],
    popolazione: 4811,
  },
  {
    nome: 'Santa Giustina',
    codice: '025048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I206',
    cap: ['32035'],
    popolazione: 6767,
  },
  {
    nome: 'Santa Giustina in Colle',
    codice: '028080',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I207',
    cap: ['35010'],
    popolazione: 7131,
  },
  {
    nome: 'Santa Luce',
    codice: '050034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'I217',
    cap: ['56040'],
    popolazione: 1737,
  },
  {
    nome: 'Santa Lucia del Mela',
    codice: '083086',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I220',
    cap: ['98046'],
    popolazione: 4744,
  },
  {
    nome: 'Santa Lucia di Piave',
    codice: '026075',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I221',
    cap: ['31025'],
    popolazione: 9081,
  },
  {
    nome: 'Santa Lucia di Serino',
    codice: '064088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I219',
    cap: ['83020'],
    popolazione: 1446,
  },
  {
    nome: 'Santa Margherita di Belice',
    codice: '084038',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'I224',
    cap: ['92018'],
    popolazione: 6544,
  },
  {
    nome: 'Santa Margherita di Staffora',
    codice: '018142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I230',
    cap: ['27050'],
    popolazione: 513,
  },
  {
    nome: 'Santa Margherita Ligure',
    codice: '010054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I225',
    cap: ['16038'],
    popolazione: 9709,
  },
  {
    nome: 'Santa Maria a Monte',
    codice: '050035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'I232',
    cap: ['56020'],
    popolazione: 12847,
  },
  {
    nome: 'Santa Maria a Vico',
    codice: '061082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I233',
    cap: ['81028'],
    popolazione: 14134,
  },
  {
    nome: 'Santa Maria Capua Vetere',
    codice: '061083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I234',
    cap: ['81055'],
    popolazione: 32503,
  },
  {
    nome: 'Santa Maria Coghinas',
    codice: '090087',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M284',
    cap: ['07030'],
    popolazione: 1430,
  },
  {
    nome: 'Santa Maria del Cedro',
    codice: '078132',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'C717',
    cap: ['87020'],
    popolazione: 4897,
  },
  {
    nome: 'Santa Maria del Molise',
    codice: '094045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I238',
    cap: ['86096'],
    popolazione: 635,
  },
  {
    nome: 'Santa Maria della Versa',
    codice: '018143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I237',
    cap: ['27047'],
    popolazione: 2476,
  },
  {
    nome: 'Santa Maria di Licodia',
    codice: '087047',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I240',
    cap: ['95038'],
    popolazione: 7322,
  },
  {
    nome: 'Santa Maria di Sala',
    codice: '027035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'I242',
    cap: ['30036'],
    popolazione: 17295,
  },
  {
    nome: 'Santa Maria Hoè',
    codice: '097074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'I243',
    cap: ['23889'],
    popolazione: 2207,
  },
  {
    nome: 'Santa Maria Imbaro',
    codice: '069084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'I244',
    cap: ['66030'],
    popolazione: 1830,
  },
  {
    nome: 'Santa Maria la Carità',
    codice: '063090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'M273',
    cap: ['80050'],
    popolazione: 11726,
  },
  {
    nome: 'Santa Maria la Fossa',
    codice: '061084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I247',
    cap: ['81050'],
    popolazione: 2682,
  },
  {
    nome: 'Santa Maria la Longa',
    codice: '030104',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I248',
    cap: ['33050'],
    popolazione: 2417,
  },
  {
    nome: 'Santa Maria Maggiore',
    codice: '103062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'I249',
    cap: ['28857'],
    popolazione: 1264,
  },
  {
    nome: 'Santa Maria Nuova',
    codice: '042043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I251',
    cap: ['60030'],
    popolazione: 4199,
  },
  {
    nome: 'Santa Marina',
    codice: '065127',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I253',
    cap: ['84067'],
    popolazione: 3166,
  },
  {
    nome: 'Santa Marina Salina',
    codice: '083087',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I254',
    cap: ['98050'],
    popolazione: 892,
  },
  {
    nome: 'Santa Marinella',
    codice: '058097',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I255',
    cap: ['00058'],
    popolazione: 17403,
  },
  {
    nome: 'Santa Ninfa',
    codice: '081019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'I291',
    cap: ['91029'],
    popolazione: 5095,
  },
  {
    nome: 'Santa Paolina',
    codice: '064093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I301',
    cap: ['83030'],
    popolazione: 1366,
  },
  {
    nome: 'Santa Severina',
    codice: '101022',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'I308',
    cap: ['88832'],
    popolazione: 2262,
  },
  {
    nome: 'Santa Sofia',
    codice: '040043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'I310',
    cap: ['47018'],
    popolazione: 4193,
  },
  {
    nome: "Santa Sofia d'Epiro",
    codice: '078133',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I309',
    cap: ['87048'],
    popolazione: 2748,
  },
  {
    nome: 'Santa Teresa di Riva',
    codice: '083089',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I311',
    cap: ['98028'],
    popolazione: 9240,
  },
  {
    nome: 'Santa Teresa Gallura',
    codice: '090063',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I312',
    cap: ['07028'],
    popolazione: 5018,
  },
  {
    nome: 'Santa Venerina',
    codice: '087048',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I314',
    cap: ['95010'],
    popolazione: 8351,
  },
  {
    nome: "Santa Vittoria d'Alba",
    codice: '004212',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I316',
    cap: ['12069'],
    popolazione: 2748,
  },
  {
    nome: 'Santa Vittoria in Matenano',
    codice: '109036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'I315',
    cap: ['63854'],
    popolazione: 1422,
  },
  {
    nome: 'Santadi',
    codice: '111068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I182',
    cap: ['09010'],
    popolazione: 3570,
  },
  {
    nome: 'Santarcangelo di Romagna',
    codice: '099018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'I304',
    cap: ['47822'],
    popolazione: 20839,
  },
  {
    nome: 'Sante Marie',
    codice: '066089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I326',
    cap: ['67067'],
    popolazione: 1208,
  },
  {
    nome: 'Santena',
    codice: '001257',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I327',
    cap: ['10026'],
    popolazione: 10738,
  },
  {
    nome: 'Santeramo in Colle',
    codice: '072041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'I330',
    cap: ['70029'],
    popolazione: 26770,
  },
  {
    nome: 'Santhià',
    codice: '002133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'I337',
    cap: ['13048'],
    popolazione: 8825,
  },
  {
    nome: 'Santi Cosma e Damiano',
    codice: '059026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'I339',
    cap: ['04020'],
    popolazione: 6882,
  },
  {
    nome: 'Santo Stefano al Mare',
    codice: '008056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'I365',
    cap: ['18010'],
    popolazione: 2239,
  },
  {
    nome: 'Santo Stefano Belbo',
    codice: '004213',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I367',
    cap: ['12058'],
    popolazione: 4255,
  },
  {
    nome: "Santo Stefano d'Aveto",
    codice: '010056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I368',
    cap: ['16049'],
    popolazione: 1217,
  },
  {
    nome: 'Santo Stefano del Sole',
    codice: '064095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I357',
    cap: ['83050'],
    popolazione: 2189,
  },
  {
    nome: 'Santo Stefano di Cadore',
    codice: '025050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'C919',
    cap: ['32045'],
    popolazione: 2663,
  },
  {
    nome: 'Santo Stefano di Camastra',
    codice: '083091',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I370',
    cap: ['98077'],
    popolazione: 4674,
  },
  {
    nome: 'Santo Stefano di Magra',
    codice: '011026',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'I363',
    cap: ['19037'],
    popolazione: 8790,
  },
  {
    nome: 'Santo Stefano di Rogliano',
    codice: '078134',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I359',
    cap: ['87056'],
    popolazione: 1640,
  },
  {
    nome: 'Santo Stefano di Sessanio',
    codice: '066091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I360',
    cap: ['67020'],
    popolazione: 111,
  },
  {
    nome: 'Santo Stefano in Aspromonte',
    codice: '080083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I371',
    cap: ['89057'],
    popolazione: 1247,
  },
  {
    nome: 'Santo Stefano Lodigiano',
    codice: '098051',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I362',
    cap: ['26849'],
    popolazione: 1902,
  },
  {
    nome: 'Santo Stefano Quisquina',
    codice: '084040',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'I356',
    cap: ['92020'],
    popolazione: 4897,
  },
  {
    nome: 'Santo Stefano Roero',
    codice: '004214',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I372',
    cap: ['12040'],
    popolazione: 1407,
  },
  {
    nome: 'Santo Stefano Ticino',
    codice: '015200',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I361',
    cap: ['20010'],
    popolazione: 4801,
  },
  {
    nome: 'Santomenna',
    codice: '065131',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I260',
    cap: ['84020'],
    popolazione: 473,
  },
  {
    nome: 'Santopadre',
    codice: '060069',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I351',
    cap: ['03030'],
    popolazione: 1410,
  },
  {
    nome: 'Santorso',
    codice: '024095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I353',
    cap: ['36014'],
    popolazione: 5746,
  },
  {
    nome: 'Santu Lussurgiu',
    codice: '095049',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I374',
    cap: ['09075'],
    popolazione: 2440,
  },
  {
    nome: 'Sanza',
    codice: '065133',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I410',
    cap: ['84030'],
    popolazione: 2697,
  },
  {
    nome: 'Sanzeno',
    codice: '022169',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I411',
    cap: ['38010'],
    popolazione: 928,
  },
  {
    nome: 'Saonara',
    codice: '028085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I418',
    cap: ['35020'],
    popolazione: 10043,
  },
  {
    nome: 'Saponara',
    codice: '083092',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I420',
    cap: ['98047'],
    popolazione: 4078,
  },
  {
    nome: 'Sappada',
    codice: '030189',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I421',
    cap: ['33012'],
    popolazione: 1306,
  },
  {
    nome: 'Sapri',
    codice: '065134',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I422',
    cap: ['84073'],
    popolazione: 6809,
  },
  {
    nome: 'Saracena',
    codice: '078136',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I423',
    cap: ['87010'],
    popolazione: 3964,
  },
  {
    nome: 'Saracinesco',
    codice: '058101',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I424',
    cap: ['00020'],
    popolazione: 184,
  },
  {
    nome: 'Sarcedo',
    codice: '024097',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I425',
    cap: ['36030'],
    popolazione: 5303,
  },
  {
    nome: 'Sarconi',
    codice: '076081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I426',
    cap: ['85050'],
    popolazione: 1362,
  },
  {
    nome: 'Sardara',
    codice: '111072',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I428',
    cap: ['09030'],
    popolazione: 4168,
  },
  {
    nome: 'Sardigliano',
    codice: '006157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I429',
    cap: ['15060'],
    popolazione: 452,
  },
  {
    nome: 'Sarego',
    codice: '024098',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I430',
    cap: ['36040'],
    popolazione: 6641,
  },
  {
    nome: 'Sarentino',
    codice: '021086',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I431',
    cap: ['39058'],
    popolazione: 6890,
  },
  {
    nome: 'Sarezzano',
    codice: '006158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I432',
    cap: ['15050'],
    popolazione: 1193,
  },
  {
    nome: 'Sarezzo',
    codice: '017174',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I433',
    cap: ['25068'],
    popolazione: 13469,
  },
  {
    nome: 'Sarmato',
    codice: '033042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'I434',
    cap: ['29010'],
    popolazione: 2919,
  },
  {
    nome: 'Sarmede',
    codice: '026078',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I435',
    cap: ['31026'],
    popolazione: 3174,
  },
  {
    nome: 'Sarnano',
    codice: '043049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'I436',
    cap: ['62028'],
    popolazione: 3367,
  },
  {
    nome: 'Sarnico',
    codice: '016193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I437',
    cap: ['24067'],
    popolazione: 6390,
  },
  {
    nome: 'Sarno',
    codice: '065135',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I438',
    cap: ['84087'],
    popolazione: 31030,
  },
  {
    nome: 'Sarnonico',
    codice: '022170',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I439',
    cap: ['38011'],
    popolazione: 749,
  },
  {
    nome: 'Saronno',
    codice: '012119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'I441',
    cap: ['21047'],
    popolazione: 38598,
  },
  {
    nome: 'Sarre',
    codice: '007066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'I442',
    cap: ['11010'],
    popolazione: 4857,
  },
  {
    nome: 'Sarroch',
    codice: '092066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'I443',
    cap: ['09018'],
    popolazione: 5198,
  },
  {
    nome: 'Sarsina',
    codice: '040044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'I444',
    cap: ['47027'],
    popolazione: 3602,
  },
  {
    nome: 'Sarteano',
    codice: '052031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'I445',
    cap: ['53047'],
    popolazione: 4741,
  },
  {
    nome: 'Sartirana Lomellina',
    codice: '018146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I447',
    cap: ['27020'],
    popolazione: 1760,
  },
  {
    nome: 'Sarule',
    codice: '091077',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'I448',
    cap: ['08020'],
    popolazione: 1770,
  },
  {
    nome: 'Sarzana',
    codice: '011027',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'I449',
    cap: ['19038'],
    popolazione: 21829,
  },
  {
    nome: 'Sassano',
    codice: '065136',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I451',
    cap: ['84038'],
    popolazione: 4995,
  },
  {
    nome: 'Sassari',
    codice: '090064',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I452',
    cap: ['07100'],
    popolazione: 123782,
  },
  {
    nome: 'Sassello',
    codice: '009055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'I453',
    cap: ['17046'],
    popolazione: 1882,
  },
  {
    nome: 'Sassetta',
    codice: '049019',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'I454',
    cap: ['57020'],
    popolazione: 533,
  },
  {
    nome: 'Sassinoro',
    codice: '062072',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I455',
    cap: ['82026'],
    popolazione: 659,
  },
  {
    nome: 'Sasso di Castalda',
    codice: '076082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I457',
    cap: ['85050'],
    popolazione: 831,
  },
  {
    nome: 'Sasso Marconi',
    codice: '037057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'G972',
    cap: ['40037'],
    popolazione: 14545,
  },
  {
    nome: 'Sassocorvaro Auditore',
    codice: '041071',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'M413',
    cap: ['61028'],
    popolazione: 5080,
  },
  {
    nome: 'Sassofeltrio',
    codice: '041060',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'I460',
    cap: ['61013'],
    popolazione: 1445,
  },
  {
    nome: 'Sassoferrato',
    codice: '042044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I461',
    cap: ['60041'],
    popolazione: 7532,
  },
  {
    nome: 'Sassuolo',
    codice: '036040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I462',
    cap: ['41049'],
    popolazione: 39885,
  },
  {
    nome: 'Satriano',
    codice: '079123',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I463',
    cap: ['88060'],
    popolazione: 3314,
  },
  {
    nome: 'Satriano di Lucania',
    codice: '076083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'G614',
    cap: ['85050'],
    popolazione: 2406,
  },
  {
    nome: 'Sauris',
    codice: '030107',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I464',
    cap: ['33020'],
    popolazione: 419,
  },
  {
    nome: "Sauze d'Oulx",
    codice: '001259',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I466',
    cap: ['10050'],
    popolazione: 1111,
  },
  {
    nome: 'Sauze di Cesana',
    codice: '001258',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I465',
    cap: ['10054'],
    popolazione: 219,
  },
  {
    nome: 'Sava',
    codice: '073026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'I467',
    cap: ['74028'],
    popolazione: 16501,
  },
  {
    nome: 'Savelli',
    codice: '101023',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'I468',
    cap: ['88825'],
    popolazione: 1321,
  },
  {
    nome: 'Saviano',
    codice: '063076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I469',
    cap: ['80039'],
    popolazione: 15488,
  },
  {
    nome: 'Savigliano',
    codice: '004215',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I470',
    cap: ['12038'],
    popolazione: 20935,
  },
  {
    nome: 'Savignano Irpino',
    codice: '064096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I471',
    cap: ['83030'],
    popolazione: 1163,
  },
  {
    nome: 'Savignano sul Panaro',
    codice: '036041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I473',
    cap: ['41056'],
    popolazione: 9276,
  },
  {
    nome: 'Savignano sul Rubicone',
    codice: '040045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'I472',
    cap: ['47039'],
    popolazione: 17521,
  },
  {
    nome: 'Savignone',
    codice: '010057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I475',
    cap: ['16010'],
    popolazione: 3226,
  },
  {
    nome: "Saviore dell'Adamello",
    codice: '017175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I476',
    cap: ['25040'],
    popolazione: 992,
  },
  {
    nome: 'Savoca',
    codice: '083093',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I477',
    cap: ['98038'],
    popolazione: 1766,
  },
  {
    nome: 'Savogna',
    codice: '030108',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I478',
    cap: ['33040'],
    popolazione: 482,
  },
  {
    nome: "Savogna d'Isonzo",
    codice: '031022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'I479',
    cap: ['34070'],
    popolazione: 1727,
  },
  {
    nome: 'Savoia di Lucania',
    codice: '076084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'H730',
    cap: ['85050'],
    popolazione: 1148,
  },
  {
    nome: 'Savona',
    codice: '009056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'I480',
    cap: ['17100'],
    popolazione: 60661,
  },
  {
    nome: 'Scafa',
    codice: '068039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'I482',
    cap: ['65027'],
    popolazione: 3836,
  },
  {
    nome: 'Scafati',
    codice: '065137',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I483',
    cap: ['84018'],
    popolazione: 50013,
  },
  {
    nome: 'Scagnello',
    codice: '004216',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I484',
    cap: ['12070'],
    popolazione: 207,
  },
  {
    nome: 'Scala',
    codice: '065138',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I486',
    cap: ['84010'],
    popolazione: 1518,
  },
  {
    nome: 'Scala Coeli',
    codice: '078137',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I485',
    cap: ['87060'],
    popolazione: 1141,
  },
  {
    nome: 'Scaldasole',
    codice: '018147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I487',
    cap: ['27020'],
    popolazione: 967,
  },
  {
    nome: 'Scalea',
    codice: '078138',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I489',
    cap: ['87029'],
    popolazione: 10152,
  },
  {
    nome: 'Scalenghe',
    codice: '001260',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I490',
    cap: ['10060'],
    popolazione: 3303,
  },
  {
    nome: 'Scaletta Zanclea',
    codice: '083094',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I492',
    cap: ['98029'],
    popolazione: 2249,
  },
  {
    nome: 'Scampitella',
    codice: '064097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I493',
    cap: ['83050'],
    popolazione: 1344,
  },
  {
    nome: 'Scandale',
    codice: '101024',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'I494',
    cap: ['88831'],
    popolazione: 3326,
  },
  {
    nome: 'Scandiano',
    codice: '035040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'I496',
    cap: ['42019'],
    popolazione: 24792,
  },
  {
    nome: 'Scandicci',
    codice: '048041',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'B962',
    cap: ['50018'],
    popolazione: 49765,
  },
  {
    nome: 'Scandolara Ravara',
    codice: '019092',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I497',
    cap: ['26040'],
    popolazione: 1466,
  },
  {
    nome: "Scandolara Ripa d'Oglio",
    codice: '019093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I498',
    cap: ['26047'],
    popolazione: 623,
  },
  {
    nome: 'Scandriglia',
    codice: '057064',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'I499',
    cap: ['02038'],
    popolazione: 2934,
  },
  {
    nome: 'Scanno',
    codice: '066093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I501',
    cap: ['67038'],
    popolazione: 1948,
  },
  {
    nome: 'Scano di Montiferro',
    codice: '095051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I503',
    cap: ['09078'],
    popolazione: 1580,
  },
  {
    nome: 'Scansano',
    codice: '053023',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'I504',
    cap: ['58054'],
    popolazione: 4534,
  },
  {
    nome: 'Scanzano Jonico',
    codice: '077031',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'M256',
    cap: ['75020'],
    popolazione: 7171,
  },
  {
    nome: 'Scanzorosciate',
    codice: '016194',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I506',
    cap: ['24020'],
    popolazione: 9835,
  },
  {
    nome: 'Scapoli',
    codice: '094048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I507',
    cap: ['86070'],
    popolazione: 758,
  },
  {
    nome: 'Scarlino',
    codice: '053024',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'I510',
    cap: ['58020'],
    popolazione: 3699,
  },
  {
    nome: 'Scarmagno',
    codice: '001261',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I511',
    cap: ['10010'],
    popolazione: 812,
  },
  {
    nome: 'Scarnafigi',
    codice: '004217',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I512',
    cap: ['12030'],
    popolazione: 2094,
  },
  {
    nome: 'Scarperia e San Piero',
    codice: '048053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'M326',
    cap: ['50038'],
    popolazione: 11968,
  },
  {
    nome: 'Scena',
    codice: '021087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I519',
    cap: ['39017'],
    popolazione: 2838,
  },
  {
    nome: 'Scerni',
    codice: '069087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'I520',
    cap: ['66020'],
    popolazione: 3399,
  },
  {
    nome: 'Scheggia e Pascelupo',
    codice: '054046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I522',
    cap: ['06027'],
    popolazione: 1442,
  },
  {
    nome: 'Scheggino',
    codice: '054047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I523',
    cap: ['06040'],
    popolazione: 481,
  },
  {
    nome: 'Schiavi di Abruzzo',
    codice: '069088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'I526',
    cap: ['66045'],
    popolazione: 931,
  },
  {
    nome: 'Schiavon',
    codice: '024099',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I527',
    cap: ['36060'],
    popolazione: 2600,
  },
  {
    nome: 'Schignano',
    codice: '013211',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I529',
    cap: ['22020'],
    popolazione: 874,
  },
  {
    nome: 'Schilpario',
    codice: '016195',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I530',
    cap: ['24020'],
    popolazione: 1250,
  },
  {
    nome: 'Schio',
    codice: '024100',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I531',
    cap: ['36015'],
    popolazione: 39131,
  },
  {
    nome: 'Schivenoglia',
    codice: '020060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'I532',
    cap: ['46020'],
    popolazione: 1240,
  },
  {
    nome: 'Sciacca',
    codice: '084041',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'I533',
    cap: ['92019'],
    popolazione: 40899,
  },
  {
    nome: 'Sciara',
    codice: '082068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'I534',
    cap: ['90020'],
    popolazione: 2787,
  },
  {
    nome: 'Scicli',
    codice: '088011',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'I535',
    cap: ['97018'],
    popolazione: 25922,
  },
  {
    nome: 'Scido',
    codice: '080084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I536',
    cap: ['89010'],
    popolazione: 976,
  },
  {
    nome: 'Scigliano',
    codice: '078139',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'D290',
    cap: ['87057'],
    popolazione: 1308,
  },
  {
    nome: 'Scilla',
    codice: '080085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I537',
    cap: ['89058'],
    popolazione: 5115,
  },
  {
    nome: 'Scillato',
    codice: '082081',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'I538',
    cap: ['90020'],
    popolazione: 627,
  },
  {
    nome: 'Sciolze',
    codice: '001262',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I539',
    cap: ['10090'],
    popolazione: 1513,
  },
  {
    nome: 'Scisciano',
    codice: '063077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I540',
    cap: ['80030'],
    popolazione: 5775,
  },
  {
    nome: 'Sclafani Bagni',
    codice: '082069',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'I541',
    cap: ['90020'],
    popolazione: 450,
  },
  {
    nome: 'Scontrone',
    codice: '066094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I543',
    cap: ['67030'],
    popolazione: 590,
  },
  {
    nome: 'Scopa',
    codice: '002134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'I544',
    cap: ['13027'],
    popolazione: 391,
  },
  {
    nome: 'Scopello',
    codice: '002135',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'I545',
    cap: ['13028'],
    popolazione: 402,
  },
  {
    nome: 'Scoppito',
    codice: '066095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I546',
    cap: ['67019'],
    popolazione: 3285,
  },
  {
    nome: 'Scordia',
    codice: '087049',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'I548',
    cap: ['95048'],
    popolazione: 17185,
  },
  {
    nome: 'Scorrano',
    codice: '075073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I549',
    cap: ['73020'],
    popolazione: 6975,
  },
  {
    nome: 'Scorzè',
    codice: '027037',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'I551',
    cap: ['30037'],
    popolazione: 18904,
  },
  {
    nome: 'Scurcola Marsicana',
    codice: '066096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I553',
    cap: ['67068'],
    popolazione: 2762,
  },
  {
    nome: 'Scurelle',
    codice: '022171',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I554',
    cap: ['38050'],
    popolazione: 1401,
  },
  {
    nome: 'Scurzolengo',
    codice: '005103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I555',
    cap: ['14030'],
    popolazione: 596,
  },
  {
    nome: 'Seborga',
    codice: '008057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'I556',
    cap: ['18012'],
    popolazione: 323,
  },
  {
    nome: 'Secinaro',
    codice: '066097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I558',
    cap: ['67029'],
    popolazione: 383,
  },
  {
    nome: 'Seclì',
    codice: '075074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I559',
    cap: ['73050'],
    popolazione: 1923,
  },
  {
    nome: 'Secugnago',
    codice: '098052',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I561',
    cap: ['26826'],
    popolazione: 1984,
  },
  {
    nome: 'Sedegliano',
    codice: '030109',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I562',
    cap: ['33039'],
    popolazione: 3937,
  },
  {
    nome: 'Sedico',
    codice: '025053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I563',
    cap: ['32036'],
    popolazione: 9906,
  },
  {
    nome: 'Sedilo',
    codice: '095052',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I564',
    cap: ['09076'],
    popolazione: 2216,
  },
  {
    nome: 'Sedini',
    codice: '090065',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I565',
    cap: ['07035'],
    popolazione: 1378,
  },
  {
    nome: 'Sedriano',
    codice: '015204',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I566',
    cap: ['20018'],
    popolazione: 11270,
  },
  {
    nome: 'Sedrina',
    codice: '016196',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I567',
    cap: ['24010'],
    popolazione: 2507,
  },
  {
    nome: 'Sefro',
    codice: '043050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'I569',
    cap: ['62025'],
    popolazione: 431,
  },
  {
    nome: 'Segariu',
    codice: '111073',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I570',
    cap: ['09040'],
    popolazione: 1277,
  },
  {
    nome: 'Seggiano',
    codice: '053025',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'I571',
    cap: ['58038'],
    popolazione: 1004,
  },
  {
    nome: 'Segni',
    codice: '058102',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I573',
    cap: ['00037'],
    popolazione: 9101,
  },
  {
    nome: 'Segonzano',
    codice: '022172',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I576',
    cap: ['38047'],
    popolazione: 1531,
  },
  {
    nome: 'Segrate',
    codice: '015205',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I577',
    cap: ['20090'],
    popolazione: 33519,
  },
  {
    nome: 'Segusino',
    codice: '026079',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I578',
    cap: ['31040'],
    popolazione: 1941,
  },
  {
    nome: 'Selargius',
    codice: '092068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'I580',
    cap: ['09047'],
    popolazione: 28684,
  },
  {
    nome: 'Selci',
    codice: '057065',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'I581',
    cap: ['02040'],
    popolazione: 1106,
  },
  {
    nome: 'Selegas',
    codice: '111074',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I582',
    cap: ['09040'],
    popolazione: 1433,
  },
  {
    nome: 'Sella Giudicarie',
    codice: '022246',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M360',
    cap: ['38087'],
    popolazione: 2918,
  },
  {
    nome: 'Sellano',
    codice: '054048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I585',
    cap: ['06030'],
    popolazione: 1140,
  },
  {
    nome: 'Sellero',
    codice: '017176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I588',
    cap: ['25050'],
    popolazione: 1506,
  },
  {
    nome: 'Sellia',
    codice: '079126',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I589',
    cap: ['88050'],
    popolazione: 511,
  },
  {
    nome: 'Sellia Marina',
    codice: '079127',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I590',
    cap: ['88050'],
    popolazione: 6987,
  },
  {
    nome: 'Selva dei Molini',
    codice: '021088',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I593',
    cap: ['39030'],
    popolazione: 1473,
  },
  {
    nome: 'Selva di Cadore',
    codice: '025054',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I592',
    cap: ['32020'],
    popolazione: 517,
  },
  {
    nome: 'Selva di Progno',
    codice: '023080',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I594',
    cap: ['37030'],
    popolazione: 934,
  },
  {
    nome: 'Selva di Val Gardena',
    codice: '021089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I591',
    cap: ['39048'],
    popolazione: 2660,
  },
  {
    nome: 'Selvazzano Dentro',
    codice: '028086',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I595',
    cap: ['35030'],
    popolazione: 22145,
  },
  {
    nome: 'Selvino',
    codice: '016197',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I597',
    cap: ['24020'],
    popolazione: 1991,
  },
  {
    nome: 'Semestene',
    codice: '090066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I598',
    cap: ['07010'],
    popolazione: 171,
  },
  {
    nome: 'Semiana',
    codice: '018148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I599',
    cap: ['27020'],
    popolazione: 257,
  },
  {
    nome: 'Seminara',
    codice: '080086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I600',
    cap: ['89028'],
    popolazione: 2820,
  },
  {
    nome: 'Semproniano',
    codice: '053028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'I601',
    cap: ['58055'],
    popolazione: 1144,
  },
  {
    nome: 'Senago',
    codice: '015206',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I602',
    cap: ['20030'],
    popolazione: 20914,
  },
  {
    nome: 'Senale-San Felice',
    codice: '021118',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I603',
    cap: ['39010'],
    popolazione: 782,
  },
  {
    nome: 'Senales',
    codice: '021091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I604',
    cap: ['39020'],
    popolazione: 1314,
  },
  {
    nome: 'Seneghe',
    codice: '095053',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I605',
    cap: ['09070'],
    popolazione: 1847,
  },
  {
    nome: 'Senerchia',
    codice: '064098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I606',
    cap: ['83050'],
    popolazione: 1014,
  },
  {
    nome: 'Seniga',
    codice: '017177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I607',
    cap: ['25020'],
    popolazione: 1581,
  },
  {
    nome: 'Senigallia',
    codice: '042045',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I608',
    cap: ['60019'],
    popolazione: 44361,
  },
  {
    nome: 'Senis',
    codice: '095054',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I609',
    cap: ['09080'],
    popolazione: 479,
  },
  {
    nome: 'Senise',
    codice: '076085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I610',
    cap: ['85038'],
    popolazione: 7127,
  },
  {
    nome: 'Senna Comasco',
    codice: '013212',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I611',
    cap: ['22070'],
    popolazione: 3171,
  },
  {
    nome: 'Senna Lodigiana',
    codice: '098053',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I612',
    cap: ['26856'],
    popolazione: 1997,
  },
  {
    nome: 'Sennariolo',
    codice: '095055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I613',
    cap: ['09078'],
    popolazione: 183,
  },
  {
    nome: 'Sennori',
    codice: '090067',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I614',
    cap: ['07036'],
    popolazione: 7375,
  },
  {
    nome: 'Senorbì',
    codice: '111075',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I615',
    cap: ['09040'],
    popolazione: 4781,
  },
  {
    nome: 'Sepino',
    codice: '070075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I618',
    cap: ['86017'],
    popolazione: 1985,
  },
  {
    nome: 'Sequals',
    codice: '093042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'I621',
    cap: ['33090'],
    popolazione: 2221,
  },
  {
    nome: 'Seravezza',
    codice: '046028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'I622',
    cap: ['55047'],
    popolazione: 13238,
  },
  {
    nome: 'Serdiana',
    codice: '111076',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I624',
    cap: ['09040'],
    popolazione: 2620,
  },
  {
    nome: 'Seregno',
    codice: '108039',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'I625',
    cap: ['20831'],
    popolazione: 43001,
  },
  {
    nome: 'Seren del Grappa',
    codice: '025055',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I626',
    cap: ['32030'],
    popolazione: 2557,
  },
  {
    nome: 'Sergnano',
    codice: '019094',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I627',
    cap: ['26010'],
    popolazione: 3631,
  },
  {
    nome: 'Seriate',
    codice: '016198',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I628',
    cap: ['24068'],
    popolazione: 24336,
  },
  {
    nome: 'Serina',
    codice: '016199',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I629',
    cap: ['24017'],
    popolazione: 2165,
  },
  {
    nome: 'Serino',
    codice: '064099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I630',
    cap: ['83028'],
    popolazione: 7129,
  },
  {
    nome: 'Serle',
    codice: '017178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I631',
    cap: ['25080'],
    popolazione: 3092,
  },
  {
    nome: 'Sermide e Felonica',
    codice: '020061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'I632',
    cap: ['46028'],
    popolazione: 7726,
  },
  {
    nome: 'Sermoneta',
    codice: '059027',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'I634',
    cap: ['04013'],
    popolazione: 9129,
  },
  {
    nome: 'Sernaglia della Battaglia',
    codice: '026080',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I635',
    cap: ['31020'],
    popolazione: 6325,
  },
  {
    nome: 'Sernio',
    codice: '014059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'I636',
    cap: ['23030'],
    popolazione: 500,
  },
  {
    nome: 'Serole',
    codice: '005104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I637',
    cap: ['14050'],
    popolazione: 142,
  },
  {
    nome: "Serra d'Aiello",
    codice: '078140',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I642',
    cap: ['87030'],
    popolazione: 549,
  },
  {
    nome: "Serra de' Conti",
    codice: '042046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I643',
    cap: ['60030'],
    popolazione: 3722,
  },
  {
    nome: 'Serra Riccò',
    codice: '010058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I640',
    cap: ['16010'],
    popolazione: 7931,
  },
  {
    nome: 'Serra San Bruno',
    codice: '102037',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I639',
    cap: ['89822'],
    popolazione: 6850,
  },
  {
    nome: 'Serra San Quirico',
    codice: '042047',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I653',
    cap: ['60048'],
    popolazione: 2967,
  },
  {
    nome: "Serra Sant'Abbondio",
    codice: '041061',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'I654',
    cap: ['61040'],
    popolazione: 1099,
  },
  {
    nome: 'Serracapriola',
    codice: '071053',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I641',
    cap: ['71010'],
    popolazione: 4069,
  },
  {
    nome: 'Serradifalco',
    codice: '085018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'I644',
    cap: ['93010'],
    popolazione: 6265,
  },
  {
    nome: "Serralunga d'Alba",
    codice: '004218',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I646',
    cap: ['12050'],
    popolazione: 524,
  },
  {
    nome: 'Serralunga di Crea',
    codice: '006159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I645',
    cap: ['15020'],
    popolazione: 579,
  },
  {
    nome: 'Serramanna',
    codice: '111077',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I647',
    cap: ['09038'],
    popolazione: 9259,
  },
  {
    nome: 'Serramazzoni',
    codice: '036042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'F357',
    cap: ['41028'],
    popolazione: 8014,
  },
  {
    nome: 'Serramezzana',
    codice: '065139',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I648',
    cap: ['84070'],
    popolazione: 347,
  },
  {
    nome: 'Serramonacesca',
    codice: '068040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'I649',
    cap: ['65025'],
    popolazione: 582,
  },
  {
    nome: 'Serrapetrona',
    codice: '043051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'I651',
    cap: ['62020'],
    popolazione: 1008,
  },
  {
    nome: 'Serrara Fontana',
    codice: '063078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I652',
    cap: ['80081'],
    popolazione: 3164,
  },
  {
    nome: 'Serrastretta',
    codice: '079129',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I655',
    cap: ['88040'],
    popolazione: 3249,
  },
  {
    nome: 'Serrata',
    codice: '080087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I656',
    cap: ['89020'],
    popolazione: 914,
  },
  {
    nome: 'Serravalle a Po',
    codice: '020062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'I662',
    cap: ['46030'],
    popolazione: 1593,
  },
  {
    nome: 'Serravalle di Chienti',
    codice: '043052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'I661',
    cap: ['62038'],
    popolazione: 1085,
  },
  {
    nome: 'Serravalle Langhe',
    codice: '004219',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I659',
    cap: ['12050'],
    popolazione: 323,
  },
  {
    nome: 'Serravalle Pistoiese',
    codice: '047020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'I660',
    cap: ['51034'],
    popolazione: 11517,
  },
  {
    nome: 'Serravalle Scrivia',
    codice: '006160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I657',
    cap: ['15069'],
    popolazione: 6322,
  },
  {
    nome: 'Serravalle Sesia',
    codice: '002137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'I663',
    cap: ['13037'],
    popolazione: 5141,
  },
  {
    nome: 'Serre',
    codice: '065140',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I666',
    cap: ['84028'],
    popolazione: 3956,
  },
  {
    nome: 'Serrenti',
    codice: '111078',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I667',
    cap: ['09027'],
    popolazione: 5028,
  },
  {
    nome: 'Serri',
    codice: '111079',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I668',
    cap: ['09063'],
    popolazione: 676,
  },
  {
    nome: 'Serrone',
    codice: '060071',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I669',
    cap: ['03010'],
    popolazione: 3069,
  },
  {
    nome: 'Sersale',
    codice: '079130',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I671',
    cap: ['88054'],
    popolazione: 4767,
  },
  {
    nome: 'Servigliano',
    codice: '109038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'C070',
    cap: ['63839'],
    popolazione: 2347,
  },
  {
    nome: 'Sessa Aurunca',
    codice: '061088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I676',
    cap: ['81037'],
    popolazione: 22216,
  },
  {
    nome: 'Sessa Cilento',
    codice: '065141',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I677',
    cap: ['84074'],
    popolazione: 1366,
  },
  {
    nome: 'Sessame',
    codice: '005105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I678',
    cap: ['14058'],
    popolazione: 284,
  },
  {
    nome: 'Sessano del Molise',
    codice: '094049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I679',
    cap: ['86097'],
    popolazione: 744,
  },
  {
    nome: 'Sesta Godano',
    codice: '011028',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'E070',
    cap: ['19020'],
    popolazione: 1452,
  },
  {
    nome: 'Sestino',
    codice: '051035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'I681',
    cap: ['52038'],
    popolazione: 1421,
  },
  {
    nome: 'Sesto',
    codice: '021092',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I687',
    cap: ['39030'],
    popolazione: 1937,
  },
  {
    nome: 'Sesto al Reghena',
    codice: '093043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'I686',
    cap: ['33079'],
    popolazione: 6319,
  },
  {
    nome: 'Sesto Calende',
    codice: '012120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'I688',
    cap: ['21018'],
    popolazione: 10819,
  },
  {
    nome: 'Sesto Campano',
    codice: '094050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'I682',
    cap: ['86078'],
    popolazione: 2331,
  },
  {
    nome: 'Sesto ed Uniti',
    codice: '019095',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I683',
    cap: ['26028'],
    popolazione: 3075,
  },
  {
    nome: 'Sesto Fiorentino',
    codice: '048043',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'I684',
    cap: ['50019'],
    popolazione: 47742,
  },
  {
    nome: 'Sesto San Giovanni',
    codice: '015209',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I690',
    cap: ['20099'],
    popolazione: 76514,
  },
  {
    nome: 'Sestola',
    codice: '036043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I689',
    cap: ['41029'],
    popolazione: 2602,
  },
  {
    nome: 'Sestri Levante',
    codice: '010059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I693',
    cap: ['16039'],
    popolazione: 18172,
  },
  {
    nome: 'Sestriere',
    codice: '001263',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I692',
    cap: ['10058'],
    popolazione: 838,
  },
  {
    nome: 'Sestu',
    codice: '092074',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'I695',
    cap: ['09028'],
    popolazione: 19893,
  },
  {
    nome: 'Settala',
    codice: '015210',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I696',
    cap: ['20090'],
    popolazione: 7328,
  },
  {
    nome: 'Settefrati',
    codice: '060072',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I697',
    cap: ['03040'],
    popolazione: 792,
  },
  {
    nome: 'Settime',
    codice: '005106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I698',
    cap: ['14020'],
    popolazione: 581,
  },
  {
    nome: 'Settimo Milanese',
    codice: '015211',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I700',
    cap: ['20019'],
    popolazione: 19148,
  },
  {
    nome: 'Settimo Rottaro',
    codice: '001264',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I701',
    cap: ['10010'],
    popolazione: 503,
  },
  {
    nome: 'Settimo San Pietro',
    codice: '092075',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'I699',
    cap: ['09060'],
    popolazione: 6532,
  },
  {
    nome: 'Settimo Torinese',
    codice: '001265',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I703',
    cap: ['10036'],
    popolazione: 46873,
  },
  {
    nome: 'Settimo Vittone',
    codice: '001266',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I702',
    cap: ['10010'],
    popolazione: 1543,
  },
  {
    nome: 'Settingiano',
    codice: '079131',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I704',
    cap: ['88040'],
    popolazione: 2955,
  },
  {
    nome: 'Setzu',
    codice: '111080',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I705',
    cap: ['09029'],
    popolazione: 144,
  },
  {
    nome: 'Seui',
    codice: '111081',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I706',
    cap: ['09064'],
    popolazione: 1361,
  },
  {
    nome: 'Seulo',
    codice: '111082',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I707',
    cap: ['09065'],
    popolazione: 897,
  },
  {
    nome: 'Seveso',
    codice: '108040',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'I709',
    cap: ['20822'],
    popolazione: 22733,
  },
  {
    nome: 'Sezzadio',
    codice: '006161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I711',
    cap: ['15079'],
    popolazione: 1294,
  },
  {
    nome: 'Sezze',
    codice: '059028',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'I712',
    cap: ['04018'],
    popolazione: 24114,
  },
  {
    nome: 'Sfruz',
    codice: '022173',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I714',
    cap: ['38010'],
    popolazione: 323,
  },
  {
    nome: 'Sgonico',
    codice: '032005',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '032', nome: 'Trieste' },
    sigla: 'TS',
    codiceCatastale: 'I715',
    cap: ['34010'],
    popolazione: 2077,
  },
  {
    nome: 'Sgurgola',
    codice: '060073',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I716',
    cap: ['03010'],
    popolazione: 2623,
  },
  {
    nome: 'Siamaggiore',
    codice: '095056',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I717',
    cap: ['09070'],
    popolazione: 970,
  },
  {
    nome: 'Siamanna',
    codice: '095057',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I718',
    cap: ['09080'],
    popolazione: 824,
  },
  {
    nome: 'Siano',
    codice: '065142',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I720',
    cap: ['84088'],
    popolazione: 10074,
  },
  {
    nome: 'Siapiccia',
    codice: '095076',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I721',
    cap: ['09080'],
    popolazione: 370,
  },
  {
    nome: 'Sicignano degli Alburni',
    codice: '065143',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'M253',
    cap: ['84029'],
    popolazione: 3419,
  },
  {
    nome: 'Siculiana',
    codice: '084042',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'I723',
    cap: ['92010'],
    popolazione: 4632,
  },
  {
    nome: 'Siddi',
    codice: '111083',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I724',
    cap: ['09020'],
    popolazione: 696,
  },
  {
    nome: 'Siderno',
    codice: '080088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I725',
    cap: ['89048'],
    popolazione: 16879,
  },
  {
    nome: 'Siena',
    codice: '052032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'I726',
    cap: ['53100'],
    popolazione: 52839,
  },
  {
    nome: 'Sigillo',
    codice: '054049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I727',
    cap: ['06028'],
    popolazione: 2468,
  },
  {
    nome: 'Signa',
    codice: '048044',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'I728',
    cap: ['50058'],
    popolazione: 17451,
  },
  {
    nome: 'Silandro',
    codice: '021093',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I729',
    cap: ['39028'],
    popolazione: 5947,
  },
  {
    nome: 'Silanus',
    codice: '091083',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'I730',
    cap: ['08017'],
    popolazione: 2200,
  },
  {
    nome: 'Silea',
    codice: '026081',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'F116',
    cap: ['31057'],
    popolazione: 9923,
  },
  {
    nome: 'Siligo',
    codice: '090068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I732',
    cap: ['07040'],
    popolazione: 912,
  },
  {
    nome: 'Siliqua',
    codice: '111084',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I734',
    cap: ['09010'],
    popolazione: 3997,
  },
  {
    nome: 'Silius',
    codice: '111085',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I735',
    cap: ['09040'],
    popolazione: 1271,
  },
  {
    nome: 'Sillano Giuncugnano',
    codice: '046037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'M347',
    cap: ['55039'],
    popolazione: 1150,
  },
  {
    nome: 'Sillavengo',
    codice: '003138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I736',
    cap: ['28064'],
    popolazione: 595,
  },
  {
    nome: "Silvano d'Orba",
    codice: '006162',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I738',
    cap: ['15060'],
    popolazione: 2056,
  },
  {
    nome: 'Silvano Pietra',
    codice: '018149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I739',
    cap: ['27050'],
    popolazione: 680,
  },
  {
    nome: 'Silvi',
    codice: '067040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'I741',
    cap: ['64028'],
    popolazione: 15401,
  },
  {
    nome: 'Simala',
    codice: '095058',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I742',
    cap: ['09090'],
    popolazione: 357,
  },
  {
    nome: 'Simaxis',
    codice: '095059',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I743',
    cap: ['09088'],
    popolazione: 2309,
  },
  {
    nome: 'Simbario',
    codice: '102038',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I744',
    cap: ['89822'],
    popolazione: 956,
  },
  {
    nome: 'Simeri Crichi',
    codice: '079133',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I745',
    cap: ['88050'],
    popolazione: 4475,
  },
  {
    nome: 'Sinagra',
    codice: '083095',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I747',
    cap: ['98069'],
    popolazione: 2760,
  },
  {
    nome: 'Sinalunga',
    codice: '052033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'A468',
    cap: ['53048'],
    popolazione: 12476,
  },
  {
    nome: 'Sindia',
    codice: '091084',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'I748',
    cap: ['08018'],
    popolazione: 1811,
  },
  {
    nome: 'Sini',
    codice: '095060',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I749',
    cap: ['09090'],
    popolazione: 515,
  },
  {
    nome: 'Sinio',
    codice: '004220',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I750',
    cap: ['12050'],
    popolazione: 516,
  },
  {
    nome: 'Siniscola',
    codice: '091085',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'I751',
    cap: ['08029'],
    popolazione: 11469,
  },
  {
    nome: 'Sinnai',
    codice: '092080',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'I752',
    cap: ['09048'],
    popolazione: 16730,
  },
  {
    nome: 'Sinopoli',
    codice: '080089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I753',
    cap: ['89020'],
    popolazione: 2154,
  },
  {
    nome: 'Siracusa',
    codice: '089017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'I754',
    cap: ['96100'],
    popolazione: 118385,
  },
  {
    nome: 'Sirignano',
    codice: '064100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I756',
    cap: ['83020'],
    popolazione: 2878,
  },
  {
    nome: 'Siris',
    codice: '095061',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I757',
    cap: ['09090'],
    popolazione: 224,
  },
  {
    nome: 'Sirmione',
    codice: '017179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I633',
    cap: ['25019'],
    popolazione: 7438,
  },
  {
    nome: 'Sirolo',
    codice: '042048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I758',
    cap: ['60020'],
    popolazione: 3856,
  },
  {
    nome: 'Sirone',
    codice: '097075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'I759',
    cap: ['23844'],
    popolazione: 2391,
  },
  {
    nome: 'Sirtori',
    codice: '097076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'I761',
    cap: ['23896'],
    popolazione: 2920,
  },
  {
    nome: 'Sissa Trecasali',
    codice: '034049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'M325',
    cap: ['43018'],
    popolazione: 7991,
  },
  {
    nome: 'Siurgus Donigala',
    codice: '111086',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I765',
    cap: ['09040'],
    popolazione: 2080,
  },
  {
    nome: 'Siziano',
    codice: '018150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'E265',
    cap: ['27010'],
    popolazione: 5807,
  },
  {
    nome: 'Sizzano',
    codice: '003139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I767',
    cap: ['28070'],
    popolazione: 1446,
  },
  {
    nome: 'Sluderno',
    codice: '021094',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I771',
    cap: ['39020'],
    popolazione: 1832,
  },
  {
    nome: 'Smerillo',
    codice: '109039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'I774',
    cap: ['63856'],
    popolazione: 389,
  },
  {
    nome: 'Soave',
    codice: '023081',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I775',
    cap: ['37038'],
    popolazione: 6908,
  },
  {
    nome: 'Socchieve',
    codice: '030110',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I777',
    cap: ['33020'],
    popolazione: 941,
  },
  {
    nome: 'Soddì',
    codice: '095078',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I778',
    cap: ['09080'],
    popolazione: 116,
  },
  {
    nome: 'Sogliano al Rubicone',
    codice: '040046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'I779',
    cap: ['47030'],
    popolazione: 3251,
  },
  {
    nome: 'Sogliano Cavour',
    codice: '075075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I780',
    cap: ['73010'],
    popolazione: 4065,
  },
  {
    nome: 'Soglio',
    codice: '005107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'I781',
    cap: ['14020'],
    popolazione: 160,
  },
  {
    nome: 'Soiano del Lago',
    codice: '017180',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I782',
    cap: ['25080'],
    popolazione: 1785,
  },
  {
    nome: 'Solagna',
    codice: '024101',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I783',
    cap: ['36020'],
    popolazione: 1895,
  },
  {
    nome: 'Solarino',
    codice: '089018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'I785',
    cap: ['96010'],
    popolazione: 7853,
  },
  {
    nome: 'Solaro',
    codice: '015213',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'I786',
    cap: ['20020'],
    popolazione: 13890,
  },
  {
    nome: 'Solarolo',
    codice: '039018',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '039', nome: 'Ravenna' },
    sigla: 'RA',
    codiceCatastale: 'I787',
    cap: ['48027'],
    popolazione: 4489,
  },
  {
    nome: 'Solarolo Rainerio',
    codice: '019096',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I790',
    cap: ['26030'],
    popolazione: 1007,
  },
  {
    nome: 'Solarussa',
    codice: '095062',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I791',
    cap: ['09077'],
    popolazione: 2467,
  },
  {
    nome: 'Solbiate Arno',
    codice: '012121',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'I793',
    cap: ['21048'],
    popolazione: 4274,
  },
  {
    nome: 'Solbiate con Cagno',
    codice: '013255',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M412',
    cap: ['22043'],
    popolazione: 4588,
  },
  {
    nome: 'Solbiate Olona',
    codice: '012122',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'I794',
    cap: ['21058'],
    popolazione: 5579,
  },
  {
    nome: 'Soldano',
    codice: '008058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'I796',
    cap: ['18036'],
    popolazione: 985,
  },
  {
    nome: 'Soleminis',
    codice: '111087',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I797',
    cap: ['09040'],
    popolazione: 1858,
  },
  {
    nome: 'Solero',
    codice: '006163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I798',
    cap: ['15029'],
    popolazione: 1660,
  },
  {
    nome: 'Solesino',
    codice: '028087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I799',
    cap: ['35047'],
    popolazione: 7180,
  },
  {
    nome: 'Soleto',
    codice: '075076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I800',
    cap: ['73010'],
    popolazione: 5542,
  },
  {
    nome: 'Solferino',
    codice: '020063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'I801',
    cap: ['46040'],
    popolazione: 2531,
  },
  {
    nome: 'Soliera',
    codice: '036044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I802',
    cap: ['41019'],
    popolazione: 15061,
  },
  {
    nome: 'Solignano',
    codice: '034035',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'I803',
    cap: ['43046'],
    popolazione: 1809,
  },
  {
    nome: 'Solofra',
    codice: '064101',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I805',
    cap: ['83029'],
    popolazione: 12419,
  },
  {
    nome: 'Solonghello',
    codice: '006164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I808',
    cap: ['15020'],
    popolazione: 221,
  },
  {
    nome: 'Solopaca',
    codice: '062073',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'I809',
    cap: ['82036'],
    popolazione: 3956,
  },
  {
    nome: 'Solto Collina',
    codice: '016200',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I812',
    cap: ['24060'],
    popolazione: 1735,
  },
  {
    nome: 'Solza',
    codice: '016251',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I813',
    cap: ['24030'],
    popolazione: 1961,
  },
  {
    nome: 'Somaglia',
    codice: '098054',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I815',
    cap: ['26867'],
    popolazione: 3661,
  },
  {
    nome: 'Somano',
    codice: '004221',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I817',
    cap: ['12060'],
    popolazione: 361,
  },
  {
    nome: 'Somma Lombardo',
    codice: '012123',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'I819',
    cap: ['21019'],
    popolazione: 16905,
  },
  {
    nome: 'Somma Vesuviana',
    codice: '063079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I820',
    cap: ['80049'],
    popolazione: 34592,
  },
  {
    nome: 'Sommacampagna',
    codice: '023082',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I821',
    cap: ['37066'],
    popolazione: 14615,
  },
  {
    nome: 'Sommariva del Bosco',
    codice: '004222',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I822',
    cap: ['12048'],
    popolazione: 6394,
  },
  {
    nome: 'Sommariva Perno',
    codice: '004223',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I823',
    cap: ['12040'],
    popolazione: 2828,
  },
  {
    nome: 'Sommatino',
    codice: '085019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'I824',
    cap: ['93019'],
    popolazione: 7267,
  },
  {
    nome: 'Sommo',
    codice: '018151',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I825',
    cap: ['27048'],
    popolazione: 1146,
  },
  {
    nome: 'Sona',
    codice: '023083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I826',
    cap: ['37060'],
    popolazione: 17030,
  },
  {
    nome: 'Soncino',
    codice: '019097',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I827',
    cap: ['26029'],
    popolazione: 7699,
  },
  {
    nome: 'Sondalo',
    codice: '014060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'I828',
    cap: ['23035'],
    popolazione: 4237,
  },
  {
    nome: 'Sondrio',
    codice: '014061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'I829',
    cap: ['23100'],
    popolazione: 21642,
  },
  {
    nome: 'Songavazzo',
    codice: '016201',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I830',
    cap: ['24020'],
    popolazione: 702,
  },
  {
    nome: 'Sonico',
    codice: '017181',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'I831',
    cap: ['25048'],
    popolazione: 1270,
  },
  {
    nome: 'Sonnino',
    codice: '059029',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'I832',
    cap: ['04010'],
    popolazione: 7279,
  },
  {
    nome: 'Sora',
    codice: '060074',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I838',
    cap: ['03039'],
    popolazione: 26247,
  },
  {
    nome: 'Soraga di Fassa',
    codice: '022176',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I839',
    cap: ['38030'],
    popolazione: 736,
  },
  {
    nome: 'Soragna',
    codice: '034036',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'I840',
    cap: ['43019'],
    popolazione: 4872,
  },
  {
    nome: 'Sorano',
    codice: '053026',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '053', nome: 'Grosseto' },
    sigla: 'GR',
    codiceCatastale: 'I841',
    cap: ['58010'],
    popolazione: 3596,
  },
  {
    nome: 'Sorbo San Basile',
    codice: '079134',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I844',
    cap: ['88050'],
    popolazione: 827,
  },
  {
    nome: 'Sorbo Serpico',
    codice: '064102',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I843',
    cap: ['83050'],
    popolazione: 594,
  },
  {
    nome: 'Sorbolo Mezzani',
    codice: '034051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'M411',
    cap: ['43058'],
    popolazione: 12984,
  },
  {
    nome: 'Sordevolo',
    codice: '096063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'I847',
    cap: ['13817'],
    popolazione: 1330,
  },
  {
    nome: 'Sordio',
    codice: '098055',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'I848',
    cap: ['26858'],
    popolazione: 3149,
  },
  {
    nome: 'Soresina',
    codice: '019098',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I849',
    cap: ['26015'],
    popolazione: 8995,
  },
  {
    nome: 'Sorgà',
    codice: '023084',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'I850',
    cap: ['37060'],
    popolazione: 3112,
  },
  {
    nome: 'Sorgono',
    codice: '091086',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'I851',
    cap: ['08038'],
    popolazione: 1753,
  },
  {
    nome: 'Sori',
    codice: '010060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'I852',
    cap: ['16031'],
    popolazione: 4404,
  },
  {
    nome: 'Sorianello',
    codice: '102039',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I853',
    cap: ['89831'],
    popolazione: 1210,
  },
  {
    nome: 'Soriano Calabro',
    codice: '102040',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I854',
    cap: ['89831'],
    popolazione: 2472,
  },
  {
    nome: 'Soriano nel Cimino',
    codice: '056048',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'I855',
    cap: ['01038'],
    popolazione: 8544,
  },
  {
    nome: 'Sorico',
    codice: '013216',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I856',
    cap: ['22010'],
    popolazione: 1234,
  },
  {
    nome: 'Soriso',
    codice: '003140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I857',
    cap: ['28010'],
    popolazione: 781,
  },
  {
    nome: 'Sorisole',
    codice: '016202',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I858',
    cap: ['24010'],
    popolazione: 9097,
  },
  {
    nome: 'Sormano',
    codice: '013217',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I860',
    cap: ['22030'],
    popolazione: 633,
  },
  {
    nome: 'Sorradile',
    codice: '095063',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I861',
    cap: ['09080'],
    popolazione: 417,
  },
  {
    nome: 'Sorrento',
    codice: '063080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I862',
    cap: ['80067'],
    popolazione: 16563,
  },
  {
    nome: 'Sorso',
    codice: '090069',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'I863',
    cap: ['07037'],
    popolazione: 14300,
  },
  {
    nome: 'Sortino',
    codice: '089019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '089', nome: 'Siracusa' },
    sigla: 'SR',
    codiceCatastale: 'I864',
    cap: ['96010'],
    popolazione: 8907,
  },
  {
    nome: 'Sospiro',
    codice: '019099',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I865',
    cap: ['26048'],
    popolazione: 3236,
  },
  {
    nome: 'Sospirolo',
    codice: '025056',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I866',
    cap: ['32037'],
    popolazione: 3213,
  },
  {
    nome: 'Sossano',
    codice: '024102',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I867',
    cap: ['36040'],
    popolazione: 4401,
  },
  {
    nome: 'Sostegno',
    codice: '096064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'I868',
    cap: ['13868'],
    popolazione: 751,
  },
  {
    nome: 'Sotto il Monte Giovanni XXIII',
    codice: '016203',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I869',
    cap: ['24039'],
    popolazione: 4291,
  },
  {
    nome: 'Sover',
    codice: '022177',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I871',
    cap: ['38048'],
    popolazione: 882,
  },
  {
    nome: 'Soverato',
    codice: '079137',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I872',
    cap: ['88068'],
    popolazione: 8841,
  },
  {
    nome: 'Sovere',
    codice: '016204',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I873',
    cap: ['24060'],
    popolazione: 5509,
  },
  {
    nome: 'Soveria Mannelli',
    codice: '079138',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I874',
    cap: ['88049'],
    popolazione: 3137,
  },
  {
    nome: 'Soveria Simeri',
    codice: '079139',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I875',
    cap: ['88050'],
    popolazione: 1643,
  },
  {
    nome: 'Soverzene',
    codice: '025057',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I876',
    cap: ['32010'],
    popolazione: 418,
  },
  {
    nome: 'Sovicille',
    codice: '052034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'I877',
    cap: ['53018'],
    popolazione: 9935,
  },
  {
    nome: 'Sovico',
    codice: '108041',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'I878',
    cap: ['20845'],
    popolazione: 8069,
  },
  {
    nome: 'Sovizzo',
    codice: '024103',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'I879',
    cap: ['36050'],
    popolazione: 7034,
  },
  {
    nome: 'Sovramonte',
    codice: '025058',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'I673',
    cap: ['32030'],
    popolazione: 1514,
  },
  {
    nome: 'Sozzago',
    codice: '003141',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'I880',
    cap: ['28060'],
    popolazione: 1055,
  },
  {
    nome: 'Spadafora',
    codice: '083096',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'I881',
    cap: ['98048'],
    popolazione: 5091,
  },
  {
    nome: 'Spadola',
    codice: '102041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I884',
    cap: ['89822'],
    popolazione: 858,
  },
  {
    nome: 'Sparanise',
    codice: '061089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I885',
    cap: ['81056'],
    popolazione: 7509,
  },
  {
    nome: 'Sparone',
    codice: '001267',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I886',
    cap: ['10080'],
    popolazione: 1085,
  },
  {
    nome: 'Specchia',
    codice: '075077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I887',
    cap: ['73040'],
    popolazione: 4807,
  },
  {
    nome: 'Spello',
    codice: '054050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I888',
    cap: ['06038'],
    popolazione: 8631,
  },
  {
    nome: 'Sperlinga',
    codice: '086017',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'I891',
    cap: ['94010'],
    popolazione: 833,
  },
  {
    nome: 'Sperlonga',
    codice: '059030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'I892',
    cap: ['04029'],
    popolazione: 3334,
  },
  {
    nome: 'Sperone',
    codice: '064103',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I893',
    cap: ['83020'],
    popolazione: 3655,
  },
  {
    nome: 'Spessa',
    codice: '018152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I894',
    cap: ['27010'],
    popolazione: 602,
  },
  {
    nome: 'Spezzano Albanese',
    codice: '078142',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I895',
    cap: ['87019'],
    popolazione: 7157,
  },
  {
    nome: 'Spezzano della Sila',
    codice: '078143',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'I896',
    cap: ['87058'],
    popolazione: 4490,
  },
  {
    nome: 'Spiazzo',
    codice: '022179',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I899',
    cap: ['38088'],
    popolazione: 1315,
  },
  {
    nome: 'Spigno Monferrato',
    codice: '006165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I901',
    cap: ['15018'],
    popolazione: 1126,
  },
  {
    nome: 'Spigno Saturnia',
    codice: '059031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'I902',
    cap: ['04020'],
    popolazione: 2903,
  },
  {
    nome: 'Spilamberto',
    codice: '036045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'I903',
    cap: ['41057'],
    popolazione: 12130,
  },
  {
    nome: 'Spilimbergo',
    codice: '093044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'I904',
    cap: ['33097'],
    popolazione: 11902,
  },
  {
    nome: 'Spilinga',
    codice: '102042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I905',
    cap: ['89864'],
    popolazione: 1470,
  },
  {
    nome: 'Spinadesco',
    codice: '019100',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I906',
    cap: ['26020'],
    popolazione: 1575,
  },
  {
    nome: 'Spinazzola',
    codice: '110008',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'I907',
    cap: ['76014'],
    popolazione: 6755,
  },
  {
    nome: 'Spinea',
    codice: '027038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'I908',
    cap: ['30038'],
    popolazione: 26862,
  },
  {
    nome: 'Spineda',
    codice: '019101',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I909',
    cap: ['26030'],
    popolazione: 633,
  },
  {
    nome: 'Spinete',
    codice: '070076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'I910',
    cap: ['86020'],
    popolazione: 1373,
  },
  {
    nome: 'Spineto Scrivia',
    codice: '006166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I911',
    cap: ['15050'],
    popolazione: 332,
  },
  {
    nome: 'Spinetoli',
    codice: '044071',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'I912',
    cap: ['63078'],
    popolazione: 7108,
  },
  {
    nome: "Spino d'Adda",
    codice: '019102',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I914',
    cap: ['26016'],
    popolazione: 6851,
  },
  {
    nome: 'Spinone al Lago',
    codice: '016205',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I916',
    cap: ['24060'],
    popolazione: 1038,
  },
  {
    nome: 'Spinoso',
    codice: '076086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'I917',
    cap: ['85039'],
    popolazione: 1555,
  },
  {
    nome: 'Spirano',
    codice: '016206',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I919',
    cap: ['24050'],
    popolazione: 5639,
  },
  {
    nome: 'Spoleto',
    codice: '054051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'I921',
    cap: ['06049'],
    popolazione: 38429,
  },
  {
    nome: 'Spoltore',
    codice: '068041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'I922',
    cap: ['65010'],
    popolazione: 18566,
  },
  {
    nome: 'Spongano',
    codice: '075078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I923',
    cap: ['73038'],
    popolazione: 3742,
  },
  {
    nome: 'Spormaggiore',
    codice: '022180',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I924',
    cap: ['38010'],
    popolazione: 1259,
  },
  {
    nome: 'Sporminore',
    codice: '022181',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I925',
    cap: ['38010'],
    popolazione: 714,
  },
  {
    nome: 'Spotorno',
    codice: '009057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'I926',
    cap: ['17028'],
    popolazione: 3886,
  },
  {
    nome: 'Spresiano',
    codice: '026082',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'I927',
    cap: ['31027'],
    popolazione: 11659,
  },
  {
    nome: 'Spriana',
    codice: '014062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'I928',
    cap: ['23020'],
    popolazione: 101,
  },
  {
    nome: 'Squillace',
    codice: '079142',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I929',
    cap: ['88069'],
    popolazione: 3400,
  },
  {
    nome: 'Squinzano',
    codice: '075079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I930',
    cap: ['73018'],
    popolazione: 14482,
  },
  {
    nome: 'Staffolo',
    codice: '042049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'I932',
    cap: ['60039'],
    popolazione: 2290,
  },
  {
    nome: 'Stagno Lombardo',
    codice: '019103',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'I935',
    cap: ['26049'],
    popolazione: 1570,
  },
  {
    nome: 'Staiti',
    codice: '080090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I936',
    cap: ['89030'],
    popolazione: 279,
  },
  {
    nome: 'Stalettì',
    codice: '079143',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I937',
    cap: ['88069'],
    popolazione: 2443,
  },
  {
    nome: 'Stanghella',
    codice: '028088',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'I938',
    cap: ['35048'],
    popolazione: 4331,
  },
  {
    nome: 'Staranzano',
    codice: '031023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'I939',
    cap: ['34079'],
    popolazione: 7199,
  },
  {
    nome: 'Statte',
    codice: '073029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'M298',
    cap: ['74010'],
    popolazione: 14194,
  },
  {
    nome: 'Stazzano',
    codice: '006167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I941',
    cap: ['15060'],
    popolazione: 2425,
  },
  {
    nome: 'Stazzema',
    codice: '046030',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'I942',
    cap: ['55040'],
    popolazione: 3318,
  },
  {
    nome: 'Stazzona',
    codice: '013218',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'I943',
    cap: ['22010'],
    popolazione: 634,
  },
  {
    nome: 'Stefanaconi',
    codice: '102043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'I945',
    cap: ['89843'],
    popolazione: 2526,
  },
  {
    nome: 'Stella',
    codice: '009058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'I946',
    cap: ['17044'],
    popolazione: 3066,
  },
  {
    nome: 'Stella Cilento',
    codice: '065144',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G887',
    cap: ['84070'],
    popolazione: 774,
  },
  {
    nome: 'Stellanello',
    codice: '009059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'I947',
    cap: ['17020'],
    popolazione: 858,
  },
  {
    nome: 'Stelvio',
    codice: '021095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'I948',
    cap: ['39029'],
    popolazione: 1190,
  },
  {
    nome: 'Stenico',
    codice: '022182',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I949',
    cap: ['38070'],
    popolazione: 1137,
  },
  {
    nome: 'Sternatia',
    codice: '075080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'I950',
    cap: ['73010'],
    popolazione: 2426,
  },
  {
    nome: 'Stezzano',
    codice: '016207',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I951',
    cap: ['24040'],
    popolazione: 12623,
  },
  {
    nome: 'Stienta',
    codice: '029045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'I953',
    cap: ['45039'],
    popolazione: 3329,
  },
  {
    nome: 'Stigliano',
    codice: '077027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'I954',
    cap: ['75018'],
    popolazione: 4685,
  },
  {
    nome: 'Stignano',
    codice: '080091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I955',
    cap: ['89040'],
    popolazione: 1340,
  },
  {
    nome: 'Stilo',
    codice: '080092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'I956',
    cap: ['89049'],
    popolazione: 2687,
  },
  {
    nome: 'Stimigliano',
    codice: '057066',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'I959',
    cap: ['02048'],
    popolazione: 2241,
  },
  {
    nome: 'Stintino',
    codice: '090089',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M290',
    cap: ['07040'],
    popolazione: 1501,
  },
  {
    nome: 'Stio',
    codice: '065145',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'I960',
    cap: ['84075'],
    popolazione: 942,
  },
  {
    nome: 'Stornara',
    codice: '071054',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I962',
    cap: ['71047'],
    popolazione: 5306,
  },
  {
    nome: 'Stornarella',
    codice: '071055',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'I963',
    cap: ['71048'],
    popolazione: 5022,
  },
  {
    nome: 'Storo',
    codice: '022183',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I964',
    cap: ['38089'],
    popolazione: 4655,
  },
  {
    nome: 'Stra',
    codice: '027039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'I965',
    cap: ['30039'],
    popolazione: 7566,
  },
  {
    nome: 'Stradella',
    codice: '018153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I968',
    cap: ['27049'],
    popolazione: 11639,
  },
  {
    nome: 'Strambinello',
    codice: '001268',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I969',
    cap: ['10010'],
    popolazione: 264,
  },
  {
    nome: 'Strambino',
    codice: '001269',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'I970',
    cap: ['10019'],
    popolazione: 6336,
  },
  {
    nome: 'Strangolagalli',
    codice: '060075',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I973',
    cap: ['03020'],
    popolazione: 2501,
  },
  {
    nome: 'Stregna',
    codice: '030111',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'I974',
    cap: ['33040'],
    popolazione: 398,
  },
  {
    nome: 'Strembo',
    codice: '022184',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'I975',
    cap: ['38080'],
    popolazione: 531,
  },
  {
    nome: 'Stresa',
    codice: '103064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'I976',
    cap: ['28838'],
    popolazione: 4816,
  },
  {
    nome: 'Strevi',
    codice: '006168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'I977',
    cap: ['15019'],
    popolazione: 2039,
  },
  {
    nome: 'Striano',
    codice: '063081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'I978',
    cap: ['80040'],
    popolazione: 8204,
  },
  {
    nome: 'Strona',
    codice: '096065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'I980',
    cap: ['13823'],
    popolazione: 1157,
  },
  {
    nome: 'Stroncone',
    codice: '055031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'I981',
    cap: ['05039'],
    popolazione: 4924,
  },
  {
    nome: 'Strongoli',
    codice: '101025',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'I982',
    cap: ['88816'],
    popolazione: 6486,
  },
  {
    nome: 'Stroppiana',
    codice: '002142',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'I984',
    cap: ['13010'],
    popolazione: 1258,
  },
  {
    nome: 'Stroppo',
    codice: '004224',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'I985',
    cap: ['12020'],
    popolazione: 107,
  },
  {
    nome: 'Strozza',
    codice: '016208',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I986',
    cap: ['24030'],
    popolazione: 1066,
  },
  {
    nome: 'Sturno',
    codice: '064104',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'I990',
    cap: ['83055'],
    popolazione: 3139,
  },
  {
    nome: 'Suardi',
    codice: '018154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'B014',
    cap: ['27030'],
    popolazione: 651,
  },
  {
    nome: 'Subbiano',
    codice: '051037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'I991',
    cap: ['52010'],
    popolazione: 6299,
  },
  {
    nome: 'Subiaco',
    codice: '058103',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'I992',
    cap: ['00028'],
    popolazione: 9066,
  },
  {
    nome: 'Succivo',
    codice: '061090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'I993',
    cap: ['81030'],
    popolazione: 8148,
  },
  {
    nome: 'Sueglio',
    codice: '097077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'I994',
    cap: ['23835'],
    popolazione: 147,
  },
  {
    nome: 'Suelli',
    codice: '111088',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'I995',
    cap: ['09040'],
    popolazione: 1135,
  },
  {
    nome: 'Suello',
    codice: '097078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'I996',
    cap: ['23867'],
    popolazione: 1686,
  },
  {
    nome: 'Suisio',
    codice: '016209',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'I997',
    cap: ['24040'],
    popolazione: 3873,
  },
  {
    nome: 'Sulbiate',
    codice: '108042',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'I998',
    cap: ['20884'],
    popolazione: 4067,
  },
  {
    nome: 'Sulmona',
    codice: '066098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'I804',
    cap: ['67039'],
    popolazione: 24275,
  },
  {
    nome: 'Sulzano',
    codice: '017182',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L002',
    cap: ['25058'],
    popolazione: 1892,
  },
  {
    nome: 'Sumirago',
    codice: '012124',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L003',
    cap: ['21040'],
    popolazione: 6254,
  },
  {
    nome: 'Summonte',
    codice: '064105',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L004',
    cap: ['83010'],
    popolazione: 1613,
  },
  {
    nome: 'Suni',
    codice: '095087',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L006',
    cap: ['09090'],
    popolazione: 1130,
  },
  {
    nome: 'Suno',
    codice: '003143',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L007',
    cap: ['28019'],
    popolazione: 2808,
  },
  {
    nome: 'Supersano',
    codice: '075081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L008',
    cap: ['73040'],
    popolazione: 4509,
  },
  {
    nome: 'Supino',
    codice: '060076',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L009',
    cap: ['03019'],
    popolazione: 4893,
  },
  {
    nome: 'Surano',
    codice: '075082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L010',
    cap: ['73030'],
    popolazione: 1698,
  },
  {
    nome: 'Surbo',
    codice: '075083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L011',
    cap: ['73010'],
    popolazione: 14849,
  },
  {
    nome: 'Susa',
    codice: '001270',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L013',
    cap: ['10059'],
    popolazione: 6629,
  },
  {
    nome: 'Susegana',
    codice: '026083',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L014',
    cap: ['31058'],
    popolazione: 11702,
  },
  {
    nome: 'Sustinente',
    codice: '020064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'L015',
    cap: ['46030'],
    popolazione: 2240,
  },
  {
    nome: 'Sutera',
    codice: '085020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'L016',
    cap: ['93010'],
    popolazione: 1436,
  },
  {
    nome: 'Sutri',
    codice: '056049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L017',
    cap: ['01015'],
    popolazione: 6552,
  },
  {
    nome: 'Sutrio',
    codice: '030112',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L018',
    cap: ['33020'],
    popolazione: 1371,
  },
  {
    nome: 'Suvereto',
    codice: '049020',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '049', nome: 'Livorno' },
    sigla: 'LI',
    codiceCatastale: 'L019',
    cap: ['57028'],
    popolazione: 3142,
  },
  {
    nome: 'Suzzara',
    codice: '020065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'L020',
    cap: ['46029'],
    popolazione: 20545,
  },
  {
    nome: 'Taceno',
    codice: '097079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'L022',
    cap: ['23837'],
    popolazione: 541,
  },
  {
    nome: 'Tadasuni',
    codice: '095064',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L023',
    cap: ['09080'],
    popolazione: 184,
  },
  {
    nome: 'Taggia',
    codice: '008059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L024',
    cap: ['18018'],
    popolazione: 14032,
  },
  {
    nome: 'Tagliacozzo',
    codice: '066099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'L025',
    cap: ['67069'],
    popolazione: 6939,
  },
  {
    nome: 'Taglio di Po',
    codice: '029046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'L026',
    cap: ['45019'],
    popolazione: 8495,
  },
  {
    nome: 'Tagliolo Monferrato',
    codice: '006169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L027',
    cap: ['15070'],
    popolazione: 1606,
  },
  {
    nome: 'Taibon Agordino',
    codice: '025059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'L030',
    cap: ['32027'],
    popolazione: 1788,
  },
  {
    nome: 'Taino',
    codice: '012125',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L032',
    cap: ['21020'],
    popolazione: 3762,
  },
  {
    nome: 'Taipana',
    codice: '030113',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'G736',
    cap: ['33040'],
    popolazione: 679,
  },
  {
    nome: 'Talamello',
    codice: '099027',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'L034',
    cap: ['47867'],
    popolazione: 1060,
  },
  {
    nome: 'Talamona',
    codice: '014063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L035',
    cap: ['23018'],
    popolazione: 4768,
  },
  {
    nome: 'Talana',
    codice: '091088',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L036',
    cap: ['08040'],
    popolazione: 1069,
  },
  {
    nome: 'Taleggio',
    codice: '016210',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L037',
    cap: ['24010'],
    popolazione: 603,
  },
  {
    nome: 'Talla',
    codice: '051038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'L038',
    cap: ['52010'],
    popolazione: 1130,
  },
  {
    nome: 'Talmassons',
    codice: '030114',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L039',
    cap: ['33030'],
    popolazione: 4144,
  },
  {
    nome: 'Tambre',
    codice: '025060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'L040',
    cap: ['32010'],
    popolazione: 1425,
  },
  {
    nome: 'Taormina',
    codice: '083097',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L042',
    cap: ['98039'],
    popolazione: 11084,
  },
  {
    nome: 'Tarano',
    codice: '057067',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'L046',
    cap: ['02040'],
    popolazione: 1431,
  },
  {
    nome: 'Taranta Peligna',
    codice: '069089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L047',
    cap: ['66018'],
    popolazione: 399,
  },
  {
    nome: 'Tarantasca',
    codice: '004225',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L048',
    cap: ['12020'],
    popolazione: 2009,
  },
  {
    nome: 'Taranto',
    codice: '073027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'L049',
    cap: ['74121', '74122', '74123'],
    popolazione: 200154,
  },
  {
    nome: 'Tarcento',
    codice: '030116',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L050',
    cap: ['33017'],
    popolazione: 9095,
  },
  {
    nome: 'Tarquinia',
    codice: '056050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'D024',
    cap: ['01016'],
    popolazione: 16016,
  },
  {
    nome: 'Tarsia',
    codice: '078145',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L055',
    cap: ['87040'],
    popolazione: 2139,
  },
  {
    nome: 'Tartano',
    codice: '014064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L056',
    cap: ['23010'],
    popolazione: 190,
  },
  {
    nome: 'Tarvisio',
    codice: '030117',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L057',
    cap: ['33018'],
    popolazione: 4577,
  },
  {
    nome: 'Tarzo',
    codice: '026084',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L058',
    cap: ['31020'],
    popolazione: 4583,
  },
  {
    nome: 'Tassarolo',
    codice: '006170',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L059',
    cap: ['15060'],
    popolazione: 636,
  },
  {
    nome: 'Taurano',
    codice: '064106',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L061',
    cap: ['83020'],
    popolazione: 1600,
  },
  {
    nome: 'Taurasi',
    codice: '064107',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L062',
    cap: ['83030'],
    popolazione: 2444,
  },
  {
    nome: 'Taurianova',
    codice: '080093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'L063',
    cap: ['89029'],
    popolazione: 15310,
  },
  {
    nome: 'Taurisano',
    codice: '075084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L064',
    cap: ['73056'],
    popolazione: 12643,
  },
  {
    nome: 'Tavagnacco',
    codice: '030118',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L065',
    cap: ['33010'],
    popolazione: 14262,
  },
  {
    nome: 'Tavagnasco',
    codice: '001271',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L066',
    cap: ['10010'],
    popolazione: 813,
  },
  {
    nome: 'Tavazzano con Villavesco',
    codice: '098056',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'F260',
    cap: ['26838'],
    popolazione: 6099,
  },
  {
    nome: 'Tavenna',
    codice: '070077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L069',
    cap: ['86030'],
    popolazione: 815,
  },
  {
    nome: 'Taverna',
    codice: '079146',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'L070',
    cap: ['88055'],
    popolazione: 2705,
  },
  {
    nome: 'Tavernerio',
    codice: '013222',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L071',
    cap: ['22038'],
    popolazione: 5705,
  },
  {
    nome: 'Tavernola Bergamasca',
    codice: '016211',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L073',
    cap: ['24060'],
    popolazione: 2140,
  },
  {
    nome: 'Tavernole sul Mella',
    codice: '017183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'C698',
    cap: ['25060'],
    popolazione: 1359,
  },
  {
    nome: 'Taviano',
    codice: '075085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L074',
    cap: ['73057'],
    popolazione: 12492,
  },
  {
    nome: 'Tavigliano',
    codice: '096066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L075',
    cap: ['13811'],
    popolazione: 960,
  },
  {
    nome: 'Tavoleto',
    codice: '041064',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'L078',
    cap: ['61020'],
    popolazione: 894,
  },
  {
    nome: 'Tavullia',
    codice: '041065',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'L081',
    cap: ['61010'],
    popolazione: 7866,
  },
  {
    nome: 'Teana',
    codice: '076087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L082',
    cap: ['85032'],
    popolazione: 645,
  },
  {
    nome: 'Teano',
    codice: '061091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L083',
    cap: ['81057'],
    popolazione: 12587,
  },
  {
    nome: 'Teggiano',
    codice: '065146',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'D292',
    cap: ['84039'],
    popolazione: 8182,
  },
  {
    nome: 'Teglio',
    codice: '014065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L084',
    cap: ['23036'],
    popolazione: 4654,
  },
  {
    nome: 'Teglio Veneto',
    codice: '027040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'L085',
    cap: ['30025'],
    popolazione: 2325,
  },
  {
    nome: 'Telese Terme',
    codice: '062074',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'L086',
    cap: ['82037'],
    popolazione: 6964,
  },
  {
    nome: 'Telgate',
    codice: '016212',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L087',
    cap: ['24060'],
    popolazione: 4857,
  },
  {
    nome: 'Telti',
    codice: '090080',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L088',
    cap: ['07020'],
    popolazione: 2222,
  },
  {
    nome: 'Telve',
    codice: '022188',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L089',
    cap: ['38050'],
    popolazione: 1995,
  },
  {
    nome: 'Telve di Sopra',
    codice: '022189',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L090',
    cap: ['38050'],
    popolazione: 617,
  },
  {
    nome: 'Tempio Pausania',
    codice: '090070',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L093',
    cap: ['07029'],
    popolazione: 13946,
  },
  {
    nome: 'Temù',
    codice: '017184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L094',
    cap: ['25050'],
    popolazione: 1083,
  },
  {
    nome: 'Tenna',
    codice: '022190',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L096',
    cap: ['38050'],
    popolazione: 955,
  },
  {
    nome: 'Tenno',
    codice: '022191',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L097',
    cap: ['38060'],
    popolazione: 1967,
  },
  {
    nome: 'Teolo',
    codice: '028089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L100',
    cap: ['35037'],
    popolazione: 8866,
  },
  {
    nome: 'Teora',
    codice: '064108',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L102',
    cap: ['83056'],
    popolazione: 1543,
  },
  {
    nome: 'Teramo',
    codice: '067041',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'L103',
    cap: ['64100'],
    popolazione: 54294,
  },
  {
    nome: 'Terdobbiate',
    codice: '003144',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L104',
    cap: ['28070'],
    popolazione: 500,
  },
  {
    nome: 'Terelle',
    codice: '060077',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L105',
    cap: ['03040'],
    popolazione: 460,
  },
  {
    nome: 'Terento',
    codice: '021096',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L106',
    cap: ['39030'],
    popolazione: 1733,
  },
  {
    nome: 'Terenzo',
    codice: '034038',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'E548',
    cap: ['43040'],
    popolazione: 1195,
  },
  {
    nome: 'Tergu',
    codice: '090086',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M282',
    cap: ['07030'],
    popolazione: 614,
  },
  {
    nome: 'Terlano',
    codice: '021097',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L108',
    cap: ['39018'],
    popolazione: 4139,
  },
  {
    nome: 'Terlizzi',
    codice: '072043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'L109',
    cap: ['70038'],
    popolazione: 26986,
  },
  {
    nome: 'Terme Vigliatore',
    codice: '083106',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'M210',
    cap: ['98050'],
    popolazione: 7213,
  },
  {
    nome: 'Termeno sulla strada del vino',
    codice: '021098',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L111',
    cap: ['39040'],
    popolazione: 3305,
  },
  {
    nome: 'Termini Imerese',
    codice: '082070',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L112',
    cap: ['90018'],
    popolazione: 26201,
  },
  {
    nome: 'Termoli',
    codice: '070078',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L113',
    cap: ['86039'],
    popolazione: 32793,
  },
  {
    nome: 'Ternate',
    codice: '012126',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L115',
    cap: ['21020'],
    popolazione: 2474,
  },
  {
    nome: 'Ternengo',
    codice: '096067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L116',
    cap: ['13844'],
    popolazione: 298,
  },
  {
    nome: 'Terni',
    codice: '055032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '055', nome: 'Terni' },
    sigla: 'TR',
    codiceCatastale: 'L117',
    cap: ['05100'],
    popolazione: 109193,
  },
  {
    nome: "Terno d'Isola",
    codice: '016213',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L118',
    cap: ['24030'],
    popolazione: 7665,
  },
  {
    nome: 'Terracina',
    codice: '059032',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'L120',
    cap: ['04019'],
    popolazione: 44233,
  },
  {
    nome: 'Terragnolo',
    codice: '022193',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L121',
    cap: ['38060'],
    popolazione: 755,
  },
  {
    nome: 'Terralba',
    codice: '095065',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L122',
    cap: ['09098'],
    popolazione: 10440,
  },
  {
    nome: 'Terranova da Sibari',
    codice: '078146',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L124',
    cap: ['87010'],
    popolazione: 4999,
  },
  {
    nome: 'Terranova dei Passerini',
    codice: '098057',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'L125',
    cap: ['26827'],
    popolazione: 906,
  },
  {
    nome: 'Terranova di Pollino',
    codice: '076088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L126',
    cap: ['85030'],
    popolazione: 1324,
  },
  {
    nome: 'Terranova Sappo Minulio',
    codice: '080094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'L127',
    cap: ['89010'],
    popolazione: 549,
  },
  {
    nome: 'Terranuova Bracciolini',
    codice: '051039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '051', nome: 'Arezzo' },
    sigla: 'AR',
    codiceCatastale: 'L123',
    cap: ['52028'],
    popolazione: 12302,
  },
  {
    nome: 'Terrasini',
    codice: '082071',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L131',
    cap: ['90049'],
    popolazione: 11985,
  },
  {
    nome: 'Terrassa Padovana',
    codice: '028090',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L132',
    cap: ['35020'],
    popolazione: 2625,
  },
  {
    nome: 'Terravecchia',
    codice: '078147',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L134',
    cap: ['87060'],
    popolazione: 1019,
  },
  {
    nome: 'Terrazzo',
    codice: '023085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L136',
    cap: ['37040'],
    popolazione: 2290,
  },
  {
    nome: "Terre d'Adige",
    codice: '022251',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M407',
    cap: ['38097'],
    popolazione: 3011,
  },
  {
    nome: 'Terre del Reno',
    codice: '038028',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'M381',
    cap: ['44047'],
    popolazione: 10571,
  },
  {
    nome: 'Terre Roveresche',
    codice: '041070',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'M379',
    cap: ['61038'],
    popolazione: 5624,
  },
  {
    nome: 'Terricciola',
    codice: '050036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'L138',
    cap: ['56030'],
    popolazione: 4511,
  },
  {
    nome: 'Terruggia',
    codice: '006171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L139',
    cap: ['15030'],
    popolazione: 901,
  },
  {
    nome: 'Tertenia',
    codice: '091089',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L140',
    cap: ['08047'],
    popolazione: 3815,
  },
  {
    nome: 'Terzigno',
    codice: '063082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'L142',
    cap: ['80040'],
    popolazione: 17367,
  },
  {
    nome: 'Terzo',
    codice: '006172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L143',
    cap: ['15010'],
    popolazione: 907,
  },
  {
    nome: "Terzo d'Aquileia",
    codice: '030120',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L144',
    cap: ['33050'],
    popolazione: 2881,
  },
  {
    nome: 'Terzolas',
    codice: '022195',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L145',
    cap: ['38027'],
    popolazione: 606,
  },
  {
    nome: 'Terzorio',
    codice: '008060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L146',
    cap: ['18010'],
    popolazione: 232,
  },
  {
    nome: 'Tesero',
    codice: '022196',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L147',
    cap: ['38038'],
    popolazione: 2868,
  },
  {
    nome: 'Tesimo',
    codice: '021099',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L149',
    cap: ['39010'],
    popolazione: 1854,
  },
  {
    nome: 'Tessennano',
    codice: '056051',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L150',
    cap: ['01010'],
    popolazione: 350,
  },
  {
    nome: 'Testico',
    codice: '009060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L152',
    cap: ['17020'],
    popolazione: 212,
  },
  {
    nome: 'Teti',
    codice: '091090',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L153',
    cap: ['08030'],
    popolazione: 690,
  },
  {
    nome: 'Teulada',
    codice: '111089',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L154',
    cap: ['09019'],
    popolazione: 3773,
  },
  {
    nome: 'Teverola',
    codice: '061092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L155',
    cap: ['81030'],
    popolazione: 13610,
  },
  {
    nome: 'Tezze sul Brenta',
    codice: '024104',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L156',
    cap: ['36056'],
    popolazione: 12600,
  },
  {
    nome: 'Thiene',
    codice: '024105',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L157',
    cap: ['36016'],
    popolazione: 23254,
  },
  {
    nome: 'Thiesi',
    codice: '090071',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L158',
    cap: ['07047'],
    popolazione: 3005,
  },
  {
    nome: 'Tiana',
    codice: '091091',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L160',
    cap: ['08020'],
    popolazione: 521,
  },
  {
    nome: 'Ticengo',
    codice: '019104',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L164',
    cap: ['26020'],
    popolazione: 448,
  },
  {
    nome: 'Ticineto',
    codice: '006173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L165',
    cap: ['15040'],
    popolazione: 1424,
  },
  {
    nome: 'Tiggiano',
    codice: '075086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L166',
    cap: ['73030'],
    popolazione: 2931,
  },
  {
    nome: 'Tiglieto',
    codice: '010061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'L167',
    cap: ['16010'],
    popolazione: 580,
  },
  {
    nome: 'Tigliole',
    codice: '005108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L168',
    cap: ['14016'],
    popolazione: 1734,
  },
  {
    nome: 'Tignale',
    codice: '017185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L169',
    cap: ['25080'],
    popolazione: 1298,
  },
  {
    nome: 'Tinnura',
    codice: '095088',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L172',
    cap: ['09090'],
    popolazione: 268,
  },
  {
    nome: 'Tione degli Abruzzi',
    codice: '066100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'L173',
    cap: ['67020'],
    popolazione: 326,
  },
  {
    nome: 'Tione di Trento',
    codice: '022199',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L174',
    cap: ['38079'],
    popolazione: 3608,
  },
  {
    nome: 'Tirano',
    codice: '014066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L175',
    cap: ['23037'],
    popolazione: 9073,
  },
  {
    nome: 'Tires',
    codice: '021100',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L176',
    cap: ['39050'],
    popolazione: 967,
  },
  {
    nome: 'Tiriolo',
    codice: '079147',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'L177',
    cap: ['88056'],
    popolazione: 3897,
  },
  {
    nome: 'Tirolo',
    codice: '021101',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L178',
    cap: ['39019'],
    popolazione: 2450,
  },
  {
    nome: 'Tissi',
    codice: '090072',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L180',
    cap: ['07040'],
    popolazione: 2289,
  },
  {
    nome: 'Tito',
    codice: '076089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L181',
    cap: ['85050'],
    popolazione: 7172,
  },
  {
    nome: 'Tivoli',
    codice: '058104',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L182',
    cap: ['00019'],
    popolazione: 52910,
  },
  {
    nome: 'Tizzano Val Parma',
    codice: '034039',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L183',
    cap: ['43028'],
    popolazione: 2113,
  },
  {
    nome: 'Toano',
    codice: '035041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'L184',
    cap: ['42010'],
    popolazione: 4458,
  },
  {
    nome: 'Tocco Caudio',
    codice: '062075',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'L185',
    cap: ['82030'],
    popolazione: 1543,
  },
  {
    nome: 'Tocco da Casauria',
    codice: '068042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'L186',
    cap: ['65028'],
    popolazione: 2721,
  },
  {
    nome: 'Toceno',
    codice: '103065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L187',
    cap: ['28858'],
    popolazione: 771,
  },
  {
    nome: 'Todi',
    codice: '054052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L188',
    cap: ['06059'],
    popolazione: 16900,
  },
  {
    nome: 'Toffia',
    codice: '057068',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'L189',
    cap: ['02039'],
    popolazione: 1002,
  },
  {
    nome: 'Toirano',
    codice: '009061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L190',
    cap: ['17055'],
    popolazione: 2669,
  },
  {
    nome: 'Tolentino',
    codice: '043053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'L191',
    cap: ['62029'],
    popolazione: 20336,
  },
  {
    nome: 'Tolfa',
    codice: '058105',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L192',
    cap: ['00059'],
    popolazione: 5147,
  },
  {
    nome: 'Tollegno',
    codice: '096068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L193',
    cap: ['13818'],
    popolazione: 2645,
  },
  {
    nome: 'Tollo',
    codice: '069090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L194',
    cap: ['66010'],
    popolazione: 4071,
  },
  {
    nome: 'Tolmezzo',
    codice: '030121',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L195',
    cap: ['33028'],
    popolazione: 10570,
  },
  {
    nome: 'Tolve',
    codice: '076090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L197',
    cap: ['85017'],
    popolazione: 3361,
  },
  {
    nome: 'Tombolo',
    codice: '028091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L199',
    cap: ['35019'],
    popolazione: 8227,
  },
  {
    nome: 'Ton',
    codice: '022200',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L200',
    cap: ['38010'],
    popolazione: 1319,
  },
  {
    nome: 'Tonara',
    codice: '091093',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L202',
    cap: ['08039'],
    popolazione: 2116,
  },
  {
    nome: 'Tonco',
    codice: '005109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L203',
    cap: ['14039'],
    popolazione: 899,
  },
  {
    nome: 'Tonengo',
    codice: '005110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L204',
    cap: ['14023'],
    popolazione: 197,
  },
  {
    nome: 'Tonezza del Cimone',
    codice: '024106',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'D717',
    cap: ['36040'],
    popolazione: 577,
  },
  {
    nome: 'Tora e Piccilli',
    codice: '061093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L205',
    cap: ['81044'],
    popolazione: 947,
  },
  {
    nome: 'Torano Castello',
    codice: '078148',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L206',
    cap: ['87010'],
    popolazione: 4573,
  },
  {
    nome: 'Torano Nuovo',
    codice: '067042',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'L207',
    cap: ['64010'],
    popolazione: 1658,
  },
  {
    nome: 'Torbole Casaglia',
    codice: '017186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L210',
    cap: ['25030'],
    popolazione: 6370,
  },
  {
    nome: 'Torcegno',
    codice: '022202',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L211',
    cap: ['38050'],
    popolazione: 697,
  },
  {
    nome: 'Torchiara',
    codice: '065147',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L212',
    cap: ['84076'],
    popolazione: 1803,
  },
  {
    nome: 'Torchiarolo',
    codice: '074018',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'L213',
    cap: ['72020'],
    popolazione: 5461,
  },
  {
    nome: 'Torella dei Lombardi',
    codice: '064109',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L214',
    cap: ['83057'],
    popolazione: 2225,
  },
  {
    nome: 'Torella del Sannio',
    codice: '070079',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L215',
    cap: ['86028'],
    popolazione: 794,
  },
  {
    nome: 'Torgiano',
    codice: '054053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L216',
    cap: ['06089'],
    popolazione: 6520,
  },
  {
    nome: 'Torgnon',
    codice: '007067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L217',
    cap: ['11020'],
    popolazione: 517,
  },
  {
    nome: 'Torino',
    codice: '001272',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L219',
    cap: [
      '10121',
      '10122',
      '10123',
      '10124',
      '10125',
      '10126',
      '10127',
      '10128',
      '10129',
      '10130',
      '10131',
      '10132',
      '10133',
      '10134',
      '10135',
      '10136',
      '10137',
      '10138',
      '10139',
      '10140',
      '10141',
      '10142',
      '10143',
      '10144',
      '10145',
      '10146',
      '10147',
      '10148',
      '10149',
      '10150',
      '10151',
      '10152',
      '10153',
      '10154',
      '10155',
      '10156',
    ],
    popolazione: 872367,
  },
  {
    nome: 'Torino di Sangro',
    codice: '069091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L218',
    cap: ['66020'],
    popolazione: 3041,
  },
  {
    nome: 'Toritto',
    codice: '072044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'L220',
    cap: ['70020'],
    popolazione: 8551,
  },
  {
    nome: 'Torlino Vimercati',
    codice: '019105',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L221',
    cap: ['26017'],
    popolazione: 449,
  },
  {
    nome: 'Tornaco',
    codice: '003146',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L223',
    cap: ['28070'],
    popolazione: 876,
  },
  {
    nome: 'Tornareccio',
    codice: '069092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L224',
    cap: ['66046'],
    popolazione: 1932,
  },
  {
    nome: 'Tornata',
    codice: '019106',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L225',
    cap: ['26030'],
    popolazione: 491,
  },
  {
    nome: 'Tornimparte',
    codice: '066101',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'L227',
    cap: ['67049'],
    popolazione: 3096,
  },
  {
    nome: 'Torno',
    codice: '013223',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L228',
    cap: ['22020'],
    popolazione: 1203,
  },
  {
    nome: 'Tornolo',
    codice: '034040',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L229',
    cap: ['43059'],
    popolazione: 1102,
  },
  {
    nome: 'Toro',
    codice: '070080',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L230',
    cap: ['86018'],
    popolazione: 1450,
  },
  {
    nome: 'Torpè',
    codice: '091094',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L231',
    cap: ['08020'],
    popolazione: 2891,
  },
  {
    nome: 'Torraca',
    codice: '065148',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L233',
    cap: ['84030'],
    popolazione: 1267,
  },
  {
    nome: 'Torralba',
    codice: '090073',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L235',
    cap: ['07048'],
    popolazione: 998,
  },
  {
    nome: 'Torrazza Coste',
    codice: '018155',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L237',
    cap: ['27050'],
    popolazione: 1693,
  },
  {
    nome: 'Torrazza Piemonte',
    codice: '001273',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L238',
    cap: ['10037'],
    popolazione: 2816,
  },
  {
    nome: 'Torrazzo',
    codice: '096069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L239',
    cap: ['13884'],
    popolazione: 224,
  },
  {
    nome: 'Torre Annunziata',
    codice: '063083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'L245',
    cap: ['80058'],
    popolazione: 43521,
  },
  {
    nome: 'Torre Beretti e Castellaro',
    codice: '018156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L250',
    cap: ['27030'],
    popolazione: 588,
  },
  {
    nome: 'Torre Boldone',
    codice: '016214',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L251',
    cap: ['24020'],
    popolazione: 8333,
  },
  {
    nome: 'Torre Bormida',
    codice: '004226',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L252',
    cap: ['12050'],
    popolazione: 211,
  },
  {
    nome: 'Torre Cajetani',
    codice: '060078',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L243',
    cap: ['03010'],
    popolazione: 1388,
  },
  {
    nome: 'Torre Canavese',
    codice: '001274',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L247',
    cap: ['10010'],
    popolazione: 589,
  },
  {
    nome: "Torre d'Arese",
    codice: '018157',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L256',
    cap: ['27010'],
    popolazione: 977,
  },
  {
    nome: "Torre d'Isola",
    codice: '018159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L269',
    cap: ['27020'],
    popolazione: 2395,
  },
  {
    nome: "Torre de' Busi",
    codice: '016215',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L257',
    cap: ['23806'],
    popolazione: 2007,
  },
  {
    nome: "Torre de' Negri",
    codice: '018158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L262',
    cap: ['27011'],
    popolazione: 347,
  },
  {
    nome: "Torre de' Passeri",
    codice: '068043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'L263',
    cap: ['65029'],
    popolazione: 3174,
  },
  {
    nome: "Torre de' Picenardi",
    codice: '019107',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L258',
    cap: ['26038'],
    popolazione: 2249,
  },
  {
    nome: "Torre de' Roveri",
    codice: '016216',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L265',
    cap: ['24060'],
    popolazione: 2310,
  },
  {
    nome: 'Torre del Greco',
    codice: '063084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'L259',
    cap: ['80059'],
    popolazione: 85922,
  },
  {
    nome: 'Torre di Mosto',
    codice: '027041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'L267',
    cap: ['30020'],
    popolazione: 4739,
  },
  {
    nome: 'Torre di Ruggiero',
    codice: '079148',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'L240',
    cap: ['88060'],
    popolazione: 1131,
  },
  {
    nome: 'Torre di Santa Maria',
    codice: '014067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L244',
    cap: ['23020'],
    popolazione: 824,
  },
  {
    nome: 'Torre Le Nocelle',
    codice: '064110',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L272',
    cap: ['83030'],
    popolazione: 1360,
  },
  {
    nome: 'Torre Mondovì',
    codice: '004227',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L241',
    cap: ['12080'],
    popolazione: 494,
  },
  {
    nome: 'Torre Orsaia',
    codice: '065149',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L274',
    cap: ['84077'],
    popolazione: 2185,
  },
  {
    nome: 'Torre Pallavicina',
    codice: '016217',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L276',
    cap: ['24050'],
    popolazione: 1114,
  },
  {
    nome: 'Torre Pellice',
    codice: '001275',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L277',
    cap: ['10066'],
    popolazione: 4573,
  },
  {
    nome: 'Torre San Giorgio',
    codice: '004228',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L278',
    cap: ['12030'],
    popolazione: 709,
  },
  {
    nome: 'Torre San Patrizio',
    codice: '109040',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '109', nome: 'Fermo' },
    sigla: 'FM',
    codiceCatastale: 'L279',
    cap: ['63814'],
    popolazione: 2078,
  },
  {
    nome: 'Torre Santa Susanna',
    codice: '074019',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'L280',
    cap: ['72028'],
    popolazione: 10703,
  },
  {
    nome: 'Torreano',
    codice: '030122',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L246',
    cap: ['33040'],
    popolazione: 2213,
  },
  {
    nome: 'Torrebelvicino',
    codice: '024107',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L248',
    cap: ['36036'],
    popolazione: 5989,
  },
  {
    nome: 'Torrebruna',
    codice: '069093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L253',
    cap: ['66050'],
    popolazione: 924,
  },
  {
    nome: 'Torrecuso',
    codice: '062076',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'L254',
    cap: ['82030'],
    popolazione: 3439,
  },
  {
    nome: 'Torreglia',
    codice: '028092',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L270',
    cap: ['35038'],
    popolazione: 6206,
  },
  {
    nome: 'Torregrotta',
    codice: '083098',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L271',
    cap: ['98040'],
    popolazione: 7426,
  },
  {
    nome: 'Torremaggiore',
    codice: '071056',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'L273',
    cap: ['71017'],
    popolazione: 17365,
  },
  {
    nome: 'Torrenova',
    codice: '083108',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'M286',
    cap: ['98070'],
    popolazione: 4240,
  },
  {
    nome: 'Torresina',
    codice: '004229',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L281',
    cap: ['12070'],
    popolazione: 65,
  },
  {
    nome: 'Torretta',
    codice: '082072',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L282',
    cap: ['90040'],
    popolazione: 4141,
  },
  {
    nome: 'Torrevecchia Pia',
    codice: '018160',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L285',
    cap: ['27010'],
    popolazione: 3427,
  },
  {
    nome: 'Torrevecchia Teatina',
    codice: '069094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L284',
    cap: ['66010'],
    popolazione: 4092,
  },
  {
    nome: 'Torri del Benaco',
    codice: '023086',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L287',
    cap: ['37010'],
    popolazione: 2802,
  },
  {
    nome: 'Torri di Quartesolo',
    codice: '024108',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L297',
    cap: ['36040'],
    popolazione: 11755,
  },
  {
    nome: 'Torri in Sabina',
    codice: '057070',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'L286',
    cap: ['02049'],
    popolazione: 1249,
  },
  {
    nome: 'Torrice',
    codice: '060079',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L290',
    cap: ['03020'],
    popolazione: 4608,
  },
  {
    nome: 'Torricella',
    codice: '073028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '073', nome: 'Taranto' },
    sigla: 'TA',
    codiceCatastale: 'L294',
    cap: ['74020'],
    popolazione: 4233,
  },
  {
    nome: 'Torricella del Pizzo',
    codice: '019108',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L296',
    cap: ['26040'],
    popolazione: 678,
  },
  {
    nome: 'Torricella in Sabina',
    codice: '057069',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'L293',
    cap: ['02030'],
    popolazione: 1405,
  },
  {
    nome: 'Torricella Peligna',
    codice: '069095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L291',
    cap: ['66019'],
    popolazione: 1391,
  },
  {
    nome: 'Torricella Sicura',
    codice: '067043',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'L295',
    cap: ['64010'],
    popolazione: 2670,
  },
  {
    nome: 'Torricella Verzate',
    codice: '018161',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L292',
    cap: ['27050'],
    popolazione: 837,
  },
  {
    nome: 'Torriglia',
    codice: '010062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'L298',
    cap: ['16029'],
    popolazione: 2392,
  },
  {
    nome: 'Torrile',
    codice: '034041',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L299',
    cap: ['43056'],
    popolazione: 7458,
  },
  {
    nome: 'Torrioni',
    codice: '064111',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L301',
    cap: ['83010'],
    popolazione: 578,
  },
  {
    nome: 'Torrita di Siena',
    codice: '052035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'L303',
    cap: ['53049'],
    popolazione: 7357,
  },
  {
    nome: 'Torrita Tiberina',
    codice: '058106',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L302',
    cap: ['00060'],
    popolazione: 1071,
  },
  {
    nome: 'Tortolì',
    codice: '091095',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'A355',
    cap: ['08048'],
    popolazione: 10743,
  },
  {
    nome: 'Tortona',
    codice: '006174',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L304',
    cap: ['15057'],
    popolazione: 25986,
  },
  {
    nome: 'Tortora',
    codice: '078149',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L305',
    cap: ['87020'],
    popolazione: 5997,
  },
  {
    nome: 'Tortorella',
    codice: '065150',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L306',
    cap: ['84030'],
    popolazione: 563,
  },
  {
    nome: 'Tortoreto',
    codice: '067044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'L307',
    cap: ['64018'],
    popolazione: 10442,
  },
  {
    nome: 'Tortorici',
    codice: '083099',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L308',
    cap: ['98078'],
    popolazione: 6732,
  },
  {
    nome: 'Torviscosa',
    codice: '030123',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L309',
    cap: ['33050'],
    popolazione: 2969,
  },
  {
    nome: 'Toscolano-Maderno',
    codice: '017187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L312',
    cap: ['25088'],
    popolazione: 7994,
  },
  {
    nome: 'Tossicia',
    codice: '067045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'L314',
    cap: ['64049'],
    popolazione: 1418,
  },
  {
    nome: "Tovo di Sant'Agata",
    codice: '014068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L316',
    cap: ['23030'],
    popolazione: 624,
  },
  {
    nome: 'Tovo San Giacomo',
    codice: '009062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L315',
    cap: ['17020'],
    popolazione: 2489,
  },
  {
    nome: 'Trabia',
    codice: '082073',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L317',
    cap: ['90019'],
    popolazione: 10360,
  },
  {
    nome: 'Tradate',
    codice: '012127',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L319',
    cap: ['21049'],
    popolazione: 17729,
  },
  {
    nome: 'Tramatza',
    codice: '095066',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L321',
    cap: ['09070'],
    popolazione: 997,
  },
  {
    nome: 'Trambileno',
    codice: '022203',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L322',
    cap: ['38068'],
    popolazione: 1355,
  },
  {
    nome: 'Tramonti',
    codice: '065151',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L323',
    cap: ['84010'],
    popolazione: 4080,
  },
  {
    nome: 'Tramonti di Sopra',
    codice: '093045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'L324',
    cap: ['33090'],
    popolazione: 358,
  },
  {
    nome: 'Tramonti di Sotto',
    codice: '093046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'L325',
    cap: ['33090'],
    popolazione: 410,
  },
  {
    nome: 'Tramutola',
    codice: '076091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L326',
    cap: ['85057'],
    popolazione: 3155,
  },
  {
    nome: 'Trana',
    codice: '001276',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L327',
    cap: ['10090'],
    popolazione: 3881,
  },
  {
    nome: 'Trani',
    codice: '110009',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'L328',
    cap: ['76125'],
    popolazione: 55842,
  },
  {
    nome: 'Traona',
    codice: '014069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L330',
    cap: ['23019'],
    popolazione: 2536,
  },
  {
    nome: 'Trapani',
    codice: '081021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'L331',
    cap: ['91100'],
    popolazione: 69241,
  },
  {
    nome: 'Trappeto',
    codice: '082074',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L332',
    cap: ['90040'],
    popolazione: 3211,
  },
  {
    nome: 'Trarego Viggiona',
    codice: '103066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L333',
    cap: ['28826'],
    popolazione: 392,
  },
  {
    nome: 'Trasacco',
    codice: '066102',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'L334',
    cap: ['67059'],
    popolazione: 6144,
  },
  {
    nome: 'Trasaghis',
    codice: '030124',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L335',
    cap: ['33010'],
    popolazione: 2298,
  },
  {
    nome: 'Trasquera',
    codice: '103067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L336',
    cap: ['28868'],
    popolazione: 211,
  },
  {
    nome: 'Tratalias',
    codice: '111090',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L337',
    cap: ['09010'],
    popolazione: 1107,
  },
  {
    nome: 'Travacò Siccomario',
    codice: '018162',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'I236',
    cap: ['27020'],
    popolazione: 4361,
  },
  {
    nome: 'Travagliato',
    codice: '017188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L339',
    cap: ['25039'],
    popolazione: 13447,
  },
  {
    nome: 'Travedona-Monate',
    codice: '012128',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L342',
    cap: ['21028'],
    popolazione: 4022,
  },
  {
    nome: 'Traversella',
    codice: '001278',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L345',
    cap: ['10080'],
    popolazione: 350,
  },
  {
    nome: 'Traversetolo',
    codice: '034042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L346',
    cap: ['43029'],
    popolazione: 9275,
  },
  {
    nome: 'Traves',
    codice: '001279',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L340',
    cap: ['10070'],
    popolazione: 553,
  },
  {
    nome: 'Travesio',
    codice: '093047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'L347',
    cap: ['33090'],
    popolazione: 1814,
  },
  {
    nome: 'Travo',
    codice: '033043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'L348',
    cap: ['29020'],
    popolazione: 1993,
  },
  {
    nome: 'Tre Ville',
    codice: '022247',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M361',
    cap: ['38095'],
    popolazione: 1396,
  },
  {
    nome: 'Trebaseleghe',
    codice: '028093',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L349',
    cap: ['35010'],
    popolazione: 12579,
  },
  {
    nome: 'Trebisacce',
    codice: '078150',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L353',
    cap: ['87075'],
    popolazione: 8734,
  },
  {
    nome: 'Trecase',
    codice: '063091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'M280',
    cap: ['80040'],
    popolazione: 9118,
  },
  {
    nome: 'Trecastagni',
    codice: '087050',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'L355',
    cap: ['95039'],
    popolazione: 10482,
  },
  {
    nome: 'Trecastelli',
    codice: '042050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '042', nome: 'Ancona' },
    sigla: 'AN',
    codiceCatastale: 'M318',
    cap: ['60012'],
    popolazione: 7577,
  },
  {
    nome: 'Trecate',
    codice: '003149',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L356',
    cap: ['28069'],
    popolazione: 19856,
  },
  {
    nome: 'Trecchina',
    codice: '076092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L357',
    cap: ['85049'],
    popolazione: 2322,
  },
  {
    nome: 'Trecenta',
    codice: '029047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'L359',
    cap: ['45027'],
    popolazione: 2956,
  },
  {
    nome: 'Tredozio',
    codice: '040049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'L361',
    cap: ['47019'],
    popolazione: 1259,
  },
  {
    nome: 'Treglio',
    codice: '069096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L363',
    cap: ['66030'],
    popolazione: 1575,
  },
  {
    nome: 'Tregnago',
    codice: '023087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L364',
    cap: ['37039'],
    popolazione: 4926,
  },
  {
    nome: 'Treia',
    codice: '043054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'L366',
    cap: ['62010'],
    popolazione: 9745,
  },
  {
    nome: 'Treiso',
    codice: '004230',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L367',
    cap: ['12050'],
    popolazione: 820,
  },
  {
    nome: 'Tremestieri Etneo',
    codice: '087051',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'L369',
    cap: ['95030'],
    popolazione: 21032,
  },
  {
    nome: 'Tremezzina',
    codice: '013252',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M341',
    cap: ['22016'],
    popolazione: 5078,
  },
  {
    nome: 'Tremosine sul Garda',
    codice: '017189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L372',
    cap: ['25010'],
    popolazione: 2125,
  },
  {
    nome: 'Trentinara',
    codice: '065152',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L377',
    cap: ['84070'],
    popolazione: 1683,
  },
  {
    nome: 'Trento',
    codice: '022205',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L378',
    cap: ['38121', '38122', '38123'],
    popolazione: 114198,
  },
  {
    nome: 'Trentola Ducenta',
    codice: '061094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L379',
    cap: ['81038'],
    popolazione: 17797,
  },
  {
    nome: 'Trenzano',
    codice: '017190',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L380',
    cap: ['25030'],
    popolazione: 5480,
  },
  {
    nome: 'Treppo Grande',
    codice: '030126',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L382',
    cap: ['33010'],
    popolazione: 1741,
  },
  {
    nome: 'Treppo Ligosullo',
    codice: '030191',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'M399',
    cap: ['33014'],
    popolazione: 784,
  },
  {
    nome: 'Trepuzzi',
    codice: '075087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L383',
    cap: ['73019'],
    popolazione: 14277,
  },
  {
    nome: 'Trequanda',
    codice: '052036',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '052', nome: 'Siena' },
    sigla: 'SI',
    codiceCatastale: 'L384',
    cap: ['53020'],
    popolazione: 1339,
  },
  {
    nome: 'Tresana',
    codice: '045015',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'L386',
    cap: ['54012'],
    popolazione: 2085,
  },
  {
    nome: 'Trescore Balneario',
    codice: '016218',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L388',
    cap: ['24069'],
    popolazione: 9427,
  },
  {
    nome: 'Trescore Cremasco',
    codice: '019109',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L389',
    cap: ['26017'],
    popolazione: 2882,
  },
  {
    nome: 'Tresignana',
    codice: '038030',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'M409',
    cap: ['44039'],
    popolazione: 7364,
  },
  {
    nome: 'Tresivio',
    codice: '014070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L392',
    cap: ['23020'],
    popolazione: 2002,
  },
  {
    nome: 'Tresnuraghes',
    codice: '095067',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L393',
    cap: ['09079'],
    popolazione: 1215,
  },
  {
    nome: 'Trevenzuolo',
    codice: '023088',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L396',
    cap: ['37060'],
    popolazione: 2731,
  },
  {
    nome: 'Trevi',
    codice: '054054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L397',
    cap: ['06039'],
    popolazione: 8335,
  },
  {
    nome: 'Trevi nel Lazio',
    codice: '060080',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L398',
    cap: ['03010'],
    popolazione: 1853,
  },
  {
    nome: 'Trevico',
    codice: '064112',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L399',
    cap: ['83058'],
    popolazione: 1072,
  },
  {
    nome: 'Treviglio',
    codice: '016219',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L400',
    cap: ['24047'],
    popolazione: 28410,
  },
  {
    nome: 'Trevignano',
    codice: '026085',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L402',
    cap: ['31040'],
    popolazione: 10565,
  },
  {
    nome: 'Trevignano Romano',
    codice: '058107',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L401',
    cap: ['00069'],
    popolazione: 5274,
  },
  {
    nome: 'Treville',
    codice: '006175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L403',
    cap: ['15030'],
    popolazione: 271,
  },
  {
    nome: 'Treviolo',
    codice: '016220',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L404',
    cap: ['24048'],
    popolazione: 10302,
  },
  {
    nome: 'Treviso',
    codice: '026086',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L407',
    cap: ['31100'],
    popolazione: 81014,
  },
  {
    nome: 'Treviso Bresciano',
    codice: '017191',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L406',
    cap: ['25070'],
    popolazione: 566,
  },
  {
    nome: 'Trezzano Rosa',
    codice: '015219',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L408',
    cap: ['20060'],
    popolazione: 4861,
  },
  {
    nome: 'Trezzano sul Naviglio',
    codice: '015220',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L409',
    cap: ['20090'],
    popolazione: 20018,
  },
  {
    nome: "Trezzo sull'Adda",
    codice: '015221',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L411',
    cap: ['20056'],
    popolazione: 11883,
  },
  {
    nome: 'Trezzo Tinella',
    codice: '004231',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L410',
    cap: ['12050'],
    popolazione: 341,
  },
  {
    nome: 'Trezzone',
    codice: '013226',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L413',
    cap: ['22010'],
    popolazione: 237,
  },
  {
    nome: 'Tribano',
    codice: '028094',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L414',
    cap: ['35020'],
    popolazione: 4431,
  },
  {
    nome: 'Tribiano',
    codice: '015222',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L415',
    cap: ['20067'],
    popolazione: 3312,
  },
  {
    nome: 'Tribogna',
    codice: '010063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'L416',
    cap: ['16030'],
    popolazione: 620,
  },
  {
    nome: 'Tricarico',
    codice: '077028',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'L418',
    cap: ['75019'],
    popolazione: 5669,
  },
  {
    nome: 'Tricase',
    codice: '075088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L419',
    cap: ['73039'],
    popolazione: 17665,
  },
  {
    nome: 'Tricerro',
    codice: '002147',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'L420',
    cap: ['13038'],
    popolazione: 709,
  },
  {
    nome: 'Tricesimo',
    codice: '030127',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L421',
    cap: ['33019'],
    popolazione: 7609,
  },
  {
    nome: 'Triei',
    codice: '091097',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L423',
    cap: ['08040'],
    popolazione: 1126,
  },
  {
    nome: 'Trieste',
    codice: '032006',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '032', nome: 'Trieste' },
    sigla: 'TS',
    codiceCatastale: 'L424',
    cap: [
      '34121',
      '34122',
      '34123',
      '34124',
      '34125',
      '34126',
      '34127',
      '34128',
      '34129',
      '34130',
      '34131',
      '34132',
      '34133',
      '34134',
      '34135',
      '34136',
      '34137',
      '34138',
      '34139',
      '34140',
      '34141',
      '34142',
      '34143',
      '34144',
      '34145',
      '34146',
      '34147',
      '34148',
      '34149',
      '34150',
      '34151',
    ],
    popolazione: 202123,
  },
  {
    nome: 'Triggiano',
    codice: '072046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'L425',
    cap: ['70019'],
    popolazione: 27007,
  },
  {
    nome: 'Trigolo',
    codice: '019110',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L426',
    cap: ['26018'],
    popolazione: 1760,
  },
  {
    nome: 'Trinità',
    codice: '004232',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L427',
    cap: ['12049'],
    popolazione: 2188,
  },
  {
    nome: "Trinità d'Agultu e Vignola",
    codice: '090074',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L428',
    cap: ['07038'],
    popolazione: 2155,
  },
  {
    nome: 'Trinitapoli',
    codice: '110010',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '110', nome: 'Barletta-Andria-Trani' },
    sigla: 'BT',
    codiceCatastale: 'B915',
    cap: ['76015'],
    popolazione: 14293,
  },
  {
    nome: 'Trino',
    codice: '002148',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'L429',
    cap: ['13039'],
    popolazione: 7437,
  },
  {
    nome: 'Triora',
    codice: '008061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L430',
    cap: ['18010'],
    popolazione: 374,
  },
  {
    nome: 'Tripi',
    codice: '083100',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L431',
    cap: ['98060'],
    popolazione: 933,
  },
  {
    nome: 'Trisobbio',
    codice: '006176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L432',
    cap: ['15070'],
    popolazione: 671,
  },
  {
    nome: 'Trissino',
    codice: '024110',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L433',
    cap: ['36070'],
    popolazione: 8620,
  },
  {
    nome: 'Triuggio',
    codice: '108043',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'L434',
    cap: ['20844'],
    popolazione: 8546,
  },
  {
    nome: 'Trivento',
    codice: '070081',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L435',
    cap: ['86029'],
    popolazione: 4812,
  },
  {
    nome: 'Trivigliano',
    codice: '060081',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L437',
    cap: ['03010'],
    popolazione: 1693,
  },
  {
    nome: 'Trivignano Udinese',
    codice: '030128',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L438',
    cap: ['33050'],
    popolazione: 1689,
  },
  {
    nome: 'Trivigno',
    codice: '076093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L439',
    cap: ['85018'],
    popolazione: 714,
  },
  {
    nome: 'Trivolzio',
    codice: '018163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L440',
    cap: ['27020'],
    popolazione: 2036,
  },
  {
    nome: 'Trodena nel parco naturale',
    codice: '021102',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L444',
    cap: ['39040'],
    popolazione: 1021,
  },
  {
    nome: 'Trofarello',
    codice: '001280',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L445',
    cap: ['10028'],
    popolazione: 10911,
  },
  {
    nome: 'Troia',
    codice: '071058',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'L447',
    cap: ['71029'],
    popolazione: 7330,
  },
  {
    nome: 'Troina',
    codice: '086018',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'L448',
    cap: ['94018'],
    popolazione: 9628,
  },
  {
    nome: 'Tromello',
    codice: '018164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L449',
    cap: ['27020'],
    popolazione: 3828,
  },
  {
    nome: 'Trontano',
    codice: '103068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L450',
    cap: ['28859'],
    popolazione: 1702,
  },
  {
    nome: 'Tronzano Lago Maggiore',
    codice: '012129',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'A705',
    cap: ['21010'],
    popolazione: 254,
  },
  {
    nome: 'Tronzano Vercellese',
    codice: '002150',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'L451',
    cap: ['13049'],
    popolazione: 3558,
  },
  {
    nome: 'Tropea',
    codice: '102044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'L452',
    cap: ['89861'],
    popolazione: 6555,
  },
  {
    nome: 'Trovo',
    codice: '018165',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L453',
    cap: ['27020'],
    popolazione: 1023,
  },
  {
    nome: 'Truccazzano',
    codice: '015224',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L454',
    cap: ['20060'],
    popolazione: 5968,
  },
  {
    nome: 'Tubre',
    codice: '021103',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L455',
    cap: ['39020'],
    popolazione: 965,
  },
  {
    nome: 'Tufara',
    codice: '070082',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L458',
    cap: ['86010'],
    popolazione: 978,
  },
  {
    nome: 'Tufillo',
    codice: '069097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L459',
    cap: ['66050'],
    popolazione: 468,
  },
  {
    nome: 'Tufino',
    codice: '063085',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'L460',
    cap: ['80030'],
    popolazione: 3785,
  },
  {
    nome: 'Tufo',
    codice: '064113',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L461',
    cap: ['83010'],
    popolazione: 924,
  },
  {
    nome: 'Tuglie',
    codice: '075089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L462',
    cap: ['73058'],
    popolazione: 5264,
  },
  {
    nome: 'Tuili',
    codice: '111091',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L463',
    cap: ['09029'],
    popolazione: 1062,
  },
  {
    nome: 'Tula',
    codice: '090075',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L464',
    cap: ['07010'],
    popolazione: 1598,
  },
  {
    nome: 'Tuoro sul Trasimeno',
    codice: '054055',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L466',
    cap: ['06069'],
    popolazione: 3850,
  },
  {
    nome: 'Turania',
    codice: '057071',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'G507',
    cap: ['02020'],
    popolazione: 245,
  },
  {
    nome: 'Turano Lodigiano',
    codice: '098058',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'L469',
    cap: ['26828'],
    popolazione: 1538,
  },
  {
    nome: 'Turate',
    codice: '013227',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L470',
    cap: ['22078'],
    popolazione: 8973,
  },
  {
    nome: 'Turbigo',
    codice: '015226',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L471',
    cap: ['20029'],
    popolazione: 7389,
  },
  {
    nome: 'Turi',
    codice: '072047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'L472',
    cap: ['70010'],
    popolazione: 12748,
  },
  {
    nome: 'Turri',
    codice: '111092',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L473',
    cap: ['09020'],
    popolazione: 442,
  },
  {
    nome: 'Turriaco',
    codice: '031024',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'L474',
    cap: ['34070'],
    popolazione: 2780,
  },
  {
    nome: 'Turrivalignani',
    codice: '068044',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'L475',
    cap: ['65020'],
    popolazione: 884,
  },
  {
    nome: 'Tursi',
    codice: '077029',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'L477',
    cap: ['75028'],
    popolazione: 5151,
  },
  {
    nome: 'Tusa',
    codice: '083101',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L478',
    cap: ['98079'],
    popolazione: 3051,
  },
  {
    nome: 'Tuscania',
    codice: '056052',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L310',
    cap: ['01017'],
    popolazione: 8145,
  },
  {
    nome: 'Ubiale Clanezzo',
    codice: '016221',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'C789',
    cap: ['24010'],
    popolazione: 1399,
  },
  {
    nome: 'Uboldo',
    codice: '012130',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L480',
    cap: ['21040'],
    popolazione: 10446,
  },
  {
    nome: 'Ucria',
    codice: '083102',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L482',
    cap: ['98060'],
    popolazione: 1105,
  },
  {
    nome: 'Udine',
    codice: '030129',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L483',
    cap: ['33100'],
    popolazione: 98287,
  },
  {
    nome: 'Ugento',
    codice: '075090',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L484',
    cap: ['73059'],
    popolazione: 12001,
  },
  {
    nome: 'Uggiano la Chiesa',
    codice: '075091',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L485',
    cap: ['73020'],
    popolazione: 4479,
  },
  {
    nome: 'Uggiate-Trevano',
    codice: '013228',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L487',
    cap: ['22029'],
    popolazione: 4451,
  },
  {
    nome: 'Ulà Tirso',
    codice: '095068',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L488',
    cap: ['09080'],
    popolazione: 580,
  },
  {
    nome: 'Ulassai',
    codice: '091098',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L489',
    cap: ['08040'],
    popolazione: 1517,
  },
  {
    nome: 'Ultimo',
    codice: '021104',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L490',
    cap: ['39016'],
    popolazione: 2920,
  },
  {
    nome: 'Umbertide',
    codice: '054056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'D786',
    cap: ['06019'],
    popolazione: 16481,
  },
  {
    nome: 'Umbriatico',
    codice: '101026',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'L492',
    cap: ['88823'],
    popolazione: 929,
  },
  {
    nome: "Urago d'Oglio",
    codice: '017192',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L494',
    cap: ['25030'],
    popolazione: 3877,
  },
  {
    nome: 'Uras',
    codice: '095069',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L496',
    cap: ['09099'],
    popolazione: 2960,
  },
  {
    nome: 'Urbana',
    codice: '028095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L497',
    cap: ['35040'],
    popolazione: 2186,
  },
  {
    nome: 'Urbania',
    codice: '041066',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'L498',
    cap: ['61049'],
    popolazione: 7077,
  },
  {
    nome: 'Urbe',
    codice: '009063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L499',
    cap: ['17048'],
    popolazione: 769,
  },
  {
    nome: 'Urbino',
    codice: '041067',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'L500',
    cap: ['61029'],
    popolazione: 15501,
  },
  {
    nome: 'Urbisaglia',
    codice: '043055',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'L501',
    cap: ['62010'],
    popolazione: 2712,
  },
  {
    nome: 'Urgnano',
    codice: '016222',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L502',
    cap: ['24059'],
    popolazione: 9549,
  },
  {
    nome: 'Uri',
    codice: '090076',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L503',
    cap: ['07040'],
    popolazione: 3016,
  },
  {
    nome: 'Ururi',
    codice: '070083',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'L505',
    cap: ['86049'],
    popolazione: 2793,
  },
  {
    nome: 'Urzulei',
    codice: '091099',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L506',
    cap: ['08040'],
    popolazione: 1295,
  },
  {
    nome: 'Uscio',
    codice: '010064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'L507',
    cap: ['16036'],
    popolazione: 2275,
  },
  {
    nome: 'Usellus',
    codice: '095070',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L508',
    cap: ['09090'],
    popolazione: 854,
  },
  {
    nome: 'Usini',
    codice: '090077',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L509',
    cap: ['07049'],
    popolazione: 4321,
  },
  {
    nome: 'Usmate Velate',
    codice: '108044',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'L511',
    cap: ['20865'],
    popolazione: 10033,
  },
  {
    nome: 'Ussana',
    codice: '111093',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L512',
    cap: ['09020'],
    popolazione: 4285,
  },
  {
    nome: 'Ussaramanna',
    codice: '111094',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L513',
    cap: ['09020'],
    popolazione: 556,
  },
  {
    nome: 'Ussassai',
    codice: '091100',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L514',
    cap: ['08040'],
    popolazione: 599,
  },
  {
    nome: 'Usseaux',
    codice: '001281',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L515',
    cap: ['10060'],
    popolazione: 185,
  },
  {
    nome: 'Usseglio',
    codice: '001282',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L516',
    cap: ['10070'],
    popolazione: 219,
  },
  {
    nome: 'Ussita',
    codice: '043056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'L517',
    cap: ['62039'],
    popolazione: 420,
  },
  {
    nome: 'Ustica',
    codice: '082075',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L519',
    cap: ['90051'],
    popolazione: 1287,
  },
  {
    nome: 'Uta',
    codice: '092090',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'L521',
    cap: ['09068'],
    popolazione: 7859,
  },
  {
    nome: 'Uzzano',
    codice: '047021',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '047', nome: 'Pistoia' },
    sigla: 'PT',
    codiceCatastale: 'L522',
    cap: ['51010'],
    popolazione: 5690,
  },
  {
    nome: 'Vaccarizzo Albanese',
    codice: '078152',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L524',
    cap: ['87060'],
    popolazione: 1184,
  },
  {
    nome: 'Vacone',
    codice: '057072',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'L525',
    cap: ['02040'],
    popolazione: 264,
  },
  {
    nome: 'Vacri',
    codice: '069098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L526',
    cap: ['66010'],
    popolazione: 1702,
  },
  {
    nome: 'Vadena',
    codice: '021105',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L527',
    cap: ['39051'],
    popolazione: 1016,
  },
  {
    nome: 'Vado Ligure',
    codice: '009064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L528',
    cap: ['17047'],
    popolazione: 8232,
  },
  {
    nome: 'Vagli Sotto',
    codice: '046031',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'L533',
    cap: ['55030'],
    popolazione: 991,
  },
  {
    nome: 'Vaglia',
    codice: '048046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'L529',
    cap: ['50036'],
    popolazione: 5065,
  },
  {
    nome: 'Vaglio Basilicata',
    codice: '076094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L532',
    cap: ['85010'],
    popolazione: 2074,
  },
  {
    nome: 'Vaglio Serra',
    codice: '005111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L531',
    cap: ['14049'],
    popolazione: 284,
  },
  {
    nome: 'Vaiano',
    codice: '100006',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'L537',
    cap: ['59021'],
    popolazione: 9821,
  },
  {
    nome: 'Vaiano Cremasco',
    codice: '019111',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L535',
    cap: ['26010'],
    popolazione: 3869,
  },
  {
    nome: 'Vaie',
    codice: '001283',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L538',
    cap: ['10050'],
    popolazione: 1455,
  },
  {
    nome: 'Vailate',
    codice: '019112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L539',
    cap: ['26019'],
    popolazione: 4460,
  },
  {
    nome: 'Vairano Patenora',
    codice: '061095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L540',
    cap: ['81058'],
    popolazione: 6658,
  },
  {
    nome: 'Vajont',
    codice: '093052',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'M265',
    cap: ['33080'],
    popolazione: 1715,
  },
  {
    nome: 'Val Brembilla',
    codice: '016253',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M334',
    cap: ['24012'],
    popolazione: 4522,
  },
  {
    nome: 'Val della Torre',
    codice: '001284',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L555',
    cap: ['10040'],
    popolazione: 3812,
  },
  {
    nome: 'Val di Chy',
    codice: '001317',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M405',
    cap: ['10039'],
    popolazione: 1289,
  },
  {
    nome: 'Val di Nizza',
    codice: '018166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L562',
    cap: ['27050'],
    popolazione: 655,
  },
  {
    nome: 'Val di Vizze',
    codice: '021107',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L564',
    cap: ['39049'],
    popolazione: 2736,
  },
  {
    nome: 'Val di Zoldo',
    codice: '025073',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M374',
    cap: ['32012'],
    popolazione: 3464,
  },
  {
    nome: 'Val Liona',
    codice: '024123',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M384',
    cap: ['36044'],
    popolazione: 3047,
  },
  {
    nome: 'Val Masino',
    codice: '014074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L638',
    cap: ['23010'],
    popolazione: 939,
  },
  {
    nome: 'Val Rezzo',
    codice: '013233',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'H259',
    cap: ['22010'],
    popolazione: 179,
  },
  {
    nome: 'Valbondione',
    codice: '016223',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L544',
    cap: ['24020'],
    popolazione: 1085,
  },
  {
    nome: 'Valbrembo',
    codice: '016224',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L545',
    cap: ['24030'],
    popolazione: 3886,
  },
  {
    nome: 'Valbrenta',
    codice: '024125',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M423',
    cap: ['36029'],
    popolazione: 5385,
  },
  {
    nome: 'Valbrevenna',
    codice: '010065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'L546',
    cap: ['16010'],
    popolazione: 812,
  },
  {
    nome: 'Valbrona',
    codice: '013229',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L547',
    cap: ['22039'],
    popolazione: 2656,
  },
  {
    nome: 'Valchiusa',
    codice: '001318',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M415',
    cap: ['10089'],
    popolazione: 1106,
  },
  {
    nome: 'Valdagno',
    codice: '024111',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L551',
    cap: ['36078'],
    popolazione: 26575,
  },
  {
    nome: 'Valdaone',
    codice: '022232',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M343',
    cap: ['38091'],
    popolazione: 1220,
  },
  {
    nome: 'Valdaora',
    codice: '021106',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L552',
    cap: ['39030'],
    popolazione: 3110,
  },
  {
    nome: 'Valdastico',
    codice: '024112',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L554',
    cap: ['36040'],
    popolazione: 1389,
  },
  {
    nome: 'Valdengo',
    codice: '096071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L556',
    cap: ['13855'],
    popolazione: 2532,
  },
  {
    nome: 'Valderice',
    codice: '081022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'G319',
    cap: ['91019'],
    popolazione: 11951,
  },
  {
    nome: 'Valdidentro',
    codice: '014071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L557',
    cap: ['23038'],
    popolazione: 4045,
  },
  {
    nome: 'Valdieri',
    codice: '004233',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L558',
    cap: ['12010'],
    popolazione: 924,
  },
  {
    nome: 'Valdilana',
    codice: '096088',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M417',
    cap: ['13835'],
    popolazione: 12067,
  },
  {
    nome: 'Valdina',
    codice: '083103',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L561',
    cap: ['98040'],
    popolazione: 1352,
  },
  {
    nome: 'Valdisotto',
    codice: '014072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L563',
    cap: ['23030'],
    popolazione: 3533,
  },
  {
    nome: 'Valdobbiadene',
    codice: '026087',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L565',
    cap: ['31049'],
    popolazione: 10690,
  },
  {
    nome: 'Valduggia',
    codice: '002152',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'L566',
    cap: ['13018'],
    popolazione: 2117,
  },
  {
    nome: 'Valeggio',
    codice: '018167',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L568',
    cap: ['27020'],
    popolazione: 236,
  },
  {
    nome: 'Valeggio sul Mincio',
    codice: '023089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L567',
    cap: ['37067'],
    popolazione: 14300,
  },
  {
    nome: 'Valentano',
    codice: '056053',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L569',
    cap: ['01018'],
    popolazione: 2895,
  },
  {
    nome: 'Valenza',
    codice: '006177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L570',
    cap: ['15048'],
    popolazione: 19671,
  },
  {
    nome: 'Valenzano',
    codice: '072048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '072', nome: 'Bari' },
    sigla: 'BA',
    codiceCatastale: 'L571',
    cap: ['70010'],
    popolazione: 17897,
  },
  {
    nome: 'Valera Fratta',
    codice: '098059',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'L572',
    cap: ['26859'],
    popolazione: 1669,
  },
  {
    nome: 'Valfabbrica',
    codice: '054057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L573',
    cap: ['06029'],
    popolazione: 3502,
  },
  {
    nome: 'Valfenera',
    codice: '005112',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L574',
    cap: ['14017'],
    popolazione: 2519,
  },
  {
    nome: 'Valfloriana',
    codice: '022209',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L575',
    cap: ['38040'],
    popolazione: 530,
  },
  {
    nome: 'Valfornace',
    codice: '043058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'M382',
    cap: ['62031'],
    popolazione: 1051,
  },
  {
    nome: 'Valfurva',
    codice: '014073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L576',
    cap: ['23030'],
    popolazione: 2703,
  },
  {
    nome: 'Valganna',
    codice: '012131',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L577',
    cap: ['21039'],
    popolazione: 1584,
  },
  {
    nome: 'Valgioie',
    codice: '001285',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L578',
    cap: ['10094'],
    popolazione: 948,
  },
  {
    nome: 'Valgoglio',
    codice: '016225',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L579',
    cap: ['24020'],
    popolazione: 607,
  },
  {
    nome: 'Valgrana',
    codice: '004234',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L580',
    cap: ['12020'],
    popolazione: 817,
  },
  {
    nome: 'Valgreghentino',
    codice: '097082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'L581',
    cap: ['23857'],
    popolazione: 3410,
  },
  {
    nome: 'Valgrisenche',
    codice: '007068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L582',
    cap: ['11010'],
    popolazione: 198,
  },
  {
    nome: 'Valguarnera Caropepe',
    codice: '086019',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'L583',
    cap: ['94019'],
    popolazione: 8182,
  },
  {
    nome: 'Vallada Agordina',
    codice: '025062',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'L584',
    cap: ['32020'],
    popolazione: 514,
  },
  {
    nome: 'Vallanzengo',
    codice: '096072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L586',
    cap: ['13847'],
    popolazione: 239,
  },
  {
    nome: 'Vallarsa',
    codice: '022210',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L588',
    cap: ['38060'],
    popolazione: 1343,
  },
  {
    nome: 'Vallata',
    codice: '064114',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L589',
    cap: ['83059'],
    popolazione: 2856,
  },
  {
    nome: 'Valle Agricola',
    codice: '061096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L594',
    cap: ['81010'],
    popolazione: 975,
  },
  {
    nome: 'Valle Aurina',
    codice: '021108',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L595',
    cap: ['39030'],
    popolazione: 5910,
  },
  {
    nome: 'Valle Cannobina',
    codice: '103079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'M404',
    cap: ['28827'],
    popolazione: 518,
  },
  {
    nome: 'Valle Castellana',
    codice: '067046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '067', nome: 'Teramo' },
    sigla: 'TE',
    codiceCatastale: 'L597',
    cap: ['64010'],
    popolazione: 1029,
  },
  {
    nome: "Valle dell'Angelo",
    codice: '065153',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'G540',
    cap: ['84070'],
    popolazione: 280,
  },
  {
    nome: 'Valle di Cadore',
    codice: '025063',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'L590',
    cap: ['32040'],
    popolazione: 2052,
  },
  {
    nome: 'Valle di Casies',
    codice: '021109',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L601',
    cap: ['39030'],
    popolazione: 2262,
  },
  {
    nome: 'Valle di Maddaloni',
    codice: '061097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L591',
    cap: ['81020'],
    popolazione: 2807,
  },
  {
    nome: 'Valle Lomellina',
    codice: '018168',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L593',
    cap: ['27020'],
    popolazione: 2153,
  },
  {
    nome: 'Valle Salimbene',
    codice: '018169',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L617',
    cap: ['27010'],
    popolazione: 1537,
  },
  {
    nome: 'Valle San Nicolao',
    codice: '096074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L620',
    cap: ['13847'],
    popolazione: 1086,
  },
  {
    nome: 'Vallebona',
    codice: '008062',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L596',
    cap: ['18012'],
    popolazione: 1332,
  },
  {
    nome: 'Vallecorsa',
    codice: '060082',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L598',
    cap: ['03020'],
    popolazione: 2800,
  },
  {
    nome: 'Vallecrosia',
    codice: '008063',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L599',
    cap: ['18019'],
    popolazione: 7032,
  },
  {
    nome: 'Valledolmo',
    codice: '082076',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L603',
    cap: ['90029'],
    popolazione: 3747,
  },
  {
    nome: 'Valledoria',
    codice: '090079',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L604',
    cap: ['07039'],
    popolazione: 4091,
  },
  {
    nome: 'Vallefiorita',
    codice: '079151',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'I322',
    cap: ['88050'],
    popolazione: 1849,
  },
  {
    nome: 'Vallefoglia',
    codice: '041068',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '041', nome: 'Pesaro e Urbino' },
    sigla: 'PU',
    codiceCatastale: 'M331',
    cap: ['61022'],
    popolazione: 14814,
  },
  {
    nome: 'Vallelaghi',
    codice: '022248',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M362',
    cap: ['38096'],
    popolazione: 4798,
  },
  {
    nome: 'Vallelonga',
    codice: '102045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'L607',
    cap: ['89821'],
    popolazione: 682,
  },
  {
    nome: 'Vallelunga Pratameno',
    codice: '085021',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'L609',
    cap: ['93010'],
    popolazione: 3641,
  },
  {
    nome: 'Vallemaio',
    codice: '060083',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L605',
    cap: ['03040'],
    popolazione: 1002,
  },
  {
    nome: 'Vallepietra',
    codice: '058108',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L611',
    cap: ['00020'],
    popolazione: 306,
  },
  {
    nome: 'Vallerano',
    codice: '056054',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L612',
    cap: ['01030'],
    popolazione: 2613,
  },
  {
    nome: 'Vallermosa',
    codice: '111095',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L613',
    cap: ['09010'],
    popolazione: 1944,
  },
  {
    nome: 'Vallerotonda',
    codice: '060084',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L614',
    cap: ['03040'],
    popolazione: 1671,
  },
  {
    nome: 'Vallesaccarda',
    codice: '064115',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L616',
    cap: ['83050'],
    popolazione: 1418,
  },
  {
    nome: 'Valleve',
    codice: '016226',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L623',
    cap: ['24010'],
    popolazione: 136,
  },
  {
    nome: 'Valli del Pasubio',
    codice: '024113',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L624',
    cap: ['36030'],
    popolazione: 3345,
  },
  {
    nome: 'Vallinfreda',
    codice: '058109',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L625',
    cap: ['00020'],
    popolazione: 317,
  },
  {
    nome: 'Vallio Terme',
    codice: '017193',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L626',
    cap: ['25080'],
    popolazione: 1372,
  },
  {
    nome: 'Vallo della Lucania',
    codice: '065154',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L628',
    cap: ['84078'],
    popolazione: 8680,
  },
  {
    nome: 'Vallo di Nera',
    codice: '054058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L627',
    cap: ['06040'],
    popolazione: 401,
  },
  {
    nome: 'Vallo Torinese',
    codice: '001286',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L629',
    cap: ['10070'],
    popolazione: 785,
  },
  {
    nome: 'Valloriate',
    codice: '004235',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L631',
    cap: ['12010'],
    popolazione: 121,
  },
  {
    nome: 'Valmacca',
    codice: '006178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L633',
    cap: ['15040'],
    popolazione: 1055,
  },
  {
    nome: 'Valmadrera',
    codice: '097083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'L634',
    cap: ['23868'],
    popolazione: 11612,
  },
  {
    nome: 'Valmontone',
    codice: '058110',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L639',
    cap: ['00038'],
    popolazione: 14975,
  },
  {
    nome: 'Valmorea',
    codice: '013232',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L640',
    cap: ['22070'],
    popolazione: 2656,
  },
  {
    nome: 'Valmozzola',
    codice: '034044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L641',
    cap: ['43050'],
    popolazione: 567,
  },
  {
    nome: 'Valnegra',
    codice: '016227',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L642',
    cap: ['24010'],
    popolazione: 207,
  },
  {
    nome: 'Valpelline',
    codice: '007069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L643',
    cap: ['11010'],
    popolazione: 655,
  },
  {
    nome: 'Valperga',
    codice: '001287',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L644',
    cap: ['10087'],
    popolazione: 3163,
  },
  {
    nome: 'Valprato Soana',
    codice: '001288',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'B510',
    cap: ['10080'],
    popolazione: 112,
  },
  {
    nome: 'Valsamoggia',
    codice: '037061',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'M320',
    cap: ['40053'],
    popolazione: 29427,
  },
  {
    nome: 'Valsavarenche',
    codice: '007070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L647',
    cap: ['11010'],
    popolazione: 187,
  },
  {
    nome: 'Valsinni',
    codice: '077030',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '077', nome: 'Matera' },
    sigla: 'MT',
    codiceCatastale: 'D513',
    cap: ['75029'],
    popolazione: 1634,
  },
  {
    nome: 'Valsolda',
    codice: '013234',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'C936',
    cap: ['22010'],
    popolazione: 1647,
  },
  {
    nome: 'Valstrona',
    codice: '103069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L651',
    cap: ['28897'],
    popolazione: 1268,
  },
  {
    nome: 'Valtopina',
    codice: '054059',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '10', nome: 'Umbria' },
    provincia: { codice: '054', nome: 'Perugia' },
    sigla: 'PG',
    codiceCatastale: 'L653',
    cap: ['06030'],
    popolazione: 1486,
  },
  {
    nome: 'Valtorta',
    codice: '016229',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L655',
    cap: ['24010'],
    popolazione: 292,
  },
  {
    nome: 'Valtournenche',
    codice: '007071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L654',
    cap: ['11028'],
    popolazione: 2147,
  },
  {
    nome: 'Valva',
    codice: '065155',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L656',
    cap: ['84020'],
    popolazione: 1712,
  },
  {
    nome: 'Valvarrone',
    codice: '097093',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'M395',
    cap: ['23836'],
    popolazione: 615,
  },
  {
    nome: 'Valvasone Arzene',
    codice: '093053',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'M346',
    cap: ['33098'],
    popolazione: 3967,
  },
  {
    nome: 'Valverde',
    codice: '087052',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'L658',
    cap: ['95028'],
    popolazione: 7714,
  },
  {
    nome: 'Valvestino',
    codice: '017194',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L468',
    cap: ['25080'],
    popolazione: 212,
  },
  {
    nome: 'Vandoies',
    codice: '021110',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L660',
    cap: ['39030'],
    popolazione: 3251,
  },
  {
    nome: 'Vanzaghello',
    codice: '015249',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L664',
    cap: ['20020'],
    popolazione: 5344,
  },
  {
    nome: 'Vanzago',
    codice: '015229',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L665',
    cap: ['20010'],
    popolazione: 8914,
  },
  {
    nome: 'Vanzone con San Carlo',
    codice: '103070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L666',
    cap: ['28879'],
    popolazione: 435,
  },
  {
    nome: "Vaprio d'Adda",
    codice: '015230',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L667',
    cap: ['20069'],
    popolazione: 8126,
  },
  {
    nome: "Vaprio d'Agogna",
    codice: '003153',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L668',
    cap: ['28010'],
    popolazione: 1015,
  },
  {
    nome: 'Varallo',
    codice: '002156',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'L669',
    cap: ['13019'],
    popolazione: 7542,
  },
  {
    nome: 'Varallo Pombia',
    codice: '003154',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L670',
    cap: ['28040'],
    popolazione: 5004,
  },
  {
    nome: 'Varano Borghi',
    codice: '012132',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L671',
    cap: ['21020'],
    popolazione: 2418,
  },
  {
    nome: "Varano de' Melegari",
    codice: '034045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L672',
    cap: ['43040'],
    popolazione: 2689,
  },
  {
    nome: 'Varapodio',
    codice: '080095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'L673',
    cap: ['89010'],
    popolazione: 2223,
  },
  {
    nome: 'Varazze',
    codice: '009065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L675',
    cap: ['17019'],
    popolazione: 13461,
  },
  {
    nome: 'Varco Sabino',
    codice: '057073',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '057', nome: 'Rieti' },
    sigla: 'RI',
    codiceCatastale: 'L676',
    cap: ['02020'],
    popolazione: 210,
  },
  {
    nome: 'Varedo',
    codice: '108045',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'L677',
    cap: ['20814'],
    popolazione: 12773,
  },
  {
    nome: 'Varenna',
    codice: '097084',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'L680',
    cap: ['23829'],
    popolazione: 765,
  },
  {
    nome: 'Varese',
    codice: '012133',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L682',
    cap: ['21100'],
    popolazione: 79793,
  },
  {
    nome: 'Varese Ligure',
    codice: '011029',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'L681',
    cap: ['19028'],
    popolazione: 2103,
  },
  {
    nome: 'Varisella',
    codice: '001289',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L685',
    cap: ['10070'],
    popolazione: 830,
  },
  {
    nome: 'Varmo',
    codice: '030130',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L686',
    cap: ['33030'],
    popolazione: 2830,
  },
  {
    nome: 'Varna',
    codice: '021111',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L687',
    cap: ['39040'],
    popolazione: 4253,
  },
  {
    nome: 'Varsi',
    codice: '034046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '034', nome: 'Parma' },
    sigla: 'PR',
    codiceCatastale: 'L689',
    cap: ['43049'],
    popolazione: 1281,
  },
  {
    nome: 'Varzi',
    codice: '018171',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L690',
    cap: ['27057'],
    popolazione: 3405,
  },
  {
    nome: 'Varzo',
    codice: '103071',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L691',
    cap: ['28868'],
    popolazione: 2106,
  },
  {
    nome: 'Vasanello',
    codice: '056055',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'A701',
    cap: ['01030'],
    popolazione: 4161,
  },
  {
    nome: 'Vasia',
    codice: '008064',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L693',
    cap: ['18020'],
    popolazione: 424,
  },
  {
    nome: 'Vasto',
    codice: '069099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'E372',
    cap: ['66054'],
    popolazione: 38747,
  },
  {
    nome: 'Vastogirardi',
    codice: '094051',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'L696',
    cap: ['86089'],
    popolazione: 728,
  },
  {
    nome: 'Vauda Canavese',
    codice: '001290',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L698',
    cap: ['10070'],
    popolazione: 1465,
  },
  {
    nome: 'Vazzano',
    codice: '102046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'L699',
    cap: ['89834'],
    popolazione: 1106,
  },
  {
    nome: 'Vazzola',
    codice: '026088',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L700',
    cap: ['31028'],
    popolazione: 7009,
  },
  {
    nome: 'Vecchiano',
    codice: '050037',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'L702',
    cap: ['56019'],
    popolazione: 12366,
  },
  {
    nome: 'Vedano al Lambro',
    codice: '108046',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'L704',
    cap: ['20854'],
    popolazione: 7426,
  },
  {
    nome: 'Vedano Olona',
    codice: '012134',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L703',
    cap: ['21040'],
    popolazione: 7301,
  },
  {
    nome: 'Vedelago',
    codice: '026089',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L706',
    cap: ['31050'],
    popolazione: 16434,
  },
  {
    nome: 'Vedeseta',
    codice: '016230',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L707',
    cap: ['24010'],
    popolazione: 210,
  },
  {
    nome: 'Veduggio con Colzano',
    codice: '108047',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'L709',
    cap: ['20837'],
    popolazione: 4434,
  },
  {
    nome: 'Veggiano',
    codice: '028096',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L710',
    cap: ['35030'],
    popolazione: 4543,
  },
  {
    nome: 'Veglie',
    codice: '075092',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L711',
    cap: ['73010'],
    popolazione: 14304,
  },
  {
    nome: 'Veglio',
    codice: '096075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L712',
    cap: ['13824'],
    popolazione: 566,
  },
  {
    nome: 'Vejano',
    codice: '056056',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L713',
    cap: ['01010'],
    popolazione: 2298,
  },
  {
    nome: 'Veleso',
    codice: '013236',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L715',
    cap: ['22020'],
    popolazione: 274,
  },
  {
    nome: 'Velezzo Lomellina',
    codice: '018172',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L716',
    cap: ['27020'],
    popolazione: 101,
  },
  {
    nome: 'Velletri',
    codice: '058111',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L719',
    cap: ['00049'],
    popolazione: 52295,
  },
  {
    nome: 'Vellezzo Bellini',
    codice: '018173',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L720',
    cap: ['27010'],
    popolazione: 3066,
  },
  {
    nome: "Velo d'Astico",
    codice: '024115',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L723',
    cap: ['36010'],
    popolazione: 2400,
  },
  {
    nome: 'Velo Veronese',
    codice: '023090',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L722',
    cap: ['37030'],
    popolazione: 781,
  },
  {
    nome: 'Velturno',
    codice: '021116',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L724',
    cap: ['39040'],
    popolazione: 2742,
  },
  {
    nome: 'Venafro',
    codice: '094052',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '094', nome: 'Isernia' },
    sigla: 'IS',
    codiceCatastale: 'L725',
    cap: ['86079'],
    popolazione: 11236,
  },
  {
    nome: 'Venaria Reale',
    codice: '001292',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L727',
    cap: ['10078'],
    popolazione: 33741,
  },
  {
    nome: 'Venarotta',
    codice: '044073',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '044', nome: 'Ascoli Piceno' },
    sigla: 'AP',
    codiceCatastale: 'L728',
    cap: ['63091'],
    popolazione: 2146,
  },
  {
    nome: 'Venasca',
    codice: '004237',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L729',
    cap: ['12020'],
    popolazione: 1472,
  },
  {
    nome: 'Venaus',
    codice: '001291',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L726',
    cap: ['10050'],
    popolazione: 959,
  },
  {
    nome: 'Vendone',
    codice: '009066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L730',
    cap: ['17032'],
    popolazione: 403,
  },
  {
    nome: 'Venegono Inferiore',
    codice: '012136',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L733',
    cap: ['21040'],
    popolazione: 6238,
  },
  {
    nome: 'Venegono Superiore',
    codice: '012137',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L734',
    cap: ['21040'],
    popolazione: 7180,
  },
  {
    nome: 'Venetico',
    codice: '083104',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L735',
    cap: ['98040'],
    popolazione: 3855,
  },
  {
    nome: 'Venezia',
    codice: '027042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'L736',
    cap: [
      '30121',
      '30122',
      '30123',
      '30124',
      '30125',
      '30126',
      '30127',
      '30128',
      '30129',
      '30130',
      '30131',
      '30132',
      '30133',
      '30134',
      '30135',
      '30136',
      '30137',
      '30138',
      '30139',
      '30140',
      '30141',
      '30142',
      '30143',
      '30144',
      '30145',
      '30146',
      '30147',
      '30148',
      '30149',
      '30150',
      '30151',
      '30152',
      '30153',
      '30154',
      '30155',
      '30156',
      '30157',
      '30158',
      '30159',
      '30160',
      '30161',
      '30162',
      '30163',
      '30164',
      '30165',
      '30166',
      '30167',
      '30168',
      '30169',
      '30170',
      '30171',
      '30172',
      '30173',
      '30174',
      '30175',
      '30176',
    ],
    popolazione: 261362,
  },
  {
    nome: 'Veniano',
    codice: '013238',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L737',
    cap: ['22070'],
    popolazione: 2887,
  },
  {
    nome: 'Venosa',
    codice: '076095',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L738',
    cap: ['85029'],
    popolazione: 12167,
  },
  {
    nome: 'Ventasso',
    codice: '035046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'M364',
    cap: ['42032'],
    popolazione: 4407,
  },
  {
    nome: 'Venticano',
    codice: '064116',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L739',
    cap: ['83030'],
    popolazione: 2532,
  },
  {
    nome: 'Ventimiglia',
    codice: '008065',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L741',
    cap: ['18039'],
    popolazione: 23926,
  },
  {
    nome: 'Ventimiglia di Sicilia',
    codice: '082077',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L740',
    cap: ['90020'],
    popolazione: 2080,
  },
  {
    nome: 'Ventotene',
    codice: '059033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '059', nome: 'Latina' },
    sigla: 'LT',
    codiceCatastale: 'L742',
    cap: ['04031'],
    popolazione: 691,
  },
  {
    nome: 'Venzone',
    codice: '030131',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L743',
    cap: ['33010'],
    popolazione: 2230,
  },
  {
    nome: 'Verano',
    codice: '021112',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L745',
    cap: ['39010'],
    popolazione: 929,
  },
  {
    nome: 'Verano Brianza',
    codice: '108048',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'L744',
    cap: ['20843'],
    popolazione: 9275,
  },
  {
    nome: 'Verbania',
    codice: '103072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L746',
    cap: ['28921', '28922', '28923', '28924', '28925'],
    popolazione: 30332,
  },
  {
    nome: 'Verbicaro',
    codice: '078153',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'L747',
    cap: ['87020'],
    popolazione: 3212,
  },
  {
    nome: 'Vercana',
    codice: '013239',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L748',
    cap: ['22013'],
    popolazione: 751,
  },
  {
    nome: 'Verceia',
    codice: '014075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L749',
    cap: ['23020'],
    popolazione: 1093,
  },
  {
    nome: 'Vercelli',
    codice: '002158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'L750',
    cap: ['13100'],
    popolazione: 46308,
  },
  {
    nome: 'Vercurago',
    codice: '097086',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'L751',
    cap: ['23808'],
    popolazione: 2833,
  },
  {
    nome: 'Verdellino',
    codice: '016232',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L752',
    cap: ['24040'],
    popolazione: 7654,
  },
  {
    nome: 'Verdello',
    codice: '016233',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L753',
    cap: ['24049'],
    popolazione: 7748,
  },
  {
    nome: 'Verderio',
    codice: '097091',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'M337',
    cap: ['23879'],
    popolazione: 5659,
  },
  {
    nome: 'Verduno',
    codice: '004238',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L758',
    cap: ['12060'],
    popolazione: 577,
  },
  {
    nome: 'Vergato',
    codice: '037059',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'L762',
    cap: ['40038'],
    popolazione: 7642,
  },
  {
    nome: 'Verghereto',
    codice: '040050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '040', nome: 'Forlì-Cesena' },
    sigla: 'FC',
    codiceCatastale: 'L764',
    cap: ['47028'],
    popolazione: 1974,
  },
  {
    nome: 'Vergiate',
    codice: '012138',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L765',
    cap: ['21029'],
    popolazione: 8967,
  },
  {
    nome: 'Vermezzo con Zelo',
    codice: '015251',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'M424',
    cap: ['20071'],
    popolazione: 5306,
  },
  {
    nome: 'Vermiglio',
    codice: '022213',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L769',
    cap: ['38029'],
    popolazione: 1869,
  },
  {
    nome: 'Vernante',
    codice: '004239',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L771',
    cap: ['12019'],
    popolazione: 1217,
  },
  {
    nome: 'Vernasca',
    codice: '033044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'L772',
    cap: ['29010'],
    popolazione: 2241,
  },
  {
    nome: 'Vernate',
    codice: '015236',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L773',
    cap: ['20080'],
    popolazione: 3181,
  },
  {
    nome: 'Vernazza',
    codice: '011030',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'L774',
    cap: ['19018'],
    popolazione: 941,
  },
  {
    nome: 'Vernio',
    codice: '100007',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '100', nome: 'Prato' },
    sigla: 'PO',
    codiceCatastale: 'L775',
    cap: ['59024'],
    popolazione: 6012,
  },
  {
    nome: 'Vernole',
    codice: '075093',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'L776',
    cap: ['73029'],
    popolazione: 7296,
  },
  {
    nome: 'Verolanuova',
    codice: '017195',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L777',
    cap: ['25028'],
    popolazione: 8133,
  },
  {
    nome: 'Verolavecchia',
    codice: '017196',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L778',
    cap: ['25029'],
    popolazione: 3875,
  },
  {
    nome: 'Verolengo',
    codice: '001293',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L779',
    cap: ['10038'],
    popolazione: 4962,
  },
  {
    nome: 'Veroli',
    codice: '060085',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L780',
    cap: ['03029'],
    popolazione: 20763,
  },
  {
    nome: 'Verona',
    codice: '023091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L781',
    cap: [
      '37121',
      '37122',
      '37123',
      '37124',
      '37125',
      '37126',
      '37127',
      '37128',
      '37129',
      '37130',
      '37131',
      '37132',
      '37133',
      '37134',
      '37135',
      '37136',
      '37137',
      '37138',
      '37139',
      '37140',
      '37141',
      '37142',
    ],
    popolazione: 252520,
  },
  {
    nome: 'Veronella',
    codice: '023092',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'D193',
    cap: ['37040'],
    popolazione: 4670,
  },
  {
    nome: 'Verrayes',
    codice: '007072',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L783',
    cap: ['11020'],
    popolazione: 1344,
  },
  {
    nome: 'Verrès',
    codice: '007073',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'C282',
    cap: ['11029'],
    popolazione: 2711,
  },
  {
    nome: 'Verretto',
    codice: '018174',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L784',
    cap: ['27053'],
    popolazione: 386,
  },
  {
    nome: 'Verrone',
    codice: '096076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L785',
    cap: ['13871'],
    popolazione: 1253,
  },
  {
    nome: 'Verrua Po',
    codice: '018175',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L788',
    cap: ['27040'],
    popolazione: 1319,
  },
  {
    nome: 'Verrua Savoia',
    codice: '001294',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L787',
    cap: ['10020'],
    popolazione: 1459,
  },
  {
    nome: 'Vertemate con Minoprio',
    codice: '013242',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L792',
    cap: ['22070'],
    popolazione: 3982,
  },
  {
    nome: 'Vertova',
    codice: '016234',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L795',
    cap: ['24029'],
    popolazione: 4844,
  },
  {
    nome: 'Verucchio',
    codice: '099020',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '099', nome: 'Rimini' },
    sigla: 'RN',
    codiceCatastale: 'L797',
    cap: ['47826'],
    popolazione: 9960,
  },
  {
    nome: 'Vervio',
    codice: '014076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L799',
    cap: ['23030'],
    popolazione: 209,
  },
  {
    nome: 'Verzegnis',
    codice: '030132',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L801',
    cap: ['33020'],
    popolazione: 906,
  },
  {
    nome: 'Verzino',
    codice: '101027',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '101', nome: 'Crotone' },
    sigla: 'KR',
    codiceCatastale: 'L802',
    cap: ['88819'],
    popolazione: 1979,
  },
  {
    nome: 'Verzuolo',
    codice: '004240',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L804',
    cap: ['12039'],
    popolazione: 6409,
  },
  {
    nome: 'Vescovana',
    codice: '028097',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L805',
    cap: ['35040'],
    popolazione: 1717,
  },
  {
    nome: 'Vescovato',
    codice: '019113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'L806',
    cap: ['26039'],
    popolazione: 3991,
  },
  {
    nome: 'Vesime',
    codice: '005113',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L807',
    cap: ['14059'],
    popolazione: 661,
  },
  {
    nome: 'Vespolate',
    codice: '003158',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L808',
    cap: ['28079'],
    popolazione: 2067,
  },
  {
    nome: 'Vessalico',
    codice: '008066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L809',
    cap: ['18026'],
    popolazione: 287,
  },
  {
    nome: 'Vestenanova',
    codice: '023093',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L810',
    cap: ['37030'],
    popolazione: 2618,
  },
  {
    nome: 'Vestignè',
    codice: '001295',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L811',
    cap: ['10030'],
    popolazione: 830,
  },
  {
    nome: 'Vestone',
    codice: '017197',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L812',
    cap: ['25078'],
    popolazione: 4461,
  },
  {
    nome: 'Vetralla',
    codice: '056057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L814',
    cap: ['01019'],
    popolazione: 13507,
  },
  {
    nome: 'Vetto',
    codice: '035042',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'L815',
    cap: ['42020'],
    popolazione: 1956,
  },
  {
    nome: "Vezza d'Alba",
    codice: '004241',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L817',
    cap: ['12040'],
    popolazione: 2206,
  },
  {
    nome: "Vezza d'Oglio",
    codice: '017198',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L816',
    cap: ['25059'],
    popolazione: 1476,
  },
  {
    nome: 'Vezzano Ligure',
    codice: '011031',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'L819',
    cap: ['19020'],
    popolazione: 7391,
  },
  {
    nome: 'Vezzano sul Crostolo',
    codice: '035043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'L820',
    cap: ['42030'],
    popolazione: 4214,
  },
  {
    nome: 'Vezzi Portio',
    codice: '009067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L823',
    cap: ['17028'],
    popolazione: 810,
  },
  {
    nome: 'Viadana',
    codice: '020066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'L826',
    cap: ['46019'],
    popolazione: 19157,
  },
  {
    nome: 'Viadanica',
    codice: '016235',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L827',
    cap: ['24060'],
    popolazione: 1084,
  },
  {
    nome: 'Viagrande',
    codice: '087053',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'L828',
    cap: ['95029'],
    popolazione: 8155,
  },
  {
    nome: 'Viale',
    codice: '005114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L829',
    cap: ['14010'],
    popolazione: 261,
  },
  {
    nome: 'Vialfrè',
    codice: '001296',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L830',
    cap: ['10090'],
    popolazione: 254,
  },
  {
    nome: 'Viano',
    codice: '035044',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'L831',
    cap: ['42030'],
    popolazione: 3377,
  },
  {
    nome: 'Viareggio',
    codice: '046033',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'L833',
    cap: ['55049'],
    popolazione: 61857,
  },
  {
    nome: 'Viarigi',
    codice: '005115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L834',
    cap: ['14030'],
    popolazione: 955,
  },
  {
    nome: 'Vibo Valentia',
    codice: '102047',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'F537',
    cap: ['89900'],
    popolazione: 33357,
  },
  {
    nome: 'Vibonati',
    codice: '065156',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L835',
    cap: ['84079'],
    popolazione: 3237,
  },
  {
    nome: 'Vicalvi',
    codice: '060086',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L836',
    cap: ['03030'],
    popolazione: 806,
  },
  {
    nome: 'Vicari',
    codice: '082078',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L837',
    cap: ['90020'],
    popolazione: 2948,
  },
  {
    nome: 'Vicchio',
    codice: '048049',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'L838',
    cap: ['50039'],
    popolazione: 8117,
  },
  {
    nome: 'Vicenza',
    codice: '024116',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L840',
    cap: ['36100'],
    popolazione: 111500,
  },
  {
    nome: 'Vico del Gargano',
    codice: '071059',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'L842',
    cap: ['71018'],
    popolazione: 7861,
  },
  {
    nome: 'Vico Equense',
    codice: '063086',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'L845',
    cap: ['80069'],
    popolazione: 20839,
  },
  {
    nome: 'Vico nel Lazio',
    codice: '060087',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L843',
    cap: ['03010'],
    popolazione: 2256,
  },
  {
    nome: 'Vicoforte',
    codice: '004242',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L841',
    cap: ['12080'],
    popolazione: 3167,
  },
  {
    nome: 'Vicoli',
    codice: '068045',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'L846',
    cap: ['65010'],
    popolazione: 396,
  },
  {
    nome: 'Vicolungo',
    codice: '003159',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'L847',
    cap: ['28060'],
    popolazione: 883,
  },
  {
    nome: 'Vicopisano',
    codice: '050038',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'L850',
    cap: ['56010'],
    popolazione: 8479,
  },
  {
    nome: 'Vicovaro',
    codice: '058112',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'L851',
    cap: ['00029'],
    popolazione: 3937,
  },
  {
    nome: 'Viddalba',
    codice: '090082',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'M259',
    cap: ['07030'],
    popolazione: 1694,
  },
  {
    nome: 'Vidigulfo',
    codice: '018176',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L854',
    cap: ['27018'],
    popolazione: 5933,
  },
  {
    nome: 'Vidor',
    codice: '026090',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'L856',
    cap: ['31020'],
    popolazione: 3769,
  },
  {
    nome: 'Vidracco',
    codice: '001298',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L857',
    cap: ['10080'],
    popolazione: 500,
  },
  {
    nome: 'Vieste',
    codice: '071060',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'L858',
    cap: ['71019'],
    popolazione: 13271,
  },
  {
    nome: 'Vietri di Potenza',
    codice: '076096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L859',
    cap: ['85058'],
    popolazione: 2917,
  },
  {
    nome: 'Vietri sul Mare',
    codice: '065157',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '065', nome: 'Salerno' },
    sigla: 'SA',
    codiceCatastale: 'L860',
    cap: ['84019'],
    popolazione: 8076,
  },
  {
    nome: 'Viganò',
    codice: '097090',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '097', nome: 'Lecco' },
    sigla: 'LC',
    codiceCatastale: 'L866',
    cap: ['23897'],
    popolazione: 2012,
  },
  {
    nome: 'Vigano San Martino',
    codice: '016236',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L865',
    cap: ['24060'],
    popolazione: 1252,
  },
  {
    nome: 'Vigarano Mainarda',
    codice: '038022',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'L868',
    cap: ['44049'],
    popolazione: 7431,
  },
  {
    nome: 'Vigasio',
    codice: '023094',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L869',
    cap: ['37068'],
    popolazione: 9438,
  },
  {
    nome: 'Vigevano',
    codice: '018177',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L872',
    cap: ['27029'],
    popolazione: 60109,
  },
  {
    nome: 'Viggianello',
    codice: '076097',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L873',
    cap: ['85040'],
    popolazione: 3124,
  },
  {
    nome: 'Viggiano',
    codice: '076098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '17', nome: 'Basilicata' },
    provincia: { codice: '076', nome: 'Potenza' },
    sigla: 'PZ',
    codiceCatastale: 'L874',
    cap: ['85059'],
    popolazione: 3122,
  },
  {
    nome: 'Viggiù',
    codice: '012139',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'L876',
    cap: ['21059'],
    popolazione: 5207,
  },
  {
    nome: "Vighizzolo d'Este",
    codice: '028098',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L878',
    cap: ['35040'],
    popolazione: 935,
  },
  {
    nome: 'Vigliano Biellese',
    codice: '096077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L880',
    cap: ['13856'],
    popolazione: 8180,
  },
  {
    nome: "Vigliano d'Asti",
    codice: '005116',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L879',
    cap: ['14040'],
    popolazione: 887,
  },
  {
    nome: 'Vignale Monferrato',
    codice: '006179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L881',
    cap: ['15049'],
    popolazione: 1068,
  },
  {
    nome: 'Vignanello',
    codice: '056058',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'L882',
    cap: ['01039'],
    popolazione: 4826,
  },
  {
    nome: 'Vignate',
    codice: '015237',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L883',
    cap: ['20060'],
    popolazione: 9053,
  },
  {
    nome: 'Vignola',
    codice: '036046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'L885',
    cap: ['41058'],
    popolazione: 24344,
  },
  {
    nome: 'Vignola-Falesina',
    codice: '022216',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L886',
    cap: ['38057'],
    popolazione: 162,
  },
  {
    nome: 'Vignole Borbera',
    codice: '006180',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L887',
    cap: ['15060'],
    popolazione: 2245,
  },
  {
    nome: 'Vignolo',
    codice: '004243',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L888',
    cap: ['12010'],
    popolazione: 2487,
  },
  {
    nome: 'Vignone',
    codice: '103074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L889',
    cap: ['28819'],
    popolazione: 1220,
  },
  {
    nome: 'Vigo di Cadore',
    codice: '025065',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'L890',
    cap: ['32040'],
    popolazione: 1499,
  },
  {
    nome: 'Vigodarzere',
    codice: '028099',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L892',
    cap: ['35010'],
    popolazione: 12885,
  },
  {
    nome: 'Vigolo',
    codice: '016237',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L894',
    cap: ['24060'],
    popolazione: 607,
  },
  {
    nome: 'Vigolzone',
    codice: '033045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'L897',
    cap: ['29020'],
    popolazione: 4268,
  },
  {
    nome: 'Vigone',
    codice: '001299',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L898',
    cap: ['10067'],
    popolazione: 5217,
  },
  {
    nome: 'Vigonovo',
    codice: '027043',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '027', nome: 'Venezia' },
    sigla: 'VE',
    codiceCatastale: 'L899',
    cap: ['30030'],
    popolazione: 9892,
  },
  {
    nome: 'Vigonza',
    codice: '028100',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L900',
    cap: ['35010'],
    popolazione: 21993,
  },
  {
    nome: 'Viguzzolo',
    codice: '006181',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L904',
    cap: ['15058'],
    popolazione: 3209,
  },
  {
    nome: 'Villa Bartolomea',
    codice: '023095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L912',
    cap: ['37049'],
    popolazione: 5841,
  },
  {
    nome: 'Villa Basilica',
    codice: '046034',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'L913',
    cap: ['55019'],
    popolazione: 1700,
  },
  {
    nome: 'Villa Biscossi',
    codice: '018178',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L917',
    cap: ['27035'],
    popolazione: 75,
  },
  {
    nome: 'Villa Carcina',
    codice: '017199',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L919',
    cap: ['25069'],
    popolazione: 10755,
  },
  {
    nome: 'Villa Castelli',
    codice: '074020',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '074', nome: 'Brindisi' },
    sigla: 'BR',
    codiceCatastale: 'L920',
    cap: ['72029'],
    popolazione: 8958,
  },
  {
    nome: 'Villa Celiera',
    codice: '068046',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '068', nome: 'Pescara' },
    sigla: 'PE',
    codiceCatastale: 'L922',
    cap: ['65010'],
    popolazione: 747,
  },
  {
    nome: 'Villa Collemandina',
    codice: '046035',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '046', nome: 'Lucca' },
    sigla: 'LU',
    codiceCatastale: 'L926',
    cap: ['55030'],
    popolazione: 1363,
  },
  {
    nome: 'Villa Cortese',
    codice: '015248',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'L928',
    cap: ['20020'],
    popolazione: 6150,
  },
  {
    nome: "Villa d'Adda",
    codice: '016238',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L929',
    cap: ['24030'],
    popolazione: 4735,
  },
  {
    nome: "Villa d'Almè",
    codice: '016239',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'A215',
    cap: ['24018'],
    popolazione: 6811,
  },
  {
    nome: "Villa d'Ogna",
    codice: '016241',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L938',
    cap: ['24020'],
    popolazione: 1971,
  },
  {
    nome: 'Villa del Bosco',
    codice: '096078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L933',
    cap: ['13868'],
    popolazione: 363,
  },
  {
    nome: 'Villa del Conte',
    codice: '028101',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L934',
    cap: ['35010'],
    popolazione: 5504,
  },
  {
    nome: 'Villa di Briano',
    codice: '061098',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'D801',
    cap: ['81030'],
    popolazione: 6066,
  },
  {
    nome: 'Villa di Chiavenna',
    codice: '014077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L907',
    cap: ['23029'],
    popolazione: 1030,
  },
  {
    nome: 'Villa di Serio',
    codice: '016240',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'L936',
    cap: ['24020'],
    popolazione: 6620,
  },
  {
    nome: 'Villa di Tirano',
    codice: '014078',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '014', nome: 'Sondrio' },
    sigla: 'SO',
    codiceCatastale: 'L908',
    cap: ['23030'],
    popolazione: 2957,
  },
  {
    nome: 'Villa Estense',
    codice: '028102',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L937',
    cap: ['35040'],
    popolazione: 2322,
  },
  {
    nome: 'Villa Faraldi',
    codice: '008067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '008', nome: 'Imperia' },
    sigla: 'IM',
    codiceCatastale: 'L943',
    cap: ['18010'],
    popolazione: 437,
  },
  {
    nome: 'Villa Guardia',
    codice: '013245',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'L956',
    cap: ['22079'],
    popolazione: 7793,
  },
  {
    nome: 'Villa Lagarina',
    codice: '022222',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'L957',
    cap: ['38060'],
    popolazione: 3692,
  },
  {
    nome: 'Villa Latina',
    codice: '060088',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'A081',
    cap: ['03040'],
    popolazione: 1286,
  },
  {
    nome: 'Villa Literno',
    codice: '061099',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'L844',
    cap: ['81039'],
    popolazione: 10715,
  },
  {
    nome: 'Villa Minozzo',
    codice: '035045',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '035', nome: "Reggio nell'Emilia" },
    sigla: 'RE',
    codiceCatastale: 'L969',
    cap: ['42030'],
    popolazione: 3900,
  },
  {
    nome: 'Villa San Giovanni',
    codice: '080096',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '080', nome: 'Reggio Calabria' },
    sigla: 'RC',
    codiceCatastale: 'M018',
    cap: ['89018'],
    popolazione: 13395,
  },
  {
    nome: 'Villa San Giovanni in Tuscia',
    codice: '056046',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'H913',
    cap: ['01010'],
    popolazione: 1313,
  },
  {
    nome: 'Villa San Pietro',
    codice: '092099',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '092', nome: 'Cagliari' },
    sigla: 'CA',
    codiceCatastale: 'I118',
    cap: ['09050'],
    popolazione: 2009,
  },
  {
    nome: 'Villa San Secondo',
    codice: '005119',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'M019',
    cap: ['14020'],
    popolazione: 410,
  },
  {
    nome: "Villa Sant'Angelo",
    codice: '066105',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'M023',
    cap: ['67020'],
    popolazione: 425,
  },
  {
    nome: "Villa Sant'Antonio",
    codice: '095048',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'I298',
    cap: ['09080'],
    popolazione: 382,
  },
  {
    nome: 'Villa Santa Lucia',
    codice: '060089',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'L905',
    cap: ['03030'],
    popolazione: 2639,
  },
  {
    nome: 'Villa Santa Lucia degli Abruzzi',
    codice: '066104',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'M021',
    cap: ['67020'],
    popolazione: 141,
  },
  {
    nome: 'Villa Santa Maria',
    codice: '069102',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'M022',
    cap: ['66047'],
    popolazione: 1433,
  },
  {
    nome: 'Villa Santina',
    codice: '030133',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'L909',
    cap: ['33029'],
    popolazione: 2222,
  },
  {
    nome: 'Villa Santo Stefano',
    codice: '060090',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'I364',
    cap: ['03020'],
    popolazione: 1707,
  },
  {
    nome: 'Villa Verde',
    codice: '095073',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'A609',
    cap: ['09090'],
    popolazione: 335,
  },
  {
    nome: 'Villabassa',
    codice: '021113',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L915',
    cap: ['39039'],
    popolazione: 1479,
  },
  {
    nome: 'Villabate',
    codice: '082079',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L916',
    cap: ['90039'],
    popolazione: 19819,
  },
  {
    nome: 'Villachiara',
    codice: '017200',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L923',
    cap: ['25030'],
    popolazione: 1432,
  },
  {
    nome: 'Villacidro',
    codice: '111096',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L924',
    cap: ['09039'],
    popolazione: 14281,
  },
  {
    nome: 'Villadeati',
    codice: '006182',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L931',
    cap: ['15020'],
    popolazione: 523,
  },
  {
    nome: 'Villadose',
    codice: '029048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'L939',
    cap: ['45010'],
    popolazione: 5188,
  },
  {
    nome: 'Villadossola',
    codice: '103075',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'L906',
    cap: ['28844'],
    popolazione: 6777,
  },
  {
    nome: 'Villafalletto',
    codice: '004244',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L942',
    cap: ['12020'],
    popolazione: 2899,
  },
  {
    nome: "Villafranca d'Asti",
    codice: '005117',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L945',
    cap: ['14018'],
    popolazione: 3250,
  },
  {
    nome: 'Villafranca di Verona',
    codice: '023096',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'L949',
    cap: ['37069'],
    popolazione: 32747,
  },
  {
    nome: 'Villafranca in Lunigiana',
    codice: '045016',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'L946',
    cap: ['54028'],
    popolazione: 4730,
  },
  {
    nome: 'Villafranca Padovana',
    codice: '028103',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L947',
    cap: ['35010'],
    popolazione: 9797,
  },
  {
    nome: 'Villafranca Piemonte',
    codice: '001300',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L948',
    cap: ['10068'],
    popolazione: 4825,
  },
  {
    nome: 'Villafranca Sicula',
    codice: '084043',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '084', nome: 'Agrigento' },
    sigla: 'AG',
    codiceCatastale: 'L944',
    cap: ['92020'],
    popolazione: 1426,
  },
  {
    nome: 'Villafranca Tirrena',
    codice: '083105',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '083', nome: 'Messina' },
    sigla: 'ME',
    codiceCatastale: 'L950',
    cap: ['98049'],
    popolazione: 8748,
  },
  {
    nome: 'Villafrati',
    codice: '082080',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '082', nome: 'Palermo' },
    sigla: 'PA',
    codiceCatastale: 'L951',
    cap: ['90030'],
    popolazione: 3377,
  },
  {
    nome: 'Villaga',
    codice: '024117',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'L952',
    cap: ['36021'],
    popolazione: 1963,
  },
  {
    nome: 'Villagrande Strisaili',
    codice: '091101',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '091', nome: 'Nuoro' },
    sigla: 'NU',
    codiceCatastale: 'L953',
    cap: ['08049'],
    popolazione: 3376,
  },
  {
    nome: 'Villalago',
    codice: '066103',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'L958',
    cap: ['67030'],
    popolazione: 589,
  },
  {
    nome: 'Villalba',
    codice: '085022',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '085', nome: 'Caltanissetta' },
    sigla: 'CL',
    codiceCatastale: 'L959',
    cap: ['93010'],
    popolazione: 1731,
  },
  {
    nome: 'Villalfonsina',
    codice: '069100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L961',
    cap: ['66020'],
    popolazione: 977,
  },
  {
    nome: 'Villalvernia',
    codice: '006183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L963',
    cap: ['15050'],
    popolazione: 966,
  },
  {
    nome: 'Villamagna',
    codice: '069101',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '069', nome: 'Chieti' },
    sigla: 'CH',
    codiceCatastale: 'L964',
    cap: ['66010'],
    popolazione: 2437,
  },
  {
    nome: 'Villamaina',
    codice: '064117',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L965',
    cap: ['83050'],
    popolazione: 1018,
  },
  {
    nome: 'Villamar',
    codice: '111097',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L966',
    cap: ['09020'],
    popolazione: 2872,
  },
  {
    nome: 'Villamarzana',
    codice: '029049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'L967',
    cap: ['45030'],
    popolazione: 1202,
  },
  {
    nome: 'Villamassargia',
    codice: '111098',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L968',
    cap: ['09010'],
    popolazione: 3655,
  },
  {
    nome: 'Villamiroglio',
    codice: '006184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L970',
    cap: ['15020'],
    popolazione: 332,
  },
  {
    nome: 'Villandro',
    codice: '021114',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'L971',
    cap: ['39040'],
    popolazione: 1893,
  },
  {
    nome: 'Villanova Biellese',
    codice: '096079',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'L978',
    cap: ['13877'],
    popolazione: 190,
  },
  {
    nome: 'Villanova Canavese',
    codice: '001301',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L982',
    cap: ['10070'],
    popolazione: 1135,
  },
  {
    nome: "Villanova d'Albenga",
    codice: '009068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'L975',
    cap: ['17038'],
    popolazione: 2522,
  },
  {
    nome: "Villanova d'Ardenghi",
    codice: '018179',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L983',
    cap: ['27030'],
    popolazione: 770,
  },
  {
    nome: "Villanova d'Asti",
    codice: '005118',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'L984',
    cap: ['14019'],
    popolazione: 5774,
  },
  {
    nome: 'Villanova del Battista',
    codice: '064118',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'L973',
    cap: ['83030'],
    popolazione: 1777,
  },
  {
    nome: 'Villanova del Ghebbo',
    codice: '029050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'L985',
    cap: ['45020'],
    popolazione: 2164,
  },
  {
    nome: 'Villanova del Sillaro',
    codice: '098060',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'L977',
    cap: ['26818'],
    popolazione: 1732,
  },
  {
    nome: 'Villanova di Camposampiero',
    codice: '028104',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'L979',
    cap: ['35010'],
    popolazione: 5929,
  },
  {
    nome: 'Villanova Marchesana',
    codice: '029051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '029', nome: 'Rovigo' },
    sigla: 'RO',
    codiceCatastale: 'L988',
    cap: ['45030'],
    popolazione: 1000,
  },
  {
    nome: 'Villanova Mondovì',
    codice: '004245',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L974',
    cap: ['12089'],
    popolazione: 5769,
  },
  {
    nome: 'Villanova Monferrato',
    codice: '006185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'L972',
    cap: ['15030'],
    popolazione: 1849,
  },
  {
    nome: 'Villanova Monteleone',
    codice: '090078',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '090', nome: 'Sassari' },
    sigla: 'SS',
    codiceCatastale: 'L989',
    cap: ['07019'],
    popolazione: 2375,
  },
  {
    nome: 'Villanova Solaro',
    codice: '004246',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'L990',
    cap: ['12030'],
    popolazione: 777,
  },
  {
    nome: "Villanova sull'Arda",
    codice: '033046',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'L980',
    cap: ['29010'],
    popolazione: 1936,
  },
  {
    nome: 'Villanova Truschedu',
    codice: '095071',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'L991',
    cap: ['09084'],
    popolazione: 323,
  },
  {
    nome: 'Villanova Tulo',
    codice: '111099',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L992',
    cap: ['09066'],
    popolazione: 1158,
  },
  {
    nome: 'Villanovaforru',
    codice: '111100',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L986',
    cap: ['09020'],
    popolazione: 681,
  },
  {
    nome: 'Villanovafranca',
    codice: '111101',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L987',
    cap: ['09020'],
    popolazione: 1433,
  },
  {
    nome: 'Villanterio',
    codice: '018180',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'L994',
    cap: ['27019'],
    popolazione: 3153,
  },
  {
    nome: 'Villanuova sul Clisi',
    codice: '017201',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'L995',
    cap: ['25089'],
    popolazione: 5661,
  },
  {
    nome: 'Villaperuccio',
    codice: '111102',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M278',
    cap: ['09010'],
    popolazione: 1097,
  },
  {
    nome: 'Villapiana',
    codice: '078154',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'B903',
    cap: ['87076'],
    popolazione: 5181,
  },
  {
    nome: 'Villaputzu',
    codice: '111103',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'L998',
    cap: ['09040'],
    popolazione: 4836,
  },
  {
    nome: 'Villar Dora',
    codice: '001303',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'L999',
    cap: ['10040'],
    popolazione: 2951,
  },
  {
    nome: 'Villar Focchiardo',
    codice: '001305',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M007',
    cap: ['10050'],
    popolazione: 2068,
  },
  {
    nome: 'Villar Pellice',
    codice: '001306',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M013',
    cap: ['10060'],
    popolazione: 1120,
  },
  {
    nome: 'Villar Perosa',
    codice: '001307',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M014',
    cap: ['10069'],
    popolazione: 4149,
  },
  {
    nome: 'Villar San Costanzo',
    codice: '004247',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'M015',
    cap: ['12020'],
    popolazione: 1502,
  },
  {
    nome: 'Villarbasse',
    codice: '001302',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M002',
    cap: ['10090'],
    popolazione: 3323,
  },
  {
    nome: 'Villarboit',
    codice: '002163',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'M003',
    cap: ['13030'],
    popolazione: 465,
  },
  {
    nome: 'Villareggia',
    codice: '001304',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M004',
    cap: ['10030'],
    popolazione: 1012,
  },
  {
    nome: 'Villaricca',
    codice: '063087',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'G309',
    cap: ['80010'],
    popolazione: 30052,
  },
  {
    nome: 'Villaromagnano',
    codice: '006186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M009',
    cap: ['15050'],
    popolazione: 700,
  },
  {
    nome: 'Villarosa',
    codice: '086020',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '086', nome: 'Enna' },
    sigla: 'EN',
    codiceCatastale: 'M011',
    cap: ['94010'],
    popolazione: 5130,
  },
  {
    nome: 'Villasalto',
    codice: '111104',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M016',
    cap: ['09040'],
    popolazione: 1127,
  },
  {
    nome: 'Villasanta',
    codice: '108049',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'M017',
    cap: ['20852'],
    popolazione: 13619,
  },
  {
    nome: 'Villasimius',
    codice: '111105',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'B738',
    cap: ['09049'],
    popolazione: 3420,
  },
  {
    nome: 'Villasor',
    codice: '111106',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M025',
    cap: ['09034'],
    popolazione: 6857,
  },
  {
    nome: 'Villaspeciosa',
    codice: '111107',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '111', nome: 'Sud Sardegna' },
    sigla: 'SU',
    codiceCatastale: 'M026',
    cap: ['09010'],
    popolazione: 2407,
  },
  {
    nome: 'Villastellone',
    codice: '001308',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M027',
    cap: ['10029'],
    popolazione: 4864,
  },
  {
    nome: 'Villata',
    codice: '002164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'M028',
    cap: ['13010'],
    popolazione: 1618,
  },
  {
    nome: 'Villaurbana',
    codice: '095072',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'M030',
    cap: ['09080'],
    popolazione: 1731,
  },
  {
    nome: 'Villavallelonga',
    codice: '066106',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'M031',
    cap: ['67050'],
    popolazione: 936,
  },
  {
    nome: 'Villaverla',
    codice: '024118',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M032',
    cap: ['36030'],
    popolazione: 6169,
  },
  {
    nome: "Ville d'Anaunia",
    codice: '022249',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M363',
    cap: ['38019'],
    popolazione: 4902,
  },
  {
    nome: 'Ville di Fiemme',
    codice: '022254',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M431',
    cap: ['38033', '38030'],
    popolazione: 2610,
  },
  {
    nome: 'Villeneuve',
    codice: '007074',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '02', nome: "Valle d'Aosta/Vallée d'Aoste" },
    provincia: { codice: '007', nome: "Valle d'Aosta/Vallée d'Aoste" },
    sigla: 'AO',
    codiceCatastale: 'L981',
    cap: ['11018'],
    popolazione: 1236,
  },
  {
    nome: 'Villesse',
    codice: '031025',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '031', nome: 'Gorizia' },
    sigla: 'GO',
    codiceCatastale: 'M043',
    cap: ['34070'],
    popolazione: 1717,
  },
  {
    nome: 'Villetta Barrea',
    codice: '066107',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'M041',
    cap: ['67030'],
    popolazione: 652,
  },
  {
    nome: 'Villette',
    codice: '103076',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'M042',
    cap: ['28856'],
    popolazione: 264,
  },
  {
    nome: 'Villimpenta',
    codice: '020068',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'M044',
    cap: ['46039'],
    popolazione: 2205,
  },
  {
    nome: 'Villongo',
    codice: '016242',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M045',
    cap: ['24060'],
    popolazione: 7619,
  },
  {
    nome: 'Villorba',
    codice: '026091',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'M048',
    cap: ['31020'],
    popolazione: 17883,
  },
  {
    nome: 'Vilminore di Scalve',
    codice: '016243',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M050',
    cap: ['24020'],
    popolazione: 1491,
  },
  {
    nome: 'Vimercate',
    codice: '108050',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '108', nome: 'Monza e della Brianza' },
    sigla: 'MB',
    codiceCatastale: 'M052',
    cap: ['20871'],
    popolazione: 25309,
  },
  {
    nome: 'Vimodrone',
    codice: '015242',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'M053',
    cap: ['20090'],
    popolazione: 16426,
  },
  {
    nome: 'Vinadio',
    codice: '004248',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'M055',
    cap: ['12010'],
    popolazione: 684,
  },
  {
    nome: 'Vinchiaturo',
    codice: '070084',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '14', nome: 'Molise' },
    provincia: { codice: '070', nome: 'Campobasso' },
    sigla: 'CB',
    codiceCatastale: 'M057',
    cap: ['86019'],
    popolazione: 3238,
  },
  {
    nome: 'Vinchio',
    codice: '005120',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '005', nome: 'Asti' },
    sigla: 'AT',
    codiceCatastale: 'M058',
    cap: ['14040'],
    popolazione: 657,
  },
  {
    nome: 'Vinci',
    codice: '048050',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '048', nome: 'Firenze' },
    sigla: 'FI',
    codiceCatastale: 'M059',
    cap: ['50059'],
    popolazione: 14105,
  },
  {
    nome: 'Vinovo',
    codice: '001309',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M060',
    cap: ['10048'],
    popolazione: 14108,
  },
  {
    nome: 'Vinzaglio',
    codice: '003164',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '003', nome: 'Novara' },
    sigla: 'NO',
    codiceCatastale: 'M062',
    cap: ['28060'],
    popolazione: 588,
  },
  {
    nome: 'Viola',
    codice: '004249',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'M063',
    cap: ['12070'],
    popolazione: 425,
  },
  {
    nome: 'Vione',
    codice: '017202',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'M065',
    cap: ['25050'],
    popolazione: 723,
  },
  {
    nome: 'Vipiteno',
    codice: '021115',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '021', nome: 'Bolzano/Bozen' },
    sigla: 'BZ',
    codiceCatastale: 'M067',
    cap: ['39049'],
    popolazione: 6390,
  },
  {
    nome: 'Virle Piemonte',
    codice: '001310',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M069',
    cap: ['10060'],
    popolazione: 1191,
  },
  {
    nome: 'Visano',
    codice: '017203',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'M070',
    cap: ['25010'],
    popolazione: 1933,
  },
  {
    nome: 'Vische',
    codice: '001311',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M071',
    cap: ['10030'],
    popolazione: 1314,
  },
  {
    nome: 'Visciano',
    codice: '063088',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'M072',
    cap: ['80030'],
    popolazione: 4550,
  },
  {
    nome: 'Visco',
    codice: '030135',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'M073',
    cap: ['33040'],
    popolazione: 775,
  },
  {
    nome: 'Visone',
    codice: '006187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M077',
    cap: ['15010'],
    popolazione: 1257,
  },
  {
    nome: 'Visso',
    codice: '043057',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '11', nome: 'Marche' },
    provincia: { codice: '043', nome: 'Macerata' },
    sigla: 'MC',
    codiceCatastale: 'M078',
    cap: ['62039'],
    popolazione: 1180,
  },
  {
    nome: 'Vistarino',
    codice: '018181',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M079',
    cap: ['27010'],
    popolazione: 1546,
  },
  {
    nome: 'Vistrorio',
    codice: '001312',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M080',
    cap: ['10080'],
    popolazione: 521,
  },
  {
    nome: 'Vita',
    codice: '081023',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '081', nome: 'Trapani' },
    sigla: 'TP',
    codiceCatastale: 'M081',
    cap: ['91010'],
    popolazione: 2139,
  },
  {
    nome: 'Viterbo',
    codice: '056059',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'M082',
    cap: ['01100'],
    popolazione: 63209,
  },
  {
    nome: 'Viticuso',
    codice: '060091',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '060', nome: 'Frosinone' },
    sigla: 'FR',
    codiceCatastale: 'M083',
    cap: ['03040'],
    popolazione: 372,
  },
  {
    nome: "Vito d'Asio",
    codice: '093049',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'M085',
    cap: ['33090'],
    popolazione: 818,
  },
  {
    nome: 'Vitorchiano',
    codice: '056060',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '056', nome: 'Viterbo' },
    sigla: 'VT',
    codiceCatastale: 'M086',
    cap: ['01030'],
    popolazione: 4956,
  },
  {
    nome: 'Vittoria',
    codice: '088012',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '088', nome: 'Ragusa' },
    sigla: 'RG',
    codiceCatastale: 'M088',
    cap: ['97019'],
    popolazione: 61006,
  },
  {
    nome: 'Vittorio Veneto',
    codice: '026092',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'M089',
    cap: ['31029'],
    popolazione: 28656,
  },
  {
    nome: 'Vittorito',
    codice: '066108',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '13', nome: 'Abruzzo' },
    provincia: { codice: '066', nome: "L'Aquila" },
    sigla: 'AQ',
    codiceCatastale: 'M090',
    cap: ['67030'],
    popolazione: 898,
  },
  {
    nome: 'Vittuone',
    codice: '015243',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'M091',
    cap: ['20010'],
    popolazione: 8949,
  },
  {
    nome: 'Vitulano',
    codice: '062077',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '062', nome: 'Benevento' },
    sigla: 'BN',
    codiceCatastale: 'M093',
    cap: ['82038'],
    popolazione: 2930,
  },
  {
    nome: 'Vitulazio',
    codice: '061100',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '061', nome: 'Caserta' },
    sigla: 'CE',
    codiceCatastale: 'M092',
    cap: ['81041'],
    popolazione: 7020,
  },
  {
    nome: 'Viù',
    codice: '001313',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M094',
    cap: ['10070'],
    popolazione: 1118,
  },
  {
    nome: 'Vivaro',
    codice: '093050',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'M096',
    cap: ['33099'],
    popolazione: 1399,
  },
  {
    nome: 'Vivaro Romano',
    codice: '058113',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M095',
    cap: ['00020'],
    popolazione: 177,
  },
  {
    nome: 'Viverone',
    codice: '096080',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M098',
    cap: ['13886'],
    popolazione: 1423,
  },
  {
    nome: 'Vizzini',
    codice: '087054',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'M100',
    cap: ['95049'],
    popolazione: 6409,
  },
  {
    nome: 'Vizzola Ticino',
    codice: '012140',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '012', nome: 'Varese' },
    sigla: 'VA',
    codiceCatastale: 'M101',
    cap: ['21010'],
    popolazione: 576,
  },
  {
    nome: 'Vizzolo Predabissi',
    codice: '015244',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'M102',
    cap: ['20070'],
    popolazione: 4045,
  },
  {
    nome: "Vo'",
    codice: '028105',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '028', nome: 'Padova' },
    sigla: 'PD',
    codiceCatastale: 'M103',
    cap: ['35030'],
    popolazione: 3417,
  },
  {
    nome: 'Vobarno',
    codice: '017204',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'M104',
    cap: ['25079'],
    popolazione: 8150,
  },
  {
    nome: 'Vobbia',
    codice: '010066',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'M105',
    cap: ['16010'],
    popolazione: 463,
  },
  {
    nome: 'Vocca',
    codice: '002166',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '002', nome: 'Vercelli' },
    sigla: 'VC',
    codiceCatastale: 'M106',
    cap: ['13020'],
    popolazione: 162,
  },
  {
    nome: 'Vodo Cadore',
    codice: '025066',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M108',
    cap: ['32040'],
    popolazione: 891,
  },
  {
    nome: 'Voghera',
    codice: '018182',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M109',
    cap: ['27058'],
    popolazione: 38174,
  },
  {
    nome: 'Voghiera',
    codice: '038023',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '038', nome: 'Ferrara' },
    sigla: 'FE',
    codiceCatastale: 'M110',
    cap: ['44019'],
    popolazione: 3847,
  },
  {
    nome: 'Vogogna',
    codice: '103077',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '103', nome: 'Verbano-Cusio-Ossola' },
    sigla: 'VB',
    codiceCatastale: 'M111',
    cap: ['28805'],
    popolazione: 1751,
  },
  {
    nome: 'Volano',
    codice: '022224',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M113',
    cap: ['38060'],
    popolazione: 3125,
  },
  {
    nome: 'Volla',
    codice: '063089',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '063', nome: 'Napoli' },
    sigla: 'NA',
    codiceCatastale: 'M115',
    cap: ['80040'],
    popolazione: 22989,
  },
  {
    nome: 'Volongo',
    codice: '019114',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'M116',
    cap: ['26030'],
    popolazione: 578,
  },
  {
    nome: 'Volpago del Montello',
    codice: '026093',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'M118',
    cap: ['31040'],
    popolazione: 10045,
  },
  {
    nome: 'Volpara',
    codice: '018183',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M119',
    cap: ['27047'],
    popolazione: 133,
  },
  {
    nome: 'Volpedo',
    codice: '006188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M120',
    cap: ['15059'],
    popolazione: 1212,
  },
  {
    nome: 'Volpeglino',
    codice: '006189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M121',
    cap: ['15050'],
    popolazione: 160,
  },
  {
    nome: 'Volpiano',
    codice: '001314',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M122',
    cap: ['10088'],
    popolazione: 14998,
  },
  {
    nome: 'Volta Mantovana',
    codice: '020070',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '020', nome: 'Mantova' },
    sigla: 'MN',
    codiceCatastale: 'M125',
    cap: ['46049'],
    popolazione: 7298,
  },
  {
    nome: 'Voltaggio',
    codice: '006190',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '006', nome: 'Alessandria' },
    sigla: 'AL',
    codiceCatastale: 'M123',
    cap: ['15060'],
    popolazione: 759,
  },
  {
    nome: 'Voltago Agordino',
    codice: '025067',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M124',
    cap: ['32020'],
    popolazione: 914,
  },
  {
    nome: 'Volterra',
    codice: '050039',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '050', nome: 'Pisa' },
    sigla: 'PI',
    codiceCatastale: 'M126',
    cap: ['56048'],
    popolazione: 10689,
  },
  {
    nome: 'Voltido',
    codice: '019115',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '019', nome: 'Cremona' },
    sigla: 'CR',
    codiceCatastale: 'M127',
    cap: ['26030'],
    popolazione: 405,
  },
  {
    nome: 'Volturara Appula',
    codice: '071061',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'M131',
    cap: ['71030'],
    popolazione: 481,
  },
  {
    nome: 'Volturara Irpina',
    codice: '064119',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'M130',
    cap: ['83050'],
    popolazione: 3401,
  },
  {
    nome: 'Volturino',
    codice: '071062',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'M132',
    cap: ['71030'],
    popolazione: 1781,
  },
  {
    nome: 'Volvera',
    codice: '001315',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '001', nome: 'Torino' },
    sigla: 'TO',
    codiceCatastale: 'M133',
    cap: ['10040'],
    popolazione: 8690,
  },
  {
    nome: 'Vottignasco',
    codice: '004250',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '004', nome: 'Cuneo' },
    sigla: 'CN',
    codiceCatastale: 'M136',
    cap: ['12020'],
    popolazione: 547,
  },
  {
    nome: 'Zaccanopoli',
    codice: '102048',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'M138',
    cap: ['89867'],
    popolazione: 802,
  },
  {
    nome: 'Zafferana Etnea',
    codice: '087055',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '19', nome: 'Sicilia' },
    provincia: { codice: '087', nome: 'Catania' },
    sigla: 'CT',
    codiceCatastale: 'M139',
    cap: ['95019'],
    popolazione: 9249,
  },
  {
    nome: 'Zagarise',
    codice: '079157',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '079', nome: 'Catanzaro' },
    sigla: 'CZ',
    codiceCatastale: 'M140',
    cap: ['88050'],
    popolazione: 1733,
  },
  {
    nome: 'Zagarolo',
    codice: '058114',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '12', nome: 'Lazio' },
    provincia: { codice: '058', nome: 'Roma' },
    sigla: 'RM',
    codiceCatastale: 'M141',
    cap: ['00039'],
    popolazione: 16922,
  },
  {
    nome: 'Zambrone',
    codice: '102049',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'M143',
    cap: ['89868'],
    popolazione: 1805,
  },
  {
    nome: 'Zandobbio',
    codice: '016244',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M144',
    cap: ['24060'],
    popolazione: 2720,
  },
  {
    nome: 'Zanè',
    codice: '024119',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M145',
    cap: ['36010'],
    popolazione: 6642,
  },
  {
    nome: 'Zanica',
    codice: '016245',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M147',
    cap: ['24050'],
    popolazione: 8193,
  },
  {
    nome: 'Zapponeta',
    codice: '071064',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '071', nome: 'Foggia' },
    sigla: 'FG',
    codiceCatastale: 'M267',
    cap: ['71030'],
    popolazione: 3326,
  },
  {
    nome: 'Zavattarello',
    codice: '018184',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M150',
    cap: ['27059'],
    popolazione: 1036,
  },
  {
    nome: 'Zeccone',
    codice: '018185',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M152',
    cap: ['27010'],
    popolazione: 1696,
  },
  {
    nome: 'Zeddiani',
    codice: '095074',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'M153',
    cap: ['09070'],
    popolazione: 1158,
  },
  {
    nome: 'Zelbio',
    codice: '013246',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '013', nome: 'Como' },
    sigla: 'CO',
    codiceCatastale: 'M156',
    cap: ['22020'],
    popolazione: 220,
  },
  {
    nome: 'Zelo Buon Persico',
    codice: '098061',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '098', nome: 'Lodi' },
    sigla: 'LO',
    codiceCatastale: 'M158',
    cap: ['26839'],
    popolazione: 6872,
  },
  {
    nome: 'Zeme',
    codice: '018186',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M161',
    cap: ['27030'],
    popolazione: 1082,
  },
  {
    nome: 'Zenevredo',
    codice: '018187',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M162',
    cap: ['27049'],
    popolazione: 478,
  },
  {
    nome: 'Zenson di Piave',
    codice: '026094',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'M163',
    cap: ['31050'],
    popolazione: 1783,
  },
  {
    nome: 'Zerba',
    codice: '033047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'M165',
    cap: ['29020'],
    popolazione: 92,
  },
  {
    nome: 'Zerbo',
    codice: '018188',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M166',
    cap: ['27017'],
    popolazione: 445,
  },
  {
    nome: 'Zerbolò',
    codice: '018189',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M167',
    cap: ['27020'],
    popolazione: 1653,
  },
  {
    nome: 'Zerfaliu',
    codice: '095075',
    zona: { codice: '5', nome: 'Isole' },
    regione: { codice: '20', nome: 'Sardegna' },
    provincia: { codice: '095', nome: 'Oristano' },
    sigla: 'OR',
    codiceCatastale: 'M168',
    cap: ['09070'],
    popolazione: 1172,
  },
  {
    nome: 'Zeri',
    codice: '045017',
    zona: { codice: '3', nome: 'Centro' },
    regione: { codice: '09', nome: 'Toscana' },
    provincia: { codice: '045', nome: 'Massa-Carrara' },
    sigla: 'MS',
    codiceCatastale: 'M169',
    cap: ['54029'],
    popolazione: 1201,
  },
  {
    nome: 'Zermeghedo',
    codice: '024120',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M170',
    cap: ['36050'],
    popolazione: 1358,
  },
  {
    nome: 'Zero Branco',
    codice: '026095',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '026', nome: 'Treviso' },
    sigla: 'TV',
    codiceCatastale: 'M171',
    cap: ['31059'],
    popolazione: 10986,
  },
  {
    nome: 'Zevio',
    codice: '023097',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'M172',
    cap: ['37059'],
    popolazione: 14413,
  },
  {
    nome: 'Ziano di Fiemme',
    codice: '022226',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '04', nome: 'Trentino-Alto Adige/Südtirol' },
    provincia: { codice: '022', nome: 'Trento' },
    sigla: 'TN',
    codiceCatastale: 'M173',
    cap: ['38030'],
    popolazione: 1679,
  },
  {
    nome: 'Ziano Piacentino',
    codice: '033048',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '033', nome: 'Piacenza' },
    sigla: 'PC',
    codiceCatastale: 'L848',
    cap: ['29010'],
    popolazione: 2635,
  },
  {
    nome: 'Zibido San Giacomo',
    codice: '015247',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '015', nome: 'Milano' },
    sigla: 'MI',
    codiceCatastale: 'M176',
    cap: ['20080'],
    popolazione: 6552,
  },
  {
    nome: 'Zignago',
    codice: '011032',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '011', nome: 'La Spezia' },
    sigla: 'SP',
    codiceCatastale: 'M177',
    cap: ['19020'],
    popolazione: 524,
  },
  {
    nome: 'Zimella',
    codice: '023098',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '023', nome: 'Verona' },
    sigla: 'VR',
    codiceCatastale: 'M178',
    cap: ['37040'],
    popolazione: 4834,
  },
  {
    nome: 'Zimone',
    codice: '096081',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M179',
    cap: ['13887'],
    popolazione: 425,
  },
  {
    nome: 'Zinasco',
    codice: '018190',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '018', nome: 'Pavia' },
    sigla: 'PV',
    codiceCatastale: 'M180',
    cap: ['27030'],
    popolazione: 3223,
  },
  {
    nome: 'Zoagli',
    codice: '010067',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '010', nome: 'Genova' },
    sigla: 'GE',
    codiceCatastale: 'M182',
    cap: ['16035'],
    popolazione: 2516,
  },
  {
    nome: 'Zocca',
    codice: '036047',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '036', nome: 'Modena' },
    sigla: 'MO',
    codiceCatastale: 'M183',
    cap: ['41059'],
    popolazione: 4883,
  },
  {
    nome: 'Zogno',
    codice: '016246',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '016', nome: 'Bergamo' },
    sigla: 'BG',
    codiceCatastale: 'M184',
    cap: ['24019'],
    popolazione: 9054,
  },
  {
    nome: 'Zola Predosa',
    codice: '037060',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '08', nome: 'Emilia-Romagna' },
    provincia: { codice: '037', nome: 'Bologna' },
    sigla: 'BO',
    codiceCatastale: 'M185',
    cap: ['40069'],
    popolazione: 18193,
  },
  {
    nome: 'Zollino',
    codice: '075094',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '16', nome: 'Puglia' },
    provincia: { codice: '075', nome: 'Lecce' },
    sigla: 'LE',
    codiceCatastale: 'M187',
    cap: ['73010'],
    popolazione: 2058,
  },
  {
    nome: 'Zone',
    codice: '017205',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '03', nome: 'Lombardia' },
    provincia: { codice: '017', nome: 'Brescia' },
    sigla: 'BS',
    codiceCatastale: 'M188',
    cap: ['25050'],
    popolazione: 1091,
  },
  {
    nome: 'Zoppè di Cadore',
    codice: '025069',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '025', nome: 'Belluno' },
    sigla: 'BL',
    codiceCatastale: 'M189',
    cap: ['32010'],
    popolazione: 265,
  },
  {
    nome: 'Zoppola',
    codice: '093051',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '093', nome: 'Pordenone' },
    sigla: 'PN',
    codiceCatastale: 'M190',
    cap: ['33080'],
    popolazione: 8419,
  },
  {
    nome: 'Zovencedo',
    codice: '024121',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M194',
    cap: ['36020'],
    popolazione: 796,
  },
  {
    nome: 'Zubiena',
    codice: '096082',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M196',
    cap: ['13888'],
    popolazione: 1251,
  },
  {
    nome: 'Zuccarello',
    codice: '009069',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '07', nome: 'Liguria' },
    provincia: { codice: '009', nome: 'Savona' },
    sigla: 'SV',
    codiceCatastale: 'M197',
    cap: ['17039'],
    popolazione: 322,
  },
  {
    nome: 'Zugliano',
    codice: '024122',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '05', nome: 'Veneto' },
    provincia: { codice: '024', nome: 'Vicenza' },
    sigla: 'VI',
    codiceCatastale: 'M199',
    cap: ['36030'],
    popolazione: 6690,
  },
  {
    nome: 'Zuglio',
    codice: '030136',
    zona: { codice: '2', nome: 'Nord-est' },
    regione: { codice: '06', nome: 'Friuli-Venezia Giulia' },
    provincia: { codice: '030', nome: 'Udine' },
    sigla: 'UD',
    codiceCatastale: 'M200',
    cap: ['33020'],
    popolazione: 606,
  },
  {
    nome: 'Zumaglia',
    codice: '096083',
    zona: { codice: '1', nome: 'Nord-ovest' },
    regione: { codice: '01', nome: 'Piemonte' },
    provincia: { codice: '096', nome: 'Biella' },
    sigla: 'BI',
    codiceCatastale: 'M201',
    cap: ['13848'],
    popolazione: 1129,
  },
  {
    nome: 'Zumpano',
    codice: '078155',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '078', nome: 'Cosenza' },
    sigla: 'CS',
    codiceCatastale: 'M202',
    cap: ['87040'],
    popolazione: 2468,
  },
  {
    nome: 'Zungoli',
    codice: '064120',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '15', nome: 'Campania' },
    provincia: { codice: '064', nome: 'Avellino' },
    sigla: 'AV',
    codiceCatastale: 'M203',
    cap: ['83030'],
    popolazione: 1197,
  },
  {
    nome: 'Zungri',
    codice: '102050',
    zona: { codice: '4', nome: 'Sud' },
    regione: { codice: '18', nome: 'Calabria' },
    provincia: { codice: '102', nome: 'Vibo Valentia' },
    sigla: 'VV',
    codiceCatastale: 'M204',
    cap: ['89867'],
    popolazione: 1985,
  },
];

export default comuniJson;

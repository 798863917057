import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { http } from '../../../utils/Utils';

import { TextField } from '../../Form/TextField';
import FormLabel from '../../UI/FormLabel';
import Button from '../../UI/buttons/Button';
import { UserContext } from '../../../store/UserContext';

const UpdateAccessCodeGeneral = (props) => {
  const form = useForm();
  const params = useParams();
  const history = useHistory();
  const [pack, setPack] = useState({});
  const mode = params.id ? 'edit' : 'create';

  const { data: currentClient } = useContext(UserContext);
  const role = currentClient?.role;

  useEffect(() => {
    fetchAccessCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchAccessCode = async () => {
    try {
      if (props.mode === 'edit') {
        const response = await http({ url: `/accessCode/${params.id}` });

        setPack(response);
        form.setValue('name', response.name);
        form.setValue('licenseType', response.licenseType);
        form.setValue('paymentPeriod', response.paymentPeriod);
        form.setValue('status', response.status);
        form.setValue('fee', response.fee);
        form.setValue('params.includedRentalLocations', response.params?.includedRentalLocations);
        form.setValue('params.includedVehicles', response.params?.includedVehicles);
        form.setValue('params.includedMonthlyRents', response.params?.includedMonthlyRents);
        form.setValue('params.includedComodati', response.params?.includedComodati);
        form.setValue('params.includedMNP', response.params?.includedMNP);

        form.setValue(
          'variablePayments.extraRentalLocationFee',
          response.variablePayments?.extraRentalLocationFee,
        );
        form.setValue(
          'variablePayments.extraVehicleFee',
          response.variablePayments?.extraVehicleFee,
        );

        form.setValue(
          'variablePayments.extraMonthlyRentFee',
          response.variablePayments?.extraMonthlyRentFee,
        );
        form.setValue(
          'variablePayments.extraComodatoFee',
          response.variablePayments?.extraComodatoFee,
        );
        form.setValue('variablePayments.extraMNPFee', response.variablePayments?.extraMNPFee);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const onSubmit = async (data) => {
    try {
      if (props.mode === 'create') {
        data = {
          ...data,
        };

        const result = await http({
          method: 'POST',
          url: '/accessCode',
          form: data,
        });
        toast.success('Codice Accesso creato');
        history.push(`/admin/codiciaccesso`);
      } else if (props.mode === 'edit') {
        await http({
          method: 'PUT',
          url: `/accessCode/${params.id}`,
          form: data,
        });
        toast.success('Codice Accesso aggiornato');
        history.push(`/admin/codiciaccesso/${params.id}`);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="p-6 bg-white rounded shadow-md">
      {!params.id || params.id === 'crea' ? (
        <span className="font-bold">Creazione Codice Accesso</span>
      ) : (
        <>
          <span>{pack?.name}:</span> <span className="font-bold">Aggiornamento Codice Accesso</span>
        </>
      )}
      <fieldset disabled={form.formState.isSubmitting} className="space-y-0">
        <div className="flex flex-wrap">
          <div className="w-96 mr-2">
            <FormLabel className="block text-gray-700 text-sm font-bold mb-2">Codice</FormLabel>
            <TextField
              form={form}
              name="code"
              type="text"
              placeholder="Codice"
              validation={{
                required: { value: true, message: 'Codice' },
              }}
            />
          </div>
          <div className="w-96 mr-2">
            <FormLabel className="block text-gray-700 text-sm font-bold mb-2">Agente</FormLabel>
            <TextField form={form} name="agent" type="text" placeholder="Agente" />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2">
          <div className="flex flex-wrap -mx-3 mt-6 ">
            <div className="w-full px-3">
              {mode === 'edit' ? (
                <div className="flex space-x-2">
                  <Button btnStyle="">Aggiorna Codice Accesso</Button>
                </div>
              ) : (
                <Button className=" text-white font-bold py-2 px-4 rounded">
                  Crea Codice Accesso
                </Button>
              )}
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default UpdateAccessCodeGeneral;

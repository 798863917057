import React, { useEffect, useState } from 'react';
import { http } from '../../utils/Utils';
import Damage from './Damage';
import DamageModal from './DamageModal';
import DamagesImage from './DamagesImage';
import RentDamages from './RentDamages';
import WhiteBox from '../../components/UI/WhiteBox';
import Button from '../UI/buttons/Button';
import PlusOutlineCircle from '../../assets/icons/PlusOutlineCircle';
import ToggleSwitch from '../UI/ToggleSwitch';
import ElementLabel from '../UI/ElementLabel';
import { mapDamageVehiclePart, mapDamageLevel, mapDamageLevelColor } from './Damage';

const Damages = ({
  rent,
  vehicle,
  expanded,
  phase,
  doNotShrink,
  noCollapsible,
  viewMode,
  updatePrice,
  ...props
}) => {
  const [vehicleDamages, setVehicleDamages] = useState([]); //eslint-disable-line
  const [filteredDamages, setFilteredDamages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [damagesType, setDamagesType] = useState('all'); //eslint-disable-line
  const [showDamageImage, setShowDamageImage] = useState(true);
  const [countOpenDamages, setCountOpenDamages] = useState(0);
  const [rentDamages, setRentDamages] = useState([]);
  const [insertMode, setInsertMode] = useState(false);

  const vehicleId = vehicle?._id || rent?.vehicle?._id;
  const rentId = rent?._id;
  const groupId = rent?.group?._id;

  useEffect(() => {
    fetchVehicleDamages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVehicleDamages = async () => {
    const response = await http({ url: `/vehicles/vehicle/damages/${vehicleId}` });
    setVehicleDamages(response);
    filterDamages(response, 'open');
    setCountOpenDamages(response.filter((damage) => damage.status === 'open').length);

    if (rentId) {
      const damagesRent = response.filter((damage) => damage.rentId === rentId);
      setRentDamages(damagesRent);
    }
  };

  const filterDamages = (damages, status) => {
    setDamagesType(status);
    if (status === 'all') {
      setFilteredDamages(damages);
      return;
    }
    setFilteredDamages(damages.filter((damage) => damage.status === status));
  };

  const updateDamages = () => {
    fetchVehicleDamages();
  };

  //eslint-disable-next-line
  const mapType = (status) => {
    if (status === 'open') {
      return <div className="text-yellow-600 text-lg font-semibold p-3">Danni aperti</div>;
    }
    if (status === 'closed') {
      return <div className="text-green-600 text-lg font-semibold p-3">Danni chiusi</div>;
    }
    if (status === 'all') {
      return <div className="text-gray-600 text-lg font-semibold p-3">Tutti i danni</div>;
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    setInsertMode(false);
    fetchVehicleDamages();
  };

  const canAddDamage =
    rent?.state === 'chiuso' ||
    rent?.state === 'fatturato' ||
    rent?.state === 'annullato' ||
    rent?.state === 'incassato' ||
    rent?.state === 'parz fatturato' ||
    rent?.state === 'parz incassato'
      ? false
      : true;

  const damagesTitle = rentDamages.map((damage) => (
    <ElementLabel className="ml-2" bgColor={mapDamageLevelColor(damage.damageLevel)}>
      {mapDamageVehiclePart(damage.vehiclePart)}, {mapDamageLevel(damage.damageLevel)}
    </ElementLabel>
  ));

  return (
    <WhiteBox
      className={noCollapsible ? 'mx-0 my-0 px-6 py-5' : 'mx-0'}
      innerClassName="px-6 py-5"
      isCollapsible={!(noCollapsible || false)}
      isExpanded={expanded || props.isExpanded}
      headerChildren={
        <div className="font-bold text-lg">
          {rentId ? (
            <>
              Danni noleggio ({rentDamages.length}) {damagesTitle}
            </>
          ) : (
            `Danni (${countOpenDamages} ${
              countOpenDamages === 1 ? 'danno aperto' : 'danni aperti'
            })`
          )}
        </div>
      }
      {...props}
    >
      {viewMode === true ? (
        <DamagesImage
          vehicleId={vehicleId}
          closeModal={closeModal}
          phase={phase}
          groupId={groupId}
          rentId={rentId}
          updateDamages={updateDamages}
          updatePrice={updatePrice}
          viewMode={viewMode}
        />
      ) : (
        <>
          <div className="flex justify-between">
            <div>
              {noCollapsible === true ? (
                <span className="font-bold text-lg mr-3">
                  {rentId
                    ? `${rentDamages.length} danni inseriti`
                    : `${countOpenDamages} ${
                        countOpenDamages === 1 ? 'danno aperto' : 'danni aperti'
                      }`}
                </span>
              ) : (
                ''
              )}

              {canAddDamage ? (
                <Button
                  btnStyle="whiteLightButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setInsertMode(true);
                    setShowDamageImage(true);
                  }}
                >
                  <PlusOutlineCircle /> Inserisci danno
                </Button>
              ) : null}
            </div>

            <ToggleSwitch
              switches={[
                {
                  label: 'Mappa danni',
                  selected: showDamageImage,
                  onClick: () => {
                    setShowDamageImage(true);
                  },
                },
                {
                  label: 'Elenco danni',
                  selected: !showDamageImage,
                  onClick: () => {
                    setShowDamageImage(false);
                  },
                },
              ]}
            />
          </div>

          <div className="transition-all duration-1000">
            {showDamageImage ? (
              <>
                <DamagesImage
                  vehicleId={vehicleId}
                  closeModal={closeModal}
                  phase={phase}
                  groupId={groupId}
                  rentId={rentId}
                  updateDamages={updateDamages}
                  updatePrice={updatePrice}
                  onModalClose={closeModal}
                  insertMode={insertMode}
                />
                {rentId ? (
                  <RentDamages
                    rentDamages={rentDamages}
                    vehicleId={vehicleId}
                    rentId={rentId}
                    groupId={groupId}
                    phase={phase}
                    updateDamages={updateDamages}
                    updatePrice={updatePrice}
                  />
                ) : null}
              </>
            ) : (
              <div className="mt-2">
                {/*<div className="flex">
                      <div>{mapType(damagesType)}</div>
                    </div>*/}
                {filteredDamages.length === 0 ? (
                  <h3 className="pt-3 italic text-center text-gray-500">Nessun danno inserito</h3>
                ) : null}
                {filteredDamages.map((damage) => (
                  <Damage
                    key={damage._id}
                    rentId={rentId}
                    vehicleId={vehicleId}
                    groupId={groupId}
                    damage={damage}
                    phase={phase}
                    closeModal={closeModal}
                  />
                ))}
              </div>
            )}
          </div>
          {openModal ? (
            <DamageModal
              vehicleId={vehicleId}
              closeModal={closeModal}
              phase={phase}
              groupId={groupId}
              rentId={rentId}
              updateDamages={updateDamages}
              updatePrice={updatePrice}
            />
          ) : null}
        </>
      )}
    </WhiteBox>
  );
};

export default Damages;

import React from 'react';
import LightGrayLink from '../../UI/LightGrayLink';

const ModelsTableItem = (item) => {
  const model = item.model;

  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{model.modelName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{model.brandId?.brandName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {model.createdAt !== undefined && new Date(model.createdAt).toLocaleString()}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600 w-10">
          {model.updatedAt !== undefined && new Date(model.updatedAt).toLocaleString()}
        </p>
      </td>
      <td>
        <LightGrayLink to={`/admin/veicoli/modelli/${model._id}`}>Aggiorna</LightGrayLink>
      </td>
    </tr>
  );
};

export default ModelsTableItem;

import React from 'react';
import LightGrayLink from '../../UI/LightGrayLink';

const VersionsTableItem = (item) => {
  const version = item.version;

  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {version.imageUrl ? <img src={version.imageUrl} width={90} alt="version" /> : <></>}
        </p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{version.versionName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{version.brandId.brandName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{version.modelId.modelName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{version.group?.mnemonic}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {version.createdAt !== undefined && new Date(version.createdAt).toLocaleDateString()}
        </p>
      </td>
      <td>
        <LightGrayLink to={`/admin/veicoli/versioni/${version._id}`}>Aggiorna</LightGrayLink>
      </td>
    </tr>
  );
};

export default VersionsTableItem;

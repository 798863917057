import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../utils/Utils';
import { UserContext } from '../../../store/UserContext';

import AdminPage from '../../../components/Admin/AdminPage';
import GrayLink from '../../../components/UI/GrayLink';
import WhiteButton from '../../../components/UI/buttons/WhiteButton';
import PackDetails from '../../../components/Packs/PackDetails';

const Pack = () => {
  const params = useParams();
  const history = useHistory();
  const [pack, setPack] = useState({});

  const { data: currentClient } = useContext(UserContext);
  const license = currentClient?.client?.license?.licenseOwner;
  const isAdmin = currentClient?.role;

  useEffect(() => {
    fetchPack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchPack = async () => {
    try {
      const response = await http({ url: `/clientPayments/packs/${params.id}` });
      setPack(response);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <div className="p-4 w-full">
        <div className="flex">
          <div className="pr-2">
            <WhiteButton onClick={() => history.goBack()}>Indietro</WhiteButton>
          </div>

          {(license === 'client' || isAdmin) && (
            <div className="px-2">
              <GrayLink to={`/admin/codiciaccesso/${params.id}/aggiorna`}>Aggiorna</GrayLink>
            </div>
          )}
        </div>

        <PackDetails pack={pack} />

        <div className="mb-4 mt-2"></div>
      </div>
    </AdminPage>
  );
};

export default Pack;

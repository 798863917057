import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { UserContext } from '../../../store/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import SettingsPage from '../../../components/Settings/SettingsPage';
import { TextField } from '../../../components/Form/TextField';
import { SelectField } from '../../../components/Form/SelectField';
import FormLabel from '../../../components/UI/FormLabel';
import { CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR, http } from '../../../utils/Utils';
import Button from '../../../components/UI/buttons/Button';
import UpdateWorkflowsAdministration from '../../../components/Workflows/UpdateWorkflowsAdministration';
import UpdateWorkflowsConfigurations from '../../../components/Workflows/UpdateWorkflowsConfigurations';
import UpdateWorkflowsExtraFields from '../../../components/Workflows/UpdateWorkflowsExtraFields';
import UpdateWorkflowsPriceLists from '../../../components/Workflows/UpdateWorkflowsPriceLists';
import UpdateWorkflowsRentalLocations from '../../../components/Workflows/UpdateWorkflowsRentalLocations';
import UpdateWorkflowsUserCompanies from '../../../components/Workflows/UpdateWorkflowsUserCompanies';
import CardsHeader from '../../../components/UI/CardsHeader';
import WhiteBox from '../../../components/UI/WhiteBox';
import TableHeaderTab from '../../../components/UI/TableHeaderTab';
import ElementLabel from '../../../components/UI/ElementLabel';
import { checkClientProfileIsAdmin } from '../../../utils/ClientProfile';

const Workflow = () => {
  const form = useForm();
  const history = useHistory();
  const params = useParams();
  const mode = params.id ? 'edit' : 'create';
  const [workflow, setWorkflow] = useState({ extraFields: [] }); //eslint-disable-line
  const [rentalLocations, setRentalLocations] = useState([]);
  const [fieldToUpdate, setFieldToUpdate] = useState('general');

  const [isAdmin, setIsAdmin] = useState(false);
  const { data: currentClient } = useContext(UserContext);
  useEffect(() => {
    setIsAdmin(checkClientProfileIsAdmin(currentClient));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  const fetchWorkflow = async () => {
    try {
      if (mode === 'edit') {
        const response = await http({ url: `/workflow/${params.id}` });
        setRentalLocations(response.rentalLocations);

        form.setValue('name', response.name);
        form.setValue('description', response.description);
        form.setValue('status', response.status);
        form.setValue('extraFields', response.extraFields);
        setWorkflow(response);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  useEffect(() => {
    fetchWorkflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      if (isAdmin) {
        if (data.extraFields && data.extraFields.length > 0) {
          data.extraFields.map((extraField) => {
            if (extraField.field === '') {
              toast.error('I campi extra non possono essere vuoti');
            }
            if (extraField.dataType === undefined) {
              toast.error('I campi devono avere un tipo');
            }
            if (extraField.requiredField === undefined) {
              extraField.requiredField = false;
            }

            return extraField;
          });
        }
        data.workflowType = 'client';
        data.client = currentClient.client.id;

        if (mode === 'create') {
          await http({
            method: 'POST',
            url: '/workflow',
            form: data,
          });
          toast.success('Flusso creato');
          await fetchWorkflow();
        } else if (mode === 'edit') {
          await http({
            method: 'PUT',
            url: `/workflow/${params.id}`,
            form: data,
          });
          toast.success('Flusso aggiornato');
          await fetchWorkflow();
        }
      } else {
        toast.error('Non hai i permessi per creare un flusso');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <SettingsPage canAccess={[CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR]} hasBox={false}>
      <CardsHeader
        title={mode === 'edit' ? 'Flusso' : 'Nuovo Flusso'}
        buttons={[
          {
            btnStyle: 'lightSlateTransparent',
            children: '« Indietro',
            onClick: () => {
              history.goBack();
            },
          },
        ]}
      />

      <WhiteBox className="mt-0">
        {mode === 'edit' && (
          <>
            <div className="flex p-7 gap-x-4">
              <div className="flex-1">
                <div className="text-3xl font-semibold">{workflow?.name}</div>
                <div className="text-md">{workflow?.description}</div>
              </div>
              <div>
                {workflow?.status === 'active' ? (
                  <ElementLabel bgColor="bg-green-600">Attivo</ElementLabel>
                ) : workflow?.status === 'inactive' ? (
                  <ElementLabel bgColor="bg-red-600">Annullato</ElementLabel>
                ) : (
                  ''
                )}
              </div>
            </div>

            <TableHeaderTab
              buttons={[
                {
                  label: 'Generale',
                  function: () => {
                    setFieldToUpdate('general');
                  },
                  selected: fieldToUpdate === 'general',
                },
                {
                  label: 'Listini',
                  function: () => {
                    setFieldToUpdate('priceLists');
                  },
                  selected: fieldToUpdate === 'priceLists',
                },
                {
                  label: 'Punti Nolo',
                  function: () => {
                    setFieldToUpdate('rentalLocations');
                  },
                  selected: fieldToUpdate === 'rentalLocations',
                },
                {
                  label: 'Campi Extra',
                  function: () => {
                    setFieldToUpdate('extraFields');
                  },
                  selected: fieldToUpdate === 'extraFields',
                },
                {
                  label: 'Configurazione',
                  function: () => {
                    setFieldToUpdate('configuration');
                  },
                  selected: fieldToUpdate === 'configuration',
                },
                {
                  label: 'Amministrazione',
                  function: () => {
                    setFieldToUpdate('administration');
                  },
                  selected: fieldToUpdate === 'administration',
                },
                {
                  label: 'Aziende Clienti',
                  function: () => {
                    setFieldToUpdate('userCompanies');
                  },
                  selected: fieldToUpdate === 'userCompanies',
                },
              ]}
            />
          </>
        )}

        <div
          className={`p-4 bg-slate-200 border-4 rounded-b-2xl border-white ${
            mode !== 'edit' && 'rounded-t-2xl'
          }`}
        >
          {fieldToUpdate === 'general' && (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <fieldset disabled={form.formState.isSubmitting}>
                <div className="max-w-sm">
                  <FormLabel>Nome</FormLabel>
                  <TextField
                    form={form}
                    name="name"
                    type="text"
                    disabled={!isAdmin}
                    placeholder="Nome"
                  />
                </div>
                <div className="max-w-sm">
                  <FormLabel>Descrizione</FormLabel>
                  <TextField
                    form={form}
                    name="description"
                    type="text"
                    placeholder="Nome"
                    disabled={!isAdmin}
                  />
                </div>
                <div className="max-w-sm">
                  <FormLabel>Stato</FormLabel>
                  <SelectField
                    form={form}
                    name="status"
                    placeholder="Stato"
                    options={[
                      { value: 'active', label: 'Attivo' },
                      { value: 'inactive', label: 'Disattivato' },
                    ]}
                    disabled={!isAdmin}
                  />
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-wrap -mx-3 mt-6 ">
                    {isAdmin && (
                      <div className="w-full px-3">
                        {mode === 'edit' ? (
                          <div className="flex space-x-2">
                            <Button>Aggiorna Flusso</Button>
                          </div>
                        ) : (
                          <Button>Crea Flusso</Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </fieldset>
            </form>
          )}
          {fieldToUpdate === 'priceLists' && (
            <UpdateWorkflowsPriceLists></UpdateWorkflowsPriceLists>
          )}
          {fieldToUpdate === 'rentalLocations' && (
            <UpdateWorkflowsRentalLocations
              rentalLocations={rentalLocations}
            ></UpdateWorkflowsRentalLocations>
          )}
          {fieldToUpdate === 'extraFields' && (
            <UpdateWorkflowsExtraFields></UpdateWorkflowsExtraFields>
          )}
          {fieldToUpdate === 'configuration' && (
            <UpdateWorkflowsConfigurations></UpdateWorkflowsConfigurations>
          )}
          {fieldToUpdate === 'administration' && (
            <UpdateWorkflowsAdministration></UpdateWorkflowsAdministration>
          )}
          {fieldToUpdate === 'userCompanies' && (
            <UpdateWorkflowsUserCompanies></UpdateWorkflowsUserCompanies>
          )}
        </div>
      </WhiteBox>
    </SettingsPage>
  );
};

export default Workflow;

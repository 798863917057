import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MOVOLAB_ROLE_ADMIN } from '../../utils/Utils';
import { UserContext } from '../../store/UserContext';
import { FaLink } from 'react-icons/fa';
import { FaToolbox } from 'react-icons/fa';
import DisplayDateTime from '../UI/dates/DisplayDateTime';
import UserImage from '../Users/UserImage';
import UserCompanyImage from '../UserCompanies/UserCompanyImage';
import ElementLabel from '../UI/ElementLabel';
import { MdGarage } from 'react-icons/md';
import WhiteBox from '../UI/WhiteBox';
import { FaAngleDown } from 'react-icons/fa6';
import FranchisesBox from '../UI/FranchisesBox';

const ReservationRecap = ({
  reservation,
  expandFn = false,
  isExpanded = false,
  className = '',
}) => {
  const pathname = window.location.pathname.split('/')[1];

  const { data: currentClient } = useContext(UserContext);
  const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN; // eslint-disable-line no-unused-vars

  return (
    <WhiteBox className={`mx-0 mt-0 p-6 ${className}`}>
      <div className="flex flex-wrap">
        <div className="flex-1">
          <div className="flex flex-wrap gap-x-6">
            <div>
              <h1 className="text-2xl font-semibold">
                <strong className="font-medium">{reservation?.code}</strong>
              </h1>
              {reservation.rent ? (
                <div className="font-medium text-xs text-gray-500">
                  <span className="font-semibold">Movo associato: {''}</span>
                  {!isAdmin ? (
                    <Link
                      className="text-gray-500"
                      to={`${pathname === 'corporate' ? '/corporate' : '/dashboard'}/movimenti/${
                        reservation?.rent?._id
                      }`}
                    >
                      {reservation?.rent?.code} <FaLink className="inline mb-1 text-blue-600" />
                    </Link>
                  ) : (
                    <span className="text-gray-500">{reservation?.rent?.code}</span>
                  )}
                </div>
              ) : null}
            </div>
            <div className="pt-1 uppercase font-semibold">
              {reservation.movementType !== undefined && (
                <>
                  {reservation.movementType === 'NOL' ? (
                    <ElementLabel bgColor="bg-blue-500">NOLEGGIO</ElementLabel>
                  ) : reservation.movementType === 'COM' ? (
                    <ElementLabel bgColor="bg-orange-600">COMODATO</ElementLabel>
                  ) : reservation.movementType === 'MNP' ? (
                    <ElementLabel bgColor="bg-gray-600">MOV NON PRODUTTIVO</ElementLabel>
                  ) : (
                    <ElementLabel>{reservation.movementType}</ElementLabel>
                  )}
                </>
              )}
            </div>
            <div className="pt-1 uppercase font-semibold">
              {reservation.state !== undefined && (
                <>
                  {reservation.state === 'aperto' ? (
                    <ElementLabel bgColor="bg-green-500">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'attivo' ? (
                    <ElementLabel bgColor="bg-green-600">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'no show' ? (
                    <ElementLabel bgColor="bg-red-600">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'chiuso' ? (
                    <ElementLabel bgColor="bg-yellow-600">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'fatturato' ? (
                    <ElementLabel bgColor="bg-purple-700">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'parz fatturato' ? (
                    <ElementLabel bgColor="bg-purple-500">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'incassato' ? (
                    <ElementLabel bgColor="bg-sky-700">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'parz incassato' ? (
                    <ElementLabel bgColor="bg-sky-500">{reservation.state}</ElementLabel>
                  ) : reservation.state === 'draft' && pathname.includes('corporate') ? (
                    <ElementLabel bgColor="bg-yellow-500">IN APPROVAZIONE</ElementLabel>
                  ) : reservation.state === 'draft' && !pathname.includes('corporate') ? (
                    <ElementLabel>BOZZA</ElementLabel>
                  ) : (
                    <ElementLabel>{reservation.state}</ElementLabel>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="mt-6 flex flex-wrap gap-x-2 gap-y-4">
            {reservation?.customerCompany && (
              <div className="font-semibold min-w-[11rem]">
                <div className="text-sm">Cliente</div>
                <div className="flex text-gray-600">
                  <div className="mr-2">
                    <div>{reservation?.customerCompany?.ragioneSociale}</div>
                    <div className="text-sm">{reservation?.customerCompany?.phone}</div>
                  </div>

                  {reservation?.customerCompany && (
                    <UserCompanyImage
                      user={reservation?.customerCompany}
                      size="25"
                      goToUser={true}
                    />
                  )}
                </div>
              </div>
            )}

            {!reservation?.customerCompany ? (
              reservation?.customer ? (
                /* Cliente selezionato */
                <div className="font-semibold min-w-[11rem]">
                  <div className="text-sm">Cliente</div>
                  <div className="flex text-gray-600">
                    <div className="mr-2">
                      <div>
                        {reservation?.customer?.name} {reservation?.customer?.surname}
                      </div>
                      <div className="text-sm">{reservation?.customer?.phone}</div>
                    </div>

                    {reservation?.customer && (
                      <UserImage user={reservation?.customer} size="25" goToUser={true} />
                    )}
                  </div>
                </div>
              ) : (
                /* Cliente "light" */
                <div className="font-semibold min-w-[11rem]">
                  <div className="text-sm">Cliente</div>
                  <div className="flex text-gray-600">
                    <div className="mr-2">
                      <div>{reservation?.customerFullName}</div>
                      <div className="text-sm">{reservation?.customerPhone}</div>
                    </div>
                  </div>
                </div>
              )
            ) : null}

            {reservation?.driver ? (
              /* Conducente selezionato */
              <div className="font-semibold min-w-[11rem]">
                <div className="text-sm">Conducente</div>
                <div className="flex text-gray-600">
                  <div className="mr-2">
                    <div>
                      {reservation?.driver?.name} {reservation?.driver?.surname}
                    </div>
                    <div className="text-sm">{reservation?.driver?.phone}</div>
                  </div>

                  {reservation?.driver && (
                    <UserImage user={reservation?.driver} size="25" goToUser={true} />
                  )}
                </div>
              </div>
            ) : (
              /* Conducente "light" */
              <div className="font-semibold min-w-[11rem]">
                <div className="text-sm">Conducente</div>
                <div className="flex text-gray-600">
                  <div className="mr-2">
                    <div>{reservation?.driverFullName}</div>
                    <div className="text-sm">{reservation?.driverPhone}</div>
                  </div>
                </div>
              </div>
            )}

            {reservation?.secondDriver && (
              <div className="font-semibold min-w-[11rem]">
                <div className="text-sm">Secondo conducente</div>
                <div className="flex text-gray-600">
                  <div className="mr-2">
                    <div>
                      {reservation?.secondDriver?.name} {reservation?.secondDriver?.surname}
                    </div>
                    <div className="text-sm">{reservation?.secondDriver?.phone}</div>
                  </div>

                  {reservation?.secondDriver && (
                    <UserImage user={reservation?.secondDriver} size="25" goToUser={true} />
                  )}
                </div>
              </div>
            )}

            <div className="font-semibold w-44">
              <div className="text-sm">Listino</div>
              {pathname === 'corporate' || isAdmin ? (
                <span className="block text-gray-600">{reservation?.priceList?.name}</span>
              ) : (
                <Link
                  className="block text-gray-600"
                  to={`/settings/listini/${reservation?.priceList?._id}`}
                >
                  {reservation?.priceList?.name} <FaToolbox className="inline mb-1 text-blue-600" />
                </Link>
              )}
            </div>

            <div className="font-semibold w-44">
              <div className="text-sm">Flusso</div>
              {pathname === 'corporate' || isAdmin ? (
                <span className="block text-gray-600">{reservation?.workflow?.name}</span>
              ) : (
                <Link
                  className="block text-gray-600"
                  to={`/settings/workflows/${reservation?.workflow?._id}`}
                >
                  {reservation?.workflow?.name} <FaToolbox className="inline mb-1 text-blue-600" />
                </Link>
              )}
            </div>

            <div className="basis-full"></div>

            <div className="font-semibold min-w-[11rem]">
              <div className="text-sm">Inizio</div>
              <DisplayDateTime date={reservation.pickUpDate} displayType={'flat'} />
              <div className="text-xs">
                {pathname === 'corporate' || isAdmin ? (
                  <>
                    <div>{reservation?.pickUpLocation?.name}</div>
                    <div className="text-xs">
                      {reservation?.pickUpLocation?.address} - {reservation?.pickUpLocation?.city}{' '}
                    </div>
                  </>
                ) : (
                  <Link
                    className="font-semibold"
                    to={`/settings/puntinolo/${reservation?.pickUpLocation?._id}`}
                  >
                    {reservation?.pickUpLocation?.name}{' '}
                    <MdGarage className="inline mb-1 text-blue-600" />
                  </Link>
                )}
              </div>
            </div>

            {reservation.expectedDropOffDate !== undefined &&
              reservation.expectedDropOffDate !== reservation.dropOffDate && (
                <div className="font-semibold min-w-[11rem]">
                  <div className="text-sm">Fine prevista</div>
                  <DisplayDateTime date={reservation.expectedDropOffDate} displayType={'flat'} />
                  {pathname === 'corporate' || isAdmin ? (
                    <>
                      <div>{reservation?.dropOffLocation?.name}</div>
                      <div className="text-xs">
                        {reservation?.dropOffLocation?.address} -{' '}
                        {reservation?.dropOffLocation?.city}{' '}
                      </div>
                    </>
                  ) : (
                    <Link
                      className="font-semibold"
                      to={`/settings/puntinolo/${reservation?.dropOffLocation?._id}`}
                    >
                      {reservation?.dropOffLocation?.name}{' '}
                      <MdGarage className="inline mb-1 text-blue-600" />
                    </Link>
                  )}
                </div>
              )}

            <div className="font-semibold min-w-[11rem]">
              <div className="text-sm">Fine</div>
              <DisplayDateTime date={reservation.dropOffDate} displayType={'flat'} />
              <div className="text-xs">
                {pathname === 'corporate' || isAdmin ? (
                  <>
                    <div>{reservation?.dropOffLocation?.name}</div>
                    <div className="text-xs">
                      {reservation?.dropOffLocation?.address} - {reservation?.dropOffLocation?.city}{' '}
                    </div>
                  </>
                ) : (
                  <Link
                    className="font-semibold"
                    to={`/settings/puntinolo/${reservation?.dropOffLocation?._id}`}
                  >
                    {reservation?.dropOffLocation?.name}{' '}
                    <MdGarage className="inline mb-1 text-blue-600" />
                  </Link>
                )}
              </div>
            </div>

            {reservation.totalDays !== undefined && (
              <div className="font-semibold">
                <div className="text-sm">Giorni</div>
                <div className="font-semibold text-gray-600">
                  {reservation.totalDays !== undefined && reservation.totalDays}
                </div>
              </div>
            )}

            <div className="basis-full"></div>

            <FranchisesBox rentResevation={reservation} />
          </div>
        </div>
        <div className="flex flex-col mt-1">
          <div className="text-right">
            <span className="border-2 rounded-full py-1 px-6">
              <strong className="font-bold">{reservation?.group?.mnemonic}</strong> (
              {reservation?.group?.description})
            </span>
          </div>

          <div className="text-right mt-2 mr-4">
            {pathname === 'corporate' || isAdmin ? (
              <span className="font-semibold">
                {reservation?.vehicle?.plate ? reservation?.vehicle.plate.toUpperCase() : ''}
              </span>
            ) : (
              <Link to={`/dashboard/veicoli/flotta/${reservation?.vehicle?._id}`}>
                <span className="font-semibold">
                  {reservation?.vehicle?.plate ? reservation?.vehicle.plate.toUpperCase() : ''}
                </span>
                <FaLink className="inline ml-1 mb-1 text-sm text-blue-600" />
              </Link>
            )}
          </div>

          <div className="flex justify-end">
            {reservation?.vehicle?.version?.imageUrl ? (
              <img
                src={reservation?.vehicle?.version?.imageUrl}
                className="max-h-32 min-h-24 align-right"
                alt={`Immagine veicolo noleggio`}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col text-end max-w-[14rem]">
            <div className="text-sm font-semibold">
              {reservation?.vehicle?.brand?.brandName} {reservation?.vehicle?.model?.modelName}
            </div>

            <div className="text-xs font-semibold text-gray-600">
              {reservation?.vehicle?.version?.versionName}
            </div>
          </div>
          {expandFn ? (
            <div className="flex flex-1 justify-end items-end pt-2">
              <button className="text-xs opacity-70 hover:opacity-100" onClick={expandFn}>
                {isExpanded ? 'Chiudi' : 'Espandi'} tutti{' '}
                <FaAngleDown className={`inline mb-1 ${isExpanded && 'transform rotate-180'}`} />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </WhiteBox>
  );
};

export default ReservationRecap;

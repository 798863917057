import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../utils/Utils';
import TableHeader from '../../../components/UI/TableHeader';
import AdminPage from '../../../components/Admin/AdminPage';
import AccessCodesTable from '../../../components/AccessCodes/AccessCodesTable';

const AdminPacks = () => {
  const history = useHistory();

  const [accessCodesCount, setAccessCodesCount] = useState(0);
  const [accessCodes, setAccessCodes] = useState([]); // eslint-disable-line

  useEffect(() => {
    fetchAccessCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAccessCodes = async () => {
    try {
      const response = await http({ url: '/accessCode' });

      setAccessCodesCount(response.count);
      setAccessCodes(response.accessCodes);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <TableHeader
        tableName={'Codici Accesso'}
        buttons={[
          {
            function: () => {
              history.push(`/admin/codiciaccesso/crea`);
            },
            label: '+',
          },
        ]}
        length={accessCodesCount}
      />

      <AccessCodesTable />
    </AdminPage>
  );
};

export default AdminPacks;

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AdminPage from '../../../../components/Admin/AdminPage';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../../utils/Utils';
import { useHistory } from 'react-router-dom';

import FaresTable from '../../../../components/Clients/Prices/FaresTable';
import TableHeader from '../../../../components/UI/TableHeader';

const AdminFares = () => {
  const history = useHistory();
  const [faresCount, setFaresCount] = useState(0);

  useEffect(() => {
    fetchFares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFares = async () => {
    try {
      const response = await http({ url: '/fares' });
      setFaresCount(response.count);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <TableHeader
        tableName={'Tariffe'}
        buttons={[
          {
            function: () => {
              history.push(`/admin/movolab/tariffe/crea`);
            },
            label: '+',
          },
        ]}
        length={faresCount}
      />

      <FaresTable />
    </AdminPage>
  );
};

export default AdminFares;

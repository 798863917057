import React, { useContext, useEffect, useState } from 'react';
import { CLIENT_ROLE_ADMIN, MOVOLAB_ROLE_ADMIN, http } from '../../../utils/Utils';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SelectField } from '../../Form/SelectField';
import { UserContext } from '../../../store/UserContext';
import Button from '../../UI/buttons/Button';

const UpdatePriceListAdministration = (props) => {
  const [priceList, setPriceList] = useState({ deposits: [] }); // eslint-disable-line
  const { data: currentClient } = useContext(UserContext);
  const form = useForm();
  const params = useParams();
  useEffect(() => {
    fetchPriceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPriceList = async () => {
    try {
      const response = await http({ url: `/pricing/priceLists/${params.id}?mode=flat` });
      setPriceList(response);

      form.setValue('fares.invoicingType', response.configuration?.fares.invoicingType);
      form.setValue('fares.vatPercentage', response.configuration?.fares.vatPercentage);
      form.setValue('deposits.invoicingType', response.configuration?.deposits.invoicingType);
      form.setValue('deposits.vatPercentage', response.configuration?.deposits.vatPercentage);
      form.setValue('kmExtra.invoicingType', response.configuration?.kmExtra?.invoicingType);
      form.setValue('kmExtra.vatPercentage', response.configuration?.kmExtra?.vatPercentage);
      form.setValue('fuelExtra.invoicingType', response.configuration?.fuelExtra?.invoicingType);
      form.setValue('fuelExtra.vatPercentage', response.configuration?.fuelExtra?.vatPercentage);
      form.setValue(
        'maintenanceFranchise.invoicingType',
        response.configuration?.maintenanceFranchise?.invoicingType,
      );
      form.setValue(
        'maintenanceFranchise.vatPercentage',
        response.configuration?.maintenanceFranchise?.vatPercentage,
      );
      form.setValue(
        'rcaFranchise.invoicingType',
        response.configuration?.rcaFranchise?.invoicingType,
      );
      form.setValue(
        'rcaFranchise.vatPercentage',
        response.configuration?.rcaFranchise?.vatPercentage,
      );
      form.setValue(
        'ifFranchise.invoicingType',
        response.configuration?.ifFranchise?.invoicingType,
      );
      form.setValue(
        'ifFranchise.vatPercentage',
        response.configuration?.ifFranchise?.vatPercentage,
      );
      form.setValue(
        'paiFranchise.invoicingType',
        response.configuration?.paiFranchise?.invoicingType,
      );
      form.setValue(
        'paiFranchise.vatPercentage',
        response.configuration?.paiFranchise?.vatPercentage,
      );
      form.setValue(
        'kaskoFranchise.invoicingType',
        response.configuration?.kaskoFranchise?.invoicingType,
      );
      form.setValue(
        'kaskoFranchise.vatPercentage',
        response.configuration?.kaskoFranchise?.vatPercentage,
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  if (!currentClient) return null;
  if (!currentClient.client && currentClient.role !== MOVOLAB_ROLE_ADMIN) return null;

  let options = [];

  if (
    currentClient.client?.license?.licenseOwner === 'client' &&
    currentClient.role === CLIENT_ROLE_ADMIN
  ) {
    options = [
      { value: '', label: '-- Seleziona --' },
      { value: 'customer', label: 'Cliente' },
    ];
  } else if (currentClient.role === MOVOLAB_ROLE_ADMIN) {
    options = [
      { value: '', label: '-- Seleziona --' },
      { value: 'movolab', label: 'Movolab' },
      { value: 'customer', label: 'Cliente' },
    ];
  }

  const onSubmit = async (data) => {
    try {
      const dataToSend = {
        configuration: {
          fares: {
            invoicingType: data.fares.invoicingType,
            vatPercentage: data.fares.vatPercentage,
          },
          deposits: {
            invoicingType: data.deposits.invoicingType,
            vatPercentage: data.deposits.vatPercentage,
          },
          kmExtra: {
            invoicingType: data.kmExtra.invoicingType,
            vatPercentage: data.kmExtra.vatPercentage,
          },
          fuelExtra: {
            invoicingType: data.fuelExtra.invoicingType,
            vatPercentage: data.fuelExtra.vatPercentage,
          },
          maintenanceFranchise: {
            invoicingType: data.maintenanceFranchise.invoicingType,
            vatPercentage: data.maintenanceFranchise.vatPercentage,
          },
          rcaFranchise: {
            invoicingType: data.rcaFranchise.invoicingType,
            vatPercentage: data.rcaFranchise.vatPercentage,
          },
          ifFranchise: {
            invoicingType: data.ifFranchise.invoicingType,
            vatPercentage: data.ifFranchise.vatPercentage,
          },
          paiFranchise: {
            invoicingType: data.paiFranchise.invoicingType,
            vatPercentage: data.paiFranchise.vatPercentage,
          },
          kaskoFranchise: {
            invoicingType: data.kaskoFranchise.invoicingType,
            vatPercentage: data.kaskoFranchise.vatPercentage,
          },
        },
      };

      await http({
        method: 'PUT',
        url: `/pricing/priceLists/${params.id}`,
        form: dataToSend,
      });
      toast.success('Listino aggiornato');
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} id="priceListForm">
      <fieldset disabled={form.formState.isSubmitting} className="flex flex-col gap-y-2">
        <div className="flex gap-2 font-semibold px-4">
          <div className="w-60"></div>
          <div className="w-60 px-3">Fatturazione</div>
          <div className="w-40 px-3">IVA</div>
        </div>

        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Tariffe</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`fares.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`fares.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Depositi</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`deposits.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            {/* <SelectField className="m-0"                 name={`deposits.vatPercentage`}
                form={form}
                options={[
                  { value: 0, label: '0%' },
                  { value: 4, label: '4%' },
                  { value: 10, label: '10%' },
                  { value: 22, label: '22%' },
                ]}
              /> */}
          </div>
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">KM Extra</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`kmExtra.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`kmExtra.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>{' '}
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Carburante Extra</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`fuelExtra.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`fuelExtra.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>{' '}
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Franchigia Manutenzione</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`maintenanceFranchise.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`maintenanceFranchise.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Franchigia RCA</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`rcaFranchise.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`rcaFranchise.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Franchigia Incendio e Furto</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`ifFranchise.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`ifFranchise.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Franchigia PAI</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`paiFranchise.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`paiFranchise.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center w-fit gap-2 bg-slate-50 rounded-md p-4">
          <div className="w-60 text-md font-semibold">Franchigia Kasko</div>
          <div className="w-60 px-3">
            <SelectField
              className="m-0"
              name={`kaskoFranchise.invoicingType`}
              form={form}
              options={options}
            />
          </div>
          <div className="w-40 px-3">
            <SelectField
              className="m-0"
              name={`kaskoFranchise.vatPercentage`}
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>
        {currentClient.role === MOVOLAB_ROLE_ADMIN ? (
          <div>
            <Button>Salva</Button>
          </div>
        ) : null}
      </fieldset>
    </form>
  );
};

export default UpdatePriceListAdministration;
